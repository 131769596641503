import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';
import { MainService } from '../../_services/main.service';
import { AuthService } from '../../_services/auth.service';
import { CartService } from '../../_services/cart.service';
import { ShopService } from '../../_services/shop.service';
import { HomeScreenService } from '../../_services/home-screen.service';

@Component({
  selector: 'app-header-star-old',
  templateUrl: './header-star-old.component.html',
  styleUrls: ['./header-star-old.component.scss']
})
export class HeaderStarOldComponent implements OnInit {
  @Input() ctx: ContextMain;

  constructor(
    public mainService: MainService,
    public authService: AuthService,
    public cartService: CartService,
    public shopService: ShopService,
    public homeScreenService: HomeScreenService
  ) { }

  ngOnInit() {
  }
}
