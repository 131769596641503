import { Component, OnInit, Input } from '@angular/core';
import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-catalog-parts-compact-list',
  templateUrl: './catalog-parts-compact-list.component.html',
  styleUrls: ['./catalog-parts-compact-list.component.scss']
})
export class CatalogPartsCompactListComponent implements OnInit {
  _ctx: ContextCatalog;
  get ctx(): ContextCatalog { return this._ctx; }
  @Input('ctx')
  set ctx(value: ContextCatalog) {
    this._ctx = value;
    this.parts = value ? this.catalogService.getPartsFromContext(this.ctx) : [];
  }
  parts: Part[];
  @Input() maxItems: number;
  filterResult = { count: 0 };
  _maxItemCount = 10;

  constructor(
    public catalogService: CatalogService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this._maxItemCount = this.maxItems;
  }

}
