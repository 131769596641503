import { Component, OnInit, Input } from '@angular/core';

import { ContextMain } from '../../_models/common/context.main';
import { AuthService } from '../../_services/auth.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-login-info',
  templateUrl: './login-info.component.html',
  styleUrls: ['./login-info.component.scss']
})
export class LoginInfoComponent implements OnInit {
@Input() ctx: ContextMain;

  constructor(
    public authService: AuthService,
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
