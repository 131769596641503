import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { UserIdentificationModel } from '../../../_models/user.identification.model';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { MainService } from '../../../_services/main.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss']
})
export class AdminFormComponent implements OnInit {
  header: ElementRef;
  @ViewChild("header") set contentHeader(content: ElementRef) { this.header = content; }
  footer: ElementRef;
  @ViewChild("footer") set contentFooter(content: ElementRef) { this.footer = content; }
  @Input() object: any;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() fullHeight: boolean;
  @Input() noPadding: boolean;
  @Input() withSettingsSelector: boolean;
  @Output() settingsChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() saveAction: Observable<any>;
  @Output() beforeSaveAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() cancelAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() labelCols: number = 2;
  @Input() saveDisabled: boolean;
  fetchingData = false;

  constructor(
    public mainService: MainService,
    private adminSettingsService: AdminSettingsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  isModal(): boolean {
    if (this.object && this.object['modal']) return true;
    return false;
  }

  getScrollStyle(): any {
    const windowHeight = this.mainService.resizeInfo.clientHeight;
    const headerHeight = this.header?.nativeElement?.clientHeight ?? 0;
    const footerHeight = this.footer?.nativeElement?.clientHeight ?? 0;
    const maxHeight = windowHeight - (headerHeight + footerHeight) - 95;
    if (maxHeight > 0) {
      return { 'max-height': maxHeight + 'px', 'overflow': 'auto' };
    }
    return null;
  }

  checkMe(object: any): boolean {
    return object === null;
  }

  getUserIdentificationModel() {
    if (this.object && this.object['UID']) {
      return UserIdentificationModel.getClone(this.object['UID']);
    }
    return null;
  }

  uidChange(uid: UserIdentificationModel) {
    this.fetchingData = true;
    setTimeout(() => {
      this.fetchData(uid);
    }, 0);
  }

  fetchData(uid: UserIdentificationModel) {
    const path = this.router.url;
    this.adminSettingsService.getSettingsForRoute(path.substr(7), uid)
      .subscribe(settings => {
        if (this.object['modal']) settings['modal'] = this.object['modal'];
        this.settingsChanged.emit(settings);
        this.fetchingData = false;
      });
  }

  beforeSave(event: MouseEvent) {
    if (this.isModal()) {
      this.object['modal'].next(true);
    }
    this.beforeSaveAction.emit(event)
  }

  cancel(event: MouseEvent) {
    if (this.isModal() || this.cancelAction.observers.length > 0) {
      this.cancelAction.emit(event);
      if (this.isModal()) {
        this.object['modal'].next(false);
      }
    } else {
      this.router.navigate(['/admin']);
    }
  }

}
