<div class="row">
  <div class="col">
    <h6>
      {{getBranch(branchInfo.BranchNumber)?.BranchName}}<span class="ms-1 text-body-tertiary">({{branchInfo.BranchNumber}})</span>
      <span *ngIf="!getBranch(branchInfo.BranchNumber)?.ShowStock" class="ms-3 badge text-bg-warning">ShowStock = FALSE!</span>
      <span *ngIf="branchInfo.HideStock" class="ms-3 badge text-bg-warning">HideStock = TRUE!</span>
    </h6>
  </div>
</div>
  <div class="row mt-1">
    <div class="col-4"><b>Voorraad</b></div><div class="col-2">{{branchInfo.Stock}}</div>
    <div *ngIf="branchInfo.Stock!=branchInfo.StockPhysical" class="col-4"><b>Voorraad fysiek</b></div><div *ngIf="branchInfo.Stock!=branchInfo.StockPhysical" class="col-2">{{branchInfo.StockPhysical}}</div>
    <div *ngIf="branchInfo.OrderBlockade" class="col-4"><b>Order blokkade</b></div><div *ngIf="branchInfo.OrderBlockade" class="col-2">JA</div>
    <!-- <div *ngIf="isDateOk(depot.value.LastUpdated)" class="col-6 text-end"><i>Laatste update</i></div> 
    <div *ngIf="isDateOk(depot.value.LastUpdated)" class="col-6"><b>{{depot.value.LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</b></div>
    <div *ngIf="isDateOk(depot.value.OrderBeforeDateTime)" class="col-6 text-end"><i>Bestel voor</i></div>
    <div *ngIf="isDateOk(depot.value.OrderBeforeDateTime)" class="col-6"><b>{{depot.value.OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</b></div>
    <div *ngIf="isDateOk(depot.value.BeingDeliveredAtBranchOnDateTime)" class="col-6 text-end"><i>Geleverd op</i></div>
    <div *ngIf="isDateOk(depot.value.BeingDeliveredAtBranchOnDateTime)" class="col-6"><b>{{depot.value.BeingDeliveredAtBranchOnDateTime | date : 'dd-MM-yyyy HH:mm'}}</b></div>
    <div *ngIf="depot.value.Remark" class="col-12 text-body-tertiary"><i>{{depot.value.Remark}}</i></div> -->
  </div>
