import { Pipe, PipeTransform } from '@angular/core';
import { ItemSortOption } from '../_models/common/item.sort.option';
import { SortService } from '../_services/sort.service';

@Pipe({
  name: 'sortOptions'
})
export class SortOptionsPipe implements PipeTransform {

  constructor(private sortService: SortService) { }

  transform(items: any[], sortOption: ItemSortOption, busy: number, alternateDataKey: string = null, alternateData: any = null): any[] {
    if (!items) { return items; }
    const self = this;
    return items.sort((item1, item2) => {
      return self.sortService.getEquality(item1, item2, sortOption.Fields.slice(0), alternateDataKey, alternateData);
    });
  }

}
