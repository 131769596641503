import { AfterContentInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { doGetCaretPosition } from '../_lib/common.typescript.lib';

@Directive({
    selector: '[appLicensePlate]'
})
export class LicensePlateDirective implements AfterContentInit {
    @Output() ngModelChange = new EventEmitter();

    @HostListener('keypress', ['$event'])
    handleKeyPress(event) {
        const inputValue: string = this.elementRef.nativeElement.value;
        const inputChar = String.fromCharCode(event.charCode);
        let selectionStart = doGetCaretPosition(this.elementRef.nativeElement);
        let transformedInput = inputValue.substring(0, selectionStart) + inputChar + inputValue.substring(selectionStart);
        transformedInput = transformedInput.replace(/[^a-zA-Z0-9]/g, '');
        let licensePlate = '';
        event.preventDefault();
        if (transformedInput.length > 6) {transformedInput = transformedInput.substring(0, 6); }
        const groups = transformedInput.match(/^([a-zA-Z]*)([0-9]*)([a-zA-Z]*)([0-9]*)/);
        groups.splice(0, 1);

        groups.forEach(function (g) {
            if (g !== '') {
                if (g.length >= 4) {
                    g = g.substring(0, 2) + '-' + g.substring(2, 4);
                }
                if (licensePlate !== '') {
                    licensePlate += '-' + g;
                } else {
                    licensePlate = g;
                }
            }
        });

        if (this.elementRef.nativeElement.value !== licensePlate) {
            this.elementRef.nativeElement.value = licensePlate;
            this.ngModelChange.emit(this.elementRef.nativeElement.value);
        }
    }

    constructor(
        private elementRef: ElementRef
    ) {
    }

    public ngAfterContentInit(): void { }

}
