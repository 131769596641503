import { CatalogLayoutBaseObject } from './catalog.layout.base.object';

export class CatalogLayoutSubgroup  extends CatalogLayoutBaseObject {
    ID: number;
    SortOrder: number;
    Description: string;
    Maingroup: number;
    ImageUrl: string;
    FavouriteSortOrder: number;
}
