import { Component, Input } from '@angular/core';
import { CreditWorthyResponse } from 'app/_models/common/credit.worthy.response';
import { AdminService } from 'app/_services/admin/admin.service';
import { ApiService } from 'app/_services/api.service';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-customer-warnings',
  templateUrl: './customer-warnings.component.html',
  styleUrl: './customer-warnings.component.scss'
})
export class CustomerWarningsComponent {
  private _customer: number;
  @Input() set customer(value: number) {
    this._customer = value;
    this.refresh()
  }
  get customer(): number {
    return this._customer;
  }
  response: CreditWorthyResponse;

  constructor(
    public mainService: MainService,
    private apiService: ApiService,
    private adminService: AdminService
  ) {

  }

  refresh() {
    if (this.customer) {
      this.apiService.adminGetCustomerWarnings(this.adminService.currentUid, this.customer)
        .subscribe(response => this.response = response);
    }
  }

  hasWarnings() {
    if (this.response?.Customer?.SalesBlock) return true;
    if (this.response && !this.response.CreditWorthy) return true;
    return false;
  }

}
