<span class="me-3"><i class="fas fa-gas-pump"></i> {{tyre.ItemInfo.Properties['FuelEfficiencyClass']}}</span>
<span class="me-3"><i class="fas fa-cloud-rain"></i> {{tyre.ItemInfo.Properties['WetGripClass']}}</span>
<span class="me-3">{{tyre.ItemInfo.Properties['RollNoiseValue']}}dB</span>
<span class="me-3">
  <span *ngIf="tyre.ItemInfo.Properties['RollNoiseClass']=='0'"><i class="fas fa-volume-off"></i></span>
  <span *ngIf="tyre.ItemInfo.Properties['RollNoiseClass']=='1'"><i class="fas fa-volume-down"></i></span>
  <span *ngIf="tyre.ItemInfo.Properties['RollNoiseClass']=='2'"><i class="fas fa-volume"></i></span>
  <span *ngIf="tyre.ItemInfo.Properties['RollNoiseClass']=='3'"><i class="fas fa-volume-up"></i></span>
  <span *ngIf="'ABC'.indexOf(tyre.ItemInfo.Properties['RollNoiseClass']) !== -1"><i class="fas fa-volume-off"></i> {{tyre.ItemInfo.Properties['RollNoiseClass']}}</span>        
</span>  
<span *ngIf="tyre.ItemInfo.Properties['SnowGrip']==2" class="me-3"><i class="far fa-snowflake"></i></span>
<!-- <span class="me-3" *ngIf="tyre.ItemInfo.Properties['SnowGrip']==1" class="fa-stack">
  <i class="far fa-snowflake fa-stack-1x"></i>
  <i class="fa fa-ban fa-stack-2x text-danger"></i>
</span> -->
<span *ngIf="tyre.ItemInfo.Properties['IceGrip']==2" class="me-3"><i class="fas fa-icicles fa-flip-vertical"></i></span>
