import { Component, OnInit, Input, ElementRef, NgZone, Renderer2, ViewChild } from "@angular/core";

import { AuthService } from "../../_services/auth.service";
import { ShopService } from "../../_services/shop.service";
import { MainService } from "../../_services/main.service";
import { ContextMain } from "../../_models/common/context.main";
import { PromotionService } from "../../_services/promotion.service";
import { ContextPromotions } from "../../_models/promotions/context.promotions";
import { SettingsService } from "../../_services/settings.service";
import { ShopSoort } from "../../_models/common/shop.soort";
import { CartService } from "../../_services/cart.service";
import { HomeScreenService } from "../../_services/home-screen.service";

@Component({
  selector: "app-header-webshop",
  templateUrl: "./header-webshop.component.html",
  styleUrls: ["./header-webshop.component.scss"]
})
export class HeaderWebshopComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctx: ContextMain;
  ctxPromotions: ContextPromotions;

  @ViewChild('counter')
  public myCounter: ElementRef;

  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    public mainService: MainService,
    public settingsService: SettingsService,
    public authService: AuthService,
    public cartService: CartService,
    public shopService: ShopService,
    public promotionService: PromotionService,
    private homeScreenService: HomeScreenService
  ) {
    if (!this.settingsService.getSettingBoolean('GeenVolgendeRouteInHeader')) {
      const nextRouteCounterWithSeconds = this.settingsService.getSettingBoolean('NextRouteCounterWithSeconds');
      const interval = nextRouteCounterWithSeconds ? 1000 : 60000;
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.renderer.setProperty(this.myCounter.nativeElement, 'textContent'
            , this.homeScreenService.getDaysHoursMinutesSecondsTillNextRoute(nextRouteCounterWithSeconds));
          setInterval(() => {
            this.renderer.setProperty(this.myCounter.nativeElement, 'textContent'
              , this.homeScreenService.getDaysHoursMinutesSecondsTillNextRoute(nextRouteCounterWithSeconds));
          }, interval);
        }, 100);
      });
    }
  }

  ngOnInit() {
    this.getPromotionContext();
  }

  getWhatsAppLink(): string {
    const number = this.ctx.CompanyInfo.BranchWhatsApp.replace(/\D/g, '').replace(/^0+/, '').replace(/^6/, '316');
    const message = encodeURI(`${this.ctx.CompanyInfo.CustomerName} (${this.ctx.CompanyInfo.CustomerNumber}) wil graag het volgende weten...`) + '%0A%0A';
    return `https://wa.me/${number}?text=${message}`;
  }

  getPromotionContext(): void {
    this.promotionService.getContext(true)
      .subscribe(ctx => {
        this.ctxPromotions = ctx;
      });
  }


}
