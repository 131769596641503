import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-subtitle',
  templateUrl: './admin-subtitle.component.html',
  styleUrls: ['./admin-subtitle.component.scss']
})
export class AdminSubtitleComponent implements OnInit {
  @Input() title: string;
  @Input() dividerKind: number;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {
  }

}
