import { Component, Input, OnInit } from '@angular/core';
import { SupplierModel } from '../../../../../_models/common/supplier.model';
import { AvailabilityCode } from '../../../../../_models/item-info/availability.code';
import { ResponseItemAvailabilitySupplierInfo } from '../../../../../_models/item-info/response.item.availability.supplier.info';
import { ResponseItemPriceNet } from '../../../../../_models/item-info/response.item.price.net';
import { SupplierInterfaceData } from 'app/_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from 'app/_services/admin/admin-supplier-interface.service';
import { AdminService } from 'app/_services/admin/admin.service';
import { InterfaceKindInfoModel } from 'app/_models/admin/supplier-interface/interface.kind.info.model';

@Component({
  selector: 'app-item-info-supplier-availability',
  templateUrl: './item-info-supplier-availability.component.html',
  styleUrls: ['./item-info-supplier-availability.component.scss']
})
export class ItemInfoSupplierAvailabilityComponent implements OnInit {
  availabilityCode = AvailabilityCode;
  @Input() supplierInfo: ResponseItemAvailabilitySupplierInfo;
  @Input() suppliers: { [key: number]: SupplierModel; };
  @Input() isMainSupplier = false;
  @Input() netPrice: ResponseItemPriceNet;
  @Input() supplierInterfaceData: SupplierInterfaceData;

  constructor(
    private adminService: AdminService,
    public adminSupplierInterfaceService: AdminSupplierInterfaceService
  ) { }

  ngOnInit(): void {
  }

  getSupplier(supplierId: number): SupplierModel {
    if (this.suppliers[supplierId]) { return this.suppliers[supplierId]; }
    return null;
  }

  isDateOk(date: Date | null): boolean {
    if (date && date.getFullYear() < 2999) return true;
    return false;
  }

  clickInterface(interfaceKindInfo: InterfaceKindInfoModel, supplier: number, itemNumber: string, itemGroup: string) {
    const interfaceData = this.adminSupplierInterfaceService.getInterfaceDataForInterfaceKindAndSupplier(this.supplierInterfaceData, interfaceKindInfo.InterfaceKind, supplier);
    this.adminService.supplierInterfaceInfoPopup(interfaceData, interfaceKindInfo, true, itemNumber, itemGroup).subscribe()
  }



}
