<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="returns" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true">Retouren</li>
    </app-breadcrumb-header>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="getReturns()"></app-extra-password-webshop>
    </div>

    <div *ngIf="!customerInformationService.showPasswordPrompt">

      <button type="button" class="btn btn-sm btn-primary mb-3" (click)="request()">Nieuwe retour</button>

      <app-grid-webshop [items]="sortService.sortObject('returns', returns)" emptyText="Geen retouren gevonden."
        class="mt-3" [isBusy]="isBusy" [enableRowSelection]="true"
        (rowClick)="customerInformationService.gotoReturnDetail($event)">
        <ng-container grid-header>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'returns'" [sortField]="'ReturnId'">Retournr</app-sort-field>
          </div>
          <div class="col-6">
            <app-sort-field [sortObjectId]="'returns'" [sortField]="'Reference'">Referentie</app-sort-field>
          </div>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'returns'" [sortField]="'DateRequested'">Datum aanvraag</app-sort-field>
          </div>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'returns'" [sortField]="'Status'">Status</app-sort-field>
          </div>
        </ng-container>
        <ng-template let-item>
          <div class="col-2">{{item.OrderNumber | leadZeros:8}}</div>
          <div class="col-6">{{item.Reference}}</div>
          <div class="col-2">{{item.DateRequested | date : 'dd-MM-yyyy'}}</div>
          <div class="col-2">{{customerInformationService.getRetourStatusDescription(item.Status)}}</div>
        </ng-template>
      </app-grid-webshop>

    </div>


  </div>
</div>