import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ShopSoort } from '../common/shop.soort';
import { ContextBase } from '../common/context.base';


export class ThirdpartyShopSession {
  shopSoort: ShopSoort;
  ctx: ContextBase;
  container: HTMLDivElement;
  iframe: HTMLIFrameElement;

  static Init(shopSoort: ShopSoort, contextObservable: Observable<ContextBase>): Observable<ThirdpartyShopSession> {
    return contextObservable.pipe(mergeMap(ctx => {
      const session = new ThirdpartyShopSession();
      session.shopSoort = shopSoort;
      session.ctx = ctx;
      return of(session);
    }));
  }
}
