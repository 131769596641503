import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  HostListener,
  ElementRef,
  AfterContentInit,
  OnDestroy
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Subscription } from "rxjs";

import { ApiService } from "../_services/api.service";
import { ContextMain } from "../_models/common/context.main";
import { AuthService } from "../_services/auth.service";
import { MainService } from "../_services/main.service";
import { ResizeInfo } from "../_models/common/resize.info";
import { MessageService } from "../_services/message.service";
import { CartService } from "../_services/cart.service";
import { CarTypeService } from "../_services/car-type.service";
import { ShopService } from "../_services/shop.service";
import { ServicesParameter } from "../_models/common/services.parameter";
import { Router } from "@angular/router";
import { Animations } from "../_animations/animations";
import { PromotionService } from "../_services/promotion.service";
import { SettingsService } from "../_services/settings.service";

@Component({
  selector: "app-portal",
  templateUrl: "./portal.component.html",
  styleUrls: ["./portal.component.scss"],
  animations: [
    Animations.slideInCart,
    Animations.slideInMenu,
    Animations.showStaticMenu
  ]
})
export class PortalComponent implements OnInit, AfterContentInit, OnDestroy {
  cartWidth = 240;
  remainingHeight = 0;
  ctx: ContextMain;
  header: ElementRef;
  @ViewChild("header") set contentHeader(content: ElementRef) {
    this.header = content;
    // console.info('onResize: header');
    this.onResize(null);
  }
  menu: ElementRef;
  @ViewChild("menu") set contentMenu(content: ElementRef) {
    this.menu = content;
    // console.info('onResize: menu');
    this.onResize(null);
  }
  cart: ElementRef;
  @ViewChild("cart") set contentCart(content: ElementRef) {
    this.cart = content;
    // console.info('onResize: cart');
    this.onResize(null);
  }
  resizeInfosSubscription: Subscription;
  contextMainChangeSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document,
    private router: Router,
    public mainService: MainService,
    public settingsService: SettingsService,
    private cartService: CartService,
    private carTypeService: CarTypeService,
    private authService: AuthService,
    private apiService: ApiService,
    private shopService: ShopService,
    private promotionService: PromotionService,
    private messageService: MessageService
  ) { }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    this.messageService.handleExternalMessage(event, <ServicesParameter>{
      router: this.router,
      mainService: this.mainService,
      cartService: this.cartService,
      carTypeService: this.carTypeService,
      authService: this.authService,
      apiService: this.apiService,
      shopService: this.shopService
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let headerHeight = 0;
    let menuWidth = 0;
    let cartWidth = 0;
    if (this.header) {
      headerHeight = this.header.nativeElement.clientHeight;
    }
    if (this.menu) {
      menuWidth = this.menu.nativeElement.clientWidth;
    }
    if (this.ctx && this.ctx.ShowCartContent && this.cart) {
      cartWidth = this.cartWidth;
    }
    this.mainService.onResize(
      new ResizeInfo(
        document.body.clientWidth,
        document.body.clientHeight,
        headerHeight,
        menuWidth,
        cartWidth
      )
    );
  }

  getRemainingHeightStyle() {
    if (this.remainingHeight) {
      return {
        height: `${this.remainingHeight}px`,
        overflow: 'hidden'
      };
    }
    return null;
  }

  getIEMenuStyle() {
    let menuWidth = 0;
    let menuHeight = 0;
    if (this.ctx && this.ctx.ShopModuleMenu) {
      menuWidth = this.ctx.ShopModuleMenu.MenuWidth;
    }
    if (this.mainService.resizeInfo) {
      menuHeight =
        this.mainService.resizeInfo.clientHeight -
        this.mainService.resizeInfo.headerHeight;
    }
    return {
      flexBasis: `${menuWidth}px`,
      height: `${menuHeight}px`,
      overflowY: 'auto'
    };
  }

  getMenuWidth(): number {
    let menuWidth = 0;
    if (this.ctx && this.ctx.ShopModuleMenu) {
      menuWidth = this.ctx.ShopModuleMenu.MenuWidth;
    }
    return menuWidth;
  }

  getMenuStyleStatic() {
    let menuWidth = 0;
    let menuHeight = 0;
    let headerHeight = 0;
    if (this.ctx && this.ctx.ShopModuleMenu) {
      menuWidth = this.ctx.ShopModuleMenu.MenuWidth;
    }
    if (this.mainService.resizeInfo) {
      menuHeight =
        this.mainService.resizeInfo.clientHeight -
        this.mainService.resizeInfo.headerHeight;
    }
    if (this.mainService.resizeInfo) {
      headerHeight = this.mainService.resizeInfo.headerHeight;
    }
    return {
      width: `${menuWidth}px`,
      height: `${menuHeight}px`,
      top: `${headerHeight}px`
    };
  }

  getMenuStyleNormal() {
    let menuHeight = 0;
    if (this.mainService.resizeInfo) {
      menuHeight =
        this.mainService.resizeInfo.clientHeight -
        this.mainService.resizeInfo.headerHeight;
    }
    return {
      height: `${menuHeight}px`,
      overflowY: 'auto'
    };
  }

  getMenuStyleStaticButton() {
    let headerHeight = 0;
    if (this.mainService.resizeInfo) {
      headerHeight = this.mainService.resizeInfo.headerHeight;
    }
    return {
      top: `${headerHeight - 40}px`
    };
  }

  getCartStyle() {
    return {
      flex: `0 0 ${this.cartWidth}px`,
      overflow: "hidden"
    };
  }

  handleResizeInfo(info: ResizeInfo) {
    // console.info('ResizeInfo ->', info);
    if (info) {
      this.remainingHeight = info.clientHeight - info.headerHeight;
    }
  }

  ngOnInit() {
    this.mainService.resizeInfos$.subscribe(info =>
      this.handleResizeInfo(info)
    );
    this.mainService.contextMainChange$.subscribe(ctx =>
      this.handleContextMainChange(ctx)
    );
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) {
      this.resizeInfosSubscription.unsubscribe();
    }
    if (this.contextMainChangeSubscription) {
      this.contextMainChangeSubscription.unsubscribe();
    }
  }

  handleContextMainChange(ctx: ContextMain) {
    if (ctx) {
      this.cartService.updateContextCart(ctx.ContextCart);
      this.ctx = ctx;
      this.shopService.initShopService(this.mainService);
      this.promotionService.init();
    }
  }

  ngAfterContentInit() {
    this.mainService.fireReziseEvent();
  }
}
