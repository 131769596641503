import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { SupplierModel } from '../../../../_models/common/supplier.model';
import { AdminService } from '../../../../_services/admin/admin.service';
import { SettingsExceptionsAvailabilityAndPrice } from '../../../../_models/admin/settings/settings.exceptions.availability.and.price';

@Component({
  selector: 'app-admin-settings-exceptions-availability-and-price',
  templateUrl: './admin-settings-exceptions-availability-and-price.component.html',
  styleUrls: ['./admin-settings-exceptions-availability-and-price.component.scss']
})
export class AdminSettingsExceptionsAvailabilityAndPriceComponent implements OnInit {
  settings: SettingsExceptionsAvailabilityAndPrice;
  focusInput = {
    ShowPriceOnlyWhenOnStockForTheseSuppliers: new EventEmitter<boolean>(),
    HideStockForSuppliers: new EventEmitter<boolean>()
  };

  constructor(
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getSuppliers(prop: string): SupplierModel[] {
    if (this.settings && this.settings[prop] && Object.keys(this.settings[prop]).length > 0) {
      return Object.keys(this.settings[prop])
        .map(supplier => this.settings[prop][supplier])
        .sort((s1: SupplierModel, s2: SupplierModel) => s1 && s1.Name ? s1.Name.localeCompare(s2.Name) : 0);
    }
    return null;
  }

  addSupplier(prop: string, supplier: SupplierModel) {
    if (supplier && this.settings && this.settings[prop] && !this.settings[prop][supplier.SupplierNumber]) {
      this.settings[prop][supplier.SupplierNumber] = supplier;
    }
    this.focusInput[prop].emit(true);
  }

  deleteSupplier(prop: string, supplier: SupplierModel) {
    if (this.settings && this.settings[prop] && this.settings[prop][supplier.SupplierNumber]) {
      delete this.settings[prop][supplier.SupplierNumber];
    }
  }


}
