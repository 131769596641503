import { Component, OnInit, Input } from '@angular/core';

import { ContextTyres } from '../../../../_models/tyres/context.tyres';
import { ShoppingCartItem } from '../../../../_models/cart/shopping.cart.item';
import { WheelTyreSet } from '../../../../_models/rims-and-tyres/wheel.tyre.set';
import { MainService } from '../../../../_services/main.service';
import { RimAndTyreService } from '../../../../_services/rim-and-tyre.service';
import { CartService } from '../../../../_services/cart.service';

@Component({
  selector: 'app-tyres-list-view-boxed',
  templateUrl: './tyres-list-view-boxed.component.html',
  styleUrls: ['./tyres-list-view-boxed.component.scss']
})
export class TyresListViewBoxedComponent implements OnInit {
  _maxItemCount: number;
  @Input() ctx: ContextTyres;
  @Input() maxItems: number;
  _tyres: ShoppingCartItem[];
  get tyres(): ShoppingCartItem[] { return this._tyres; }
  @Input('tyres')
  set tyres(value: ShoppingCartItem[]) {
    this._tyres = value;
    this._maxItemCount = this.maxItems;
  }
  @Input() set: WheelTyreSet;

  constructor(
    public mainService: MainService,
    public rimAndTyreService: RimAndTyreService,
    public cartService: CartService
  ) { }

  ngOnInit() {
    this._maxItemCount = this.maxItems;
  }

}
