<app-admin-form [title]="'Aanbieding onderhoud'" [object]="promotion">
  <app-admin-input [label]="'Titel'" [(model)]="promotion.Titel"></app-admin-input>
  <app-admin-checkbox [label]="'Aktief'" [(model)]="promotion.Aktief"></app-admin-checkbox>
  <app-admin-editor [label]="'Omschrijving'" [(model)]="promotion.Omschrijving"></app-admin-editor>
  <app-admin-date-input [label]="'Geldig vanaf'" [(model)]="promotion.GeldigVanaf"></app-admin-date-input>
  <app-admin-date-input [label]="'Geldig tot'" [(model)]="promotion.GeldigTot"></app-admin-date-input>
  <app-admin-input [label]="'Tekst van'" [(model)]="promotion.TekstVan" [placeholder]="'van veel'"></app-admin-input>
  <app-admin-input [label]="'Tekst voor'" [(model)]="promotion.TekstVoor" [placeholder]="'voor weinig'"></app-admin-input>
  <app-admin-custom-control [label]="'Filialen'">
    <div *ngFor="let branch of branches" class="custom-control custom-checkbox noselect">
      <input type="checkbox" class="custom-control-input" [id]="'customCheck'+branch.Filiaalnr"
        [(ngModel)]="branch.Selected" (ngModelChange)="branchChange(branch)">
      <label class="custom-control-label" [for]="'customCheck'+branch.Filiaalnr">{{branch.Naam}}</label>
    </div>
  </app-admin-custom-control>
  <app-admin-custom-control [label]="'Artikelen'">
    <table *ngIf="promotion.Artikelen.length>0" class="table table-striped table-responsive small">
      <thead>
        <tr>
          <th>Artikelnummer</th>
          <th class="text-end">Artikelgroep</th>
          <th class="text-end">Aantal</th>
          <th>Omschrijving</th>
          <th class="text-end">Aktie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of promotion.Artikelen">
          <td>{{item.Artikelnr}}</td>
          <td class="text-end">{{item.Artikelgroep}}</td>
          <td class="text-end">{{item.Aantal}}</td>
          <td>{{item.Omschrijving}}</td>
          <td class="text-end align-middle">
            <div class="btn-group">
              <button class="btn btn-xs btn-outline-secondary"
                (click)="adminPromotionService.editPromotionItem(promotion, item)"><i class="fas fa-edit"></i>
                Wijzig</button>
              <button class="btn btn-xs btn-outline-secondary"
                (click)="adminPromotionService.deletePromotionItem(promotion, item)"><i class="fas fa-trash-alt"></i>
                Verwijder</button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-end align-middle" colspan="8">
            <button class="btn-primary btn btn-xs" (click)="adminPromotionService.editPromotionItem(promotion, null)">
              <i class="fas fa-plus"></i> Artikel Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button *ngIf="promotion.Artikelen.length==0" class="btn-primary btn" (click)="adminPromotionService.editPromotionItem(promotion, null)"><i class="fas fa-plus"></i> Artikel Toevoegen</button>
  </app-admin-custom-control>
  <app-admin-custom-control [label]="'Afbeeldingen'">
    <app-upload [description]="'Zoek nieuwe afbeeldingen, of sleep ze in het vak hierboven.'" [accept]="'image/*'"
      (FileDrop)="upload($event)">
      <div class="image-thumbs-container"><div class="grow" *ngFor="let image of promotion.Images">
        <img style="height: 75px;" [src]="image.AfbeeldingsUrl" />
        <span class="image-delete" (click)="adminPromotionService.deletePromotionImage(promotion,image)"><i class="fas fa-trash-alt"></i></span>
      </div></div>
    </app-upload>
  </app-admin-custom-control>
  <app-admin-cancel-save-button [saveAction]="adminPromotionService.savePromotionDialog(promotion)"
    (cancelAction)="cancelPromotion()"></app-admin-cancel-save-button>
</app-admin-form>
