<div *ngIf="title" class="modal-header text-bg-primary py-2">
  {{title}}
</div>
<div class="modal-body">
  <div [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}" class="form-group row">
    <button *ngFor="let kvp of selectionValues | keyvalue" type="button" class="list-group-item list-group-item-action" (click)="confirm(kvp.value)">{{kvp.key}}</button>
  </div>
  <div class="row">
    <div class="col-6 ps-2"><button type="button" class="btn btn-warning w-100" (click)="decline()">Annuleren</button></div>
  </div>
</div>
