import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemImageItemNumber } from '../../../_models/admin/item-images/item.image.item.number';
import { ItemImagesItemNumberData } from '../../../_models/admin/item-images/item.images.item.number.data';
import { AdminItemImagesService } from '../../../_services/admin/admin-item-images.service';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-admin-item-images',
  templateUrl: './admin-item-images.component.html',
  styleUrls: ['./admin-item-images.component.scss']
})
export class AdminItemImagesComponent implements OnInit {
  itemImagesData: ItemImagesItemNumberData;
  focusInput = new EventEmitter<boolean>();
  filterTimeout: number;

  constructor(
    public mainService: MainService,
    public adminItemImagesService: AdminItemImagesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: any) => {
        this.itemImagesData = data.itemImagesData;
        this.setInputFocus();
      });
  }

  setInputFocus(): void {
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  clearFilter(event: MouseEvent) {
    this.itemImagesData.Filter = '';
    this.setInputFocus();
  }

  doFilter(directly = false) {
    let timeout = 1000;
    if (this.filterTimeout) { window.clearTimeout(this.filterTimeout); }
    if (directly) { timeout = 0; }
    this.filterTimeout = window.setTimeout(() => {
      this.adminItemImagesService.getItemImagesItemNumberData(this.itemImagesData.Filter, 1, this.itemImagesData.PageSize)
        .subscribe(data => {
          this.itemImagesData = data;
          this.setInputFocus();
        });
    }, timeout);
  }

  delete(item: ItemImageItemNumber) {
    this.adminItemImagesService.deleteItemImagesItemNumberDialog(item)
      .subscribe(ok => {
        if (ok) {
          this.doFilter(true);
        };
      });
  }

}
