import { ShopPortalMessageButton } from './shop.portal.message.button';
import { TimingObject } from './timing.object';

export class ShopPortalMessage {
    Title: string;
    Text: string;
    Buttons: ShopPortalMessageButton[];
    Timing: TimingObject;

    constructor(title: string, text: string, buttons: ShopPortalMessageButton[], timing: TimingObject) {
        this.Title = title;
        this.Text = text;
        this.Buttons = buttons;
        this.Timing = timing;
    }
}
