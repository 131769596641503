export class CatalogLayoutFilterSettings {
  extendedFilter: boolean;
  autoFilter: boolean;
  withPartsDescriptions: boolean;
  withBrands: boolean;
  hideFilteredCategories: boolean;
  hideFilteredGroups = true;
  hideInactiveCategories: boolean;
  filterString: string;
  partsDescriptionFilterString: string;
  brandFilterString: string;

  constructor() {
    this.extendedFilter = false;
    this.autoFilter = false;
    this.withPartsDescriptions = false;
    this.withBrands = false;
    this.hideFilteredCategories = false;
    this.hideFilteredGroups = false;
    this.hideInactiveCategories = false;
    this.filterString = '';
    this.partsDescriptionFilterString = '';
    this.brandFilterString = '';
  }
}
