<app-admin-form [title]="'Categorie onderhoud'" [object]="category">
  <app-admin-input [label]="'Omschrijving'" [(model)]="category.Description"></app-admin-input>
  <app-admin-checkbox [label]="'Aktief'" [(model)]="category.Active"></app-admin-checkbox>
  <app-admin-cancel-save-button
    [saveAction]="adminService.handleObservableResult(adminLoyaltyShopService.saveLoyaltyShopCategory(category),['/admin/loyaltyshop/categories'])"
    (cancelAction)="router.navigate(['/admin/loyaltyshop/categories']);">
    <button *ngIf="category.ID>0" type="button" class="btn btn-danger ms-2"
      (click)="adminLoyaltyShopService.deleteLoyaltyShopCategoryDialog(category,['/admin/loyaltyshop/categories'])">
      <i class="fas fa-trash-alt"></i> Verwijderen</button>
  </app-admin-cancel-save-button>
</app-admin-form>