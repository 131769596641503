<app-admin-form [title]="'Artikel afbeeldingen onderhoud'" [object]="item">
  <app-admin-typeahead-item [label]="'Artikel'" [model]="taItem" [focusInput]="focusInput" [searchInDescription]="false" (modelChange)="itemSelected($event)"></app-admin-typeahead-item>
  <app-admin-custom-control [label]="'Afbeeldingen'">
    <app-upload [description]="'Zoek nieuwe afbeeldingen, of sleep ze in het vak hierboven...'" [accept]="'image/*'"
      (FileDrop)="upload($event)">
      <div class="image-thumbs-container"><div class="grow" *ngFor="let image of item.Images">
        <img [src]="image.ImageUrl" class="image-thumb img-fluid " />
        <span class="image-delete" (click)="adminItemImagesService.deleteItemImageItemNumberDialog(item, image)"><i class="fas fa-trash-alt"></i></span>
      </div></div>
    </app-upload>
    <button class="btn-primary btn btn-sm mt-2 ms-2" (click)="addImageUrl($event)">...of klik hier om een url naar een afbeelding toe te voegen.</button>
  </app-admin-custom-control>
  <app-admin-cancel-save-button [saveAction]="save(item)" [saveDisabled]="item.InternalItemNumber==0 || !item.Images || !item.Images.length" (cancelAction)="cancel()"></app-admin-cancel-save-button>
</app-admin-form>
