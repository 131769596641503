<div class="container">

  <app-breadcrumb-header [debugObject]="order" [autoStart]="true">
    <li appBreadcrumbHeaderItem [routerLink]="['/details/open-orders']">Openstaande Orders</li>
    <li appBreadcrumbHeaderItem [active]="true">{{orderId}}</li>
  </app-breadcrumb-header>

  <app-grid-webshop [items]="getSortedItems()" emptyText="Geen orderregels gevonden." class="mt-3 mb-3"
    [enableRowSelection]="false" [isBusy]="isBusy">
    <ng-container grid-header>
      <div class="col-1">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'LineNumberOrder'">Regel
        </app-sort-field>
      </div>
      <div class="col-2">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'ItemNumber'">Artikelnr
        </app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'ItemGroup'">Groep</app-sort-field>
      </div>
      <div class="col-3">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'Description'">Omschrijving
        </app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'Quantity'">Aantal</app-sort-field>
      </div>
      <div class="col-2 text-end">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'GrossPrice'">Bruto</app-sort-field>
      </div>
      <div class="col-2 text-end">
        <app-sort-field [sortObjectId]="'open-orders-headers-details'" [sortField]="'NetPrice'">Netto</app-sort-field>
      </div>
    </ng-container>
    <ng-template let-item>
      <div class="col-1">{{getLine(item).LineNumberOrder | leadZeros:4}}</div>
      <div *ngIf="getLine(item).LineType===1" class="col-2">{{getLine(item).ItemNumber}}</div>
      <div *ngIf="getLine(item).LineType===1" class="col-1 text-end">{{getLine(item).ItemGroup | leadZeros:4}}
      </div>
      <div *ngIf="getLine(item).LineType===1" class="col-3">{{item.Description}}</div>
      <div *ngIf="getLine(item).LineType===1" class="col-1 text-end">{{getLine(item).Quantity}}</div>
      <div *ngIf="getLine(item).LineType===1" class="col-2 text-end">{{getLine(item).GrossPrice | amount}}</div>
      <div *ngIf="getLine(item).LineType===1" class="col-2 text-end">{{getLine(item).NetPrice | amount }}</div>
      <div *ngIf="getLine(item).LineType!==1" class="col-11">{{item.Description}}</div>
    </ng-template>
  </app-grid-webshop>

</div>
