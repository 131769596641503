import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Wheel } from '../../../_models/rims-and-tyres/wheel';
import { MainService } from '../../../_services/main.service';
import { ContextRimsAndTyres } from '../../../_models/rims-and-tyres/context.rims.and.tyres';
import { Animations } from '../../../_animations/animations';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-wheel-info-popup',
  templateUrl: './wheel-info-popup.component.html',
  styleUrls: ['./wheel-info-popup.component.scss'],
  animations: [Animations.fadeInOut]
})
export class WheelInfoPopupComponent implements OnInit {
  ctx: ContextRimsAndTyres;
  wheel: Wheel;
  mainService: MainService;
  catalogService: CatalogService;
  selectedImage = 0;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.bsModalRef.hide();
  }

}
