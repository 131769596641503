<app-admin-typeahead-item-search [label]="label" [labelCols]="labelCols" [disabled]="disabled" [dropup]="dropup"
  [wholesaler]="adminService.currentUid.Wholesaler" [focusInput]="focusInput" (selected)="add($event)"
  [placeholder]="placeholder" [dividerKind]="-1" [start]="start" [emptyAfterSelected]="true">
</app-admin-typeahead-item-search>
<app-admin-custom-control [label]="' '" [labelCols]="labelCols" [dividerKind]="dividerKind" [disabled]="disabled">
  <button *ngIf="noneSelectionLabel && (!model || !model.length)" type="button" class="btn btn-light border border-light-subtle text-start mt-2" (click)="doSearch()">
    <span>{{noneSelectionLabel}}</span>
  </button>
  <button *ngFor="let modelObject of modelObjects;let index=index" type="button" class="btn btn-light border border-light-subtle text-start me-2 mt-2" (click)="doSearch(modelObject.ItemNumber)">
    <div class="row">
      <div *ngIf="sortEnabled" class="col text-center" style="width: 72px;">
        <div class="badge text-bg-light h-100">
          <div class="btn-group">
            <button [disabled]="index===0" class="btn btn-xs btn-outer-secondary" (click)="miia(index, index-1)"><i class="fas fa-arrow-left"></i></button>
            <button [disabled]="index===(modelObjects.length-1)" class="btn btn-xs btn-outer-secondary" (click)="miia(index, index+1)"><i class="fas fa-arrow-right"></i></button>
          </div>
          <div><div class="index badge text-bg-secondary">{{index + 1}}</div></div>  
        </div>
      </div>
      <div class="col-auto" (click)="doSearch(modelObject.Description)">
        <span *ngIf="noneSelectionLabel && (!model || !model.length)">{{noneSelectionLabel}}</span>
        <span><b>{{modelObject.ItemNumber}}</b> <span class="ms-3 text-low">({{modelObject.ItemGroup}})</span>
            <span *ngIf="noneSelectionLabel" (click)="remove($event, modelObject)" class="ms-3"><i class="fas fa-trash-alt"></i></span>
            <br />{{modelObject.Description}}</span>
      </div>
    </div>
  </button>
</app-admin-custom-control>
