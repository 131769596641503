import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SettingsWholesalerOverview } from '../../../_models/admin/settings/settings.wholesaler.overview';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-wholesaler-overview',
  templateUrl: './admin-wholesaler-overview.component.html',
  styleUrls: ['./admin-wholesaler-overview.component.scss']
})
export class AdminWholesalerOverviewComponent implements OnInit {
  settings: SettingsWholesalerOverview;
  filterString: string;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getLatestVersionDate(backendInfo: string): Date {
    let latest = new Date(0);
    if (this.settings?.WholesalerInfos) {
      this.settings.WholesalerInfos.forEach(info => {
        if (info[backendInfo]) {
          info[backendInfo].forEach(bi => {
            if (bi.VersionDate > latest) latest = bi.VersionDate;  
          });
        }
      });
    }
    return latest;
  }

  getLatestVersion(backendInfo: string): string {
    let latest = '';
    if (this.settings?.WholesalerInfos) {
      this.settings.WholesalerInfos.forEach(info => {
        if (info[backendInfo]) {
          info[backendInfo].forEach(bi => {
            if (bi.Version > latest) latest = bi.Version;  
          });
        }
      });
    }
    return latest;
  }

}
