import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCartItem } from '../../../../_models/cart/shopping.cart.item';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { Aanbieding } from '../../../../_models/promotions/aanbieding';
import { PromotionService } from '../../../../_services/promotion.service';

@Component({
  selector: 'app-star-promotion',
  templateUrl: './star-promotion.component.html',
  styleUrls: ['./star-promotion.component.scss']
})
export class StarPromotionComponent implements OnInit {
  @Input() promotion: Aanbieding;
  _cartItems: { [key: number]: ShoppingCartItem };
  get cartItems(): { [key: number]: ShoppingCartItem } { return this._cartItems; }
  @Input('cartItems')
  set cartItems(value: { [key: number]: ShoppingCartItem }) {
    this._cartItems = value;
    this.itemsPrice = this.promotionService.getPrice(this.cartItems, this.promotion);
  }
  @Input() css: string;
  shopKind = ShopSoort;
  itemsPrice = 0;

  constructor(
    public promotionService: PromotionService
  ) { }

  ngOnInit() {
  }

}
