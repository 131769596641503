<div class="modal-header center">
  <h3 class="modal-title" (contextmenu)="mainService.showObject($event, editInterfaceData)"><span *ngIf="!addNew">Wijzigen</span><span *ngIf="addNew">Toevoegen</span> koppeling</h3>
</div>
<div class="modal-body text-bg-light">
  <div
    *ngIf="!adminSupplierInterfaceService.isUnique(supplierInterfaceData.InterfaceData,interfaceData,editInterfaceData)"
    class="alert alert-danger" role="alert">
    <i class="fas fa-exclamation-triangle"></i> <span class="m-1"><strong>Let op!</strong></span><br />
    <div class="mt-1">Er bestaat al een
      '{{adminSupplierInterfaceService.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos,
      editInterfaceData.IntType).Name}}' koppeling van
      '{{adminSupplierInterfaceService.getSupplierName(supplierInterfaceData.SupplierData,
      editInterfaceData.Supplier)}}' voor '{{adminSupplierInterfaceService.getBranch(supplierInterfaceData.Branches,
        interfaceData.Branch).BranchName}}'</div>
  </div>
  <app-admin-checkbox  [labelCols]="4" [dividerKind]="1" [label]="'Actief'" [model]="editInterfaceData.IntType>0" (modelChanged)="editInterfaceData.IntType = editInterfaceData.IntType * -1;"></app-admin-checkbox>
  <app-admin-dropdown [labelCols]="4" [dividerKind]="1" [label]="'Filiaal'" [(model)]="editInterfaceData.Branch"
    [modelValues]="branchModelValues" [modelValueProperty]="'BranchNumber'"
    [modelDisplayProperty]="'BranchName'"></app-admin-dropdown>
  <app-admin-number-input [labelCols]="4" [dividerKind]="1" [label]="'Type'" [(model)]="editInterfaceData.IntType"
    [allowNegative]="true" (modelChange)="kindInfo = adminSupplierInterfaceService.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, editInterfaceData.IntType);">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
      aria-expanded="false">{{getInterfaceKindName(editInterfaceData.IntType)}}</button>
    <div class="dropdown-menu interface-kind-list">
      <button *ngFor="let interfaceKind of interfaceKinds" class="dropdown-item" type="button" (click)="kindChange(interfaceKind)">{{interfaceKind.Name}}</button>
    </div>
  </app-admin-number-input>
  <app-admin-supplier-input [labelCols]="4" [label]="'Leverancier'" [dividerKind]="1" [(model)]="editInterfaceData.Supplier" [noneSelectionLabel]="'Onbekend'" (modelObjectChange)="supplierChange($event)"></app-admin-supplier-input>
  <app-admin-input [labelCols]="4" [label]="'Url'" [dividerKind]="1" [(model)]="editInterfaceData.Url" [placeholder]="kindInfo.HintUrl"></app-admin-input>
  <app-admin-input [labelCols]="4" [label]="'Username'" [dividerKind]="1" [(model)]="editInterfaceData.Username" [placeholder]="kindInfo.HintUsername"></app-admin-input>
  <app-admin-input [labelCols]="4" [label]="'Password'" [dividerKind]="1" [(model)]="editInterfaceData.Password" [placeholder]="kindInfo.HintPassword"></app-admin-input>
  <app-admin-input [labelCols]="4" [label]="'Klantcode'" [dividerKind]="1" [(model)]="editInterfaceData.CustomerCode" [placeholder]="kindInfo.HintCustomerCode"></app-admin-input>
  <app-admin-input [labelCols]="4" [label]="'Leveranciercode'" [dividerKind]="1" [(model)]="editInterfaceData.SupplierCode" [placeholder]="kindInfo.HintSupplierCode"></app-admin-input>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary"
    [ngClass]="{'disabled': !adminSupplierInterfaceService.isUnique(supplierInterfaceData.InterfaceData,interfaceData,editInterfaceData)}"
    (click)="confirm()"><i class="fas fa-check"></i> Opslaan</button>
</div>