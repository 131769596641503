import { Component, OnInit } from '@angular/core';

import { MainService } from '../../_services/main.service';
import { CatalogService } from '../../_services/catalog.service';
import { ShopService } from '../../_services/shop.service';
import { ItemInfoService } from '../../_services/item-info.service';
import { ActivatedRoute } from '@angular/router';
import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { ItemInfoRequest } from '../../_models/item-info/item.info.request';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss']
})
export class ItemInfoComponent implements OnInit {
  ctx: ContextCatalog;

  constructor(
    private route: ActivatedRoute,
    public mainService: MainService,
    public shopService: ShopService,
    public catalogService: CatalogService,
    public itemInfoService: ItemInfoService
  ) { }

  ngOnInit() {
    this.itemInfoService.getContextItemInfo().subscribe(ctx => {
      if (!this.ctx) { this.ctx = ctx; }
    });
    this.route.data.subscribe((data: any) => {
      if (data.itemResult) { this.ctx = data.itemResult; }
    });
  }



}
