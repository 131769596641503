import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { CarTypeService } from '../../_services/car-type.service';
import { ContextRimsAndTyres } from '../../_models/rims-and-tyres/context.rims.and.tyres';
import { RimAndTyreService } from '../../_services/rim-and-tyre.service';

@Injectable({
  providedIn: 'root'
})
export class RimsDataResolverService  {

  constructor(
    private carTypeService: CarTypeService,
    private rimAndTyreService: RimAndTyreService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextRimsAndTyres> | Observable<never> {
    const carTypeId = route.paramMap.get('carTypeId');
    const carType = this.carTypeService.currentCarType;

    return this.rimAndTyreService.getContextRimsAndTyres(carType).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          return EMPTY;
        }
      })
    );

  }

}
