<div *ngIf="enabled" class="containder-fluid callback-modal-background">
    <div class="row justify-content-center align-items-center h-100">
        <div class="col col-sm-6 col-md-6 col-lg-5 col-xl-4">
            <div class="card callback-card">
                <div class="card-body">
                    <span>
                        <strong>{{title}}</strong>
                        <br />
                      </span>
                    <span *ngIf="description">{{description}}<br /></span>
                    <br />
                    <div class="progress" role="progressbar" aria-label="Animated striped example" [attr.aria-valuenow]="progressValue" aria-valuemin="0" [attr.aria-valuemax]="progressMax">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 75%"></div>
                      </div>
                    <br />
                    <span>
                        <em>{{currentActivity}}</em>
                      </span>
                </div>
            </div>
        </div>
    </div>
</div>