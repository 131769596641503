<div class="header content-header"
  [ngStyle]="{'background-image': 'url('+settingsService.getSetting('DefaultNavigationTemplateBackgroundImage')+')','background-color': settingsService.getSetting('DefaultNavigationTemplateBackgroundColor')}">
  <app-container>
    <div class="card" [ngClass]="'border-'+ settingsService.getSetting('WebshopCustomerBarCssClass')">
      <div class="card-body p-2 customer-bar"
        [ngClass]="['bg-'+ settingsService.getSetting('WebshopCustomerBarCssClass'), settingsService.getSetting('WebshopCustomerBarTextCssClass')]">
        <span class="ps-2 customer-bar-text">
          Welkom {{ctx.CompanyInfo.CustomerName}}, <span class="small"
            title="Klantnr:{{ctx.CompanyInfo.CustomerNumber}} UserID:{{ctx.CompanyInfo.UserID}} Session:{{authService.currentLogon.Session}} Gebruiker:{{authService.currentLogon.Login}}">
            u bent ingelogd bij {{ctx.CompanyInfo.BranchName}}</span>
        </span>
        <div class="btn-group float-end">
          <button *ngIf="settingsService.getSettingBoolean('LoyaltyShopActive')" class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')"
            [tooltip]="settingsService.getSetting('NameOfPointsLoyaltyShop')" placement="top" container="body"
            [routerLink]="getLoyaltyShopLink()">
            <i class="far fa-gift"></i>
            <span class="ms-1 badge text-bg-dark">{{cartService.getLoyaltyPoints()}}</span></button>
          <button class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')" [tooltip]="mailboxService.messageCountText()" placement="top"
            container="body">
            <span *ngIf="!mailboxService.messageCount"><i class="far fa-envelope"></i></span>
            <span *ngIf="mailboxService.messageCount"><i class="far fa-envelope-open-text"></i></span>
            <span *ngIf="mailboxService.messageCount"
              class="ms-1 badge text-bg-dark">{{mailboxService.messageCount}}</span>
          </button>
          <button *ngIf="!settingsService.getSettingBoolean('CartInHeaderInsteadOfNavigationTemplate')"
            class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')" tooltip="Winkelwagen" placement="top" container="body" [routerLink]="['/cart']">
            <span *ngIf="cartService.isCartEmpty()"><i class="far fa-shopping-cart"></i></span>
            <span *ngIf="!cartService.isCartEmpty()"><i class="fas fa-shopping-cart"></i></span>
            <span *ngIf="!cartService.isCartEmpty()"
              class="ms-1 badge text-bg-dark">{{cartService.getCartItemCount()}}</span></button>
          <button class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')" tooltip="Gebruikers instellingen" placement="top" container="body"
            [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i
              class="far fa-user-cog"></i></button>
          <button *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1"
            tooltip="Login als een andere gebruiker" placement="top" container="body" (click)="authService.switchUser()"
            class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')"><i class="far fa-users"></i></button>
          <button *ngIf="!ctx.CompanyInfo.LogoutDisabled" tooltip="Uitloggen" placement="top" container="body"
            (click)="authService.gotoLogout()" class="btn" [ngClass]="'btn-'+ settingsService.getSetting('WebshopCustomerBarCssClass')"><i class="far fa-sign-out-alt"></i></button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg mb-3">
        <app-car-type-search [ctx]="ctx" [licensePlate]="licensePlate" (carTypeSelected)="carTypeSelected($event)"
          (gotFocus)="switchToCatalog()">
        </app-car-type-search>
      </div>
      <div class="col-lg">
        <div *ngIf="shopService.containsShopModule(ctx, shopSoort.Banden)">
          <app-tyre-search-input (gotFocus)="switchToTyres()"></app-tyre-search-input>
        </div>
        <div class="mt-1">
          <app-item-search-input [useTypeahead]="settingsService.getSettingBoolean('ItemInfoWithTypeahead')"
            [useSearchInDescriptionCheckbox]="settingsService.getSettingBoolean('UseSearchInDescriptionCheckbox')"
            [useSeperateInputs]="settingsService.getSettingBoolean('UseSeperateInputsForItemInfo')">
          </app-item-search-input>
        </div>
      </div>
    </div>
  </app-container>
</div>