import { ShopSoort } from './shop.soort';

export class ShopModuleMenuItem {
    Id: number;
    Description: string;
    Link: string;
    CssClass: string;
    ShopKind: ShopSoort;
    ParentId: number;
    MenuItems: ShopModuleMenuItem[];
}
