import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';

@Component({
  selector: 'app-extra-password-webshop',
  templateUrl: './extra-password-webshop.component.html',
  styleUrls: ['./extra-password-webshop.component.scss']
})
export class ExtraPasswordWebshopComponent implements OnInit {

  @Output() tryPassword: EventEmitter<any> = new EventEmitter();

  constructor(
    public mainService: MainService,
    public sortService: SortService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
  }

  checkPassword() {
    this.tryPassword.emit(null);
  }

}
