import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { flatMap, startWith } from 'rxjs/operators';

import { MainService } from '../../../_services/main.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { OnlineSessions } from '../../../_models/admin/statistics/online.sessions';
import { OnlineSession } from '../../../_models/admin/statistics/online.session';
import { SessionTotals } from '../../../_models/admin/statistics/session.totals';
import { ContextAdmin } from '../../../_models/admin/context.admin';
import { LicensePlateService } from '../../../_services/license-plate.service';

@Component({
  selector: 'app-admin-usage-online',
  templateUrl: './admin-usage-online.component.html',
  styleUrls: ['./admin-usage-online.component.scss']
})
export class AdminUsageOnlineComponent implements OnInit, OnDestroy {
  ctx: ContextAdmin;
  onlineSessions: OnlineSessions;
  sessions: OnlineSession[];
  totals: SessionTotals;
  wholesalerNameTotals: { [key: string]: number }
  frontendVersionTotals: { [key: string]: number };
  environmentTotals: { [key: string]: number };
  thirdPartyCustomerNameTotals: { [key: string]: number };
  platformTotals: { [key: string]: number };
  browserTotals: { [key: string]: number };
  intervalSubscription: Subscription;
  filterString: string;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public licensePlateService: LicensePlateService
  ) { }

  ngOnInit() {
    this.intervalSubscription = interval(15 * 1000)
      .pipe(
        startWith(0),
        flatMap(() => this.adminService.getOnlineSessions(20))
      )
      .subscribe((sessions: OnlineSessions) => {
        this.onlineSessions = sessions;
        this.sessions = sessions.Sessions;
        this.totals = sessions.Totals;
        this.wholesalerNameTotals = this.getPropertyTotals(this.sessions, ['WholesalerName']);
        this.frontendVersionTotals = this.getPropertyTotals(this.sessions, ['FrontendVersion']);
        this.environmentTotals = this.getPropertyTotals(this.sessions, ['Environment']);
        this.thirdPartyCustomerNameTotals = this.getPropertyTotals(this.sessions, ['ThirdPartyCustomerName'], 'Losse browser');
        this.platformTotals = this.getPropertyTotals(this.sessions, ['Platform', 'PlatformVersion']);
        this.browserTotals = this.getPropertyTotals(this.sessions, ['Browser']);
      });
    this.adminService.getContext()
      .subscribe((ctx: ContextAdmin) => {
        this.ctx = ctx;
      });
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  showBackend(session: OnlineSession) {
    // this.adminService.getBackendUsers(session.Wholesaler, session.CustomerNumber, session.UserID)
    //   .subscribe(data => {
    //     if (data && data.length > 0) {
    //       this.mainService.showObject(null, data);
    //     } else {
    //       this.mainService.msgBox('Geen data!', 'Is dit wel de juiste backend?');
    //     }
    //   });
  }

  getPropertyTotals(sessions: OnlineSession[], property: string[], rest: string = null) {
    const propTotals: { [key: string]: number } = {};
    const keys = property;
    sessions.forEach(session => {
      let group = '';
      keys.forEach(key => {
        group += session[key] + ' ';
      });
      group = group.trim();
      if (!group && rest) { group = rest; }
      if (group) {
        if (!(propTotals[group] > 0)) { propTotals[group] = 0; }
        propTotals[group]++;
      }
    });
    return propTotals;
  }

  isDayOffset(someDate: Date, offset: number) {
    const offsetDay = new Date();
    if (offset) { offsetDay.setDate(offsetDay.getDate() + offset); }
    return someDate.getDate() === offsetDay.getDate() &&
      someDate.getMonth() === offsetDay.getMonth() &&
      someDate.getFullYear() === offsetDay.getFullYear();
  }

  getTotals(totals: SessionTotals, id: number): SessionTotals {
    if (totals && totals.Totals && totals.Totals[id]) { return totals.Totals[id]; }
    return null;
  }

  getTotalsWholesaler(session: OnlineSession): SessionTotals {
    return this.getTotals(this.totals, session.Wholesaler);
  }

  getTotalsWholesalerCount(session: OnlineSession): number {
    const totals = this.getTotalsWholesaler(session);
    if (totals) { return totals.Count; }
    return 0;
  }


  getTotalsBranch(session: OnlineSession): SessionTotals {
    return this.getTotals(this.getTotalsWholesaler(session), session.BranchNumber);
  }

  getTotalsBranchCount(session: OnlineSession): number {
    const totals = this.getTotalsBranch(session);
    if (totals) { return totals.Count; }
    return 0;
  }

  getTotalsCustomer(session: OnlineSession): SessionTotals {
    return this.getTotals(this.getTotalsBranch(session), session.CustomerNumber);
  }

  getTotalsCustomerCount(session: OnlineSession): number {
    const totals = this.getTotalsCustomer(session);
    if (totals) { return totals.Count; }
    return 0;
  }

  getTotalsUserID(session: OnlineSession): SessionTotals {
    return this.getTotals(this.getTotalsCustomer(session), session.UserID);
  }

  getTotalsUserIDCount(session: OnlineSession): number {
    const totals = this.getTotalsUserID(session);
    if (totals) { return totals.Count; }
    return 0;
  }

  getTotalsIPs(session: OnlineSession): number {
    const totals = this.getTotalsCustomer(session);
    if (totals) { return totals.IPs.length; }
    return 0;
  }

  getHostnames(session: OnlineSession): string[] {
    const totals = this.getTotalsCustomer(session);
    if (totals) { return totals.Hostnames; }
    return null;
  }

  // getTotalsFrontendVersion(session: OnlineSession): SessionTotals {
  //   return this.getTotals(this.totals, session.FrontendVersion);
  // }

  // getTotalsCustomerCount(session: OnlineSession): number {
  //   const totals = this.getTotalsCustomer(session);
  //   if (totals) { return totals.Count; }
  //   return 0;
  // }

}
