import { Component, Input, OnInit } from '@angular/core';
import { Animations } from '../../../../_animations/animations';

@Component({
  selector: 'app-side-panel-body',
  templateUrl: './side-panel-body.component.html',
  styleUrls: ['./side-panel-body.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class SidePanelBodyComponent implements OnInit {
  @Input() collapsed = false;

  constructor() { }

  ngOnInit() {
  }

}
