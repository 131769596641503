import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { MainService } from '../../../_services/main.service';
import { PromotionService } from '../../../_services/promotion.service';


@Component({
  selector: 'app-promotions-overview',
  templateUrl: './promotions-overview.component.html',
  styleUrls: ['./promotions-overview.component.scss']
})
export class PromotionsOverviewComponent implements OnInit, OnDestroy {
  ctx: ContextPromotions;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private promotionService: PromotionService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
        this.promotionService.startImageChanging(data.ctx);
      });
  }

  ngOnDestroy() {
    if (this.promotionService && this.promotionService.imageChangingSubscription) {
      this.promotionService.imageChangingSubscription.unsubscribe();
    }
  }


}
