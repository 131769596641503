export class NamesCacheObject {
  wholesalers: {
    [key: number]: {
      wholesalerName: string,
      branches: { [key: number]: { branchName: string } },
      customers: {
        [key: number]: {
          customerName: string,
          branch: number,
          users: { [key: number]: { userName: string } }
        }
      }
    }
  };

}
