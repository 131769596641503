export class TyreSize {
    Width: number;
    AspectRatio: number;
    Diameter: number;

    getFullDiameter() {
        return (this.Diameter * 2.54) + ((this.Width / 10) * (this.AspectRatio / 100)) * 2;
    }

    getCircumFerence() {
        return this.getFullDiameter() * Math.PI;
    }

    static getFromString(size: string): TyreSize {
        if (size) {
            const maat = size.toUpperCase();
            if (maat && maat.indexOf('/') > 0 && maat.indexOf('R') > 0) {
                let data = maat.replace(' ', '').toUpperCase().split('/');
                if (data.length === 2) {
                    const t = new TyreSize();
                    t.Width = Number(data[0]);
                    data = data[1].split('R');
                    if (data.length === 2) {
                        t.AspectRatio = Number(data[0]);
                        t.Diameter = Number((data[1] + '  ').substring(0, 2));
                        return t;
                    }
                }
            } else if (maat) {
                let transformedInput = size.replace(/[^0-9]/g, '');
                const t = new TyreSize();
                if (transformedInput.length > 5) {
                    t.Diameter = Number(transformedInput.substring(5));
                    if (t.Diameter > 24) { t.Diameter = 24; }
                    transformedInput = transformedInput.substring(0, 5);
                }
                if (transformedInput.length > 3) {
                    t.AspectRatio = Number(transformedInput.substring(3));
                    if (t.AspectRatio > 90) { t.AspectRatio = 90; }
                    transformedInput = transformedInput.substring(0, 3);
                }
                if (transformedInput.length > 0) {
                    t.Width = Number(transformedInput);
                    if (t.Width > 345) { t.Width = 345; }
                }
                return t;
            }
        }
        return null;
    }

    toBareString() {
        let result = '';
        if (this.Width) {
            result = this.Width.toString();
            if (this.AspectRatio) {
                result += this.AspectRatio;
                if (this.Diameter) {
                    result += this.Diameter;
                }
            }
        }
        return result;
    }

    public toString() {
        let result = '';
        if (this.Width) {
            result = this.Width.toString();
            if (this.AspectRatio) {
                result += '/' + this.AspectRatio;
                if (this.Diameter) {
                    result += 'R' + this.Diameter;
                }
            }
        }
        return result;
    }
}
