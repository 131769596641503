<app-admin-form [title]="'Beschikbaarheid en prijs instellingen'" [object]="settings">
  <app-admin-subtitle [title]="'Wanneer er geen eigen voorraad is, laat dan geen prijs zien bij onderstaande leveranciers.'" [dividerKind]="1"></app-admin-subtitle>
  <app-admin-custom-control *ngIf="getSuppliers('ShowPriceOnlyWhenOnStockForTheseSuppliers')" [dividerKind]="1">
    <table class="table table-striped table-responsive small">
      <thead>
        <tr>
          <th class="text-end">Leveranciernr</th>
          <th>Naam</th>
          <th>Plaats</th>
          <th class="text-end">Aktie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let supplier of getSuppliers('ShowPriceOnlyWhenOnStockForTheseSuppliers')">
          <td class="text-end">{{supplier.SupplierNumber}}</td>
          <td>{{supplier.Name}}</td>
          <td>{{supplier.City}}</td>
          <td class="text-end">
            <button class="btn btn-xs btn-outline-secondary" (click)="deleteSupplier('ShowPriceOnlyWhenOnStockForTheseSuppliers', supplier)"><i
                class="fas fa-trash-alt"></i>
              Verwijder</button>
          </td>
        </tr>
      </tbody>
    </table>
  </app-admin-custom-control>
  <app-admin-typeahead-supplier-search [label]="'Leverancier toevoegen'" [wholesaler]="adminService.currentUid.Wholesaler" (selected)="addSupplier('ShowPriceOnlyWhenOnStockForTheseSuppliers', $event)" [emptyAfterSelected]="true">
  </app-admin-typeahead-supplier-search>
  <app-admin-subtitle [title]="'Verberg de voorraad voor onderstaande leveranciers.'" [dividerKind]="1"></app-admin-subtitle>
  <app-admin-custom-control *ngIf="getSuppliers('HideStockForSuppliers')" [dividerKind]="1">
    <table class="table table-striped table-responsive small">
      <thead>
        <tr>
          <th class="text-end">Leveranciernr</th>
          <th>Naam</th>
          <th>Plaats</th>
          <th class="text-end">Aktie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let supplier of getSuppliers('HideStockForSuppliers')">
          <td class="text-end">{{supplier.SupplierNumber}}</td>
          <td>{{supplier.Name}}</td>
          <td>{{supplier.City}}</td>
          <td class="text-end">
            <button class="btn btn-xs btn-outline-secondary" (click)="deleteSupplier('HideStockForSuppliers', supplier)"><i
                class="fas fa-trash-alt"></i>
              Verwijder</button>
          </td>
        </tr>
      </tbody>
    </table>
  </app-admin-custom-control>
  <app-admin-typeahead-supplier-search [label]="'Leverancier toevoegen'" [wholesaler]="adminService.currentUid.Wholesaler" (selected)="addSupplier('HideStockForSuppliers', $event)" [emptyAfterSelected]="true">
  </app-admin-typeahead-supplier-search>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsExceptionsAvailabilityAndPrice(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>