import { Component, OnInit, Input } from '@angular/core';

import { ContextRimsAndTyres } from '../../../_models/rims-and-tyres/context.rims.and.tyres';
import { Wheel } from '../../../_models/rims-and-tyres/wheel';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-wheel-list-item',
  templateUrl: './wheel-list-item.component.html',
  styleUrls: ['./wheel-list-item.component.scss']
})
export class WheelListItemComponent implements OnInit {
  @Input() ctx: ContextRimsAndTyres;
  @Input() wheel: Wheel;
  @Input() odd: boolean;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public rimAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
  }

}
