import { Directive, ElementRef, HostListener, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
  @Input() decimals = 0;
  @Input() allowNegative = false;
  @Input() format = false;
  @Output() ngModelChange = new EventEmitter();

  @HostListener('click') onClick() {
    (this.elementRef.nativeElement as HTMLInputElement).select();
    console.info('click => selected');
  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event) {
    const oldValue = this.elementRef.nativeElement.value.replace(',', '.');
    const startPosition = this.elementRef.nativeElement.selectionStart;
    const endPosition = this.elementRef.nativeElement.selectionEnd;
    let newValue = oldValue;
    const newChar = String.fromCharCode(event.charCode).replace(',', '.');
    if (startPosition === oldValue.length) {
      newValue += newChar;
    } else {
      newValue = oldValue.substring(0, startPosition) + newChar + oldValue.substring(endPosition, oldValue.length);
    }
    event.preventDefault();

    if (!this.check(newValue)) { newValue = oldValue; }

    if (this.elementRef.nativeElement.value !== newValue) {
      this.elementRef.nativeElement.value = newValue;
      if (this.elementRef.nativeElement.setSelectionRange) {
        this.elementRef.nativeElement.setSelectionRange(endPosition + 1, endPosition + 1);
      }
      this.ngModelChange.emit(this.elementRef.nativeElement.value);
    }
  }

  @HostListener('focus', ['$event'])
  handleFocus(event) {
    if (this.format) {
      while (this.elementRef.nativeElement.value.includes('.')
        && (this.elementRef.nativeElement.value[this.elementRef.nativeElement.value.length - 1] === '0'
          || this.elementRef.nativeElement.value[this.elementRef.nativeElement.value.length - 1] === '.')) {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.slice(0, -1);
      }
    }
  }

  @HostListener('blur', ['$event'])
  handleBlur(event) {
    this.formatInput();
  }

  constructor(
    private elementRef: ElementRef
  ) {
    setTimeout(() => this.formatInput(), 10);
  }


  private check(value: string) {
    const minus = this.allowNegative ? '-?' : '';
    if (this.decimals <= 0) {
      return String(value).match(new RegExp('^' + minus + '\\d+$'));
    } else {
      const regExpString =
        '^' + minus + '\\s*((\\d+(\\.\\d{0,' +
        this.decimals +
        '})?)|((\\d*(\\.\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  formatInput() {
    if (this.format) {
      if (!this.elementRef.nativeElement.value.includes('.')) {
        this.elementRef.nativeElement.value += '.00';
      } else {
        const index = this.elementRef.nativeElement.value.indexOf('.');
        this.elementRef.nativeElement.value = (this.elementRef.nativeElement.value + '00').substring(0, index + 3);
      }
    }
  }
}
