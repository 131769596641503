import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCount'
})
export class FilterCountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    } else if (typeof args === 'object') {
      args.object[args.key] = value.length;
      return value;
    } else {
      return value;
    }
  }

}
