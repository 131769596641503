<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="customerInformationService.requestDeliveryNote" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true">Pakbonnen</li>
    </app-breadcrumb-header>

    <div *ngIf="!customerInformationService.showPasswordPrompt">
      <div class="row">
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Selectie</label>
            <div class="col-8">
              <select class="form-control form-control-sm" [ngModel]="customerInformationService.selectionDeliveryNote"
                (ngModelChange)="customerInformationService.setDates(customerInformationService.requestDeliveryNote, $event);customerInformationService.selectionDeliveryNote = $event;">
                <option [ngValue]="choice" *ngFor="let choice of customerInformationService.dateChoicesDeliveryNote">
                  {{choice.Description}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum van</label>
            <div class="col-8">
              <input type="text" placeholder="datum van" [(ngModel)]="customerInformationService.requestDeliveryNote.DateFrom"
                (ngModelChange)="customerInformationService.selectionDeliveryNote = customerInformationService.getSelection(customerInformationService.requestDeliveryNote, customerInformationService.dateChoicesDeliveryNote)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionDeliveryNote.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum tot</label>
            <div class="col-8">
              <input type="text" placeholder="datum tot" [(ngModel)]="customerInformationService.requestDeliveryNote.DateTo"
                (ngModelChange)="customerInformationService.selectionDeliveryNote = customerInformationService.getSelection(customerInformationService.requestDeliveryNote, customerInformationService.dateChoicesDeliveryNote)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionDeliveryNote.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Pakbonnr</label>
            <div class="col-8">
              <input type="number" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestDeliveryNote.DeliveryNoteNumber"
                maxlength="9" max="999999999">
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Factuurnr</label>
            <div class="col-8">
              <input type="number" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestDeliveryNote.InvoiceNumber" maxlength="9"
                max="999999999">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Artikelnr</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestDeliveryNote.ArticleNumber" maxlength="50">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Referentie</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestDeliveryNote.Reference" maxlength="200">
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group row">
            <div class="col">
              <button type="button" class="btn btn-sm w-100 btn-primary" (click)="search()"><i class="fas fa-search"></i>
                Zoeken</button>
            </div>
          </div>
        </div>
      </div>

      <app-grid-webshop contentHeight="100%" [items]="sortService.sortObject('delivery-notes', headers)"
        emptyText="Geen pakbonnen gevonden." class="flexbox-grow flexbox-dumpster mt-3" [isBusy]="isBusy">
        <ng-container grid-header>
          <div class="col-2 no-wrapping">
            <app-sort-field [sortObjectId]="'delivery-notes'" [sortField]="'DeliveryNoteNumber'">Pakbonnr
            </app-sort-field>
          </div>
          <div class="col-2 no-wrapping">
            <app-sort-field [sortObjectId]="'delivery-notes'" [sortField]="'DeliveryNoteDate'">Pakbondatum
            </app-sort-field>
          </div>
          <div class="col-7 no-wrapping">
            <app-sort-field [sortObjectId]="'delivery-notes'" [sortField]="'CustomerReference'">Referentie
            </app-sort-field>
          </div>
          <div class="col-1 no-wrapping text-center">PDF</div>
        </ng-container>
        <ng-template let-item>
          <div class="col-2">{{item.DeliveryNoteNumber | leadZeros:8}}</div>
          <div class="col-2">{{item.DeliveryNoteDate | date : 'dd-MM-yyyy'}}</div>
          <div class="col-7">{{item.CustomerRemarks}}</div>
          <div class="col-1 text-center">
            <span *ngIf="customerInformationService.containsPdf(item)" class="text-danger pointer" (click)="customerInformationService.showDeliveryNotePdf(item)"><i
                class="fas fa-file-pdf"></i></span>
          </div>
        </ng-template>
      </app-grid-webshop>
    </div>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="search()"></app-extra-password-webshop>
    </div>

  </div>
</div>