import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';
import { UniversalCarModel } from '../../_models/car-types/universal.car.model';

@Component({
  selector: 'app-car-model-list',
  templateUrl: './car-model-list.component.html',
  styleUrls: ['./car-model-list.component.scss']
})
export class CarModelListComponent implements OnInit {
  carModel: UniversalCarModel;
  catalog: string;
  filterString: string;
  onClose: Subject<UniversalCarModel>;

  constructor(
    private bsModalRef: BsModalRef,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.onClose = new Subject<UniversalCarModel>();
  }

  getTitle() {
    if (this.carModel && this.carModel.Brand) {
      return `Kies een model ${this.carModel.Brand.BrandName}.`;
    }
    return 'Kies een model.';
  }

  choose(item) {
    if (item) {
      this.carModel.CatalogCarModels[this.catalog] = [item];
      this.onClose.next(this.carModel);
    } else {
      this.onClose.next(null);
    }
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
