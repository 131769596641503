import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BranchModel } from 'app/_models/common/branch.model';
import { AdminService } from 'app/_services/admin/admin.service';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-admin-branch-input',
  templateUrl: './admin-branch-input.component.html',
  styleUrl: './admin-branch-input.component.scss'
})
export class AdminBranchInputComponent {
  @Input() label: string;
  @Input() centerLabel: boolean;
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelObject: BranchModel;
  @Output() modelObjectChange: EventEmitter<BranchModel> = new EventEmitter<BranchModel>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() wholesaler: number = 0;
  @Output() oneBranchChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  branches: { [key: string]: BranchModel };
  oneBranch: boolean = false;

  constructor(
    public adminService: AdminService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
    if (this.noneSelectionLabel && !this.noneSelectionModelValue) { this.noneSelectionModelValue = -1; }
    if (this.model !== this.noneSelectionModelValue) { this.getObjectModel(this.model); }
    if (this.wholesaler === 0) this.wholesaler = this.adminService.currentUid.Wholesaler;
    this.getBranches(this.wholesaler);
  }

  getBranches(wholesaler: number) {
    this.adminService.getBranches(wholesaler)
      .subscribe(branches => {
        if (branches) {
          this.branches = Object.fromEntries(branches.map(branch => [branch.BranchNumber.toString(), branch]));
          this.oneBranch = (branches.length === 1);
          this.oneBranchChange.emit(this.oneBranch);
        } else {
          console.info(`Geen filialen ontvangen voor ${wholesaler}`);
        }
      });
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModel(model: number) {
    if (model && this.branches && this.branches[model]) {
      const result = this.branches[model];
      if (this.model !== result.BranchNumber) {
        this.model = result.BranchNumber;
        this.modelChange.emit(this.model);
      }
      if (!this.modelObject || this.modelObject.BranchNumber !== result.BranchNumber) {
        this.modelObject = result;
        this.modelObjectChange.emit(this.modelObject);
      }
    }
  }

  objectChange(value: BranchModel) {
    if (value.BranchNumber !== this.model) { this.getObjectModel(value.BranchNumber); }
  }

  clear(event: MouseEvent) {
    if (!this.disabled) {
      this.model = this.noneSelectionModelValue;
      this.modelObject = null;
      this.modelChange.emit(this.model);
      this.modelObjectChange.emit(this.modelObject);
    }
    event.stopPropagation();
  }


}
