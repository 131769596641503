import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-checkbox-editor',
  templateUrl: './admin-checkbox-editor.component.html',
  styleUrls: ['./admin-checkbox-editor.component.scss']
})
export class AdminCheckboxEditorComponent implements OnInit {
  @Input() label: string;
  @Input() centerLabel: boolean;
  @Input() invertModel: boolean;
  @Input() modelEnabled: boolean;
  @Output() modelEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modelEditor: any;
  @Output() modelEditorChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
  }

}
