<app-container [renderCheck]="ctx" [shopSoort]="shopSoort.Catalogus">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true">Groepenoverzicht</li>
    <form class="d-flex flex-row align-items-center flex-nowrap ms-auto">
      <span class="input-group">
        <input type="text" name="filter" class="form-control" [(ngModel)]="filterString"
          placeholder="zoek op productsoort">
        <button class="btn btn-light" type="button" (click)="filterString = ''">
          <i class="fas fa-times"></i>
        </button>
        <button *ngIf="ctx.FavouriteCategories" class="btn btn-light" type="button"
          tooltip="{{ctx.FavouritesCollapsed ? 'Laat de afbeeldingen met favorieten zien' : 'Verberg de afbeeldingen'}}"
          placement="bottom" container="body" (click)="clickFavouritesCollapsed(ctx)">
          <span *ngIf="ctx.FavouritesCollapsed"><i class="far fa-star"></i></span>
          <span *ngIf="!ctx.FavouritesCollapsed"><i class="fas fa-star"></i></span>
        </button>
      </span>
      <button *ngIf="shopService.containsShopModule(mainService.ctxMain, shopSoort.Autotools)" class="btn btn-warning ms-2" type="button" [routerLink]="['/'+shopRouteConfig.routePaths[shopSoort.Autotools]]">
        <span class="text-nowrap">{{shopService.getShopModuleName(mainService.ctxMain, shopSoort.Autotools)}}</span>
      </button>
      <button *ngIf="shopService.containsShopModule(mainService.ctxMain, shopSoort.WielenEnBanden)" class="btn btn-warning ms-2" type="button" [routerLink]="['/rims', ctx.CarType.Id]">
        <span class="text-nowrap">{{shopService.getShopModuleName(mainService.ctxMain, shopSoort.WielenEnBanden)}}</span>
      </button>
      <button *ngIf="settingsService.getSettingBoolean('CatalogGroupsScrollLayoutShowAldocButton') && shopService.containsShopModule(mainService.ctxMain, shopSoort.AldocSys)" class="btn btn-warning ms-2" type="button" [routerLink]="['/aldoc']">
        <span class="text-nowrap">{{shopService.getShopModuleName(mainService.ctxMain, shopSoort.AldocSys)}}</span>
      </button>
      <button *ngIf="settingsService.getSettingBoolean('CatalogGroupsScrollLayoutShowHbaseButton') && shopService.containsShopModule(mainService.ctxMain, shopSoort.hbase)" class="btn btn-warning ms-2" type="button" [routerLink]="['/hbase']">
        <span class="text-nowrap">{{shopService.getShopModuleName(mainService.ctxMain, shopSoort.hbase)}}</span>
      </button>
      <button *ngIf="shopService.containsShopModule(mainService.ctxMain, shopSoort.MPM)" class="btn btn-warning ms-2" type="button" [routerLink]="['/'+shopRouteConfig.routePaths[shopSoort.MPM]]">
        <span class="text-nowrap">{{shopService.getShopModuleName(mainService.ctxMain, shopSoort.MPM)}}</span>
      </button>

    </form>
  </app-breadcrumb-header>

  <app-car-type-info-header [ctx]="ctx"></app-car-type-info-header>

  <div class="mt-3">
    <div *ngIf="ctx.FavouriteCategories" [@slideInHorizontal]="ctx.FavouritesCollapsed" class="row favourites-row">
      <div *ngFor="let favourite of ctx.FavouriteCategories" class="col-sm-2 parts-category group-container mb-3">
        <div class="card border pointer" (click)="catalogService.clickCategory($event, ctx.CarType, favourite, null)">
          <img class="card-img-top favourite-image" [src]="'/assets/img/catalog/'+favourite.ImageUrl" />
          <div class="text-center favourite-category-name">{{favourite.CategoryName}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div *ngFor="let mainCategory of ctx.MainCategories | sortByProperty:'SortOrder'" class="col group-container">
      <div class="card border">
        <div class="card-header  text-bg-dark">
          <span class="panel-title">{{mainCategory.CategoryName}}</span>
          <span class="float-end" *ngIf="filterString">({{filterResult.count}} van
            {{mainCategory.SubPartsCategories.length}})</span>
        </div>
        <ul class="list-group list-group-flush">
          <ng-container
            *ngFor="let partsCategory of mainCategory.SubPartsCategories | partsCategoryFilter: filterString | filterCount: { object: filterResult, key: 'count' }; let odd=odd;">
            <li class="list-group-item parts-category-container pointer"
              [ngClass]="{'alt-back': odd, 'pointer': settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')}"
              (click)="clickCategory($event, true, ctx.CarType, partsCategory, null)">
              <div class="parts-category-header">
                <div class="parts-category-collapse-button pe-2">
                  <div
                    *ngIf="!filterString && !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')"
                    (click)="partsCategory['isOpen']=!partsCategory['isOpen']" class="collapse-button float-start"
                    [ngClass]="{'is-collapsed': partsCatIsClosed(partsCategory), 'parts-category-empty': partsCatIsEmpty(partsCategory)}">
                    <i class="fas fa-angle-up"></i>
                  </div>
                </div>
                <div class="parts-category"
                  [ngClass]="{'pointer': !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList'), 'text-uppercase': settingsService.getSettingNumber('CatalogGroupsLetterCase')===1, 'text-lowercase': settingsService.getSettingNumber('CatalogGroupsLetterCase')===2}"
                  (click)="clickCategory($event, true, ctx.CarType, partsCategory, null)">
                  {{partsCategory.CategoryName}}
                </div>
              </div>
              <ul
                *ngIf="(partsCategory['isOpen'] && !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')) || filterString"
                class="parts-category-descriptions-list"
                [@slideInHorizontal]="!partsCategory['isOpen'] && !filterString">
                <li *ngFor="let description of partsCategory.PartsDescriptions | filterString: filterString"
                  class="text-alarm" (click)="catalogService.clickCategory($event, ctx.CarType, partsCategory, description)">
                  {{description}}
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="ctx && !ctx.MainCategories" class="alert alert-warning" role="alert">
    <i class="fas fa-exclamation-triangle"></i>&nbsp;Er missen instellingen voor de catalogus!
  </div>
</app-container>