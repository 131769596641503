<div class="modal-header">
    <div class="row w-100">
        <div class="col">
            <h4 class="modal-title">Overzicht</h4>
        </div>
        <div class="col-md-auto float-end">

        </div>
    </div>
</div>
<div class="modal-body"
    [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
    <div class="row mb-3">
        <div class="col">
            U heeft gekozen voor {{set.RequestItemCount}}x onderstaande velg
            <span *ngIf="!set.CartItemTyre">+ band combinatie </span>voor de {{set.CarType?.Display}}
            <span *ngIf="set.CarType && set.CarType.LicensePlate">met kenteken
                <img class="licenseplate" [src]="set.CarType.LicensePlate.ImageUrl" />
            </span>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-sm-8 kop">
            Gekozen velg:
        </div>
        <div class="col-sm-2 kop text-end">
            Bruto
        </div>
        <div class="col-sm-2 kop text-end">
            Netto
        </div>
    </div>
    <div class="row">
        <div class="col-sm-5">
            <div class="row">
                <div class="col-sm-5 label">
                    Artikelnummer
                </div>
                <div class="col-sm-7 data">
                    {{set.Wheel.PartItemNumber}}
                </div>
                <div class="col-sm-5 label">
                    Merk
                </div>
                <div class="col-sm-7 data">
                    {{set.Wheel.Brand}}
                </div>
                <div class="col-sm-5 label">
                    Omschrijving
                </div>
                <div class="col-sm-7 data">
                    {{set.Wheel.Description}}
                </div>
            </div>
            <div *ngFor="let prop of set.Wheel.Properties['Unknown']" class="row">
                <div class="col-sm-5 label">
                    {{splitProperty(prop.Value)[0]}}
                </div>
                <div class="col-sm-7 data">
                    {{splitProperty(prop.Value)[1]}}
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <img [src]="set.Wheel.Images[0]" />
        </div>
        <div class="col-sm-2 kop text-end">
            {{set.RequestItemCount}}x {{set.CartItemWheel.Brutoprijs | price}}
        </div>
        <div class="col-sm-2 kop text-end">
            {{set.CartItemWheel.Nettoprijs | price}}
        </div>
    </div>
    <div *ngIf="set.CartItemTyre" class="row mb-1">
        <div class="col kop">
            Gekozen band:
        </div>
    </div>
    <div *ngIf="set.CartItemTyre" class="row">
        <div class="col-sm-5">
            <div class="row">
                <div class="col-sm-5 label">
                    Artikelnummer
                </div>
                <div class="col-sm-7 data">
                    {{set.CartItemTyre.Artikelnr}}
                </div>
                <div class="col-sm-5 label">
                    Merk
                </div>
                <div class="col-sm-7 data">
                    {{set.CartItemTyre.ItemInfo.Properties['TyreBrand']}}
                </div>
                <div class="col-sm-5 label">
                    Maat
                </div>
                <div class="col-sm-7 data">
                    {{set.CartItemTyre.ItemInfo.Properties['TyreSize']}}
                </div>
                <div class="col-sm-5 label">
                    Soort
                </div>
                <div class="col-sm-7 data">
                    {{set.CartItemTyre.ItemInfo.Properties['TyreKind']}}
                </div>
                <div class="col-sm-5 label">
                    Omschrijving
                </div>
                <div class="col-sm-7 data">
                    {{set.CartItemTyre.ItemInfo.Description}}
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <img class="tyre-image" [src]="set.CartItemTyre.ItemInfo.Properties['Image']" />
        </div>
        <div class="col-sm-2 kop text-end">
            {{set.RequestItemCount}}x {{set.CartItemTyre.Brutoprijs | price}}
        </div>
        <div class="col-sm-2 kop text-end">
            {{set.CartItemTyre.Nettoprijs | price}}
        </div>
    </div>


    <div class="row mt-3 mb-1">
        <div class="col-sm-8">
            <div class="alert alert-danger" role="alert">
                <div class="row">
                    <div class="col-sm-1 p-2">
                        <i class="fas fa-2x fa-exclamation-triangle"></i>
                    </div>
                    <div class="col-sm-11">
                        <span
                            *ngIf="catalogPropertyService.hasProperty(set.CarType.Properties, catalogPropertyService.propertyKindCatalog.Tpms)">
                            Beschikt uw auto over een direct TPMS systeem, dan kunt u hier sensoren toevoegen aan uw
                            order. Om de TPMS sensoren met de
                            juiste software te configureren hebben we de exacte maand en bouwjaar van uw auto nodig.
                        </span>
                        <span
                            *ngIf="!catalogPropertyService.hasProperty(set.CarType.Properties, catalogPropertyService.propertyKindCatalog.Tpms)">
                            Het is niet mogelijk om de TPMS te configureren voor uw auto. Als u doorgaat met bestellen
                            zonder TPMS sensoren zal uw TPMS
                            systeem niet functioneren.
                        </span>
                    </div>
                </div>
                <div *ngIf="set.Tpms" class="row my-3">
                    <div class="col">
                        <span class="me-3">Selecteer bouw maand / jaar</span>
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <div class="btn-group" role="group">
                                <button id="btnGroupMonth" type="button" class="btn btn-light dropdown-toggle"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{set.BuildMonth === 0 ? 'kies maand' : months[set.BuildMonth]}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnGroupMonth">
                                    <a *ngFor="let month of months; let index = index;" class="dropdown-item"
                                        (click)="set.BuildMonth = index">{{month}}</a>
                                </div>
                            </div>
                            <button type="button" class="btn btn-light" disabled>/</button>
                            <div class="btn-group" role="group">
                                <button id="btnGroupYear" type="button" class="btn btn-light dropdown-toggle"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{set.BuildYear === 0 ? 'kies jaar' : set.BuildYear}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnGroupYear">
                                    <a *ngFor="let year of years" class="dropdown-item"
                                        (click)="set.BuildYear = year">{{year}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="set.CarType['OBDII']" class="row my-3">
                    <div class="col-sm-1 p-2">
                        <i class="fas fa-2x fa-exclamation-triangle"></i>
                    </div>
                    <div class="col-sm-11">Uw auto beschikt over een OBD II systeem. Er is een kans dat u de sensoren
                        moet laten activeren bij uw garage/autodealer.</div>
                </div>
                <div class="row my-3">
                    <div class="col kop">
                        <span class="pointer" (click)="set.Tpms = !set.Tpms">
                            <span *ngIf="set.Tpms" class="me-2"><i class="far fa-square"></i> </span>
                            <span *ngIf="!set.Tpms" class="me-2"><i class="far fa-check-square"></i> </span>
                            Ik wil geen TPMS ventielen.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="set.SelectionItems && set.SelectionItems.length !== 0" class="row mt-3 mb-1">
        <div class="col-sm-6 data">
            Extra
        </div>
        <div class="col-sm-1 text-end data">
            Bruto
        </div>
        <div class="col-sm-1 text-end data">
            Netto
        </div>
        <div class="col-sm-4"></div>
        <ng-container *ngFor="let item of set.SelectionItems">
            <ng-container *ngIf="displayItem(item)">
                <div class="col-sm-5 label">
                    <span class="pointer" (click)="item.Properties['IsSelected'] = !item.Properties['IsSelected']">
                        <span *ngIf="item.Properties['IsSelected']" class="me-2"><i
                                class="far fa-check-square"></i></span>
                        <span *ngIf="!item.Properties['IsSelected']" class="me-2"><i class="far fa-square"></i></span>
                        {{item.Omschrijving}}</span>
                </div>
                <div class="col-sm-1 text-end label" [ngClass]="{'item-disabled': !item.Properties['IsSelected']}">
                    {{selectionItemCount(item)}} x
                </div>
                <div class="col-sm-1 text-end label" [ngClass]="{'item-disabled': !item.Properties['IsSelected']}">
                    {{priceSingleSelectionItem(item, 'GrossPrice') | price}}
                </div>
                <div class="col-sm-1 text-end label" [ngClass]="{'item-disabled': !item.Properties['IsSelected']}">
                    {{priceSingleSelectionItem(item, 'NetPrice') | price}}
                </div>
                <div class="col-sm-2 text-end data">
                    <span *ngIf="false && item.Properties['IsSelected']">{{priceSelectionItem(item, 'GrossPrice') |
                        price}}</span>
                </div>
                <div class="col-sm-2 text-end data">
                    <span *ngIf="false && item.Properties['IsSelected']">{{priceSelectionItem(item, 'NetPrice') |
                        price}}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="set.SelectionItems && set.SelectionItems.length !== 0" class="row my-3">
        <div class="col-sm-8 kop">
            Totaal extra
        </div>
        <div class="col-sm-2 kop text-end">
            {{totalSelectionItems('GrossPrice') | price}}
        </div>
        <div class="col-sm-2 kop text-end">
            {{totalSelectionItems('NetPrice') | price}}
        </div>
    </div>
    <div class="row mt-3 mb-1">
        <div class="col-sm-8 kop">
            Set totaal
        </div>
        <div class="col-sm-2 kop text-end line-top">
            {{totalPrice('GrossPrice') | price}}
        </div>
        <div class="col-sm-2 kop text-end line-top">
            {{totalPrice('NetPrice') | price}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown">Aantal:
        {{set.RequestItemCount}}</button>
    <div class="dropdown-menu">
        <a *ngFor="let count of set.RequestItemCountsPossible" class="dropdown-item"
            (click)="set.RequestItemCount = count">{{count}}</a>
    </div>
    <button type="button" class="btn btn-primary" (click)="confirm()">Bestel nu!</button>
    <button type="button" class="btn btn-warning" (click)="decline()">Terug</button>
</div>