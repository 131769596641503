<div class="card border-primary">
  <div class="card-header text-bg-primary py-1 px-2">
    <span class="panel-title">{{catalogService.getPartsTypeDescription(ctx, partsTypeId, 'artikel')}}</span>
    <span class="float-end close-button" (click)="closeDialog()"><i class="fas fa-times fa-2x"></i></span>
  </div>
  <div class="card-body p-0"
    [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 100) + 'px', 'overflow': 'auto'}">
    <app-catalog-part-detailed
      *ngFor="let part of selection.PartsPerPartsType[partsTypeId] | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.PartsCartItems; let odd=odd;"
      [odd]="odd" [ctx]="ctx" [part]="part" (selected)="choosePart($event)"></app-catalog-part-detailed>
  </div>
</div>