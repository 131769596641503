<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <ul class="list-group">
    <li class="list-group-item typeahead-item" *ngFor="let match of matches"
      [class.active]="typeaheadTemplateMethods.isActive(match)"
      (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
      (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
      <span class="itemnumber">{{match.item.ItemNumber}}</span>
      <span class="ms-1 description">{{match.item.Description}}</span><br />
      <span class="description font-italic">{{match.item.ItemGroupDescription}}</span>
    </li>
  </ul>
</ng-template>
<ng-template #typeahead>
  <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="zoek of cross een artikel..."
    aria-label="Search for..." (keyup.enter)="onEnterKeyTypeahead()" [appSelectOnclick]="true" autocomplete="off"
    [typeahead]="getTypeaheadItem()" [adaptivePosition]="false" [optionsListTemplate]="customListTemplate"
    [typeaheadMinLength]="1" [typeaheadOptionsLimit]="8" (typeaheadOnSelect)="typeaheadOnSelect($event)"
    [typeaheadOptionField]="'ItemNumber'" [typeaheadIsFirstItemActive]="false" [typeaheadSelectFirstItem]="false">
</ng-template>
<ng-template #notypeahead>
  <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="zoek of cross een artikel..."
    aria-label="Search for..." (focus)="hasFocus=true" (blur)="onBlur()"
    (keyup.enter)="router.navigate(['/iteminfo', searchText], { queryParams: itemInfoService.getQueryParams(searchInDescription&&!useSeperateInputs,0) })"
    [appSelectOnclick]="true">
</ng-template>
<div *ngIf="!useSeperateInputs" class="input-group">

  <ng-container *ngIf="useTypeahead">
    <ng-container *ngTemplateOutlet="typeahead"></ng-container>
  </ng-container>

  <ng-container *ngIf="!useTypeahead">
    <ng-container *ngTemplateOutlet="notypeahead"></ng-container>
    <div *ngIf="!useSearchInDescriptionCheckbox" class="dropdown-menu w-100 info-dropdown"
      [ngClass]="{'show': searchText && hasFocus}">
      <div class="px-4 py-1 text-body-tertiary">
        Type een artikelnummer om te crossen en druk op enter.
      </div>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" [routerLink]="['/iteminfo',searchText]"
        [queryParams]="itemInfoService.getQueryParams(true ,0)">Klik hier om te zoeken naar '{{searchText}}' in alle
        omschrijvingen.</a>
    </div>
  </ng-container>

    <button *ngIf="useSearchInDescriptionCheckbox" class="btn btn-primary" type="button" (click)="searchInDescription = !searchInDescription"
      tooltip="Zoek ook in de omschrijvingen" placement="bottom" container="body">
      <span *ngIf="!searchInDescription"><i class="far fa-square"></i></span>
      <span *ngIf="searchInDescription"><i class="far fa-check-square"></i></span>
    </button>

    <button class="btn btn-primary" type="button" [routerLink]="['/iteminfo',searchText]"
      [queryParams]="itemInfoService.getQueryParams(searchInDescription,0)">
      <i class="fas fa-search"></i>
    </button>
</div>
<div *ngIf="useSeperateInputs" class="input-group">
  <ng-container *ngIf="useTypeahead">
    <ng-container *ngTemplateOutlet="typeahead"></ng-container>
  </ng-container>
  <ng-container *ngIf="!useTypeahead">
    <ng-container *ngTemplateOutlet="notypeahead"></ng-container>
  </ng-container>
  <span class="input-group-btn" style="width:0px;"></span>
    <button class="btn btn-primary" type="button" [routerLink]="['/iteminfo',searchText]"
      [queryParams]="itemInfoService.getQueryParams(false,0)">
      <i class="fas fa-search"></i>
    </button>
  <input type="text" class="form-control" [(ngModel)]="searchDescription" placeholder="zoek in omschrijving..."
    (keyup.enter)="router.navigate(['/iteminfo', searchDescription], { queryParams: itemInfoService.getQueryParams(true,0) })"
    [appSelectOnclick]="true" style="margin-left:-2px">
    <button class="btn btn-primary" type="button" [routerLink]="['/iteminfo',searchDescription]"
      [queryParams]="itemInfoService.getQueryParams(true,0)">
      <i class="fas fa-search"></i>
    </button>
</div>