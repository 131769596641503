import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';

import { ShopSoort } from '../../../_models/common/shop.soort';
import { ThirdpartyShopService } from '../../../_services/thirdparty-shop.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { ThirdpartyShopSession } from '../../../_models/thirdparty-shop/thirdparty-shop-session';


@Component({
  selector: 'app-thirdparty-shop-boxed-layout',
  templateUrl: './thirdparty-shop-boxed-layout.component.html',
  styleUrls: ['./thirdparty-shop-boxed-layout.component.scss']
})
export class ThirdpartyShopBoxedLayoutComponent implements OnInit {
  _session: ThirdpartyShopSession;
  get session(): ThirdpartyShopSession {
    return this._session;
  }
  @Input('session')
  set session(value: ThirdpartyShopSession) {
    this._session = value;
    this.initSession('session setter');
  }
  headerEnabled = true;
  container: ElementRef;
  @ViewChild('thirdpartyContainer') set content(element: ElementRef) {
    this.container = element;
    this.initSession('container ViewChild');
  }
  isZoomed = false;

  initSession(from: string) {
    if (this._session && this.container) {
      if (!this._session.container) {
        this._session.container = this.container.nativeElement;
        this.thirdpartyShopService.initFrame(this._session);
        console.info(`Frame initialized from ${from}.`);
      }
    } else if (this._session) {
      console.info(`No container from ${from}!`);
    } else if (this.container) {
      console.info(`No session from ${from}!`);
    }
  }

  constructor(
    public thirdpartyShopService: ThirdpartyShopService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
    if (this.session && this.session.ctx && this.session.ctx.ShopKind === ShopSoort.AldocSys) { this.headerEnabled = false; }
    // begin altijd ingeklapt bij thirdpartyshops
    if (this.session && this.session.ctx) { this.session.ctx['CarTypeExtraDataCollapsed'] = true; }
  }

  netPriceOptionChanged() {
    if (this.thirdpartyShopService.changedOptionsNeedsRefresh(this.session.shopSoort)) {
      this.thirdpartyShopService.refreshFrame(this.session);
    }
  }

}
