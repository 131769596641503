import { ResponseItem } from '../item-info/response.item';
import { ResponseItemPrice } from '../item-info/response.item.price';
import { ShoppingCartItem } from '../cart/shopping.cart.item';

export class AanbiedingArtikel {
    ID: number;
    AanbiedingID: number;
    InternArtikelnr: number;
    Aantal: number;
    Artikelnr: string;
    Artikelgroep: number;
    Omschrijving: string;
    Prijs: ResponseItemPrice;
    ItemInfo: ResponseItem;
    // CartItem: ShoppingCartItem;
}
