<table class="table table-striped table-hover" [ngClass]="{'table-vertical-border': ctx.BandenGridMetVerticaleScheiding}">
  <thead>
      <tr class="text-bg-primary">
          <th class="text-bg-primary text-center"><span *ngIf="!set">Aantal</span></th>
          <th class="text-bg-primary">Artikel</th>
          <th *ngIf="mainService.resizeInfo.isThisOrLarger('xxxl')" class="text-bg-primary">Omschrijving</th>
          <th *ngIf="mainService.resizeInfo.isThisOrLarger('xxl')" class="text-bg-primary">Fabrikant</th>
          <th class="text-bg-primary">Categorie</th>
          <th class="text-bg-primary">Runflat</th>
          <th class="text-bg-primary">Label</th>
          <th *ngIf="ctx.TyresSurcharge.Active"  class="text-bg-primary text-end">{{ctx.TyresSurcharge.ColumnName}}</th>
          <th *ngIf="mainService.resizeInfo.isThisOrSmaller('lg')" class="text-bg-primary" [style.width]="cartService.getTemplatesFromFirstCartItem(tyres).Combined.Width + 'px'">{{cartService.getTemplatesFromFirstCartItem(tyres).Combined.Description}}</th>
          <ng-container *ngIf="mainService.resizeInfo.isThisOrLarger('xl')">
              <th *ngFor="let temp of cartService.getTemplateParts(cartService.getFirstItemInfoFromCartItems(tyres), ctx.Nettoprijs && !cartService.getNetPriceHidden())" class="text-bg-primary" [ngClass]="{'text-end': temp.HorizontalAlignment == 3}">{{temp.Description}}</th>
          </ng-container>
          <th  class="text-bg-primary text-center">Info</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let tyre of (tyres | maxItemsFilter:_maxItemCount | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']); trackBy: mainService.trackByShoppingCartItem" class="align-middle">
          <td *ngIf="!set && mainService.resizeInfo.isThisOrSmaller('xxl')">
              <app-item-count [sci]="tyre" [center]="true"></app-item-count>
          </td>
          <td *ngIf="!set && mainService.resizeInfo.isThisOrLarger('xxxl')">
              <app-item-count-small [sci]="tyre" [center]="true"></app-item-count-small>
          </td>
          <td *ngIf="set" class="text-center">
              <span class="text-primary icon-xl pointer" (click)="rimAndTyreService.orderRimsAndTyresSet(set,tyre)"><i class="fas fa-plus-square"></i></span>
          </td>
          <td>
              <span>{{tyre.Artikelnr}}</span>
              <span *ngIf="mainService.resizeInfo.isThisOrSmaller('xxl')">
        <br />{{tyre.ItemInfo.Description}}</span>
              <span *ngIf="mainService.resizeInfo.isThisOrSmaller('xl')">
        <br />{{tyre.ItemInfo.Properties['TyreBrand']}}</span>
          </td>
          <td *ngIf="mainService.resizeInfo.isThisOrLarger('xxxl')">{{tyre.ItemInfo.Description}}</td>
          <td *ngIf="mainService.resizeInfo.isThisOrLarger('xxl')">{{tyre.ItemInfo.Properties['TyreBrand']}}</td>
          <td>{{tyre.ItemInfo.Properties['TyreKind']}}</td>
          <td>{{rimAndTyreService.getRunFlat(tyre)}}</td>
          <td>{{rimAndTyreService.getTyreLabel(tyre)}}</td>
          <td *ngIf="ctx.TyresSurcharge.Active" class="text-end">{{tyre.ItemInfo.Price.RetailPrice | price}}</td>
          <td *ngIf="mainService.resizeInfo.isThisOrSmaller('lg'); else columns">
              <app-item-info-template [itemInfo]="tyre.ItemInfo" [nettoprijs]="ctx.Nettoprijs"></app-item-info-template>
          </td>
          <ng-template #columns>
              <td *ngFor="let temp of cartService.getTemplateParts(cartService.getFirstItemInfoFromCartItems(tyres), ctx.Nettoprijs && !cartService.getNetPriceHidden()); let i = index">
                  <app-item-info-template [itemInfo]="tyre.ItemInfo" [templatePart]="i" [nettoprijs]="ctx.Nettoprijs"></app-item-info-template>
              </td>
          </ng-template>
          <td class="text-center">
              <span class="text-primary icon-xl pointer" (click)="rimAndTyreService.showTyreInfo(tyre)"><i class="fas fa-info-circle"></i></span>
          </td>
      </tr>
  </tbody>
</table>
<app-show-more-button [(currentItemCount)]="_maxItemCount" [showPerCount]="maxItems" [totalItemCount]="tyres.length"></app-show-more-button>
