import { Component, OnInit } from "@angular/core";
import { SettingsService } from "../../_services/settings.service";


@Component({
  selector: "app-scroll-to-top-button",
  templateUrl: "./scroll-to-top-button.component.html",
  styleUrls: ["./scroll-to-top-button.component.scss"]
})
export class ScrollToTopButtonComponent implements OnInit {

  constructor(
    public settingsService: SettingsService
  ) { }

  ngOnInit() { }

}
