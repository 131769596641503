import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { ShopSoort } from '../_models/common/shop.soort';
import { ContextMain } from '../_models/common/context.main';
import { ShopModule } from '../_models/common/shop.module';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ShopRouteStorageObject } from '../_models/common/shop.route.storage.object';
import { ShopRouteConfig } from '../_models/common/shop.route.config';
import { MainService } from './main.service';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { TyreRequest } from '../_models/tyres/tyre.request';
import { ShopModuleMenuItem } from '../_models/common/shop.module.menu.item';


@Injectable()
export class ShopService {
  private heartbeatSubscription: Subscription;
  private heartbeatCount = 0;
  public startUpShop: ShopSoort = ShopSoort.ONBEKEND;
  public lastActiveShop: ShopSoort = ShopSoort.ONBEKEND;
  public currentActiveShop: ShopSoort = ShopSoort.ONBEKEND;
  public shopStates: { [key: number]: ShopRouteStorageObject } = {};
  // public shopStatesExt: { [key: string]: ShopRouteStorageObject } = {};
  public lastShopRoutes: { [key: number]: string } = {};
  public dateTimeNow = new Date();
  initialized = false;

  constructor(
    private router: Router,
    private apiService: ApiService
  ) { }

  clear(): void {
    this.startUpShop = ShopSoort.ONBEKEND;
    this.lastActiveShop = ShopSoort.ONBEKEND;
    this.currentActiveShop = ShopSoort.ONBEKEND;
    this.shopStates = {};
    this.lastShopRoutes = {};
    this.dateTimeNow = new Date();
    this.initialized = false;
    this.heartbeatCount = 0;
    this.stopHeartbeat();
  }

  public initShopService(mainService: MainService) {
    let activeShop: ShopModule;
    let startShop: ShopModule;
    for (const shop of mainService.ctxMain.ShopModules) {
      if (shop.IsStartupShop) {
        this.startUpShop = shop.ID;
        startShop = shop;
      }
      if (shop.ID === this.currentActiveShop) { activeShop = shop; }
    }
    if (activeShop) {
      this.currentActiveShop = activeShop.ID;
      activeShop.Active = true;
    } else if (startShop) {
      // this.currentActiveShop = startShop.ID;
      this.startShopModule(mainService.ctxMain, startShop);
    }
    this.startHeartbeat(mainService);
    this.initialized = true;
  }

  private startHeartbeat(mainService: MainService): void {
    this.stopHeartbeat();
    if (mainService.ctxMain) {
      console.info(`startHeartbeat: beat every ${mainService.ctxMain.HeartbeatIntervalMilliseconds} ms`);
      // this.heartbeatSubscription = timer((60 - new Date().getSeconds()) * 1000, mainService.ctxMain.HeartbeatIntervalMilliseconds)
      this.heartbeatSubscription = timer((5) * 1000, mainService.ctxMain.HeartbeatIntervalMilliseconds)
        .pipe(takeWhile(() => mainService.ctxMain != null))
        .subscribe(() => {
          this.doHeartbeat(mainService);
        });
    }
  }

  private stopHeartbeat(): void {
    if (this.heartbeatSubscription) { this.heartbeatSubscription.unsubscribe(); }
  }

  private doHeartbeat(mainService: MainService): void {
    this.dateTimeNow = new Date();
    this.apiService.updateHeartbeat(this.currentActiveShop)
      .subscribe(ok => {
        console.log(`Heartbeat update: ${++this.heartbeatCount}`);
      });
  }

  isLegacyShop(shopSoort: ShopSoort): boolean {
    switch (shopSoort) {
      case ShopSoort.BandenCalculator:
      case ShopSoort.GarageInformatieSysteem:
      case ShopSoort.Aanbiedingen:
      case ShopSoort.Thule:
      case ShopSoort.BandenOpslag:
      case ShopSoort.KentekenPlaten:
      case ShopSoort.WerkplaatsTop10:
      case ShopSoort.StorE:
      case ShopSoort.Hartsant:
      case ShopSoort.MPM:
      case ShopSoort.ModusWebshop:
      case ShopSoort.DVSE:
      case ShopSoort.VerhuurTool:
      case ShopSoort.Bonusshop:
        return true;
    }
    return false;
  }

  getShopSoortFromRoute(route: ActivatedRouteSnapshot): ShopSoort {
    if (route && route.routeConfig && route.routeConfig.data && route.routeConfig.data['shop']) {
      return route.routeConfig.data['shop'];
    }
    if (route && route.params && route.params['shop']) {
      return +route.params['shop'];
    }
    if (route && route.parent) { return this.getShopSoortFromRoute(route.parent); }
    return ShopSoort.ONBEKEND;
  }

  getShopModuleByShopSoort(ctx: ContextMain, shopSoort: ShopSoort): ShopModule {
    if (ctx) {
      for (const shop of ctx.ShopModules) {
        if (shop.ID === shopSoort) { return shop; }
      }
      // Shop niet gevonden in actieve shops... is het een tabgroup?
      const tabShops = this.getGroupForShopSoort(ctx, shopSoort);
      if (tabShops) { return this.getShopModuleByShopSoort(ctx, tabShops[0]); }
    } else { console.warn('No context!'); }
    return null;
  }

  getShopKindName(ctx: ContextMain, shopSoort: ShopSoort): string {
    if (ctx && ctx.ShopKindNames) {
      return ctx.ShopKindNames[shopSoort];
    }
    return '';
  }

  getShopModuleName(ctx: ContextMain, shopSoort: ShopSoort): string {
    if (shopSoort === ShopSoort.StartupShop) { shopSoort = this.startUpShop; }
    const shop = this.getShopModuleByShopSoort(ctx, shopSoort);
    if (shop) {
      return shop.Naam;
    }
    return '';
  }

  containsShopModule(ctx: ContextMain, shopSoort: ShopSoort): boolean {
    let ok = false;
    ctx.ShopModules.forEach(shop => {
      if (shop.ID === shopSoort) { ok = true; }
    });
    return ok;
  }

  public resetShopState(shopSoort: ShopSoort): void {
    delete this.shopStates[shopSoort];
    delete this.lastShopRoutes[shopSoort];
  }

  public resetCarTypeShops(ctx: ContextMain): void {
    if (ctx) {
      const shops = [];
      for (const shop of ctx.ShopModules) {
        if (shop.GebruiktAutotypes) {
          shops.push(ShopSoort[shop.ID]);
          this.resetShopState(shop.ID as ShopSoort);
          if (this.currentActiveShop === shop.ID) {
            const path = `/${ShopRouteConfig.getRoutePathForShopSoort(this.currentActiveShop)}`;
            if (path) { this.router.navigate([path]); }
          }
        }
      }
      console.info(`resetCarTypeShops: deleted shopStates: ${shops.join(',')}`);
    }
  }

  public reloadShops() {
    location.reload();
  }

  private deactivateAllShopModulesExcept(ctx: ContextMain, shopSoort: ShopSoort): void {
    console.info(`deactivateAllShopModulesExcept: ${ShopSoort[shopSoort]}`);
    for (const shop of ctx.ShopModules) {
      const active = (shop.ID === shopSoort);
      shop.Active = active;
    }
  }

  // private navigateToShopSoort(ctx: ContextMain, shopSoort: ShopSoort, customPath: string) {
  //   console.info(`navigateToShopSoort: ${ShopSoort[shopSoort]}`);
  //   const router = this.inj.get(Router);
  //   let path = `/${ShopRouteConfig.getRoutePathForShopSoort(this.currentActiveShop)}`;
  //   if (router.url && router.url.startsWith(path)) { this.lastShopRoutes[this.currentActiveShop] = router.url; }
  //   this.deactivateAllShopModulesExcept(ctx, shopSoort);
  //   this.lastActiveShop = this.currentActiveShop;
  //   if (this.lastActiveShop === ShopSoort.AldocSys) {
  //     console.info('Aldoc left -> Updating cart...');
  //     const cartService = this.inj.get(CartService);
  //     cartService.updateCart(true);
  //   }
  //   path = `/${ShopRouteConfig.getRoutePathForShopSoort(shopSoort)}`;
  //   if (this.currentActiveShop === shopSoort) {
  //     this.resetShopState(shopSoort);
  //   } else {
  //     this.currentActiveShop = shopSoort;
  //     if (this.lastShopRoutes[this.currentActiveShop]) { path = this.lastShopRoutes[this.currentActiveShop]; }
  //   }
  //   if (customPath) { path = customPath; }
  //   if (path) {
  //     console.info(`navigateToShopSoort on path: ${path}`);
  //     router.navigate([path]);
  //   }
  // }

  gotoStartupModule(ctx: ContextMain): void {
    const shop = this.getShopModuleByShopSoort(ctx, Number(this.startUpShop));
    if (shop) { this.startShopModule(ctx, shop); }
  }

  gotoShopModule(ctx: ContextMain, shopSoort: ShopSoort, parameter: any = null): void {
    const shop = this.getShopModuleByShopSoort(ctx, Number(shopSoort));
    if (shop) { this.startShopModule(ctx, shop, parameter); }
  }

  gotoPreviousShopModule(ctx: ContextMain): void {
    const shop = this.getShopModuleByShopSoort(ctx, this.lastActiveShop);
    if (shop) { this.startShopModule(ctx, shop); }
  }

  startShopModule(ctx: ContextMain, shop: ShopModule, parameter: any = null): void {
    let path = ShopRouteConfig.getRoutePathForCarTypeShopSoort(shop.ID);
    if (parameter && path) {
      this.router.navigate([path, parameter]);
    } else {
      path = `/${ShopRouteConfig.getRoutePathForShopSoort(shop.ID)}`;
      if (path) { this.router.navigate([path]); }
    }
  }

  gotoCarTypeSupportedShopModule(ctx: ContextMain, gotoShop: ShopSoort, fallbackShop: ShopSoort) {
    let shopSoort = fallbackShop;
    const shop = this.getShopModuleByShopSoort(ctx, gotoShop);
    if (shop && shop.GebruiktAutotypes) { shopSoort = gotoShop; }
    this.gotoShopModule(ctx, shopSoort, ctx.CurrentAuto?.Id);
  }


  // public setActiveShopModuleByRoute(route: ActivatedRouteSnapshot) {
  //   const shopSoort = ShopRouteConfig.getShopSoortFromPath(route.routeConfig.path);
  //   if (this.currentActiveShop === ShopSoort.ONBEKEND) {
  //     this.currentActiveShop = shopSoort;
  //   }
  // }

  private getGroupForShopSoort(ctx: ContextMain, shopSoort: ShopSoort): ShopSoort[] {
    if (ctx.ShopModuleGroups) {
      for (const group of ctx.ShopModuleGroups) {
        for (const shop of group) {
          if (shop === shopSoort) {
            const activeGroup: ShopSoort[] = [];
            for (const shopTab of group) {
              for (const module of ctx.ShopModules) {
                if (module.ID === shopTab) { activeGroup.push(shopTab); }
              }
            }
            if (activeGroup.length > 0) { return activeGroup; }
          }
        }
      }
    }
    return null;
  }

  public getTabGroupTabs(ctx: ContextMain): ShopModule[] {
    const tabs: ShopModule[] = [];
    const group = this.getGroupForShopSoort(ctx, this.currentActiveShop);
    if (group && group.length > 1) {
      for (const shopSoort of group) {
        const shop = this.getShopModuleByShopSoort(ctx, shopSoort);
        if (shop) { tabs.push(shop); }
      }
    }
    return tabs;
  }

  public doSearchTyre(ctx: ContextMain, request: TyreRequest) {
    // this.resetShopState(ShopSoort.Banden);
    // this.navigateToShopSoort(ctx, ShopSoort.Banden, `/rims/${request.Sizes[0].toBareString()}`);
    this.router.navigate(['/tyres', request.Sizes[0].toBareString()]);
  }

  getHomeScreenName(ctx: ContextMain): string {
    let name = this.getShopModuleName(ctx, ShopSoort.StartScherm);
    if (!name) { name = 'Start'; }
    return name;
  }

  getShopSoortLink(shopKind: ShopSoort): any[] {
    const path = `/${ShopRouteConfig.getRoutePathForShopSoort(shopKind)}`;
    if (path) { return [path]; }
    return null;
  }

  getHomeScreenLink(): string[] {
    return this.getShopSoortLink(this.startUpShop);
  }

  clickMenuItem(menuItem: ShopModuleMenuItem): void {
    if (menuItem.Link) {
      window.open(menuItem.Link, '_blank');
    } else {
      let shopKind = menuItem.ShopKind;
      if (shopKind === ShopSoort.StartupShop) { shopKind = this.startUpShop; }
      const path = ShopRouteConfig.getRoutePathForShopSoort(shopKind);
      if (path) { this.router.navigate([`/${path}`]); }
    }
  }

  getClasses(menuItem: ShopModuleMenuItem, hasSubMenuExtra: string = ''): string[] {
    const classes: string[] = [];
    if (menuItem?.CssClass) classes.push(menuItem.CssClass);
    // else if (!menuItem?.ParentId) classes.push('nav-link');
    if (this.hasSubMenu(menuItem) && hasSubMenuExtra) { classes.push(hasSubMenuExtra); }
    return classes;
  }

  getMenuItemDescription(ctxMain: ContextMain, menuItem: ShopModuleMenuItem): string {
    if (!menuItem) { return null; }
    if (menuItem.Description) { return menuItem.Description; }
    return this.getShopModuleName(ctxMain, menuItem.ShopKind);
  }



  hasSubMenu(menuItem: ShopModuleMenuItem): boolean {
    return menuItem.MenuItems && menuItem.MenuItems.length > 0;
  }

}
