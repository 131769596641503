import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { Part } from '../../../_models/catalog/part';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { CatalogPartPopupInfoKind } from '../../../_models/catalog/catalog-part-popup-info-kind';
import { CarTypeResult } from '../../../_models/car-types/car.type.result';

@Component({
  selector: 'app-catalog-part-detailed-popup',
  templateUrl: './catalog-part-detailed-popup.component.html',
  styleUrls: ['./catalog-part-detailed-popup.component.scss']
})
export class CatalogPartDetailedPopupComponent implements OnInit {
  ctx: ContextCatalog;
  parts: Part[];
  infoKind: CatalogPartPopupInfoKind;
  catalogService: CatalogService;
  mainService: MainService;
  currentPart: Part;
  carTypeResultApplication = new CarTypeResult();

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.parts && this.parts.length > 0) { this.currentPart = this.parts[0]; }
  }

  choosePart(part: Part) {
    this.currentPart = part;
    this.carTypeResultApplication = new CarTypeResult();
  }

}
