<div *ngIf="ctx" class="container">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true">{{shopService.getShopModuleName(mainService.ctxMain, shopKind.Favorites)}}</li>
  </app-breadcrumb-header>

  <app-side-panel-and-content *ngIf="ctx">
    <ng-container side-panel>
      <app-side-panel-catalog [ctx]="ctx"></app-side-panel-catalog>
    </ng-container>
    <ng-container content>
      <app-catalog-part-list [ctx]="ctx" [maxItems]="20">
      </app-catalog-part-list>
    </ng-container>
  </app-side-panel-and-content>

</div>
