<ng-template #customItemTemplate let-model="item" let-index="index">
  <span class="small">{{model.Description}}</span>&nbsp;<span class="small lighter">{{model.ItemGroupId}}</span><br />
  <span class="small lighter">{{model.Brand}}</span>
</ng-template>
<app-admin-custom-control [label]="label" [labelCols]="labelCols" [dividerKind]="dividerKind">
  <div class="input-group">
    <input type="text" [appFocus]="focusInput" [typeahead]="adminService.searchItemGroup(start)" [adaptivePosition]="true"
      [dropup]="dropup" [typeaheadItemTemplate]="customItemTemplate" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8"
      [id]="'input'+uniqueId" class="form-control" [disabled]="disabled" [(ngModel)]="start"
      (typeaheadOnSelect)="typeaheadOnSelect($event)" (blur)="onBlur($event)" [typeaheadOptionField]="'ItemGroupId'"
      [placeholder]="placeholder?placeholder:''" autocomplete="off">
    <ng-content></ng-content>
  </div>
</app-admin-custom-control>

