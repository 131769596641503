<div class="alert alert-secondary px-0 mt-3" *ngFor="let interfaceData of interfaceDatas;let even=even">

  <div class="m-2">
    <app-default-interface-data-settings-single [interfaceDatas]="interfaceDatas"
      [supplierInterfaceData]="supplierInterfaceData" (interfaceDataChanged)="interfaceDataChanged.emit($event)"
      [interfaceData]="interfaceData"></app-default-interface-data-settings-single>
  </div>

  <div *ngIf="interfaceData.LinkedInterfaceData">
    <div *ngFor="let id of interfaceData.LinkedInterfaceData | keyvalue">
      <hr />
      <div class="row">
        <div class="col-2 small">
          <b>{{adminSupplierInterfaceService.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, +id.key).Name}}</b>
        </div>
        <div class="col-10">
          <app-default-interface-data-settings-single [interfaceDatas]="interfaceDatas"
            [supplierInterfaceData]="supplierInterfaceData" (interfaceDataChanged)="interfaceDataChanged.emit($event)"
            [interfaceData]="id.value"></app-default-interface-data-settings-single>
        </div>
      </div>
    </div>
  </div>
</div>