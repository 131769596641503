import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';

import { MessagePopupComponent } from '../_common/message-popup/message-popup.component';
import { ConfirmPopupComponent } from '../_common/confirm-popup/confirm-popup.component';
import { ContextMain } from '../_models/common/context.main';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { ItemSortOption } from '../_models/common/item.sort.option';
import { CallbackInfoComponent } from '../_common/callback-info/callback-info.component';
import { CallbackInfoProgress } from '../_models/common/callback.info.progress';
import { ResizeInfo } from '../_models/common/resize.info';
import { ShowObjectComponent } from '../_common/show-object/show-object.component';
import { TimingObject } from '../_models/common/timing.object';
import { ShowTimingsComponent } from '../_common/show-timings/show-timings.component';
import { ShopPortalMessage } from '../_models/common/shop.portal.message';
import { ContextPublic } from '../_models/common/context.public';
import { SafeHtml, DomSanitizer, Title } from '@angular/platform-browser';
import { ApiService } from './api.service';
import { Part } from '../_models/catalog/part';
import { ContextPublicRequest } from '../_models/common/context.public.request';
import { InputPopupComponent } from '../_common/input-popup/input-popup.component';
import { environment } from '../../environments/environment';
import { LayoutKind } from '../_models/common/layout.kind';
import { ContextBase } from '../_models/common/context.base';
import { AvailabilityLogObject } from '../_models/item-info/availability.log.object';
import { ShowAvailabilityLogComponent } from '../_common/show-availability-log/show-availability-log.component';
import { TimingItem } from '../_models/common/timing.item';
import { TimingClassItem } from '../_models/common/timing.class.level';
import { SettingsService } from './settings.service';
import { LogService } from './log.service';
import { ConfigData } from '../_models/common/config.data';
import { CatalogPropertyService } from './catalog-property.service';
import { ToasterService } from './toaster.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { SelectionPopupComponent } from '../_common/selection-popup/selection-popup.component';
import { ShopSoort } from '../_models/common/shop.soort';
import { ShopService } from './shop.service';
import { ContextMainRequest } from '../_models/common/context.main.request';
import { MenuItem } from '../_models/admin/menu.item';
import { ShopModuleMenuItem } from '../_models/common/shop.module.menu.item';
import { PasswordForgottenPopupComponent } from '../_common/password-forgotten-popup/password-forgotten-popup.component';
import { AccountRequest } from '../_models/logon/account.request';
import { RequestAccountPopupComponent } from '../_common/request-account-popup/request-account-popup.component';
import { ImageViewerModalComponent } from '../_common/image-viewer-modal/image-viewer-modal.component';


@Injectable()
export class MainService {
  // ctxPublic: ContextPublic;
  layoutKind = LayoutKind;
  ctxMain: ContextMain;
  resizeSubject = new BehaviorSubject<ResizeInfo>(null);
  contextMainChangeSubject = new BehaviorSubject<ContextMain>(null);
  callBackInfo: CallbackInfoComponent;
  public resizeInfo: ResizeInfo;
  public resizeInfos$ = this.resizeSubject.asObservable(); // .throttleTime(200);
  public contextMainChange$ = this.contextMainChangeSubject.asObservable();
  private menuIsFloating = false;

  isProduction = environment.production;

  isIE = /*@cc_on!@*/false || !!document['documentMode'];

  constructor(
    // @Inject(APP_BASE_HREF) private baseHref: string,
    public configData: ConfigData,
    private apiService: ApiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private settingsService: SettingsService,
    private catalogPropertyService: CatalogPropertyService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private datePipe: DatePipe
  ) {
    if (this.configData && this.configData.ctxPublic) {
      this.handleContextPublicChange(this.configData.ctxPublic);
    }
  }

  clear(): void {
    this.ctxMain = null;
  }

  isDev(): boolean {
    return environment.version === 'dev';
  }

  getShopPortalLayoutKind(shopKind: ShopSoort = ShopSoort.ONBEKEND): LayoutKind {
    let layoutKind = LayoutKind.BoxedLayout;
    if (this.ctxMain && this.ctxMain.ShopPortalLayoutKinds && this.ctxMain.ShopPortalLayoutKinds[shopKind]) {
      layoutKind = this.ctxMain.ShopPortalLayoutKinds[shopKind] as LayoutKind;
    } else if (this.ctxMain && this.ctxMain.ShopPortalLayoutKinds && this.ctxMain.ShopPortalLayoutKinds[ShopSoort.ONBEKEND]) {
      layoutKind = this.ctxMain.ShopPortalLayoutKinds[ShopSoort.ONBEKEND] as LayoutKind;
    }
    return layoutKind;
  }

  public swicthIfWithAltShift(event: MouseEvent, input: boolean): boolean {
    if (event.shiftKey && event.altKey && !event.ctrlKey) {
      return !input;
    }
    return input;
  }

  public swicthIfWithCtrlAlt(event: MouseEvent, input: boolean): boolean {
    if (!event.shiftKey && event.altKey && event.ctrlKey) {
      return !input;
    }
    return input;
  }

  public registerCallBackInfo(cbInfo: CallbackInfoComponent) {
    this.callBackInfo = cbInfo;
  }

  public onResize(info: ResizeInfo) {
    this.resizeInfo = info;
    this.resizeSubject.next(info);
  }

  public shouldMenuBeHidden(): boolean {
    if (this.isMenuActive() && this.resizeInfo) {
      const ri = this.resizeInfo;
      return (ri.clientWidth - this.ctxMain.ShopModuleMenu.MenuWidth - ri.cartWidth) < this.ctxMain.ShopModuleMenu.Breakpoint;
    }
    return true;
  }

  public isMenuActive(): boolean {
    if (this.ctxMain && this.ctxMain.ShopModuleMenu && this.ctxMain.ShopModuleMenu.MenuWidth) { return true; }
    return false;
  }

  public isMenuActiveAndHidden(): boolean {
    return this.isMenuActive() && this.shouldMenuBeHidden();
  }

  public isMenuFloating(): boolean {
    return (this.isMenuActive() && this.menuIsFloating && this.shouldMenuBeHidden());
  }

  public toggleMenuFloating(onoff: any): void {
    if (!onoff && onoff !== false) {
      this.menuIsFloating = !this.menuIsFloating;
    } else {
      this.menuIsFloating = onoff;
    }
  }

  private handleContextPublicChange(ctx: ContextPublic) {
    this.settingsService.initPublic(ctx.Settings);
    this.logService.setLogLevel(this.settingsService.getSettingNumber('AppLogLevelClient'));
    this.handleStyleSheetsChange(ctx.Layout.ExtraStyleSheets);
    if (ctx.Layout.FavIconHref) {
      this.changeFavIcon(ctx.Layout.FavIconHref, ctx.Layout.FavIconType);
      this.toasterService.defaultIcon = ctx.Layout.FavIconHref;
    }
    this.changeThemeColor(ctx.Layout.ThemeColorLight, ctx.Layout.ThemeColorDark);
  }

  public getContextPublic(wholesaler: number): Observable<ContextPublic> {
    if (this.configData.ctxPublic && this.configData.ctxPublic.Wholesaler === wholesaler) {
      return of(this.configData.ctxPublic);
    } else {
      const req = new ContextPublicRequest(document.location.href, wholesaler);
      return this.apiService.getContextPublic(req)
        .pipe(mergeMap(ctx => {
          this.configData.ctxPublic = ctx;
          this.handleContextPublicChange(ctx);
          return of(ctx);
        }));
    }
  }

  public getContextMain(ticket: string = '', hideProgress: boolean = true): Observable<ContextMain> {
    if (this.ctxMain) {
      return of(this.ctxMain);
    } else {
      const cb = this.callbackInfoBox('Eén moment geduld...', null, null, hideProgress);
      const request = new ContextMainRequest(document.location.origin, environment.version, ticket);
      return this.apiService.getContextMain(request)
        .pipe(mergeMap(ctx => {
          if (ctx) {
            this.ctxMain = ctx;
            this.settingsService.initMain(ctx.Settings);
            this.catalogPropertyService.init();
            this.logService.setLogLevel(this.settingsService.getSettingNumber('AppLogLevelClient'));
            this.contextMainChangeSubject.next(ctx);
            this.handleStyleSheetsChange(ctx.ExtraStyleSheets);
          }
          cb.complete();
          return of(ctx);
        }));
    }
  }

  removeNotActivatedShopModulesFromMenu(shopService: ShopService, ctx: ContextMain) {
    if (ctx?.ShopModuleMenu?.MenuItems?.length) {

      for (let index = ctx.ShopModuleMenu.MenuItems.length - 1; index >= 0; index--) {
        this.removeNotActivatedShopModulesFromMenuItems(shopService, ctx, ctx.ShopModuleMenu.MenuItems);
      }
    }
  }

  removeNotActivatedShopModulesFromMenuItems(shopService: ShopService, ctx: ContextMain, menuItems: ShopModuleMenuItem[]) {
    for (let index = menuItems.length - 1; index >= 0; index--) {
      if (menuItems[index].ShopKind > 0 && !shopService.containsShopModule(ctx, menuItems[index].ShopKind)) {
        menuItems.splice(index, 1);
      } else if (!menuItems[index].ShopKind && menuItems[index].MenuItems?.length) {
        this.removeNotActivatedShopModulesFromMenuItems(shopService, ctx, menuItems[index].MenuItems);
        if (!menuItems[index].MenuItems.length) { menuItems.splice(index, 1); }
      }
    }
  }

  handleStyleSheetsChange(stylesheets: string[]): void {
    for (let i = 0; i < 10; i++) {
      this.removeCss(`extra_css_${i}`);
    }
    if (stylesheets) {
      for (let i = 0; i < stylesheets.length; i++) {
        this.appendCss(stylesheets[i], `extra_css_${i}`);
      }
    }
  }

  removeCss(cssId): void {
    const css = document.getElementById(cssId);
    if (css) { css.parentNode.removeChild(css); }
  }

  appendCss(url, cssId): void {
    if (!document.getElementById(cssId)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.media = 'all';
      // const first = document.getElementsByTagName('base')[0];
      // if (first) {
      //   head.insertBefore(link, first);
      // } else {
      head.appendChild(link);
      // }
    }
  }

  changeFavIcon(favIconHref: string, favIconType: string) {
    const linkRemove = document.head.querySelector("#favIcon");
    if (linkRemove) { document.head.removeChild(linkRemove); }
    const linkElement = document.createElement("link");
    const noCache = (favIconHref.includes('?') ? '&' : '?') + 't=' + new Date().getTime();
    linkElement.setAttribute("id", "favIcon");
    linkElement.setAttribute("rel", "shortcut icon");
    linkElement.setAttribute("type", favIconType);
    linkElement.setAttribute("href", favIconHref + noCache);
    document.head.appendChild(linkElement);
  }

  changeThemeColor(themeColorLight: string, themeColorDark: string) {
    this.refreshMetaTag('themeColorLight', 'theme-color', themeColorLight, '(prefers-color-scheme: light)');
    this.refreshMetaTag('themeColorDark', 'theme-color', themeColorDark, '(prefers-color-scheme: dark)');
  }

  refreshMetaTag(id: string, name: string, content: string, media: string) {
    const metaRemove = document.head.querySelector(`#${id}`);
    if (metaRemove) { document.head.removeChild(metaRemove); }
    if (content) {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("id", id);
      metaElement.setAttribute("name", name);
      metaElement.setAttribute("content", content);
      metaElement.setAttribute("media", media);
      document.head.appendChild(metaElement);
    }
  }

  public getBackendApi(): string {
    return this.configData.backendApi;
  }

  public getSafeHtml(html: string): SafeHtml {
    if (html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return this.sanitizer.bypassSecurityTrustHtml('');
  }

  saveToClipBoard(text: string, event: Event = null) {
    const pending = this.clipboard.beginCopy(text);
    let remainingAttempts = 15;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();
    let copyText = '';
    if (text.length < 100) { copyText = ` '${text}'`; }
    this.toasterService.showToast('Succes', `De tekst${copyText} is gekopieerd naar het klembord!`);
    if (event) this.preventAndStop(event)
  }

  public msgBoxExtended(message: ShopPortalMessage): Observable<string> {
    const initialState = {
      message: message,
      mainService: this
    };
    const modalRef = this.modalService.show(MessagePopupComponent, { initialState, class: 'modal-xxl' });
    return modalRef.content.onClose;
  }

  public msgBox(title: string, message: string): Observable<string> {
    return this.msgBoxExtended(new ShopPortalMessage(title, message, null, null));
  }

  public confirmBox(text: string): Observable<boolean> {
    const initialState = {
      text: text
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, { initialState, class: 'modal-sm' });
    return modalRef.content.onClose;
  }

  public selectionBoxExtended(title: string, selectionValues: { [key: string]: any }, defaultValue: string, css: string): Observable<any> {
    const initialState = {
      title: title,
      selectionValues: selectionValues,
      defaultValue: defaultValue,
      mainService: this
    };
    const modalRef = this.modalService.show(SelectionPopupComponent, { initialState, class: css });
    return modalRef.content.onClose;
  }

  public selectionBox(title: string, values: string[], defaultValue: string = null): Observable<any> {
    const selectionValues: { [key: string]: any } = {};
    values.forEach(element => {
      selectionValues[element] = element;
    });
    return this.selectionBoxExtended(title, selectionValues, defaultValue, 'modal-sm');
  }

  public inputBoxExtended(title: string, description: string, defaultValue: string, isPassword: boolean, css: string): Observable<string> {
    const initialState = {
      title: title,
      description: description,
      defaultValue: defaultValue,
      isPassword: isPassword,
      mainService: this
    };
    const modalRef = this.modalService.show(InputPopupComponent, { initialState, class: css });
    return modalRef.content.onClose;
  }

  public inputBox(description: string, defaultValue: string, isPassword: boolean): Observable<string> {
    return this.inputBoxExtended('', description, defaultValue, isPassword, 'modal-sm');
  }

  public callbackInfoBoxSimpel(title: string): CallbackInfoProgress {
    return this.callbackInfoBoxExtended(title, '', 100, 100, '');
  }

  public callbackInfoBox(title: string, description: string, currentActivity = '', hideProgress?: boolean): CallbackInfoProgress {
    return this.callbackInfoBoxExtended(title, description, 100, 100, currentActivity, hideProgress);
  }

  public callbackInfoBoxExtended(
    title: string,
    description: string,
    progressMax: number,
    progressValue: number,
    currentActivity: string,
    hideProgress?: boolean
  ): CallbackInfoProgress {
    const cb = this.callBackInfo.show(title, description, progressMax, 0, currentActivity, hideProgress);
    setTimeout(() => {
      cb.progressValue = progressValue;
      cb.update();
    });
    return cb;
  }

  showDebugContextInfo(event: MouseEvent, ctx: ContextBase): boolean {
    if ((event.shiftKey || event.altKey) && !(event.shiftKey && event.altKey) && event.ctrlKey && ctx && ctx.Timing) {
      return this.showTiming(null, ctx.Timing);
    } else if (event.shiftKey && event.altKey && !event.ctrlKey && ctx) {
      return this.showObject(null, ctx);
    }
    return true;
  }

  public showDebugInfo(event: MouseEvent, timing: TimingObject, obj: any): boolean {
    if ((event.shiftKey || event.altKey) && !(event.shiftKey && event.altKey) && event.ctrlKey) {
      return this.showTiming(null, timing);
    } else if (event.shiftKey && event.altKey && !event.ctrlKey) {
      return this.showObject(null, obj);
    }
    return true;
  }

  showObjectDialog(event: MouseEvent, object: any): void {
    const initialState = {
      object: object,
      mainService: this
    };
    const modalRef = this.modalService.show(ShowObjectComponent, { initialState, class: 'modal-max' });
    if (event) { event.stopPropagation(); }
  }

  public showObject(event: MouseEvent, object: any): boolean {
    if (!event || (event.shiftKey && event.altKey && !event.ctrlKey)) {
      this.showObjectDialog(event, object);
      return false;
    }
    return true;
  }

  showTimingDialog(timing: TimingObject): void {
    const initialState = {
      timing: timing,
      mainService: this
    };
    const modalRef = this.modalService.show(ShowTimingsComponent, { initialState, class: 'modal-max' });
  }

  public showTiming(event: MouseEvent, timing: TimingObject): boolean {
    if (!event || ((event.shiftKey || event.altKey) && !(event.shiftKey && event.altKey) && event.ctrlKey)) {
      this.showTimingDialog(timing);
      return false;
    }
    return true;
  }

  showAvailabilityLogDialog(log: AvailabilityLogObject): void {
    const initialState = {
      log: log,
      mainService: this
    };
    const modalRef = this.modalService.show(ShowAvailabilityLogComponent, { initialState, class: 'modal-max' });
  }

  public showAvailabilityLog(event: MouseEvent, log: AvailabilityLogObject): boolean {
    if ((event.shiftKey || event.altKey) && !(event.shiftKey && event.altKey) && event.ctrlKey) {
      this.showAvailabilityLogDialog(log);
      return false;
    }
    return true;
  }

  showImageViewer(images: string[], index = 0) {
    const initialState = {
      images: images,
      currentImage: index
    };
    const modalRef = this.modalService.show(ImageViewerModalComponent, { initialState, animated: false, class: 'modal-xxl' });
    return modalRef.content.onClose;
  }

  public passwordForgotten(): Observable<[string, string]> {
    let companyData: [string, string] = ['', ''];
    const initialState = {
      data: companyData
    };
    const modalRef = this.modalService.show(PasswordForgottenPopupComponent, { initialState, class: 'modal-md' });
    return modalRef.content.onClose;
  }

  public forgotPasswordPopup() {
    this.passwordForgotten()
      .subscribe(data => {
        if (data) {
          this.apiService.sendForgotPassword(data[0], data[1]).subscribe(ok => {
            if (ok) {
              this.toasterService.showToast('Succes', `Er is een aanvraag voor een nieuw wachtwoord verzonden.`);
            } else {
              this.toasterService.showToast('Let op!', `Er ging iets mis met de aanvraag voor een nieuw wachtwoord.`);
            }
          })
        }
      });
  }

  public requestAccount(): Observable<AccountRequest> {
    let request = new AccountRequest();
    const initialState = {
      request: request
    };
    const modalRef = this.modalService.show(RequestAccountPopupComponent, { initialState, class: 'modal-md' });
    return modalRef.content.onClose;
  }

  public requestAccountPopup() {
    this.requestAccount()
      .subscribe(data => {
        if (data) {
          this.apiService.requestAccount(data).subscribe(ok => {
            if (ok) {
              this.toasterService.showToast('Succes', `De account aanvraag is verzonden.`);
            } else {
              this.toasterService.showToast('Let op!', `Er ging iets mis met het aanvragen van een nieuw account.`);
            }
          })
        }
      });
  }

  public padLeft(text: string, count: number, char: string = '0'): string {
    const pad = new Array(1 + count).join(char);
    return (pad + text).slice(-pad.length);
  }

  public getGrossierFromUniqueID(uniqueID: string): number {
    if (uniqueID) {
      const data = uniqueID.split('_', 1);
      if (data.length === 1) { return +data[0]; }
    }
    return 0;
  }

  public getActiveSort(sortOptions: ItemSortOption[]): ItemSortOption {
    if (sortOptions) {
      return sortOptions.find(o => o.Active);
    }
    return new ItemSortOption();
  }

  public setActiveSort(sortOptions: ItemSortOption[], sort): ItemSortOption {
    if (sortOptions) {
      for (const s of sortOptions) {
        s.Active = (sort === s);
      }
    }
    return sort;
  }

  trackByShoppingCartItem(index: number, sci: ShoppingCartItem) {
    return sci.InternArtikelnr;
  }

  trackByCatalogPart(index: number, part: Part) {
    return part.UniqueID;
  }

  capatalize(text: string) {
    if (!text) return text;
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  isTomorrow(date: Date): boolean {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return date.getDate() === tomorrow.getDate() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getFullYear() === tomorrow.getFullYear();
  }

  getHumanDateString(date: Date, withTime: boolean = false, seperatorText: string = ' '): string {
    if (!date) return '';
    let text = this.datePipe.transform(date, 'dd-MM-yyyy');
    if (this.isToday(date)) { text = 'vandaag'; }
    if (this.isTomorrow(date)) { text = 'morgen'; }
    if (withTime) { text += seperatorText + this.datePipe.transform(date, 'HH:mm') + ' uur'; }
    return text;
  }

  getMaxCharsString(text: string, maxChars: number): string {
    if (!text) { return ''; }
    if (!maxChars || text.length <= maxChars) { return text; }
    return text.substr(0, maxChars) + '...';
  }

  fireReziseEvent() {
    if (this.isIE) {
      setTimeout(() => {
        const event = document.createEvent('Event');
        event.initEvent('resize', false, true);
        window.dispatchEvent(event);

      }, 500);
    } else {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  }

  getMax(value1: number, value2: number): number {
    return value1 < value2 ? value2 : value1;
  }

  getTimingMax(timings: TimingItem[], recursive: boolean): number {
    let max = 0;
    for (const ti of timings) {
      if (recursive && ti.SubTiming) {
        const maxR = this.getTimingMax(ti.SubTiming.Timings, recursive);
        if (maxR > max) { max = maxR; }
      } else if (ti.Duration && (ti.Duration / 10000000) > max) { max = (ti.Duration / 10000000); }
    }
    return max;
  }

  createTimingClassLevels(timingObject: TimingObject): TimingClassItem[] {
    if (!timingObject) { return null; }
    const maxD = this.getTimingMax(timingObject.Timings, true);
    const maxDC = this.getTimingMax(timingObject.Timings, false);
    const levels = [
      { max: 0, maxC: 0, css: 'text-alarm' },
      { max: 0, maxC: 0, css: 'text-letop' },
      { max: 0, maxC: 0, css: 'text-ok' },
      { max: 0, maxC: 0, css: 'text-low' }
    ];
    for (let i = 0; i < levels.length - 1; i++) {
      levels[i].max = maxD / (levels.length - 1) * (levels.length - 1 - i);
      levels[i].maxC = maxDC / (levels.length - 1) * (levels.length - 1 - i);
    }
    return levels;
  }


  getTimingClassLevel(classLevels: TimingClassItem[], delta: number, collapsed: boolean): string {
    let css = '';
    if (!collapsed && delta > classLevels[0].max) { return classLevels[0].css; }
    if (collapsed && delta > classLevels[0].maxC) { return classLevels[0].css; }
    for (const level of classLevels) {
      if (!collapsed && delta <= level.max) { css = level.css; }
      if (collapsed && delta <= level.maxC) { css = level.css; }
    }
    return css;
  }

  getProperty(object: any, property: string): any {
    if (!object) { return null; }
    if (!property) { return object; }
    const arr = property.split('.');
    while (arr.length && (object = object[arr.shift()])) { }
    return object;
  }

  setProperty(object: any, property: string, value: any): void {
    if (!object) { return null; }
    const arr = property.split('.');
    while (arr.length && arr.length > 1 && (object = object[arr.shift()])) { }
    object[arr[0]] = value;
  }

  getPropertyValue(object: any, propertyName: string): any {
    let value = this.getProperty(object, propertyName);
    if (value && value.valueOf) { value = value.valueOf(); }
    return value;
  }

  getPropertyEquality(item1: any, item2: any, propertyName: string): number {
    let direction = 1;
    if (propertyName.startsWith('-')) {
      direction = -1;
      propertyName = propertyName.substring(1);
    }
    const value1 = this.getPropertyValue(item1, propertyName);
    const value2 = this.getPropertyValue(item2, propertyName);
    if (value1 === null && value2 !== null) { return 1; }
    if (value1 !== null && value2 === null) { return -1; }
    if (value1 > value2) { return 1 * direction; }
    if (value1 < value2) { return -1 * direction; }
    return 0;
  }

  getArraySortedOnProperty(items: any[], propertyName: string): any[] {
    if (items) {
      const sorted = items.sort((item1, item2) => {
        return this.getPropertyEquality(item1, item2, propertyName);
      });
      return sorted;
    }
    return items;
  }

  getArraySortedOnPropertyWithProperySet(items: any[], propertyName: string = null): any[] {
    if (items && propertyName) {
      const sorted = this.getArraySortedOnProperty(items, propertyName);
      let index = 0;
      sorted.forEach((item) => {
        this.setProperty(item, propertyName, index++);
      });
      return sorted;
    }
    return items;
  }

  moveItemUp(items: any[], item: any, sortPropertyName: string = null): void {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index > 0) {
      const tempOrder = this.getProperty(sorted[index], sortPropertyName);
      if (sortPropertyName) {
        this.setProperty(sorted[index], sortPropertyName, this.getProperty(sorted[index - 1], sortPropertyName));
        this.setProperty(sorted[index - 1], sortPropertyName, tempOrder);
      } else {
        sorted[index] = this.getProperty(sorted[index - 1], sortPropertyName);
        sorted[index - 1] = tempOrder;
      }
    }
  }

  moveItemDown(items: any[], item: any, sortPropertyName: string = null): void {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index < sorted.length - 1) {
      const tempOrder = this.getProperty(sorted[index], sortPropertyName);
      if (sortPropertyName) {
        this.setProperty(sorted[index], sortPropertyName, this.getProperty(sorted[index + 1], sortPropertyName));
        this.setProperty(sorted[index + 1], sortPropertyName, tempOrder);
      } else {
        sorted[index] = this.getProperty(sorted[index + 1], sortPropertyName);
        sorted[index + 1] = tempOrder;
      }
    }
  }

  getItemUp(items: any[], item: any, sortPropertyName: string = null): any {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index > 0) {
      return this.getProperty(sorted[index - 1], sortPropertyName);
    }
    return null;
  }

  getItemDown(items: any[], item: any, sortPropertyName: string = null): any {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index < sorted.length - 1) {
      return this.getProperty(sorted[index + 1], sortPropertyName);
    }
    return null;
  }

  isItemTop(items: any[], item: any, sortPropertyName: string = null): boolean {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    return (index === 0);
  }

  isItemBottom(items: any[], item: any, sortPropertyName: string = null): boolean {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    return (index === (sorted.length - 1));
  }

  getPreviousItem(items: any[], item: any, sortPropertyName: string): boolean {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index > 0) { return sorted[index - 1]; }
    return null;
  }

  getNextItem(items: any[], item: any, sortPropertyName: string): any {
    const sorted = this.getArraySortedOnPropertyWithProperySet(items, sortPropertyName);
    const index = sorted.indexOf(item, 0);
    if (index < (sorted.length - 1)) { return sorted[index + 1]; }
    return null;
  }

  getStringOrDefault(text: string, defaultText: string): string {
    if (!text) { return defaultText; }
    return text;
  }

  getStringSection(section: number, input: string, filter: string): string {
    if (input) {
      let index = -1;
      if (filter) { index = input.toLowerCase().indexOf(filter.toLowerCase()); }
      if (section === 0 && index === -1) { return input; }
      if (section === 0 && index > 0) { return input.substr(0, index); }
      if (section === 1 && index >= 0) { return input.substr(index, filter.length); }
      if (section === 2 && index > -1 && index + filter.length < input.length) { return input.substr(index + filter.length); }
    }
    return '';
  }

  getStringSectionRegEx(section: number, input: string, filter: RegExp): string {
    if (input) {
      let index = -1;
      if (filter) { index = input.search(filter); }
      if (section === 0 && index === -1) { return input; }
      if (section === 0 && index > 0) { return input.substr(0, index); }
      if (section === 1 && index >= 0) { return input.substr(index, filter.source.length); }
      if (section === 2 && index > -1 && index + filter.source.length < input.length) { return input.substr(index + filter.source.length); }
    }
    return '';
  }

  scrollToTop() {
    $('html, body').animate({
      scrollTop: 0
    }, 800);
  }

  getMathCeil(value: number): number {
    return Math.ceil(value);
  }

  getStripped(value: string): string {
    if (value) return value.replace(/\W/g, '');
    return value;
  }

  preventAndStop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
