<div class="row bottom-border mx-1 py-1" [ngClass]="{'alt-back': odd}">
  <div class="col-2 align-self-center pointer"
    (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Details)">
    <img *ngIf="catalogService.partHasImage(ctx, part)"
      [src]="catalogService.partGetImage(ctx, part)" class="part-image grow" />
  </div>
  <div class="col-3 text-center">
    <span class="part-item-number pb-2" (contextmenu)="mainService.showObject($event, part)">
      <app-part-item-number-link [partItemNumber]="part.PartItemNumber" [shopKind]="ctx.ShopKind">
      </app-part-item-number-link>
    </span>
    <div class="row mt-2">
      <div class="col">
        <button *ngIf="part.HasSubParts && !selected.observers.length" class="btn btn-outline-secondary"
          (click)="catalogService.showSubParts(ctx.CarType, ctx.Category, part)" tooltip="Toebehoren"><i
            class="fas fa-plus"></i></button>
      </div>
      <div class="col">
        <app-item-count *ngIf="!selected.observers.length" [center]="true"
          [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"></app-item-count>
        <button *ngIf="selected.observers.length" class="btn btn-primary" (click)="selected.emit(part)">Kies dit
          artikel</button>
      </div>
    </div>
    <div class="mt-2 text-primary">
      <span *ngIf="part.SupportsGetCarTypesByPart" class="pointer px-1"
        (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Application)">
        <i class="fas fa-car"></i></span>
      <app-catalog-part-document-buttons [part]="part"></app-catalog-part-document-buttons>
    </div>
  </div>
  <div class="col-4">
    <div><span class="part-description">{{part.Brand}} {{part.Description}}</span>
      <span *ngIf="part.DescriptionLong" class="ps-1 pointer text-primary"
        (click)="mainService.msgBox('Extra informatie',part.DescriptionLong)"><i class="fad fa-file-alt"></i></span>
    </div>
    <app-catalog-parts-properties [partsProperties]="ctx.PartsProperties"
      [partsPropertyTranslations]="ctx.PartsPropertyTranslations" [shopKind]="ctx.ShopKind" [part]="part"
      [collapsedItemCount]="ctx.PartsPropertyCollapsedItemCount"></app-catalog-parts-properties>

  </div>
  <div class="col-3 align-self-center part-item-info">
    <app-item-info-template [busy]="ctx['busy']" [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"
      [nettoprijs]="ctx.Nettoprijs" [part]="part"></app-item-info-template>    
      <app-debug-sort-options *ngIf="hotkeyService.debugMode" [item]="part" [sortOption]="mainService.getActiveSort(ctx?.SortOptions)" [alternateDataKey]="'UniqueID'" [alternateData]="ctx.PartsCartItems"></app-debug-sort-options>
  </div>
</div>