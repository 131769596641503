export enum PartsPropertyUnitKind {
    Unknown = 0,
    Millimeter = 1,
    Volt = 2,
    AmpereHour = 3,
    Ampere = 4,
    Ohm = 5,
    Liter = 6,
    Pieces = 7,
    Watt = 8,
    Centimeter = 9,
    Meter = 10,
    Kilogram = 11,
    KiloNewton = 12,
    Milliliter = 13,
    Inch = 14,
    Wires = 15,
    SquareMillimeter = 16,
    CC = 17,
    Degrees = 18,
    Kilowatt = 19
}
