import { Component, Input, OnInit } from '@angular/core';

import { Animations } from '../../../_animations/animations';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';



@Component({
  selector: 'app-catalog-parts-card-view',
  templateUrl: './catalog-parts-card-view.component.html',
  styleUrls: ['./catalog-parts-card-view.component.scss'],
  animations: [Animations.enterLeaveBounce]
})
export class CatalogPartsCardViewComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  parts: Part[];

  constructor(
    public catalogService: CatalogService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.parts = this.catalogService.getPartsFromContext(this.ctx);
  }
}
