import { Component, Input, OnInit } from '@angular/core';
import { ShopModuleMenuItem } from '../../../../../_models/common/shop.module.menu.item';
import { ShopSoort } from '../../../../../_models/common/shop.soort';
import { ShopRouteConfig } from '../../../../../_models/common/shop.route.config';
import { ShopService } from '../../../../../_services/shop.service';
import { MainService } from '../../../../../_services/main.service';

@Component({
  selector: 'app-webshop-sub-menu',
  templateUrl: './webshop-sub-menu.component.html',
  styleUrls: ['./webshop-sub-menu.component.scss']
})
export class WebshopSubMenuComponent implements OnInit {
@Input() menuItem: ShopModuleMenuItem;
uniqueId = Math.random().toString(36).substring(2);

  constructor(
    public shopService: ShopService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }


}
