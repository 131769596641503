import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../_services/auth.service';
import { ShopPortalLogon } from '../_models/logon/shop.portal.logon';
import { Router } from '@angular/router';
import { ConfigData } from '../_models/common/config.data';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private inj: Injector
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/auth/logon') || request.url.endsWith('/api/Public/Context')
      || request.url.includes('app-config.json') || request.url.includes('version.json')) {
      return this.handleIntercept(null, request, next, 1);
    }
    const authService = this.inj.get(AuthService);
    return authService.getCurrentLogon()
      .pipe(mergeMap((logon: ShopPortalLogon) => {
        return this.handleIntercept(logon, request, next, 1)
          .pipe(mergeMap((response: HttpEvent<any>) => {
            if (response) { return of(response); }
            console.info(`Null response on '${request.url}'.... Get new logon and try again...`);
            return authService.getNewLogon(logon.Session)
              .pipe(mergeMap((newLogon: ShopPortalLogon) => {
                return this.handleIntercept(newLogon, request, next, 2);
              }));
          }));
      }));
  }

  private handleIntercept(logon: ShopPortalLogon, request: HttpRequest<any>, next: HttpHandler, tries: number): Observable<HttpEvent<any>> {

    let customReq: HttpRequest<any>;
    if (logon && logon.UniqueID) {
      const config = this.inj.get(ConfigData);
      var token = logon.Token;
      customReq = request.clone({
        headers: request.headers
          .set('Authorization', 'Bearer ' + token)
          // 2019-04-30 Maurice: gaat verkeerd met uploaden files en lijkt te werken zonder ;-)
          // .set('Content-Type', 'application/json')
          // .set('Auth-extra-password', extraPassword ? extraPassword : '')
      });
      // console.info("token", token);
    } else {
      customReq = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    return next
      .handle(customReq)
      .pipe(tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          // console.info('processing response', ev);
        }
      }))
      .pipe(catchError(response => {
        let log = true;
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            console.warn('De loginnaam of het wachtwoord is onjuist. Logout and redirect to /logout');
            log = false;
            const router = this.inj.get(Router);
            router.navigate(['/logout']);
            return observableThrowError(response);
          } else if (response.status === 404 && response.url.includes('app-config.json')) {
            log = false;
            return of(null);
          } else if (response.status === 404) {
            console.warn(`${response.url} niet gevonden!`);
            log = false;
            return observableThrowError(response);
          } else if (response.status === 0 && response.url.endsWith('/auth/logon')) {
            console.warn('Fout bij authenticatie');
            log = false;
            return observableThrowError(response);
          } else if (response.status === 410 && tries === 1) {
            console.warn('Session not found! Is Backend reset? Try to get new session...');
            log = false;
            return of(null);
          } else if (logon && tries === 1) {
            return of(null);
          }
        }
        if (log) { console.error('Processing http error', response); }
        return observableThrowError(response);
      }));
  }
}
