import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { MainService } from './main.service';
import { ContextMain } from '../_models/common/context.main';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { ApiService } from './api.service';
import { ShopSoort } from '../_models/common/shop.soort';
import { ResponseItem } from '../_models/item-info/response.item';
import { ChooseResponseItemComponent } from '../_common/choose-response-item/choose-response-item.component';
import { ContextCart } from '../_models/cart/context.cart';
import { OrderRequestParams } from '../_models/cart/order.request.params';
import { ShopOrderResponse } from '../_models/cart/shop.order.response';
import { Settings } from '../_models/common/settings';
import { ResponseItemTemplates } from '../_models/item-info/response.item.templates';
import { ShopService } from './shop.service';
import { TimingObject } from '../_models/common/timing.object';
import { SettingsService } from './settings.service';
import { CartItemsResponse } from '../_models/cart/cart.items.response';
import { VoorraadTemplateRendered } from '../_models/item-info/voorraad.template.rendered';
import { ShopPortalOrderResponse } from '../_models/cart/shop.portal.order.response';
import { ShoppingCartItemComposite } from '../_models/cart/shopping.cart.item.composite';

@Injectable()
export class CartService {
  busyUpdating = false;
  cartItemsSubscribed: ShoppingCartItem[] = [];

  constructor(
    private mainService: MainService,
    private modalService: BsModalService,
    private settingsService: SettingsService,
    private apiService: ApiService
  ) { }

  private getCtx(): ContextMain {
    return this.mainService.ctxMain;
  }

  getNetPriceHidden(): boolean {
    return this.settingsService.getSettingBoolean('HideNetPriceGlobally');
  }

  changeNetPriceOption() {
    this.settingsService.setSetting('HideNetPriceGlobally', !this.settingsService.getSettingBoolean('HideNetPriceGlobally'));
  }

  updateCart(withExternalCarts: boolean): void {
    if (this.getCtx()) {
      this.busyUpdating = true;
      this.apiService.getContextCart(withExternalCarts)
        .subscribe(ctxCart => {
          this.updateContextCart(ctxCart);
          this.busyUpdating = false;
        });
    }
  }

  updateSupplierAvailabilityCartItemsDictionary(cartItemsResponse: CartItemsResponse, busyObject: any, updateCallback: () => any = null) {
    busyObject['busy'] = 2;
    if (updateCallback) updateCallback();
    this.apiService.updateSupplierAvailabilityCartItemsDictionary(cartItemsResponse)
      .subscribe((updated: CartItemsResponse) => {
        if (updated?.CartItems && cartItemsResponse?.CartItems) {
          cartItemsResponse.CartItems.replaceKeys(updated.CartItems);
          TimingObject.updateFromTo(updated.Timing, cartItemsResponse.Timing);
          console.info('CartItemsResponse updated!');
        } else console.warn('CartItemsResponse update empty!');
        busyObject['busy'] = 0;
        if (updateCallback) updateCallback();
      });
  }

  syncCatalogCartItemsWithCart(items: { [key: string]: ShoppingCartItem }) {
    const cartItems = this.getCartItems();
    if (cartItems && cartItems.length > 0) {
      Object.keys(items).forEach(key => {
        const sci = this.getShoppingCartItemFromCart(items[key]);
        if (sci) { items[key] = sci; }
      });
    }
  }

  clearExternalCarts(): void {
    if (this.getCtx()) {
      this.busyUpdating = true;
      this.apiService.clearExternalCarts()
        .subscribe(ctxCart => {
          this.updateContextCart(ctxCart);
          this.busyUpdating = false;
        });
    }
  }

  getCartItemCount(): number {
    let aantal = 0;
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      for (const sci of this.getCtx().ContextCart.CartItems) {
        aantal += sci.Aantal;
      }
    }
    return aantal;
  }

  getCartContext(): ContextCart {
    if (this.getCtx() && this.getCtx().ContextCart) {
      return this.getCtx().ContextCart;
    }
    return null;
  }

  getCartContextTiming(): TimingObject {
    if (this.getCtx() && this.getCtx().ContextCart) {
      return this.getCtx().ContextCart.Timing;
    }
    return null;
  }

  getCartItems(): ShoppingCartItem[] {
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      return this.getCtx().ContextCart.CartItems;
    }
    return null;
  }

  private getCartTotalField(field: string): number {
    let amount = 0;
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      for (const sci of this.getCtx().ContextCart.CartItems) {
        amount += sci.Aantal * sci[field];
      }
    }
    return amount;
  }

  private getCartTotalTemplateValueByDescription(description: string): number {
    let amount = 0;
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      for (const sci of this.getCtx().ContextCart.CartItems) {
        const part = this.getTemplatePartByDescription(sci?.ItemInfo, description, true);
        if (!part?.Value) return 0;
        amount += sci.Aantal * part.Value;
      }
    }
    return amount;
  }

  getCartTotalGross(): number {
    return this.getCartTotalField('Brutoprijs');
  }

  getCartTotalNet(): number {
    return this.getCartTotalField('Nettoprijs');
  }

  getShoppingCartItemIndex(cartItems: ShoppingCartItem[]
    , id: number, internalItemNumber: number, itemNumber: string, itemGroup: number): number {
    for (let index = 0; index < cartItems.length; index++) {
      if ((id && cartItems[index].ID === id) ||
        (internalItemNumber && cartItems[index].InternArtikelnr === internalItemNumber) ||
        (itemNumber && cartItems[index].Artikelnr === itemNumber && cartItems[index].Artikelgroep === itemGroup)) {
        return index;
      }
    }
    return -1;
  }

  isCartTotalNetLocked(shopService: ShopService) {
    let locked = false;
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems && this.getCtx().ContextCart.NettoprijsOpties) {
      for (const sci of this.getCtx().ContextCart.CartItems) {
        if (!this.getCtx().ContextCart.NettoprijsOpties[shopService.getShopKindName(this.getCtx(), sci.Herkomst)]) {
          locked = true;
        }
      }
    }
    return locked;
  }

  private getShoppingCartItemIndexByCartItem(sci: ShoppingCartItem): number {
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      return this.getShoppingCartItemIndex(
        this.getCtx().ContextCart.CartItems,
        sci.ID,
        sci.InternArtikelnr,
        sci.Artikelnr,
        sci.Artikelgroep
      );
    }
    return -1;
  }

  getShoppingCartItemFromCart(sci: ShoppingCartItem): ShoppingCartItem {
    if (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.CartItems) {
      const index = this.getShoppingCartItemIndexByCartItem(sci);
      if (index !== -1) {
        if (this.getCtx().ContextCart.CartItems[index] === sci) { return sci; }
        if (!this.getCtx().ContextCart.CartItems[index].Kenteken) { this.getCtx().ContextCart.CartItems[index].Kenteken = sci.Kenteken; }
        return this.getCtx().ContextCart.CartItems[index];
      }
    }
    return null;
  }

  getShoppingCartItemCountFromCart(sci: ShoppingCartItem): number {
    if (sci.TimeoutItemCount) { return sci.TimeoutItemCount; }
    const sciCart = this.getShoppingCartItemFromCart(sci);
    if (sciCart) { return sciCart.Aantal; }
    if (!sci.TimeoutID) { sci.Aantal = 0; }
    return sci.Aantal;
  }

  setShoppingCartItemCount(sci: ShoppingCartItem, itemCount: string, perQuantity = 1) {
    let count = +itemCount;
    if (perQuantity > 1) {
      count = Math.ceil(count / perQuantity) * perQuantity;
    }
    const delta = count - this.getShoppingCartItemCountFromCart(sci);
    this.changeShoppingCartItemCount(sci, delta);
  }

  extend() {
    for (let i = 1; i < arguments.length; i++) {
      for (const key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          arguments[0][key] = arguments[i][key];
        }
      }
    }
    return arguments[0];
  }

  addToFrontendCart(sci: ShoppingCartItem) {
    this.getCtx().ContextCart.CartItems.push(sci);
  }

  changeShoppingCartItemCount(sci: ShoppingCartItem, delta: number) {
    let delay = 1000;
    let sciCart = this.getShoppingCartItemFromCart(sci);
    if (!sciCart) {
      if (!sci.TimeoutID) { sci.Aantal = 0; }
      // sciCart = jQuery.extend(true, {}, sci);
      sciCart = JSON.parse(JSON.stringify(sci));
      this.getCtx().ContextCart.CartItems.push(sciCart);
    }
    const aantal = Math.max(Number(sciCart.Aantal) + delta, 0);
    if (sci.TimeoutID) { window.clearTimeout(sci.TimeoutID); }
    if (aantal === 0) {
      const index = this.getShoppingCartItemIndexByCartItem(sciCart);
      if (index !== -1) { this.getCtx().ContextCart.CartItems.splice(index, 1); }
      delay = 1;
    }
    sciCart.Aantal = aantal;
    sci.Aantal = aantal;
    sci.TimeoutID = window.setTimeout((): void => {
      sci.TimeoutItemCount = sciCart.Aantal;
      this.busyUpdating = true;
      this.apiService.changeShoppingCartItem(this, sciCart)
        .subscribe((result) => {
          sci.TimeoutItemCount = 0;
          this.busyUpdating = false;
        });
      sci.TimeoutID = null;
      sciCart.TimeoutID = null;
    }, delay);
    sciCart.TimeoutID = sci.TimeoutID;
  }

  addItemToCart(itemNumber: string, itemGroup: number, description: string, itemCount: number, grossPrice: number
    , carType: string, licensePlate: string, sourceShop: ShopSoort): ShoppingCartItem {
    const sci = new ShoppingCartItem();
    sci.Artikelnr = itemNumber;
    sci.Artikelgroep = itemGroup;
    sci.Omschrijving = description;
    sci.Autotype = carType;
    sci.Kenteken = licensePlate;
    sci.Herkomst = sourceShop;
    sci.Aantal = 0;
    sci.Punten = 0;
    sci.Brutoprijs = grossPrice;
    sci.Nettoprijs = 0;
    this.changeShoppingCartItemCount(sci, itemCount);
    return sci;
  }

  deleteShoppingCartItem(sci: ShoppingCartItem) {
    this.changeShoppingCartItemCount(sci, -sci.Aantal);
  }

  isCartEmpty(): boolean {
    return this.getCartItemCount() === 0;
  }

  orderShoppingCart(order: OrderRequestParams): Observable<ShopPortalOrderResponse> {
    this.busyUpdating = true;
    return this.apiService.orderShoppingCart(order)
      .pipe(
        mergeMap(response => {
          this.busyUpdating = false;
          if (!response?.ErrorMessage) this.getCtx().ContextCart.CartItems = [];
          return of(response);
        }),
        catchError(error => {
          this.mainService.msgBox('Let op!', 'We kregen geen antwoord, maar de bestelling kan wel binnen zijn! Raadpleeg uw grossier.');
          return of(new ShopPortalOrderResponse());
        })
      );
  }

  checkOrder(timestamp: Date): Observable<ShopPortalOrderResponse> {
    this.busyUpdating = true;
    return this.apiService.checkOrder(timestamp)
      .pipe(
        mergeMap(response => {
          this.busyUpdating = false;
          return of(response);
        }),
        catchError(error => {
          this.mainService.msgBox('Let op!', 'We kregen geen antwoord, maar de bestelling kan wel binnen zijn! Raadpleeg uw orderhistorie.');
          return of(new ShopPortalOrderResponse());
        })
      );
  }

  emptyShoppingcart(): Observable<boolean> {
    this.busyUpdating = true;
    return this.apiService.emptyShoppingcart()
      .pipe(mergeMap(ctxCart => {
        this.updateContextCart(ctxCart);
        this.busyUpdating = false;
        return of(ctxCart.CartItems.length === 0);
      }));
  }

  setBusyItemCounts(currentCartItems: ShoppingCartItem[], newCartItems: ShoppingCartItem[]) {
    for (const sci of currentCartItems) {
      if (sci.TimeoutID) {
        for (const newSci of newCartItems) {
          if (sci.ID === newSci.ID) { newSci.Aantal = sci.Aantal; }
        }
      }
    }
  }

  updateContextCart(cartCtx: ContextCart): void {
    if (this.getCtx() && cartCtx) {
      console.info('updateContextCart -> ', cartCtx);
      this.setBusyItemCounts(this.getCtx().ContextCart.CartItems, cartCtx.CartItems);
      this.getCtx().ContextCart = cartCtx;
    }
  }

  isLoyaltyPointsActive(): boolean {
    return (this.getCtx() && this.getCtx().ContextCart && this.getCtx().ContextCart.LoyaltyShop != null);
  }

  getLoyaltyPointsName(): string {
    if (this.isLoyaltyPointsActive()) { return this.getCtx().ContextCart.LoyaltyShop.NameOfPoints; }
    return '';
  }

  getLoyaltyPointsInCart(): number {
    let punten = 0;
    if (this.getCtx().ContextCart.CartItems) {
      for (const sci of this.getCtx().ContextCart.CartItems) {
        punten += sci.Aantal * sci.Punten;
      }
    }
    return punten;
  }

  getLoyaltyPoints(): number {
    let punten = 0;
    if (this.getCtx().ContextCart.LoyaltyShop) {
      punten += this.getCtx().ContextCart.LoyaltyShop.Points - this.getLoyaltyPointsInCart();
    }
    return punten;
  }

  hasAlternatives(sci) {
    return (sci.ItemInfo && sci.ItemInfo.Alternatives && sci.ItemInfo.Alternatives.length > 0);
  }

  chooseAlternative(sci: ShoppingCartItem, nettoprijs: boolean) {
    if (this.hasAlternatives(sci)) {
      this.chooseResponseItem(
        nettoprijs,
        'Kies een alternatief artikel',
        sci.ItemInfo.Alternatives
      ).subscribe(alternatief => {
        if (alternatief) {
          sci.InternArtikelnr = alternatief.InternalItemNumber;
          sci.Artikelnr = alternatief.ItemNumber;
          sci.Artikelgroep = alternatief.ItemGroup;
          sci.Omschrijving = alternatief.Description;
          sci.Brutoprijs = alternatief.Price.GrossPrice;
          sci.Nettoprijs = alternatief.Price.NetPrice;
          sci.ItemInfo = alternatief;
          this.apiService.chooseAlternative(this, sci, alternatief);
        }
      });
    }
  }

  isLoyaltyShopItem(sci) {
    return sci.Herkomst === ShopSoort.Bonusshop;
  }

  isStockOk(item: ResponseItem, onlyStockSupplier: boolean) {
    if (item && item.Availability) {
      let stock = item.Availability.TotalSupplierStock;
      if (!onlyStockSupplier) {
        stock += item.Availability.TotalBranchStock;
      }
      return stock >= item.Availability.RequestItemCount;
    }
    return false;
  }

  getResponseItemsFromCartItems(cartItems: ShoppingCartItem[]): ResponseItem[] {
    const items: ResponseItem[] = [];
    for (const sci of cartItems) {
      if (sci.ItemInfo) { items.push(sci.ItemInfo); }
    }
    return items;
  }

  chooseResponseItem(nettoprijs: boolean, title: string, items: ResponseItem[]): Observable<ResponseItem> {
    if (items.length === 0) { return of(null); }
    if (items.length === 1) { return of(items[0]); }
    const initialState = {
      nettoprijs: nettoprijs,
      title: title,
      items: items,
      mainService: this.mainService
    };
    const modalRef = this.modalService.show(ChooseResponseItemComponent, { initialState, class: 'modal-lg' });
    return modalRef.content.onClose;
  }

  doCartSlide(ctx: ContextMain) {
    if (ctx) {
      ctx.ShowCartContent = !ctx.ShowCartContent;
      this.apiService.saveSettings(new Settings('ShowCartContentDefault', ctx.ShowCartContent.toString()));
      this.mainService.fireReziseEvent();
    }
  }

  public getFirstItemInfoFromCartItems(cartItems: ShoppingCartItem[]): ResponseItem {
    if (cartItems && cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].ItemInfo) { return cartItems[i].ItemInfo; }
      }
    }
    return null;
  }

  public getTemplatesFromFirstCartItem(cartItems: ShoppingCartItem[]): ResponseItemTemplates {
    if (cartItems && cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        const templates = this.getTemplatesFromCartItem(cartItems[i]);
        if (templates) { return templates; }
      }
    }
    return null;
  }

  public getTemplatesFromCartItem(sci: ShoppingCartItem): ResponseItemTemplates {
    if (sci && sci.ItemInfo && sci.ItemInfo.Templates) {
      return sci.ItemInfo.Templates;
    }
    return null;
  }

  public getTemplatesFromItemInfo(itemInfo: ResponseItem): ResponseItemTemplates {
    if (itemInfo) { return itemInfo.Templates; }
    return null;
  }

  public getTemplateParts(itemInfo: ResponseItem, withNetPrice: boolean) {
    if (withNetPrice) { return itemInfo?.Templates?.Parts; }
    return itemInfo.Templates.NettoHidden.Parts;
  }

  public getTemplatePartById(itemInfo: ResponseItem, id: number, withNetPrice: boolean): VoorraadTemplateRendered {
    const parts = this.getTemplateParts(itemInfo, withNetPrice);
    return parts.find(i => i.Id === id);
  }

  public getTemplatePartByDescription(itemInfo: ResponseItem, description: string, withNetPrice: boolean): VoorraadTemplateRendered {
    const parts = this.getTemplateParts(itemInfo, withNetPrice);
    if (parts) return parts.find(i => i.Description.toLowerCase() === description.toLowerCase());
    return null;
  }

  getCompositeItem(sci: ShoppingCartItem): ShoppingCartItemComposite {
    return sci as ShoppingCartItemComposite;
  }

  getTotalValueByTemplatePartDescription(sci: ShoppingCartItem, description: string): number {
    const scic = this.getCompositeItem(sci) as ShoppingCartItemComposite;
    if (scic.CompositeItemContent?.length) {
      let value = 0;
      this.getCompositeItem(sci).CompositeItemContent.forEach(i => {
        value += this.getTotalValueByTemplatePartDescription(i, description);
      });
      return value;
    } else {
      const part = this.getTemplatePartByDescription(sci?.ItemInfo, description, true);
      if (part && part.Value) return sci?.Aantal * part.Value;
    }
    return 0;
  }

  deliveryMomentChoiceNeeded(cartItems: ShoppingCartItem[]): boolean {
    let choiceNeeded = false;
    if (cartItems && cartItems.length > 0) {
      for (const sci of cartItems) {
        if (!sci.ItemInfo || !sci.ItemInfo.Availability || sci.ItemInfo.Availability.DefaultBranchStock < sci.Aantal) {
          choiceNeeded = true;
        }
      }
    }
    return choiceNeeded;
  }

  getResponseItemTemplates(cartItems: ShoppingCartItem[], sci: ShoppingCartItem, responseItem: ResponseItem): ResponseItemTemplates {
    let itemInfo: ResponseItem;
    if (sci) {
      const cartSci = this.getShoppingCartItemFromCart(sci);
      if (cartSci && cartSci.ItemInfo && cartSci.ItemInfo) {
        itemInfo = cartSci.ItemInfo;
      } else {
        itemInfo = sci.ItemInfo;
      }
    }
    if (!itemInfo && responseItem) { itemInfo = responseItem; }
    if (itemInfo && itemInfo.Templates) {
      return itemInfo.Templates;
    } else {
      const item = this.getFirstItemInfo(cartItems);
      if (item) { return item.Templates; }
    }
    return null;
  }

  getFirstItemInfo(cartItems: ShoppingCartItem[]): ResponseItem {
    if (cartItems && cartItems.length > 0) {
      let index = 0;
      while (index < cartItems.length) {
        if (cartItems[index].ItemInfo) { return cartItems[index].ItemInfo; }
        index++;
      }
    }
    return null;
  }

  getCartItemTextLines(sci: ShoppingCartItem, position: number): string[] {
    if (sci?.Tekstregels?.Regels?.length) {
      return sci.Tekstregels.Regels
        .filter(r => r.Positie === position)
        .map(i => i.Tekst);
    }
    return null;
  }

}
