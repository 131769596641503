<div class="modal-header center">
  <h3 class="modal-title">Artikel Zoeken</h3>
</div>
<div class="modal-body text-bg-light">
  <app-admin-typeahead-item [labelCols]="4" [dividerKind]="1" [label]="'Artikel'" [model]="_taItem" [focusInput]="focusInput" [searchInDescription]="false"></app-admin-typeahead-item>
  <app-admin-number-input [labelCols]="4" [dividerKind]="1" [label]="'Aantal'" [(model)]="_aantal">
  </app-admin-number-input>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i> Opslaan</button>
</div>
