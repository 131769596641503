export class ItemInfoWarnings {
  StockInfo_BlockedSupplierKinds: string[];
  StockInfo_NoCredentials: boolean;

  static anyWarnings(warnings: ItemInfoWarnings): boolean {
    if (warnings?.StockInfo_NoCredentials || warnings?.StockInfo_BlockedSupplierKinds?.length) {
      return true;
    }
    return false;
  }
}