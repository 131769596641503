<app-admin-form [title]="'Instellingen Aldoc'" [object]="settings">
  <app-admin-checkbox [label]="'Aktief'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-checkbox>
  <app-admin-dropdown [label]="'Soort API'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_ApiKind').Value" [modelValues]="[0,1]" [modelDisplayValues]="{'0':'Versie 6','1':'Versie 7'}" [disabled]="!adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-dropdown>
  <app-admin-input [label]="'MMT Url'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_MmtUrl').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-input>
  <app-admin-input [label]="'Catalog Url'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_CatalogUrl').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-input>
  <app-admin-checkbox [label]="'Stuklijst/toebehoren samenvoegen in de artikellijst'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_MergeSubParts').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-checkbox>
  <app-admin-dropdown [label]="'Taal'" [(model)]="adminSettingsService.getAdminSetting(settings,'AldocCatalog_Language').Value" [modelValues]="['nl','fr','en','de']" [modelDisplayValues]="{'nl':'Nederlands','fr':'Frans','en':'Engels','de':'Duits'}" [disabled]="!adminSettingsService.getAdminSetting(settings,'AldocCatalog_Enabled').Value"></app-admin-dropdown>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>
  