<div *ngIf="ctx" class="card-body">
    <div class="row">
        <div class="col-sm-12">
            <app-catalog-part-detailed *ngFor="let part of (parts | catalogPartsFilter: catalogService:ctx | filterCount: { object: filterResult, key: 'count' } | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.PartsCartItems | maxItemsFilter:_maxItemCount ); let odd=odd;" [odd]="odd" [ctx]="ctx" [part]="part"></app-catalog-part-detailed>
            <app-show-more-button [(currentItemCount)]="_maxItemCount" [showPerCount]="maxItems" [totalItemCount]="parts.length"></app-show-more-button>
            <div *ngIf="filterResult.count === 0" class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        <i class="fas fa-exclamation-triangle"></i> Er bleven geen artikelen over aan de hand van de volgende selectie keuzes:
                        <br />
                        <br />
                        <ul class="">
                            <li *ngFor="let selectie of catalogService.getFilterDescription(ctx, '')">{{selectie}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>