import { Component, OnInit, Input } from '@angular/core';
import { BranchModel } from '../../../../../_models/common/branch.model';
import { ResponseItemAvailabilityBranchInfo } from '../../../../../_models/item-info/response.item.availability.branch.info';

@Component({
  selector: 'app-item-info-branch-availability',
  templateUrl: './item-info-branch-availability.component.html',
  styleUrls: ['./item-info-branch-availability.component.scss']
})
export class ItemInfoBranchAvailabilityComponent implements OnInit {
  @Input() branchInfo: ResponseItemAvailabilityBranchInfo;
  @Input() branches: { [key: number]: BranchModel; };

  constructor() { }

  ngOnInit(): void {
  }

  getBranch(branchNumber: number): BranchModel {
    if (this.branches[branchNumber]) { return this.branches[branchNumber]; }
    return null;
  }

}
