import { Component, OnInit, Input } from '@angular/core';

import { Part } from '../../../_models/catalog/part';
import { ShopService } from '../../../_services/shop.service';
import { MainService } from '../../../_services/main.service';
import { Animations } from '../../../_animations/animations';
import { ShopSoort } from '../../../_models/common/shop.soort';


@Component({
  selector: 'app-catalog-parts-properties',
  templateUrl: './catalog-parts-properties.component.html',
  styleUrls: ['./catalog-parts-properties.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CatalogPartsPropertiesComponent implements OnInit {
  @Input() part: Part;
  @Input() partsProperties: { [key: string]: { Item1: string, Item2: string }[] };
  @Input() partsPropertyTranslations: { [key: string]: { [key: string]: string } };
  @Input() shopKind: ShopSoort;
  @Input() collapsedItemCount: number;

  constructor(
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
    if (!this.collapsedItemCount) { this.collapsedItemCount = 0; }
  }

}
