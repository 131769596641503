import { AfterContentInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Directive, Input, ElementRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TyreSize } from '../_models/tyres/tyre.size';
import { doGetCaretPosition } from '../_lib/common.typescript.lib';

@Directive({
    selector: '[appBandenMaat]'
})
export class BandenMaatDirective implements AfterContentInit {
    @Output() ngModelChange = new EventEmitter<string>();

    @HostListener('keypress', ['$event'])
    handleKeyPress(event) {
        let inputValue: string = this.elementRef.nativeElement.value;
        let selectionStart = doGetCaretPosition(this.elementRef.nativeElement);
        if (this.isTextSelected(this.elementRef.nativeElement)) { inputValue = ''; }
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (pattern.test(inputChar)) { inputValue = inputValue.substring(0, selectionStart) + inputChar + inputValue.substring(selectionStart); }
        event.preventDefault();
        const stripped = inputValue.replace('/', '').replace('R', '');
        const size = TyreSize.getFromString(stripped);
        if (size) {
            const bandenmaat = size.toString();
            if (this.elementRef.nativeElement.value !== bandenmaat) {
                this.elementRef.nativeElement.value = bandenmaat;
                this.ngModelChange.emit(this.elementRef.nativeElement.value);
            }
        }
    }

    constructor(
        private elementRef: ElementRef
    ) {
    }

    public ngAfterContentInit(): void { }

    isTextSelected(input: HTMLInputElement): boolean {
        if (typeof input.selectionStart === 'number') {
            return input.selectionStart === 0 && input.selectionEnd === input.value.length;
        }
        return false;
    }


}
