import { Component, OnInit, Input } from '@angular/core';
import { Animations } from '../../../../../_animations/animations';

@Component({
  selector: 'app-catalog-line-checkbox',
  templateUrl: './catalog-line-checkbox.component.html',
  styleUrls: ['./catalog-line-checkbox.component.scss'],
  animations: [Animations.slideInOut]
})
export class CatalogLineCheckboxComponent implements OnInit {
  @Input() keyValuePair: { key: any, value: any };
  _filter: RegExp;
  get filter(): RegExp {return this._filter;}
  @Input('filter')
  set filter(value: RegExp) {
    this._filter = value;
    this.hasFilterHit = this.getFilterHit(this.keyValuePair, this.depth);
  }
  @Input() parentFilterHit: boolean;
  @Input() parentKey: string;
  @Input() fixedCatalog: string;
  @Input() uniqueId;
  @Input() depth: number;
  collapsed = true;
  hasFilterHit = false;

  constructor() { }

  ngOnInit() {
    if (!this.uniqueId) { this.uniqueId = Math.random().toString(36).substring(2); }
  }

  getUniqueKey(): string {
    if (this.parentKey) { return `${this.parentKey}_${this.keyValuePair.key}_${this.uniqueId}`; }
    return `${this.keyValuePair.key}_${this.uniqueId}`;
  }

  getValue(obj: any, depth: number): number {
    const keys = Object.keys(obj);
    if (keys && keys.length > 0) {
      let indeterminate = false;
      let sum = 0;
      let invisible = 0;
      keys.forEach(key => {
        if (!this.fixedCatalog || depth !== 1 || key === this.fixedCatalog) {
          const value = this.getValue(obj[key], depth + 1);
          if (value < 0) { indeterminate = true; }
          sum += value;
        } else {
          invisible++;
        }
      });
      if (indeterminate) { return -1; }
      if (sum === 0) { return 0; }
      if (sum === (keys.length - invisible)) { return 1; }
      return -1;
    }
    return obj === true ? 1 : 0;
  }

  setValue(obj: any, value: boolean, depth: number) {
    const keys = Object.keys(obj);
    if (keys && keys.length > 0) {
      keys.forEach(key => {
        if (depth < 2) {
          if (!this.fixedCatalog || depth !== 1 || key === this.fixedCatalog) {
            // console.info(`setValue=${value} fixedCatalog=${this.fixedCatalog} depth=${depth} key=${key}`);
            this.setValue(obj[key], value, depth + 1);
          } else {
            // console.info(`NOT setValue=${value} fixedCatalog=${this.fixedCatalog} depth=${depth} key=${key}`);
          }
        } else {
          obj[key] = value;
        }
      });
    }
  }

  needsCollapse(): boolean {
    if (this.fixedCatalog) { return this.depth < 1; }
    return this.depth < 2;
  }

  change(value: boolean) {
    // const originalValue = (this.getValue(this.keyValuePair.value, this.depth) === 0);
    this.setValue(this.keyValuePair.value, value, this.depth);
  }

  isThisFilterHit(kvp: { key: string, value: any }): boolean {
    return (kvp.key && this._filter && kvp.key.search(this.filter) !== -1);
  }

  getFilterHit(kvp: { key: string, value: any }, depth: number): boolean {
    if (!this.depth) { this.depth = 0; }
    let hit = this.parentFilterHit;
    if (!hit && this._filter) {
      if (this.isThisFilterHit(kvp)) { hit = true; }
      if (!hit) {
        const keys = Object.keys(kvp.value);
        if (keys && keys.length > 0) {
          keys.forEach(key => {
            if (key.search(this.filter) !== -1) { hit = true; }
            if (!hit && depth < 1) {
              if (this.getFilterHit({ key: null, value: kvp.value[key] }, depth + 1)) { hit = true; }
            }
          });
        }
      }
    }
    return hit;
  }

  getCatalogs(obj: any, depth: number): string[] {
    const keys = Object.keys(obj);
    if (keys && keys.length > 0) {
      if (depth < 1) {
        const catalogs: string[] = [];
        keys.forEach(key => {
          let cats: string[] = null;
          if (depth < 0) {
            cats = this.getCatalogs(obj[key], depth + 1);
          } else if (depth === 0) {
            cats = Object.keys(obj[key]);
          }
          if (cats && cats.length > 0) {
            cats.forEach(cat => {
              if (!catalogs.includes(cat)) { catalogs.push(cat); }
            });
          }
        });
        return catalogs;
      } else if (depth === 1) {
        return keys;
      }
    }
    return [];
  }

}
