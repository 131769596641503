import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';
import { ContextTyres } from '../../../_models/tyres/context.tyres';
import { WheelTyreSet } from '../../../_models/rims-and-tyres/wheel.tyre.set';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { ShopSoort } from '../../../_models/common/shop.soort';

@Component({
  selector: 'app-tyre-list-item',
  templateUrl: './tyre-list-item.component.html',
  styleUrls: ['./tyre-list-item.component.scss']
})
export class TyreListItemComponent implements OnInit {
  @Input() ctx: ContextTyres;
  @Input() tyre: ShoppingCartItem;
  @Input() set: WheelTyreSet;
  @Input() odd: boolean;
  shopKind = ShopSoort;

  constructor(
    public mainService: MainService,
    public rimAndTyreService: RimAndTyreService,
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
  }

}
