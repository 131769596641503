import { Component, OnInit, Input } from '@angular/core';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { CatalogService } from '../../../_services/catalog.service';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-catalog-part-list',
  templateUrl: './catalog-part-list.component.html',
  styleUrls: ['./catalog-part-list.component.scss']
})
export class CatalogPartListComponent implements OnInit {
  _maxItemCount: number;
  @Input() maxItems: number;
  @Input() ctx: ContextCatalog;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
    this._maxItemCount = this.maxItems;
  }

}
