import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-grid-webshop',
  templateUrl: './grid-webshop.component.html',
  styleUrls: ['./grid-webshop.component.scss']
})
export class GridWebshopComponent implements OnInit {


  @Input() isBusy = false;
  @Input() enableRowSelection = false;

  @Input() items;
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  selectedIndex: number;

  private rowHeaderContainer: ElementRef;
  @ViewChild('rowHeaderContainer') set setRowHeaderContainer(content: ElementRef) { this.rowHeaderContainer = content; }
  private rowsContainer: ElementRef;
  @ViewChild('rowsContainer') set setRowsContainer(content: ElementRef) { this.rowsContainer = content; }

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
  withRowClick: boolean;

  @Input() emptyText: string;

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.selectedIndex = -1;
    this.withRowClick = (this.rowClick.observers.length > 0);
  }

  rowClickInternal(item: any, index: number): void {
    if (this.withRowClick) {
      this.selectedIndex = index;
      this.rowClick.emit(item);
    }
  }
}
