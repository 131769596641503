import { Component, OnInit, Input } from '@angular/core';
import { ShopModuleMenuItem } from '../../../_models/common/shop.module.menu.item';
import { ShopService } from '../../../_services/shop.service';
import { MainService } from '../../../_services/main.service';
import { Animations } from '../../../_animations/animations';

@Component({
  selector: 'app-side-menu-doetam-button',
  templateUrl: './side-menu-doetam-button.component.html',
  styleUrls: ['./side-menu-doetam-button.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class SideMenuDoetamButtonComponent implements OnInit {
  @Input() menuItem: ShopModuleMenuItem;
  @Input() parentMenu: ShopModuleMenuItem[];
  @Input() btnClass: string;

  constructor(
    private shopService: ShopService,
    private mainService: MainService
  ) { }

  ngOnInit() {
  }

  collapseRestParent(thisMenu: ShopModuleMenuItem): void {
    for (const item of this.parentMenu) {
      if (item !== thisMenu) {
        item['uncollapsed'] = false;
      }
    }
  }

  clickMenuItem(menuItem: ShopModuleMenuItem): void {
    if (menuItem.MenuItems) {
      if (!menuItem['uncollapsed']) {
        menuItem['uncollapsed'] = true;
      } else {
        menuItem['uncollapsed'] = false;
      }
      this.collapseRestParent(menuItem);
    } else {
      this.mainService.toggleMenuFloating(false);
      this.shopService.gotoShopModule(this.mainService.ctxMain, menuItem.ShopKind);
    }
  }
}
