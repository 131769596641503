<div class="card" [ngClass]="{'pointer': canClickButton()}" (click)="clickButton()">
  <ng-container [ngSwitch]="shopSoort">

    <div *ngSwitchDefault class="card-body button-body p-0 d-flex flex-wrap align-items-center">
      <img class="mx-auto d-block" [src]="'/assets/img/identity/star/home/module-'+shopSoort+'.png'" />
    </div>

    <div *ngSwitchCase="shopKind.Banden" class="card-body button-body p-0 d-flex flex-wrap">
      <div class="card-body-tyres">
        <img class="mx-auto d-block py-2" [src]="'/assets/img/identity/star/home/module-'+shopSoort+'.png'" />
        <div class="mx-auto d-block px-1">
          <div class="input-group input-group-sm">
            <input type="text" class="form-control" placeholder="bandenmaat..." appBandenMaat [appAutofocus]="true" [ngModel]="bandenmaat" (ngModelChange)="bandenmaat=$event" #inputTyreSize [appSelectOnclick] (keyup.enter)="searchTyre(bandenmaat)">
            <button class="btn btn-primary" type="button" (click)="searchTyre(bandenmaat)">
              <i class="far fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="shopKind.Artikelinformatie" class="card-body button-body p-0 d-flex flex-wrap">
      <div class="card-body-tyres">
        <img class="mx-auto d-block py-2 search-image" [src]="'/assets/img/identity/star/home/module-'+shopSoort+'.png'" />
        <div class="mx-auto d-block px-1">
          <div class="input-group input-group-sm">
            <input type="text" class="form-control" placeholder="zoek..." [(ngModel)]="search" #inputSearch [appSelectOnclick] (keyup.enter)="router.navigate(['/iteminfo', search])">
            <button class="btn btn-primary" type="button" (click)="router.navigate(['/iteminfo', search])">
              <i class="far fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <div class="card-footer footer text-bg-primary text-center">
    {{shopService.getShopModuleName(mainService.ctxMain, shopSoort) || 'ZOEKEN'}}
  </div>
</div>