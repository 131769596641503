export enum OrderTypeEnum {
  Extern = 0,
  Intern = 1,
  Mijngrossier = 2,
  YellowBox = 3,
  Partsnet = 4,
  Rechtstreeks = 5,
  Partsnetgo = 6,
  WebShop = 7,
  Offerte = 8,
  Internet = 9,
  Consignatie = 10,
  Retour = 11,
  Garantie = 12
}
