import { Component, OnInit, ElementRef, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Aanbieding } from '../../../_models/promotions/aanbieding';
import { PromotionService } from '../../../_services/promotion.service';
import { MainService } from '../../../_services/main.service';
import { Animations } from '../../../_animations/animations';
import { ResizeInfo } from '../../../_models/common/resize.info';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { AanbiedingImage } from '../../../_models/promotions/aanbieding.image';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.scss']
})
export class PromotionDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public promotionService: PromotionService,
    public mainService: MainService
  ) { }

  ngOnInit() {
  }


}
