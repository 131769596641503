<button *ngIf="!isZoomed" type="button" class="btn btn-primary position-absolute zoom-button"
  (click)="isZoomed=!isZoomed">
  <i class="fas fa-expand"></i></button>
<button *ngIf="isZoomed" type="button" class="btn btn-primary zoom-button top-right" (click)="isZoomed=!isZoomed">
  <i class="fas fa-compress"></i>
</button>
<div *ngIf="session" #thirdpartyContainer [ngClass]="{'main-container': !isZoomed, 'main-container-zoomed': isZoomed}">
  <div *ngIf="headerEnabled && !isZoomed && session.ctx" id="keepme" class="header px-1">
    <app-header-panel-inner [ctx]="session.ctx" [timing]="session.ctx?.Timing"
      [withoutCarType]="thirdpartyShopService.withoutCarType(session.shopSoort)"
      [hideBackClick]="thirdpartyShopService.withoutCarType(session.shopSoort)"
      (backClick)="carTypeService.backToCarType(session.ctx.ShopKind)">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col-sm-auto ps-2">
          <app-voorraad-prijs-opties [ctx]="session.ctx" (netPriceOptionChanged)="netPriceOptionChanged()"
            [saveSettingsDirect]="thirdpartyShopService.changedOptionsNeedsRefresh(session.shopSoort)">
          </app-voorraad-prijs-opties>
        </div>
      </div>
    </app-header-panel-inner>
    <div></div>
  </div>
</div>