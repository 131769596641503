import { Component, OnInit, Input } from '@angular/core';

import { ContextPromotions } from '../../../../_models/promotions/context.promotions';
import { MainService } from '../../../../_services/main.service';
import { PromotionService } from '../../../../_services/promotion.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';

@Component({
  selector: 'app-promotions-carousel-view',
  templateUrl: './promotions-carousel-view.component.html',
  styleUrls: ['./promotions-carousel-view.component.scss']
})
export class PromotionsCarouselViewComponent implements OnInit {
  @Input() ctx: ContextPromotions;
  @Input() shopKind: ShopSoort;

  constructor(
    public mainService: MainService,
    public promotionService: PromotionService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      (<any>$('.carousel')).carousel({ interval: 3000 });
      (<any>$('.carousel')).carousel('cycle');
      // (<any>$('.carousel')).on('slide.bs.carousel', function () {
      //   console.info('slide...');
      // });
    }, 500);
  }

}
