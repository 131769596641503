<div class="card card-light mb-3">
  <div class="card-body">
    <div class="row mb-2 row-height">
      <div class="col-2">
        <div class="btn-group-vertical w-100 h-100">
          <button class="btn btn-xs btn-outline-secondary" (click)="changeEnabled()">
            <span *ngIf="enabled"><i class="far fa-check-square"></i></span>
            <span *ngIf="!enabled"><i class="far fa-square"></i></span>
             Actief
          </button>
          <button class="btn btn-xs btn-outline-secondary"
            (click)="deleted.emit(itemGroup)"><i class="fas fa-trash-alt"></i>
            Verwijder</button>
        </div>
      </div>
      <div class="col-10">
        <app-admin-item-group-input [disabled]="!enabled" [model]="itemGroup" (modelChange)="itemGroupChange.emit($event)" [dividerKind]="-1" [stopSearchingOnBlur]="true"></app-admin-item-group-input>
      </div>
    </div>
    <div><app-admin-editor [disabled]="!enabled" [model]="message" (modelChange)="messageChange.emit($event)" [dividerKind]="-1"></app-admin-editor></div>
  </div>
</div>