import { RelationType } from './relation.type';
import { CustomerRegion } from './customer.region';
import { UserModel } from './user.model';
import { DeliveryModeKind } from '../cart/delivery.mode.kind';
import { SingleSignOnLogin } from '../admin/backendmanagement/single.sign.on.login';

export class CustomerModel {
    CustomerNumber: number;
    Description: string;
    UserID: number;
    BranchNumber: number;
    DefaultDeliveryMode: DeliveryModeKind;
    WebOrdersAutoProcess: boolean;
    WebOrdersAllowSplitting: boolean;
    Active: boolean;
    Name: string;
    Contact: string;
    Phone: string;
    Fax: string;
    Mobile: string;
    Email: string;
    StreetName: string;
    HouseNumber: string;
    PostalCode: string;
    City: string;
    Country: string;
    ShortName: string;
    CustomerType: RelationType;
    CustomerTypeWebAlias: RelationType;
    SalesBlock: boolean;
    CreditPeriod: number;
    CreditPeriodBlockageAtExtraDays: number;
    CreditPeriodBlockage: boolean;
    CreditLimit: number;
    CreditLimitBlockage: boolean;
    InvoiceSeparately: boolean;
    VAT: boolean;
    CurrencyCode: string;
    CurrencyRate: number;
    CardNumber: number;
    LegacyRoute: string;
    Region: CustomerRegion;
    Users: UserModel[];
    SingleSignOnLogins: SingleSignOnLogin[];
}