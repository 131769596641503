import { Component, OnInit, Input } from '@angular/core';
import { ToastModel } from '../../../_models/toaster/toast.model';
import { ToasterService } from '../../../_services/toaster.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  _toast: ToastModel;
  get toast(): ToastModel { return this._toast; }
  @Input('toast')
  set ctx(value: ToastModel) {
    this._toast = value;
    this.percentage = value.Percentage;
    this.duration = value.Duration;
    this.timestamp = value.Timestamp;
  }
  percentage: number;
  duration: number;
  timestamp: number;
  closeTimerHandle: number;

  constructor(
    public toasterService: ToasterService
  ) { }

  ngOnInit() {
    window.setTimeout(() => this.percentage = 0, 0);
    this.closeTimerHandle = window.setTimeout(() => this.toasterService.closeToast(this.toast), this.duration * 1000);
  }

  getPercentage(): number {
    if (this.duration && this.timestamp && this.toast.Duration) {
      const now = new Date();
      const ms = now.getTime() - this.timestamp;
      const perc = Math.floor((ms / this.toast.Duration / 10));
      return perc > 100 ? 0 : 100 - perc;
    }
    return 0;
  }

  pauseCloseTimer() {
    window.clearTimeout(this.closeTimerHandle);
    this.percentage = this.getPercentage();
    this.duration = 0;
  }

  resumeCloseTimer() {
    this.duration = this.toast.Duration * this.percentage / 100;
    window.setTimeout(() => this.percentage = 0, 0);
    this.closeTimerHandle = window.setTimeout(() => this.toasterService.closeToast(this.toast), this.duration * 1000);
    const now = new Date();
    this.timestamp = now.getTime() - ((this.toast.Duration - this.duration) * 1000);
  }

}
