<ng-container *ngIf="part?.Documents">
  <ng-container *ngIf="part.Documents.length<3 || show; else multiDocs">
    <button *ngFor="let doc of part.Documents" class="btn btn-xs btn-link" (click)="catalogService.openDocument(doc)"
      [ngClass]="{'btn-info': button, 'icon-button': button, 'btn-link': !button}">
      <i class="fas fa-file-invoice"></i></button>
    <button *ngIf="show" class="btn btn-xs btn-link" (click)="show = false"
      [ngClass]="{'btn-info': button, 'icon-button': button, 'btn-link': !button}">
      <i class="fas fa-chevron-left"></i></button>
  </ng-container>
  <ng-template #multiDocs>
    <button class="btn btn-xs btn-link" [ngClass]="{'btn-info': button, 'icon-button': button, 'btn-link': !button}"
      (click)="show = true">
      <i class="fas fa-file-invoice"></i>
    </button>
    <span class="badge badge-notify textbg-warning" style="font-size:10px;">{{part.Documents.length}}</span>
  </ng-template>
</ng-container>