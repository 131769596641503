import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ContextPublic } from '../../_models/common/context.public';
import { ChooseVersionComponent } from '../choose-version/choose-version.component';
import { MainService } from '../../_services/main.service';
import { Credentials } from '../../_models/logon/credentials';
import { AuthService } from '../../_services/auth.service';
import { SettingsService } from '../../_services/settings.service';

@Component({
  selector: 'app-login-default',
  templateUrl: './login-default.component.html',
  styleUrls: ['./login-default.component.scss']
})
export class LoginDefaultComponent implements OnInit {
  @Input() ctx: ContextPublic;
  @Output() wholesalerChange = new EventEmitter();
  isPwVisible = false;

  constructor(
    public mainService: MainService,
    public authService: AuthService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {

  }

  public keytab(event) {
    const element = event.srcElement.nextElementSibling; // get the sibling element
    if (element == null) {
      return;
    } else {
      element.focus();   // focus if not null
    }
  }

}
