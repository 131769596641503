import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-radiobutton',
  templateUrl: './admin-radiobutton.component.html',
  styleUrls: ['./admin-radiobutton.component.scss']
})
export class AdminRadiobuttonComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() radios: { [key: string]: any };
  @Input() centerLabel: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() disabled: boolean;
  objectKeys = Object.keys;
  uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
  }

  changeModel(value: any): void {
    this.modelChange.emit(value);
  }

}
