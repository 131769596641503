<div class="footer text-bg-dark py-3">
  <div class="container my-3">
    <div class="row">
      <div class="col-lg">
        <div class="footer-header pb-2">ASSORTIMENT</div>
        <ul class="footer-list mt-2">
          <li><a href="http://www.xtraparts.nu/assortiment/merken-overzicht.html" target="_new" class="text-white"><span>Merkenoverzicht</span></a></li>
          <li><a href="http://www.xtraparts.nu/assortiment/automaterialen.html" target="_new" class="text-white"><span>Automaterialen</span></a></li>
          <li><a href="http://www.xtraparts.nu/assortiment/banden-velgen.html" target="_new" class="text-white"><span>Banden en Velgen</span></a></li>
          <li><a href="http://www.xtraparts.nu/assortiment/gereedschap.html" target="_new" class="text-white"><span>Gereedschap</span></a></li>
          <li><a href="http://www.xtraparts.nu/assortiment/olie-en-vloeistoffen.html" target="_new" class="text-white"><span>Olie en vloeistoffen</span></a></li>
          <li><a href="http://www.xtraparts.nu/assortiment/verbruiksmaterialen.html" target="_new" class="text-white"><span>Verbruiksmaterialen</span></a></li>
        </ul>
      </div>
      <div class="col-lg">
        <div class="footer-header pb-2">SERVICE</div>
        <ul class="footer-list mt-2">
          <li><a href="http://www.xtraparts.nu/service/bestellen.html" target="_new" class="text-white"><span>Bestellen</span></a></li>
          <li><a href="http://www.xtraparts.nu/service/bezorging.html" target="_new" class="text-white"><span>Bezorging</span></a></li>
          <li><a href="http://www.xtraparts.nu/service/kentekenplaten.html" target="_new" class="text-white"><span>Kentekenplaten</span></a></li>
          <li><a href="http://www.xtraparts.nu/service/werkplaats.html" target="_new" class="text-white"><span>Werkplaats</span></a></li>
          <li><a href="http://www.xtraparts.nu/service/downloads.html" target="_new" class="text-white"><span>Downloads</span></a></li>
        </ul>
      </div>
      <div class="col-lg">
        <div class="footer-header pb-2">OVER ONS</div>
        <ul class="footer-list mt-2">
          <li><a href="http://www.xtraparts.nu/over-ons/wie-zijn-wij.html" target="_new" class="text-white"><span>Wie zijn wij</span></a></li>
          <li><a href="http://www.xtraparts.nu/over-ons/veel-gestelde-vragen.html" target="_new" class="text-white"><span>Veel Gestelde Vragen</span></a></li>
          <li><a href="http://www.xtraparts.nu/over-ons/hulp-op-afstand.html" target="_new" class="text-white"><span>Hulp Op Afstand</span></a></li>
          <li><a href="http://www.xtraparts.nu/over-ons/contactgegevens.html" target="_new" class="text-white"><span>Contactgegevens</span></a></li>
          <li><a href="http://www.xtraparts.nu/over-ons/downloads.html" target="_new" class="text-white"><span>Downloads</span></a></li>
        </ul>
      </div>
    </div>
    <footer> &copy; {{year}} &nbsp;<a href="http://www.xtraparts.nu" class="text-white" target="_blank">XtraParts automaterialen V.O.F. Dordrecht  &nbsp;|&nbsp;  </a><a href="http://www.xtraparts.nu/privacy" class="text-white" target="_blank"> Privacy Statement</a></footer>
  </div>
</div>
