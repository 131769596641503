import { Injectable } from '@angular/core';
import { ToastModel } from '../_models/toaster/toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toasts: ToastModel[] = [];
  defaultIcon = '/assets/img/identity/_default/favicon.ico?t=' + new Date().getTime();
  defaultDuration = 5;

  constructor() {
  }

  showToast(title: string, message: string, icon: string = null) {
    const t = new ToastModel();
    t.Title = title;
    t.MessageHtml = message;
    t.Timestamp = new Date().getTime();
    t.Duration = this.defaultDuration;
    t.Percentage = 100;
    if (this.defaultIcon) { t.Icon = this.defaultIcon; }
    if (icon) { t.Icon = icon; }
    this.toasts.unshift(t);
  }

  closeToast(toast: ToastModel) {
    const index = this.toasts.indexOf(toast);
    if (index !== -1) { this.toasts.splice(index, 1); }
  }


}
