import { Pipe, PipeTransform } from '@angular/core';
import { UniversalCarType } from '../../_models/car-types/universal.car.type';

@Pipe({
  name: 'universalCarTypeFilter'
})
export class UniversalCarTypeFilterPipe implements PipeTransform {

  containsFilter(texts: string[], filter: string) {
    for (const text of texts) {
      if (filter && text && text.toLowerCase().indexOf(filter) >= 0) { return true; }
    }
    return false;
  }

  transform(types: UniversalCarType[], filterString: string): UniversalCarType[] {
    if (!filterString) { return types; }
    return types.filter(type => {
      let ok = false;
      if (type) {
        const texts = [type.TypeName, type.TypeRemark, type.Model.ModelName];
        if (type.Model && type.Model.Brand) { texts.push(type.Model.Brand.BrandName); }
        if (type.LicensePlate && type.LicensePlate.Bare) {
          texts.push(type.LicensePlate.Bare);
          texts.push(type.LicensePlate.Formated);
        }
        if (this.containsFilter(texts, filterString)) { ok = true; }
      }
      return ok;
    });

  }


}
