import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CarTypeService } from '../../../_services/car-type.service';
import { MainService } from '../../../_services/main.service';
import { ApiService } from '../../../_services/api.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { CatalogService } from '../../../_services/catalog.service';
import { ActivatedRoute } from '@angular/router';
import { ShopSoort } from '../../../_models/common/shop.soort';

@Component({
  selector: 'app-catalog-groups',
  templateUrl: './catalog-groups.component.html',
  styleUrls: ['./catalog-groups.component.scss']
})
export class CatalogGroupsComponent implements OnInit {
  shopSoort = ShopSoort
  ctx: ContextCatalog;

  constructor(
    public mainService: MainService,
    private carTypeService: CarTypeService,
    private catalogService: CatalogService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.info('CatalogGroupsComponent ngOnInit()');
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.catalogGroups;
        console.info('CatalogGroupsComponent got data');
      });
    if (!this.route.snapshot.paramMap.get('carTypeId')) {
      const carType = this.carTypeService.currentCarType;
      this.catalogService.getCategories(carType)
        .subscribe(ctx => this.ctx = ctx);
    }
  }

}
