<ng-template #content><ng-content></ng-content></ng-template>
<div *ngIf="!isModal();else modal" class="card m-3" [ngClass]="{'full-height': fullHeight}">
  <div class="card-header text-bg-primary"
    (contextmenu)="mainService.showDebugInfo($event, object['Timing'], object)">
    {{title}} <span *ngIf="subTitle" class="subtitle text-white-50">{{subTitle}}</span>
    <div *ngIf="withSettingsSelector && object" class="float-end">
      <app-admin-settings-selector-info-link [uid]="getUserIdentificationModel()"
        [moreSpecificSettings]="object['MoreSpecificSettings']" (uidChanged)="uidChange($event)">
      </app-admin-settings-selector-info-link>
    </div>
  </div>
  <div *ngIf="!fetchingData && object; else loading;" class="card-body" [ngClass]="{'no-padding': noPadding}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <ng-container *ngIf="saveAction">
      <app-admin-cancel-save-button [saveAction]="saveAction" (beforeSaveAction)="beforeSave($event)"
        (cancelAction)="cancel($event)" [labelCols]="labelCols" [saveDisabled]="saveDisabled" [modal]="false">
      </app-admin-cancel-save-button></ng-container>
  </div>
</div>
<ng-template #loading>
  <app-centered-spinner [faSize]="'fa-3x'" [color]="'text-low'"></app-centered-spinner>
</ng-template>

<ng-template #modal>
  <div #header class="modal-header center text-bg-light" (contextmenu)="mainService.showDebugInfo($event, object['Timing'], object)">
    <h4 class="modal-title">{{title}}</h4><span *ngIf="subTitle" class="subtitle text-white-50">{{subTitle}}</span>
    <div *ngIf="withSettingsSelector && object" class="float-end">
      <app-admin-settings-selector-info-link [uid]="getUserIdentificationModel()"
        [moreSpecificSettings]="object['MoreSpecificSettings']" (uidChanged)="uidChange($event)">
      </app-admin-settings-selector-info-link>
    </div>
  </div>
  <div *ngIf="!fetchingData && object; else loading;" class="modal-body" [ngClass]="{'no-padding': noPadding}">
    <div [ngStyle]="getScrollStyle()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <div #footer class="modal-footer text-bg-light">
    <ng-container *ngIf="saveAction">
      <app-admin-cancel-save-button [saveAction]="saveAction" (beforeSaveAction)="beforeSave($event)"
        (cancelAction)="cancel($event)" [labelCols]="labelCols" [saveDisabled]="saveDisabled" [modal]="true">
      </app-admin-cancel-save-button>
    </ng-container>
  </div>
</ng-template>