import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';

import { FavoritesService } from '../../_services/favorites.service';
import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { mergeMap, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FavoritesDataResolverService  {

  constructor(
    private favoritesService: FavoritesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextCatalog> | Observable<never> {
    return this.favoritesService.getContextFavorites().pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
