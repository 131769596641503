import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterfaceData } from '../../../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../../../../_services/admin/admin-supplier-interface.service';
import { MainService } from '../../../../../../_services/main.service';
import { InterfaceKindInfoModel } from '../../../../../../_models/admin/supplier-interface/interface.kind.info.model';

@Component({
  selector: 'app-default-interface-data-settings-single',
  templateUrl: './default-interface-data-settings-single.component.html',
  styleUrls: ['./default-interface-data-settings-single.component.scss']
})
export class DefaultInterfaceDataSettingsSingleComponent implements OnInit {
  @Input() interfaceDatas: InterfaceData[];
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Input() interfaceData: InterfaceData;
  @Output() interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();

  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }

  isLinked(): boolean {
    return this.interfaceData.Url.toUpperCase().startsWith("LINKED:");
  }

  getLinked(): InterfaceKindInfoModel[] {
    if (this.supplierInterfaceData.InterfaceKindInfos && this.supplierInterfaceData.InterfaceKindInfos[this.interfaceData.IntType]
      && this.supplierInterfaceData.InterfaceKindInfos[this.interfaceData.IntType].LinkedInterfaceKinds?.length) {
      return this.supplierInterfaceData.InterfaceKindInfos[this.interfaceData.IntType].LinkedInterfaceKinds;
    }
    return null;
  }

  switch(interfaceData: InterfaceData) {
    interfaceData.IntType = interfaceData.IntType * -1;
    setTimeout(() => {
      this.interfaceDataChanged.emit(this.supplierInterfaceData);
    }, 500);
  }

}
