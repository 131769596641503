import { Component, Input, OnInit } from '@angular/core';
import { ContextMpm } from '../../../_models/mpm/context.mpm';
import { MpmPart } from '../../../_models/mpm/mpm.part';
import { MpmPartsCategory } from '../../../_models/mpm/mpm.parts.category';

@Component({
  selector: 'app-mpm-part',
  templateUrl: './mpm-part.component.html',
  styleUrls: ['./mpm-part.component.scss']
})
export class MpmPartComponent implements OnInit {
  @Input() mainCategory: MpmPartsCategory;
  @Input() category: MpmPartsCategory;
  @Input() part: MpmPart;
  @Input() ctx: ContextMpm;

  constructor() { }

  ngOnInit(): void {
  }

  partHasItems(part: MpmPart): boolean {
    var ok = false;
    part.Items.forEach(item => {
      //if (this.ctx.CartItems[item.articleNumber]) ok = true;
      ok = true;
    });
    return ok;
  }

  getIntervals(category: MpmPartsCategory): string[] {
    if (category && category.RecommendedProducts
      && category.RecommendedProducts[0].Intervals
      && category.RecommendedProducts[0].Intervals.length > 0
      && category.RecommendedProducts[0].Intervals[0]) {
      return category.RecommendedProducts[0].Intervals;
    }
    return null;
  }

  showManual(manual) {
    alert(manual);
  }

}
