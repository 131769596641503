import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { MainService } from '../../_services/main.service';
import { ResponseItem } from '../../_models/item-info/response.item';
import { ShoppingCartItem } from '../../_models/cart/shopping.cart.item';
import { CartService } from '../../_services/cart.service';
import { ResponseItemTemplates } from '../../_models/item-info/response.item.templates';
import { VoorraadTemplateRendered } from '../../_models/item-info/voorraad.template.rendered';
import { AvailabilityTemplateSubPartKind } from '../../_models/item-info/availability.template.sub.part.kind';
import { Part } from '../../_models/catalog/part';


@Component({
  selector: 'app-item-info-template',
  templateUrl: './item-info-template.component.html',
  styleUrls: ['./item-info-template.component.scss']
})
export class ItemInfoTemplateComponent implements OnInit {
  @Input() busy;
  @Input() cartItems: ShoppingCartItem[];
  @Input() sci: ShoppingCartItem;
  @Input() itemInfo: ResponseItem;
  @Input() nettoprijs: boolean;
  @Input() templatePart?: number = null;
  @Input() noDebug: boolean;
  @Input() part: Part;

  constructor(
    public mainService: MainService,
    private cartService: CartService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  isBusyAndNoData(): boolean {
    return this.busy && this.cartService.getResponseItemTemplates(this.cartItems, this.sci, this.itemInfo) === null;
  }

  debug(event: MouseEvent) {
    if (!this.noDebug) {
      const rits = this.cartService.getResponseItemTemplates(this.cartItems, this.sci, this.itemInfo);
      if (rits) {
        const log = rits.Log;
        this.mainService.showAvailabilityLog(event, log);
      }
    }
  }

  getItemInfo(): ResponseItem {
    let itemInfo: ResponseItem;
    if (this.sci) {
      const cartSci = this.cartService.getShoppingCartItemFromCart(this.sci);
      if (cartSci && cartSci.ItemInfo && cartSci.ItemInfo) {
        itemInfo = cartSci.ItemInfo;
      } else {
        itemInfo = this.sci.ItemInfo;
      }
    }
    if (!itemInfo && this.itemInfo) { itemInfo = this.itemInfo; }
    return itemInfo;
  }

  getCombinedParts(): VoorraadTemplateRendered[] {
    let itemInfo = this.getItemInfo();

    if (itemInfo?.Templates) {
      if (this.templatePart != null) {
        if (this.nettoprijs && !this.cartService.getNetPriceHidden()) {
          return [itemInfo.Templates.Parts[this.templatePart]];
        } else {
          return [itemInfo.Templates.NettoHidden.Parts[this.templatePart]];
        }
      } else {
        if (this.nettoprijs && !this.cartService.getNetPriceHidden()) {
          return itemInfo.Templates.CombinedParts;
        } else {
          return itemInfo.Templates.NettoHidden.CombinedParts;
        }
      }
    } else {
      const item = this.cartService.getFirstItemInfo(this.cartItems);
      if (item?.Templates) {
        const template = new VoorraadTemplateRendered();
        template.Html = item.Templates.Combined.Description;
        return [template];
      }
    }
    return [];
  }

  // getTemplate(): SafeHtml {
  //   let itemInfo = this.getItemInfo();

  //   if (itemInfo?.Templates) {
  //     if (this.templatePart != null) {
  //       if (this.nettoprijs && !this.cartService.getNetPriceHidden()) {
  //         return this.sanitizer.bypassSecurityTrustHtml(this.getHtmlFromTemplate(itemInfo.Templates.Parts[this.templatePart]));
  //       } else {
  //         return this.sanitizer.bypassSecurityTrustHtml(this.getHtmlFromTemplate(itemInfo.Templates.NettoHidden.Parts[this.templatePart]));
  //       }
  //     } else {
  //       if (this.nettoprijs && !this.cartService.getNetPriceHidden()) {
  //         return this.sanitizer.bypassSecurityTrustHtml(this.getHtmlFromTemplate(itemInfo.Templates.Combined));
  //       } else {
  //         return this.sanitizer.bypassSecurityTrustHtml(this.getHtmlFromTemplate(itemInfo.Templates.NettoHidden.Combined));
  //       }
  //     }
  //   } else {
  //     const item = this.cartService.getFirstItemInfo(this.cartItems);
  //     if (item?.Templates) { return this.sanitizer.bypassSecurityTrustHtml(item.Templates.Combined.Description); }
  //   }
  //   return this.sanitizer.bypassSecurityTrustHtml('');
  // }



}
