<div class="container">

  <p>
    Voor deze module is een extra wachtwoord nodig.
  </p>


  <div class="form-group row">
    <label class="col-4 col-form-label">Wachtwoord:</label>
    <div class="col-8">
      <input type="password" class="form-control form-control-sm" [(ngModel)]="customerInformationService.password" maxlength="32">
    </div>
  </div>

  <div class="form-group row">
    <div class="col">
        <button type="button" class="btn btn-sm w-100 btn-primary" (click)="checkPassword()">Aanmelden</button>
    </div>
  </div>

</div>