import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterfaceData } from '../../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../../../_services/admin/admin-supplier-interface.service';
import { MainService } from '../../../../../_services/main.service';

@Component({
  selector: 'app-default-interface-data-settings',
  templateUrl: './default-interface-data-settings.component.html',
  styleUrls: ['./default-interface-data-settings.component.scss']
})
export class DefaultInterfaceDataSettingsComponent implements OnInit {
  @Input() interfaceDatas: InterfaceData[];
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Output() interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();

  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }

}
