import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { ReturnHeader } from '../../../_models/returns/return.header';

@Component({
  selector: 'app-product-returns-webshop',
  templateUrl: './product-returns-webshop.component.html',
  styleUrls: ['./product-returns-webshop.component.scss']
})
export class ProductReturnsWebshopComponent implements OnInit {

  returns: ReturnHeader[];
  isBusy: boolean;

  constructor(
    public mainService: MainService,
    public sortService: SortService,
    public authService: AuthService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
    this.sortService.initSortField('returns', 'DateRequested', true);
    this.getReturns();
  }

  getReturns() {
    this.isBusy = true;
    this.customerInformationService.showPasswordPrompt = false;
    this.customerInformationService.getReturns()
      .subscribe(headers => {
        this.isBusy = false;
        this.returns = headers;
      }, error => {
        this.isBusy = false;
        if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
        console.error(error);
      });
  }

  request() {
    this.customerInformationService.gotoReturnRequest();
  }


}
