import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoyaltyShopData } from '../../../_models/loyalty-shop/loyalty.shop.data';
import { MainService } from '../../../_services/main.service';
import { LoyaltyShopItem } from '../../../_models/loyalty-shop/loyalty.shop.item';
import { LoyaltyShopCategory } from '../../../_models/loyalty-shop/loyalty.shop.category';
import { AdminLoyaltyShopService } from '../../../_services/admin/admin-loyalty-shop.service';


@Component({
  selector: 'app-admin-loyalty-shop-items',
  templateUrl: './admin-loyalty-shop-items.component.html',
  styleUrls: ['./admin-loyalty-shop-items.component.scss']
})
export class AdminLoyaltyShopItemsComponent implements OnInit {
  loyaltyShop: LoyaltyShopData;
  sortUpdateTimeout: number;
  currentCategoryID: number;

  constructor(
    public mainService: MainService,
    public adminLoyaltyShopService: AdminLoyaltyShopService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.loyaltyShop = data.loyaltyShopData;
      });
  }

  getCategoryDescription(): string {
    if (this.currentCategoryID && this.loyaltyShop && this.loyaltyShop.Categories && this.loyaltyShop.Categories[this.currentCategoryID]) {
      return this.loyaltyShop.Categories[this.currentCategoryID].Description;
    }
    return 'Alle categorieën';
  }

  getCategories(): LoyaltyShopCategory[] {
    return Object.keys(this.loyaltyShop.Categories).map(key => this.loyaltyShop.Categories[key]);
  }

  getItems(): LoyaltyShopItem[] {
    if (this.currentCategoryID && this.loyaltyShop && this.loyaltyShop.Cross && this.loyaltyShop.Cross[this.currentCategoryID]) {
      const result: LoyaltyShopItem[] = [];
      Object.keys(this.loyaltyShop.Cross[this.currentCategoryID])
        .sort((key1, key2) => this.loyaltyShop.Cross[this.currentCategoryID][key1] - this.loyaltyShop.Cross[this.currentCategoryID][key2])
        .forEach(itemID => {
          result.push(this.loyaltyShop.Items[itemID]);
        });
      return result;
    } else if (this.loyaltyShop && this.loyaltyShop.Items) {
      return Object.keys(this.loyaltyShop.Items)
        .map(key => this.loyaltyShop.Items[key]);
    }
    return null;
  }

  changedItemActive(item: LoyaltyShopItem) {
    this.adminLoyaltyShopService.saveLoyaltyShopItem(item)
      .subscribe(updated => console.info(`ItemID ${item.ID} changed to ${updated.Active}`));
  }

  click(isUp: boolean, item: LoyaltyShopItem) {
    if (this.currentCategoryID && this.loyaltyShop && this.loyaltyShop.Cross && this.loyaltyShop.Cross[this.currentCategoryID]) {
      let switchItem: LoyaltyShopItem = null;
      if (isUp) {
        switchItem = this.mainService.getItemUp(this.getItems(), item);
      } else {
        switchItem = this.mainService.getItemDown(this.getItems(), item);
      }
      if (switchItem) {
        const tmp = this.loyaltyShop.Cross[this.currentCategoryID][item.ID];
        this.loyaltyShop.Cross[this.currentCategoryID][item.ID] = this.loyaltyShop.Cross[this.currentCategoryID][switchItem.ID];
        this.loyaltyShop.Cross[this.currentCategoryID][switchItem.ID] = tmp;
      }
      if (this.sortUpdateTimeout) { window.clearTimeout(this.sortUpdateTimeout); }
      this.sortUpdateTimeout = window.setTimeout(() => {
        this.adminLoyaltyShopService.updateLoyaltyShopItemsSortOrder(this.loyaltyShop.Cross[this.currentCategoryID], this.currentCategoryID)
          .subscribe(updated => console.info(`SortOrders updated: ${updated}`));
      }, 1000);
    }
  }

  clickUp(item: LoyaltyShopItem) {
    this.click(true, item);
  }

  clickDown(item: LoyaltyShopItem) {
    this.click(false, item);
  }

}
