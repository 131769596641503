import { Component, OnInit, Input, IterableDiffers, DoCheck } from '@angular/core';
import { ContextRimsAndTyres } from '../../../../_models/rims-and-tyres/context.rims.and.tyres';
import { Wheel } from '../../../../_models/rims-and-tyres/wheel';
import { SelectListItem } from '../../../../_models/common/select.list.item';
import { RimAndTyreService } from '../../../../_services/rim-and-tyre.service';
import { SettingsService } from '../../../../_services/settings.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';

@Component({
  selector: 'app-rims-overview-scroll-layout',
  templateUrl: './rims-overview-scroll-layout.component.html',
  styleUrls: ['./rims-overview-scroll-layout.component.scss']
})
export class RimsOverviewScrollLayoutComponent implements OnInit, DoCheck {
  shopSoort = ShopSoort;
  @Input() ctx: ContextRimsAndTyres;
  wheelsFiltered: Wheel[];
  iterableDiffer: any;

  constructor(
    private iterableDiffers: IterableDiffers,
    private rimAndTyreService: RimAndTyreService,
    public settingsService: SettingsService
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.ctx.Wheels);
    if (changes) {
      this.filterRims();
    }
  }

  filterRims() {
    this.wheelsFiltered = this.rimAndTyreService.filterWheels(this.ctx, this.ctx.Filters, this.ctx.Voorraad);
  }

  stockChange(item: SelectListItem) {
    this.ctx.Voorraad = item.selected;
    this.filterRims();
  }

  filterChange(filter: string, item: SelectListItem) {
    if (this.ctx.Filters && this.ctx.Filters[filter]) {
      const value = item.value.toString();
      if (item.selected && !this.ctx.Filters[filter].includes(value)) {
        this.ctx.Filters[filter].push(value);
      } else if (!item.selected && this.ctx.Filters[filter].includes(value)) {
        const index = this.ctx.Filters[filter].indexOf(value);
        if (index > -1) { this.ctx.Filters[filter].splice(index, 1); }
      }
    }
    this.filterRims();
  }

  filterOptionsChange(item: SelectListItem) {
    if (this.ctx.Filters && this.ctx.Filters[item.value]) {
      if (item.selected && this.ctx.Filters[item.value].length === 0) {
        this.ctx.Filters[item.value].push('true');
      } else if (!item.selected && this.ctx.Filters[item.value].length !== 0) {
        this.ctx.Filters[item.value].splice(0, 1);
      }
    }
    this.filterRims();
  }

}
