import { ContextPublic } from "./context.public";

export class ConfigData {
  path: string;
  basePath: string;
  backendApi: string;
  backendApiVersion: string;
  ctxPublic: ContextPublic;
  timeout: number = 10000;
}
