import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PromotionService } from '../../_services/promotion.service';
import { MainService } from '../../_services/main.service';
import { PromotionsAdmin } from '../../_models/admin/promotions/promotions.admin';
import { Aanbieding } from '../../_models/promotions/aanbieding';
import { AdminPromotionService } from '../../_services/admin/admin-promotion.service';

@Component({
  selector: 'app-admin-promotions',
  templateUrl: './admin-promotions.component.html',
  styleUrls: ['./admin-promotions.component.scss']
})
export class AdminPromotionsComponent implements OnInit {
  promoAdmin: PromotionsAdmin;
  sortUpdateTimeout: number;

  constructor(
    public mainService: MainService,
    public adminPromotionService: AdminPromotionService,
    public promotionService: PromotionService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.promoAdmin = data.promotions;
      });
  }

  click(isUp: boolean, promo: Aanbieding) {
    if (isUp) {
      this.mainService.moveItemUp(this.promoAdmin.Promotions, promo, 'Volgorde');
    } else {
      this.mainService.moveItemDown(this.promoAdmin.Promotions, promo, 'Volgorde');
    }
    if (this.sortUpdateTimeout) { window.clearTimeout(this.sortUpdateTimeout); }
    this.sortUpdateTimeout = window.setTimeout(() => {
      this.adminPromotionService.updatePromotionsSortOrder(this.promoAdmin.Promotions).subscribe();
    }, 1000);
  }

  clickUp(promo: Aanbieding) {
    this.click(true, promo);
  }

  clickDown(promo: Aanbieding) {
    this.click(false, promo);
  }

}
