<div>
  <ng-container *ngFor="let prop of partsProperties[part.UniqueID]; let index=index;">
    <ng-container *ngIf="index < collapsedItemCount || collapsedItemCount === 0">
      <app-catalog-parts-property [partsPropertyTranslations]="partsPropertyTranslations" [shopKind]="shopKind"
        [part]="part" [property]="prop"></app-catalog-parts-property>
    </ng-container>
  </ng-container>
</div>
<div class="collapsable-properties" [@slideInHorizontal]="!part['uncollapsed']">
  <ng-container *ngFor="let prop of partsProperties[part.UniqueID]; let index=index;">
    <ng-container *ngIf="index >= collapsedItemCount && collapsedItemCount !== 0">
      <app-catalog-parts-property [partsPropertyTranslations]="partsPropertyTranslations" [shopKind]="shopKind"
        [part]="part" [property]="prop"></app-catalog-parts-property>
    </ng-container>
  </ng-container>
</div>
<span *ngIf="collapsedItemCount && partsProperties[part.UniqueID] && partsProperties[part.UniqueID].length > collapsedItemCount"
  class="pointer more-or-less" (click)="part['uncollapsed'] = !part['uncollapsed']"><span
    *ngIf="!part['uncollapsed']">meer
  </span>
  <div class="collapse-button" [ngClass]="{'is-collapsed': !part['uncollapsed']}"><i
      class="fas fa-angle-double-left"></i></div>
  <span *ngIf="part['uncollapsed']"> minder</span>
</span>
