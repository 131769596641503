import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsBase } from 'app/_models/admin/settings/settings.base';
import { AdminSettingsService } from 'app/_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-aldoc',
  templateUrl: './admin-settings-aldoc.component.html',
  styleUrl: './admin-settings-aldoc.component.scss'
})
export class AdminSettingsAldocComponent {
  settings: SettingsBase;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

}
