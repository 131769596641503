<div class="modal-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col" (contextmenu)="mainService.showDebugInfo($event, response?.Timing, response?.Response)">
        <h4>Artikel Info {{interfaceKindInfo.Name}}</h4>
      </div>
    </div>
  </div>
</div>
<div class="modal-body"
  [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  @if (interfaceKindInfo?.UsageInfo) {
  <app-admin-custom-control [label]="'Informatie koppeling'" [centerLabel]="true">
    <div class="alert alert-warning m-0" role="alert">
      {{interfaceKindInfo.UsageInfo}}
    </div>
  </app-admin-custom-control>
  }

  <app-admin-custom-control [label]="'Artikelnummer'" [dividerKind]="response?0:-1">
    <div class="row">
      <div class="col"><app-admin-input [(model)]="itemNumber" [toUppercase]="true" [dividerKind]="-1"></app-admin-input></div>
      <div *ngIf="withItemGroup" class="col"><app-admin-input [label]="'Artikelgroep'" [labelCols]="3" [toUppercase]="true"
          [(model)]="itemGroup" [dividerKind]="-1"></app-admin-input></div>
    </div>
  </app-admin-custom-control>

  <app-supplier-interface-response *ngIf="response" [response]="response?.Response"
    [withoutFirstColumn]="interfaceKindInfo.InterfaceKind!==22"
    (contextmenu)="mainService.showDebugInfo($event, response?.Timing, response?.Response)"></app-supplier-interface-response>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning ms-3 me-2" (click)="decline($event)"><i class="fas fa-undo"></i>
    Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="doInfo($event)" [disabled]="!itemNumber">
    <span *ngIf="!busy" class="icon-width"><i class="far fa-file-search"></i></span>
    <span *ngIf="busy" class="icon-width"><i class="fas fa-sync fa-spin"></i></span>
    Artikel info</button>
  <button type="button" class="btn btn-info ms-2" (click)="mainService.showTimingDialog(response?.Timing)"
    [disabled]="!response?.Response"><i class="far fa-stopwatch"></i>
    Timings</button>
</div>