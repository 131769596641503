import { Component, OnInit, Input } from '@angular/core';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { ContextPromotions } from '../../../../_models/promotions/context.promotions';

import { MainService } from '../../../../_services/main.service';
import { PromotionService } from '../../../../_services/promotion.service';

@Component({
  selector: 'app-promotions-overview-scroll-layout-component',
  templateUrl: './promotions-overview-scroll-layout-component.component.html',
  styleUrls: ['./promotions-overview-scroll-layout-component.component.scss']
})
export class PromotionsOverviewScrollLayoutComponentComponent implements OnInit {
  shopKind = ShopSoort;
  @Input() ctx: ContextPromotions;

  constructor(
    public mainService: MainService,
    private promotionService: PromotionService
  ) { }

  ngOnInit() {
  }

}
