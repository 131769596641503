<div *ngFor="let cap of mainCategory.Capacities" class="row">
  <div class="col-md-3 mpm-sub-title">{{cap.CapacityType}}</div>
  <div class="col-md-9 mpm-sub-title">{{cap.Capacity}}</div>
</div>
<div *ngIf="getIntervals(category)" class="row">
  <div class="col-md-3 mpm-sub-title">Service intervallen</div>
  <div class="col-md-9 mpm-sub-title">
    <span *ngFor="let interval of getIntervals(category)">{{interval}}</span>
  </div>
</div>
<div *ngIf="mainCategory.Manuals" class="row">
  <div class="col-md-3 mpm-sub-title">Handleiding</div>
  <div class="col-md-9 mpm-sub-title">
    <ng-container *ngFor="let manual of mainCategory.Manuals">
      <span *ngIf="manual.IframeURL"><button class="btn btn-primary" uib-tooltip='Klik hier voor de handleiding.'
          (click)="showManual(manual)"><i class='fas fa-info'></i></button></span>
    </ng-container>
  </div>
</div>
<div *ngIf="part" class="row my-3">
  <div class="col-md-12">
    <div class="mpm-title">{{part.PartDescriptionOriginal}}</div>
    <div class="mpm-sub-title">{{part.OneLiner}}</div>
    <div [innerHTML]="part.Description | safeHtml"></div>
  </div>
  <div class="col-md-4 p-3">
    <ng-container *ngIf="part.ImageObjects && part.ImageObjects.length;else partsImages">
      <img *ngFor="let img of part.ImageObjects" [src]="img.Size_original" class="img-fluid" />
    </ng-container>
    <ng-template #partsImages>
      <img *ngFor="let img of part.Items[0]?.Images" [src]="img.Size_original" class="img-fluid" />
    </ng-template>

  </div>
  <div class="col-md-8 p-3">
    <div *ngIf="part.Specifications && part.Specifications.length">
      <b>Voldoet aan de eisen van:</b><br />
      <ul>
        <li *ngFor="let spec of part.Specifications">{{spec}}</li>
      </ul>
    </div>
  </div>
  <div class="col-md-12 mt-3">
    <div *ngIf="partHasItems(part)" class="panel panel-primary" style="overflow: hidden;">
      <app-mpm-list-item *ngFor="let item of part.Items | mpmHasCartItem:ctx.CartItems; let odd=odd;" [ctx]="ctx"
        [item]="item" [odd]="odd"></app-mpm-list-item>
    </div>
  </div>
</div>