import { Component, OnInit, Input } from '@angular/core';
import { ContextHomeScreen } from '../../../_models/common/context.home.screen';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrls: ['./home-default.component.scss']
})
export class HomeDefaultComponent implements OnInit {
  @Input() ctx: ContextHomeScreen;

  constructor() { }

  ngOnInit() {
  }

}
