<app-side-panel>
  <app-side-panel-header><i class="fas fa-sort-amount-down"></i> Sorteren</app-side-panel-header>
  <app-side-panel-body>
    <app-sort-options [ctx]="ctx" [btn]="'btn-light w-100'"></app-sort-options>
  </app-side-panel-body>
</app-side-panel>

<app-side-panel>
  <app-side-panel-header>
    <i class="fas fa-filter"></i> Filteren
    <span *ngIf="ctx.filteredParts" class="float-end filter-result text-low">{{ctx.filteredParts.length}} van
      {{catalogService.getPartsFromContext(ctx).length}}</span>
  </app-side-panel-header>
  <app-side-panel-accordion-filter *ngIf="ctx.VoorraadOptie" [title]="'Voorraad'"
    [descriptions]="ctx.FilterDescriptions['Stock']" (filterChange)="stockChange($event)">
  </app-side-panel-accordion-filter>

  <app-side-panel-accordion-filter *ngFor="let filter of ctx.FilterDeclarations" [title]="filter.Title"
    [maxItems]="filter.MaxFilterItems" [descriptions]="ctx.FilterDescriptions[filter.Property]"
    (filterChange)="filterChange($event,filter.PropertyStringPart+'_', filter.AllMustMatch)">
  </app-side-panel-accordion-filter>
  <!-- <app-side-panel-accordion-filter [title]="'Artikelsoort'" [maxItems]="ctx.MaxFilterItems['PartsDescription']" [descriptions]="ctx.FilterDescriptions.PartsDescriptions" (filterChange)="filterChange($event,'PartsDescription_', false)"></app-side-panel-accordion-filter>
  <app-side-panel-accordion-filter [title]="'Merk'" [maxItems]="ctx.MaxFilterItems['Brand']" [descriptions]="ctx.FilterDescriptions.Brands" (filterChange)="filterChange($event,'Brand_', false)"></app-side-panel-accordion-filter>
  <app-side-panel-accordion-filter [title]="'Inbouwplaats'" [maxItems]="ctx.MaxFilterItems['Property_InstallLocation']" [descriptions]="ctx.FilterDescriptions.Locations" (filterChange)="filterChange($event,'Property_InstallLocation_', false)"></app-side-panel-accordion-filter>
  <app-side-panel-accordion-filter [title]="'Systeem'" [maxItems]="ctx.MaxFilterItems['System']" [descriptions]="ctx.FilterDescriptions.Systems" (filterChange)="filterChange($event,'System_', false)"></app-side-panel-accordion-filter>
  <app-side-panel-accordion-filter [title]="'Aantal polen'" [maxItems]="ctx.MaxFilterItems['Property_NumberOfPins']" [descriptions]="ctx.FilterDescriptions.NumberOfPins" (filterChange)="filterChange($event,'Property_NumberOfPins_', false)"></app-side-panel-accordion-filter> -->
</app-side-panel>