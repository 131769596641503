import { Component, OnInit, HostListener } from '@angular/core';

import { Animations } from "../../../_animations/animations";
import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-scroll-to-top-button-default',
  templateUrl: './scroll-to-top-button-default.component.html',
  styleUrls: ['./scroll-to-top-button-default.component.scss'],
  animations: [Animations.fadeScrollButton]
})
export class ScrollToTopButtonDefaultComponent implements OnInit {
  scrollPossible = window.pageYOffset !== 0;
  @HostListener("window:scroll", ["$event"])
  scrollHandler(event) {
    this.scrollPossible = window.pageYOffset !== 0;
  }

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() { }

  
}
