import { Component, OnInit, Input } from '@angular/core';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { ShopService } from '../../../../_services/shop.service';
import { MainService } from '../../../../_services/main.service';
import { TyreRequest } from '../../../../_models/tyres/tyre.request';
import { TyreSize } from '../../../../_models/tyres/tyre.size';
import { ContextMain } from '../../../../_models/common/context.main';
import { Router } from '@angular/router';

@Component({
  selector: 'app-star-module',
  templateUrl: './star-module.component.html',
  styleUrls: ['./star-module.component.scss']
})
export class StarModuleComponent implements OnInit {
  shopKind = ShopSoort;
  @Input() shopSoort: ShopSoort;
  @Input() ctxMain: ContextMain;
  bandenmaat: string;
  search: string;

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  canClickButton(): boolean {
    return this.shopSoort !== ShopSoort.Banden && this.shopSoort !== ShopSoort.Artikelinformatie;
  }

  clickButton() {
    if (this.shopSoort !== ShopSoort.Banden && this.shopSoort !== ShopSoort.Artikelinformatie) {
      this.shopService.gotoShopModule(this.mainService.ctxMain, this.shopSoort);
    }
  }

  searchTyre(size: string): void {
    if (size) {
      const request = new TyreRequest([TyreSize.getFromString(size)], 2, true, 0, ShopSoort.Banden);
      this.shopService.doSearchTyre(this.ctxMain, request);
    }
  }

}
