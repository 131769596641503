<div class="main-startscherm">
  <div class="container startscherm-button-container">
    <div class="row g-0">
      <ng-container *ngFor="let button of ctx.Buttons; let index = index">
        <div class="startscherm-col">
          <div class="startscherm-button">
            <img *ngIf="button.IconUrl" (click)="shopService.gotoShopModule(mainService.ctxMain, +button.CommandValue)" class="center-block"
              [ngClass]="{'pointer': button.CommandMessage}" [src]="homeScreenService.getHomeButtonImage('appicon', button.IconUrl, '306dbc')">
            <br />
            <span (click)="shopService.gotoShopModule(mainService.ctxMain, +button.CommandValue)" class="startscherm-button-label text-primary"
              [ngClass]="{'pointer': button.CommandMessage}" [innerHTML]="mainService.getSafeHtml(button.Text)"></span>
          </div>
        </div>
        <div *ngIf="ctx.LicensePlateInputEnabled && getPos(index) === licencePlaceAfterButton" class="startscherm-middle-col startscherm-kenteken-container">
          <div class="startscherm-kenteken-plaat mx-auto">
            <div class="startscherm-kenteken-links"></div>
            <div class="startscherm-kenteken-midden">
              <input type="text" [appAutofocus]="true" appLicensePlate class="startscherm-kenteken-input" [ngModel]="licensePlate" (ngModelChange)="licensePlate=$event" (click)="licensePlate = ''"
                (keyup.enter)="searchLicensePlate(licensePlate)" />
            </div>
            <div class="startscherm-kenteken-rechts">
              <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-mmt" (click)="searchBrandModelType()" tooltip="Klik hier om via merk, model, type te kiezen."
                placement="right" container="body">
                <i class="fas fa-car" data-fa-transform="grow-10"></i>
              </div>
              <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-history" (click)="searchHistory()" tooltip="Klik hier om een eerder gekozen auto te kiezen."
                placement="right" container="body">
                <i class="fas fa-history" data-fa-transform="grow-8"></i>
              </div>
            </div>
          </div>
          <span *ngIf="!ctx.CarType" class="startscherm-kenteken-label text-primary">Vul uw kenteken in.</span>
          <span *ngIf="ctx.CarType" class="startscherm-kenteken-label text-primary">{{ctx.CarType.Model?.Brand.BrandName}} {{ctx.CarType.TypeName}}</span>
        </div>
        
        <div *ngIf="ctx.ItemSearchInputEnabled && getPos(index) === itemSearchAfterButton" class="startscherm-middle-col startscherm-kenteken-container">
          <div class="startscherm-kenteken-plaat mx-auto">
            <div class="startscherm-itemsearch">
              <input type="text" class="form-control startscherm-itemsearch-input" [(ngModel)]="itemSearch" (click)="itemSearch = ''"
                (keyup.enter)="router.navigate(['/iteminfo', itemSearch])" />
            </div>            
          </div>
          <span *ngIf="!ctx.CarType" class="startscherm-button-label text-primary">Zoek een artikel.</span>
        </div>        
      </ng-container>
      <div *ngFor="let filler of getFillers()" class="startscherm-col">&nbsp;</div>
    </div>
  </div>

  <div class="container">
    <div class="row my-3">
      <div *ngIf="ctxLoyaltyShop" class="col-3">
        <div class="card border-primary startscherm-kadoshop-card">
          <div class="card-body startscherm-kadoshop-card-body">
            <div id="carouselLoyaltyShop" class="carousel slide" data-interval="4000">
              <div class="carousel-inner">
                <div *ngFor="let item of loyaltyShopService.getItems(0,true); let index = index" class="active startscherm-kadoshop-content carousel-item"
                  [ngClass]="{'active': index === 0}">
                  <div class="startscherm-kadoshop-omschrijving" [innerHTML]="mainService.getSafeHtml(item.Description)"></div>
                  <div *ngIf="item.Images" class="startscherm-kadoshop-afbeelding-container text-center">
                    <img [src]="loyaltyShopService.getImagelUrl(item, item.Images[0], '155x190')" />
                  </div>
                  <button type="button" class="btn btn-lg btn-outline-primary w-100 startscherm-btn" ng-click="clickBonusshop(slide)">Ga naar Kadoshop</button>
                </div>
              </div>
              <a *ngIf="loyaltyShopService.getItems(0,true).length > 1" class="carousel-control-prev" href="#carouselLoyaltyShop" role="button"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a *ngIf="loyaltyShopService.getItems(0,true).length > 1" class="carousel-control-next" href="#carouselLoyaltyShop" role="button"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="ctxPromotions" [ngClass]="{'col-9': ctxLoyaltyShop, 'col-12': !ctxLoyaltyShop }">
        <div class="card border-primary">
          <div class="card-body startscherm-aanbieding-card-body">
            <app-promotions-carousel-view [ctx]="ctxPromotions" [shopKind]="22"></app-promotions-carousel-view>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="startscherm-col">&nbsp;</div>
    </div>
  </div>

</div>
