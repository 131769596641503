import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';

import { ItemInfoRequest } from '../_models/item-info/item.info.request';
import { ContextCatalog } from '../_models/catalog/context.catalog';
import { ApiService } from './api.service';
import { MainService } from './main.service';
import { mergeMap } from 'rxjs/operators';
import { ShopSoort } from '../_models/common/shop.soort';
import { CatalogService } from './catalog.service';
import { ToasterService } from './toaster.service';
import { ResponseItem } from '../_models/item-info/response.item';


@Injectable()
export class ItemInfoService {
  _ctx: ContextCatalog;
  _itemInfoRequest: ItemInfoRequest;
  _itemInfoResult: ContextCatalog;
  // request = new ItemInfoRequest(0, '', 0, false, 1, ShopSoort.Artikelinformatie);
  // itemInfoResultSubject = new BehaviorSubject<ContextCatalog>(null);


  constructor(
    private apiService: ApiService,
    private mainService: MainService,
    private toasterService: ToasterService,
    private catalogService: CatalogService
  ) { }

  clear(): void {
    this._ctx = null;
    this._itemInfoRequest = null;
    this._itemInfoResult = null;
  }

  getContextItemInfo(): Observable<ContextCatalog> {
    if (this._ctx) {
      return of(this._ctx);
    } else {
      return this.apiService.getContextItemInfo()
        .pipe(mergeMap(ctx => {
          this._ctx = ctx;
          return of(ctx);
        }));
    }
  }

  getItems(itemInfoRequest: ItemInfoRequest): Observable<ContextCatalog> {
    if (this._itemInfoResult !== null && this._itemInfoRequest && this._itemInfoRequest.equals(itemInfoRequest)) {
      return of(this._itemInfoResult);
    } else {
      let current = '';
      if (itemInfoRequest && itemInfoRequest.SearchInDescriptions) { current = 'het duurt iets langer... er wordt ook gezocht in de omschrijvingen.'; }
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', `zoeken naar '${itemInfoRequest.ItemNumber}'`, current);
      this._itemInfoResult = null;
      return this.apiService.getItemInfoResponse(itemInfoRequest)
        .pipe(
          mergeMap(result => {
            cb.complete();
            if (!result || !result.Parts || Object.keys(result.Parts).length === 0) {
              this.toasterService.showToast('Helaas...', 'Er werden geen artikelen gevonden.');
              return EMPTY;
            } else {
              if (result) { result.FilterDescriptions = this.catalogService.getFilterDescriptions(result); }
              this._itemInfoRequest = itemInfoRequest;
              this._itemInfoResult = result;
              this.catalogService.fillFilterParts(this._itemInfoResult);
              const itemNumber = itemInfoRequest.ItemNumber.toUpperCase();
              const itemNumberShort = itemNumber.replace(/\W/g, '').replace('_', '');
              Object.keys(result.Parts).forEach(key => {
                result.Parts[key].forEach(part => {
                  const itemNumberMatchBonus = part.PartItemNumber.toUpperCase() == itemNumber ? 100 : 0;
                  const itemNumberShortMatchBonus = part.PartItemNumberStripped.toUpperCase() == itemNumberShort ? 10 : 0;
                  part['itemAccuracy'] = itemNumberMatchBonus + itemNumberShortMatchBonus;
                });
              });
              const updateFilters = (): void => {
                this.catalogService.updateFilterDescriptionStock(this._itemInfoResult);
                this.catalogService.fillFilterParts(this._itemInfoResult);
              };
              updateFilters();
              this.catalogService.getCatalogPartsCartItemsForPartsDict(null, 'ItemInfo', this._itemInfoResult.Parts, this._itemInfoResult.Timing, this._itemInfoResult, updateFilters)
                .subscribe(response => {
                  result.PartsCartItems = response.CartItems;
                  result.Timing = response.Timing;
                  Object.keys(result.Parts).forEach(key => {
                    result.Parts[key].forEach(part => {
                      if (!result.PartsCartItems[part.UniqueID]) { part['itemAccuracy'] = -1; }
                      else if (!result.PartsCartItems[part.UniqueID].ItemInfo) { part['itemAccuracy'] = 0; }
                    });
                  });
                });

              return of(result);
            }
          }));
    }
  }

  getQueryParams(searchInDescription: boolean, shopSoort: ShopSoort): any {
    const queryParams = {};
    if (searchInDescription) { queryParams['searchInDescription'] = searchInDescription; }
    if (shopSoort !== ShopSoort.ONBEKEND) { queryParams['shopKind'] = shopSoort; }
    return queryParams;
  }

  getItemInfoImage(itemInfo: ResponseItem, index = 0): string {
    if (itemInfo?.Properties && itemInfo.Properties["Image"] && itemInfo.Properties["Image"][index]) {
      return itemInfo.Properties["Image"][index];
    }
    return null;
  }

}
