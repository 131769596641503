<div *ngIf="ctx && ctx.Promotions" id="carouselPromotions" class="carousel slide" data-bs-interval="4000" data-bs-theme="dark">
  <div class="carousel-inner">
    <div *ngFor="let promotion of ctx.Promotions; let index = index" class="carousel-item"
      [ngClass]="{'active': index === 0}">
      <div class="row aanbieding-content" (click)="promotionService.orderPromotion(promotion, shopKind)">
        <div class="col-4">
          <div *ngIf="promotion.Images && promotion.Images.length" class="aanbiedingen-afbeelding-container">
            <img class="img-fluid" [src]="promotion.Images[0].AfbeeldingsUrl" />
          </div>
        </div>
        <div class="col-8">
          <div class="aanbieding-content-container">
            <div class="aanbieding-titel" [innerHTML]="mainService.getSafeHtml(promotion.Titel)"></div>
            <div (click)="promotionService.orderPromotion(promotion, shopKind)">
              <div class="aanbieding-omschrijving" [innerHTML]="promotion.Omschrijving | safeHtml"></div>
              <div class="click-bait"></div>
            </div>
            <div class="prijs-van">{{promotion.TekstVan}}</div>
            <div class="prijs">{{promotion.TekstVoor}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselPromotions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselPromotions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
