<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3">

    <app-header-panel [ctx]="ctx" [timing]="ctx?.Timing" (backClick)="goBack()">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col">
        </div>
        <div class="col-sm-auto">
          <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown">{{ctx.RequestItemCount}}</button>
          <div class="dropdown-menu">
            <a *ngFor="let count of ctx.RequestItemCountsPossible" class="dropdown-item" (click)="ctx.RequestItemCount = count">{{count}}</a>
          </div>
        </div>
        <div class="col-sm-auto ps-2">
          <app-sort-options [ctx]="ctx" [btn]="'btn-primary'"></app-sort-options>
        </div>
        <div class="col-sm-auto ps-2">
          <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">{{ctx.Filters['Diameter']}} inch</button>
          <span class="dropdown-menu">
            <a *ngFor="let diameter of ctx.Diameters;let first=first; " class="dropdown-item" (click)="ctx.Filters['Diameter'] = [diameter.toString()]">{{diameter}} inch &nbsp;&nbsp;
              <span *ngIf="!first && ctx.fetching"><i class="fas fa-sync-alt fa-spin"></i></span>
            </a>
          </span>
        </div>
        <div class="col-sm-auto ps-2">
          <app-voorraad-prijs-display-opties [ctx]="ctx" [viewKinds]="viewKinds"></app-voorraad-prijs-display-opties>
        </div>
      </div>
    </app-header-panel>

    <ng-container *ngIf="ctx" [ngSwitch]="ctx.ViewKind">
      <div *ngSwitchDefault class="mt-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
        <app-rims-card-view [ctx]="ctx" [fetching]="ctx.fetching"></app-rims-card-view>
      </div>
      <!-- <div *ngSwitchCase="0" class="card border-primary mt-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
        <app-rims-list-view [ctx]="ctx" [fetching]="fetching"></app-rims-list-view>
      </div> -->
    </ng-container>

  </div>
</div>
