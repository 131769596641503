<div *ngIf="title" class="modal-header text-bg-primary py-2">
  {{title}}
</div>
<div class="modal-body">
  <div class="form-group row">
    <label class="col-4 col-form-label">{{description}}</label>
    <div class="col-8">
      <input type="text" [ngClass]="{'conceal': isPassword}" [appFocus]="focusInput" class="form-control" [(ngModel)]="inputValue"
        (dblclick)="isPassword = mainService.swicthIfWithAltShift($event, isPassword)" (keyup.enter)="confirm()">
    </div>
  </div>
  <div class="row">
    <div class="col-6 pe-2"><button type="button" class="btn btn-primary w-100" (click)="confirm()">Ok</button>
    </div>
    <div class="col-6 ps-2"><button type="button" class="btn btn-warning w-100"
        (click)="decline()">Annuleren</button></div>
  </div>
</div>
