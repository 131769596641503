import { Component, OnInit, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, CdkDrag, transferArrayItem, copyArrayItem, CdkDropList } from '@angular/cdk/drag-drop';

import { SettingsCatalogLayout } from '../../../../_models/admin/settings/settings.catalog.layout';
import { CatalogLayoutSubgroupCategoryCrossLink } from '../../../../_models/admin/settings/catalog.layout.subgroup.category.cross.link';
import { MainService } from '../../../../_services/main.service';
import { CatalogLayoutFilterSettings } from '../../../../_models/admin/settings/catalog.layout.filter.settings';
import { CatalogLayoutService } from '../../../../_services/catalog-layout.service';
import { CatalogLayoutMaingroup } from '../../../../_models/admin/settings/catalog.layout.maingroup';
import { CatalogLayoutSubgroup } from '../../../../_models/admin/settings/catalog.layout.subgroup';
import { CatalogLayoutEditStatus } from '../../../../_models/admin/settings/catalog.layout.edit.status';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-catalog-layout',
  templateUrl: './admin-settings-catalog-layout.component.html',
  styleUrls: ['./admin-settings-catalog-layout.component.scss']
})
export class AdminSettingsCatalogLayoutComponent implements OnInit, AfterViewInit {
  settings: SettingsCatalogLayout;
  selected: CatalogLayoutSubgroupCategoryCrossLink;
  filter: CatalogLayoutFilterSettings = new CatalogLayoutFilterSettings();
  @Input() focusFilter: EventEmitter<boolean> = new EventEmitter();
  explainDrag = false;

  constructor(
    private route: ActivatedRoute,
    public catalogLayoutService: CatalogLayoutService,
    public adminSettingsService: AdminSettingsService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.focusFilter.emit(true); }, 0);
  }

  clearFilter() {
    this.filter.filterString = '';
    this.filter.partsDescriptionFilterString = '';
    this.filter.brandFilterString = '';
    this.focusFilter.emit(true);
  }

  setFilter(category: CatalogLayoutSubgroupCategoryCrossLink) {
    let content = '';
    if (category) { content = category.CategoryDescription.substr(0, 4); }
    this.filter.filterString = content;
  }

  filterClick() {
    this.filter.autoFilter = !this.filter.autoFilter;
    if (this.selected && this.filter.autoFilter) { this.setFilter(this.selected); }
  }

  setSelected(category: CatalogLayoutSubgroupCategoryCrossLink) {
    this.selected = category;
    if (this.filter.autoFilter) { this.setFilter(this.selected); }
    const id = `cat_${category.Origin}_${category.CategoryId}_`;
    this.scrollIntoViewIfNeeded(`#${id}all`, '#scrollContainerCategories');
    const subgroups = this.catalogLayoutService.getCategoryLinkedSubgroups(this.settings.CatalogLayoutSubgroupCategoryCross, category);
    if (subgroups && subgroups.length > 0) {
      this.scrollIntoViewIfNeeded(`#${id}${subgroups[0]}`, '#scrollContainerGroups');
    }
  }

  scrollIntoViewIfNeeded(elementId: string, containerId: string) {
    setTimeout(() => {
      const el = document.querySelector(elementId) as HTMLElement;
      const parentView = document.querySelector(containerId) as HTMLElement;
      if (el && !this.isVisible(el, parentView)) {
        el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      }
    }, 0);
  }

  isVisible(el: HTMLElement, parentView: HTMLElement) {
    let rect = el.getBoundingClientRect();
    const top = rect.top;
    const height = rect.height;
    el = el.parentElement;
    // Check if bottom of the element is off the page
    if (rect.bottom < 0) {
      return false;
    }
    // Check its within the document viewport
    if (top > document.documentElement.clientHeight) {
      return false;
    }
    do {
      rect = el.getBoundingClientRect();
      // if ((top <= rect.bottom) === false) {
      //   return false;
      // }
      // Check if the element is out of view due to a container scrolling
      if ((top + height) <= rect.top) {
        return false;
      }
      el = el.parentElement;
    } while (el !== document.body && el.id !== parentView.id);
    return true;
  }

  addMaingroup() {
    this.mainService.inputBoxExtended('Toevoegen Hoofdgroep', 'Omschrijving', '', false, 'modal-md')
      .subscribe(description => {
        if (description) { this.catalogLayoutService.addMaingroup(this.settings.Groups, description); }
      });
  }

  editMaingroup(maingroup: CatalogLayoutMaingroup) {
    this.mainService.inputBoxExtended('Aanpassen Hoofdgroep', 'Omschrijving', maingroup.Description, false, 'modal-md')
      .subscribe(result => {
        if (result) {
          maingroup.Description = result;
          maingroup.Status = CatalogLayoutEditStatus.Changed;
        }
      });
  }

  deleteMaingroup(maingroup: CatalogLayoutMaingroup) {
    this.mainService.confirmBox(`Weet u zeker dat u de hoofdgroep '${maingroup.Description}' wilt verwijderen?`)
      .subscribe(ok => {
        if (ok) {
          this.catalogLayoutService.deleteMaingroup(this.settings.CatalogLayoutSubgroupCategoryCross, maingroup);
        }
      });
  }

  addSubgroup(maingroup: CatalogLayoutMaingroup) {
    this.mainService.inputBoxExtended('Toevoegen Subgroep', 'Omschrijving', '', false, 'modal-md')
      .subscribe(description => {
        if (description) { this.catalogLayoutService.addSubgroup(this.settings.Groups, maingroup, description); }
      });
  }

  editSubgroup(subgroup: CatalogLayoutSubgroup) {
    this.mainService.inputBoxExtended('Aanpassen Subgroep', 'Omschrijving', subgroup.Description, false, 'modal-md')
      .subscribe(result => {
        if (result) {
          subgroup.Description = result;
          subgroup.Status = CatalogLayoutEditStatus.Changed;
        }
      });
  }

  deleteSubgroup(subgroup: CatalogLayoutSubgroup) {
    this.mainService.confirmBox(`Weet u zeker dat u de subgroep '${subgroup.Description}' wilt verwijderen?`)
      .subscribe(ok => {
        if (ok) {
          this.catalogLayoutService.deleteSubgroup(this.settings.CatalogLayoutSubgroupCategoryCross, subgroup);
        }
      });
  }

  dropMaingroup(dropInfo: CdkDragDrop<CatalogLayoutMaingroup[]>) {
    moveItemInArray(dropInfo.container.data, dropInfo.previousIndex, dropInfo.currentIndex);
  }

  otherMaingroupIDs(groups: CatalogLayoutMaingroup[], maingroup: CatalogLayoutMaingroup): string[] {
    return groups.filter(g => g.ID !== maingroup.ID).map(g => `subgroupList${g.ID}`);
  }

  dropSubgroup(dropInfo: CdkDragDrop<CatalogLayoutSubgroup[]>) {
    if (dropInfo.previousContainer === dropInfo.container) {
      moveItemInArray(dropInfo.container.data, dropInfo.previousIndex, dropInfo.currentIndex);
    } else {
      transferArrayItem(dropInfo.previousContainer.data,
        dropInfo.container.data,
        dropInfo.previousIndex,
        dropInfo.currentIndex);
    }
  }

  otherSubgroupIDs(subgroupId: string): string[] {
    const subgroupids: string[] = [];
    if (subgroupId !== 'subgroupAll') { subgroupids.push('subgroupAll'); }
    this.settings.Groups.forEach(maingroup => {
      maingroup.Subgroups.forEach(subgroup => {
        const id = `subgroup${subgroup.ID}`;
        if (id !== subgroupId) { subgroupids.push(id); }
      });
    });
    return subgroupids;
  }

  dropCategorie(dropInfo: CdkDragDrop<CatalogLayoutSubgroupCategoryCrossLink[]>) {
    this.explainDrag = false;
    const category = dropInfo.previousContainer.data[dropInfo.previousIndex];
    if (dropInfo.previousContainer.id !== 'subgroupAll' && dropInfo.container.id === 'subgroupAll') {
      dropInfo.previousContainer.data.splice(dropInfo.previousIndex, 1);
    } else if (dropInfo.previousContainer.id === 'subgroupAll' && dropInfo.container.id !== 'subgroupAll') {
      copyArrayItem(dropInfo.previousContainer.data, dropInfo.container.data, dropInfo.previousIndex, dropInfo.currentIndex);
    } else if (dropInfo.container.id !== 'subgroupAll' && dropInfo.previousContainer !== dropInfo.container) {
      transferArrayItem(dropInfo.previousContainer.data, dropInfo.container.data, dropInfo.previousIndex, dropInfo.currentIndex);
    }
    this.setSelected(category);
  }

  noDoublesPredicate(drag: CdkDrag<CatalogLayoutSubgroupCategoryCrossLink>, drop: CdkDropList<CatalogLayoutSubgroupCategoryCrossLink[]>) {
    const dragCategory = drag.data;
    const dropListData = drop.data;
    if (dropListData) {
      const exists = dropListData
        .map(c => `${c.Origin}_${c.CategoryId}`)
        .includes(`${dragCategory.Origin}_${dragCategory.CategoryId}`);
      return !exists;
    }
    return true;
  }

  save() {
    this.adminSettingsService.saveSettingsCatalogLayout(this.settings);
  }

  test(info) {
    console.info(info);
  }

}
