import { Injectable } from '@angular/core';
import { ContextBase } from '../_models/common/context.base';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { ApiService } from '../_services/api.service';
import { mergeMap, take } from 'rxjs/operators';
import { ShopRouteConfig } from '../_models/common/shop.route.config';
import { ShopSoort } from '../_models/common/shop.soort';
import { CarTypeService } from '../_services/car-type.service';

@Injectable({
  providedIn: 'root'
})
export class ContextDataResolverService  {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private carTypeService: CarTypeService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextBase> | Observable<never> {
    
    return this.getContextForRoute(route.routeConfig.path).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          this.router.navigate(['/']);
          return EMPTY;
        }
      })
    );
  }

  getContextForRoute(path: string): Observable<ContextBase> {
    switch (path) {
      case ShopRouteConfig.routePaths[ShopSoort.Paint]:
        return this.apiService.getContextPaint();
      
    }
    console.info(`No settings for "${path}" found!`);
    return EMPTY;
  }


}
