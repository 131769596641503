<div class="header p-3">
  <div class="row">
    <div class="col" (contextmenu)="mainService.showObject($event, brands)"><b>Kies een merk</b></div>
    <div class="col">
      <div class="input-group float-end">
        <input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="filterString" placeholder="filter op merk">
      </div>
    </div>
  </div>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  <div class="row brand-container">
    <div *ngFor="let brand of brands | carBrandFilter: filterString" class="thumb brand-thumb">
      <button class="btn btn-light brand-button" (click)="choose(brand)">
        <div class="brand-logo"><img *ngIf="brand.BrandLogo" class="brand-image img-fluid" [src]="carTypeService.getCarBrandLogoUrl(brand.BrandLogo,'50x50')"
            [alt]="brand.BrandName"></div>
        <span class="brand-title">{{brand.BrandName}}</span>
      </button>
    </div>
  </div>
</div>
<div class="footer p-3">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-warning float-end" (click)="choose(null)">Annuleren</button>
    </div>
  </div>
</div>
