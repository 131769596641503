<div class="card border-primary" (contextmenu)="mainService.showDebugInfo($event, getTimingObject(), ctx)">
  <div class="card-body py-0 px-2">
    <app-header-panel-inner [ctx]="ctx" [timing]="timing" [preText]="preText" (backClick)="backClick.emit($event)" [hideBackClick]="!showBackButton()" [withoutCarType]="withoutCarType">
      <ng-content></ng-content>
      <ng-container content-bottom>
        <ng-content select="[content-bottom]"></ng-content>
      </ng-container>
    </app-header-panel-inner>
  </div>
</div>
