<app-admin-custom-control *ngIf="!searching" [label]="label" [labelCols]="labelCols" [dividerKind]="dividerKind">
  <button type="button" class="btn btn-light border border-light-subtle text-start" (click)="doSearch()">
    <span *ngIf="noneSelectionLabel && model===noneSelectionModelValue">{{noneSelectionLabel}}</span>
    <span *ngIf="modelObject"><b>{{modelObject.Description}}</b> <span class="ms-3 text-low">({{modelObject.ItemGroupId}})</span>
        <span *ngIf="noneSelectionLabel" (click)="clear($event)" class="ms-3"><i class="fas fa-trash-alt"></i></span>
        <br />{{modelObject.Brand}}</span>
  </button>
</app-admin-custom-control>
<app-admin-typeahead-item-group-search *ngIf="searching" [label]="label" [labelCols]="labelCols" [disabled]="disabled" [dropup]="dropup"
  [wholesaler]="adminService.currentUid.Wholesaler" [focusInput]="focusInput" (selected)="objectChange($event)"
  [placeholder]="placeholder" [dividerKind]="dividerKind" (blur)="typeaheadBlur($event)">
</app-admin-typeahead-item-group-search>