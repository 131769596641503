<app-admin-form *ngIf="ctx && ctx.AdminAccesslevel > 15" [title]="'Super Admin'" [object]="ctx">
  <app-admin-dropdown [label]="'Backend'" [(model)]="urlDescription" [modelValues]="getBackendDescriptions()"
    (modelChange)="backendUrlChanged(urlDescription)"></app-admin-dropdown>
  <app-admin-action-button [label]="'Herlaad de settings tabel'"
    (clickAction)="adminService.reloadSettings(getBackendUrls(urlDescription))"><i class="fas fa-sync-alt"></i>
    Instellingen herladen</app-admin-action-button>
  <app-admin-action-button [label]="'Flush CatalogHelper tabellen'"
    (clickAction)="adminService.flushCatalogHelper(getBackendUrls(urlDescription))"><i class="far fa-toilet"></i> Flush
    CatalogHelper</app-admin-action-button>
  <!-- <app-admin-action-button [label]="'Convert ShopModule Settings'" (clickAction)="convertShopModuleSettings()"><i
      class="fas fa-tasks"></i> Convert Settings</app-admin-action-button> -->
  <app-admin-custom-control [label]="'Show Settings'">
    <div class="input-group">
        <button id="dropdownSettings" class="btn btn-danger dropdown-toggle" type="button" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"><i class="fas fa-sliders-v"></i> Show {{showSettingSelector}}
          Settings</button>
        <div class="dropdown-menu" aria-labelledby="dropdownSettings">
          <a class="dropdown-item pointer" (click)="showSettingSelector = 'EasyCloud'">EasyCloud</a>
          <a class="dropdown-item pointer" (click)="showSettingSelector = 'ShopPortal'">ShopPortal</a>
          <a class="dropdown-item pointer" (click)="showSettingSelector = 'Webshop'">Webshop</a>
        </div>
        <button id="dropdownWholesalers" class="btn btn-danger dropdown-toggle" type="button" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">{{showSettingWholesaler?showSettingWholesaler.ShortName:'Alle grossiers'}}</button>
        <div class="dropdown-menu wholesaler-list" aria-labelledby="dropdownWholesalers">
          <a class="dropdown-item pointer" (click)="showSettingWholesaler = null">Alle grossiers</a>
          <a *ngFor="let wholesaler of ctx.Wholesalers" class="dropdown-item pointer"
            (click)="showSettingWholesaler = wholesaler">{{wholesaler.ShortName}} <span
              class="text-low">{{wholesaler.ID}}</span></a>
        </div>
      <input type="text" class="form-control" [(ngModel)]="showSettingValue" placeholder="alle settings">
      <button class="btn btn-danger" type="button" (click)="showSettings()"><i class="fas fa-binoculars"></i></button>
    </div>
  </app-admin-custom-control>
</app-admin-form>