import { Component, EventEmitter, OnInit } from '@angular/core';
import { CustomerModel } from '../../../_models/common/customer.model';
import { AdminService } from '../../../_services/admin/admin.service';
import { ApiService } from '../../../_services/api.service';
import { RouteInfo } from '../../../_models/item-info/route.info';
import { SupplierModel } from 'app/_models/common/supplier.model';
import { BranchModel } from 'app/_models/common/branch.model';
import { SupplierDepot } from 'app/_models/item-info/supplier.depot';
import { RouteInfoObject } from 'app/_models/item-info/route.info.object';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-admin-route-info',
  templateUrl: './admin-route-info.component.html',
  styleUrls: ['./admin-route-info.component.scss']
})
export class AdminRouteInfoComponent implements OnInit {
  routeInfoObject: RouteInfoObject;
  routeType: number = 0;
  customer: number;
  customerModel: CustomerModel
  fromBranch: number;
  fromBranchModel: BranchModel
  toBranch: number;
  toBranchModel: BranchModel
  supplier: number;
  supplierModel: SupplierModel
  depotKeuze = false;
  depot: string;
  timestamp = new Date();
  busyRouteSearch = false;
  routes: RouteInfo[] = [];
  focusInput = new EventEmitter<boolean>();
  hasRun = false;

  constructor(
    public adminService: AdminService,
    public mainService: MainService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.adminGetRouteInfoObject(this.adminService.currentUid)
      .subscribe(info => this.routeInfoObject = info);
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  getTime(time: string): string {
    if (time.split(":").length > 2) return time.substring(0, time.length - 3);
    return time;
  }

  supplierChange(supplierModel: SupplierModel) {
    this.supplierModel = supplierModel;
    this.depotKeuze = (this.routeInfoObject?.SupplierDepotsInfo && this.routeInfoObject.SupplierDepotsInfo[this.supplierModel.SupplierNumber] && this.routeInfoObject.SupplierDepotsInfo[this.supplierModel.SupplierNumber].length > 0);
    this.depot = '';
    if (this.depotKeuze) this.depot = this.routeInfoObject.SupplierDepotsInfo[this.supplierModel.SupplierNumber][0].DepotId;
  }

  getRoutes() {
    this.hasRun = true;
    if (this.routeType == 0) {
      if (this.customerModel) {
        this.busyRouteSearch = true;
        this.apiService.adminGetCustomerRouteInfo(this.adminService.currentUid, this.customerModel.BranchNumber, this.customer, this.timestamp)
          .subscribe(result => {
            this.busyRouteSearch = false;
            this.routes = result ?? [];
          });
      }
    }
    if (this.routeType == 1) {
      if (this.fromBranch && this.toBranch) {
        this.busyRouteSearch = true;
        this.apiService.adminGetBranchRouteInfo(this.adminService.currentUid, this.fromBranch, this.toBranch, this.timestamp)
          .subscribe(result => {
            this.busyRouteSearch = false;
            this.routes = result ?? [];
          });
      }
    }
    if (this.routeType == 2) {
      if (this.supplier) {
        this.busyRouteSearch = true;
        this.apiService.adminGetSupplierRouteInfo(this.adminService.currentUid, this.supplier, this.depot, this.toBranch, this.timestamp)
          .subscribe(result => {
            this.busyRouteSearch = false;
            this.routes = result ?? [];
          });
      }
    }
  }


}
