<app-admin-form [title]="'Welkom!'" [object]="ctx">
  <h5 class="card-title">Welkom op het ShopPortal Admin Dashboard</h5>
  <p class="card-text">Hier kunt u alles wat te maken heeft met uw ShopPortal aanpassen en instellen.</p>
  <p class="card-text">
    Door middel van het menu aan de linkerkant kunt u navigeren door alle instellingen en wanneer u
    iets niet kunt vinden, dan kunt u het zoeken door middel van de zoek functie rechts bovenin het scherm.
  </p>
  <!-- <div style="width: 400px;">
    <div>
      <div style="clear: left;">
        <div class="loading" style="width: 70px; text-align: left; float: left;">Bruto</div>
        <div class="loading" style="width: 80px; text-align: right; float: right;"> € 135,20</div>
      </div>
      <div style="clear: left;">
        <div class="loading" style="width: 70px; text-align: left; float: left;">Netto</div>
        <div class="loading" style="width: 80px; text-align: right; float: right;"> € 64,90</div>
      </div>
      <div class="loading" style="clear:left;">Dit is een test!</div>
      <div class="loading" style="clear:left;text-align:center;"><img title="Klik hier voor alternatieven voor dit artikel." onclick=""
          src="https://media.easycloud.autotaal.biz/availability/alternatieven.png" align="right"
          style="border-width:0px;cursor:pointer;"></div>
    </div>
  </div> -->


  <!-- <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside"
      aria-expanded="false">
      Dropdown button
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a class="dropdown-item">Action</a></li>
      <li>
        <a class="dropdown-item">
          <div class="dropdown dropend">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
              data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown button
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li><a class="dropdown-item">Action</a></li>
              <li><a class="dropdown-item">Another action</a></li>
              <li><a class="dropdown-item">Something else here</a></li>
            </ul>
          </div>
        </a>
      </li>
      <li><a class="dropdown-item">Something else here</a></li>
    </ul>
  </div> -->

  <!-- <button type="button" class="btn btn-primary">Primary</button>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-success">Success</button>
<button type="button" class="btn btn-danger">Danger</button>
<button type="button" class="btn btn-warning">Warning</button>
<button type="button" class="btn btn-info">Info</button>
<button type="button" class="btn btn-light">Light</button>
<button type="button" class="btn btn-dark">Dark</button>

<button type="button" class="btn btn-link">Link</button> -->

</app-admin-form>