import { Component, OnInit } from '@angular/core';
import { LicensePlateService } from '../../../_services/license-plate.service';
import { LicensePlateRequest } from '../../../_models/license-plates/license.plate.request';
import { LicensePlateReason } from '../../../_models/license-plates/license.plate.reason';
import { LicensePlateType } from '../../../_models/license-plates/license.plate.type';
import { MainService } from '../../../_services/main.service';
import { ApiService } from '../../../_services/api.service';
import { ToasterService } from '../../../_services/toaster.service';
import { ShopService } from '../../../_services/shop.service';

@Component({
  selector: 'app-license-plates-webshop',
  templateUrl: './license-plates-webshop.component.html',
  styleUrls: ['./license-plates-webshop.component.scss']
})
export class LicensePlatesWebshopComponent implements OnInit {

  request: LicensePlateRequest;

  constructor(
    public licensePlateService: LicensePlateService,
    private mainService: MainService,
    private toasterService: ToasterService,
    private shopService: ShopService,
    private apiService: ApiService
  ) {
    this.request = new LicensePlateRequest();
    this.request.DuplicateCode = 0;
    this.request.Amount = 1;
  }

  ngOnInit() {
    this.licensePlateService.getLicensePlateTypes(false);
    this.getLicensePlateCustomerSettings();
  }

  getLicensePlateCustomerSettings() {
    this.apiService.getLicensePlateCustomerSettings().subscribe(result => {
      this.request.IdentificationNumber = result.IdentificationNumber;
      this.request.RdwCompanyNumber = result.RdwCompanyNumber;
      this.request.RdwCompanyCardNumber = result.RdwCompanyCardNumber;
    });
  }

  isTrailerPlateSelected() {
    if (!this.request.LicensePlateType) return false;
    return this.request.LicensePlateType.IsTrailerPlate;
  }

  compareReason(c1: LicensePlateReason, c2: LicensePlateReason): boolean {
    return c1 && c2 ? c1.ReasonId === c2.ReasonId : c1 === c2;
  }

  compareType(c1: LicensePlateType, c2: LicensePlateType): boolean {
    return c1 && c2 ? c1.LicensePlateTypeId === c2.LicensePlateTypeId : c1 === c2;
  }

  reasonChanged() {
    if (this.request && this.request.Reason && this.request.Reason.ReasonId == 0) {
      this.request.Amount = 2;
    } else {
      // this.request.Amount = 1; 
    }
    this.updateLicensePlateTypes();
  }

  updateLicensePlateTypes() {
    let onlyTrailerPlates = false;
    if (this.request && this.request.Reason) { onlyTrailerPlates = this.request.Reason.ReasonId === 3; }
    //this.licensePlateService.getLicensePlateTypes(onlyTrailerPlates);
    this.licensePlateService.getLicensePlateTypes(false); // Altijd alle soorten voortaan
  }

  orderLicensePlate() {
    console.info(this.request);

    this.licensePlateService.orderLicensePlate(this.request).subscribe(result => {
      if (result.Success) {
        this.resetAlles();
        this.toasterService.showToast("Succes", `De kentekenplaat ${result.LicensePlateNumber} is toegevoegd aan uw winkelwagen.`);
        this.shopService.gotoStartupModule(this.mainService.ctxMain);
      } else {
        console.log(result.Message);
        this.mainService.msgBox("Let op!", `Er is helaas iets fout gegaan bij het toevoegen van de kentekenplaat aan uw winkelwagen.`);
      }
    }, error => {
      console.error(error);
      if (error.status === 400) {
        this.mainService.msgBox('Fout', error.error);
      }
    });
  }

  resetAlles() {
    this.request = new LicensePlateRequest();
    this.request.DuplicateCode = 0;
    this.request.Amount = 1;
    this.getLicensePlateCustomerSettings();
  }
}
