import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Input, EventEmitter, AfterViewInit } from '@angular/core';

import { MainService } from '../../../_services/main.service';
import { ResizeInfo } from '../../../_models/common/resize.info';
import { ViewKind } from '../../../_models/common/view.kind';
import { ViewKindInfo } from '../../../_models/common/view.kind.info';
import { CatalogService } from '../../../_services/catalog.service';
import { ShopService } from '../../../_services/shop.service';
import { ItemInfoService } from '../../../_services/item-info.service';
import { Subscription } from 'rxjs';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../_services/settings.service';

@Component({
  selector: 'app-item-info-boxed-layout',
  templateUrl: './item-info-boxed-layout.component.html',
  styleUrls: ['./item-info-boxed-layout.component.scss']
})
export class ItemInfoBoxedLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  _ctx: ContextCatalog;
  get ctx(): ContextCatalog { return this._ctx; }
  @Input('ctx')
  set ctx(value: ContextCatalog) {
    this._ctx = value;
    this.mainService.fireReziseEvent();
  }
  item: string;
  searchInDescriptions: boolean;
  viewKinds: ViewKindInfo[] = [{ kind: ViewKind.Card, icon: 'fas fa-th' }, { kind: ViewKind.Detail, icon: 'fas fa-th-list' }];
  private containerHeight = '0';
  private itemContainer: ElementRef;
  @ViewChild('itemContainer') set content(content: ElementRef) {
    this.itemContainer = content;
    this.resizeInfosSubscription = this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
  }
  resizeInfosSubscription: Subscription;
  focusInput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public catalogService: CatalogService,
    public itemInfoService: ItemInfoService,
    public router: Router,
    public settingsService: SettingsService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  // changeMee(optie: string) {
  //   if (this.ctx) {
  //     this.ctx[optie] = this.ctx[optie];
  //   }
  // }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'max-height': this.containerHeight,
        'overflow': 'hidden',
        'overflow-y': 'auto'
      };
    }
    return null;
  }

  handleResizeInfo(info: ResizeInfo) {
    if (this.itemContainer) {
      this.containerHeight = (info.clientHeight - info.headerHeight - this.itemContainer.nativeElement.offsetTop + 105) + 'px';
    }
  }

  onViewKindChanged(): void {
    this.mainService.fireReziseEvent();
  }



}
