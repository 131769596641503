import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ShopSoort } from '../_models/common/shop.soort';
import { CarTypeService } from './car-type.service';
import { MessageService } from './message.service';
import { CartService } from './cart.service';
import { ContextBase } from '../_models/common/context.base';
import { ApiService } from './api.service';
import { ContextAldocSys } from '../_models/aldoc-sys/context.aldoc.sys';
import { ShopService } from './shop.service';
import { MainService } from './main.service';
import { ContextNhsShop } from '../_models/nhs-shop/context.nhs.shop';
import { RequestContextNhsShop } from '../_models/nhs-shop/request.context.nhs.shop';
import { ThirdpartyShopSession } from '../_models/thirdparty-shop/thirdparty-shop-session';
import { UniversalCarType } from '../_models/car-types/universal.car.type';

@Injectable({
  providedIn: 'root'
})
export class ThirdpartyShopService {

  constructor(
    private carTypeService: CarTypeService,
    private messageService: MessageService,
    private cartService: CartService,
    private shopService: ShopService,
    private mainService: MainService,
    private apiService: ApiService
  ) { }

  withoutCarType(shopSoort: ShopSoort): boolean {
    return (shopSoort === ShopSoort.Toolbase)
      || (shopSoort === ShopSoort.NhsArtikelZoeken)
      || (shopSoort === ShopSoort.UniversalParts)
      || (shopSoort === ShopSoort.Sinatec);
  }

  getUniqueName(shopKind: ShopSoort, name: string): string {
    return `thirdparty_shop${shopKind}_${name.toLocaleLowerCase()}`;
  }

  getShopSession(shopSoort: ShopSoort): Observable<ThirdpartyShopSession> {
    const shop = this.shopService.getShopModuleByShopSoort(this.mainService.ctxMain, shopSoort);
    if (shop.Message) {
      this.mainService.msgBoxExtended(shop.Message);
    }
    switch (shopSoort) {
      case ShopSoort.hbase:
      case ShopSoort.Toolbase:
      case ShopSoort.UniversalParts:
      case ShopSoort.Autotools:
      case ShopSoort.AMT_Reparatiewijzer:
      case ShopSoort.Greenline:
      case ShopSoort.Modificaties:
      case ShopSoort.Recalls:
      case ShopSoort.Hartsant:
      case ShopSoort.NhsArtikelZoeken:
        const request = new RequestContextNhsShop(shopSoort, this.carTypeService.currentCarType, shop.Properties['url'], shop.Properties['domain'], shop.Properties['ticket'], document.location.origin);
        return ThirdpartyShopSession.Init(shopSoort, this.apiService.getContextNhsShop(request));
      case ShopSoort.AldocSys:
        return ThirdpartyShopSession.Init(shopSoort, this.apiService.getContextAldocSys());
      default:
        return of(null);
    }
  }

  getNhsUrl(ctx: ContextNhsShop): string {
    const carType = ctx.CarType;
    let url: string = ctx.FrameUrl;
    if (carType && carType.LicensePlate) { url += `&regcode=${carType.LicensePlate.Bare}`; }
    if (carType && (carType.CatalogCarTypes && carType.CatalogCarTypes['Hbase'])) {
      const hbcar = carType.CatalogCarTypes['Hbase'][0];
      if (!hbcar['VIN']) {
        url += `&brandcode=${hbcar['HbaseTypeId']['BrandCode']}&typecode=${hbcar['HbaseTypeId']['TypeCode']}`;
      } else if (!carType.LicensePlate) {
        url += `&regcodetype=chassis&regcode=${hbcar['VIN']}`;
      }
    }
    return url;
  }

  receiveMessagesNhs(ctx: ContextBase): void {
    const shop = this.shopService.getShopModuleByShopSoort(this.mainService.ctxMain, ctx.ShopKind);
    if (!this.messageService.isRegistered('NhsShop', shop.Properties['domain'])) {
      this.messageService.messageListener('NhsShop', shop.Properties['domain']).subscribe(message => {
        if (message) {
          console.info(`NhsShop - ${shop.Properties['ticket']} ->`, message);
          if (message.substr(0, 11) === 'CartAction;') {
            const data = message.split(';');
            if (data.length > 10) {
              let car = '';
              let licensePlate = '';
              const carType = this.carTypeService.currentCarType;
              if (carType) { car = carType.TypeId; }
              let aantal = -999;
              aantal = Number(data[12]);
              if (Number.isNaN(aantal) || aantal === -999) {
                aantal = Number(data[10].substr(0, data[10].length - 1));
              }
              if (Number.isNaN(aantal) || aantal === -999 || aantal === -1) {
                aantal = 1;
              }
              if (carType && carType.LicensePlate) { licensePlate = carType.LicensePlate.Bare; }
              this.cartService.addItemToCart(data[6], Number(data[3]), data[7], aantal, 0, car, licensePlate, shop.ID);
            }
          }
        }
        // NhsShop -> CartAction;135;4358278;;;0;64-010-012;Airman;0;27736;1A
        // ShopApplicationID;HalosInternNummer;HalossArtikelGroep;Leverancier;HbArtikelgroep;ArtikelNummer;Fabrikant;?;GroupID;Aantal
        // NhsShop -> CartAction_ArticleChange,CartArticleID=10083547
      });
    }
  }

  receiveMessagesAldocSys(ctx: ContextAldocSys): void {
    if (!this.messageService.isRegistered('AldocSys', ctx.Domain)) {
      this.messageService.messageListener('AldocSys', ctx.Domain).subscribe(message => {
        if (message) {
          console.info(`AldocSys ->`, message);
          if (message['application'] && message['application'] === 'AldocCart') {
            console.info(`receiveMessagesAldocSys application:AldocCart -> updateCart quantity: ${message['quantity']}`);
            this.cartService.updateCart(true);
          }
          if (message['Kent']
            && (!this.carTypeService.currentCarType || (this.carTypeService.currentCarType
              && this.carTypeService.currentCarType.LicensePlate
              && this.carTypeService.currentCarType.LicensePlate.Bare !== message['Kent']))) {
            console.info(`receiveMessagesAldocSys LicensePlate changed to ${message['Kent']} -> updateCarType`);
            this.carTypeService.searchLicensePlate(ShopSoort.Catalogus, message['Kent'])
              .subscribe(result => {
                if (result && result.CarType) {
                  this.carTypeService.saveCarType(ShopSoort.Catalogus, result.CarType)
                    .subscribe(saved => {
                      this.shopService.resetCarTypeShops(this.mainService.ctxMain);
                      this.carTypeService.setCarType(saved.CarType);
                    });
                }
              });
          }
        }
      });
    }
  }

  createIframe(shopKind: ShopSoort, container: HTMLDivElement, url: string): HTMLIFrameElement {
    const iframe = container.ownerDocument.createElement('iframe');
    iframe.id = this.getUniqueName(shopKind, 'frame');
    iframe.name = iframe.id;
    iframe['frameBorder'] = '0';
    iframe.style.flexGrow = '1';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.src = url;
    return iframe;
  }

  initFrameNhs(ctxBase: ContextBase, container: HTMLDivElement): HTMLIFrameElement {
    const ctx = ctxBase as ContextNhsShop;
    if (container) {
      this.receiveMessagesNhs(ctx);
      const iframe = this.createIframe(ctx.ShopKind, container, this.getNhsUrl(ctx));
      container.appendChild(iframe);
      return iframe;
    }
    return null;
  }

  initFrameAldocSys(ctxBase: ContextBase, container: HTMLDivElement): HTMLIFrameElement {
    const ctx = ctxBase as ContextAldocSys;
    const oO = { login: `${ctx.Catalog}\u2022${ctx.User}`, password: ctx.Password, application: 'AldocSys', ackreq: true, ShowCart: 0 };
    if (container) {
      this.receiveMessagesAldocSys(ctx);
      const iframe = this.createIframe(ctx.ShopKind, container, ctx.Url);
      container.appendChild(iframe);
      const form = container.ownerDocument.createElement('form');
      form.action = ctx.Url;
      form.method = 'POST';
      form.target = iframe.name;
      container.appendChild(form);
      for (const att of Object.keys(oO)) {
        const input = container.ownerDocument.createElement('input');
        input.name = att;
        input.value = '' + oO[att];
        input.type = 'hidden';
        form.appendChild(input);
      }
      form.submit();
      return iframe;
    }
    return null;
  }

  initFrame(session: ThirdpartyShopSession) {
    if (session && session.ctx && session.ctx.ShopKind) {
      switch (session.ctx.ShopKind) {
        case ShopSoort.hbase:
        case ShopSoort.Toolbase:
        case ShopSoort.UniversalParts:
        case ShopSoort.Autotools:
        case ShopSoort.AMT_Reparatiewijzer:
        case ShopSoort.Greenline:
        case ShopSoort.Modificaties:
        case ShopSoort.Recalls:
        case ShopSoort.Hartsant:
        case ShopSoort.NhsArtikelZoeken:
          session.iframe = this.initFrameNhs(session.ctx, session.container);
          break;
        case ShopSoort.AldocSys:
          session.iframe = this.initFrameAldocSys(session.ctx, session.container);
          break;
      }
    } else { console.warn('initFrame: No session or context!!'); }
  }

  changedOptionsNeedsRefresh(shopSoort: ShopSoort) {
    switch (shopSoort) {
      case ShopSoort.hbase:
      case ShopSoort.Toolbase:
      case ShopSoort.UniversalParts:
      case ShopSoort.Autotools:
      case ShopSoort.AMT_Reparatiewijzer:
      case ShopSoort.Greenline:
      case ShopSoort.Modificaties:
      case ShopSoort.Recalls:
      case ShopSoort.Hartsant:
      case ShopSoort.NhsArtikelZoeken:
        return true;
    }
    return false;
  }

  updateCarType(session: ThirdpartyShopSession, carType: UniversalCarType) {
    if (session && session.shopSoort === ShopSoort.AldocSys && carType && carType.LicensePlate) {
      console.info(`ThirdpartyShopComponent: updateCarType`);
      const ctx = session.ctx as ContextAldocSys;
      session.iframe.contentWindow.postMessage(
        {
          application: 'AldocSys',
          ackreq: true,
          title: document.title,
          status: 'OK',
          ShowCart: 0,
          Type: 0,
          Kent: carType.LicensePlate.Bare
        },
        ctx.Scheme + '://' + ctx.Domain
      );
    }
  }

  refreshFrame(session: ThirdpartyShopSession) {
    if (session && session.iframe) {
      const tmpUrl = session.iframe.src;
      session.iframe.src = '';
      setTimeout(() => {
        session.iframe.src = tmpUrl;
      }, 500);
    }
  }

}
