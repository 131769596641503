import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ShopModuleMenuItem } from '../../../../../_models/common/shop.module.menu.item';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { SettingsMenu } from '../../../../../_models/admin/settings/settings.menu';

@Component({
  selector: 'app-admin-shop-module-menu-item',
  templateUrl: './admin-shop-module-menu-item.component.html',
  styleUrls: ['./admin-shop-module-menu-item.component.scss']
})
export class AdminShopModuleMenuItemComponent implements OnInit {
  menuItem: ShopModuleMenuItem;
  shopModules: { [key: number]: string };
  ExtraStyles: { [key: string]: string } = {};
  settings: SettingsMenu;
  focusDescription: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onClose: Subject<ShopModuleMenuItem>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.shopModules = JSON.parse(JSON.stringify(this.settings.ModuleNames));
    this.shopModules[0] = 'Geen Shop Module, maar submenu of link';
    this.shopModules[-3] = 'Start-up Shop Module';
    this.shopModules[-2] = 'Instellingen';
    this.shopModules[-1] = 'Winkelwagen';
    this.ExtraStyles[''] = 'Geen';
    this.ExtraStyles['d-none d-xl-block'] = 'Alleen bij breed scherm zichtbaar';
    this.ExtraStyles['d-xl-none'] = 'Alleen bij small scherm zichtbaar';
    this.ExtraStyles['btn btn-danger'] = 'Rode knop';
    setTimeout(() => {
      this.focusDescription.emit(true);
    }, 0);
  }

  getPlaceholder(menuItem: ShopModuleMenuItem): string {
    if (this.settings.Modules[menuItem.ShopKind]) return this.settings.Modules[menuItem.ShopKind].Naam;
    if (menuItem.ShopKind !== 0) return 'module staat uit!';
    return '';
  }

  public confirm(menuItem: ShopModuleMenuItem) {
    this.onClose.next(menuItem);
    this.bsModalRef.hide();
  }

}
