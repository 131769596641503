<div class="row mb-0">
  <div *ngIf="labelCols" [ngClass]="labelClass" class="col-form-label admin-label noselect pe-0">
    <ng-content select="[beforeLabel]"></ng-content>
    <label [for]="'label'+uniqueId">
      <span *ngIf="busy" class="me-2"><i class="fas fa-sync fa-spin"></i></span>
      {{label}}
    </label>
    <ng-content select="[afterLabel]"></ng-content>
  </div>
  <div [ngClass]="'col-sm-' + (12-labelCols)" class="my-auto">
    <div class="parent">
      <ng-content></ng-content>
      <div *ngIf="disabled" class="disabler"></div>
    </div>
  </div>
</div>

<app-admin-divider [dividerKind]="dividerKind"></app-admin-divider>