import { Component, OnInit, ElementRef, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { ShopSoort } from '../../../_models/common/shop.soort';

@Component({
  selector: 'app-catalog-parts',
  templateUrl: './catalog-parts.component.html',
  styleUrls: ['./catalog-parts.component.scss']
})
export class CatalogPartsComponent implements OnInit {
  shopSoort = ShopSoort;
  ctx: ContextCatalog;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.parts;
      });
  }

}
