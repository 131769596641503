<div class="row">
  <div class="col ms-2 mb-2">
    <button type="button" class="btn btn-primary w-100" (click)="clickMenuItem(menuItem)">
      <span>{{menuItem.Description}}</span>
      <div *ngIf="menuItem.MenuItems" class="pointer collapse-button" [ngClass]="{'is-collapsed':menuItem['uncollapsed']}"><i class="fas fa-angle-down"></i></div>
    </button>
  </div>
</div>
<div *ngIf="menuItem.MenuItems" class="row menu-items" [@slideInHorizontal]="!menuItem['uncollapsed']">
  <div class="col ms-2">
    <app-side-menu-default-button *ngFor="let subItem of menuItem.MenuItems" [menuItem]="subItem"></app-side-menu-default-button>
  </div>
</div>
