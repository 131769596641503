<ng-template #customItemTemplate let-model="item" let-index="index">
  <span class="small itemnumber">{{model.ItemNumber}}</span><br />
  <span class="small description">{{model.Description}}</span>
</ng-template>
<div class="input-group">
  <input type="text" [appFocus]="focusInput" [typeahead]="apiService.getTypeaheadItemNumber(start)"
    [typeaheadItemTemplate]="customItemTemplate" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8"
    [id]="'input'+uniqueId" class="form-control" [disabled]="disabled" [(ngModel)]="start"
    (ngModelChange)="changeStart($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
    [typeaheadOptionField]="'ItemNumber'" [placeholder]="placeholder?placeholder:''" autocomplete="off">
  <span class="input-group-text">
    <i class="fas fa-search"></i>
  </span>
</div>