import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { ShopModuleMenuItem } from '../../../../_models/common/shop.module.menu.item';
import { SettingsMenu } from '../../../../_models/admin/settings/settings.menu';
import { MainService } from '../../../../_services/main.service';
import { AdminShopModuleMenuItemComponent } from './admin-shop-module-menu-item/admin-shop-module-menu-item.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-shop-module-menu',
  templateUrl: './admin-shop-module-menu.component.html',
  styleUrls: ['./admin-shop-module-menu.component.scss']
})
export class AdminShopModuleMenuComponent implements OnInit {
  @Input() settings: SettingsMenu;
  @Input() menu: ShopModuleMenuItem[] = [];
  @Output() changed: EventEmitter<ShopModuleMenuItem[]> = new EventEmitter<ShopModuleMenuItem[]>();
  activeMenuItem: ShopModuleMenuItem;

  constructor(
    public mainService: MainService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  getDescription(menuItem: ShopModuleMenuItem): string {
    if (menuItem.Description) return menuItem.Description;
    if (this.settings.Modules[menuItem.ShopKind]) return this.settings.Modules[menuItem.ShopKind].Naam;
    if (this.settings.ModuleNames[menuItem.ShopKind]) return this.settings.ModuleNames[menuItem.ShopKind];
    return '';
  }

  isEnabled(menuItem: ShopModuleMenuItem): boolean {
    if (!menuItem.ShopKind) return true;
    if (this.settings.Modules[menuItem.ShopKind] && this.settings.Modules[menuItem.ShopKind].Active) return true;
    return false;
  }

  menuItemClick(menuItem: ShopModuleMenuItem) {
    if (!menuItem.MenuItems) menuItem.MenuItems = [];
    if (this.activeMenuItem !== menuItem) this.activeMenuItem = menuItem;
    else this.activeMenuItem = null;
  }

  menuItemEdit(event, menuItem: ShopModuleMenuItem) {
    const index = this.menu.indexOf(menuItem);
    const edit = new ShopModuleMenuItem();
    edit.ShopKind = menuItem.ShopKind;
    edit.Description = menuItem.Description;
    edit.Link = menuItem.Link;
    edit.CssClass = menuItem.CssClass;
    const initialState = {
      menuItem: edit,
      settings: this.settings
    };
    const modalRef = this.modalService.show(AdminShopModuleMenuItemComponent, { initialState, class: 'modal-md' });
    if (event) this.mainService.preventAndStop(event)
    return modalRef.content.onClose
      .subscribe((editedMenuItem: ShopModuleMenuItem) => {
        if (editedMenuItem && this.menu[index]) {
          this.menu[index].ShopKind = editedMenuItem.ShopKind;
          this.menu[index].Description = editedMenuItem.Description;
          this.menu[index].Link = editedMenuItem.Link;
          this.menu[index].CssClass = editedMenuItem.CssClass;
          this.changed.emit(this.menu);
        }
      });
  }

  menuItemDelete(event, menuItem: ShopModuleMenuItem) {
    const index = this.menu.indexOf(menuItem);
    if (index >= 0) this.menu.splice(index, 1);
    this.changed.emit(this.menu);
    if (event) this.mainService.preventAndStop(event)
  }

  newMenuItem() {
    const item = new ShopModuleMenuItem();
    item.Id = 0;
    item.ShopKind = 0;
    item.Description = '';
    item.Link = '';
    item.CssClass = '';
    item.ParentId = 0;
    this.menu.push(item);
    this.changed.emit(this.menu);
    this.menuItemEdit(null, item);
  }

  drop(event: CdkDragDrop<ShopModuleMenuItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.changed.emit(this.menu);
  }

}
