<div class="card border-primary">
  <div class="card-header text-bg-primary py-1 px-2"
    (contextmenu)="mainService.showDebugInfo($event, ctx.Timing, ctx)">
    <span class="panel-title">{{parentPart.Brand}} {{parentPart.Description}}</span>
    <span class="float-end close-button" (click)="closeDialog()"><i class="fas fa-times fa-2x"></i></span>
  </div>
  <div class="card-body p-0"
    [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 100) + 'px', 'overflow': 'auto'}">
    <app-catalog-parts-compact-list [ctx]="ctx"
      [maxItems]="settingsService.getSettingNumber('ShowCatalogPartsPerCount')"></app-catalog-parts-compact-list>
  </div>
</div>