<ng-template #customItemTemplate let-model="item" let-index="index">
  <span class="small">{{model.Name}}</span>&nbsp;<span class="small lighter">{{model.CustomerNumber}}</span><br />
  <span class="small lighter">{{model.City}}</span>
</ng-template>

<app-admin-custom-control [label]="label" [centerLabel]="centerLabel" [labelCols]="labelCols"
  [dividerKind]="dividerKind" [disabled]="disabled">
  <div class="input-group">
    <input type="text" [appFocus]="focusInput" [typeahead]="adminService.searchCustomer(start, wholesaler)"
      [typeaheadItemTemplate]="customItemTemplate" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8"
      [id]="'input'+uniqueId" class="form-control" [disabled]="disabled" [(ngModel)]="start"
      (typeaheadOnSelect)="typeaheadOnSelect($event)" (blur)="onBlur($event)" [typeaheadOptionField]="'Name'" 
      [adaptivePosition]="true" [placeholder]="placeholder?placeholder:''" autocomplete="off" (ngModelChange)="startChange.emit(start)" >
    <ng-content></ng-content>
  </div>
</app-admin-custom-control>