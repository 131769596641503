<app-admin-form [title]="'Order Zoeken'" [object]="response??{}">

  <app-admin-custom-control [label]="'Kies klant, artikel, pakbonnummer  en/of order-id'">
    <div class="row" (contextmenu)="mainService.showObject($event, request)">
      <div class="col-md-6"><app-admin-customer-input [labelCols]="0" [dividerKind]="1" [(model)]="request.Customer"
          [noneSelectionLabel]="'Geen klant gekozen.'"></app-admin-customer-input></div>
      <div class="col-md-6"><app-admin-item-input [labelCols]="0" [dividerKind]="1"
          [(model)]="request.InternalItemNumber" [noneSelectionLabel]="'Geen artikel gekozen.'"></app-admin-item-input>
      </div>
      <div class="col-md-6"><app-admin-number-input [labelCols]="0" [dividerKind]="-1"
          [(model)]="request.DeliveryNoteNumber" [placeholder]="'Kies eventueel pakbonnummer'"></app-admin-number-input>
      </div>
      <div class="col-md-6"><app-admin-input [labelCols]="0" [dividerKind]="-1" [(model)]="request.OrderId"
          [placeholder]="'Type eventueel order-id'"></app-admin-input>
      </div>
    </div>
  </app-admin-custom-control>

  <app-admin-custom-control [label]="'Datum vanaf en tot'">
    <div class="row">
      <div class="col-md-6"><app-admin-date-input [labelCols]="0" [dividerKind]="-1" [(model)]="request.DateTimeFrom"
          [withTimepicker]="true"></app-admin-date-input></div>
      <div class="col-md-6"><app-admin-date-input [labelCols]="0" [dividerKind]="-1" [(model)]="request.DateTimeTo"
          [withTimepicker]="true"></app-admin-date-input></div>
    </div>
  </app-admin-custom-control>
  <app-admin-action-button (clickAction)="search()" [buttonColor]="'info'">
    <span *ngIf="busy"><i class="fas fa-sync fa-spin"></i></span><span *ngIf="!busy"><i
        class="fas fa-search"></i></span> Zoek Orders
  </app-admin-action-button>

  <app-admin-custom-control *ngIf="response" [busy]="busy">

    <div *ngIf="!response?.Orders?.length;else orders" class="alert alert-danger mt-3" role="alert">
      <h5><i class="fas fa-exclamation-triangle"></i><span class="ms-2">Geen orders gevonden!</span></h5>
    </div>
    <ng-template #orders>
      <div class="table-responsive small">
        <table class="table table-striped table-hover table-sm">
          <thead>
            <th class="text-end header-cell">Ordernummer</th>
            <th class="header-cell">Klant</th>
            <th *ngIf="archiveOrderKindTypes.length==1" class="header-cell text-center">Herkomst</th>
            <th *ngIf="archiveOrderKindTypes.length>1" class="filter-cell text-center">
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <span class="text-nowrap"><span class="fw-bold me-2">Herkomst</span><span
                      [ngClass]="{'text-danger': archiveOrderKindFilter!=null}"><i
                        class="far fa-filter"></i></span></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <div class="dropdown-item pointer" (click)="setArchiveOrderKindFilter(null)"><span class="me-2"
                      [ngClass]="{'text-success': archiveOrderKindFilter==null, 'text-white': archiveOrderKindFilter!=null}"><i
                        class="fas fa-check"></i></span><span class="badge bg-white text-black">Alles</span></div>
                  <div *ngFor="let kind of archiveOrderKindTypes" class="dropdown-item pointer"
                    (click)="setArchiveOrderKindFilter(kind)"><span class="me-2"
                      [ngClass]="{'text-success': archiveOrderKindFilter==kind, 'text-white': archiveOrderKindFilter!=kind}"><i
                        class="fas fa-check"></i></span><app-archive-order-kind-badge
                      [archiveOrderKind]="getArchiveOrderKind(kind)"
                      [orderType]="getOrderType(kind)"></app-archive-order-kind-badge></div>
                </div>
              </div>
            </th>
            <th class="text-end header-cell">Totaal</th>
            <th class="text-center header-cell">Orderdatum</th>
            <th class="header-cell">Order Id</th>
            <th *ngIf="orderStatus.length==1" class="header-cell">Status</th>
            <th *ngIf="orderStatus.length>1" class="filter-cell">
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <span class="fw-bold me-2">Status</span><span [ngClass]="{'text-danger': orderStatusFilter!=null}"><i
                      class="far fa-filter"></i></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <a class="dropdown-item" (click)="setOrderStatusFilter(null)"><span class="me-2"
                      [ngClass]="{'text-success': orderStatusFilter==null, 'text-white': orderStatusFilter!=null}"><i
                        class="fas fa-check"></i></span>Alles</a>
                  <a *ngFor="let status of orderStatus" class="dropdown-item"
                    (click)="setOrderStatusFilter(status)"><span class="me-2"
                      [ngClass]="{'text-success': orderStatusFilter==status, 'text-white': orderStatusFilter!=status}"><i
                        class="fas fa-check"></i></span>{{status}}</a>
                </div>
              </div>
            </th>
          </thead>
          <tbody>
            <ng-container *ngFor="let order of filteredOrders">
              <tr
                [routerLink]="['/admin/superadmin/admin-order-info', order.OrderNumber!=0?order.OrderNumber:order.Id]"
                class="pointer">
                <td class="text-end">{{order.OrderNumber}}</td>
                <td>{{getCustomer(order)?.Name}}<span class="ms-2 text-low">({{order.Customer}})</span></td>
                <td class="text-center">
                  <app-archive-order-kind-badge [archiveOrderKind]="order.OrderKind"
                    [orderType]="order.OrderType"></app-archive-order-kind-badge>
                </td>
                <td class="text-end">
                  <span *ngIf="response.OrderAmount[order.OrderNumber]">{{response.OrderAmount[order.OrderNumber] |
                    price}}</span>
                </td>
                <td class="text-center text-nowrap">{{order.CreatedAt | date : 'dd-MM-yyyy HH:mm'}}</td>
                <td><span class="badge text-bg-success">{{order.OrderId}}</span></td>
                <td class="filter-cell smaller text-low">{{response.OrderStatus[order.OrderNumber]}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-template>

  </app-admin-custom-control>


</app-admin-form>