import { Component, OnInit, Input } from '@angular/core';
import { ContextTyres } from '../../../_models/tyres/context.tyres';
import { MainService } from '../../../_services/main.service';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { WheelTyreSet } from '../../../_models/rims-and-tyres/wheel.tyre.set';
import { ViewKind } from '../../../_models/common/view.kind';

@Component({
  selector: 'app-tyre-list',
  templateUrl: './tyre-list.component.html',
  styleUrls: ['./tyre-list.component.scss']
})
export class TyreListComponent implements OnInit {
  viewKind = ViewKind;
  _maxItemCount: number;
  @Input() ctx: ContextTyres;
  @Input() maxItems: number;
  _tyres: ShoppingCartItem[];
  get tyres(): ShoppingCartItem[] { return this._tyres; }
  @Input('tyres')
  set tyres(value: ShoppingCartItem[]) {
    this._tyres = value;
    this._maxItemCount = this.maxItems;
  }
  @Input() set: WheelTyreSet;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
    this._maxItemCount = this.maxItems;
  }

}
