export enum CatalogKind {
    Unknown = 0,
    Aldoc = 1,
    Hbase = 2,
    TecDoc = 3,
    Mpm = 4,
    Alcar = 5,
    InterTyre = 6,
    Sinatec = 7,
    Universal = 999
}
