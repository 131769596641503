import { CatalogKind } from './catalog.kind';

export class PartsType {
    Origin: CatalogKind;
    CustomerID: number;
    ID: number;
    Description: string;
    DescriptionOriginal: string;
    Score: number;
}
