<div *ngIf="sci" class="input-group input-group-sm item-count" [ngClass]="{'mx-auto': center}">
  <button class="btn btn-outline-secondary btn-xs" type="button"
    (click)="cartService.changeShoppingCartItemCount(sci, getPerQuantity())">
    <i class="fas fa-plus"></i>
  </button>
  <input type="text" [ngModel]="cartService.getShoppingCartItemCountFromCart(sci)" class="form-control" maxlength="4"
    (ngModelChange)="cartService.setShoppingCartItemCount(sci, $event, getPerQuantity())" appNumericOnly
    (contextmenu)="mainService.showObject($event, sci)">
  <button class="btn btn-outline-secondary btn-xs" type="button"
    (click)="cartService.changeShoppingCartItemCount(sci, getPerQuantity() * -1)">
    <i class="fas fa-minus"></i>
  </button>
</div>