import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { PartsPropertyUnitKind } from '../_models/catalog/parts.property.unit.kind';
import { PropertyKindCatalog } from '../_models/catalog/property.kind.catalog';
import { CatalogPropertyData } from '../_models/catalog/catalog.property.data';
import { PartsProperty } from '../_models/catalog/parts.property';


@Injectable({
  providedIn: 'root'
})
export class CatalogPropertyService {
  propertyKindCatalog = PropertyKindCatalog;
  partsPropertyUnitKind = PartsPropertyUnitKind;
  data: CatalogPropertyData;

  constructor(
    private apiService: ApiService
  ) {
  }

  init() {
    if (!this.data) {
      console.time('getCatalogPropertyData');
      this.apiService.getCatalogPropertyData()
        .subscribe(data => {
          this.data = data;
          console.timeEnd('getCatalogPropertyData');
        });
    }
  }

  hasProperty(properties: { [key: string]: PartsProperty[] }, propertyKind: PropertyKindCatalog) {
    const property = this.getPropertyText(propertyKind);
    return (properties && properties[property] && properties[property].length > 0);
  }

  getPartsPropertiesValueString(partsProperties: PartsProperty[]): string {
    const valueStrings: string[] = partsProperties.map(p => p.ValueString);
    return valueStrings.join(',');
  }

  getPropertyValueString(properties: { [key: string]: PartsProperty[] }, propertyKind: PropertyKindCatalog): string {
    if (this.hasProperty(properties, propertyKind)) {
      const property = this.getPropertyText(propertyKind);
      return this.getPartsPropertiesValueString(properties[property]);
    }
    return '';
  }

  getPropertyText(propertyKind: PropertyKindCatalog) {
    if (this.data && this.data.PartsPropertyKinds && this.data.PartsPropertyKinds[propertyKind]) {
      return this.data.PartsPropertyKinds[propertyKind];
    }
    return '';
  }

  getPropertyTranslation(propertyKind: PropertyKindCatalog, language = 'NL') {
    const property = this.getPropertyText(propertyKind);
    if (this.data && this.data.PartsPropertyTranslations
      && this.data.PartsPropertyTranslations[property] && this.data.PartsPropertyTranslations[property][language]) {
      return this.data.PartsPropertyTranslations[property][language];
    }
    return this.getPropertyText(propertyKind);
  }

  getPropertyKinds(properties: { [key: string]: PartsProperty[] }, except: PropertyKindCatalog[] = null): PropertyKindCatalog[] {
    const propKinds: PropertyKindCatalog[] = [];
    if (this.data && this.data.PartsPropertyKindsLookup) {
      Object.keys(properties).forEach(propString => {
        const propKind = this.data.PartsPropertyKindsLookup[propString];
        if (propKind && (!except || !except.includes(propKind))) { propKinds.push(propKind); }
      });
    }
    return propKinds;
  }

  getPropertyDiffs(propertiesArray: Record<string, PartsProperty[]>[]): PropertyKindCatalog[] {
    const diffs: PropertyKindCatalog[] = [];
    if (propertiesArray.length > 1) {
      const firstProps = propertiesArray[0];
      // Doorloop de properties van het eerste item in de array en Kijk of er verschillen zijn met de andere items in de array.
      Object.keys(firstProps).forEach(key => {
        const propKind = PropertyKindCatalog[key];
        for (let i = 1; i < propertiesArray.length; i++) {
          if (this.getPropertyValueString(firstProps, propKind) !== this.getPropertyValueString(propertiesArray[i], propKind)) {
            if (!diffs.includes(propKind)) { diffs.push(propKind); }
          }
        }
      });
      // Kijk ook nog even bij de andere items in de array of daar misschien properties zijn die niet in het eerste item bestaan.
      for (let i = 1; i < propertiesArray.length; i++) {
        Object.keys(propertiesArray[i]).forEach(key => {
          const propKind = PropertyKindCatalog[key];
          if (!this.hasProperty(firstProps, propKind)) {
            if (!diffs.includes(propKind)) { diffs.push(propKind); }
          }
        });
      }
    }
    return diffs;
  }

}
