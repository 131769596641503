<div class="container-fluid container-header">
    <div class="row g-0">
        <div class="col">
            <img src="/assets/img/identity/twimva/twimvalogo.png" (dblclick)="authService.gotoLogout()" />
        </div>
        <div class="col-lg-auto company-name">
            <span class="font-large text-end text-dark">{{ctx.CompanyInfo.CustomerName}}</span>
        </div>
        <div class="col-lg-auto ms-3">
            <div class="row g-0 float-end ms-3">
                <div class="col tijd-datum text-center"><span class="font-large text-light">{{shopService.dateTimeNow | date:'HH:mm'}}</span><br /><span class="font-small text-light">{{shopService.dateTimeNow | date:'EEEE d LLLL'}}</span></div>
                <div class="col cartContainer py-2" [routerLink]="['/cart']">
                    <img *ngIf="cartService.isCartEmpty()" src="/assets/img/icons/cart-leeg.png" />
                    <img *ngIf="!cartService.isCartEmpty()" src="/assets/img/icons/cart-vol-badge.png" />
                    <span *ngIf="!cartService.isCartEmpty()" class="cart-item-count text-white">{{cartService.getCartItemCount()}}</span>
                </div>
                <div class="col">
                    <div class="settingsContainer text-light" *ngIf="true">
                        <span [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fas fa-cog"></i></span>
                        <br />
                        <span (click)="cartService.doCartSlide(ctx)">
                            <div class="collapse-button" [ngClass]="{'is-collapsed': ctx.ShowCartContent}"><i class="fas fa-arrow-alt-circle-left"></i></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-legacy-tab-strip [ctx]="ctx"></app-legacy-tab-strip>
</div>