<div class="container">

  <app-breadcrumb-header [debugObject]="order" [autoStart]="true">
    <li appBreadcrumbHeaderItem [routerLink]="['/details/history']">Orderhistorie</li>
    <li appBreadcrumbHeaderItem [active]="true" *ngIf="order">{{order.OrderNumber}}</li>
  </app-breadcrumb-header>

  <app-grid-webshop [items]="getSortedItems()" emptyText="Geen orderregels gevonden." class="mt-3 mb-3">
    <ng-container grid-header>
      <div class="col-1">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'LineNumber'">Regel</app-sort-field>
      </div>
      <div class="col-2">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'ItemNumber'">Artikelnr</app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'ItemGroup'">Groep</app-sort-field>
      </div>
      <div class="col-3">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'Description'">Omschrijving
        </app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'ItemCount'">Aantal</app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'GrossPrice'">Bruto</app-sort-field>
      </div>
      <div class="col-1 text-end">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'NetPrice'">Netto</app-sort-field>
      </div>
      <div class="col-2 text-center">
        <app-sort-field [sortObjectId]="'order-history-regels'" [sortField]="'LicensePlateNumber'">Kenteken
        </app-sort-field>
      </div>
    </ng-container>
    <ng-template let-item>
      <div class="col-1 small">{{item.LineNumber}}</div>
      <div class="col-2 small">{{item.ItemNumber}}</div>
      <div class="col-1 text-end small">
        <span *ngIf="item.ItemGroup">{{item.ItemGroup}}</span>
      </div>
      <div class="col-3 small">{{item.Description}}</div>
      <div class="col-1 text-end small">{{item.ItemCount}}</div>
      <div class="col-1 text-end small">{{item.GrossPrice | amount}}</div>
      <div class="col-1 text-end small">{{item.NetPrice | amount}}</div>
      <div class="col-2 text-center small">
        <img *ngIf="item.LicensePlate" style="width: 100px;" [src]="licensePlateService.getLicensePlateUrl(item.LicensePlate) | async" />
      </div>
    </ng-template>
  </app-grid-webshop>

</div>
