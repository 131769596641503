import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextRimsAndTyres } from '../../../../_models/rims-and-tyres/context.rims.and.tyres';
import { ViewKindInfo } from '../../../../_models/common/view.kind.info';
import { ViewKind } from '../../../../_models/common/view.kind';
import { MainService } from '../../../../_services/main.service';
import { CarTypeService } from '../../../../_services/car-type.service';
import { ResizeInfo } from '../../../../_models/common/resize.info';
import { RimAndTyreService } from '../../../../_services/rim-and-tyre.service';


@Component({
  selector: 'app-rims-overview-boxed-layout',
  templateUrl: './rims-overview-boxed-layout.component.html',
  styleUrls: ['./rims-overview-boxed-layout.component.scss']
})
export class RimsOverviewBoxedLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ctx: ContextRimsAndTyres;
  viewKinds: ViewKindInfo[] = [{ kind: ViewKind.Card, icon: 'fas fa-th' }, { kind: ViewKind.Detail, icon: 'fas fa-th-list' }];

  private containerHeight = '0';
  private itemContainer: ElementRef;
  @ViewChild('itemContainer') set content(content: ElementRef) {
    this.itemContainer = content;
    this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
  }
  resizeInfosSubscription: Subscription;

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public rimsAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  ngAfterViewInit() {
    this.mainService.fireReziseEvent();
  }

  goBack(): void {
    this.rimsAndTyreService.clear();
    this.carTypeService.resetCarType(this.ctx.ShopKind);
  }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'max-height': this.containerHeight,
        'overflow': 'hidden',
        'overflow-y': 'auto'
      };
    }
    return null;
  }

  handleResizeInfo(info: ResizeInfo) {
    if (this.itemContainer) {
      this.containerHeight = (info.clientHeight - info.headerHeight - this.itemContainer.nativeElement.offsetTop + 105) + 'px';
    }
  }

}
