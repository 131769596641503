<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
    <table class="table table-fix-striped table-fix-hover pointer">
        <tr *ngFor="let item of items" (click)="choose(item)">
            <td>
                <b>{{item.ItemNumber}}</b><br /> {{item.Description}}
                <br /> {{item.ItemGroupDescription}} ({{item.ItemGroup}})<br /> {{item.Brand}}
            </td>
            <td>
                <app-item-info-template [itemInfo]="item" [nettoprijs]="nettoprijs"></app-item-info-template>
            </td>
        </tr>
    </table>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="choose(null)">Annuleren</button>
</div>