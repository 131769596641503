import { Part } from '../catalog/part';
import { ShoppingCartItem } from '../cart/shopping.cart.item';

export class MaintenancePartsSelection {
  PartsTypes: { [key: number]: number };
  PartsPerPartsType: { [key: number]: Part[] };
  SelectedPartPerPartsType: { [key: number]: Part };
  SelectedCartItemPerPartsType: { [key: number]: ShoppingCartItem };
  Populated: boolean;
}
