import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../_models/common/context.main';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() ctx: ContextMain;

  constructor() { }

  ngOnInit() {

  }

}
