import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-filtered-text',
  templateUrl: './filtered-text.component.html',
  styleUrls: ['./filtered-text.component.scss']
})
export class FilteredTextComponent implements OnInit {
  @Input() minimumFilterLength: number;
  _text: string;
  get text(): string { return this._text; }
  @Input('text')
  set text(value: string) {
    this._text = value;
    this.setSections();
  }

  _filter: string;
  get filter(): string { return this._filter; }
  @Input('filter')
  set filter(value: string) {
    this._filter = value;
    this.setSections();
  }

  _filterRegex: RegExp;
  get filterRegex(): RegExp { return this._filterRegex; }
  @Input('filterRegex')
  set filterRegex(value: RegExp) {
    this._filterRegex = value;
    this.setSections();
  }
  filterActive: boolean;
  begin: string;
  filterHit: string;
  end: string;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
    if (!this.minimumFilterLength) { this.minimumFilterLength = 1; }
    this.setSections();
  }

  setSections() {
    this.filterActive = false;
    if (this._filterRegex && this._filterRegex.source.length > this.minimumFilterLength) {
      this.begin = this.mainService.getStringSectionRegEx(0, this.text, this.filterRegex);
      this.filterHit = this.mainService.getStringSectionRegEx(1, this.text, this.filterRegex);
      this.end = this.mainService.getStringSectionRegEx(2, this.text, this.filterRegex);
      this.filterActive = true;
    } else if (this._filter && this._filter.length > this.minimumFilterLength) {
      this.begin = this.mainService.getStringSection(0, this.text, this.filter);
      this.filterHit = this.mainService.getStringSection(1, this.text, this.filter);
      this.end = this.mainService.getStringSection(2, this.text, this.filter);
      this.filterActive = true;
    }
  }

}
