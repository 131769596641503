import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { FavoriteItem } from '../_models/favorites/favorite.item';
import { MainService } from './main.service';
import { ShopService } from './shop.service';
import { ShopSoort } from '../_models/common/shop.soort';
import { Part } from '../_models/catalog/part';
import { ContextCatalog } from '../_models/catalog/context.catalog';
import { CatalogService } from './catalog.service';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  enabled: boolean | null = null;
  favorites: { [key: number]: FavoriteItem } = null;
  private toggleFavoriteTimeoutHandles: { [key: number]: number } = {};

  constructor(
    private apiService: ApiService,
    private mainService: MainService,
    private shopService: ShopService,
    private catalogService: CatalogService
  ) { }

  init(): void {
    this.clear();
  }

  clear(): void {
    this.favorites = null;
  }

  areFavoritesEnabled(): Observable<boolean> {
    if (this.enabled != null) {
      return of(this.enabled);
    } else {
      return this.mainService.getContextMain()
        .pipe(mergeMap(ctx => {
          if (ctx) {
            this.enabled = this.shopService.containsShopModule(ctx, ShopSoort.Favorites);
            return of(this.enabled);
          }
          this.enabled = null;
          return of(false);
        }));
    }
  }

  getFavorites(): Observable<{ [key: number]: FavoriteItem }> {
    if (this.favorites) {
      return of(this.favorites);
    } else {
      return this.apiService.getFavorites()
        .pipe(mergeMap(favorites => {
          this.favorites = favorites;
          return of(favorites);
        }));
    }
  }

  isFavorite(internalItemNumber: number): Observable<boolean> {
    return this.getFavorites()
      .pipe(mergeMap(favorites => {
        return of(favorites[internalItemNumber] !== undefined);
      }));
  }

  toggleFavorite(internalItemNumber: number, part: Part, sci: ShoppingCartItem) {
    if (this.favorites) {
      const exists = (this.favorites[internalItemNumber] !== undefined);

      if (exists) {
        delete this.favorites[internalItemNumber];
      } else {
        this.favorites[internalItemNumber] = new FavoriteItem(internalItemNumber);
      }

      if (this.toggleFavoriteTimeoutHandles[internalItemNumber]) { window.clearTimeout(this.toggleFavoriteTimeoutHandles[internalItemNumber]); }
      this.toggleFavoriteTimeoutHandles[internalItemNumber] = window.setTimeout((cbInternalItemNumber, cbPart, cbSci) => {
        const existsNow = (this.favorites[cbInternalItemNumber] !== undefined);

        if (existsNow) {
          this.addFavorite(cbInternalItemNumber, cbPart, cbSci)
            .subscribe(item => {
              this.favorites[cbInternalItemNumber] = item;
            });
        } else {
          this.apiService.removeFavorite(cbInternalItemNumber)
            .subscribe(ok => {
              if (ok) { delete this.favorites[cbInternalItemNumber]; };
            });
        }

        delete this.toggleFavoriteTimeoutHandles[cbInternalItemNumber];
      }, 2000, internalItemNumber, part, sci);
    }
  }

  addFavorite(internalItemNumber: number, part: Part, sci: ShoppingCartItem): Observable<FavoriteItem> {
    if (part) {
      return this.apiService.addFavoriteWithPart(internalItemNumber, part);
    } else if (sci) {
      return this.apiService.addFavoriteWithSci(internalItemNumber, sci);
    } else {
      return this.apiService.addFavorite(internalItemNumber);
    }
  }

  getContextFavorites(): Observable<ContextCatalog> {
    return this.apiService.getContextFavorites()
      .pipe(
        mergeMap((ctx: ContextCatalog) => {
          if (ctx) {
            ctx.FilterDescriptions = this.catalogService.getFilterDescriptions(ctx);
            const updateFilters = (): void => {
              this.catalogService.updateFilterDescriptionStock(ctx);
              this.catalogService.fillFilterParts(ctx);
            };
            updateFilters();
            this.catalogService.getCatalogPartsCartItemsForPartsDict(null, '', ctx.Parts, ctx.Timing, ctx, updateFilters)
              .subscribe(response => {
                if (response) {
                  ctx.PartsCartItems = response.CartItems;
                  ctx.Timing = response.Timing;
                }
              });
          }
          return of(ctx);
        }));
  }



}
