import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ShopSoort } from '../_models/common/shop.soort';
import { ApiService } from '../_services/api.service';
import { ContextCarTypeSelection } from '../_models/car-types/context.car.type.selection';
import { CarTypeService } from '../_services/car-type.service';
import { UniversalCarType } from '../_models/car-types/universal.car.type';
import { ShopService } from '../_services/shop.service';
import { MainService } from '../_services/main.service';
import { UniversalCarBrand } from '../_models/car-types/universal.car.brand';
import { UniversalCarModel } from '../_models/car-types/universal.car.model';
import { TimingObject } from '../_models/common/timing.object';
import { SettingsService } from '../_services/settings.service';

@Component({
  selector: 'app-car-type-selection',
  templateUrl: './car-type-selection.component.html',
  styleUrls: ['./car-type-selection.component.scss']
})
export class CarTypeSelectionComponent implements OnInit {
  shopKind = ShopSoort;
  ctx: ContextCarTypeSelection;
  timing: TimingObject;
  licensePlate: string;
  vin: string;
  shopSoort: ShopSoort;
  back: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private carTypeService: CarTypeService,
    private shopService: ShopService,
    public mainService: MainService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.back = (this.route.snapshot.queryParams['back'] === 'true');
    this.route.params.subscribe(params => {
      if (params && params.shop && params.shop !== '0') {
        this.shopSoort = params.shop as ShopSoort;
        if (this.shopSoort !== ShopSoort.ONBEKEND) {
          console.info(`carTypeService.getContextCarTypeSelection for shopSoort ${this.shopSoort}`);
          this.carTypeService.getContextCarTypeSelection(this.shopSoort)
            .subscribe(ctx => {
              this.ctx = ctx;
              this.timing = ctx.Timing;
              if (this.back && ctx.CurrentBrand && ctx.CurrentModel) { this.searchBrandModelType(ctx.CurrentBrand, ctx.CurrentModel); }
            });
        }
      }
    });
  }

  carTypeSelected(carType: UniversalCarType): void {
    this.carTypeService.saveCarType(this.shopSoort, carType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigate([returnUrl]);
      });
  }

  searchLicensePlate(licensePlate: string): void {
    this.carTypeService.searchLicensePlate(this.shopSoort, licensePlate)
      .subscribe(result => {
        if (result && result.CarType) {
          this.carTypeSelected(result.CarType);
        } else if (result && result.Timing) {
          this.timing = result.Timing;
        }
      });
  }

  searchBrandModelType(brand: UniversalCarBrand = null, model: UniversalCarModel = null): void {
    this.carTypeService.searchBrandModelType(this.shopSoort, this.ctx.Merken, brand, model)
      .subscribe(result => {
        if (result && result.CarType) {
          this.carTypeSelected(result.CarType);
        } else if (result && result.Timing) {
          this.timing = result.Timing;
        }
      });
  }

  searchVin(vin: string): void {
    this.carTypeService.searchVin(this.shopSoort, vin)
      .subscribe(result => {
        if (result && result.CarType) {
          this.carTypeSelected(result.CarType);
        } else if (result && result.Timing) {
          this.timing = result.Timing;
        }
      });
  }

  searchHistory(): void {
    this.carTypeService.searchHistory(this.ctx.Historie)
      .subscribe(result => {
        if (result && result.CarType) {
          this.carTypeSelected(result.CarType);
        } else if (result && result.Timing) {
          this.timing = result.Timing;
        }
      });
  }

}
