<div class="modal-header">
    <span class="modal-title" (contextmenu)="mainService.showObject($event, carTypes)">
  <b>Kies een eerder gekozen auto.</b>
</span>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
    <table class="table table-striped table-hover table-sm">
        <tr *ngFor="let car of carTypes" (click)="choose(car)">
            <td><img class="licenseplate" *ngIf="car.LicensePlate?.ImageUrl" [src]="car.LicensePlate.ImageUrl" /></td>
            <td>{{car.Display}}</td>
            <!-- <td>{{car.TypeName}}</td>
            <td>{{car.TypeRemark}}</td>
            <td class="text-nowrap">{{car.AvailableFrom.Display}}</td>
            <td class="text-nowrap">{{car.AvailableUntil.Display}}</td> -->
        </tr>
    </table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="choose(null)">Annuleren</button>
</div>