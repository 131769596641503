<div class="container">
  <div class="row my-3">
    <div class="col">
      <h4>Welkom bij Rijpma Automaterialen</h4>
      <h5><em>Voor al uw automaterialen en accessoires</em></h5>
      <h6>Gelieve met onderstaande rekening te houden bij het afhalen van producten:</h6>
      <ul>
        <li>Kom alleen</li>
        <li>Mondkapje verplicht</li>
        <li>Houd 1,5m afstand</li>
        <li>Desinfecteer uw handen bij de ingang</li>
        <li>Graag pinnen</li>
      </ul>
    </div>
    <div class="col-lg-3">
      <div class="card">
        <h6 class="card-header">Openingstijden</h6>
        <div class="card-body">
          <div class="row">
            <div class="col-4">MA</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">DI</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">WO</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">DO</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">VR</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">ZA</div>
            <div class="col-8 text-end">8:00-16:00</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card">
        <img src="/assets/img/identity/rijpma/rijpma.jpg" class="card-img-top">
        <div class="card-body">
          <h6 class="card-title">Rijpma Automaterialen</h6>
          <p class="card-text">
            Kanaalstraat 72 <br />
            9301 LT Roden <br />
            Tel: 050-5019197 <br />
            Fax: 050-5016315 <br />
            Email: <a href="mailto:info@rijpmaroden.nl">info&#64;rijpmaroden.nl</a> <br />
          </p>
        </div>
      </div>

    </div>
  </div>



</div>