<app-container [shopSoort]="shopSoort.Banden">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li *ngIf="carTypeService.currentCarType && set" appBreadcrumbHeaderItem [routerLink]="['/rims', carTypeService.currentCarType.Id]">{{shopService.getShopModuleName(this.mainService.ctxMain, shopSoort.WielenEnBanden)}}</li>
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="6"></li>
    <div class="ms-auto"><app-list-display-options [ctx]="ctx" [viewKinds]="viewKinds"></app-list-display-options></div>
  </app-breadcrumb-header>

  <app-side-panel-and-content>
    <ng-container side-panel>
      <app-side-panel *ngIf="settingsService.getSettingNumber('ShowTyreSizeInputInSidePanel')">
        <app-side-panel-header><i class="fas fa-sort-amount-down"></i> Bandenmaat</app-side-panel-header>
        <app-side-panel-body>
          <app-tyre-search-input [focusTyreInput]="focusTyreInput" [withoutExtraButtons]="true"></app-tyre-search-input>
        </app-side-panel-body>
      </app-side-panel>

      <app-side-panel>
        <app-side-panel-header><i class="fas fa-sort-amount-down"></i> Sorteren</app-side-panel-header>
        <app-side-panel-body>
          <app-sort-options [ctx]="ctx" [btn]="'btn-light w-100'"></app-sort-options>
        </app-side-panel-body>
      </app-side-panel>

      <app-side-panel>
        <app-side-panel-header>
          <i class="fas fa-filter"></i> Filteren
          <span class="float-end filter-result text-low">{{tyres?.length}} van {{tyreResult?.Tyres?.length}}</span>
        </app-side-panel-header>
        <app-side-panel-accordion-filter *ngIf="ctx.VoorraadOptie" [title]="'Voorraad'"
          [descriptions]="tyreResult?.FilterDescriptions.Stock" (filterChange)="stockChange($event)">
        </app-side-panel-accordion-filter>
        <app-side-panel-accordion-filter [title]="'Categorie'" [descriptions]="tyreResult?.FilterDescriptions.Categories"
          (filterChange)="filterChange('TyreKind',$event)"></app-side-panel-accordion-filter>
        <app-side-panel-accordion-filter [title]="'Merk'" [maxItems]="5"
          [descriptions]="tyreResult?.FilterDescriptions.Brands" (filterChange)="filterChange('TyreBrand',$event)">
        </app-side-panel-accordion-filter>
        <app-side-panel-accordion-filter *ngIf="hasRunflat()" [title]="'Runflat'"
          [descriptions]="tyreResult?.FilterDescriptions.Runflat" (filterChange)="runflatChange($event)">
        </app-side-panel-accordion-filter>
        <!-- load/speed -->
      </app-side-panel>
    </ng-container>
    <ng-container content>
      <app-tyre-list [ctx]="ctx" [tyres]="tyres" [set]="set" [maxItems]="settingsService.getSettingNumber('ShowTyresPerCount')"></app-tyre-list>
    </ng-container>
  </app-side-panel-and-content>

</app-container>
