import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-password-forgotten-popup',
  templateUrl: './password-forgotten-popup.component.html',
  styleUrls: ['./password-forgotten-popup.component.scss']
})
export class PasswordForgottenPopupComponent implements OnInit {
  data: [string, string];

  public onClose: Subject<[string, string]>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public confirm() {
    this.onClose.next(this.data);
    this.bsModalRef.hide();
  }

  public decline() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

}
