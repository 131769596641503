import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CarTypeService } from '../../../../_services/car-type.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { CarTypeResult } from '../../../../_models/car-types/car.type.result';
import { ContextCarTypeSelection } from '../../../../_models/car-types/context.car.type.selection';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-star-licenseplate',
  templateUrl: './star-licenseplate.component.html',
  styleUrls: ['./star-licenseplate.component.scss']
})
export class StarLicenseplateComponent implements OnInit {
  @Output() carTypeSelected: EventEmitter<CarTypeResult> = new EventEmitter();
  @Input() licensePlate: string;
  @Input() ctxCarTypeSelection: ContextCarTypeSelection;
  @Output() LicensePlateChange: EventEmitter<string> = new EventEmitter<string>();
  focusLicensePlate = new EventEmitter<boolean>();
  defaultCatalog = ShopSoort.Catalogus;

  constructor(
    private carTypeService: CarTypeService,
    private mainService: MainService
  ) { }

  ngOnInit() {
    if (this.mainService.ctxMain.DefaultCatalog) { this.defaultCatalog = this.mainService.ctxMain.DefaultCatalog; }
  }

  searchLicensePlate(licensePlate: string): void {
    this.carTypeService.searchLicensePlate(this.defaultCatalog, licensePlate)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected.emit(result); }
      });
  }

  searchBrandModelType(): void {
    this.carTypeService.searchBrandModelType(this.defaultCatalog, this.ctxCarTypeSelection.Merken, null, null)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected.emit(result); }
      });
  }

  searchVin(): void {
    this.mainService.inputBoxExtended('Zoeken op chassisnummer', 'Chassisnr', '', false, 'modal-md')
      .subscribe(vin => {
        if (vin) {
          this.carTypeService.searchVin(this.defaultCatalog, vin)
            .subscribe(result => {
              if (result && result.CarType) { this.carTypeSelected.emit(result); }
            });
        }
      });
  }

  searchHistory(): void {
    this.carTypeService.searchHistory(this.ctxCarTypeSelection.Historie)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected.emit(result); }
      });
  }

}
