import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { CatalogService } from '../../_services/catalog.service';
import { CarTypeService } from '../../_services/car-type.service';
import { MaintenancePartsSelection } from '../../_models/maintenance/maintenance.parts.selection';
import { ShopService } from '../../_services/shop.service';
import { MainService } from '../../_services/main.service';
import { ShopSoort } from '../../_models/common/shop.soort';


@Injectable({
  providedIn: 'root'
})
export class MaintenancePartsDataResolverService  {

  constructor(
    private catalogService: CatalogService,
    private carTypeService: CarTypeService,
    private shopService: ShopService,
    private mainService: MainService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<{ ctx: ContextCatalog, selection: MaintenancePartsSelection }> | Observable<never> {
    const shop = this.shopService.getShopModuleByShopSoort(this.mainService.ctxMain, ShopSoort.Maintenance);
    const carType = this.carTypeService.currentCarType;
    const selection = new MaintenancePartsSelection();
    if (shop && shop.Properties && shop.Properties['GenArts']) {
      if (shop.Properties['GenArts']['$type']) { delete shop.Properties['GenArts']['$type']; }
      selection.PartsTypes = shop.Properties['GenArts'];
      selection.PartsPerPartsType = {};
      selection.SelectedPartPerPartsType = {};
      selection.SelectedCartItemPerPartsType = {};

      return this.catalogService.getMaintenancePartsData(carType, selection).pipe(
        take(1),
        mergeMap(ctx => {
          if (ctx) {
            return of(ctx);
          } else { // no data?
            return EMPTY;
          }
        })
      );
    } else {
      return EMPTY;
    }

  }

}
