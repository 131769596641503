export enum PropertyKindCatalog {
    Unknown = 0,
    Length = 1,
    Width = 2,
    Height = 3,
    Length2 = 4,
    Width2 = 5,
    Height2 = 6,
    ThreadSize = 7,
    ThreadSize1 = 8,
    ThreadSize2 = 9,
    D3 = 10,
    InstallLocation = 11,
    Thickness = 12,
    Thickness2 = 13,
    InsideWidth = 14,
    System = 15,
    Weight = 16,
    Mark = 17,
    Bore = 18,
    InsideDiameter = 19,
    InsideDiameter2 = 20,
    OutsideDiameter = 21,
    Quantity = 22,
    Capacity = 23,
    Packaging = 24,
    Connections = 25,
    ExternalThread = 26,
    ThreadLength = 27,
    SparkPosition = 28,
    WrenchSize = 29,
    EngineCode = 30,
    Dimensions = 31,
    NumberOfRibs = 32,
    Holes = 33,
    WarehouseCode = 34,
    TrailerWeight = 35,
    MaxVerticaleLoad = 36,
    DValue = 37,
    ConeSlope = 38,
    Cone = 39,
    Flange = 40,
    NumberOfTeeth = 41,
    Diameter = 42,
    Voltage = 43,
    Charge = 44,
    Current = 45,
    Resistor = 46,
    RatedPower = 47,
    Depth = 48,
    Unit = 49,
    Diameter2 = 50,
    Diameter3 = 51,
    Diameter4 = 52,
    Lamp = 53,
    Specification = 54,
    Viscosity = 55,
    BaseType = 56,
    InternalThread = 57,
    Color = 58,
    ColorCode = 59,
    EntranceDiameter = 60,
    ExitDiameter = 61,
    CentreBore = 62,
    CenteringDiameter = 63,
    WheelInsertDiameter = 64,
    TubeDiameter = 65,
    Power = 66,
    EAN = 67,
    NumberOfWires = 68,
    DrillDiameter = 69,
    NutSize = 70,
    WireRange = 71,
    WireDiameter = 72,
    ThreadMetric = 73,
    ThreadLengthMetric = 74,
    EnglishUnits = 75,
    Quality = 76,
    Size = 77,
    MaleFemale = 78,
    Brand = 79,
    OENr = 80,
    SurfaceCoverage = 81,
    WindingPitch = 82,
    TensileStrength = 83,
    Model = 84,
    Shape = 85,
    Certificate = 86,
    Axle = 87,
    Bolt = 88,
    Nut = 89,
    CenterRing = 90,
    PictureNumber = 91,
    SystemNumber = 92,
    EngineCapacity = 93,
    CrankCaseCapacity = 94,
    CoolingSystemCapacity = 95,
    NoCylinders = 96,
    Refrigerants = 97,
    TyreSize = 98,
    Material = 99,
    PinDiameter = 100,
    BendAngle = 101,
    HookWidth = 102,
    ApplicableAt = 103,
    SuitableFor = 104,
    DriveType = 105,
    BodyForm = 106,
    FirstAdmission = 107,
    RegistrationDate = 108,
    ApkExpiration = 109,
    Tpms = 110,
    MassOfEmptyVehicle = 111,
    FuelType = 112,
    WheelDimension = 113,
    BuildYear = 114,
    RegistrationBrand = 115,
    RegistrationModel = 116,
    RegistrationVersion = 117,
    RegistrationDescription = 118,
    RegistrationFuel = 119,
    RegistrationEngineCapacity = 120,
    RegistrationPower = 121,
    RegistrationEngineCode = 122,
    OBDII = 123,
    TyreKind = 124,
    TyreBrand = 125,
    TyreSpeedIndex = 126,
    RunFlatTyre = 127,
    FuelEfficiencyClass = 128,
    WetGripClass = 129,
    RollNoiseValue = 130,
    RollNoiseClass = 131,
    ProfileImage = 132,
    SnowGrip = 133,
    IceGrip = 134,
    EprelId = 135,
    AlternateReference = 136,
    DateFrom = 137,
    DateTo = 138,
    NumberOfPins = 139,
    Length1 = 140,
    Width1 = 141,
    Height1 = 142,
    Remark = 143,
    Diameter1 = 144,
    NumberOfDoors = 145
  }
