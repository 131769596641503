import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';
import { MainService } from '../../../_services/main.service';
import { ContextTyres } from '../../../_models/tyres/context.tyres';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tyre-search-input',
  templateUrl: './tyre-search-input.component.html',
  styleUrls: ['./tyre-search-input.component.scss']
})
export class TyreSearchInputComponent implements OnInit {
  @Input() withoutExtraButtons = false;
  @Input() focusTyreInput = new EventEmitter<boolean>();
  @Output() gotFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  ctx: ContextTyres;
  categories: string[] = [''];
  brands: string[] = [];
  size: string;
  category: string;
  brand: string;
  brandFilter = '';
  focusFilter = new EventEmitter<boolean>();

  get queryParams(): any {
    if (this.brand || this.category) {
      const queryParams = {};
      if (this.category) { queryParams['category'] = this.category; }
      if (this.brand) { queryParams['brand'] = this.brand; }
      return queryParams;
    }
    return null;
  }

  constructor(
    public mainService: MainService,
    public rimAndTyreService: RimAndTyreService,
    public router: Router
  ) { }

  ngOnInit() {
    this.rimAndTyreService.getContextTyres()
      .subscribe(ctx => {
        this.ctx = ctx;
        if (ctx.TyreSearchInfo) {
          this.categories.push(...ctx.TyreSearchInfo.Seasons.map(s => s.Description));
          this.brands.push(...ctx.TyreSearchInfo.Brands);
        }
      });
  }

  reset() {
    this.size = '';
    this.category = '';
    this.brand = '';
  }

  dropdownClick(event: MouseEvent) {
    setTimeout(() => {
      this.focusFilter.emit(true)
    }, 100);
  }

  clearFilter(event: MouseEvent) {
    this.brandFilter = '';
    event.stopPropagation();
  }

  getCalculatorLink(): any[] {
    const link: any[] = ['/tyrecalc'];
    if (this.size) { link.push(this.size); }
    return link;
  }

}
