<app-container [renderCheck]="tyres" [shopSoort]="shopKind.BandenCalculator">
  
    <app-breadcrumb-header [debugObject]="tyres" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopKind.BandenCalculator"></li>
    </app-breadcrumb-header>

    <div class="row my-3">
      <div class="col-5 offset-1">
        <div class="input-group">
          <input type="text" appBandenMaat class="form-control tyre-input" [value]="tyres[0].toString()"
            placeholder="band A" [appSelectOnclick] (ngModelChange)="changeTyre(0, $event)" />
          <span class="input-group-text">Band A</span>
        </div>
      </div>
      <div class="col-5">
        <div class="input-group">
          <span class="input-group-text">Band B</span>
          <input type="text" appBandenMaat class="form-control tyre-input" [value]="tyres[1].toString()"
            placeholder="band B" [appSelectOnclick] (ngModelChange)="changeTyre(1, $event)" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[0].Width" [min]="sliderWidth.floor"
          [max]="sliderWidth.ceil" [step]="sliderWidth.step">
      </div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[0].Width}}</div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[1].Width}}</div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[1].Width" [min]="sliderWidth.floor"
          [max]="sliderWidth.ceil" [step]="sliderWidth.step">
      </div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[0].AspectRatio" [min]="sliderAspectRatio.floor"
          [max]="sliderAspectRatio.ceil" [step]="sliderAspectRatio.step">
      </div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[0].AspectRatio}}</div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[1].AspectRatio}}</div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[1].AspectRatio" [min]="sliderAspectRatio.floor"
          [max]="sliderAspectRatio.ceil" [step]="sliderAspectRatio.step">
      </div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[0].Diameter" [min]="sliderDiameter.floor"
          [max]="sliderDiameter.ceil" [step]="sliderDiameter.step">
      </div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[0].Diameter}}</div>
      <div class="col-3 text-center banden-calculator-value">{{tyres[1].Diameter}}</div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyres[1].Diameter" [min]="sliderDiameter.floor"
          [max]="sliderDiameter.ceil" [step]="sliderDiameter.step">
      </div>
    </div>
    <div class="row my-3">
      <div class="col-3 text-end">diameter</div>
      <div class="col-3 text-center data">{{tyres[0].getFullDiameter() | number : '1.1-1' }} cm</div>
      <div class="col-3 text-center data">{{tyres[1].getFullDiameter() | number : '1.1-1' }} cm</div>
      <div class="col-3">&nbsp;</div>
      <div class="col-3 text-end">verschil</div>
      <div class="col-6 text-center data">{{getDiameterDiff() | number : '1.1-1' }} cm</div>
      <div class="col-3">&nbsp;</div>
    </div>
    <div class="row my-3">
      <div class="col-3 text-end">omtrek</div>
      <div class="col-3 text-center data">{{tyres[0].getCircumFerence() | number : '1.1-1' }} cm</div>
      <div class="col-3 text-center data">{{tyres[1].getCircumFerence() | number : '1.1-1' }} cm</div>
      <div class="col-3">&nbsp;</div>
      <div class="col-3 text-end">verschil</div>
      <div class="col-6 text-center data">{{getCircumferenceDiff() | number : '1.1-1' }} cm</div>
      <div class="col-3">&nbsp;</div>
    </div>
    <div class="row my-3">
      <div class="col-3 text-end">toegestaan</div>
      <div class="col-3 text-center data">-2%</div>
      <div class="col-3 text-center data">1,5%</div>
      <div class="col-3">&nbsp;</div>
      <div class="col-3 text-end banden-calculator-afwijking"><span *ngIf="getDeviation()<-2 || getDeviation()>1.5"
          class="text-danger"><i class="fas fa-exclamation-triangle"></i></span> afwijking</div>
      <div class="col-6 banden-calculator-title text-center"
        [ngClass]="{'text-danger': getDeviation()<-2 || getDeviation()>1.5}">{{getDeviation() | number : '1.2-2' }} %
      </div>
      <div class="col-3">&nbsp;</div>
    </div>
    <div class="row my-3">
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyreSpeeds[0]" [min]="sliderSpeed.floor"
          [max]="sliderSpeed.ceil" [step]="sliderSpeed.step" (ngModelChange)="updateSpeed(1)">
      </div>
      <div class="col-3 text-center data">{{tyreSpeeds[0] | number : '1.0-0' }} KM/u</div>
      <div class="col-3 text-center data">{{tyreSpeeds[1] | number : '1.0-0' }} KM/u</div>
      <div class="col-3">
        <input type="range" class="form-range" [(ngModel)]="tyreSpeeds[1]" [min]="sliderSpeed.floor"
          [max]="sliderSpeed.ceil" [step]="sliderSpeed.step" (ngModelChange)="updateSpeed(0)">
      </div>
    </div>

</app-container>