import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../_services/api.service';
import { ContextHomeScreen } from '../../_models/common/context.home.screen';
import { PromotionService } from '../../_services/promotion.service';
import { ContextPromotions } from '../../_models/promotions/context.promotions';
import { ContextLoyaltyShop } from '../../_models/loyalty-shop/context.loyalty.shop';
import { LoyaltyShopService } from '../../_services/loyalty-shop.service';


@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {
  ctx: ContextHomeScreen;
  ctxPromotions: ContextPromotions;
  ctxLoyaltyShop: ContextLoyaltyShop;

  constructor(
    private apiService: ApiService,
    private promotionService: PromotionService,
    private loyaltyShopService: LoyaltyShopService
  ) { }

  ngOnInit() {
    this.getContext();
  }

  public getContext(): void {
    this.apiService.getContextHomeScreen()
      .subscribe(ctx => {
        this.ctx = ctx;
        if (ctx.HomeScreenUsesPromotions) {
          this.promotionService.getContext(true)
            .subscribe(ctxPromotions => {
              if (ctxPromotions && ctxPromotions.Promotions && ctxPromotions.Promotions.length > 0) {
                this.ctxPromotions = ctxPromotions;
              }
              setTimeout(() => {
                (<any>$('.carousel')).carousel({ interval: 3000 });
                (<any>$('.carousel')).carousel('cycle');
                // (<any>$('.carousel')).on('slide.bs.carousel', function () {
                //   console.info('slide...');
                // });
              }, 500);
            });
        }
        if (ctx.HomeScreenUsesLoyaltyItems) {
          this.loyaltyShopService.getContext(true)
            .subscribe(ctxLoyaltyShop => {
              if (ctxLoyaltyShop && ctxLoyaltyShop.LoyaltyShop && ctxLoyaltyShop.LoyaltyShop.Items
                && Object.keys(ctxLoyaltyShop.LoyaltyShop.Items).length > 0) {
                this.ctxLoyaltyShop = ctxLoyaltyShop;
              }
            });
        }
      });
  }

}
