export class ResizeInfo {
    clientWidth: number;
    clientHeight: number;
    headerHeight: number;
    menuWidth: number;
    cartWidth: number;

    constructor(clientWidth: number, clientHeight: number, headerHeight: number, menuWidth: number, cartWidth: number) {
        this.clientWidth = clientWidth;
        this.clientHeight = clientHeight;
        this.headerHeight = headerHeight;
        this.menuWidth = menuWidth;
        this.cartWidth = cartWidth;
    }

    static get breakPoints(): { name: string, from: number, to: number }[] {
        return [
            { name: 'xs', from: 0, to: 567 },
            { name: 'sm', from: 567, to: 768 },
            { name: 'md', from: 768, to: 992 },
            { name: 'lg', from: 992, to: 1200 },
            { name: 'xl', from: 1200, to: 1400 },
            { name: 'xxl', from: 1400, to: 1600 },
            { name: 'xxxl', from: 1600, to: 9999 }
        ];
    }

    get usableWidth(): number {
        return this.clientWidth - this.menuWidth - this.cartWidth;
    }

    get usableWidthString(): string {
        for (const size of ResizeInfo.breakPoints) {
            if (this.usableWidth < size.to) { return size.name; }
        }
        return 'xl';
    }

    isThisOrLarger(size: string): boolean {
        for (const s of ResizeInfo.breakPoints) {
            if (s.name == size) { return this.usableWidth >= s.from; }
        }
        return false;
    }

    isThisOrSmaller(size: string): boolean {
        for (const s of ResizeInfo.breakPoints) {
            if (s.name == size) { return this.usableWidth < s.to; }
        }
        return false;
    }
}
