import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailboxService {
  messageCount = 0;

  constructor() { }

  messageCountText(): string {
    if (this.messageCount === 1) { return 'U heeft één nieuw bericht'; }
    if (this.messageCount) { return `U heeft ${this.messageCount} nieuwe berichten`; }
    return 'U heeft geen nieuwe berichten';
  }
}
