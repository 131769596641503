import { AanbiedingSoort } from './aanbieding.soort';
import { AanbiedingImage } from './aanbieding.image';
import { AanbiedingArtikel } from './aanbieding.artikel';

export class Aanbieding {
    ID: number;
    Aktief: boolean;
    AanbiedingID: string;
    SoortAanbieding: AanbiedingSoort;
    Titel: string;
    Subtitel: string;
    Omschrijving: string;
    GeldigVanaf: Date;
    GeldigTot: Date;
    TekstVan: string;
    TekstVoor: string;
    PrijsVan: number;
    PrijsVoor: number;
    Filialen: number[];
    Images: AanbiedingImage[];
    AfbeeldingsUrl: string;
    Artikelen: AanbiedingArtikel[];
    Volgorde: number;

    constructor() {
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.ID = -1;
        this.Aktief = true;
        this.AanbiedingID = '';
        this.SoortAanbieding = AanbiedingSoort.Normaal;
        this.Titel = '';
        this.Subtitel = '';
        this.Omschrijving = '';
        this.GeldigVanaf = new Date(today);
        this.GeldigVanaf.setDate(today.getDate() + 1);
        this.GeldigTot = new Date(today);
        this.GeldigTot.setDate(today.getDate() + 8);
        this.TekstVan = '';
        this.TekstVoor = '';
        this.PrijsVan = 0;
        this.PrijsVoor = 0;
        this.Filialen = [];
        this.Images = [];
        this.AfbeeldingsUrl = '';
        this.Artikelen = [];
        this.Volgorde = 9999;

    }
}
