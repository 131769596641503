<ng-container *ngIf="!response?.length; else responseExists">
  <div class="alert alert-warning" role="alert">
    Geen antwoord ontvangen!
  </div>
</ng-container>

<ng-template #responseExists>

  <table class="table table-striped small">
    <tbody>
      <ng-container *ngFor="let item of response">
        <tr>
          <td>
            <div class="row">
              <div class="col-xl-4">
                <span class="text-body-tertiary pointer" (click)="searchAction.emit('^'+item.VrmArticleId)">Vrooam
                  Article ID:
                  {{item.VrmArticleId}}</span><br />
                <span class="fw-bold pointer"
                  (click)="searchAction.emit(item.FactoryCode)">{{item.FactoryCode}}</span><span
                  class="ms-1 text-body-tertiary">({{item.VrmGroupNumber}}
                  <b>{{item.VrmGroupBrand}}</b> {{item.VrmGroupDescription}})</span><br />
                <span *ngIf="item.Description">{{item.Description}}<br /></span>
                <span *ngIf="item.DescriptionGeneric">{{item.DescriptionGeneric}}<br /></span>
                <span *ngIf="item.Ean && +item.Ean > 0" class="text-body-tertiary pointer"
                  (click)="searchAction.emit('&'+item.Ean)">EAN: {{item.Ean}}<br /></span>
                <span *ngIf="item.TecdocBrandId && +item.Ean > 0" class="text-body-tertiary pointer"
                  (click)="searchAction.emit('&'+item.TecdocBrandId)">Tecdoc BrandId: {{item.TecdocBrandId}}<br /></span>
                <span *ngIf="item.TecdocGenartId && +item.TecdocGenartId > 0" class="text-body-tertiary pointer"
                  (click)="searchAction.emit('&'+item.TecdocGenartId)">Tecdoc GenartId: {{item.TecdocGenartId}}<br /></span>  
                <button type="button" class="btn btn-success btn-xs w-100 my-3"
                  (click)="mainService.showObjectDialog($event, item)"><i class="far fa-brackets-curly"></i>
                  json</button>

                <div *ngIf="item.SupplierDeposit" class="row pointer"
                  (click)="searchAction.emit('^'+item.SupplierDeposit)">
                  <div class="col-7 fw-bold">Loodtoeslag</div>
                  <div class="col-5 text-end font-italic">{{item.SupplierDeposit}}</div>
                </div>
                <div *ngIf="item.SupplierDisposalFee" class="row pointer"
                  (click)="searchAction.emit('^'+item.SupplierDisposalFee)">
                  <div class="col-7 fw-bold">Verwijderbijdrage</div>
                  <div class="col-5 text-end font-italic">{{item.SupplierDisposalFee}}</div>
                </div>
                <div *ngIf="item.SupplierSurcharge" class="row pointer"
                  (click)="searchAction.emit('^'+item.SupplierSurcharge)">
                  <div class="col-7 fw-bold">Statiegeld</div>
                  <div class="col-5 text-end font-italic">{{item.SupplierSurcharge}}</div>
                </div>

              </div>
              <div class="col-xl-4">
                <b>{{item.SupplierName}}</b><span class="ms-1 text-body-tertiary">({{item.SupplierCode}})</span><br />
                <span class="fw-bold pointer"
                  (click)="searchAction.emit('$'+item.SupplierArticleNumber)">{{item.SupplierArticleNumber}}</span><span
                  class="ms-1 text-body-tertiary">({{item.SupplierGroup}})</span><br />
                <div class="row">
                  <div class="col-6">Bruto</div>
                  <div class="col-6 text-end">{{item.SupplierGrossPrice | price}}</div>
                  <div class="col-8">Supplier Agreement</div>
                  <div class="col-4 text-end">
                    <span *ngIf="item.SupplierCentralAgreement">ja</span>
                    <span *ngIf="!item.SupplierCentralAgreement">nee</span>
                  </div>
                  <div class="col-6">Korting</div>
                  <div class="col-6 text-end">{{item.SupplierDiscount}}%</div>
                  <div class="col-6">Nettoprijs</div>
                  <div class="col-6 text-end">{{item.SupplierNetPrice | price}}</div>
                  <div class="col-6">Promo prijs</div>
                  <div class="col-6 text-end">{{item.SupplierPromotionalPrice | price}}</div>
                  <div class="col-6">Retail prijs</div>
                  <div class="col-6 text-end">{{item.SupplierRetailPrice | price}}</div>
                  <div class="col-6 mt-3">Popcode</div>
                  <div class="col-6 mt-3 text-end">{{item.SupplierMarketability}}</div>
                  <div class="col-6">Order unit</div>
                  <div class="col-6 text-end">{{item.SupplierOrderUnit | number}}</div>
                  <div class="col-6">Price code</div>
                  <div class="col-6 text-end">{{item.SupplierPriceCode | number}}</div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="row">
                  <div class="col-5">Datumtijd</div>
                  <div class="col-7 text-end">{{item.Datetime | date: 'dd-MM-yyyy HH:mm'}}</div>
                  <div class="col-5">Action</div>
                  <div class="col-7 text-end">{{item.Action}}</div>
                  <div class="col-5">Culture</div>
                  <div class="col-7 text-end">{{item.Culture}}</div>
                  <div class="col-5 mt-3">Created</div>
                  <div class="col-7 mt-3 text-end">{{item.Created | date: 'dd-MM-yyyy HH:mm'}}</div>
                  <div class="col-5">Updated</div>
                  <div class="col-7 text-end">{{item.Updated | date: 'dd-MM-yyyy HH:mm'}}</div>
                  <div class="col-6 mt-3">Promo price start</div>
                  <div class="col-6 mt-3 text-end">{{item.SupplierPromotionalPriceStartDate | date: 'dd-MM-yyyy HH:mm'}}
                  </div>
                  <div class="col-6">Promo price end</div>
                  <div class="col-6 text-end">{{item.SupplierPromotionalPriceEndDate | date: 'dd-MM-yyyy'}}
                  </div>
                  <div class="col-6">Retail price start</div>
                  <div class="col-6 text-end">{{item.SupplierRetailPriceStartDate | date: 'dd-MM-yyyy'}}</div>
                  <div class="col-6">Retail price end</div>
                  <div class="col-6 text-end">{{item.SupplierRetailPriceEndDate | date: 'dd-MM-yyyy'}}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

</ng-template>