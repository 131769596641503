<app-container [renderCheck]="session" [shopSoort]="shopSoort.ThirdPartyShopApi">

  <app-breadcrumb-header [debugObject]="session" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="session.shopSoort"></li>
    <span class="ms-auto">
      <app-voorraad-prijs-opties [ctx]="session.ctx" (netPriceOptionChanged)="netPriceOptionChanged()"
        [saveSettingsDirect]="thirdpartyShopService.changedOptionsNeedsRefresh(session.shopSoort)">
      </app-voorraad-prijs-opties>
    </span>
  </app-breadcrumb-header>

  <app-car-type-info-header *ngIf="session.ctx && !thirdpartyShopService.withoutCarType(session.shopSoort)" [ctx]="session.ctx"></app-car-type-info-header>

  <div class="border rounded mb-3" [ngClass]="{'parent position-relative': !isZoomed}">
    <button *ngIf="!isZoomed" type="button" class="btn btn-primary position-absolute zoom-button"
      (click)="isZoomed=!isZoomed">
      <i class="fas fa-expand"></i></button>
    <button *ngIf="isZoomed" type="button" class="btn btn-primary zoom-button top-right" (click)="isZoomed=!isZoomed">
      <i class="fas fa-compress"></i>
    </button>
    <div #thirdpartyContainer [ngClass]="{'frame-container': !isZoomed, 'frame-container-zoomed': isZoomed}"></div>
  </div>

</app-container>