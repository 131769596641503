<div class="container">
  <div (dblclick)="showTicket=!showTicket" class="cell">
    <span class="noselect label">één moment a.u.b... </span><br /><br />
    <div *ngIf="showTicket">
      <span>{{ticket}}</span><br />
      <span *ngIf="ticket.length!=36">lenght = {{ticket.length}} (must be 36)</span>
    </div>


  </div>
</div>
