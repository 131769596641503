import { Component, OnInit, Input } from '@angular/core';
import { LoyaltyShopItem } from '../../../_models/loyalty-shop/loyalty.shop.item';
import { LoyaltyShopService } from '../../../_services/loyalty-shop.service';
import { SettingsService } from '../../../_services/settings.service';
import { CartService } from '../../../_services/cart.service';

@Component({
  selector: 'app-loyalty-shop-item',
  templateUrl: './loyalty-shop-item.component.html',
  styleUrls: ['./loyalty-shop-item.component.scss']
})
export class LoyaltyShopItemComponent implements OnInit {
  @Input() item: LoyaltyShopItem;

  constructor(
    public settingsService: SettingsService,
    public loyaltyShopService: LoyaltyShopService,
    public cartService: CartService
  ) { }

  ngOnInit() {
  }

  notEnoughCredits(): boolean {
    return this.item.Credits > this.cartService.getLoyaltyPoints();
  }

}
