import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

import { ContextCarTypeSelection } from '../../../_models/car-types/context.car.type.selection';
import { CarTypeService } from '../../../_services/car-type.service';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { MainService } from '../../../_services/main.service';
import { CarTypeResult } from '../../../_models/car-types/car.type.result';



@Component({
  selector: 'app-history-search',
  templateUrl: './history-search.component.html',
  styleUrls: ['./history-search.component.scss']
})
export class HistorySearchComponent implements OnInit {
  @Input() ctxCarTypeSelection: ContextCarTypeSelection;
  @Input() focusHistory: EventEmitter<boolean>;
  @Output() carTypeSelected: EventEmitter<CarTypeResult> = new EventEmitter();
  filterString: string;

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
  }

  flipDropDown(dropdown: BsDropdownDirective): void {
    dropdown.isOpen = !dropdown.isOpen;
  }

  searchHistory(): void {
    return;
  }

  chooseCarType(carType: UniversalCarType): void {
    const result = new CarTypeResult();
    result.CarType = carType;
    this.carTypeSelected.emit(result);
  }

}
