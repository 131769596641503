import { Component, OnInit, Input } from '@angular/core';

import { ContextTyres } from '../../../../_models/tyres/context.tyres';
import { ShoppingCartItem } from '../../../../_models/cart/shopping.cart.item';
import { WheelTyreSet } from '../../../../_models/rims-and-tyres/wheel.tyre.set';
import { MainService } from '../../../../_services/main.service';
import { RimAndTyreService } from '../../../../_services/rim-and-tyre.service';
import { CartService } from '../../../../_services/cart.service';

@Component({
  selector: 'app-tyres-card-view-boxed',
  templateUrl: './tyres-card-view-boxed.component.html',
  styleUrls: ['./tyres-card-view-boxed.component.scss']
})
export class TyresCardViewBoxedComponent implements OnInit {
  @Input() ctx: ContextTyres;
  @Input() tyres: ShoppingCartItem[];
  @Input() set: WheelTyreSet;

  constructor(
    public mainService: MainService,
    public rimAndTyreService: RimAndTyreService,
    public cartService: CartService
  ) { }

  ngOnInit() {
  }

}
