<app-container [renderCheck]="ctx" [shopSoort]="shopSoort.Catalogus">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [routerLink]="['/catalog/groups',ctx.CarType.Id]">Groepenoverzicht</li>
    <li appBreadcrumbHeaderItem [active]="true">{{ctx.Category.CategoryName}}</li>
  </app-breadcrumb-header>

  <app-car-type-info-header [ctx]="ctx"></app-car-type-info-header>

  <app-side-panel-and-content>
    <ng-container side-panel>
      <app-side-panel-catalog [ctx]="ctx"></app-side-panel-catalog>
    </ng-container>
    <ng-container content>
      <app-catalog-part-list [ctx]="ctx" [maxItems]="settingsService.getSettingNumber('ShowCatalogPartsPerCount')">
      </app-catalog-part-list>
    </ng-container>
  </app-side-panel-and-content>

</app-container>
