export class InterfaceKindInfoModel {
  InterfaceKind: number;
  InterfaceKindEnum: string;
  Name: string;
  Description: string;
  Depricated: boolean;
  NeedsProxy: boolean;
  SortOrder: number;
  DefaultUrl: string;
  HintUrl: string;
  HintUsername: string;
  HintPassword: string;
  HintCustomerCode: string;
  HintSupplierCode: string;
  ParentInterfaceKind: number;
  UsageInfo: string;
  TestData: string;
  LinkedInterfaceKinds: InterfaceKindInfoModel[];
}
