import { Component, OnInit, Input } from '@angular/core';
import { Part } from '../../../../_models/catalog/part';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { ItemInfoService } from '../../../../_services/item-info.service';

@Component({
  selector: 'app-part-list-item-info',
  templateUrl: './part-list-item-info.component.html',
  styleUrls: ['./part-list-item-info.component.scss']
})
export class PartListItemInfoComponent implements OnInit {
  @Input() part: Part;
  @Input() partsProperties: { [key: string]: { Item1: string, Item2: string }[] };
  @Input() partsPropertyTranslations: { [key: string]: { [key: string]: string } };
  @Input() partsPropertyCollapsedItemCount: number;
  @Input() shopKind: ShopSoort;

  constructor(
    public itemInfoService: ItemInfoService
  ) { }

  ngOnInit() {
  }

}
