import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ShopPortalMessage } from '../../_models/common/shop.portal.message';
import { ShopPortalMessageButton } from '../../_models/common/shop.portal.message.button';
import { MainService } from '../../_services/main.service';


@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {
  message: ShopPortalMessage;
  mainService: MainService;
  warningsHtml: string;

  public onClose: Subject<string>;

  constructor(
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    this.onClose = new Subject();
    if (!this.message.Buttons) {
      this.message.Buttons = [new ShopPortalMessageButton('Ok', 'OK', 'btn-warning')];
    }
    if (this.message.Timing && this.message.Timing.Warnings) {
      this.warningsHtml = this.message.Timing.Warnings.join('<br />');
    }
  }

  public choose(command: string) {
    this.onClose.next(command);
    this.bsModalRef.hide();
  }

}
