import { Component, OnInit } from '@angular/core';
import { ContextAdmin } from '../../_models/admin/context.admin';
import { AdminService } from '../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-start',
  templateUrl: './admin-start.component.html',
  styleUrls: ['./admin-start.component.scss']
})
export class AdminStartComponent implements OnInit {
  ctx: ContextAdmin;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    this.adminService.getContext()
      .subscribe((ctx: ContextAdmin) => {
        this.ctx = ctx;
      });
  }

}
