import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpEvent } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UploadFile } from '../../_common/upload/upload-file.model';
import { MainService } from '../main.service';
import { ApiService } from '../api.service';
import { AdminService } from './admin.service';
import { Router } from '@angular/router';
import { LoyaltyShopData } from '../../_models/loyalty-shop/loyalty.shop.data';
import { LoyaltyShopItem } from '../../_models/loyalty-shop/loyalty.shop.item';
import { LoyaltyShopCategory } from '../../_models/loyalty-shop/loyalty.shop.category';
import { SortOrderUpdate } from '../../_models/admin/sort.order.update';
import { LoyaltyShopItemImage } from '../../_models/loyalty-shop/loyalty.shop.item.image';

@Injectable({
  providedIn: 'root'
})
export class AdminLoyaltyShopService {
  cachedData: LoyaltyShopData;
  cachedDataWholesaler: number;

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private adminService: AdminService,
    private router: Router
  ) { }

  clear() {
    this.cachedData = null;
  }

  updateLoyaltyShopBadges(loyaltyShopData: LoyaltyShopData) {
    if (loyaltyShopData) {
      if (loyaltyShopData.Categories) {
        this.adminService.setBadge('loyaltyShopCategoriesCount', Object.keys(loyaltyShopData.Categories).length);
      }
      if (loyaltyShopData.Items) {
        this.adminService.setBadge('loyaltyShopItemsCount', Object.keys(loyaltyShopData.Items).length);
      }
    }
  }

  getLoyaltyShopData(): Observable<LoyaltyShopData> {
    if (this.cachedData && this.cachedDataWholesaler === this.adminService.currentUid.Wholesaler) {
      return of(this.cachedData);
    } else {
      return this.apiService.adminGetLoyaltyShopData(this.adminService.currentUid)
        .pipe(mergeMap(data => {
          this.cachedData = data;
          this.cachedDataWholesaler = this.adminService.currentUid.Wholesaler;
          this.updateLoyaltyShopBadges(data);
          return of(data);
        }));
    }
  }

  getCategories(): Observable<LoyaltyShopCategory[]> {
    return this.getLoyaltyShopData()
      .pipe(mergeMap(data => {
        if (data && data.Categories) {
          const result: LoyaltyShopCategory[] = [];
          for (const key of Object.keys(data.Categories)) {
            result.push(data.Categories[key]);
          }
          return of(result);
        } else {
          return of([]);
        }
      }));
  }

  getCategoriesForItem(item: LoyaltyShopItem): Observable<LoyaltyShopCategory[]> {
    return this.getLoyaltyShopData()
      .pipe(mergeMap(data => {
        if (data && data.Categories && data.Cross) {
          const result = [];
          Object.keys(data.Cross).forEach(key => {
            if (data.Cross[key][item.ID] !== undefined) { result.push(data.Categories[key]); }
          });
          return of(result);
        } else {
          return of([]);
        }
      }));
  }

  updateLoyaltyShopCategoriesSortOrder(categories: LoyaltyShopCategory[]): Observable<number> {
    const update = this.adminService.getSortOrderUpdate(categories, 'ID', 'SortOrder');
    return this.apiService.adminUpdateLoyaltyShopCategoriesSortOrder(this.adminService.currentUid, update);
  }

  getLoyaltyShopCategoryById(id: number): Observable<LoyaltyShopCategory> {
    if (!id) { return of(new LoyaltyShopCategory()); }
    return this.getLoyaltyShopData()
      .pipe(mergeMap(data => {
        if (data && data.Categories && data.Categories[id]) {
          return of(data.Categories[id]);
        }
        return of(null);
      }));
  }

  saveLoyaltyShopCategory(category: LoyaltyShopCategory): Observable<LoyaltyShopCategory> {
    return this.apiService.adminSaveLoyaltyShopCategory(this.adminService.currentUid, category)
      .pipe(mergeMap(saved => {
        if (saved) {
          if (category.ID === saved.ID) {
            return of(category);
          }
          return this.getLoyaltyShopData()
            .pipe(mergeMap(data => {
              if (data && data.Categories) {
                data.Categories[saved.ID] = saved;
                this.updateLoyaltyShopBadges(data);
                return of(saved);
              }
              return of(null);
            }));
        }
        return of(null);
      }));
  }

  deleteLoyaltyShopCategory(categoryID: number): Observable<boolean> {
    return this.apiService.adminDeleteLoyaltyShopCategory(this.adminService.currentUid, categoryID)
      .pipe(mergeMap(ok => {
        if (ok) {
          return this.getLoyaltyShopData()
            .pipe(mergeMap(data => {
              if (data && data.Categories && data.Categories[categoryID]) {
                delete data.Categories[categoryID];
              }
              if (data && data.Cross && data.Cross[categoryID]) {
                delete data.Cross[categoryID];
              }
              this.updateLoyaltyShopBadges(data);
              return of(ok);
            }));
        }
        return of(ok);
      }));
  }

  deleteLoyaltyShopCategoryDialog(category: LoyaltyShopCategory, routeSucces = null): void {
    this.mainService.confirmBox(`Weet u zeker dat u de categorie '${category.Description}' wilt verwijderen?`)
      .subscribe(yes => {
        if (yes) {
          this.deleteLoyaltyShopCategory(category.ID)
            .subscribe(ok => {
              if (ok) {
                if (routeSucces) { this.router.navigate(routeSucces); }
              } else {
                this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!');
              }
            });
        }
      });
  }

  updateLoyaltyShopItemsSortOrder(sortOrder: { [key: number]: number }, forCategoryID: number): Observable<number> {
    const update = new SortOrderUpdate(sortOrder);
    return this.apiService.adminUpdateLoyaltyShopItemsSortOrder(this.adminService.currentUid, update, forCategoryID);
  }

  getLoyaltyShopItemById(id: number): Observable<LoyaltyShopItem> {
    if (!id) { return of(new LoyaltyShopItem()); }
    return this.getLoyaltyShopData()
      .pipe(mergeMap(data => {
        if (data && data.Items && data.Items[id]) {
          return of(data.Items[id]);
        }
        return of(null);
      }));
  }

  saveLoyaltyShopItem(item: LoyaltyShopItem): Observable<LoyaltyShopItem> {
    return this.apiService.adminSaveLoyaltyShopItem(this.adminService.currentUid, item)
      .pipe(mergeMap(saved => {
        if (saved) {
          if (item.ID === saved.ID) {
            return of(item);
          }
          return this.getLoyaltyShopData()
            .pipe(mergeMap(data => {
              if (data && data.Items) {
                data.Items[saved.ID] = saved;
                this.updateLoyaltyShopBadges(data);
                return of(saved);
              }
              return of(null);
            }));
        }
        return of(null);
      }));
  }

  saveLoyaltyShopItemDialog(item: LoyaltyShopItem, itemCategories: number[]): Observable<boolean> {
    if (item && item.Title) {
      const api = this.apiService;
      const uid = this.adminService.currentUid;
      return this.saveLoyaltyShopItem(item)
        .pipe(mergeMap(newItem => {
          if (newItem) {
            return api.adminSaveLoyaltyShopItemCategories(uid, newItem, itemCategories)
              .pipe(mergeMap(data => {
                return this.getLoyaltyShopData()
                  .pipe(mergeMap(loyaltyShop => {
                    loyaltyShop.Cross = data.Cross;
                    this.updateLoyaltyShopBadges(loyaltyShop);
                    this.router.navigate(['/admin/loyaltyshop/items']);
                    return of(true);
                  }));
                return of(true);
              }));
          } else {
            return this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het opslaan!')
              .pipe(mergeMap(ok => of(false)));
          }
        }));
    } else {
      return new Observable<boolean>((observer) => {
        this.mainService.msgBox('Let op!', 'U heeft geen titel ingevoerd!');
        observer.next(false);
        observer.complete();
      });
    }
  }

  deleteLoyaltyShopItem(itemID: number): Observable<number> {
    return this.apiService.adminDeleteLoyaltyShopItem(this.adminService.currentUid, itemID)
      .pipe(mergeMap(ok => {
        if (ok) {
          return this.getLoyaltyShopData()
            .pipe(mergeMap(data => {
              if (data && data.Items && data.Items[itemID]) {
                delete data.Items[itemID];
              }
              if (data && data.Cross) {
                Object.keys(data.Cross).forEach(key => {
                  if (data.Cross[key][itemID]) { delete data.Cross[key][itemID]; }
                });
              }
              this.updateLoyaltyShopBadges(data);
              return of(ok);
            }));
        }
        return of(ok);
      }));
  }

  deleteLoyaltyShopItemDialog(item: LoyaltyShopItem, routeSucces = null): void {
    this.mainService.confirmBox(`Weet u zeker dat u het artikel '${item.Title}' wilt verwijderen?`)
      .subscribe(yes => {
        if (yes) {
          this.deleteLoyaltyShopItem(item.ID)
            .subscribe(ok => {
              if (ok) {
                if (routeSucces) { this.router.navigate(routeSucces); }
              } else {
                this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!');
              }
            });
        }
      });
  }

  addLoyaltyShopItemImages(itemID: number, fileList: UploadFile[]): Observable<HttpEvent<LoyaltyShopItem>> {
    return this.apiService.adminAddLoyaltyShopItemImages(this.adminService.currentUid, itemID, fileList);
  }

  deleteLoyaltyShopItemImage(item: LoyaltyShopItem, image: LoyaltyShopItemImage) {
    const index = item.Images.indexOf(image);
    if (index > -1) {
      this.apiService.adminDeleteLoyaltyShopItemImage(this.adminService.currentUid, image.ID)
        .subscribe(ok => {
          if (ok) { item.Images.splice(index, 1); }
        });
    }
  }

}
