import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';
import { TimingObject } from '../../_models/common/timing.object';
import { TimingItem } from '../../_models/common/timing.item';
import { TimingClassItem } from '../../_models/common/timing.class.level';

@Component({
  selector: 'app-show-timings',
  templateUrl: './show-timings.component.html',
  styleUrls: ['./show-timings.component.scss']
})
export class ShowTimingsComponent implements OnInit {
  timing: TimingObject;
  mainService: MainService;
  collapsed = true;
  classLevels: TimingClassItem[];

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.classLevels = this.mainService.createTimingClassLevels(this.timing);
  }

  getFlatTimings(timingObject: TimingObject): TimingItem[] {
    var result: TimingItem[] = [];
    timingObject.Timings.forEach(item => {
      result.push(item);
      if (item.SubTiming?.Timings?.length) {
        result.push(...this.getFlatTimings(item.SubTiming));
      }
    });
    return result;
  }

  // getTimingHtml(timingObject: TimingObject, level: number, odd: boolean): string {
  //   let html = '';
  //   let indent = 0;
  //   if (level) { indent = level; }
  //   if (timingObject) {
  //     for (const timing of timingObject.Timings) {
  //       let cls = '';
  //       if (odd) { cls = 'table-detail-row-odd'; }
  //       html += '<div class=\'row ' + cls + '\'>';
  //       if (indent) {
  //         html += '  <div class=\'col-md-' + indent + '\'>&nbsp;</div>';
  //       }
  //       html += '  <div class=\'col-md-' + (10 - indent) + '\'>' + timing.Description + '</div>';
  //       html += '  <div class=\'col-md-1 text-end\'>' + timing._secondsFromStart.toFixed(3) + '</div>';
  //       let delta = '';
  //       if ((!this.collapsed || !timing.SubTiming) && timing.Seconds >= 0) { delta = timing.Seconds.toFixed(3); }
  //       const css = this.getClassLevel(timing.Seconds, this.collapsed);
  //       html += '  <div class=\'col-md-1 text-end ' + css + '\'>' + delta + '</div>';
  //       html += '</div>';
  //       if (!this.collapsed) {
  //         html += this.getTimingHtml(timing.SubTiming, indent + 1, !odd);
  //         if (timing.SubTiming && timing.SubTiming.Timings.length % 2 !== 0) { odd = !odd; }
  //       }
  //       odd = !odd;
  //     }
  //   }
  //   return html;
  // }

  // getHtml(timingObject: TimingObject): string {
  //   return this.getTimingHtml(timingObject, 0, false);
  // }

}
