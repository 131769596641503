<app-centered-spinner *ngIf="!stats" [faSize]="'fa-3x'" [color]="'text-low'"></app-centered-spinner>
<div *ngIf="stats" class="container-fluid">
  <div class="row">
    <div class="col-12 mt-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Gebruikers en Orderregels <span class="subtitle text-white-50">afgelopen 24 uur</span>
        </div>
        <div class="card-body">
          <div style="display: block;">
            <canvas baseChart width="400" height="100" [datasets]="stats.UsersAndOrdersData.datasets"
              [labels]="stats.UsersAndOrdersData.labels" [options]="{ responsive: true }" [legend]="true"
              [type]="lineChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Gebruikers
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.BranchUsersData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Orders
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.BranchOrdersData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 my-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Browsers
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.BrowserData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 my-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Platforms
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.PlatformData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 my-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Apparaat soort
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.DeviceData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 my-3">
      <div class="card">
        <div class="card-header text-bg-primary">
          Apparaat model
        </div>
        <div class="card-body">
          <div style="display: block">
            <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.ModelData)" [type]="doughnutChartType"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 my-3">
        <div class="card">
          <div class="card-header text-bg-primary">
            Koppelingen
          </div>
          <div class="card-body">
            <div style="display: block">
              <canvas baseChart width="400" height="400" [data]="getDoughnutChartData(stats.ThirdPartyData)" [type]="doughnutChartType"></canvas>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
