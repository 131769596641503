import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Part } from '../../../_models/catalog/part';
import { MainService } from '../../../_services/main.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { SettingsService } from '../../../_services/settings.service';


@Component({
  selector: 'app-catalog-sub-parts',
  templateUrl: './catalog-sub-parts.component.html',
  styleUrls: ['./catalog-sub-parts.component.scss']
})
export class CatalogSubPartsComponent implements OnInit {
  ctx: ContextCatalog;
  parentPart: Part;

  constructor(
    public mainService: MainService,
    private bsModalRef: BsModalRef,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.bsModalRef.hide();
  }

}
