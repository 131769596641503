import { Component, OnInit, HostListener } from '@angular/core';

import { Animations } from '../../../_animations/animations';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-scroll-to-top-button-roskamp',
  templateUrl: './scroll-to-top-button-roskamp.component.html',
  styleUrls: ['./scroll-to-top-button-roskamp.component.scss'],
  animations: [Animations.fadeScrollButton]
})
export class ScrollToTopButtonRoskampComponent implements OnInit {
  scrollPossible = window.pageYOffset !== 0;
  @HostListener("window:scroll", ["$event"])
  scrollHandler(event) {
    this.scrollPossible = window.pageYOffset !== 0;
  }

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() { }

  
}
