import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MainService } from './main.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    private http: HttpClient,
    private router: Router,
    private mainService: MainService
  ) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 5) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    const checkUrl = url + '?t=' + new Date().getTime();
    console.info(`Version check ${checkUrl}...`);
    this.http.get(checkUrl)
      .pipe(first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const newVersion = response.version;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);
          console.info(`Version changed? ${hashChanged}`);
          // If new version, do something
          if (hashChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            // for an example: location.reload();
            // this.mainService.msgBox('Let op!', 'Er is een nieuwe versie. Deze wordt nu ingeladen.')
            //   .subscribe(() => {
                console.info(`Old version.. ${environment.version} -> loading ${newVersion}...`);
                this.router.navigate(['/reload']);
            //   });

          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error('Could not get version!', err);
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
