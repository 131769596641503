import { Component, OnInit, Input } from '@angular/core';

import { ContextHomeScreen } from '../../../_models/common/context.home.screen';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { ContextLoyaltyShop } from '../../../_models/loyalty-shop/context.loyalty.shop';
import { ShopService } from '../../../_services/shop.service';
import { ContextMain } from '../../../_models/common/context.main';
import { MainService } from '../../../_services/main.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { ContextCarTypeSelection } from '../../../_models/car-types/context.car.type.selection';
import { TyreRequest } from '../../../_models/tyres/tyre.request';
import { TyreSize } from '../../../_models/tyres/tyre.size';
import { Router } from '@angular/router';
import { CarTypeResult } from '../../../_models/car-types/car.type.result';
import { Aanbieding } from '../../../_models/promotions/aanbieding';
import { PromotionService } from '../../../_services/promotion.service';

@Component({
  selector: 'app-home-star',
  templateUrl: './home-star.component.html',
  styleUrls: ['./home-star.component.scss']
})
export class HomeStarComponent implements OnInit {
  @Input() ctx: ContextHomeScreen;
  _ctxPromotions: ContextPromotions;
  get ctxPromotions(): ContextPromotions { return this._ctxPromotions; }
  @Input('ctxPromotions')
  set ctxPromotions(value: ContextPromotions) {
    this._ctxPromotions = value;
    this.promoSets = this.promotionService.getPromotionSets(value?.Promotions, 2);
  }
  @Input() ctxLoyaltyShop: ContextLoyaltyShop;
  ctxMain: ContextMain;
  ctxCarTypeSelection: ContextCarTypeSelection;
  licensePlate: string;
  vin: string;
  item: string;
  bandenmaat: string;
  shopSoort = ShopSoort;
  defaultCatalog = ShopSoort.Catalogus;
  promoSets: Aanbieding[][];

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    private promotionService: PromotionService,
    private carTypeService: CarTypeService,
    public router: Router
  ) { }

  ngOnInit() {
    if (this.mainService.ctxMain.DefaultCatalog) { this.defaultCatalog = this.mainService.ctxMain.DefaultCatalog; }
    this.setContextCarType(this.defaultCatalog);
    this.setCarTypeInfo(this.ctx.CarType);
    this.mainService.getContextMain('')
      .subscribe(ctxMain => {
        this.ctxMain = ctxMain;
      });
  }

  setContextCarType(shopKind: ShopSoort) {
    this.carTypeService.getContextCarTypeSelection(shopKind)
      .subscribe(ctx => {
        this.ctxCarTypeSelection = ctx;
      });
  }

  carTypeSelected(carTypeResult: CarTypeResult): void {
    this.carTypeService.saveCarType(ShopSoort.Catalogus, carTypeResult.CarType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        if (result.CarType.LicensePlate) { this.licensePlate = result.CarType.LicensePlate.Formated; }
        this.router.navigate(['/catalog/groups', result.CarType.Id]);
      });
  }

  setCarTypeInfo(carType: UniversalCarType): void {
    if (carType) {
      if (carType.LicensePlate) {
        this.licensePlate = carType.LicensePlate.Formated;
      } else {
        this.licensePlate = null;
      }
    }
  }

  searchTyre(size: string): void {
    if (size) {
      const request = new TyreRequest([TyreSize.getFromString(size)], 2, true, 0, ShopSoort.Banden);
      this.shopService.doSearchTyre(this.ctxMain, request);
    }
  }

}
