import { Component, OnInit, Input } from '@angular/core';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-catalog-parts-filter-row',
  templateUrl: './catalog-parts-filter-row.component.html',
  styleUrls: ['./catalog-parts-filter-row.component.scss']
})
export class CatalogPartsFilterRowComponent implements OnInit {
@Input() ctx: ContextCatalog;

  constructor(
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
  }

}
