import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingCartItem } from '../../_models/cart/shopping.cart.item';
import { MpmItem } from '../../_models/mpm/mpm.item';

@Pipe({
  name: 'mpmHasCartItem'
})
export class MpmHasCartItemPipe implements PipeTransform {

  transform(items: MpmItem[], cartItems: { [key: string]: ShoppingCartItem }): MpmItem[] {
    if (items && cartItems) {
      return items.filter(item => {
        return cartItems[item.ArticleNumber];
      });
    }
    return [];
  }

}
