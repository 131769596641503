import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SupplierInterfaceData } from '../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../_services/admin/admin-supplier-interface.service';
import { InterfaceData } from '../../../_models/admin/supplier-interface/interface.data';
import { InterfaceKindInfoModel } from '../../../_models/admin/supplier-interface/interface.kind.info.model';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-supplier-interfaces',
  templateUrl: './admin-supplier-interfaces.component.html',
  styleUrls: ['./admin-supplier-interfaces.component.scss']
})
export class AdminSupplierInterfacesComponent implements OnInit, OnDestroy {
  supplierInterfaceData: SupplierInterfaceData;
  filterUnknown = true;
  filterString = '';
  groupedInterfaceDatas: { intType: number, interfaceDatas: { [key: number]: InterfaceData[] } }[];
  interfaceKinds: InterfaceKindInfoModel[];
  countTotal = 0;
  countFiltered = 0;
  interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();
  debug = {};


  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    public adminService: AdminService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: any) => {
        this.interfaceDataChanged.subscribe(newSupplierInterfaceData => {
          this.filter(newSupplierInterfaceData);
        });
        this.filter(data.supplierInterfaceData);
      });
  }

  ngOnDestroy(): void {
    this.interfaceDataChanged.unsubscribe();
  }

  addInterfaceData(intType: number) {
    if (intType === 1000) { this.addProxyInterfaceData(); }
    else {
      const info = this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, intType);
      const interfaceData = new InterfaceData();
      interfaceData.Wholesaler = this.adminService.currentUid.Wholesaler;
      interfaceData.Branch = 0;
      interfaceData.IntType = intType;
      interfaceData.Supplier = 0;
      interfaceData.Url = info.DefaultUrl;
      interfaceData.Username = '';
      interfaceData.Password = '';
      interfaceData.CustomerCode = '';
      interfaceData.SupplierCode = '';
      this.adminSupplierInterfaceService.editInterfaceData(this.supplierInterfaceData, interfaceData, this.interfaceDataChanged, true);
    }
  }

  addProxyInterfaceData() {
    const info = this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, 1000);
    const interfaceData = new InterfaceData();
    interfaceData.Wholesaler = this.adminService.currentUid.Wholesaler;
    interfaceData.Branch = 0;
    interfaceData.IntType = 1000;
    interfaceData.Supplier = 0;
    interfaceData.Url = info.DefaultUrl;
    interfaceData.Username = this.adminService.currentUid.Wholesaler.toString();
    interfaceData.Password = Math.random().toString(36).slice(-8);
    interfaceData.CustomerCode = '';
    interfaceData.SupplierCode = '';

    this.adminSupplierInterfaceService.saveInterfaceData(this.supplierInterfaceData, null, interfaceData, this.interfaceDataChanged, true);
  }

  filter(supplierInterfaceData: SupplierInterfaceData) {
    this.supplierInterfaceData = supplierInterfaceData;
    const filtered = this.adminSupplierInterfaceService.filterInterfaceDatas(supplierInterfaceData.InterfaceData, this.filterString, supplierInterfaceData, this.filterUnknown);
    this.countTotal = supplierInterfaceData.InterfaceData.length;
    this.countFiltered = filtered.length;
    this.groupedInterfaceDatas = this.adminSupplierInterfaceService.getInterfaceDataGrouped(supplierInterfaceData, filtered);
    this.interfaceKinds = this.adminSupplierInterfaceService
      .getSortedInterfaceKindInfos(supplierInterfaceData.InterfaceKindInfos)
      .filter(interfaceKind => {
        let ok = true;
        if (interfaceKind.Depricated) { ok = false; }
        if (supplierInterfaceData.InterfaceData.some(i => i.IntType === interfaceKind.InterfaceKind)) { ok = false; }
        if (interfaceKind.InterfaceKind === 0) { ok = true; }
        return ok;
      });
  }

  clearFilter() {
    this.filterString = '';
    this.filter(this.supplierInterfaceData);
  }

  getKind(intType: number): InterfaceKindInfoModel {
    return this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, intType);
  }

  hasKind(intType: number): boolean {
    return this.adminSupplierInterfaceService.hasInterfaceDataType(this.supplierInterfaceData.InterfaceData, intType);
  }

  switchFilterUnknown() {
    this.filterUnknown = !this.filterUnknown;
    this.filter(this.supplierInterfaceData);
  }

  enableDebug(intType: number) {
    this.debug[intType] = !this.debug[intType]
  }

}
