import { Component, OnInit, Input } from '@angular/core';

import { Animations } from '../../../_animations/animations';
import { TimingItem } from '../../../_models/common/timing.item';
import { TimingClassItem } from '../../../_models/common/timing.class.level';
import { MainService } from '../../../_services/main.service';
import { TimingObject } from '../../../_models/common/timing.object';


@Component({
  selector: 'app-timing-item-display',
  templateUrl: './timing-item-display.component.html',
  styleUrls: ['./timing-item-display.component.scss'],
  animations: [Animations.slideInOut]
})
export class TimingItemDisplayComponent implements OnInit {
  @Input() timingItem: TimingItem;
  @Input() startTimeStamp: number;
  @Input() flatTimings: TimingItem[];
  @Input() depth: number;
  @Input() alt: boolean;
  @Input() classLevels: TimingClassItem[];
  collapsed: boolean = true;
  private _collapseState: boolean;
  get collapseState(): boolean {
    return this._collapseState;
  }
  @Input()
  set collapseState(collapseState: boolean) {
    this._collapseState = collapseState;
    if (collapseState === true || collapseState === false) { this.collapsed = collapseState; }
  }

  constructor(
    public mainService: MainService
  ) { }

  clickCollapse(event: MouseEvent): boolean {
    this.collapsed = !this.collapsed;
    return false;
  }

  ngOnInit() {
  }

  isBackgroundTask(timingItem: TimingItem): boolean {
    const index = this.flatTimings.indexOf(timingItem);
    if (index >= 0 && this.flatTimings.length > index + 1) {
      const diff = this.flatTimings[index + 1].TimeStamp - (timingItem.TimeStamp + timingItem.Duration)
      return diff < 5000000;
    }
    return false;
  }

  hasDebugObjectInTree(timingItem: TimingItem): boolean {
    if (timingItem?.DebugObject) return true;
    let anySubTimingHasDebugObjectInTree = false;
    if (timingItem?.SubTiming?.Timings?.length > 0) {
      timingItem.SubTiming.Timings.forEach(item => {
        if (this.hasDebugObjectInTree(item)) { anySubTimingHasDebugObjectInTree = true; }
      });
    }
    return anySubTimingHasDebugObjectInTree;
  }

  hasWarningInTree(timingItem: TimingItem): boolean {
    if (timingItem?.Warning) return true;
    let anySubTimingHasWarningInTree = false;
    if (timingItem?.SubTiming?.Timings?.length > 0) {
      timingItem.SubTiming.Timings.forEach(item => {
        if (this.hasWarningInTree(item)) { anySubTimingHasWarningInTree = true; }
      });
    }
    return anySubTimingHasWarningInTree;
  }

  // getBackgroundTaskDepth(timingItem: TimingItem, parent: TimingObject, depth: number = 0): number {
  //   const index = parent.Timings.indexOf(timingItem);
  //   let startNext = 0;
  //   if (index >= 0 && parent.Timings.length > index + 1) {
  //     startNext = parent.Timings[index + 1].TimeStamp;
  //   } else {
  //     startNext = 
  //   }
  // }

}
