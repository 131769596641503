import { RouteInfo } from '../item-info/route.info';
import { DeliveryChoice } from './delivery.choice';
import { DeliveryModeKind } from './delivery.mode.kind';
import { DeliveryMomentKind } from './delivery.moment.kind';

export class  ShopPortalOrderRequest {
    Reference: string;
    Remark: string;
    DeliveryMode: DeliveryModeKind;
    Route: RouteInfo;
    DeliveryMoment: DeliveryMomentKind;
    DeliveryChoice: DeliveryChoice;
    Urgency: boolean;
    TimeStamp: Date;
}