import { Component, OnInit, Input } from '@angular/core';

import { ContextRimsAndTyres } from '../../../../../_models/rims-and-tyres/context.rims.and.tyres';
import { MainService } from '../../../../../_services/main.service';
import { Animations } from '../../../../../_animations/animations';
import { RimAndTyreService } from '../../../../../_services/rim-and-tyre.service';
import { CatalogService } from '../../../../../_services/catalog.service';

@Component({
  selector: 'app-rims-card-view',
  templateUrl: './rims-card-view.component.html',
  styleUrls: ['./rims-card-view.component.scss'],
  animations: [Animations.enterLeaveBounce]
})
export class RimsCardViewComponent implements OnInit {
  @Input() ctx: ContextRimsAndTyres;
  @Input() fetching: boolean;
  filterResult = { count: 0 };

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public rimsAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
  }

}
