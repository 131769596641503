import { Injectable } from '@angular/core';
import { Observable, Subject, of, Subscription } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import { ShopSoort } from '../_models/common/shop.soort';
import { ContextMain } from '../_models/common/context.main';
import { ItemInfoRequest } from '../_models/item-info/item.info.request';
import { ServicesParameter } from '../_models/common/services.parameter';

@Injectable()
export class MessageService {
  messageSubject = new Subject<any>();
  messages$ = this.messageSubject.asObservable();
  listeners: { [key: string]: string } = {};
  registeredOK = false;
  registeredSource;
  registeredOrigin = '';
  debugMessages = false;

  constructor() { }

  isRegistered(id: string, host: string): boolean {
    if (this.listeners && this.listeners[id] && this.listeners[id] !== '') {
      return true;
    }
    return false;
  }

  messageListener(id: string, host: string): Observable<any> {
    if (this.messages$ && !this.isRegistered(id, host)) {
      this.listeners[id] = host;
      return this.messages$.pipe(mergeMap(message => {
        if (message && message.origin.endsWith(host)) {
          return of(message.data);
        }
        console.info(`messageListener ${id} -> ${message.origin} !== ${host}`);
        return of(null);
      }));
    }
    return of(null);
  }

  unsubscribeMessageListener(id: string, host: string, subscription: Subscription) {
    if (this.isRegistered(id, host)) { delete this.listeners[id];}
    if (subscription) { subscription.unsubscribe(); }
  }

  sendToMessageListeners(event: MessageEvent) {
    if (event && event.data && event.origin) {
      let anyListener = false;
      for (const listener of Object.keys(this.listeners)) {
        if (event.origin.endsWith(this.listeners[listener])) {
          anyListener = true;
        }
      }
      if (anyListener && this.messageSubject) { this.messageSubject.next({ origin: event.origin, data: event.data }); }
    }
  }

  handleExternalMessage(event: MessageEvent, services: ServicesParameter) {
    this.sendToMessageListeners(event);
    if (!services || !services.mainService) { return; }
    services.mainService.getContextMain('',true)
      .subscribe((ctx) => {
        if (ctx && ctx.MessageOrigins && ctx.MessageOrigins.includes(event.origin) && (typeof event.data) === 'string') {
          let command = event.data;
          let param = '';
          if (command.indexOf(':') !== -1) {
            param = command.substring(command.indexOf(':') + 1);
            command = command.substring(0, command.indexOf(':'));
          }
          switch (command.toUpperCase()) {
            case 'GET_ACTIVESHOP':
              this.registeredSource.postMessage(services.shopService.currentActiveShop, event.origin);
              break;
            case 'REGISTER':
              if (param === ctx.ThirdPartySessionTicket) {
                this.registeredSource = event.source;
                this.registeredOrigin = event.origin;
                this.registeredOK = true;
                this.registeredSource.postMessage('REGISTERED:true', this.registeredOrigin);
                if (services.carTypeService.currentCarType &&
                  services.carTypeService.currentCarType.LicensePlate &&
                  services.carTypeService.currentCarType.LicensePlate.Bare) {
                  this.registeredSource.postMessage(
                    'SET_KENTEKEN:' + services.carTypeService.currentCarType.LicensePlate.Bare, this.registeredOrigin
                  );
                }
                const aantalWinkelwagen = services.cartService.getCartItemCount();
                if (aantalWinkelwagen > 0) {
                  this.registeredSource.postMessage('CART_ITEM_COUNT:' + aantalWinkelwagen, this.registeredOrigin);
                }
                this.registeredSource.postMessage('SET_ACTIVESHOP:' + services.shopService.currentActiveShop, this.registeredOrigin);
              }
              break;
            default:
              this.handleMessage(command, param, ctx, services);
              break;
          }
        }
      });
  }

  private handleMessage(command: string, param: string, ctx: ContextMain, services: ServicesParameter) {
    console.info(`handleMessage -> command: ${command} param: ${param}`);
    let shop = null;
    switch (command) {
      case 'LOGOUT':
        services.authService.gotoLogout();
        break;
      case 'SET_KENTEKEN':
        services.carTypeService.setKenteken(param);
        break;
      case 'RESET_AUTOTYPE':
        services.carTypeService.resetCarType(Number(param) as ShopSoort);
        // shop = shopService.getShopModuleByShopSoort(ctx, Number(param) as ShopSoort);
        // if (shop) { shopService.startShopModule(shop); }
        break;
      case 'RESET_PORTAL':
        services.shopService.reloadShops();
        break;
      case 'RESET_SHOP':
        shop = services.shopService.getShopModuleByShopSoort(ctx, Number(param) as ShopSoort);
        if (shop) {
          // shop.isLoaded = false;
          // shop.needsRefresh = true;
          services.shopService.startShopModule(ctx, shop);
        }
        break;
      case 'GOTO_SHOP':
        shop = services.shopService.getShopModuleByShopSoort(ctx, Number(param) as ShopSoort);
        if (shop) { services.shopService.startShopModule(ctx, shop); }
        break;
      case 'GOTO_STARTSSHOP':
        shop = services.shopService.getShopModuleByShopSoort(ctx, services.shopService.startUpShop);
        if (shop) { services.shopService.startShopModule(ctx, shop); }
        break;
      case 'GOTO_PREVIOUSSHOP':
        services.shopService.gotoPreviousShopModule(ctx);
        break;
      case 'GOTO_CART':
        services.router.navigate(['/cart']);
        break;
      case 'UPDATE_CART':
        services.cartService.updateCart(true);
        break;
      case 'ARTIKELINFO':
        const data = JSON.parse(param);
        const request = new ItemInfoRequest(
          data.InternArtikelnr, data.Artikelnr, data.Artikelgroep, false, data.Aantal, services.shopService.currentActiveShop, true
        );
        let item = data.Artikelnr;
        const queryParams = { 'shopKind': services.shopService.currentActiveShop };
        if (data.InternArtikelnr) {
          item = data.InternArtikelnr;
          queryParams['internalItemNumber'] = true;
        } else if (data.Artikelgroep) {
          queryParams['itemGroup'] = data.Artikelgroep;
        }
        if (data.Aantal !== 1) { queryParams['requestItemCount'] = data.Aantal; }
        services.router.navigate(['/iteminfo', item], { queryParams: queryParams });
        break;
      case 'SHOW_AANBIEDING':
        alert(`show aanbieding: ${param}`);
        break;
      case 'MESSAGE':
        services.mainService.msgBox(null, param);
        break;
      // case 'TIMINGS':
      //   services.apiService.getCloudMessage(param)
      //     .subscribe(message => {
      //       services.mainService.showTiming(null, message);
      //     });
      //   break;

    }
  }

}


