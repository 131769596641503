import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-save-button',
  templateUrl: './admin-save-button.component.html',
  styleUrls: ['./admin-save-button.component.scss']
})
export class AdminSaveButtonComponent implements OnInit {
  @Input() saveAction: Observable<any>;
  @Input() block: boolean;
  @Input() disabled: boolean = false;
  @Output() beforeSaveAction: EventEmitter<MouseEvent> = new EventEmitter();
  busySaving = false;

  constructor() { }

  ngOnInit(): void {
  }

  save(event: MouseEvent) {
    this.busySaving = true;
    this.beforeSaveAction.emit(event);
    console.info('save nu!');
    this.saveAction.subscribe(() => this.busySaving = false);
  }

}
