import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Settings } from '../_models/common/settings';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  _settingsIntern: { [key: string]: any } = {};
  _settingsPublic: { [key: string]: any } = null;
  _settingsMain: { [key: string]: any } = null;

  constructor(
    private apiService: ApiService
  ) {
  }

  clear(): void {
    this._settingsIntern = {};
    this._settingsPublic = null;
    this._settingsMain = null;
  }

  initPublic(settings: { [key: string]: any }) {
    this._settingsPublic = settings;
  }

  initMain(settings: { [key: string]: any }) {
    this._settingsMain = settings;
  }

  setSetting(setting: string, value: any) {
    if (this._settingsMain && this._settingsMain[setting]) {
      this._settingsMain[setting] = value;
    } else if (this._settingsPublic && this._settingsPublic[setting]) {
      this._settingsPublic[setting] = value;
    } else {
      this._settingsIntern[setting] = value;
    }
    this.apiService.saveSettings(new Settings(setting, value.toString()));
  }

  getSetting(setting: string): any {
    if (this._settingsMain && this._settingsMain[setting]) {
      return this._settingsMain[setting];
    }
    if (this._settingsPublic && this._settingsPublic[setting]) {
      return this._settingsPublic[setting];
    }
    if (this._settingsIntern && this._settingsIntern[setting]) {
      return this._settingsIntern[setting];
    }
    return null;
  }

  getSettingBoolean(setting: string): boolean {
    const value = this.getSetting(setting);
    if (value) { return value; }
    return false;
  }

  getSettingNumber(setting: string): number {
    const value = this.getSetting(setting);
    if (value) { return value; }
    return 0;
  }
}
