<input [appFocus]="focusHistory" class="history-search-input rounded mb-1" type="text" placeholder="zoek..."
  [(ngModel)]="filterString" (click)="filterString = ''" (keyup.enter)="searchHistory()" />
<div class="history-list border card"
  [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 310) + 'px', 'overflow-y': 'auto'}">
  <ng-container *ngIf="(ctxCarTypeSelection?.Historie | universalCarTypeFilter: filterString) as resultingCars">
    <table class="table table-striped table-hover table-sm pointer">
      <tbody>
        <tr *ngFor="let carType of resultingCars" (click)="chooseCarType(carType)">
          <td>
            <img class="image mx-auto mb-1 d-block" *ngIf="carTypeService.getCarTypeImage(carType)"
              [src]="carTypeService.getCarTypeImage(carType)" />
            <img class="image mx-auto d-block" *ngIf="carType?.LicensePlate?.ImageUrl"
              [src]="carType?.LicensePlate?.ImageUrl" />
          </td>
          <td>
            <b>{{carTypeService.getCarTypeDescription(carType)}}</b><br />
            <i>{{carType.AvailableFrom.Display}} t/m {{carType.AvailableUntil.Display || 'nu'}}</i><br />
            <span class="text-low">{{carType.TypeRemark}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="!resultingCars.length" class="p-3 fs-6 text-danger"><i>Er bleven geen autotypes over die
        '{{filterString}}' bevatten in de historie.</i></p>
  </ng-container>
</div>