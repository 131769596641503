import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';
import { UniversalCarBrand } from '../../_models/car-types/universal.car.brand';
import { CarTypeService } from '../../_services/car-type.service';

@Component({
  selector: 'app-car-brand-list',
  templateUrl: './car-brand-list.component.html',
  styleUrls: ['./car-brand-list.component.scss']
})
export class CarBrandListComponent implements OnInit {
brands: UniversalCarBrand[];
filterString: string;
carTypeService: CarTypeService;
onClose: Subject<UniversalCarBrand>;

  constructor(
    private bsModalRef: BsModalRef,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.onClose = new Subject<UniversalCarBrand>();
  }

  public choose(item: UniversalCarBrand) {
    if (item) {this.onClose.next(item); }
    this.bsModalRef.hide();
  }

}
