export class FavoriteItem {
  Wholesaler: number;
  Customer: number;
  InternalItemNumber: number;
  CreatedAt: Date;

  constructor(internalItemNumber: number) {
    this.Wholesaler = 0;
    this.Customer = 0;
    this.InternalItemNumber = internalItemNumber;
    this.CreatedAt = new Date;
  }
}