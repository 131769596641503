import { Pipe, PipeTransform } from '@angular/core';
import { CarType } from '../../_models/car-types/car.type';

@Pipe({
  name: 'carTypeFilter'
})
export class CarTypeFilterPipe implements PipeTransform {

  containsFilter(texts: string[], filter: string) {
    for (const text of texts) {
      if (filter && text && text.toLowerCase().indexOf(filter) >= 0) { return true; }
    }
    return false;
  }

  transform(types: CarType[], filterString: string, withBrand: boolean = false): CarType[] {
    if (!filterString) { return types; }
    return types.filter(type => {
      let ok = false;
      if (type) {
        const texts = [type.TypeName, type.TypeRemark, type.Model.ModelName];
        if (withBrand && type.Model && type.Model.Brand) { texts.push(type.Model.Brand.BrandName); }
        if (type.Properties) {
          Object.keys(type.Properties).forEach(prop => {
            if (type.Properties[prop][0] && type.Properties[prop][0]['ValueString']) {
              texts.push(type.Properties[prop][0]['ValueString']);
            }
          });
        }
        if (this.containsFilter(texts, filterString)) { ok = true; }
      }
      return ok;
    });

  }

}
