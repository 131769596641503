<app-admin-form [title]="'Instellingen h-base'" [object]="settings">
  <app-admin-checkbox [label]="'Aktief'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-checkbox>
  <app-admin-number-input [label]="'Debiteurnummer'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_DebiteurNr').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-number-input>
  <app-admin-dropdown [label]="'Soort API'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_ApiKind').Value" [modelValues]="[0,2]" [modelDisplayValues]="{'0':'WsHbase','2':'AutomotiveCloud'}" [disabled]="!adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-dropdown>
  <app-admin-input *ngIf="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_ApiKind').Value===1" [label]="'Dealer ID'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_DealerID').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-input>
  <app-admin-input *ngIf="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_ApiKind').Value===1" [label]="'Dealer Password'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_DealerPassword').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-input>    
  <app-admin-input *ngIf="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_ApiKind').Value===2" [label]="'Subscription Key'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_MijnGrossierAzureSubscriptionKey').Value" [disabled]="!adminSettingsService.getAdminSetting(settings,'HbaseCatalog_Enabled').Value"></app-admin-input>    
  <app-admin-checkbox [label]="'Artikelgroep leverancier moet overeenkomen'" [(model)]="adminSettingsService.getAdminSetting(settings,'HbaseCatalog_ItemGroupMustMatch').Value"></app-admin-checkbox>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>