<div class="card me-3 mb-3 menu float-start">
  <ul cdkDropList [cdkDropListData]="menu" class="list-group list-group-flush" (cdkDropListDropped)="drop($event)">
    <li *ngFor="let menuItem of menu" type="button" class="list-group-item list-group-item-action menu-item"
      [ngClass]="{'active': menuItem.Id===activeMenuItem?.Id, 'not-active': !isEnabled(menuItem)}"
      (click)="menuItemClick(menuItem)" cdkDrag>
      <div class="placeholder" *cdkDragPlaceholder></div>
      <table class="menu-item-width">
        <tr>
          <td>
            <div class="menu-item-width text-truncate"><span cdkDragHandle><i
                  class="fas fa-grip-vertical move"></i></span> {{getDescription(menuItem)}}</div>
          </td>
          <td>
            <div class="btn-group btn-group-xxs me-2" role="group">
              <button type="button" class="btn" [ngClass]="{'btn-outline-secondary': menuItem!==activeMenuItem, 'btn-outline-light': menuItem===activeMenuItem}" (click)="menuItemEdit($event, menuItem)"><i
                  class="far fa-edit"></i></button>
              <button type="button" class="btn" [ngClass]="{'btn-outline-secondary': menuItem!==activeMenuItem, 'btn-outline-light': menuItem===activeMenuItem}" (click)="menuItemDelete($event, menuItem)"><i
                  class="fas fa-trash-alt"></i></button>
            </div>
          </td>
          <td *ngIf="menuItem.MenuItems?.length" class="text-end"><i class="fas fa-caret-right"></i></td>
        </tr>
      </table>
    </li>
    <li type="button" class="list-group-item list-group-item-action menu-item text-bg-dark" (click)="newMenuItem()">
      <table class="menu-item-width">
        <tr>
          <td>
            <i class="fas fa-plus"></i> Menuitem toevoegen
          </td>
        </tr>
      </table>
    </li>
  </ul>
</div>
<ng-container *ngIf="activeMenuItem">
  <app-admin-shop-module-menu [menu]="activeMenuItem?.MenuItems" [settings]="settings" (changed)="changed.emit(menu)"></app-admin-shop-module-menu>
</ng-container>