export class ContextMainRequest {
  FrontendUrl: string;
  FrontendVersion: string;
  Ticket: string;

  constructor(frontendUrl: string, frontendVersion: string, ticket: string) {
      this.FrontendUrl = frontendUrl;
      this.FrontendVersion = frontendVersion;
      this.Ticket = ticket;
  }
}
