import { Component, Input } from '@angular/core';
import { ItemInfoWarnings } from 'app/_models/item-info/item.info.warnings';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-item-info-warnings',
  templateUrl: './item-info-warnings.component.html',
  styleUrl: './item-info-warnings.component.scss'
})
export class ItemInfoWarningsComponent {
  @Input() warnings: ItemInfoWarnings;
  itemInfoWarnings = ItemInfoWarnings;

  constructor(
    public mainService: MainService
  ) { }


}
