import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { ItemImagesItemGroupData } from '../../_models/admin/item-images/item.images.item.group.data';
import { AdminItemImagesService } from '../../_services/admin/admin-item-images.service';

@Injectable({
  providedIn: 'root'
})
export class AdminItemImagesItemGroupResolverService  {

  constructor(
    private router: Router,
    private adminItemImagesService: AdminItemImagesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ItemImagesItemGroupData> | Observable<never> {
    const pageNumber = route.queryParams['pageNumber'] ?? 1;
    const pageSize = route.queryParams['pageSize'] ?? 5;
    const filter = route.queryParams['filter'] ?? '';

    return this.adminItemImagesService.getItemImagesItemGroupData(filter, +pageNumber, +pageSize).pipe(
      take(1),
      mergeMap(itemImagesData => {
        if (itemImagesData) {
          return of(itemImagesData);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
