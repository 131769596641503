import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { MainService } from '../../../../_services/main.service';
import { ResizeInfo } from '../../../../_models/common/resize.info';
import { CatalogService } from '../../../../_services/catalog.service';
import { Part } from '../../../../_models/catalog/part';
import { MaintenancePartsSelection } from '../../../../_models/maintenance/maintenance.parts.selection';
import { ItemInfoService } from '../../../../_services/item-info.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { CartService } from '../../../../_services/cart.service';

@Component({
  selector: 'app-maintenance-parts-boxed-layout',
  templateUrl: './maintenance-parts-boxed-layout.component.html',
  styleUrls: ['./maintenance-parts-boxed-layout.component.scss']
})
export class MaintenancePartsBoxedLayoutComponent implements OnInit, OnDestroy {
  @Input() ctx: ContextCatalog;
  @Input() selection: MaintenancePartsSelection;
  object = Object;
  shopSoort = ShopSoort;
  resizeInfosSubscription: Subscription;
  private containerHeight = '0';
  private itemContainer: ElementRef;
  @ViewChild('itemContainer') set content(content: ElementRef) {
    this.itemContainer = content;
    this.resizeInfosSubscription = this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
  }

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public itemInfoService: ItemInfoService,
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.mainService.fireReziseEvent();
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'max-height': this.containerHeight,
        'overflow': 'hidden',
        'overflow-y': 'auto'
      };
    }
    return null;
  }

  handleResizeInfo(info: ResizeInfo) {
    if (this.itemContainer) {
      this.containerHeight = (info.clientHeight - info.headerHeight - this.itemContainer.nativeElement.offsetTop + 105) + 'px';
    }
  }

  nothingSelected(): boolean {
    if (this.selection) {
      let selected = false;
      Object.keys(this.selection.PartsTypes).forEach(partsType => {
        const partsTypeId = +partsType;
        if (this.selection.SelectedCartItemPerPartsType[partsTypeId]) { selected = true; }
      });
      return !selected;
    }
    return true;
  }

  orderSelectedItems() {
    if (this.selection) {
      Object.keys(this.selection.PartsTypes).forEach(partsType => {
        const partsTypeId = +partsType;
        if (this.selection.SelectedCartItemPerPartsType[partsTypeId]) {
          this.selection.SelectedCartItemPerPartsType[partsTypeId].SyncID = partsType;
          this.cartService.setShoppingCartItemCount(this.selection.SelectedCartItemPerPartsType[partsTypeId]
            , this.selection.PartsTypes[partsTypeId].toString());
        }
      });
      this.mainService.msgBox('Bedankt!', 'De artikelen zijn toegevoegd aan de winkelwagen.').subscribe(ok => {

      });
    }
  }

}
