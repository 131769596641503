<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div *ngIf="returnItem" class="container">

    <app-breadcrumb-header [debugObject]="returnItem" [autoStart]="true">
      <li appBreadcrumbHeaderItem [routerLink]="['/details/returns']">Retouren</li>
      <li appBreadcrumbHeaderItem [active]="true">Nieuw</li>
    </app-breadcrumb-header>

    <div class="row pb-3">
      <div class="col-12">
        <div class="card border">
          <div class="card-header card-header-compact  text-bg-dark">Retourgegevens</div>
          <div class="card-body p-2">
            <div class="form-group row">
              <label class="col-4 col-form-label">Referentie</label>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="returnItem.Header.Reference"
                  maxlength="64" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Opmerking</label>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="returnItem.Header.Remarks"
                  maxlength="64" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label">Naam aanvrager</label>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="returnItem.Header.ApplicantName"
                  maxlength="64" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <button type="button" class="btn btn-sm btn-primary mb-3 me-1" (click)="addItem()"><i class="fas fa-plus"></i>
                  Toevoegen</button>
                <button type="button" class="btn btn-sm btn-primary mb-3 me-1" (click)="save()"><i class="fas fa-check"></i>
                  Opslaan</button>
                <button type="button" class="btn btn-sm btn-primary mb-3" (click)="send()"><i class="far fa-envelope"></i>
                  Versturen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-grid-webshop [items]="getSortedItems()" emptyText="Geen retourregels gevonden." class="mt-3 mb-3">
      <ng-container grid-header>
        <div class="col-1">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'LineNumber'">Regel</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemNumber'">Artikelnr</app-sort-field>
        </div>
        <div class="col-1 text-end">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemGroup'">Groep</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemDescription'">Omschrijving
          </app-sort-field>
        </div>
        <div class="col-1 text-end">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'Amount'">Aantal</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'Reason'">Reden</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ReasonDescription'">Redenomschrijving
          </app-sort-field>
        </div>
        <div class="col-1">
        </div>
      </ng-container>
      <ng-template let-item>
        <div class="col-1">{{item.LineNumber}}</div>
        <div class="col-2">{{item.ItemNumber}}</div>
        <div class="col-1 text-end">
          <span *ngIf="item.ItemGroup">{{item.ItemGroup}}</span>
        </div>
        <div class="col-2">{{item.ItemDescription}}</div>
        <div class="col-1 text-end">{{item.Amount}}</div>
        <div class="col-2">{{customerInformationService.getReturnReasonDescription(item.Reason)}}</div>
        <div class="col-2">{{item.ReasonDescription}}</div>
        <div class="col-1 text-end">
          <button (click)="deleteItem(item)" class="btn btn-danger" aria-label="Wissen" type="button"><i
              class="fas fa-trash-alt"></i></button>
        </div>
      </ng-template>
    </app-grid-webshop>

  </div>
</div>