import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-input-popup',
  templateUrl: './input-popup.component.html',
  styleUrls: ['./input-popup.component.scss']
})
export class InputPopupComponent implements OnInit {
  title: string;
  description: string;
  defaultValue: string;
  isPassword: boolean;
  inputValue: string;
  mainService: MainService;
  focusInput = new EventEmitter<boolean>();

  public onClose: Subject<string>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.inputValue = this.defaultValue;
    setTimeout(() => {
          this.focusInput.emit(true);
    }, 0);
  }

  public confirm() {
    this.onClose.next(this.inputValue);
    this.bsModalRef.hide();
  }

  public decline() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }
}
