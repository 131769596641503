import { Pipe, PipeTransform } from '@angular/core';
import { UniversalCarBrand } from '../../_models/car-types/universal.car.brand';

@Pipe({
  name: 'carBrandFilter'
})
export class CarBrandFilterPipe implements PipeTransform {

  containsFilter(text, filter) {
    return filter && text.toLowerCase().indexOf(filter) >= 0;
  }

  transform(brands: UniversalCarBrand[], filterString: string): UniversalCarBrand[] {
    if (!filterString) { return brands; }
    return brands.filter(brand => {
      let ok = false;
      if (brand) {
        if (this.containsFilter(brand.BrandName, filterString)) { ok = true; }
      }
      return ok;
    });

  }

}
