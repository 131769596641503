<div class="row g-0">
  <div class="col-5 pe-1">
    <div class="dropdown">
      <button type="button" class="btn btn-primary w-100 small-font-btn" data-bs-toggle="dropdown"
        [disabled]="ctxCarTypeSelection==null">
        <app-car-brand-display [carBrand]="selectedBrand" defaultText="Kies merk..."></app-car-brand-display>
      </button>
      <span class="dropdown-menu">
        <div class="brand-list" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 270) + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden', 'background-color': '#ffffff'}">
          <table class="table table-fix-striped table-fix-hover table-fix-margin table-sm pointer">
            <tr>
              <td>
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control" [(ngModel)]="filterBrand" placeholder="zoek...">
                </div>
              </td>
            </tr>
            <tr *ngFor="let brand of ctxCarTypeSelection.Merken | carBrandFilter: filterBrand"
              (click)="chooseBrand(brand)">
              <td>
                <app-car-brand-display [carBrand]="brand"></app-car-brand-display>
              </td>
            </tr>
          </table>
        </div>
      </span>
    </div>
  </div>
  <div class="col-7">
    <div class="dropdown">
      <button type="button" class="btn btn-primary w-100 small-font-btn" data-bs-toggle="dropdown"
        [disabled]="models==null">
        <div>{{getSelectedModel("Kies model...")}}</div>
        <div>{{getSelectedModelDuration()}}</div>
      </button>
      <span class="dropdown-menu model-list"
        [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 270) + 'px', 'overflow-y': 'auto'}">
        <table class="table table-fix-striped table-fix-hover table-fix-margin table-sm pointer">
          <tr>
            <td colspan="5">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" [(ngModel)]="filterModel" placeholder="zoek...">
              </div>
            </td>
          </tr>
          <tr *ngFor="let model of models | carModelFilter: filterModel" (click)="chooseModel(model)">
            <td>{{model.ModelName}}</td>
            <td>{{model.ModelRemark}}</td>
            <td>{{model.BodyType}}</td>
            <td class="text-nowrap">{{model.AvailableFrom.Display}}</td>
            <td class="text-nowrap">{{model.AvailableUntil.Display}}</td>
          </tr>
        </table>
      </span>
    </div>
  </div>
</div>
<div class="row g-0 mt-1">
  <div class="col">
    <div class="dropdown">
      <button type="button" class="btn btn-primary w-100 small-font-btn" data-bs-toggle="dropdown"
        [disabled]="carTypes==null">
        <div>{{getSelectedCarType("Kies type...")}}</div>
        <div>{{getSelectedCarTypeDuration()}}</div>
      </button>
      <span class="dropdown-menu type-list"
        [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 310) + 'px', 'overflow-y': 'auto'}">
        <table class="table table-fix-striped table-fix-hover table-fix-margin table-sm pointer">
          <tr>
            <td colspan="6">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" [(ngModel)]="filterType" placeholder="zoek...">
              </div>
            </td>
          </tr>
          <tr *ngFor="let carType of carTypes | carTypeFilter: filterType" (click)="chooseCarType(carType)">
            <td class="text-nowrap">{{carType.TypeName}}</td>
            <td>{{carType.TypeRemark}}</td>
            <td class="text-nowrap">{{catalogPropertyService.getPropertyValueString(carType.Properties, catalogPropertyService.propertyKindCatalog.EngineCode)}}</td>
            <td class="text-nowrap">{{catalogPropertyService.getPropertyValueString(carType.Properties, catalogPropertyService.propertyKindCatalog.Power)}}</td>
            <td class="text-nowrap">{{carType.AvailableFrom.Display}}</td>
            <td class="text-nowrap">{{carType.AvailableUntil.Display}}</td>
          </tr>
        </table>
      </span>
    </div>
  </div>
</div>