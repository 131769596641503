import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemGroupImagesData } from '../../../_models/admin/item-images/item.group.images.data';
import { AdminItemImagesService } from '../../../_services/admin/admin-item-images.service';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-admin-item-group-images',
  templateUrl: './admin-item-group-images.component.html',
  styleUrls: ['./admin-item-group-images.component.scss']
})
export class AdminItemGroupImagesComponent implements OnInit {
  itemGroupImagesData: ItemGroupImagesData;
  focusInput = new EventEmitter<boolean>();
  filterTimeout: number;

  constructor(
    public mainService: MainService,
    public adminItemImagesService: AdminItemImagesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: any) => {
        this.itemGroupImagesData = data.itemGroupImagesData;
        this.setInputFocus();
      });
  }

  setInputFocus(): void {
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  clearFilter(event: MouseEvent) {
    this.itemGroupImagesData.Filter = '';
    this.setInputFocus();
  }

  doFilter(directly = false) {
    let timeout = 1000;
    if (this.filterTimeout) { window.clearTimeout(this.filterTimeout); }
    if (directly) { timeout = 0; }
    this.filterTimeout = window.setTimeout(() => {
      this.adminItemImagesService.getItemImagesItemGroupData(this.itemGroupImagesData.Filter, 1, this.itemGroupImagesData.PageSize)
        .subscribe(data => {
          this.itemGroupImagesData = data;
          this.setInputFocus();
        });
    }, timeout);
  }

}
