import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextTyres } from '../../../_models/tyres/context.tyres';
import { TyreResult } from '../../../_models/tyres/tyre.result';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { ViewKindInfo } from '../../../_models/common/view.kind.info';
import { ViewKind } from '../../../_models/common/view.kind';
import { MainService } from '../../../_services/main.service';
import { CartService } from '../../../_services/cart.service';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';
import { SelectListItem } from '../../../_models/common/select.list.item';
import { ResizeInfo } from '../../../_models/common/resize.info';
import { TyreRequest } from '../../../_models/tyres/tyre.request';
import { TyreSize } from '../../../_models/tyres/tyre.size';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { WheelTyreSet } from '../../../_models/rims-and-tyres/wheel.tyre.set';
import { SettingsService } from '../../../_services/settings.service';

@Component({
  selector: 'app-tyres-boxed-layout',
  templateUrl: './tyres-boxed-layout.component.html',
  styleUrls: ['./tyres-boxed-layout.component.scss']
})
export class TyresBoxedLayoutComponent implements OnInit, OnDestroy {
  @Input() ctx: ContextTyres;
  @Input() tyreResult: TyreResult;
  @Input() tyres: ShoppingCartItem[];
  @Input() set: WheelTyreSet;
  bandenmaat: string;
  // categories: string[] = [''];
  // brands: string[] = [''];
  viewKinds: ViewKindInfo[] = [{ kind: ViewKind.Compact, icon: 'fas fa-th-list' }, { kind: ViewKind.Card, icon: 'fas fa-th' }];
  busySearching = false;
  private containerHeight = '0';
  private itemContainer: ElementRef;
  @ViewChild('itemContainer') set content(content: ElementRef) {
    this.itemContainer = content;
    this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
  }
  sizeInputFocus = new EventEmitter<boolean>();
  resizeInfosSubscription: Subscription;

  constructor(
    public mainService: MainService,
    public cartService: CartService,
    public rimAndTyreService: RimAndTyreService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  filterChange(filter: string, item: SelectListItem) {
    if (this.ctx.Filters && this.ctx.Filters[filter]) {
      if (!item) {
        this.ctx.Filters[filter] = [];
      } else if (item.selected) {
        this.ctx.Filters[filter] = [item.description];
      } else {
        this.ctx.Filters[filter] = [];
      }
      this.doFilter();
    }
  }

  doFilter() {
    this.tyres = this.rimAndTyreService.filterTyres(this.tyreResult, this.ctx.Filters, this.ctx.Voorraad, this.ctx.OnlyStockSupplier);
  }

  chooseTyre(tyre: ShoppingCartItem) {

  }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'height': this.containerHeight,
        'overflow': 'auto'
      };
    }
    return null;
  }

  handleResizeInfo(info: ResizeInfo) {
    if (this.itemContainer) {
      this.containerHeight = (info.clientHeight - info.headerHeight - this.itemContainer.nativeElement.offsetTop + 105) + 'px';
    }
  }

  getTyres(bandenmaat: string, itemCount: number): void {
    const request = new TyreRequest([TyreSize.getFromString(bandenmaat)], itemCount, true, 0, ShopSoort.Banden);
    console.info(`getTyres: ${bandenmaat}`);
    this.rimAndTyreService.getTyreSizes(request)
      .subscribe(tyreResult => {
        this.tyreResult = tyreResult;
        this.doFilter();
        if (this.set) {
          this.mainService.fireReziseEvent();
        }
      });
  }

  getSelectedItem(filterOption: string, emptyFilterDescription: string): string {
    if (this.ctx && this.ctx.Filters[filterOption].length !== 0) {
      return this.ctx.Filters[filterOption].join(',');
    }
    return emptyFilterDescription;
  }

  setSelectedItem(filterOption: string, filter: string): void {
    if (this.ctx && !filter) {
      this.ctx.Filters[filterOption] = [];
    } else if (this.ctx && !this.ctx.Filters[filterOption].includes(filter)) {
      this.ctx.Filters[filterOption] = [filter];
    }
    this.doFilter();
  }

  getFilterOption(filterOption: string): boolean {
    if (this.ctx) {
      return this.ctx.Filters[filterOption].includes('1');
    }
  }

  toggleFilterItem(filterOption: string): void {
    if (this.ctx) {
      if (this.ctx.Filters[filterOption].includes('1')) {
        this.ctx.Filters[filterOption] = [];
      } else {
        this.ctx.Filters[filterOption] = ['1'];
      }
      this.doFilter();
    }
  }

  reset(): void {
    this.bandenmaat = '';
    this.tyreResult = null;
    this.tyres = null;
    this.setSelectedItem('TyreSpeedIndex', '');
    this.setSelectedItem('TyreBrand', '');
    this.setSelectedItem('TyreKind', '');
    this.sizeInputFocus.emit(true);
  }

}
