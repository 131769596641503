import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserIdentificationModel } from '../../../../_models/user.identification.model';
import { AdminService } from '../../../../_services/admin/admin.service';
import { AdminNameService } from '../../../../_services/admin/admin-name.service';
import { SettingModel } from '../../../../_models/common/setting.model';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { MainService } from '../../../../_services/main.service';
import { ToasterService } from '../../../../_services/toaster.service';

@Component({
  selector: 'app-admin-settings-selector-info-link',
  templateUrl: './admin-settings-selector-info-link.component.html',
  styleUrls: ['./admin-settings-selector-info-link.component.scss']
})
export class AdminSettingsSelectorInfoLinkComponent implements OnInit {
  @Input() uid: UserIdentificationModel;
  @Output() uidChanged: EventEmitter<UserIdentificationModel> = new EventEmitter<UserIdentificationModel>();
  @Input() moreSpecificSettings: { [key: string]: SettingModel[] };
  showMenu = false;

  constructor(
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    public adminNameService: AdminNameService,
    private mainService: MainService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  isEmpty(uid: UserIdentificationModel) {
    return (uid && uid.Wholesaler === 0 && uid.Branch === 0 && uid.Customer === 0 && uid.UserID === 0);
  }

  isWholesalerSpecified(uid: UserIdentificationModel) {
    return (uid && uid.Wholesaler !== 0 && uid.Branch === 0 && uid.Customer === 0 && uid.UserID === 0);
  }

  isBranchSpecified(uid: UserIdentificationModel) {
    return (uid && uid.Wholesaler !== 0 && uid.Branch !== 0 && uid.Customer === 0 && uid.UserID === 0);
  }

  isCustomerSpecified(uid: UserIdentificationModel) {
    return (uid && uid.Wholesaler !== 0 && uid.Customer !== 0 && uid.UserID === 0);
  }

  isUserIDSpecified(uid: UserIdentificationModel) {
    return (uid && uid.Wholesaler !== 0 && uid.Customer !== 0 && uid.UserID !== 0);
  }

  choose(uid: UserIdentificationModel) {
    this.showMenu = false;
    this.adminService.settingsSelectorPopup(uid)
      .subscribe(uidNew => {
        this.select(uid, uidNew);
      });
  }

  reset(uid: UserIdentificationModel) {
    const uidNew = new UserIdentificationModel();
    uidNew.Wholesaler = this.adminService.currentUid.Wholesaler;
    this.select(uid, uidNew);
  }

  select(uid: UserIdentificationModel, uidNew: UserIdentificationModel) {
    if (uidNew && !UserIdentificationModel.areEqual(uidNew, uid)) {
      UserIdentificationModel.copyFromTo(uidNew, uid);
      setTimeout(() => {
        this.uidChanged.emit(uidNew);
      }, 500);
    }
  }

  delete(event: MouseEvent, uim: UserIdentificationModel, currentUid: UserIdentificationModel) {
    const desc = this.adminNameService.getUidText(uim, uim.Wholesaler !== currentUid.Wholesaler);
    const settings = this.adminSettingsService.getMoreSpecificSettingsForUim(this.moreSpecificSettings, uim);
    this.mainService.confirmBox(`Weet u zeker dat u onderstaande specifieke instellingen van '${desc}' wilt verwijderen?<br /><br />${settings.join('<br />')}`)
      .subscribe(yes => {
        if (yes) {
          this.adminSettingsService.deleteSettingsForUim(uim, this.moreSpecificSettings)
            .subscribe(ok => {
              this.toasterService.showToast('Succes', `De instellingen van '${desc}' zijn verwijderd!`);
              this.uidChanged.emit(currentUid);
            });
        }
      });
    event.stopPropagation();
  }

}
