<div class="footer text-bg-dark py-3">
  <div class="container my-3">
    <div class="row">
      <div class="col-lg">
        <div class="footer-header pb-2">Klantenservice</div>
        <div class="footer-text">
          T: 024-3710510 <br />
          F: 024-3710511<br />
          Openingstijden:<br />
          Ma - Vr: 8.00 - 17.30<br />
          Za: 8:30 - 12:00<br />
          Zo: Gesloten
        </div>
      </div>
      <div class="col-lg">
        <div class="footer-header pb-2">Links</div>
        <div class="footer-text">
          <a href="https://ovi.rdw.nl/" class="text-white">RDW</a><br />
          <a href="/assets/img/identity/excluparts/Privacyverklaring.pdf" class="text-white" target="_blank" title="Privacy verklaring">Privacy verklaring</a><br />
          <a href="/assets/img/identity/excluparts/Cookieverklaring2018.pdf" class="text-white" target="_blank" title="Cookie verklaring">Cookie verklaring</a><br />
        </div>
      </div>
    </div>    
  </div>
</div>