<table class="table table-striped">
  <thead>
    <tr>
      <th>Koppeling</th>
      <th>Filialen</th>
      <th>Leverancier</th>
      <th class="text-end">Aktie</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let link of stockInfoSuppliersLink | keyvalue">
      <td class="ps-3">{{link.key}}</td>
      <td>
        <app-admin-dropdown [dividerKind]="-1" [labelCols]="0" [(model)]="link.value.Branches" [modelValues]="branches" [modelValueProperty]="'BranchNumber'" [modelDisplayProperty]="'BranchNameShort'" [modelEmptyDisplayValue]="'Alle filialen'" [multiSelect]="true" [emptyIsAllSelected]="true" [multiSelectSeparator]="'<br />'"></app-admin-dropdown>
      </td>
      <td>
        <app-admin-supplier-input [labelCols]="0" [model]="link.value.Supplier" (modelChange)="changeLink(link.key, $event)" [dividerKind]="-1" [stopSearchingOnBlur]="true">
        </app-admin-supplier-input>
      </td>
      <td class="text-end">
        <button class="btn btn-xs btn-outline-secondary" (click)="removeLink(link.key)"><i class="fas fa-trash-alt"></i>
          Verwijder</button>
      </td>
    </tr>
    <tr>
      <td class="text-end" colspan="7">
        <div class="btn-group">
          <button class="btn-primary btn btn-xs" (click)="addLink()"><i class="fa fa-plus"></i> Toevoegen</button>
        </div>
      </td>
    </tr>
  </tbody>
</table>