<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3 scroll-container">

    <app-header-panel [ctx]="ctx" [timing]="ctx?.Timing" [preText]="'Groepen voor een '"
      (backClick)="carTypeService.backToCarType(ctx.ShopKind)">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col-sm-auto">
          <div class="input-group">
            <input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="filterString"
              placeholder="filter op productsoort">
            <button class="btn btn-light" type="button" (click)="filterString = ''">
              <i class="fas fa-times"></i>
            </button>
            <button *ngIf="ctx.FavouriteCategories" class="btn btn-light" type="button"
              tooltip="{{ctx.FavouritesCollapsed ? 'Laat de afbeeldingen met favorieten zien' : 'Verberg de afbeeldingen'}}"
              placement="bottom" container="body" (click)="clickFavouritesCollapsed(ctx)">
              <span *ngIf="ctx.FavouritesCollapsed"><i class="far fa-star"></i></span>
              <span *ngIf="!ctx.FavouritesCollapsed"><i class="fas fa-star"></i></span>
            </button>
          </div>
        </div>
        <div *ngIf="!settingsService.getSettingBoolean('HideCatalogGroupsBoxedLayoutDisplayOptions')"
          class="col-sm-auto ps-2">
          <app-display-opties [ctx]="ctx" [viewKinds]="viewKinds"></app-display-opties>
        </div>
      </div>
    </app-header-panel>

    <div class="mt-3">
      <div *ngIf="ctx.FavouriteCategories" [@slideInHorizontal]="ctx.FavouritesCollapsed" class="row favourites-row">
        <div *ngFor="let favourite of ctx.FavouriteCategories" class="col-sm-2 parts-category mb-3">
          <div class="card border-primary" (click)="catalogService.clickCategory($event, ctx.CarType, favourite, null)">
            <div class="row">
              <img class="col-12" [src]="favourite.ImageUrl+'_200x140.png'" />
            </div>
            <div class="row">
              <span class="col-12 text-center">{{favourite.CategoryName}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="ctx" class="row">
      <div *ngFor="let mainCategory of ctx.MainCategories | sortByProperty:'SortOrder'" class="col-sm-3"
        [ngClass]="{'group-container-compact': ctx.ViewKind === 2}">
        <div class="card border">
          <div class="card-header  text-bg-primary" [ngClass]="{'card-header-compact': ctx.ViewKind === 2}">
            <span class="panel-title">{{mainCategory.CategoryName}}</span>
            <span class="float-end" *ngIf="filterString">({{filterResult.count}} van
              {{mainCategory.SubPartsCategories?.length}})</span>
          </div>
          <ul class="list-group list-group-flush">
            <ng-container *ngFor="let partsCategory of mainCategory.SubPartsCategories | partsCategoryFilter: filterString | filterCount: { object: filterResult, key: 'count' }; let odd=odd;">
              <li class="list-group-item parts-category-container"
                [ngClass]="{'alt-back': odd, 'pointer': settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')}"
                (click)="clickCategory($event, true, ctx.CarType, partsCategory, null)">
                <div class="parts-category-header">
                  <div
                    *ngIf="!filterString && !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')"
                    class="parts-category-collapse-button">
                    <div *ngIf="!filterString && partsCategory.PartsDescriptions.length"
                      (click)="partsCategory['isOpen'] = !partsCategory['isOpen'];" class="collapse-button"
                      [ngClass]="{'is-collapsed': !partsCatIsOpen(partsCategory)}">
                      <i class="fas fa-angle-up"></i>
                    </div>
                  </div>
                  <div class="parts-category"
                    [ngClass]="{'pointer': !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList'), 'text-uppercase': settingsService.getSettingNumber('CatalogGroupsLetterCase')===1, 'text-lowercase': settingsService.getSettingNumber('CatalogGroupsLetterCase')===2}"
                    (click)="clickCategory($event, true, ctx.CarType, partsCategory, null)">
                    {{partsCategory.CategoryName}}
                  </div>
                </div>
                <ul
                  *ngIf="(partsCategory['isOpen'] && !settingsService.getSettingBoolean('HideCatalogGroupsPartsTypesCollapsibleList')) || filterString"
                  class="parts-category-descriptions-list"
                  [@slideInHorizontal]="!partsCategory['isOpen'] && !filterString">
                  <li *ngFor="let description of partsCategory.PartsDescriptions | filterString: filterString"
                    [ngClass]="{'text-alarm':containsFilter(description, filterString), 'text-low': filterString && !containsFilter(description, filterString)}"
                    (click)="catalogService.clickCategory($event, ctx.CarType, partsCategory, description)">{{description}}</li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="ctx && !ctx.MainCategories" class="alert alert-warning" role="alert">
      <i class="fas fa-exclamation-triangle"></i>&nbsp;Er missen instellingen voor de catalogus!
    </div>

  </div>
</div>