import { Component, OnInit, Input } from "@angular/core";
import { ContextMain } from "../../_models/common/context.main";

@Component({
  selector: "app-footer-default",
  templateUrl: "./footer-default.component.html",
  styleUrls: ["./footer-default.component.scss"]
})
export class FooterDefaultComponent implements OnInit {
  @Input() ctx: ContextMain;
  year = 2019;
  constructor() {}

  ngOnInit() {
    const now = new Date();
    this.year = now.getFullYear();
  }
}
