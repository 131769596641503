import { Component, OnInit } from '@angular/core';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { DateChoice } from '../../../_models/customer-information/date.choice';
import { GisDeliveryNoteSearchRequest } from '../../../_models/customer-information/gis.delivery.note.search.request';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SortService } from '../../../_services/sort.service';
import { MainService } from '../../../_services/main.service';
import { AuthService } from '../../../_services/auth.service';
import { DeliveryNoteHeader } from '../../../_models/delivery-notes/delivery.note.header';

@Component({
  selector: 'app-delivery-notes-webshop',
  templateUrl: './delivery-notes-webshop.component.html',
  styleUrls: ['./delivery-notes-webshop.component.scss']
})
export class DeliveryNotesWebshopComponent implements OnInit {
  headers: DeliveryNoteHeader[];
  isBusy = false;

  constructor(
    private localeService: BsLocaleService,
    public customerInformationService: CustomerInformationService,
    public mainService: MainService,
    private authService: AuthService,
    public sortService: SortService
  ) { }

  ngOnInit() {
    this.localeService.use('nl');
    if (!this.customerInformationService.requestDeliveryNote) {
      this.customerInformationService.requestDeliveryNote = new GisDeliveryNoteSearchRequest();
      this.customerInformationService.dateChoicesDeliveryNote = this.customerInformationService.getDateChoices();
      this.customerInformationService.selectionDeliveryNote = this.customerInformationService.dateChoicesDeliveryNote[2];
      this.customerInformationService.setDates(this.customerInformationService.requestDeliveryNote
        , this.customerInformationService.selectionDeliveryNote);
    }
    this.sortService.initSortField('delivery-notes', 'DeliveryNoteDate', true);
    this.search();
  }

  search() {

    this.isBusy = true;
    this.customerInformationService.getDeliveryNotes(
      this.customerInformationService.requestDeliveryNote.DateFrom,
      this.customerInformationService.requestDeliveryNote.DateTo,
      this.customerInformationService.requestDeliveryNote.ArticleNumber,
      this.customerInformationService.requestDeliveryNote.DeliveryNoteNumber,
      this.customerInformationService.requestDeliveryNote.InvoiceNumber,
      this.customerInformationService.requestDeliveryNote.Reference)
      .subscribe(headers => {
        this.isBusy = false;
        this.customerInformationService.showPasswordPrompt = false;
        this.headers = headers;
      }, error => {
        this.isBusy = false;
        if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
        console.error(error);
      });

  }


}
