<app-admin-form [title]="'Onderhoud artikel soorten'" [object]="settings" [fullHeight]="true" [noPadding]="true">
  <ng-container *ngIf="(getPartsTypes() | filterPartsTypes:filterString | filterCrossedPartsTypes:settings:hideCrossed) as resultingPartsTypes">
    <div class="row m-0 mt-3">
      <div class="col-8">
        <app-admin-input [labelCols]="1" [label]="'Zoek'" [(model)]="filterString" (inputClick)="filterString = ''"
          [placeholder]="'geef hier eventueel een zoekstring'" [labelLeftAlign]="true" [dividerKind]="1">
          <span class="input-group-text"><span class="small">{{resultingPartsTypes.length}} /
              {{getPartsTypesCount()}}</span></span>
        </app-admin-input>
      </div>
      <div class="col-4 pt-2">
        <app-admin-checkbox [labelCols]="8" [label]="'Verberg gekoppelde'" [(model)]="hideCrossed" [dividerKind]="1">
        </app-admin-checkbox>
      </div>
    </div>
    <table class="table table-striped m-0">
      <thead>
        <tr>
          <th class="py-1">
            <div class="row">
              <div *ngFor="let catalog of getCatalogs()" class="col-md mb-1">{{catalog}}</div>
            </div>
          </th>
        </tr>
      </thead>
    </table>
    <div class="cross-container">
      <div class="cross-container-scroll small-font">
        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let partsType of resultingPartsTypes | maxItemsFilter: _maxItemCount;">
              <td>
                <div class="row">
                  <div class="col-12 mb-1">{{partsType.Description}} ({{partsType.ID}})</div>
                  <div *ngFor="let catalog of getCatalogs()" class="col-md mb-1">
                    <app-admin-parts-type-binder [partsType]="partsType" [catalog]="catalog" [settings]="settings">
                    </app-admin-parts-type-binder>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-show-more-button [(currentItemCount)]="_maxItemCount" [showPerCount]="maxItems" [totalItemCount]="resultingPartsTypes.length"></app-show-more-button>
      </div>
    </div>
    <hr class="mt-0">
    <div class="ms-2">
      <app-admin-cancel-save-button [labelCols]="0"
        [saveAction]="adminSettingsService.saveSettingsCatalogPartsTypes(settings)">
      </app-admin-cancel-save-button>
    </div>
  </ng-container>
</app-admin-form>