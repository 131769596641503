import { Component, OnInit, Input } from '@angular/core';
import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { MainService } from '../../../_services/main.service';
import { Animations } from '../../../_animations/animations';

@Component({
  selector: 'app-catalog-parts-description-parts',
  templateUrl: './catalog-parts-description-parts.component.html',
  styleUrls: ['./catalog-parts-description-parts.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CatalogPartsDescriptionPartsComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  @Input() partsDescription: string;
  filterResult = { count: 0 };
  objectKeys = Object.keys;

  constructor(
    public catalogService: CatalogService,
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

  getTranslatedPropertyName(key: string, language: string): string {
    if (this.ctx?.PartsPropertyTranslations && this.ctx.PartsPropertyTranslations[key]) {
      return this.ctx.PartsPropertyTranslations[key][language];
    }
    return null;
  }


}
