import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SettingsCatalogLayoutCatalogKindInfo } from '../../../../../_models/admin/settings/settings.catalog.layout.catalog.kind.info';


@Component({
  selector: 'app-admin-catalog-layout-choose-catalog',
  templateUrl: './admin-catalog-layout-choose-catalog.component.html',
  styleUrls: ['./admin-catalog-layout-choose-catalog.component.scss']
})
export class AdminCatalogLayoutChooseCatalogComponent implements OnInit {
  catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo };
  data: { clear: boolean, catalogInfo: { [key: number]: boolean } };
  public onClose: Subject<{ clear: boolean, catalogInfo: { [key: number]: boolean } }>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.data = { clear: false, catalogInfo: {} };
    Object.keys(this.catalogKindInfo).forEach(key => {
      this.data.catalogInfo[key] = false;
    });
  }

  getCatalogKindInfos(): SettingsCatalogLayoutCatalogKindInfo[] {
    return Object.keys(this.catalogKindInfo).map(key => this.catalogKindInfo[key]);
  }

  save() {
    this.onClose.next(this.data);
    this.bsModalRef.hide();
  }

  cancel() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }
}
