import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';
import { AvailabilityLogObject as AvailabilityLogObject } from '../../_models/item-info/availability.log.object';
import { AvailabilityLogItem } from '../../_models/item-info/availability.log.item';

@Component({
  selector: 'app-show-availability-log',
  templateUrl: './show-availability-log.component.html',
  styleUrls: ['./show-availability-log.component.scss']
})
export class ShowAvailabilityLogComponent implements OnInit {
  log: AvailabilityLogObject;
  mainService: MainService;
  
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  getIndents(logItem: AvailabilityLogItem): string {
    if (logItem) {return Array(logItem.Indent).fill('').join('&nbsp;&nbsp;'); }
    return '';
  }

}
