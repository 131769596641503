import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { ContextExternalFrame } from '../../_models/external-frame/context.external.frame';
import { ApiService } from '../../_services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalFrameDataResolverService {

  constructor(
    private apiService: ApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextExternalFrame> | Observable<never> {
    let frameId = 0;
    if (state.url.startsWith('/external')) { frameId = +state.url.substr('/external'.length); }

    return this.apiService.getContextExternalFrame(frameId).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx && !ctx.NewBrowser) {
          return of(ctx);
        } else { // no data?
          if (ctx) { window.open(ctx.Url); }
          return EMPTY;
        }
      })
    );

  }
}
