<div class="modal-header">
    <span class="modal-title">
        <div class="row">
            <label for="inputFilter" class="col-sm-5 col-form-label">Kies een grossier</label>
            <div class="col-sm-7">
                <input [appFocus]="focusInput" [(ngModel)]="filterString" type="text" class="form-control" id="inputFilter">
            </div>
        </div>
    </span>
</div>
<div class="modal-body"
    [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
    <table class="table table-striped table-hover">
        <tbody>
            @for (grossier of wholesalers | wholesalerFilter: filterString; track grossier.ID;) {
            <tr class="pointer">
                <td (click)="choose(grossier)">{{grossier.ShortName}} </td>
                <td class="text-low text-end">{{grossier.ID}}</td>
                @if (withCookieDeleter) {
                <td><span class="float-end" (click)="deleteCookiesWithConfirm(grossier)"><i class="far fa-trash-alt"></i></span></td>
                }
            </tr>
            }
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="choose(null)">Annuleren</button>
</div>