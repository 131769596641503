<div class="card" [ngClass]="{'disabled': notEnoughCredits()}">
  <div class="card-body">
    <h5 class="card-title">{{item.Title}}</h5>
  </div>
  <img *ngIf="item.Images && item.Images.length"
    [src]="loyaltyShopService.getImagelUrl(item, item.Images[0], '155x190')" class="card-img-top">
  <div class="card-body">
    <p class="card-text" [innerHTML]="item.Description | safeHtml"></p>
    <p class="card-text">{{item.Credits}} {{settingsService.getSetting('NameOfPointsLoyaltyShop')}}
      <span *ngIf="item.Price"> + {{item.Price | price}}</span></p>
    <button *ngIf="!notEnoughCredits()" (click)="loyaltyShopService.orderItem(item)" class="btn w-100 btn-primary"><i class="far fa-cart-arrow-down"></i> Bestel!</button>
    <div *ngIf="notEnoughCredits()" class="alert alert-danger" role="alert">
     Nog {{item.Credits - this.cartService.getLoyaltyPoints()}} {{settingsService.getSetting('NameOfPointsLoyaltyShop')}} nodig
    </div>
  </div>
</div>