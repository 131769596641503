import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-outstanding-items',
  templateUrl: './outstanding-items.component.html',
  styleUrls: ['./outstanding-items.component.scss']
})
export class OutstandingItemsComponent implements OnInit {

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }
}
