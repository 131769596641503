import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { ContextAdmin } from '../../_models/admin/context.admin';
import { AdminService } from '../../_services/admin/admin.service';


@Injectable({
  providedIn: 'root'
})
export class ContextAdminDataResolverService   {

  constructor(
    private adminService: AdminService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextAdmin> | Observable<never> {

    return this.adminService.getContext(false).pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
