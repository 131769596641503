import { Component, OnInit, Input } from '@angular/core';

import { ShopSoort } from '../../../_models/common/shop.soort';
import { ContextMain } from '../../../_models/common/context.main';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';

@Component({
  selector: 'app-webshop-menu-dynamic',
  templateUrl: './webshop-menu-dynamic.component.html',
  styleUrls: ['./webshop-menu-dynamic.component.scss']
})
export class WebshopMenuDynamicComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctxMain: ContextMain;
  @Input() ctxPromotions: ContextPromotions;

  constructor() { }

  ngOnInit() {
  }

}
