import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-side-panel-header',
  templateUrl: './side-panel-header.component.html',
  styleUrls: ['./side-panel-header.component.scss']
})
export class SidePanelHeaderComponent implements OnInit {
  @Input() collapseOnClickHeader: boolean = null;
  @Input() collapsed = false;
  @Input() cssClass = 'text-bg-dark';
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  collapse(clickedOnPanel: boolean) {
    if (this.collapseOnClickHeader === true || !clickedOnPanel) {
      this.collapsed = !this.collapsed;
      this.collapsedChange.emit(this.collapsed);
    }
  }

}
