import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { ItemInfoResponse } from '../../../../_models/item-info/item.info.response';
import { ResponseItem } from '../../../../_models/item-info/response.item';
import { ResponseItemAvailabilityBranchInfo } from '../../../../_models/item-info/response.item.availability.branch.info';
import { ResponseItemAvailabilitySupplierInfo } from '../../../../_models/item-info/response.item.availability.supplier.info';
import { CartService } from '../../../../_services/cart.service';
import { MainService } from '../../../../_services/main.service';
import { ItemInfoFilterPipe } from './item-info-filter.pipe';
import { SupplierInterfaceData } from 'app/_models/admin/supplier-interface/supplier.interface.data';

@Component({
  selector: 'app-item-info-response',
  templateUrl: './item-info-response.component.html',
  styleUrls: ['./item-info-response.component.scss']
})
export class ItemInfoResponseComponent implements OnInit, OnChanges {
  @Input() response: ItemInfoResponse;
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Input() filterEnabled = false;
  @Output() searchAction: EventEmitter<string> = new EventEmitter();
  @Output() filterResult: EventEmitter<ResponseItem[]> = new EventEmitter();
  filteredItems: ResponseItem[];

  constructor(
    public mainService: MainService,
    private cartService: CartService,
    private itemInfoFilterPipe: ItemInfoFilterPipe
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.info('changes...', changes);
    this.doFilter();
  }

  doFilter() {
    this.filteredItems = this.itemInfoFilterPipe.transform(this.response?.ItemResults, this.response?.Filter);
    this.filterResult.emit(this.filteredItems);
  }

  getFilterDeclarations(): { [key: string]: string[] } {
    const filterDeclarations: { [key: string]: string[] } = {};
    if (this.response?.FilterDeclarations) {
      Object.keys(this.response.FilterDeclarations).forEach(filterDeclaration => {
        if (this.response.FilterDeclarations[filterDeclaration].length > 1) {
          filterDeclarations[filterDeclaration] = this.response.FilterDeclarations[filterDeclaration];
        }
      });
    }
    return filterDeclarations;
  }

  getActiveFilterDeclarations(): { [key: string]: string[] } {
    const filterDeclarations: { [key: string]: string[] } = {};
    if (this.response?.FilterDeclarations && this.response?.Filter) {
      Object.keys(this.response.Filter).forEach(filter => {
        Object.keys(this.response.FilterDeclarations).forEach(filterDeclaration => {
          if (filter === filterDeclaration && this.response.FilterDeclarations[filterDeclaration].length > 1) {
            filterDeclarations[filterDeclaration] = this.response.FilterDeclarations[filterDeclaration];
          }
        });
      });
    }
    return filterDeclarations;
  }

  isFilterPresent() {
    return this.response?.Filter && Object.keys(this.response.Filter).length > 0;
  }

  changeFilter(filterKey: string, value: any) {
    this.response.Filter[filterKey] = value.target.value;
    this.doFilter();
  }

  addFilter() {
    if (this.response?.FilterDeclarations) {
      let filters = Object.keys(this.getFilterDeclarations());
      if (this.response?.Filter && this.response.Filter['length']) { filters = filters.filter(f => !Object.keys(this.response.Filter).includes(f)); }
      this.mainService.selectionBox('Kies een filter', filters)
        .subscribe(filter => {
          if (filter && this.response.FilterDeclarations[filter].length > 1) {
            if (!this.response.Filter) this.response.Filter = {};
            this.response.Filter[filter] = this.response.FilterDeclarations[filter][0];
            this.doFilter();
          }
        });
    }
  }

  clearFilter(filter: string) {
    if (this.response?.Filter && this.response.Filter.hasOwnProperty(filter)) {
      delete this.response.Filter[filter];
      this.doFilter();
    }
  }

  

}
