import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { SettingsBase } from '../../../_models/admin/settings/settings.base';
import { AdminSetting } from '../../../_models/admin/settings/admin.setting';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-admin-settings-customer-information',
  templateUrl: './admin-settings-customer-information.component.html',
  styleUrls: ['./admin-settings-customer-information.component.scss']
})
export class AdminSettingsCustomerInformationComponent implements OnInit {
  settings: SettingsBase;
  oldPasswordDisabled: boolean;
  oldPassword: string;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.oldPasswordDisabled = this.adminSettingsService.getAdminSetting(data.settings, 'GisPasswordDisabled').Value;
        this.oldPassword = this.adminSettingsService.getAdminSetting(data.settings, 'ShopModule_GarageInformatieSysteem_ExtraPassword').Value;
      });
  }

  getActiveModels(): AdminSetting[] {
    const models: AdminSetting[] = [];
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOrderhistorieVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOpenstaandeOrdersVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisPakbonnenVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisFakturenVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOpenstaandePostenVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisRetourenVerbergen'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisGarantiesVerbergen'));
    return models;
  }

  getPasswordModels(): AdminSetting[] {
    const models: AdminSetting[] = [];
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOrderhistorieNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOpenstaandeOrdersNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisPakbonnenNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisFakturenNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisOpenstaandePostenNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisRetourenNietAchterLogin'));
    models.push(this.adminSettingsService.getAdminSetting(this.settings, 'GisGarantiesNietAchterLogin'));
    return models;
  }

  save(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const passwordDisabled = this.adminSettingsService.getAdminSetting(this.settings, 'GisPasswordDisabled').Value;
      const currentPassword = this.adminSettingsService.getAdminSetting(this.settings, 'ShopModule_GarageInformatieSysteem_ExtraPassword').Value;
      const passwordChanged = (passwordDisabled !== this.oldPasswordDisabled
        || (!passwordDisabled && currentPassword !== this.oldPassword));
      if (!passwordChanged) {
        this.adminSettingsService.removeAdminSetting(this.settings, 'GisPasswordDisabled');
        this.adminSettingsService.removeAdminSetting(this.settings, 'ShopModule_GarageInformatieSysteem_ExtraPassword');
      }
      this.adminSettingsService.saveSettingsBase(this.settings)
        .subscribe(ok => {
          observer.next(ok);
          observer.complete();
        });
    });

  }


}
