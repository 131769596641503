import { Component, EventEmitter, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { OrderInfoResponse } from '../../../_models/orders/order.info.response';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-order-info',
  templateUrl: './admin-order-info.component.html',
  styleUrls: ['./admin-order-info.component.scss']
})
export class AdminOrderInfoComponent implements OnInit {
  orderId: string;
  orderInfo: OrderInfoResponse;
  busy = false;
  focusInput = new EventEmitter<boolean>();

  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data: any) => {
        this.orderInfo = data.orderInfo;
        if (this.orderInfo?.ArchiveOrder?.OrderNumber) { 
          this.orderId = this.orderInfo.ArchiveOrder.OrderNumber.toString();
        } else if (this.orderInfo?.Order?.OrderNumber) {
          this.orderId = this.orderInfo.Order.OrderNumber.toString();
        } else if (this.orderInfo?.ArchiveOrder?.Id) { 
          this.orderId = this.orderInfo.ArchiveOrder.Id.toString();
        }
      });
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  getOrderInfo(orderId: string) {
    if (orderId) {
      this.busy = true;
      this.apiService.adminGetOrderInfo(this.adminService.currentUid, orderId)
        .subscribe(result => {
          this.busy = false;
          this.orderInfo = result;
        });
    }
  }

}
