import { Pipe, PipeTransform } from '@angular/core';
import { Part } from '../../_models/catalog/part';
import { WheelFilter } from '../../_models/rims-and-tyres/wheel.filter';
import { ShoppingCartItem } from '../../_models/cart/shopping.cart.item';
import { CatalogService } from '../../_services/catalog.service';

@Pipe({
  name: 'rimFilter',
  pure: false
})
export class RimFilterPipe implements PipeTransform {

  filterHit(
    part: Part, diameter: number, stock: boolean, cartItems: { [key: string]: ShoppingCartItem }, catalogService: CatalogService
  ): boolean {
    if (diameter && part['Diameter'] !== diameter) { return false; }
    if (stock && cartItems && !catalogService.hasStock(part, cartItems)) { return false; }
    return true;
  }

  transform(
    rims: Part[], diameter: number, stock: boolean, cartItems: { [key: string]: ShoppingCartItem }, catalogService: CatalogService
  ): Part[] {
    return rims.filter((rim) => {
      return this.filterHit(rim, diameter, stock, cartItems, catalogService);
    });
  }

}
