import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoyaltyShopCategory } from '../../../../_models/loyalty-shop/loyalty.shop.category';
import { AdminLoyaltyShopService } from '../../../../_services/admin/admin-loyalty-shop.service';
import { AdminService } from '../../../../_services/admin/admin.service';


@Component({
  selector: 'app-admin-loyalty-shop-category-edit',
  templateUrl: './admin-loyalty-shop-category-edit.component.html',
  styleUrls: ['./admin-loyalty-shop-category-edit.component.scss']
})
export class AdminLoyaltyShopCategoryEditComponent implements OnInit {
  category: LoyaltyShopCategory;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public adminService: AdminService,
    public adminLoyaltyShopService: AdminLoyaltyShopService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.category = data.category;
      });
  }

}
