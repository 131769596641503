export enum ShopSoort {
    StartupShop = -3,
    Settings = -2,
    ShoppingCart = -1,
    ONBEKEND = 0,
    hbase = 1,
    Toolbase = 2,
    UniversalParts = 3,
    Autotools = 4,
    AldocSys = 5,
    Banden = 6,
    WielenEnBanden = 7,
    AMT_Reparatiewijzer = 8,
    Greenline = 9,
    Modificaties = 10,
    Recalls = 11,
    BandenCalculator = 12,
    GarageInformatieSysteem = 13,
    Homepage = 14,
    Aanbiedingen = 15,
    Thule = 16,
    BandenOpslag = 17,
    KentekenPlaten = 18,
    WerkplaatsTop10 = 19,
    StorE = 20,
    Artikelinformatie = 21,
    StartScherm = 22,
    Hartsant = 23,
    MPM = 24,
    NhsArtikelZoeken = 25,
    ModusWebshop = 26,
    DVSE = 27,
    VerhuurTool = 28,
    Bonusshop = 29,
    Catalogus = 30,
    Sinatec = 31,
    Links = 32,
    Paint = 33,
    Favorites = 34,
    Maintenance = 35,
    PickupRequest = 36,
    HaynesPro_Doyen = 37,

    ExternalFrame1 = 101,
    ExternalFrame2 = 102,
    ExternalFrame3 = 103,
    ExternalFrame4 = 104,
    ExternalFrame5 = 105,
    ExternalFrame6 = 106,
    ExternalFrame7 = 107,
    ExternalFrame8 = 108,
    ExternalFrame9 = 109,

    ThirdPartyTestShop = 999,
    ThirdPartyApi = 1002,
    ThirdPartyShopApi = 1003
}
