import { Component, OnInit } from '@angular/core';

import { SettingsTyres } from '../../../_models/admin/settings/settings.tyres';
import { ActivatedRoute } from '@angular/router';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-tyres',
  templateUrl: './admin-settings-tyres.component.html',
  styleUrls: ['./admin-settings-tyres.component.scss']
})
export class AdminSettingsTyresComponent implements OnInit {
  settings: SettingsTyres;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

}
