import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { ContextLoyaltyShop } from '../../_models/loyalty-shop/context.loyalty.shop';
import { LoyaltyShopService } from '../../_services/loyalty-shop.service';


@Injectable({
  providedIn: 'root'
})
export class LoyaltyShopDataResolverService  {

  constructor(
    private loyaltyShopService: LoyaltyShopService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextLoyaltyShop> | Observable<never> {

    return this.loyaltyShopService.getContext(false).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          return EMPTY;
        }
      })
    );

  }

}
