<div class="modal-header">
  <h4 class="modal-title">Voor wie gelden de instellingen?</h4>
</div>
<div class="modal-body">
  <app-admin-settings-selector [dividerKind]="1" [(modelWholesaler)]="uidNew.Wholesaler" [(modelBranch)]="uidNew.Branch"
  [(modelCustomer)]="uidNew.Customer" [(modelUserID)]="uidNew.UserID" (modelChange)="selectorChanged(uidNew)">
</app-admin-settings-selector>  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm(uidNew)">Selecteer</button>
  <button type="button" class="btn btn-warning" (click)="decline()">Annuleren</button>
</div>

