import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';

import { CallbackInfoProgress } from '../../_models/common/callback.info.progress';

@Component({
  selector: 'app-callback-info',
  templateUrl: './callback-info.component.html',
  styleUrls: ['./callback-info.component.scss']
})
export class CallbackInfoComponent implements OnInit, OnDestroy {
  title: string;
  description: string;
  progressMax: number;
  progressValue: number;
  currentActivity: string;
  enabled = false;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  public show(
    title: string,
    description: string,
    progressMax: number,
    progressValue: number,
    currentActivity: string,
    hideProgress?: boolean
  ): CallbackInfoProgress {
    const progress = new CallbackInfoProgress(title, description, currentActivity, progressMax, progressValue, this);
    progress.update();
    this.enabled = !hideProgress;
    this.cdr.detectChanges();
    return progress;
  }

  public update(p: CallbackInfoProgress) {
    this.title = p.title;
    this.description = p.description;
    this.progressMax = p.progressMax;
    this.progressValue = p.progressValue;
    this.currentActivity = p.currentActivity;
    this.cdr.detectChanges();
  }

  public complete() {
    this.enabled = false;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
