import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { CatalogPartPopupInfoKind } from '../../../_models/catalog/catalog-part-popup-info-kind';
import { HotkeyService } from '../../../_services/hotkey.service';


@Component({
  selector: 'app-catalog-part-detailed',
  templateUrl: './catalog-part-detailed.component.html',
  styleUrls: ['./catalog-part-detailed.component.scss']
})
export class CatalogPartDetailedComponent implements OnInit {
  catalogPartPopupInfoKind = CatalogPartPopupInfoKind;
  @Input() ctx: ContextCatalog;
  @Input() part: Part;
  @Input() partsDescription: string;
  @Input() odd: boolean;
  @Output() selected: EventEmitter<Part> = new EventEmitter();

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public hotkeyService: HotkeyService
  ) { }

  ngOnInit() {
    if (!this.part.hasOwnProperty('uncollapsed') && this.ctx.NotCollapsedByDefault) { this.part['uncollapsed'] = true; }
  }

}
