import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ContextGraphicParts } from '../../../_models/catalog/context.graphic.parts';
import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { StripMap } from '../../../_models/catalog/strip.map';
import { Part } from '../../../_models/catalog/part';
import { StripMapRect } from '../../../_models/catalog/strip.map.rect';
import { StripMapCoordinate } from '../../../_models/catalog/strip.map.coordinate';
import { Animations } from '../../../_animations/animations';
import { HotkeyService } from '../../../_services/hotkey.service';


@Component({
  selector: 'app-catalog-graphic-strip',
  templateUrl: './catalog-graphic-strip.component.html',
  styleUrls: ['./catalog-graphic-strip.component.scss'],
  animations: [Animations.fadeInOut]
})
export class CatalogGraphicStripComponent implements OnInit {
  @Input() ctx: ContextGraphicParts;
  highLightTiNumbers: string[] = [];

  currentStripWidth = 825;

  constructor(
    private catalogService: CatalogService,
    private hotkeyService: HotkeyService
  ) { }

  ngOnInit() {
  }

  getPartsForMap(ctx: ContextCatalog, map: StripMap): Part[] {
    const parts = this.catalogService.getPartsByUniqueIds(ctx.Parts, map.PartUniqueIDs);
    return parts.filter(part => {
      return this.catalogService.filterParts(ctx, part);
    });
  }

  isFiltered(ctx: ContextCatalog, map: StripMap): boolean {
    const parts = this.getPartsForMap(ctx, map);
    return parts.length === 0;
  }

  click(event: MouseEvent, ctx: ContextCatalog): void {
    ctx.partsFilter = [];
    this.catalogService.fillFilterParts(ctx);
  }

  clickMap(ctx: ContextCatalog, map: StripMap): void {
    if (ctx.partsFilter?.length && ctx.partsFilter.sort().join(',') === map.PartUniqueIDs.sort().join(',')) ctx.partsFilter = [];
    else ctx.partsFilter = map.PartUniqueIDs;
    this.catalogService.fillFilterParts(ctx);
  }

  getID(map): string {
    return 'mapid' + this.getCoords(map).replace(',', '_');
  }



  getCoords(map: StripMap): string {
    const coords = [];
    for (const coord of map.Coordinaten) {
      coords.push(coord.X + ',' + coord.Y);
    }
    return coords.join();
  }

  getScaled(value: number): number {
    return value * this.currentStripWidth / this.ctx.currentStrip.ImageWidth;
  }

  getGraphicPartDivStyle(ctx: ContextCatalog, map: StripMap): {} {
    if (map && map.Coordinaten) {
      const rect = this.getLeftTopRightBottomFromCoordinates(map.Coordinaten);
      const style = {
        position: 'absolute',
        left: this.getScaled(rect.x1) + 'px',
        top: this.getScaled(rect.y1) + 'px',
        width: this.getScaled(rect.x2 - rect.x1) + 'px',
        height: this.getScaled(rect.y2 - rect.y1) + 'px'
      };

      if (this.hotkeyService.debugMode || this.highLightTiNumbers.includes(map.TiNumber) || (ctx.partsFilter?.length && !this.isFiltered(ctx, map))) {
        style['z-index'] = '600';
        style['border'] = '1px dotted #ff0000';
        style['background-color'] = 'rgba(255,0,0,0.1)';
        style['cursor'] = 'pointer';
      } else {
        style['z-index'] = '500';
      }
      return style;
    }
  }

  // getGraphicPartDivFilterStyle(map: StripMap): {} {
  //   if (map && map.Coordinaten) {
  //     const rect = this.getLeftTopRightBottomFromCoordinates(map.Coordinaten);
  //     const style = {
  //       position: 'absolute',
  //       zOrder: '9999',
  //       left: this.getScaled(rect.x1) + 'px',
  //       top: this.getScaled(rect.y1) + 'px',
  //       width: this.getScaled(rect.x2 - rect.x1) + 'px',
  //       height: this.getScaled(rect.y2 - rect.y1) + 'px'
  //     };
  //     if (this.hotkeyService.debugMode || this.highLightTiNumbers.includes(map.TiNumber)) {
  //       style['background-color'] = 'rgba(255,0,0,0.1)';
  //     }
  //     return style;
  //   }
  // }

  mouseEnter(map: StripMap): void {
    this.highLightTiNumbers.push(map.TiNumber);
  }

  mouseLeave(map: StripMap): void {
    const index: number = this.highLightTiNumbers.indexOf(map.TiNumber);
    if (index !== -1) {
      this.highLightTiNumbers.splice(index, 1);
    }
  }

  getLeftTopRightBottomFromCoordinatesWithRect(rect: StripMapRect, coords: StripMapCoordinate[]): StripMapRect {
    for (const coord of coords) {
      if (rect.x1 === null || rect.x1 > coord.X) { rect.x1 = coord.X; }
      if (rect.x2 === null || rect.x2 < coord.X) { rect.x2 = coord.X; }
      if (rect.y1 === null || rect.y1 > coord.Y) { rect.y1 = coord.Y; }
      if (rect.y2 === null || rect.y2 < coord.Y) { rect.y2 = coord.Y; }
    }
    return rect;
  }

  getLeftTopRightBottomFromCoordinates(coords: StripMapCoordinate[]): StripMapRect {
    const rect = new StripMapRect(null, null, null, null);
    return this.getLeftTopRightBottomFromCoordinatesWithRect(rect, coords);
  }


}
