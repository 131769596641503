<div class="modal-body text-center">
  <h4>Wachtwoord vergeten?</h4>
  <div class="row">
    <div class="col-12 my-3">Vul hieronder uw bedrijfsnaam en eventueel uw e-mailadres in.</div>
    <div class="col-4 my-2">Bedrijfsnaam</div><div class="col-8 my-2"><input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="data[0]" placeholder="bedrijfsnaam"></div>
    <div class="col-4">E-mailadres</div><div class="col-8"><input type="text" class="form-control" [(ngModel)]="data[1]" placeholder="emailadres"></div>
</div>
  <div class="row mt-3">
      <div class="col-6 pe-1"><button type="button" class="btn btn-primary w-100" (click)="confirm()" [disabled]="!data[0]">Verzenden</button></div>
      <div class="col-6 ps-1"><button type="button" class="btn btn-warning w-100" (click)="decline()">Annuleren</button></div>
  </div>

</div>
