import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { ContextExternalFrame } from '../../_models/external-frame/context.external.frame';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-frame',
  templateUrl: './external-frame.component.html',
  styleUrls: ['./external-frame.component.scss']
})
export class ExternalFrameComponent implements OnInit {
  ctx: ContextExternalFrame;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
      });
  }


}
