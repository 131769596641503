<app-container [renderCheck]="ctx" [shopSoort]="shopSoort.Catalogus">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [routerLink]="['/catalog/groups', ctx.CarType.Id]">Groepenoverzicht</li>
    <li appBreadcrumbHeaderItem [active]="true">{{ctx.Context[ctx.currentStrip.StripId].Category.CategoryName}}</li>
  </app-breadcrumb-header>

  <app-car-type-info-header [ctx]="ctx"></app-car-type-info-header>

  <app-side-panel-and-content>
    <ng-container side-panel>
      <app-side-panel *ngIf="ctx.GraphicParts.length>1">
        <app-side-panel-header [cssClass]="' text-bg-danger'"><i class="far fa-images"></i> Systeem keuze</app-side-panel-header>
        <app-side-panel-select [descriptions]="strips" [(selected)]="ctx.currentStrip"></app-side-panel-select>
      </app-side-panel>
      <app-side-panel-catalog [ctx]="ctx.Context[ctx.currentStrip.StripId]"></app-side-panel-catalog>
    </ng-container>
    <ng-container content>
      <app-side-panel>
        <app-side-panel-header><i class="far fa-image"></i> {{catalogService.getAttributeString(ctx.currentStrip)}}
        </app-side-panel-header>
        <app-side-panel-body>
          <app-catalog-graphic-strip [ctx]="ctx"></app-catalog-graphic-strip>
        </app-side-panel-body>
      </app-side-panel>
      <app-catalog-part-list [ctx]="ctx.Context[ctx.currentStrip.StripId]" [maxItems]="settingsService.getSettingNumber('ShowCatalogPartsPerCount')"></app-catalog-part-list>
    </ng-container>
  </app-side-panel-and-content>

</app-container>