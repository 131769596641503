import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Animations } from '../../_animations/animations';

import { TimingObject } from '../../_models/common/timing.object';
import { ContextBase } from '../../_models/common/context.base';
import { MainService } from '../../_services/main.service';
import { CarTypeService } from '../../_services/car-type.service';


@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit {
  @Input() ctx: ContextBase;
  @Input() timing: TimingObject;
  @Input() preText: string;
  @Output() backClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() hideBackClick: boolean;
  @Input() withoutCarType: boolean;

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
  }

  showBackButton(): boolean {
    if (this.hideBackClick) { return false; }
    return this.backClick.observers.length > 0;
  }

  getTimingObject(): TimingObject {
    if (this.timing) { return this.timing; }
    if (this.ctx && this.ctx.Timing) { return this.ctx.Timing; }
    if (this.ctx && this.ctx['LoginTiming']) { return this.ctx['LoginTiming']; }
  }

}
