import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { Aanbieding } from '../../_models/promotions/aanbieding';
import { AdminPromotionService } from '../../_services/admin/admin-promotion.service';


@Injectable({
  providedIn: 'root'
})
export class AdminPromotionEditDataResolverService  {

  constructor(
    private adminPromotionService: AdminPromotionService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Aanbieding> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.adminPromotionService.getPromotionById(+id).pipe(
      take(1),
      mergeMap(promo => {
        if (promo) {
          return of(promo);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
