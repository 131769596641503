<div *ngIf="getCarType()" class="card mb-3">
  <div class="card-header text-bg-primary car-type-info-header">
    

    <div class="d-table w-100">
      <div class="d-table-row">
        <div class="d-table-cell">
          <img *ngIf="hasLogo()" class="small-logo text-bg-dark rounded p-1"
            [src]="carTypeService.getCarBrandLogoUrl(getCarType().BrandLogo,'40x40')" />
          <span class="align-middle big-info ps-3">{{carTypeService.getCarTypeDescription(getCarType())}}</span>
        </div>
        <div class="d-table-cell text-end small-info">
          <ul class="list-inline list-middot align-middle me-3">
            <li>{{catalogPropertyService.getPropertyValueString(getCarType()?.Properties, catalogPropertyService.propertyKindCatalog.EngineCode)}}</li>
            <li>{{carTypeService.getDuration(getCarType(),' t/m ','nu')}}</li>
            <li><span [innerHTML]="catalogPropertyService.getPropertyValueString(getCarType()?.Properties, catalogPropertyService.propertyKindCatalog.EngineCapacity) | safeHtml"></span></li>
            <li>{{catalogPropertyService.getPropertyValueString(getCarType()?.Properties, catalogPropertyService.propertyKindCatalog.Power)}}</li>
          </ul>
        </div>
        <div class="d-table-cell header-button text-center">
          <div class="collapse-button" [ngClass]="{'is-collapsed': collapsed}" (click)="doCollapse()"><i class="fas fa-chevron-up"></i></div>
        </div>
      </div>
    </div>


  </div>
  <div class="card-body car-type-info" [@slideInHorizontal]="collapsed">
    <app-car-type-info-block [carType]="getCarType()"></app-car-type-info-block>
  </div>
</div>
