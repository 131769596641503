<ng-template #customItemTemplate let-model="item" let-index="index">
  <span class="small itemnumber">{{model.ItemNumber}}</span><br />
  <span class="small description">{{model.Description}}</span>
</ng-template>
<app-admin-custom-control [label]="label" [centerLabel]="centerLabel" [labelCols]="labelCols"
  [dividerKind]="dividerKind">
    <div class="input-group">
      <input type="text" [appFocus]="focusInput" [typeahead]="adminService.searchItemNumber(start, searchInDescription)" [adaptivePosition]="true" [dropup]="dropup"
        [typeaheadItemTemplate]="customItemTemplate" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8" [id]="'input'+uniqueId"
        class="form-control" [disabled]="disabled" [(ngModel)]="start" (typeaheadOnSelect)="typeaheadOnSelect($event)"
        [typeaheadOptionField]="'ItemNumber'" [placeholder]="placeholder?placeholder:''" autocomplete="off">
      <ng-content></ng-content>
    </div>
  </app-admin-custom-control>
