import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopSoort } from '../../_models/common/shop.soort';
import { ContextLoyaltyShop } from '../../_models/loyalty-shop/context.loyalty.shop';
import { LoyaltyShopService } from '../../_services/loyalty-shop.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-loyalty-shop',
  templateUrl: './loyalty-shop.component.html',
  styleUrls: ['./loyalty-shop.component.scss']
})
export class LoyaltyShopComponent implements OnInit {
  shopKind = ShopSoort;
  ctx: ContextLoyaltyShop;
  currentCategoryId: number;

  constructor(
    private route: ActivatedRoute,
    public loyaltyShopService: LoyaltyShopService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
        const cats = this.loyaltyShopService.getCategories(true);
        if (cats && cats.length > 0) { this.currentCategoryId = cats[0].ID; }
      });
  }

}
