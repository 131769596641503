import { Pipe, PipeTransform } from '@angular/core';
import { ResponseItem } from '../../../../_models/item-info/response.item';

@Pipe({
  name: 'itemInfoFilter'
})
export class ItemInfoFilterPipe implements PipeTransform {

  getPropertyValue(object: any, property: string): any {
    if (!object) { return null; }
    const arr = property.split('.');
    while (arr.length && (object = object[arr.shift()])) { }
    return object;
  }

  getItemValue(object: any, property: string): any {
    let value = this.getPropertyValue(object, property);
    if (value && value.valueOf) { value = value.valueOf(); }
    return value;
  }

  // getEquality(item1: any, item2: any, field: string): number {
  //   let direction = 1;
  //   if (field.startsWith('-')) {
  //     direction = -1;
  //     field = field.substring(1);
  //   }
  //   const value1 = this.getItemValue(item1, field);
  //   const value2 = this.getItemValue(item2, field);
  //   if (value1 === null && value2 !== null) { return 1; }
  //   if (value1 !== null && value2 === null) { return -1; }
  //   if (value1 > value2) { return 1 * direction; }
  //   if (value1 < value2) { return -1 * direction; }
  //   return 0;
  // }


  transform(items: ResponseItem[], filter: { [key: string]: string }): ResponseItem[] {
    if (items && filter && Object.keys(filter).length > 0) {
      return items.filter(item => {
        let ok = true;
        Object.keys(filter).forEach(filterItem => {
          const checkValue = filter[filterItem];
          const value = this.getItemValue(item, filterItem).toString();
          if (value !== checkValue) {
            ok = false;
          };
        });
        return ok;
      });
    }
    return items;
  }

}
