import { Pipe, PipeTransform } from '@angular/core';
import { OnlineSession } from '../../../_models/admin/statistics/online.session';

@Pipe({
  name: 'sortSessions'
})
export class SortSessionsPipe implements PipeTransform {

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  transform(sessions: OnlineSession[], descending: boolean): OnlineSession[] {
    if (sessions) {
      return sessions.sort((s1, s2) => {
        return (this.getTime(s1.SessionStart) - this.getTime(s2.SessionStart)) * (descending ? -1 : 1);
      });
    }
    return sessions;
  }

}
