import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsBase } from '../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-admin-settings-common-settings-generic',
  templateUrl: './admin-settings-common-settings-generic.component.html',
  styleUrls: ['./admin-settings-common-settings-generic.component.scss']
})
export class AdminSettingsCommonSettingsGenericComponent implements OnInit {
  settings: SettingsBase;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        if (data.settings) this.settings = data.settings;
      });
  }

  isModal(): boolean {
    if (this.settings && this.settings['modal']) return true;
    return false;
  }


}
