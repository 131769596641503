<div *ngIf="ctx" class="container">

    <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
      <li *ngIf="itemInfoService._itemInfoRequest && itemInfoService._itemInfoRequest.FromShop !== 0 && itemInfoService._itemInfoRequest.FromShop !== shopKind.Artikelinformatie" appBreadcrumbHeaderItem [routerLink]="getBackUrl().routeCommands">{{shopService.getShopModuleName(mainService.ctxMain, itemInfoService._itemInfoRequest.FromShop)}}</li>
      <li appBreadcrumbHeaderItem [active]="true">{{shopService.getShopModuleName(mainService.ctxMain, shopKind.Artikelinformatie)}}</li>
    </app-breadcrumb-header>
  
    <app-side-panel-and-content>
      <ng-container side-panel>
        <app-side-panel-catalog [ctx]="ctx"></app-side-panel-catalog>
      </ng-container>
      <ng-container content>
        <app-catalog-part-list [ctx]="ctx" [maxItems]="settingsService.getSettingNumber('ShowCrossPartsPerCount')">
        </app-catalog-part-list>
      </ng-container>
    </app-side-panel-and-content>
  
  </div>
  