import { Component, OnInit, Input } from '@angular/core';

import { ShopService } from '../../../_services/shop.service';
import { PromotionService } from '../../../_services/promotion.service';
import { MainService } from '../../../_services/main.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { ContextMain } from '../../../_models/common/context.main';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { ShopRouteConfig } from '../../../_models/common/shop.route.config';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { GisModuleKind } from '../../../_models/customer-information/gis.module.kind';
import { ShopModule } from '../../../_models/common/shop.module';
import { CarTypeService } from '../../../_services/car-type.service';
import { ApiService } from '../../../_services/api.service';
import { take } from 'rxjs/operators';
import { ContextCustomerInformation } from '../../../_models/customer-information/context.customer.information';

@Component({
  selector: 'app-webshop-menu-static',
  templateUrl: './webshop-menu-static.component.html',
  styleUrls: ['./webshop-menu-static.component.scss']
})
export class WebshopMenuStaticComponent implements OnInit {
  menuItemsCount = 4;
  shopSoort = ShopSoort;
  gisModuleKind = GisModuleKind;
  ctxCustomerInformation: ContextCustomerInformation;
  @Input() ctxMain: ContextMain;
  @Input() ctxPromotions: ContextPromotions;

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public promotionService: PromotionService,
    public customerInformationService: CustomerInformationService,
    private carTypeService: CarTypeService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.customerInformationService.getContextCustomerInformation()
      .subscribe(ctx => this.ctxCustomerInformation = ctx);
  }

  getShopModules() {
    const allreadyInMenu = [ShopSoort.StartScherm, ShopSoort.Aanbiedingen, ShopSoort.Catalogus, ShopSoort.GarageInformatieSysteem, ShopSoort.Artikelinformatie, ShopSoort.Banden];
    const modules: ShopModule[] = [];
    this.ctxMain.ShopModules.forEach(module => {
      if (!allreadyInMenu.includes(module.ID)) {
        modules.push(module);
      }
    });
    return modules;
  }

  getFirstShopModules(count: number) {
    const modules = this.getShopModules();
    return modules.slice(0, count);
  }

  getNextShopModules(count: number) {
    const modules = this.getShopModules();
    return modules.slice(count);
  }

  startShop37(shop: ShopModule) {
    if (!shop.TabWindowId || !shop.TabWindowId.window) { shop.TabWindowId = window.open('about:blank', '_blank'); }
    const licensePlate = this.carTypeService.currentCarType?.LicensePlate.Bare;
    const kType = this.carTypeService.currentCarType?.Ktypes[0];

    return this.apiService.getContextThirdPartyFrame(ShopSoort.HaynesPro_Doyen, licensePlate, kType)
      .subscribe(ctx => {
        if (ctx) {
          shop.TabWindowId.location.href = ctx.Url;
          shop.TabWindowId.focus();
        }
      });
  }

}
