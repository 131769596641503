<app-admin-form [title]="'Bright instellingen'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">

  
  <app-admin-supplier-input [label]="'Inter-Tyre leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'InterTyre_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>

  <app-admin-supplier-input [label]="'Inter-Sprint leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'InterSprint_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'WereldBand leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'WereldBand_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Van den Ban leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'VanDenBan_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Deldo leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Deldo_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Toyo Tires leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'ToyoTires_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Reedijk Banden leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'ReedijkBanden_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'De Klok Banden leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'DeKlokBanden_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Donckers Banden leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Donckers_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  
  <app-admin-supplier-input [label]="'Alliance Automotive Trading leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'AllianceAutomotiveTrading_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Doyen leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Doyen_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Fource leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Fource_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Motorparts leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Motorparts_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Sinatec Trading leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Sinatec_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'TipPoint Trading leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'TipPoint_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Eurol leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Eurol_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Lindemann leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Lindemann_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Stahlie leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Stahlie_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Delta International / Draper Tools leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'DeltaDraper_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'All4Car leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'All4Car_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Rema TipTop leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'RemaTipTop_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Krautli leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Krautli_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-supplier-input [label]="'Airco Techniek leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Aircotechniek_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  
  <app-admin-supplier-input [label]="'GAIK leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-item-groups-input [label]="'GAIK artikelgroepen'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_ItemGroups').Value" [noneSelectionLabel]="'Geen artikelgroepen geselecteerd.'" [dropup]="true"></app-admin-item-groups-input>
  <app-admin-dropdown [label]="'GAIK artikel veld'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_ArticleField').Value" [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'Gaik_ArticleFieldKinds').Value" [modelEmptyDisplayValue]="'Niets geselecteerd'"></app-admin-dropdown>
  
  <app-admin-dropdown [label]="'Promolabel ResponseItem property'" [(model)]="adminSettingsService.getAdminSetting(settings,'BrightPromoLabelResponseItemProperty').Value" [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'BrightPromoLabelResponseItemPropertyKinds').Value" [modelEmptyDisplayValue]="'Niets geselecteerd'"></app-admin-dropdown>

  <app-admin-item-groups-input [label]="'Artikelgroepen voor supplier-stocks.csv'" [(model)]="adminSettingsService.getAdminSetting(settings,'BrightSupplierStocks_ItemGroups').Value" [noneSelectionLabel]="'Geen artikelgroepen geselecteerd.'" [dropup]="true"></app-admin-item-groups-input>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)"></app-admin-cancel-save-button>
</app-admin-form>
