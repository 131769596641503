<div class="container">
  <div class="row">
    <div class="col-lg-8 mt-3">
      <div class="row">
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Catalogus"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Banden" [ctxMain]="ctxMain"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.WielenEnBanden"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Toolbase"></app-star-module>
        </div>
      </div>
    </div>
    <div class="col-lg-4 px-2 mt-3">
      <app-star-licenseplate [licensePlate]="licensePlate" (LicensePlateChange)="licensePlate = $event" [ctxCarTypeSelection]="ctxCarTypeSelection"
        (carTypeSelected)="carTypeSelected($event)"></app-star-licenseplate>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-lg-8">
      <div class="row">
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Sinatec"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Autotools"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.KentekenPlaten"></app-star-module>
        </div>
        <div class="col px-2">
          <app-star-module [shopSoort]="shopSoort.Artikelinformatie" [ctxMain]="ctxMain"></app-star-module>
        </div>
      </div>
    </div>
    <div class="col-lg-4 px-2">

    </div>
  </div>
</div>

<div *ngIf="promoSets" class="container-fluid">
  <div id="promotionsCarousel" class="carousel slide">
    <div class="carousel-inner">
      <div *ngFor="let set of promoSets; let first = first;" class="carousel-item" [ngClass]="{'active': first}">

        <div class="container">
          <div class="row my-3 py-3">
            <div class="col px-2">
              <app-star-promotion [promotion]="set[0]" [cartItems]="ctxPromotions.CartItems" [css]="'bg-danger'"></app-star-promotion>
            </div>
            <div class="col px-2">
              <app-star-promotion [promotion]="set[1]" [cartItems]="ctxPromotions.CartItems" [css]="'bg-info'"></app-star-promotion>
            </div>
          </div>
        </div>

      </div>      
    </div>
    <a *ngIf="promoSets.length > 1" class="carousel-control-prev" href="#promotionsCarousel" role="button" data-slide="prev">
      <span class="text-secondary"><i class="fas fa-angle-left"></i></span>
      <span class="sr-only">Previous</span>
    </a>
    <a *ngIf="promoSets.length > 1" class="carousel-control-next" href="#promotionsCarousel" role="button" data-slide="next">
      <span class="text-secondary"><i class="fas fa-angle-right"></i></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>