<div *ngIf="promotion" class="p-3">
    <div class="card" [ngStyle]="getContainerHeightStyle()">
        <div #containerHeader class="card-header text-bg-primary"
            (contextmenu)="mainService.showObject($event, promotion)">{{promotion.Titel}}</div>
        <div class="card-body card-body-promotion py-0 px-2">
            <div class="row">
                <div class="col col-md-5">
                    <div class="row">
                        <div class="col py-2" [ngStyle]="getHeightStyle(.6)" #description>
                            <div class="scrollable" [ngStyle]="getHeightStyleByContainer(description)"
                                [innerHTML]="mainService.getSafeHtml(promotion.Omschrijving)"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col py-2" [ngStyle]="getHeightStyle(.4)" #items>
                            <div class="scrollable items" [ngStyle]="getHeightStyleByContainer(items)">
                                <table class="table table-condensed table-fix-striped">
                                    <tbody>
                                        <tr *ngFor="let item of promotion.Artikelen">
                                            <td>
                                                <app-item-count
                                                    [sci]="promotionService.getCartItemByItemId(ctx.CartItems,item.ID)" [center]="true"></app-item-count>
                                            </td>
                                            <td>
                                                <strong>{{item.Artikelnr}}</strong>
                                                <br /> {{item.Omschrijving | lowercase}}
                                            </td>
                                            <td>
                                                <app-item-info-template
                                                    [sci]="promotionService.getCartItemByItemId(ctx.CartItems,item.ID)"
                                                    [nettoprijs]="ctx.Nettoprijs"></app-item-info-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-7">
                    <div class="row">
                        <div class="col py-2 image-container" [ngStyle]="getImageRowHeightStyle()" #imageContainer>
                            <img *ngFor="let image of promotion.Images; let index = index;" class="img-fluid zoom-cursor"
                                [ngStyle]="getImageRowHeightStyle()"
                                [src]="image.AfbeeldingsUrl"
                                [@fadeInOut]="promotionService.getCurrentImage(promotion) === index" 
                                (click)="showImage(promotion, image)" />
                        </div>
                    </div>
                    <div *ngIf="promotion.Images.length > 1" class="row">
                        <div class="col py-2" [ngStyle]="getThumnailRowHeightStyle()">
                            <div class="thumbnail-container">
                                <img *ngFor="let image of promotion.Images; let index = index;"
                                    (click)="promotionService.setCurrentImage(promotion, index)"
                                    class="img-thumbnail m-1"
                                    [src]="image.AfbeeldingsUrl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div #containerFooter class="card-footer">
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary float-end"
                        (click)="promotionService.gotoPromotionsOverview()">Terug</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-image-viewer [images]="images" [show]="showImageModal" (close)="closeImage()"></app-image-viewer>
