import { ShoppingCartItemTekstregels } from './shopping.cart.item.tekstregels';
import { ResponseItem } from '../item-info/response.item';
import { Subscription } from 'rxjs';

export class ShoppingCartItem {
    ID: number;
    Grossier: number;
    Klantnr: number;
    UserID: number;
    BelongsToCompositeItem: string;
    Autotype: string;
    Herkomst: number;
    SyncID: string;
    ItemInfo?: ResponseItem | null;
    // Toegevoegd: Date;
    // Gewijzigd: Date;
    Artikelnr: string;
    Artikelgroep: number;
    InternArtikelnr: number;
    Omschrijving: string;
    Nettoprijs: number;
    Brutoprijs: number;
    Punten: number;
    VastePrijs: number;
    Aantal: number;
    Kenteken: string;
    KentekenCoded: string;
    Tekstregels: ShoppingCartItemTekstregels;
    TimeoutID: number;
    TimeoutItemCount: number;
    CartChangeSubscription: Subscription;
    CompositeItemId: string;
    CompositeItemContent: ShoppingCartItem[];

    static getCloneWithoutItemInfo(sci: ShoppingCartItem) {
        if (!sci) return null;
        const clone = new ShoppingCartItem();
        clone.ID = sci.ID;
        clone.Grossier = sci.Grossier;
        clone.Klantnr = sci.Klantnr;
        clone.UserID = sci.UserID;
        clone.BelongsToCompositeItem = sci.BelongsToCompositeItem;
        clone.Autotype = sci.Autotype;
        clone.Herkomst = sci.Herkomst;
        clone.SyncID = sci.SyncID;
        // clone.Toegevoegd = sci.Toegevoegd;
        // clone.Gewijzigd = sci.Gewijzigd;
        clone.Artikelnr = sci.Artikelnr;
        clone.Artikelgroep = sci.Artikelgroep;
        clone.InternArtikelnr = sci.InternArtikelnr;
        clone.Omschrijving = sci.Omschrijving;
        clone.Nettoprijs = sci.Nettoprijs;
        clone.Brutoprijs = sci.Brutoprijs;
        clone.Punten = sci.Punten;
        clone.VastePrijs = sci.VastePrijs;
        clone.Aantal = sci.Aantal;
        clone.Kenteken = sci.Kenteken;
        clone.KentekenCoded = sci.KentekenCoded;
        clone.Tekstregels = sci.Tekstregels;
        clone.TimeoutID = sci.TimeoutID;
        clone.TimeoutItemCount = sci.TimeoutItemCount;
        clone.CartChangeSubscription = sci.CartChangeSubscription;
        clone.CompositeItemId = sci.CompositeItemId;
        clone.CompositeItemContent = sci.CompositeItemContent;
        return clone;
    }
}
