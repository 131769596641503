<app-admin-form *ngIf="settings" [title]="'Inleren catalogus'" [object]="settings">
  <app-admin-dropdown [label]="'Data providers voor de catalogus'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_CatalogKinds_Catalogus').Value"
    [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'CatalogKinds_Providers').Value"
    [modelEmptyDisplayValue]="'Niets geselecteerd'" [multiSelect]="true">
  </app-admin-dropdown>
  <app-admin-dropdown [label]="'Data providers voor het crossen'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_CatalogKinds_Artikelinformatie').Value"
    [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'CatalogKinds_Providers').Value"
    [modelEmptyDisplayValue]="'Niets geselecteerd'" [multiSelect]="true">
  </app-admin-dropdown>
  <app-admin-dropdown [label]="'Gebruik groepen layout van'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_GroupLayoutProvider').Value"
    [modelValues]="[0].concat(adminSettingsService.getAdminSetting(settings,'Catalog_CatalogKinds_Catalogus').Value)"
    [modelDisplayValues]="getGroupLayoutPossibilities()">
  </app-admin-dropdown>
  <app-admin-action-button [label]="'Inleren'" (clickAction)="adminService.learnCatalog()"><i
      class="fad fa-head-side-brain"></i> Start inleren catalogus</app-admin-action-button>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>