import { AddressModel } from "./address.model";
import { CapabilityKind } from "./capability.kind";

export class InterfaceData {
  Capabilities: CapabilityKind;
  Wholesaler: number;
  Branch: number;
  IntType: number;
  Supplier: number;
  Url: string;
  Username: string;
  Password: string;
  CustomerCode: string;
  SupplierCode: string;
  SupplierAddress: AddressModel;
  LinkedInterfaceData: { [key: number]: InterfaceData; };

  static setFromA2B(interfaceDataA: InterfaceData, interfaceDataB: InterfaceData) {
    interfaceDataB.IntType = interfaceDataA.IntType;
    interfaceDataB.Capabilities = interfaceDataA.Capabilities;
    interfaceDataB.Wholesaler = interfaceDataA.Wholesaler;
    interfaceDataB.Branch = interfaceDataA.Branch;
    interfaceDataB.Supplier = interfaceDataA.Supplier;
    interfaceDataB.Url = interfaceDataA.Url;
    interfaceDataB.Username = interfaceDataA.Username;
    interfaceDataB.Password = interfaceDataA.Password;
    interfaceDataB.CustomerCode = interfaceDataA.CustomerCode;
    interfaceDataB.SupplierCode = interfaceDataA.SupplierCode;
    interfaceDataB.SupplierAddress = interfaceDataA.SupplierAddress;
    interfaceDataB.LinkedInterfaceData = interfaceDataB.LinkedInterfaceData;
  }
}