export enum AdminSettingKind {
  Input = 0,
  Checkbox = 1,
  CheckboxInput = 2,
  NumberInput = 3,
  DateTimeInput = 4,

  CustomerInput = 101,
  SupplierInput = 102,
  ItemInput=103,
  ItemGroupInput=104,
  

  ListOf = 1002,
  Json = 1003,
  Document = 1004
}
