import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CustomerInformationService } from '../../../_services/customer-information.service';
import { GisModuleSettings } from '../../../_models/customer-information/gis.module.settings';
import { GisModuleKind } from '../../../_models/customer-information/gis.module.kind';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-customer-information-menu',
  templateUrl: './customer-information-menu.component.html',
  styleUrls: ['./customer-information-menu.component.scss']
})
export class CustomerInformationMenuComponent implements OnInit {
  modules: GisModuleSettings[];

  constructor(
    public mainService: MainService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
    this.customerInformationService.getContextCustomerInformation()
      .subscribe(ctx => this.modules = this.customerInformationService.getModules(ctx));
  }

  gotoModule(module: GisModuleSettings): void {
    if (module.UnLocked) {
      switch (module.Kind) {

        case GisModuleKind.OrderHistory:
          this.customerInformationService.gotoOrderHistory();
          break;
        case GisModuleKind.OpenOrders:
          this.customerInformationService.gotoOpenOrders();
          break;
        case GisModuleKind.DeliveryNotes:
          this.customerInformationService.gotoDeliveryNotes();
          break;
        case GisModuleKind.Invoices:
          this.customerInformationService.gotoInvoices();
          break;
        case GisModuleKind.OutstandingItems:
          this.customerInformationService.gotoOutstandingItems();
          break;
        case GisModuleKind.ProductReturns:
          this.customerInformationService.gotoReturns();
          break;
        case GisModuleKind.ProductWarranty:
          this.customerInformationService.gotoWarranty();
          break;
      }
    }
  }

  clickModule(module: GisModuleSettings): void {
    if (module.UnLocked || !module.PasswordNeeded) {
      this.gotoModule(module);
    } else {
      this.lockUnlockModule(module)
        .subscribe(m => {
          if (m && m.UnLocked) { this.gotoModule(m); }
        });
    }
  }

  clickLock(module: GisModuleSettings): void {
    this.lockUnlockModule(module)
      .subscribe(m => {
        if (m && m.UnLocked) { this.gotoModule(m); }
      });
  }

  lockUnlockModule(module: GisModuleSettings): Observable<GisModuleSettings> {
    return this.getPassword(module.UnLocked)
      .pipe(mergeMap(password => {
        this.customerInformationService.setPassword(password);
        this.customerInformationService.ctx = null;
        return this.customerInformationService.getContextCustomerInformation()
          .pipe(mergeMap(ctx => {
            this.modules = this.customerInformationService.getModules(ctx);
            const newModule = this.modules.find(m => module.Kind === m.Kind);
            if (!module.UnLocked && newModule && !newModule.UnLocked) {
              this.mainService.msgBox(
                'Let op!', `Het wachtwoord voor '${this.customerInformationService.getTitleHtml(module.Kind)}' is onjuist.`
              );
            }
            return of(newModule);
          }));
      }));
  }

  getPassword(unlocked: boolean): Observable<string> {
    if (!unlocked) {
      return this.mainService.inputBox('Wachtwoord', this.customerInformationService.password, true);
    } else {
      return of('');
    }
  }

}
