<div class="modal-header center">
  <h3 class="modal-title">Automatisch vullen van de groepen voor</h3>
</div>
<div class="modal-body textbg-light">
  <app-admin-checkbox *ngFor="let catInfo of getCatalogKindInfos()" [labelCols]="5" [dividerKind]="1"
    [label]="catInfo.CatalogKindString" [(model)]="data.catalogInfo[catInfo.CatalogKind]"></app-admin-checkbox>
  <app-admin-divider [dividerKind]="3"></app-admin-divider>
  <app-admin-checkbox [labelCols]="5" [dividerKind]="1" [label]="'Wis alle groepen van tevoren'" [(model)]="data.clear">
  </app-admin-checkbox>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i> Ok</button>
</div>
