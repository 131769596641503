import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-before-unload',
  templateUrl: './before-unload.component.html',
  styleUrls: ['./before-unload.component.scss']
})
export class BeforeUnloadComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    if (false) { $event.returnValue = ''; }
  }
  constructor() { }

  ngOnInit() {
  }

}
