import { Pipe, PipeTransform } from '@angular/core';
import { CatalogLayoutBaseObject } from '../../../../_models/admin/settings/catalog.layout.base.object';
import { CatalogLayoutEditStatus } from '../../../../_models/admin/settings/catalog.layout.edit.status';

@Pipe({
  name: 'adminCatalogLayoutStatusFilter',
  pure: false
})
export class AdminCatalogLayoutStatusFilterPipe implements PipeTransform {

  transform(objects: CatalogLayoutBaseObject[]): any {
    if (objects) {
      return objects.filter(object => object.Status !== CatalogLayoutEditStatus.Deleted);
    }
    return objects;
  }

}
