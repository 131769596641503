import { MonthYear } from './month.year';
import { CarBrand } from './car.brand';

export class CarModel {
    Origin: number;
    ModelId: string;
    ModelName: string;
    ModelRemark: string;
    BodyType: string;
    AvailableFrom: MonthYear;
    AvailableUntil: MonthYear;
    Brand: CarBrand;
}
