export class DateChoice {
  Description: string;
  DateFrom: Date;
  DateTo: Date;
  DisableControls: boolean = true;

  constructor(description: string, dateFrom: Date, dateTo: Date) {
    this.Description = description;
    this.DateFrom = dateFrom;
    this.DateTo = dateTo;
  }
}
