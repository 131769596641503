<div class="modal-header center">
  <h3 class="modal-title">Lever Keuze</h3>
</div>
<div class="modal-body text-bg-light">
  <app-admin-input [labelCols]="3" [dividerKind]="1" [label]="'Omschrijving'" [(model)]="choice.ChoiceDescription" [focusInput]="focusInput"></app-admin-input>
  <app-admin-custom-control [labelCols]="3" [dividerKind]="1" [label]="'Laat zien van'" [centerLabel]="true">
    <div class="row">
      <div class="col-sm-4">
        <timepicker [showMeridian]="false" [disabled]="always" [(ngModel)]="choice.ValidFrom"></timepicker>
      </div>
      <div class="col-sm-1 my-auto text-center p-0">tot</div>
      <div class="col-sm-4">
        <timepicker [showMeridian]="false" [disabled]="always" [(ngModel)]="choice.ValidThru"></timepicker>
      </div>
      <div class="col-sm-3 my-auto ps-2">
        <div class="form-group form-check m-0">
          <input type="checkbox" class="form-check-input checkbox-large fix-checkbox" id="check1" [(ngModel)]="always">
          <label class="form-check-label ms-2" for="check1">Altijd</label>
        </div>
      </div>
    </div>
  </app-admin-custom-control>
  <app-admin-dropdown [labelCols]="3" [dividerKind]="1" [label]="'Laat zien bij'" [(model)]="choice.DeliveryMethod"
    [modelValues]="[0,1,-1]" [modelDisplayValues]="{'0':'Afhalen','1':'Bezorgen','-1':'Afhalen + Bezorgen'}"></app-admin-dropdown>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i> Opslaan</button>
</div>
