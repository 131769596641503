import { Pipe, PipeTransform } from '@angular/core';

import { PartsType } from '../../../_models/catalog/parts.type';
import { SettingsCatalogPartsTypes } from '../../../_models/admin/settings/settings.catalog.parts.types';

@Pipe({
  name: 'filterCrossedPartsTypes'
})
export class FilterCrossedPartsTypesPipe implements PipeTransform {

  transform(partsTypes: PartsType[], settings: SettingsCatalogPartsTypes, hideCrossed: boolean): PartsType[] {
    if (!(hideCrossed && settings && settings.LearnedPartsTypes)) { return partsTypes; }
    return partsTypes.filter(partsType => {
      let allCrossed = true;
      Object.keys(settings.LearnedPartsTypes).forEach(catalog => {
        if (!(settings.PartsTypeCross[partsType.ID] && settings.PartsTypeCross[partsType.ID][catalog]
          && settings.PartsTypeCross[partsType.ID][catalog].length > 0)) {
          allCrossed = false;
        }
      });
      return !allCrossed;
    });
  }

}
