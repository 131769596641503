<div class="modal-body">
  <span class="text-center"><h4>Account aanvragen</h4></span>
  <div class="row">
    <div class="col-12 my-3">Vul hieronder uw gegevens in en wij nemen uw aanvraag in behandeling.</div>
    <div class="col-4 my-2">Bedrijfsnaam</div><div class="col-8 my-2"><input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="request.CompanyName" placeholder="bedrijfsnaam"></div>
    <div class="col-4 mb-2">KvK nummer</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.KvK" placeholder="kvk"></div>
    <div class="col-4 mb-2">Voornaam</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.Frontname" placeholder="voornaam"></div>
    <div class="col-4 mb-2">Achternaam</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.Lastname" placeholder="achternaam"></div>
    <div class="col-4 mb-2">Straat</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.StreetName" placeholder="straat"></div>
    <div class="col-4 mb-2">Huisnummer</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.HouseNumber" placeholder="huisnummer"></div>
    <div class="col-4 mb-2">Postcode</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.PostalCode" placeholder="postcode"></div>
    <div class="col-4 mb-2">Woonplaats</div><div class="col-8 mb-2"><input type="text"class="form-control" [(ngModel)]="request.City" placeholder="woonplaats"></div>
    <div class="col-4 mb-2">E-mailadres</div><div class="col-8 mb-2"><input type="text" class="form-control" [(ngModel)]="request.EmailAddress" placeholder="emailadres"></div>
    <div class="col-4 my-2">Telefoonnummer</div><div class="col-8 mb-2"><input type="text" class="form-control" [(ngModel)]="request.PhoneNumber" placeholder="telefoonnummer"></div>
    <div class="col-12 mt-3"><div class="checkbox"><label><input name="remember" type="checkbox" [(ngModel)]="request.KnownCustomer"> Ik ben een bestaande klant.</label></div></div>

</div>
  <div class="row mt-3">
      <div class="col-6 pe-1"><button type="button" class="btn btn-primary w-100" (click)="confirm()" [disabled]="!request.CompanyName||!request.PhoneNumber">Aanvragen</button></div>
      <div class="col-6 ps-1"><button type="button" class="btn btn-warning w-100" (click)="decline()">Annuleren</button></div>
  </div>

</div>
