import { Component, OnInit } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';
defineLocale('nl', nlLocale);

import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.scss']
})
export class DeliveryNotesComponent implements OnInit {

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }
}
