<app-admin-form *ngIf="item" [title]="'Artikel onderhoud'" [object]="item">
  <app-admin-input [label]="'Titel'" [(model)]="item.Title"></app-admin-input>
  <app-admin-checkbox [label]="'Aktief'" [(model)]="item.Active"></app-admin-checkbox>
  <app-admin-editor [label]="'Omschrijving'" [(model)]="item.Description"></app-admin-editor>
  <app-admin-editor [label]="'Uitgebreide omschrijving'" [(model)]="item.DescriptionExtended"></app-admin-editor>
  <app-admin-typeahead-item [label]="'Artikel'" [(model)]="_taItem"></app-admin-typeahead-item>
  <app-admin-number-input [label]="'Benodigde punten'" [(model)]="item.Credits"></app-admin-number-input>
  <app-admin-number-input [label]="'Eventueel bijbetalen'" [(model)]="item.Price" [decimals]="2"></app-admin-number-input>
  <app-admin-dropdown [label]="'Categorieën'" [(model)]="itemCategories" [modelValues]="categories"
    [modelValueProperty]="'ID'" [modelDisplayProperty]="'Description'" [modelEmptyDisplayValue]="'Geen categorieën geselecteerd'" [multiSelect]="true">
  </app-admin-dropdown>
  <app-admin-custom-control [label]="'Afbeeldingen'">
    <app-upload [description]="'Zoek nieuwe afbeeldingen, of sleep ze in het vak hierboven.'" [accept]="'image/*'"
      (FileDrop)="upload($event)">
      <div class="image-thumbs-container">
        <div class="grow" *ngFor="let image of item.Images">
          <img style="height: 75px;" [src]="image.Url" />
          <span class="image-delete" (click)="adminLoyaltyShopService.deleteLoyaltyShopItemImage(item,image)"><i
              class="fas fa-trash-alt"></i></span>
        </div>
      </div>
    </app-upload>
  </app-admin-custom-control>
  <app-admin-cancel-save-button [saveAction]="adminLoyaltyShopService.saveLoyaltyShopItemDialog(item, itemCategories)"
    (cancelAction)="cancelItem()"></app-admin-cancel-save-button>
</app-admin-form>