<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="request" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true">Openstaande Posten</li>
    </app-breadcrumb-header>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="getBills()"></app-extra-password-webshop>
    </div>

    <div class="row" *ngIf="!customerInformationService.showPasswordPrompt">
      <div class="col-3">

        <div class="btn-group-vertical btn-group-toggle w-100 mb-3" data-bs-toggle="buttons">
          <label class="btn btn-outline-dark" [ngClass]="{'active':request.RequestType === 1}"
            (click)="setRequestType(1)">
            <input type="radio" name="options" id="option1" autocomplete="off"> Openstaande posten
          </label>
          <label class="btn btn-outline-dark" [ngClass]="{'active':request.RequestType === 2}"
            (click)="setRequestType(2)">
            <input type="radio" name="options" id="option2" autocomplete="off"> Vervallen posten
          </label>
          <label class="btn btn-outline-dark" [ngClass]="{'active':request.RequestType === 0}"
            (click)="setRequestType(0)">
            <input type="radio" name="options" id="option3" autocomplete="off"> Alle posten
          </label>
        </div>

        <div class="card mb-3 border flexbox-noshrink">
          <div class="card-header card-header-compact  text-bg-dark">
            <span>Totalen</span>
          </div>
          <div class="card-body p-2">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <label class="col-7 col-form-label">Factuurbedrag</label>
                  <div class="col-5 col-form-label text-end">
                    {{totaalFactuurbedrag | amount}}
                  </div>
                </div>
                <div class="row">
                  <label class="col-7 col-form-label">Betaald</label>
                  <div class="col-5 col-form-label text-end">
                    {{totaalBetaald | amount}}
                  </div>
                </div>
                <div class="row">
                  <label class="col-7 col-form-label">Nog te betalen</label>
                  <div class="col-5 col-form-label text-end">
                    {{totaalNogTeBetalen | amount}}
                  </div>
                </div>
                <div class="row">
                  <label class="col-7 col-form-label">Niet vervallen</label>
                  <div class="col-5 col-form-label text-end">
                    {{totaalNietVervallen | amount}}
                  </div>
                </div>
                <div class="row">
                  <label class="col-7 col-form-label">Vervallen</label>
                  <div class="col-5 col-form-label text-end">
                    {{totaalVervallen | amount}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-9">

        <app-grid-webshop [items]="sortService.sortObject('outstandingitems', headers)"
          emptyText="Geen openstaande posten gevonden." class="mt-3" [isBusy]="isBusy">
          <ng-container grid-header>
            <div class="col-2">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'InvoiceNumber'">Factuurnr
              </app-sort-field>
            </div>
            <div class="col-2">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'InvoiceDate'">Factuurdatum
              </app-sort-field>
            </div>
            <div class="col-1 text-end">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'IsDue'">Vervallen</app-sort-field>
            </div>
            <div class="col-2 text-end">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'AmountTotal'">Bedrag</app-sort-field>
            </div>
            <div class="col-2 text-end">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'AmountPaid'">Betaald</app-sort-field>
            </div>
            <div class="col-2 text-end">
              <app-sort-field [sortObjectId]="'outstandingitems'" [sortField]="'AmountOutstanding'">Openstaand
              </app-sort-field>
            </div>
            <div class="col-1 text-center text-nowrap">PDF</div>
          </ng-container>
          <ng-template let-item>
            <div class="col-2">{{item.InvoiceNumber | leadZeros:8}}</div>
            <div class="col-2">{{item.InvoiceDate | date : 'dd-MM-yyyy'}}</div>
            <div class="col-1 text-end">{{item.IsDue ? 'Ja' : 'Nee'}}</div>
            <div class="col-2 text-end">{{item.AmountTotal | amount}}</div>
            <div class="col-2 text-end">{{item.AmountPaid | amount}}</div>
            <div class="col-2 text-end">{{item.AmountOutstanding | amount}}</div>
            <div class="col-1 text-center">
              <span *ngIf="customerInformationService.containsPdf(item)" class="text-danger pointer" (click)="customerInformationService.showInvoicePdf(item.InvoiceNumber)"><i
                  class="fas fa-file-pdf"></i></span>
            </div>
          </ng-template>
        </app-grid-webshop>

      </div>
    </div>

  </div>
</div>