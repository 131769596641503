<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container my-3">
    
    <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopKind.Bonusshop"></li>
    </app-breadcrumb-header>

    <div class="row">
      <div class="col-lg-3">
        <div class="list-group">
          <button *ngFor="let category of loyaltyShopService.getCategories(true)" type="button"
            (click)="currentCategoryId = category.ID" class="list-group-item list-group-item-action"
            [ngClass]="{'active': category.ID === currentCategoryId}">
            {{category.Description}}
          </button>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="row">
          <div *ngFor="let item of loyaltyShopService.getItems(currentCategoryId,true)" class="col-md-6">
            <app-loyalty-shop-item [item]="item"></app-loyalty-shop-item>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>