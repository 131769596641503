import { Injectable } from '@angular/core';
import { LicensePlateReason } from '../_models/license-plates/license.plate.reason';
import { ApiService } from './api.service';
import { LicensePlateTypeRequest } from '../_models/license-plates/license.plate.type.request';
import { LicensePlateType } from '../_models/license-plates/license.plate.type';
import { LicensePlateRequest } from '../_models/license-plates/license.plate.request';
import { LicensePlateResponse } from '../_models/license-plates/license.plate.response';
import { CartService } from './cart.service';
import { MainService } from './main.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicensePlateService {
  imageUrlCache: { [key: string]: string } = {};
  reasons: LicensePlateReason[];
  licensePlateTypes: LicensePlateType[];
  licensePlateResponse: LicensePlateResponse;

  constructor(
    private apiService: ApiService,
    private cartService: CartService,
    private mainService: MainService) {
    this.reasons = this.getReasons();
  }

  clear(): void {
    this.imageUrlCache = {};
  }

  getLicensePlateUrl(licensePlate: string): Observable<string> {
    if (this.imageUrlCache[licensePlate]) {
      return of(this.imageUrlCache[licensePlate]);
    } else {
      return this.apiService.getLicensePlateImageUrl(licensePlate)
        .pipe(mergeMap(url => {
          this.imageUrlCache[licensePlate] = url;
          return of(url);
        }));
    }
  }

  getReasons(): LicensePlateReason[] {

    const reasons = new Array<LicensePlateReason>();
    reasons.push({ ReasonId: 0, Description: "Eerste aanschaf" });
    reasons.push({ ReasonId: 1, Description: "Vermissing / diefstal" });
    reasons.push({ ReasonId: 2, Description: "Omwisseling" });
    // reasons.push({ ReasonId: 3, Description: "Aanhanger/caravan/fietsendrager (wit)" });

    return reasons;
  }

  getLicensePlateTypes(onlyTrailerPlates: boolean) {
    const request = new LicensePlateTypeRequest();
    request.onlyTrailerPlates = onlyTrailerPlates;
    this.apiService.getLicensePlateTypes(request).subscribe(result => {
      this.licensePlateTypes = result;
    });
  }

  orderLicensePlate(request: LicensePlateRequest): Observable<LicensePlateResponse> {
    this.licensePlateResponse = null;
    return this.apiService.orderLicensePlate(request).pipe(
      mergeMap(result => {
        this.licensePlateResponse = result;
        if (this.licensePlateResponse.Success) {
          console.info("orderLicensePlate: cart bijwerken");
          this.cartService.updateCart(false);
          return of(result);
        }
        return of(result);
      }));
  }

  resetAlles() {

  }
}
