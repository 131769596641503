import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemGroup } from '../../../_models/common/item.group';
import { AdminService } from '../../../_services/admin/admin.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-admin-item-groups-input',
  templateUrl: './admin-item-groups-input.component.html',
  styleUrls: ['./admin-item-groups-input.component.scss']
})
export class AdminItemGroupsInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: number[];
  @Output() modelChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() modelObjects: ItemGroup[] = [];
  @Output() modelObjectsChange: EventEmitter<ItemGroup[]> = new EventEmitter<ItemGroup[]>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() stopSearchingOnBlur: boolean;
  @Input() dropup: boolean = false;
  @Input() start: string;
  @Input() sortEnabled: boolean;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (!this.noneSelectionModelValue) { this.noneSelectionModelValue = 0; }
    if (this.model.length) this.getObjectModels(this.model);
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModels(model: number[]) {
    let changed = false;
    const added: number[] = [];
    const count = this.modelObjects.length;

    this.modelObjects = this.modelObjects.filter(i => !model.includes(i.ItemGroupId));
    if (this.modelObjects.length !== count) changed = true;

    model.forEach(id => {
      if (!this.modelObjects.find(i => i.ItemGroupId === id)) {
        added.push(id);
      }
    });

    if (added.length) {
      this.adminService.getItemGroups(this.adminService.currentUid.Wholesaler, added)
        .subscribe(result => {
          if (result) {
            this.modelObjects.push(...result);
            this.modelObjectsChange.emit(this.modelObjects);
          }
        });
    } else if (changed) this.modelObjectsChange.emit(this.modelObjects);
  }

  add(itemGroup: ItemGroup) {
    this.model.push(itemGroup.ItemGroupId);
    this.modelObjects.push(itemGroup);
    this.doSearch('');
  }

  doSearch(start: string = null) {
    setTimeout(() => {
      this.focusInput.emit(true);
      this.start = start;
    }, 0);
  }

  remove(event: MouseEvent, itemGroup: ItemGroup) {
    this.model = this.model.remove(itemGroup.ItemGroupId);
    this.modelObjects = this.modelObjects.filter(i => this.model.includes(i.ItemGroupId));
    this.modelChange.emit(this.model);
    this.modelObjectsChange.emit(this.modelObjects);
    event.stopPropagation();
    this.doSearch('');
  }

  id(index, item: ItemGroup) {
    return item.ItemGroupId;
  }

  miia(from: number, to: number) {
  moveItemInArray(this.modelObjects, from, to);
  moveItemInArray(this.model, from, to);
}



}
