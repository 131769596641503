import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { LoyaltyShopItem } from '../../../../_models/loyalty-shop/loyalty.shop.item';
import { LoyaltyShopService } from '../../../../_services/loyalty-shop.service';
import { UploadEvent } from '../../../../_common/upload/upload-event.model';
import { UploadFile } from '../../../../_common/upload/upload-file.model';
import { AdminLoyaltyShopService } from '../../../../_services/admin/admin-loyalty-shop.service';
import { TypeaheadItem } from '../../../../_models/typeahead/typeahead.item';
import { LoyaltyShopCategory } from '../../../../_models/loyalty-shop/loyalty.shop.category';
import { LoyaltyShopData } from '../../../../_models/loyalty-shop/loyalty.shop.data';


@Component({
  selector: 'app-admin-loyalty-shop-item-edit',
  templateUrl: './admin-loyalty-shop-item-edit.component.html',
  styleUrls: ['./admin-loyalty-shop-item-edit.component.scss']
})
export class AdminLoyaltyShopItemEditComponent implements OnInit {
  _item: LoyaltyShopItem;
  _taItem: TypeaheadItem;
  get item(): LoyaltyShopItem {
    if (this._item && this._taItem) {
      this._item.InternalItemNumber = this._taItem.InternalItemNumber;
      this._item.ItemNumber = this._taItem.ItemNumber;
      this._item.ItemGroup = this._taItem.ItemGroup;
      this._item.ItemDescription = this._taItem.Description;
    }
    return this._item;
  }
  set item(value: LoyaltyShopItem) {
    this._item = value;
    this._taItem = new TypeaheadItem();
    this._taItem.InternalItemNumber = value.InternalItemNumber;
    this._taItem.ItemNumber = value.ItemNumber;
    this._taItem.ItemGroup = value.ItemGroup;
    this._taItem.Description = value.ItemDescription;
  }
  categories: LoyaltyShopCategory[];
  itemCategories: number[];
  savedForImages = false;
  uploadPercent: number;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public adminLoyaltyShopService: AdminLoyaltyShopService,
    public loyaltyShopService: LoyaltyShopService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.item = data.item;
        this.adminLoyaltyShopService.getCategories()
          .subscribe(cats => this.categories = cats);
        this.adminLoyaltyShopService.getCategoriesForItem(this.item)
          .subscribe(cats => this.itemCategories = cats.map(c=>c.ID));
      });
  }



  upload(event: UploadEvent) {
    const fileList: UploadFile[] = [];
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        fileList.push(droppedFile);
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.info(droppedFile.relativePath, fileEntry);
      }
    }
    this.saveIfNewItem(this.item)
      .subscribe(item => {
        if (item) {
          this.item = item;
          this.adminLoyaltyShopService.addLoyaltyShopItemImages(this.item.ID, fileList)
            .subscribe(progress => {
              if (progress.type === HttpEventType.UploadProgress) {
                this.uploadPercent = Math.round(100 * progress.loaded / progress.total);
              } else if (progress.type === HttpEventType.Response) {
                const updatedItem = progress.body as LoyaltyShopItem;
                this.item.Images = updatedItem.Images;
              }
            });
        }
      });
  }

  saveIfNewItem(item: LoyaltyShopItem): Observable<LoyaltyShopItem> {
    if (item.ID > 0) {
      this.savedForImages = false;
      return of(item);
    } else {
      return this.adminLoyaltyShopService.saveLoyaltyShopItem(item)
        .pipe(mergeMap(newItem => {
          if (newItem) {
            this.savedForImages = true;
            return of(newItem);
          }
        }));
    }
  }

  cancelItem() {
    if (this.savedForImages) {
      this.adminLoyaltyShopService.deleteLoyaltyShopItem(this.item.ID)
        .subscribe(ok => {
          if (ok) {
            this.savedForImages = false;
            this.router.navigate(['/admin/loyaltyshop/items']);
          }
        });
    } else {
      this.router.navigate(['/admin/loyaltyshop/items']);
    }
  }

}

