import { Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AdminSettingsExtraMessagesComponent } from '../../../admin/admin-settings/admin-settings-availability-and-price/admin-settings-extra-messages/admin-settings-extra-messages.component';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdminSettingsCommonSettingsGenericComponent } from '../../../admin/admin-settings/admin-settings-common-settings-generic/admin-settings-common-settings-generic.component';

@Component({
  selector: 'app-admin-modal-settings-wrapper',
  templateUrl: './admin-modal-settings-wrapper.component.html',
  styleUrls: ['./admin-modal-settings-wrapper.component.scss']
})
export class AdminModalSettingsWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  componentName: string;
  subscription: Subscription = new Subscription();
  private componentRef: ComponentRef<{}>;
  private componentsMapping = {
    adminSettingsCommonSettingsGenericComponent: AdminSettingsCommonSettingsGenericComponent,
    adminSettingsExtraMessagesComponent: AdminSettingsExtraMessagesComponent
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private adminService: AdminService,
    private adminSettingsService: AdminSettingsService,
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
    const componentPart = this.componentName.split('_')[0];
    const component = this.componentsMapping[componentPart];
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.container.createComponent(factory);
    this.subscription.add(this.adminSettingsService.getSettingsForRoute(this.componentName, this.adminService.currentUid)
      .pipe(take(1))
      .subscribe(settings => {
        if (settings) {
          const onClose = new Subject<boolean>();
          this.componentRef.instance['settings'] = settings;
          this.componentRef.instance['settings']['modal'] = onClose;
          this.subscription.add(onClose.pipe(take(1)).subscribe(ok => {
            const s = settings;
            this.componentRef.instance['settings']['modal'] = true;
            this.bsModalRef.hide();
          }));
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}