<div class="container-fluid">
  <div class="container mb-3">
    <div class="row pb-3">
      <div class="col-12">
        <div class="pointer text-low" (click)="cartService.changeNetPriceOption()">
          <span class="me-2">nettoprijs</span>
          <span *ngIf="!cartService.getNetPriceHidden()"><i class="fas fa-eye"></i></span>
          <span *ngIf="cartService.getNetPriceHidden()"><i class="fas fa-eye-slash"></i></span>
        </div>
      </div>
      <div class="col-4"><img src="/assets/img/identity/star/star_logo.png" (dblclick)="authService.gotoLogout()"
          (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)" />
      </div>
      <div class="col-8">
        <div class="back-header">
          <div class="header-col home-filiaal-col">
            <img class="home-image" src="/assets/img/identity/star/home.png" [routerLink]="['/start']" />
            <span class="icon-label">| {{ctx.CompanyInfo.BranchName}}</span>
          </div>
          <div *ngIf="shopService.containsShopModule(ctx, shopSoort.GarageInformatieSysteem)" class="header-col account-col" [routerLink]="['/details']">
            <img src="/assets/img/identity/star/account.png" />
            <span class="icon-label">mijn account</span>
          </div>
          <div class="header-col cart-col">
            <div class="cart-extra" *ngIf="true">
              <span [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fas fa-cog"></i></span><br />
              <!-- <span (click)="cartService.doCartSlide(ctx)">
                <span *ngIf="!ctx.ShowCartContent"><i class="fas fa-arrow-alt-circle-left"></i></span>
                <span *ngIf="ctx.ShowCartContent"><i class="fas fa-arrow-alt-circle-right"></i></span>
              </span><br /> -->
              <span (click)="authService.gotoLogout()"><i class="fas fa-sign-out-alt"></i></span>
            </div>
            <div class="cart-icon" [routerLink]="['/cart']">
              <div *ngIf="!cartService.isCartEmpty()" class="cart-count-container">
                <span class="badge text-bg-danger">{{cartService.getCartItemCount()}}</span>
              </div>
              <img class="cart-image" src="/assets/img/identity/star/cart.png" />
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-3 text-bg-primary">
  <div class="container py-3">
    <div class="row">
      <div class="col-6 naam text-white" title="Klantnr:{{ctx.CompanyInfo.CustomerNumber}} UserID:{{ctx.CompanyInfo.UserID}} Session:{{authService.currentLogon.Session}} Gebruiker:{{authService.currentLogon.Login}}">
        {{ctx.CompanyInfo.CustomerName}}
      </div>
      <div class="col-6 text-white">
        <div class="whatsapp">
          <div class="whatsapp-text">Niet gevonden wat u zocht?<br />Neem contact op via whatsapp:</div>
          <div class="whatsapp-icon mx-3">
            <img class="whatsapp-image" src="/assets/img/identity/star/whatsapp.png" />
          </div>
          <div class="whatsapp-nr">{{ctx.CompanyInfo.BranchWhatsApp}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
