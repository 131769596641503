import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { TypeaheadItem } from '../../_models/typeahead/typeahead.item';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-item-typeahead-search',
  templateUrl: './item-typeahead-search.component.html',
  styleUrls: ['./item-typeahead-search.component.scss']
})
export class ItemTypeaheadSearchComponent implements OnInit {
  @Input() label: string;
  @Input() start: string;
  @Output() startChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  uniqueId = Math.random().toString(36).substring(2);

  constructor(
    public apiService: ApiService
  ) { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
  }

  typeaheadOnSelect(item: TypeaheadMatch<TypeaheadItem>) {
    this.selected.emit(item);
  }

  reset() {
    this.start = null;
  }

  changeStart(event: string): void {
    this.startChange.emit(this.start);
  }

}
