import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UploadFile } from '../../_common/upload/upload-file.model';
import { ItemImageLink } from '../../_models/admin/item-images/item.image.link';
import { ItemImageItemNumber } from '../../_models/admin/item-images/item.image.item.number';
import { ItemImagesItemNumberData } from '../../_models/admin/item-images/item.images.item.number.data';
import { ApiService } from '../api.service';
import { MainService } from '../main.service';
import { AdminService } from './admin.service';
import { ItemGroupImagesData } from '../../_models/admin/item-images/item.group.images.data';
import { ItemImageItemGroup } from '../../_models/admin/item-images/item.image.item.group';
import { ItemImagesItemGroupData } from '../../_models/admin/item-images/item.images.item.group.data';

@Injectable({
  providedIn: 'root'
})
export class AdminItemImagesService {

  constructor(
    private apiService: ApiService,
    private adminService: AdminService,
    private mainService: MainService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  getItemImagesItemNumberData(filter: string = '', pageNumber: number = 1, pageSize: number = 5): Observable<ItemImagesItemNumberData> {

    return this.apiService.adminGetItemImageItemNumbersData(this.adminService.currentUid, filter, pageNumber, pageSize);

  }

  getItemImageItemNumber(internalItemNumber: number): Observable<ItemImageItemNumber> {
    return this.apiService.adminGetItemImageItemNumber(this.adminService.currentUid, internalItemNumber);
  }

  saveItemImageItemNumber(item: ItemImageItemNumber): Observable<ItemImageItemNumber> {
    return this.apiService.adminSaveItemImageItemNumber(this.adminService.currentUid, item);
  }

  addItemImagesItemNumber(internalItemNumber: number, fileList: UploadFile[]): Observable<HttpEvent<ItemImageItemNumber>> {
    return this.apiService.adminUploadItemImageItemNumbers(this.adminService.currentUid, internalItemNumber, fileList);
  }

  addItemImageItemNumbers(image: ItemImageLink): Observable<ItemImageItemNumber> {
    return this.apiService.adminAddItemImageItemNumber(this.adminService.currentUid, image);
  }

  deleteItemImagesItemNumber(internalItemNumber: number): Observable<boolean> {
    return this.apiService.adminDeleteItemImagesItemNumber(this.adminService.currentUid, internalItemNumber);
  }

  deleteItemImageItemNumber(internalItemNumber: number, imageId: string): Observable<boolean> {
    return this.apiService.adminDeleteItemImageItemNumber(this.adminService.currentUid, internalItemNumber, imageId);
  }

  deleteItemImagesItemNumberDialog(item: ItemImageItemNumber): Observable<boolean> {
    return this.mainService.confirmBox(`Weet u zeker dat u de afbeeldingen voor artikel '${item.ItemNumber}' wilt verwijderen?`)
      .pipe(mergeMap(ja => {
        if (ja) {
          return this.deleteItemImagesItemNumber(item.InternalItemNumber)
            .pipe(mergeMap(ok => {
              if (!ok) {
                return this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!')
                  .pipe(mergeMap(ok => {
                    return of(false);
                  }));
              } else {
                return of(true);
              }
            }));
        }
      }));
  }

  deleteItemImageItemNumberDialog(item:ItemImageItemNumber, image: ItemImageLink): void {
    this.mainService.confirmBox(`Weet u zeker dat u de afbeelding '${image.Filename}' wilt verwijderen?`)
      .subscribe(ja => {
        if (ja) {
          this.deleteItemImageItemNumber(image.InternalItemNumber, image.ImageId)
            .subscribe(ok => {
              if (ok) {
                item.Images.remove(image);
              } else {
                this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!');
              }
            });
        }
      });
  }


  getItemImagesItemGroupData(filter: string = '', pageNumber: number = 1, pageSize: number = 5): Observable<ItemImagesItemGroupData> {

    return this.apiService.adminGetItemImageItemGroupData(this.adminService.currentUid, filter, pageNumber, pageSize);

  }

  getItemImageItemGroup(itemGroupNumber: number): Observable<ItemImageItemGroup> {
    return this.apiService.adminGetItemImageItemGroup(this.adminService.currentUid, itemGroupNumber);
  }

  saveItemImageItemGroup(itemGroup: ItemImageItemGroup): Observable<ItemImageItemGroup> {
    return this.apiService.adminSaveItemImageItemGroup(this.adminService.currentUid, itemGroup);
  }

  addItemImagesItemGroup(itemGroupNumber: number, fileList: UploadFile[]): Observable<HttpEvent<ItemImageItemGroup>> {
    return this.apiService.adminUploadItemImageItemGroup(this.adminService.currentUid, itemGroupNumber, fileList);
  }

  addItemImageItemGroup(image: ItemImageLink): Observable<ItemImageItemGroup> {
    return this.apiService.adminAddItemImageItemGroup(this.adminService.currentUid, image);
  }

  deleteItemImagesItemGroup(itemGroupNumber: number): Observable<boolean> {
    return this.apiService.adminDeleteItemImagesItemGroup(this.adminService.currentUid, itemGroupNumber);
  }

  deleteItemImageItemGroup(itemGroupNumber: number, imageId: string): Observable<boolean> {
    return this.apiService.adminDeleteItemImageItemGroup(this.adminService.currentUid, itemGroupNumber, imageId);
  }

  deleteItemImagesItemGroupDialog(item: ItemImageItemGroup): Observable<boolean> {
    return this.mainService.confirmBox(`Weet u zeker dat u de afbeeldingen voor artikelgroep '${item.ItemGroupNumber}' wilt verwijderen?`)
      .pipe(mergeMap(ja => {
        if (ja) {
          return this.deleteItemImagesItemGroup(item.ItemGroupNumber)
            .pipe(mergeMap(ok => {
              if (!ok) {
                return this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!')
                  .pipe(mergeMap(ok => {
                    return of(false);
                  }));
              } else {
                return of(true);
              }
            }));
        }
      }));
  }

  deleteItemImageItemGroupDialog(itemGroup: ItemImageItemGroup, image: ItemImageLink): void {
    this.mainService.confirmBox(`Weet u zeker dat u de afbeelding '${image.Filename}' wilt verwijderen?`)
      .subscribe(ja => {
        if (ja) {
          this.deleteItemImageItemGroup(image.ItemGroupNumber, image.ImageId)
            .subscribe(ok => {
              if (ok) {
                itemGroup.Images.remove(image);
              } else {
                this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!');
              }
            });
        }
      });
  }


}
