import { Component, OnInit } from '@angular/core';

import { BarChart } from '../../../_models/admin/statistics/bar.chart';
import { AdminService } from '../../../_services/admin/admin.service';
import { DatePeriod } from '../../../_models/admin/statistics/date.period';
import { MainService } from '../../../_services/main.service';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js/dist/types/index';

@Component({
  selector: 'app-admin-usage-last-year',
  templateUrl: './admin-usage-last-year.component.html',
  styleUrls: ['./admin-usage-last-year.component.scss']
})
export class AdminUsageLastYearComponent implements OnInit {
  stats: BarChart = null;

  public barChartType = 'bar' as const;

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  constructor(
    public mainService: MainService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.adminService.getStatistics(DatePeriod.getLastXYears(1), 'Month', 24)
      .subscribe(chart => this.stats = chart);
    // this.adminService.getStatistics(DatePeriod.getLastMonth(), 'Week', 24)
    //   .subscribe(chart => this.stats = chart);
  }

  getData(data: BarChart): ChartData<'bar'> {
    if (data) {
      const barChartData: ChartData<'bar'> = {
        labels: data.labels,
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
          { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
        ],
      };

      for (let index = 0; index < data.datasets.length; index++) {
        const dataset = { data: data.datasets[index].data, label: data.datasets[index].label }
        barChartData.datasets.push(dataset);
      }

      return barChartData;
    }
    return null;
  }

}