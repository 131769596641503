<div class="row bottom-border mx-1 py-1" [ngClass]="{'alt-back': odd}">
    <div class="px-1" [ngClass]="'col-md-'+columnWidths[0]">
        <div [ngClass]="{'text-center': textCenterFirstColumn}"><ng-content select="[col-1]"></ng-content></div>
    </div>
    <div *ngIf="columnWidths.length>1" class="pe-0" [ngClass]="'col-md-'+columnWidths[1]" (contextmenu)="mainService.showObject($event, item)">
        <ng-content select="[col-2]"></ng-content>
    </div>
    <div *ngIf="columnWidths.length>2" class="px-1" [ngClass]="'col-md-'+columnWidths[2]">
        <ng-content select="[col-3]"></ng-content>
    </div>
</div>