import { CatalogKind } from '../../catalog/catalog.kind';
import { SettingsBase } from './settings.base';

export class SettingsCatalogLayoutCatalogKindInfo extends SettingsBase {
  CatalogKind: CatalogKind;
  CatalogKindString: string;
  NativeMainCategoryName: string;
  NativeMainCategoryIdName: string;
  NativePartsCategoryName: string;
  NativePartsCategoryIdName: string;

  constructor() {
    super();
    this.CatalogKind = CatalogKind.Unknown;
    this.CatalogKindString = 'Onbekend';
  }
}
