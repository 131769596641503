import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tyre-storage',
  templateUrl: './tyre-storage.component.html',
  styleUrls: ['./tyre-storage.component.scss']
})
export class TyreStorageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
