<app-container *ngIf="promotion" [renderCheck]="promotion" [shopSoort]="shopSoort.Aanbiedingen">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [addShopModule]="shopSoort.Aanbiedingen"></li>
    <li appBreadcrumbHeaderItem [active]="true">{{promotion.Titel}}</li>
  </app-breadcrumb-header>

  <div class="row mb-3">
    <div class="col-lg-6" [innerHTML]="mainService.getSafeHtml(promotion.Omschrijving)">
    </div>
    <div class="col-lg-6 items">

      <div class="card border mb-3">

        <div class="card-header card-header-compact  text-bg-dark">Artikelen</div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let item of promotion.Artikelen; let even=even;"
            [ngClass]="{'alt-back': even}">
            <div class="row">
              <div class="col-6">
                <div class="row nogutter">
                  <div class="col-md-5 col-lg-12 col-xl-5">
                    <app-item-count [sci]="promotionService.getCartItemByItemId(ctx.CartItems,item.ID)">
                    </app-item-count>
                  </div>
                  <div class="col-md-7 col-lg-12 col-xl-7">
                    <strong>{{item.Artikelnr}}</strong>
                    <br /> {{item.Omschrijving | lowercase}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <app-item-info-template [sci]="promotionService.getCartItemByItemId(ctx.CartItems,item.ID)"
                  [nettoprijs]="ctx.Nettoprijs">
                </app-item-info-template>
              </div>
            </div>
          </li>
          <li class="list-group-item" *ngIf="promotion.Artikelen.length === 0">
            <div class="row">
              <div class="col-12">
                Bij deze aanbieding staan geen artikelen.
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="thumbnail-container mt-3">
        <img *ngFor="let image of promotion.Images; let index = index;" (click)="showImage(promotion, index)"
          class="img-thumbnail m-1 zoom-cursor" [src]="image.AfbeeldingsUrl" />
      </div>

    </div>
  </div>

  <app-image-viewer [images]="images" [show]="showImageModal" (close)="closeImage()"></app-image-viewer>

</app-container>