<div class="container">

  <app-breadcrumb-header [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopSoort.Paint"></li>
  </app-breadcrumb-header>

  <div class="card border mb-3">

    <div class="card-header card-header-compact  text-bg-dark" (contextmenu)="mainService.showDebugContextInfo($event, ctx)">
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td class="align-middle">Autolak bestellen</td>
            <td *ngIf="ctx.ExtraInformationEnabled" class="text-end">
              <button type="button" class="btn btn-warning" (click)="mainService.msgBox('', ctx.ExtraInformation)"><i class="fas fa-info-circle"></i> Meer informatie</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-body p-2">

      <div class="form-group row">
        <label class="col-md-4 col-form-label">Merk auto</label>
        <div class="col-md-8">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.CarBrand" maxlength="50" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Kleurnummer/benaming</label>
        <div class="col-md-8">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.CarPaintColor" maxlength="50" />
        </div>
      </div>
      <div *ngIf="ctx.PaintCartItems?.length>1" class="form-group row">
        <label class="col-md-4 col-form-label">Kies een item</label>
        <div class="col-md-8">
          <table class="table table-striped table-hover hand-cursor">
            <tbody>
              <tr *ngFor="let sci of ctx.PaintCartItems" (click)="addremove(sci.InternArtikelnr)">
                <td class="pointer" style="width: 70px;"><span *ngIf="contains(sci.InternArtikelnr)"
                    class="large-check text-secondary"><i class="fas fa-check"></i></span></td>
                <td><img *ngIf="itemInfoService.getItemInfoImage(sci.ItemInfo)"
                    [src]="itemInfoService.getItemInfoImage(sci.ItemInfo)" class="sci-image grow zoom-cursor"
                    (click)="mainService.showImageViewer(sci.ItemInfo.Properties['Image'])" /></td>
                <td><strong>{{sci.Artikelnr}}</strong><br /> {{sci.Omschrijving}}</td>
                <td class="small"><app-item-info-template [sci]="sci"
                    [nettoprijs]="ctx.Nettoprijs"></app-item-info-template></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">
          <span *ngIf="ctx.PaintCartItems?.length>1;else geenItemKeuze">Opmerkingen</span>
          <ng-template #geenItemKeuze><span>Wat heeft u nodig?</span></ng-template>
        </label>
        <div class="col-md-8">
          <textarea class="form-control" [(ngModel)]="request.Remarks" style="height: 70px;"></textarea>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-8 offset-md-4 mt-2">
          <button type="button" class="btn btn-primary"
            [disabled]="ctx.PaintCartItems?.length>1 && !request.InternalItemNumbers?.length"
            (click)="orderPaint(request)" (contextmenu)="mainService.showObject($event, request)">Bestellen</button>
        </div>
      </div>



    </div>

  </div>


</div>