import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, Renderer2, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';

// tslint:disable-next-line: max-line-length
import { FileSystemEntry, FileSystemFileEntry, FileSystemDirectoryEntry, Metadata, FileError, FileSystemDirectoryReader } from './dom.types';
import { UploadEvent } from './upload-event.model';
import { UploadFile } from './upload-file.model';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {
  @Input() description: string;
  @Input() accept = '*';
  get disabled(): boolean { return this._disabled; }
  @Input()
  set disabled(value: boolean) {
    this._disabled = (value != null && `${value}` !== 'false');
  }
  private _disabled = false;
  @Output() FileDrop: EventEmitter<UploadEvent> = new EventEmitter<UploadEvent>();
  @Output() FileOver: EventEmitter<any> = new EventEmitter<any>();
  @Output() FileLeave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileSelector') fileSelector: ElementRef;
  isDraggingOverDropZone = false;

  private globalDraggingInProgress = false;
  private globalDragStartListener: () => void;
  private globalDragEndListener: () => void;
  private files: UploadFile[] = [];
  private numOfActiveReadEntries = 0;

  private helperFormEl: HTMLFormElement | null = null;
  private fileInputPlaceholderEl: HTMLDivElement | null = null;

  private dropEventTimerSubscription: Subscription | null = null;

  constructor(
    private zone: NgZone,
    private renderer: Renderer2
  ) {
    this.globalDragStartListener = this.renderer.listen('document', 'dragstart', (evt: Event) => {
      this.globalDraggingInProgress = true;
    });
    this.globalDragEndListener = this.renderer.listen('document', 'dragend', (evt: Event) => {
      this.globalDraggingInProgress = false;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
      this.dropEventTimerSubscription = null;
    }
    this.globalDragStartListener();
    this.globalDragEndListener();
    this.files = [];
    this.helperFormEl = null;
    this.fileInputPlaceholderEl = null;
  }

  onDragOver(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.FileOver.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  onDragLeave(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = false;
        this.FileLeave.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  async dropFiles(event: DragEvent) {
    this.preventAndStop(event);
    if (!this.isDropzoneDisabled()) {
      this.isDraggingOverDropZone = false;
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
        // let items: FileList | DataTransferItemList;
        // if (event.dataTransfer.items) {
        //   items = event.dataTransfer.items;
        // } else {
        //   items = event.dataTransfer.files;
        // }
        const items = await this.getFilesAsync(event.dataTransfer);
        this.checkFiles(items);
      }
    }
  }

  async getFilesAsync(dataTransfer: DataTransfer) {
    const files: File[] = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      const item = dataTransfer.items[i];
      if (item.kind === "file") {
        if (typeof item.webkitGetAsEntry === "function") {
          const entry = item.webkitGetAsEntry();
          const entryContent = await this.readEntryContentAsync(entry);
          files.push(...entryContent);
          continue;
        }

        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }

    return files;
  }

  readEntryContentAsync(entry: any) {
    return new Promise<File[]>((resolve, reject) => {
      let reading = 0;
      const contents: File[] = [];
      let readReaderContent = (reader: FileSystemDirectoryReader) => { };
      const readEntry = (e: any) => {
        if (this.isFile(e)) {
          reading++;
          e.file(file => {
            reading--;
            contents.push(file);

            if (reading === 0) {
              resolve(contents);
            }
          });
        } else if (this.isDirectory(e)) {
          readReaderContent(e.createReader());
        }
      };
      readReaderContent = (reader: FileSystemDirectoryReader) => {
        reading++;

        reader.readEntries(function (entries) {
          reading--;
          for (const e of entries) {
            readEntry(e);
          }

          if (reading === 0) {
            resolve(contents);
          }
        });
      };

      readEntry(entry);

    });
  }

  isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
    return entry.isDirectory;
  }

  isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
    return entry.isFile;
  }

  clickUpload(event: MouseEvent, fileInput) {
    if (fileInput && document.createEvent) {
      const evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      fileInput.dispatchEvent(evt);
    }
  }

  uploadFiles(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (event.target) {
        const items = (event.target as HTMLInputElement).files || ([] as any);
        this.checkFiles(items);
        this.resetFileInput();
      }
    }
  }

  private checkFiles(items: FileList | DataTransferItemList | File[]): void {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let entry = null;
      if (this.canGetAsEntry(item)) {
        entry = item.webkitGetAsEntry();
      }

      if (!entry) {
        if (item) {
          const fakeFileEntry: FileSystemFileEntry = {
            name: (item as File).name,
            isDirectory: false,
            isFile: true,
            file: (callback: (filea: File) => void): void => {
              callback(item as File);
            },
            getMetadata(successCallback: (metadata: Metadata) => void, errorCallback?: (error: FileError) => void): void { }
          };
          const toUpload: UploadFile = new UploadFile(fakeFileEntry.name, fakeFileEntry);
          this.addToQueue(toUpload);
        }

      } else {
        if (entry.isFile) {
          const toUpload: UploadFile = new UploadFile(entry.name, entry);
          this.addToQueue(toUpload);

        } else if (entry.isDirectory) {
          this.traverseFileTree(entry, entry.name);
        }
      }
    }

    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
    }
    this.dropEventTimerSubscription = timer(200, 200)
      .subscribe(() => {
        if (this.files.length > 0 && this.numOfActiveReadEntries === 0) {
          this.FileDrop.emit(new UploadEvent(this.files));
          this.files = [];
        }
      });
  }

  private traverseFileTree(item: FileSystemEntry, path: string): void {
    if (item.isFile) {
      const toUpload: UploadFile = new UploadFile(path, item);
      this.files.push(toUpload);

    } else {
      path = path + '/';
      const dirReader = (item as FileSystemDirectoryEntry).createReader();
      let entries: FileSystemEntry[] = [];

      const readEntries = () => {
        this.numOfActiveReadEntries++;
        dirReader.readEntries((result) => {
          if (!result.length) {
            // add empty folders
            if (entries.length === 0) {
              const toUpload: UploadFile = new UploadFile(path, item);
              this.zone.run(() => {
                this.addToQueue(toUpload);
              });

            } else {
              for (let i = 0; i < entries.length; i++) {
                this.zone.run(() => {
                  this.traverseFileTree(entries[i], path + entries[i].name);
                });
              }
            }

          } else {
            // continue with the reading
            entries = entries.concat(result);
            readEntries();
          }

          this.numOfActiveReadEntries--;
        });
      };

      readEntries();
    }
  }

  /**
   * Clears any added files from the file input element so the same file can subsequently be added multiple times.
   */
  private resetFileInput(): void {
    if (this.fileSelector && this.fileSelector.nativeElement) {
      const fileInputEl = this.fileSelector.nativeElement as HTMLInputElement;
      const fileInputContainerEl = fileInputEl.parentElement;
      const helperFormEl = this.getHelperFormElement();
      const fileInputPlaceholderEl = this.getFileInputPlaceholderElement();

      // Just a quick check so we do not mess up the DOM (will never happen though).
      if (fileInputContainerEl !== helperFormEl) {
        // Insert the form input placeholder in the DOM before the form input element.
        this.renderer.insertBefore(fileInputContainerEl, fileInputPlaceholderEl, fileInputEl);
        // Add the form input as child of the temporary form element, removing the form input from the DOM.
        this.renderer.appendChild(helperFormEl, fileInputEl);
        // Reset the form, thus clearing the input element of any files.
        helperFormEl.reset();
        // Add the file input back to the DOM in place of the file input placeholder element.
        this.renderer.insertBefore(fileInputContainerEl, fileInputEl, fileInputPlaceholderEl);
        // Remove the input placeholder from the DOM
        this.renderer.removeChild(fileInputContainerEl, fileInputPlaceholderEl);
      }
    }
  }

  /**
   * Get a cached HTML form element as a helper element to clear the file input element.
   */
  private getHelperFormElement(): HTMLFormElement {
    if (!this.helperFormEl) {
      this.helperFormEl = this.renderer.createElement('form') as HTMLFormElement;
    }

    return this.helperFormEl;
  }

  /**
   * Get a cached HTML div element to be used as placeholder for the file input element when clearing said element.
   */
  private getFileInputPlaceholderElement(): HTMLDivElement {
    if (!this.fileInputPlaceholderEl) {
      this.fileInputPlaceholderEl = this.renderer.createElement('div') as HTMLDivElement;
    }

    return this.fileInputPlaceholderEl;
  }

  private canGetAsEntry(item: any): item is DataTransferItem {
    return !!item.webkitGetAsEntry;
  }

  private isDropzoneDisabled(): boolean {
    return (this.globalDraggingInProgress || this.disabled);
  }

  private addToQueue(item: UploadFile): void {
    this.files.push(item);
  }

  preventAndStop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }


}
