import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-show-more-less-button',
  templateUrl: './show-more-less-button.component.html',
  styleUrls: ['./show-more-less-button.component.scss']
})
export class ShowMoreLessButtonComponent implements OnInit {
  @Input() more: boolean;
  @Output() moreChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
