import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-admin-date-input',
  templateUrl: './admin-date-input.component.html',
  styleUrls: ['./admin-date-input.component.scss']
})
export class AdminDateInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Input() centerLabel: boolean = false;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<any>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() withTimepicker: boolean;
  uniqueId = Math.random().toString(36).substring(2);

  constructor(
    private localeService: BsLocaleService
  ) { }

  ngOnInit() {
    this.localeService.use('nl');
  }

  changeModel(event: string): void {
    this.modelChange.emit(this.model);
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

}
