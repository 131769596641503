import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { GisOrderHistorySearchRequest } from '../../../_models/customer-information/gis.order.history.search.request';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { ShopOrder } from '../../../_models/shoporders/shop.order';

@Component({
  selector: 'app-order-history-webshop',
  templateUrl: './order-history-webshop.component.html',
  styleUrls: ['./order-history-webshop.component.scss']
})
export class OrderHistoryWebshopComponent implements OnInit {
  headers: ShopOrder[];
  isBusy: boolean;

  constructor(
    private localeService: BsLocaleService,
    public mainService: MainService,
    public sortService: SortService,
    public authService: AuthService,
    public customerInformationService: CustomerInformationService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
    this.localeService.use('nl');
    if (!this.customerInformationService.requestOrderHistory) {
      this.customerInformationService.dateChoicesOrderHistory = this.customerInformationService.getDateChoices();
      this.customerInformationService.selectionOrderHistory = this.customerInformationService.dateChoicesOrderHistory[2];
      this.customerInformationService.requestOrderHistory = new GisOrderHistorySearchRequest();
      this.customerInformationService.setDates(this.customerInformationService.requestOrderHistory
        , this.customerInformationService.selectionOrderHistory);
    }
    this.sortService.initSortField('order-history', 'OrderDate', true);
    this.search();
  }

  search() {
    this.isBusy = true;
    this.customerInformationService.showPasswordPrompt = false;
    this.customerInformationService.getShopOrders(
      this.customerInformationService.requestOrderHistory.DateFrom,
      this.customerInformationService.requestOrderHistory.DateTo,
      this.customerInformationService.requestOrderHistory.ArticleNumber,
      this.customerInformationService.requestOrderHistory.Ordernumber,
      this.customerInformationService.requestOrderHistory.Reference,
      this.customerInformationService.requestOrderHistory.Description,
      this.customerInformationService.requestOrderHistory.PlateNumber
    ).subscribe(headers => {
      this.isBusy = false;
      this.headers = headers;
    }, error => {
      this.isBusy = false;
      if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
      console.error(error);
    });
  }

  clickHeader(header: ShopOrder): void {
    // Goto details
    this.customerInformationService.gotoShopOrderDetail(header);
  }

}
