<div class="modal-header">
    <div>
        <h3 class="modal-title">Band Informatie</h3>
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-2 control-label">Artikelnummer</div>
        <div class="col-sm-5 form-data">{{tyre.ItemInfo.ItemNumber}}</div>
        <div class="col-sm-2 control-label">Fabrikant</div>
        <div class="col-sm-3 form-data">{{tyre.ItemInfo.Properties['TyreBrand']}}</div>
    </div>
    <div class="row">
        <div class="col-sm-2 control-label">Omschrijving</div>
        <div class="col-sm-5 form-data">{{tyre.ItemInfo.Description}}</div>
        <div class="col-sm-2 control-label">Categorie</div>
        <div class="col-sm-3 form-data">{{tyre.ItemInfo.Properties['TyreKind']}}</div>
    </div>
    <div class="row">
        <div class="col-sm-2 control-label">Bandenmaat</div>
        <div class="col-sm-5 form-data">{{tyre.ItemInfo.Properties['TyreSize']}}</div>
        <div *ngIf="rimAndTyreService.getTyreLabel(tyre)" class="col-sm-2 control-label">Bandenlabel</div>
        <div class="col-sm-3 form-data">{{rimAndTyreService.getTyreLabel(tyre)}}</div>
    </div>
    <div class="row align-items-center">
        <div class="col-sm-6 text-center"><img [src]="tyre.ItemInfo.Properties['Image']" /></div>
        <div class="col-sm-6 text-center">
            <a *ngIf="rimAndTyreService.getTyreLabel(tyre)" href="{{rimAndTyreService.getLabelUrl(tyre, true)}}" target="_blank">
                <img class="img-fluid" [src]="rimAndTyreService.getLabelUrl(tyre)" />
            </a>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="choose(null)">Sluiten</button>
</div>