import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppLogEntry } from '../_models/common/app.log.entry';
import { AppLogLevel } from '../_models/common/app.log.level';
import { ConfigData } from '../_models/common/config.data';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  consoleActive = true;
  logLevel = AppLogLevel.Errors;
  authService: AuthService;
  origLog: (message?: any, ...optionalParams: any[]) => any;
  origInfo: (message?: any, ...optionalParams: any[]) => any;
  origWarn: (message?: any, ...optionalParams: any[]) => any;
  origError: (message?: any, ...optionalParams: any[]) => any;
  origTime: (message?: any, ...optionalParams: any[]) => any;
  origTimeEnd: (message?: any, ...optionalParams: any[]) => any;
  logBuffer: AppLogEntry[] = [];
  timers: { [key: string]: number } = {};

  constructor(
    private inj: Injector,
    private http: HttpClient,
    private config: ConfigData
  ) { }

  setLogLevel(level: AppLogLevel) {
    if (this.logLevel !== level) {
      console.info(`Setting AppLogLevel from ${AppLogLevel[this.logLevel]} to ${AppLogLevel[level]}`);
      this.logLevel = level;
    }
  }

  getActiveConsoleFunction(originalFunction: (message?: any, ...optionalParams: any[]) => any, level: AppLogLevel)
    : (message?: any, ...optionalParams: any[]) => any {
    return function () {
      return Function.prototype.bind.call(originalFunction, console);
    };
  }

  getAlternativeConsoleFunction(originalFunction: (message?: any, ...optionalParams: any[]) => any, level: AppLogLevel, self: LogService)
    : (message?: any, ...optionalParams: any[]) => any {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      if (originalFunction === self.origTime) {
        self.timers[args[0]] = Date.now();
      } else if (originalFunction === self.origTimeEnd) {
        const entry = new AppLogEntry(level, [`${args[0]}: ${(Date.now() - self.timers[args[0]]) / 1000} secs`]);
        // self.logBuffer.push(entry);
        self.postLog(entry);
      } else {
        const entry = new AppLogEntry(level, args);
        // self.logBuffer.push(entry);
        self.postLog(entry);
      }
      if (level <= self.logLevel || !environment.production) {
        originalFunction(...arguments);
      }
    };
  }

  postLog(entry: AppLogEntry) {
    try {
      if (this.isLoggedOn()) {
        if (entry.Level <= this.logLevel) {
          this.http.post<boolean>(`${this.config.backendApi}/api/ClientLog`, entry)
            .subscribe();
        }
      }
    } catch (error) {

    }
  }

  isLoggedOn() {
    const now = new Date();
    if (this.authService.currentLogon && this.authService.currentLogon.Expiration > now) {
      return true;
    }
    return false;
  }

  init() {
    if (!this.authService) { this.authService = this.inj.get(AuthService); }
    // this.origLog = console.log;
    // this.origInfo = console.info;
    // this.origWarn = console.warn;
    // this.origError = console.error;
    // this.origTime = console.time;
    // this.origTimeEnd = console.timeEnd;
    // this.consoleActive = !environment.production;

    // if (this.consoleActive) {
    //   this.mapActiveConsole();
    // } else {
    //   this.mapAlternativeConsole();
    // }

  }

  mapActiveConsole() {
    this.consoleActive = true;
    console.log = this.getActiveConsoleFunction(this.origLog, AppLogLevel.Info)();
    console.info = this.getActiveConsoleFunction(this.origInfo, AppLogLevel.Info)();
    console.warn = this.getActiveConsoleFunction(this.origWarn, AppLogLevel.Warnings)();
    console.error = this.getActiveConsoleFunction(this.origError, AppLogLevel.Errors)();
    console.time = this.getActiveConsoleFunction(this.origTime, AppLogLevel.Info)();
    console.timeEnd = this.getActiveConsoleFunction(this.origTimeEnd, AppLogLevel.Info)();
  }

  mapAlternativeConsole() {
    this.consoleActive = false;
    console.log = this.getAlternativeConsoleFunction(this.origLog, AppLogLevel.Info, this);
    console.info = this.getAlternativeConsoleFunction(this.origInfo, AppLogLevel.Info, this);
    console.warn = this.getAlternativeConsoleFunction(this.origWarn, AppLogLevel.Warnings, this);
    console.error = this.getAlternativeConsoleFunction(this.origError, AppLogLevel.Errors, this);
    console.time = this.getAlternativeConsoleFunction(this.origTime, AppLogLevel.Info, this);
    console.timeEnd = this.getAlternativeConsoleFunction(this.origTimeEnd, AppLogLevel.Info, this);
  }

}
