import { Component, OnInit } from '@angular/core';
import { GisInvoiceSearchRequest } from '../../../_models/customer-information/gis.invoice.search.request';
import { DateChoice } from '../../../_models/customer-information/date.choice';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { InvoiceHeader } from '../../../_models/invoices/invoice.header';

@Component({
  selector: 'app-invoices-webshop',
  templateUrl: './invoices-webshop.component.html',
  styleUrls: ['./invoices-webshop.component.scss']
})
export class InvoicesWebshopComponent implements OnInit {
  headers: InvoiceHeader[];
  isBusy = false;

  constructor(
    private localeService: BsLocaleService,
    public mainService: MainService,
    public sortService: SortService,
    public authService: AuthService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
    this.localeService.use('nl');
    if (!this.customerInformationService.requestInvoice) {
      this.customerInformationService.requestInvoice = new GisInvoiceSearchRequest();
      this.customerInformationService.dateChoicesInvoice = this.customerInformationService.getDateChoices();
      this.customerInformationService.selectionInvoice = this.customerInformationService.dateChoicesInvoice[2];
      this.customerInformationService.setDates(this.customerInformationService.requestInvoice
        , this.customerInformationService.selectionInvoice);
    }
    this.sortService.initSortField('invoices', 'InvoiceDate', true);
    this.search();
  }

  search() {
    this.isBusy = true;
    this.customerInformationService.getInvoices(
      this.customerInformationService.requestInvoice.DateFrom,
      this.customerInformationService.requestInvoice.DateTo,
      this.customerInformationService.requestInvoice.ArticleNumber,
      this.customerInformationService.requestInvoice.DeliveryNoteNumber,
      this.customerInformationService.requestInvoice.InvoiceNumber)
      .subscribe(headers => {
        this.isBusy = false;
        this.customerInformationService.showPasswordPrompt = false;
        this.headers = headers;
      }, error => {
        this.isBusy = false;
        if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
        console.error(error);
      });
  }

}
