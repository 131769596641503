import { Component, OnInit, Input } from '@angular/core';
import { MainService } from '../../_services/main.service';
import { SortService } from '../../_services/sort.service';

@Component({
  selector: 'app-sort-field',
  templateUrl: './sort-field.component.html',
  styleUrls: ['./sort-field.component.scss']
})
export class SortFieldComponent implements OnInit {
descending = false;
@Input() sortObjectId: string;
@Input() sortField: string;

  constructor(
    public mainService: MainService,
    public sortService: SortService
  ) { }

  ngOnInit() {
  }

}
