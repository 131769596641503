import { Component, OnInit, Input } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';


import { CatalogService } from '../../../_services/catalog.service';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { SelectListItem } from '../../../_models/common/select.list.item';



@Component({
  selector: 'app-catalog-parts-filter',
  templateUrl: './catalog-parts-filter.component.html',
  styleUrls: ['./catalog-parts-filter.component.scss']
})
export class CatalogPartsFilterComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  @Input() keyPart: string;
  @Input() useRegex: boolean;
  @Input() completeString: string;
  somethingClicked = false;
  selectedText: string;
  allSelected: boolean;
  descriptions: SelectListItem[];

  constructor(
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
    this.descriptions = this.catalogService.getFilterItems(this.ctx, this.keyPart, this.useRegex);
    this.selectedText = this.catalogService.getSelectedFilterString(this.ctx, this.keyPart, 30, this.completeString, this.useRegex);
    this.allSelected = this.catalogService.isFilterFull(this.ctx, this.keyPart, this.useRegex);
  }

  closeDropDown(dropdown: BsDropdownDirective): void {
    if (dropdown.isOpen && this.somethingClicked) {
      this.somethingClicked = false;
      dropdown.isOpen = false;
    }
  }

  clickFull(): void {
    this.somethingClicked = true;
    this.descriptions = this.catalogService.setFullFilter(
      this.ctx, this.keyPart, !this.catalogService.isFilterFull(this.ctx, this.keyPart, this.useRegex), this.useRegex
    );
    this.selectedText = this.catalogService.getSelectedFilterString(this.ctx, this.keyPart, 30, this.completeString, this.useRegex);
    this.allSelected = this.catalogService.isFilterFull(this.ctx, this.keyPart, this.useRegex);
  }

  clickItem(item: SelectListItem) {
    this.somethingClicked = true;
    this.descriptions = this.catalogService.flipFiltersAndGetItems(this.ctx, this.keyPart, item.description, this.useRegex);
    this.selectedText = this.catalogService.getSelectedFilterString(this.ctx, this.keyPart, 30, this.completeString, this.useRegex);
    this.allSelected = this.catalogService.isFilterFull(this.ctx, this.keyPart, this.useRegex);
  }

}
