import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopSoort } from '../../_models/common/shop.soort';
import { TyreSize } from '../../_models/tyres/tyre.size';

@Component({
  selector: 'app-tyre-calculator',
  templateUrl: './tyre-calculator.component.html',
  styleUrls: ['./tyre-calculator.component.scss']
})
export class TyreCalculatorComponent implements OnInit {
  shopKind = ShopSoort;
  tyres: TyreSize[] = [TyreSize.getFromString('195/55R16'), TyreSize.getFromString('225/35R18')];
  tyreSpeeds = [100, 100];
  sliderWidth = { floor: 125, ceil: 345, step: 5 };
  sliderAspectRatio = { floor: 25, ceil: 90, step: 5 };
  sliderDiameter = { floor: 10, ceil: 24, step: 1 };
  sliderSpeed = { floor: 0, ceil: 250, step: 0.1 };

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const size = TyreSize.getFromString(this.route.snapshot.paramMap.get('size'));
    if (size) this.tyres = [size, size];
    this.updateSpeed(1);
  }

  changeTyre(tyre: number, size: string) {
    if (size) { this.tyres[tyre] = TyreSize.getFromString(size); }
  }

  getDeviation(): number {
    return (this.tyres[1].getCircumFerence() - this.tyres[0].getCircumFerence()) * 100 / this.tyres[0].getCircumFerence();
  }

  getDiameterDiff(): number {
    return this.tyres[1].getFullDiameter() - this.tyres[0].getFullDiameter();
  }

  getCircumferenceDiff(): number {
    return this.tyres[1].getCircumFerence() - this.tyres[0].getCircumFerence();
  }

  getSpeedTyre(tyre: number, speed: number): number {
    if (tyre === 0) {
      return Math.round(speed * 100 / (this.getDeviation() + 100));
    }
    return Math.round((this.getDeviation() + 100) * speed) / 100;
  }

  updateSpeed(band) {
    this.tyreSpeeds[band] = this.getSpeedTyre(band, this.tyreSpeeds[Math.abs(band - 1)]);
  }

}
