<app-admin-custom-control [label]="label" [labelCols]="labelCols" [dividerKind]="dividerKind">
  <div class="dropdown" [ngClass]="{'disabled': disabled}">
    <button class="btn btn-light border border-light-subtle dropdown-toggle" [disabled]="disabled" type="button"
      id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="float-start">
        <ng-container *ngTemplateOutlet="buttonTemplate ? buttonTemplate: defaultTemplate; context:{model:getModelDisplayValue(model)}"></ng-container>
      </div>
    </button>
    <div class="dropdown-menu maxheight-dropdown" aria-labelledby="dropdownMenuButton">
      <a *ngFor="let value of getModelValues()" class="dropdown-item noselect pointer" [ngClass]="{'active': !multiSelect && model===value}" (click)="setModel(value)">
        <div class="d-flex">
          <div *ngIf="multiSelect" class="me-2" [ngClass]="{'check-off': !modelContainsValue(value) && !emptyIsAllSelectedAndEmpty()}"><i class="far fa-check"></i></div>
          <div class="w-100">
            <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate: defaultTemplate; context:{model:getDisplayValue(value)}"></ng-container>
          </div>
        </div>
      </a>
    </div>
  </div>
</app-admin-custom-control>
  
<ng-template #defaultTemplate let-model='model'>
  <span [innerHtml]="model | safeHtml"></span>
</ng-template>
