import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterfaceData } from '../../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../../../_services/admin/admin-supplier-interface.service';
import { MainService } from '../../../../../_services/main.service';

@Component({
  selector: 'app-proxy-interface-data-settings',
  templateUrl: './proxy-interface-data-settings.component.html',
  styleUrls: ['./proxy-interface-data-settings.component.scss']
})
export class ProxyInterfaceDataSettingsComponent implements OnInit {
  @Input() interfaceDatas: InterfaceData[];
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Output() interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();

  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    public mainService: MainService
  ) { }
  
  ngOnInit(): void {
  }

}
