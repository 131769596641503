import { Component, OnInit, Input } from '@angular/core';

import { ContextHomeScreen } from '../../../_models/common/context.home.screen';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { ContextLoyaltyShop } from '../../../_models/loyalty-shop/context.loyalty.shop';
import { ContextCarTypeSelection } from '../../../_models/car-types/context.car.type.selection';
import { MainService } from '../../../_services/main.service';
import { ShopService } from '../../../_services/shop.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { PromotionService } from '../../../_services/promotion.service';
import { LoyaltyShopService } from '../../../_services/loyalty-shop.service';
import { ApiService } from '../../../_services/api.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { HomeScreenService } from '../../../_services/home-screen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-hofland',
  templateUrl: './home-hofland.component.html',
  styleUrls: ['./home-hofland.component.scss']
})
export class HomeHoflandComponent implements OnInit {
  @Input() ctx: ContextHomeScreen;
  @Input() ctxPromotions: ContextPromotions;
  @Input() ctxLoyaltyShop: ContextLoyaltyShop;
  ctxCarTypeSelection: ContextCarTypeSelection;
  licensePlate: string;
  itemSearch: string;
  horizontalButtonCount = 5;
  licencePlaceButtonWidth = 3;
  licencePlaceAfterButton = 6;
  itemSearchButtonWidth = 3;
  itemSearchAfterButton = 11;
  defaultCatalog = ShopSoort.Catalogus;

  constructor(
    public mainService: MainService,
    public homeScreenService: HomeScreenService,
    public shopService: ShopService,
    public carTypeService: CarTypeService,
    public promotionService: PromotionService,
    public loyaltyShopService: LoyaltyShopService,
    public router: Router
  ) { }

  ngOnInit() {
    if (this.mainService.ctxMain.DefaultCatalog) { this.defaultCatalog = this.mainService.ctxMain.DefaultCatalog; }
    this.setContextCarType(this.defaultCatalog);
    this.setCarTypeInfo(this.ctx.CarType);
  }

  setContextCarType(shopKind: ShopSoort) {
    this.carTypeService.getContextCarTypeSelection(shopKind)
      .subscribe(ctx => {
        this.ctxCarTypeSelection = ctx;
      });
  }

  getPos(index: number): number {
    let position = index + 1;
    if (position > this.licencePlaceAfterButton) { position += this.licencePlaceButtonWidth; }
    if (position > this.itemSearchAfterButton) { position += this.itemSearchButtonWidth; }
    return position;
  }

  needBreak(index: number): boolean {
    return this.getPos(index) % this.horizontalButtonCount === 0;
  }

  getFillers(): number[] {
    const posCount = this.ctx.Buttons.length + this.licencePlaceButtonWidth + this.itemSearchButtonWidth;
    const posRest = (posCount % this.horizontalButtonCount);
    const filler = [];
    if (posRest > 0) {
      for (
        let i = 0;
        i < this.horizontalButtonCount - posRest;
        i++) {
        filler.push(i);
      }
    }
    return filler;
  }

  carTypeSelected(carType: UniversalCarType): void {
    this.carTypeService.saveCarType(this.defaultCatalog, carType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        this.ctx.CarType = result.CarType;
        this.setCarTypeInfo(result.CarType);
        if (this.mainService.ctxMain && this.shopService.currentActiveShop !== this.defaultCatalog) {
          this.shopService.gotoShopModule(this.mainService.ctxMain, this.defaultCatalog);
        }
      });
  }

  searchLicensePlate(licensePlate: string): void {
    this.carTypeService.searchLicensePlate(this.defaultCatalog, licensePlate)
      .subscribe(carTypeResult => {
        if (carTypeResult && carTypeResult.CarType) {
          this.carTypeSelected(carTypeResult.CarType);
        }
      });
  }

  setCarTypeInfo(carType: UniversalCarType): void {
    if (carType) {
      if (carType.LicensePlate) {
        this.licensePlate = carType.LicensePlate.Formated;
      } else {
        this.licensePlate = null;
      }
    }
  }

  searchBrandModelType(): void {
    this.carTypeService.searchBrandModelType(this.defaultCatalog, this.ctxCarTypeSelection.Merken, null, null)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

  searchHistory(): void {
    this.carTypeService.searchHistory(this.ctxCarTypeSelection.Historie)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

}
