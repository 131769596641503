import { Component, OnInit, Input } from '@angular/core';
import { PortalSetting } from '../../_models/portal-settings/portal-setting';

@Component({
  selector: 'app-portal-setting',
  templateUrl: './portal-setting.component.html',
  styleUrls: ['./portal-setting.component.scss']
})
export class PortalSettingComponent implements OnInit {
@Input() setting: PortalSetting;

  constructor() { }

  ngOnInit() {
  }

}
