import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-divider',
  templateUrl: './admin-divider.component.html',
  styleUrls: ['./admin-divider.component.scss']
})
export class AdminDividerComponent implements OnInit {
@Input() dividerKind: number;

  constructor() { }

  ngOnInit() {
    if (!this.dividerKind) { this.dividerKind = 0; }
  }

}
