import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ContextPublic } from '../../_models/common/context.public';
import { ChooseVersionComponent } from '../choose-version/choose-version.component';
import { MainService } from '../../_services/main.service';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-login-hofland',
  templateUrl: './login-hofland.component.html',
  styleUrls: ['./login-hofland.component.scss']
})

export class LoginHoflandComponent implements OnInit {
@Input() ctx: ContextPublic;
@Output() wholesalerChange = new EventEmitter();
isPwVisible = false;

  constructor(
    public mainService: MainService,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

  public keytab(event) {
    const element = event.srcElement.nextElementSibling; // get the sibling element
    if (element == null) {
      return;
    } else {
      element.focus();   // focus if not null
    }
  }

}
