import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { ItemInfoService } from '../../_services/item-info.service';
import { ItemInfoRequest } from '../../_models/item-info/item.info.request';
import { ShopSoort } from '../../_models/common/shop.soort';


@Injectable({
  providedIn: 'root'
})
export class ItemInfoDataResolverService  {

  constructor(
    private itemInfoService: ItemInfoService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextCatalog> | Observable<never> {
    const item = route.paramMap.get('item');
    const request = new ItemInfoRequest(0, item, 0, false, 1, ShopSoort.ONBEKEND, true);
    request.SearchInDescriptions = (route.queryParamMap.get('searchInDescription') || '').toLowerCase() === 'true' ? true : false;
    request.FromShop = (route.queryParamMap.get('shopKind') || '') !== '' ? +route.queryParamMap.get('shopKind') : ShopSoort.ONBEKEND;
    request.ItemGroup = (route.queryParamMap.get('itemGroup') || '') !== '' ? +route.queryParamMap.get('itemGroup') : 0;
    request.RequestItemCount
      = (route.queryParamMap.get('requestItemCount') || '') !== '' ? +route.queryParamMap.get('requestItemCount') : 1;
    if ((route.queryParamMap.get('internalItemNumber') || '').toLowerCase() === 'true') {
      request.InternalItemNumber = +item;
      request.ItemNumber = null;
    }
    return this.itemInfoService.getItems(request).pipe(
      take(1),
      mergeMap(result => {
        if (result && result.Parts && Object.keys(result.Parts).length > 0) {
          return of(result);
        } else { // no data?
          return EMPTY;
        }
      })
    );

  }


}
