import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../_services/admin/admin.service';
import { ContextAdmin } from '../../_models/admin/context.admin';
import { BackendUser } from '../../_models/admin/backendmanagement/backend.user';
import { MainService } from '../../_services/main.service';
import { ApiService } from '../../_services/api.service';
import { ConfigData } from '../../_models/common/config.data';
import { SettingModel } from '../../_models/common/setting.model';
import { WholesalerModel } from '../../_models/common/wholesaler.model';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit {
  ctx: ContextAdmin;
  urlDescription: string;
  backendUsers: BackendUser[];
  showSettingSelector = 'ShopPortal';
  showSettingWholesaler: WholesalerModel;
  showSettingValue = '';

  constructor(
    private configData: ConfigData,
    public adminService: AdminService,
    public mainService: MainService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.adminService.getContext()
      .subscribe((ctx: ContextAdmin) => {
        this.ctx = ctx;
        this.showSettingWholesaler = this.ctx.Wholesalers[0];
        this.urlDescription = this.getUrlDescription();
      });
  }

  getUrlDescription(): string {
    let description;
    if (this.ctx && this.ctx.BackendUrls) {
      Object.keys(this.ctx.BackendUrls).forEach(key => {
        if (this.ctx.BackendUrls[key]?.length === 2 && this.ctx.BackendUrls[key][0] === this.configData.backendApi)
          description = key;
      });
      if (!description) {
        description = 'Huidige Backends';
        this.ctx.BackendUrls[description] = [this.configData.backendApi];
      }
    }
    return description;
  }

  getBackendDescriptions(): string[] {
    if (this.ctx && this.ctx.BackendUrls) {
      return Object.keys(this.ctx.BackendUrls);
    }
    return null;
  }

  getBackendUrls(urlDescription: string): string[] {
    if (this.ctx?.BackendUrls && this.ctx.BackendUrls[urlDescription]?.length >0) {
      return this.ctx.BackendUrls[urlDescription];
    }
    return null;
  }

  backendUrlChanged(urlDescription: string) {
    if (this.ctx?.BackendUrls && this.ctx.BackendUrls[urlDescription]?.length === 2) {
      this.configData.backendApi = this.ctx.BackendUrls[urlDescription][0];
    }
  }

  convertShopModuleSettings() {
    const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'De shopmodule settings worden geconverteerd.');
    this.adminService.convertShopModuleSettings()
      .subscribe(timing => {
        cb.complete();
        this.mainService.showTimingDialog(timing);
      });
  }

  showSettings() {
    const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'De settings worden opgehaald');
    this.adminService.getSettings()
      .subscribe(settings => {
        cb.complete();
        const wholesaler = this.showSettingWholesaler ? this.showSettingWholesaler.ID : 0;
        let subset: {
          [key: number]: {
            [key: number]: {
              [key: number]: {
                [key: number]: {
                  [key: number]: {
                    [key: string]: SettingModel;
                  }
                }
              }
            }
          }
        } = {};
        if (wholesaler === 0) {
          subset = settings[this.showSettingSelector];
        } else {
          subset[0] = settings[this.showSettingSelector][0];
          subset[wholesaler] = settings[this.showSettingSelector][wholesaler];
        }
        if (this.showSettingValue) {
          const filtered: {
            [key: number]: {
              [key: number]: {
                [key: number]: {
                  [key: number]: {
                    [key: number]: {
                      [key: string]: SettingModel;
                    }
                  }
                }
              }
            }
          } = {};

          const lowerSetting = this.showSettingValue.toLowerCase();
          for (let wholesaler in subset) {
            for (let branch in subset[wholesaler]) {
              for (let customer in subset[wholesaler][branch]) {
                for (let userid in subset[wholesaler][branch][customer]) {
                  for (let session in subset[wholesaler][branch][customer][userid]) {
                    for (let setting in subset[wholesaler][branch][customer][userid][session]) {
                      if (setting.toLowerCase().startsWith(lowerSetting)) {
                        if (!filtered[wholesaler]) filtered[wholesaler] = {};
                        if (!filtered[wholesaler][branch]) filtered[wholesaler][branch] = {};
                        if (!filtered[wholesaler][branch][customer]) filtered[wholesaler][branch][customer] = {};
                        if (!filtered[wholesaler][branch][customer][userid]) filtered[wholesaler][branch][customer][userid] = {};
                        if (!filtered[wholesaler][branch][customer][userid][session]) filtered[wholesaler][branch][customer][userid][session] = {};
                        filtered[wholesaler][branch][customer][userid][session][setting] = subset[wholesaler][branch][customer][userid][session][setting];
                      }
                    }
                  }
                }
              }
            }
          }

          this.mainService.showObject(null, filtered);
        } else {
          this.mainService.showObject(null, subset);
        }
      });
  }

}


// let subset
// }