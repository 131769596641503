<div *ngIf="ctx" class="container-fluid h-100 scroll">
  <div
    *ngIf="settingsService.getSettingBoolean('CarTypeSelectionInHeader') && ctx.NotificationWhenCarTypeSelectionInHeader"
    class="container">
    <div class="row">
      <div class="col" [innerHTML]="mainService.getSafeHtml(ctx.NotificationWhenCarTypeSelectionInHeader)"></div>
    </div>
  </div>
  <div *ngIf="!settingsService.getSettingBoolean('CarTypeSelectionInHeader') || shopSoort==shopKind.WielenEnBanden || shopSoort==shopKind.MPM" class="container py-2"
    [style.width]="ctx.ChassisNrEnabled ? '518px' : '610px'"
    [style.marginTop]="ctx.MededelingOnderAutokeuze ? '0' : '100px'">
    <div class="card text-bg-light" (contextmenu)="mainService.showDebugInfo($event, timing, ctx)">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-licenseplate ms-3">
            <div *ngIf="ctx.KentekenEnabled" class="license-plate">
              <input type="text" [appAutofocus]="true" appLicensePlate class="license-plate-input"
                [ngModel]="licensePlate" (ngModelChange)="licensePlate=$event" (click)="licensePlate = ''"
                (keyup.enter)="searchLicensePlate(licensePlate)" />
            </div>
          </div>
          <div class="col-bmt ms-2">
            <button class="btn btn-lg btn-primary large-button" type="button" (click)="searchBrandModelType()"
              tooltip="Zoek via merk, model, type" placement="right" container="body">
              <i class="fas fa-car fa-2x"></i>
            </button>
          </div>
          <div *ngIf="ctx.Historie.length > 0 && !ctx.ChassisNrEnabled" class="col-hist ms-3">
            <button class="btn btn-lg btn-primary large-button" type="button" (click)="searchHistory()"
              tooltip="Kies een eerder gekozen auto" placement="right" container="body">
              <i class="fas fa-history fa-2x"></i>
            </button>
          </div>
        </div>
        <div *ngIf="ctx.ChassisNrEnabled" class="row">
          <div class="col-vin ms-3">
            <div class="vin">
              <div class="input-group input-group-lg">
                <input type="text" [(ngModel)]="vin" class="form-control vin-input"
                  placeholder="Zoek op chassisnummer..." (keyup.enter)="searchVin(vin)">
                <button class="btn btn-primary large-button" type="button" (click)="searchVin(vin)">
                  <i class="fas fa-search fa-2x"></i>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="ctx.Historie.length > 0" class="col-hist ms-2">
            <button class="btn btn-lg btn-primary large-button" type="button" (click)="searchHistory()"
              tooltip="Kies een eerder gekozen auto" placement="right" container="body">
              <i class="fas fa-history fa-2x"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="ctx.MededelingOnderAutokeuze" class="row">
    <div class="col">
      <div [innerHTML]="mainService.getSafeHtml(ctx.MededelingOnderAutokeuzeHtml)"></div>
    </div>
  </div>
</div>