import { Component, OnInit, Input } from '@angular/core';

import { Animations } from '../../../_animations/animations';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-catalog-part-info',
  templateUrl: './catalog-part-info.component.html',
  styleUrls: ['./catalog-part-info.component.scss'],
  animations: [Animations.fadeInOut]
})
export class CatalogPartInfoComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  @Input() part: Part;
  currentImage = 0;
  thumbnailRowHeight = 100;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
  }

  getImageBoxStyle(part: Part) {
    let height = this.mainService.resizeInfo.clientHeight - 135;
    if (this.catalogService.partHasImage(this.ctx,part)) {
      if (this.catalogService.partGetImages(this.ctx,part).length > 1) {height -= 135; }
      return {
        height: `${height}px`,
        backgroundImage: `url(${this.catalogService.partGetImages(this.ctx,part)[this.currentImage]})`
      };
    }
  }

}
