import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-checkbox-input',
  templateUrl: './admin-checkbox-input.component.html',
  styleUrls: ['./admin-checkbox-input.component.scss']
})
export class AdminCheckboxInputComponent implements OnInit {
  @Input() label: string;
  @Input() centerLabel: boolean;
  @Input() invertModel: boolean;
  @Input() modelEnabled: boolean;
  @Output() modelEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modelInput: any;
  @Output() modelInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() placeholder: string;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
  }

}
