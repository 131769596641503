<app-admin-form [title]="'Supplier Informatie'" [object]="supplierInterfaceData">
  <ng-template #dropdownTemplate let-model='model'>
    <span *ngIf="model; else noneSelected">{{model?.Name}}</span>
    <ng-template #noneSelected>niets gekozen</ng-template>
  </ng-template>
  <app-admin-custom-control [label]="'Soort koppeling'">
    <div class="row gy-2 gx-3 align-items-center">
      <div class="col-auto">
        <app-admin-dropdown [(model)]="interfaceKindInfo" [modelValues]="interfaceKinds" [modelDisplayProperty]="'Name'"
          [modelEmptyDisplayValue]="'Kies een koppeling...'"
          (modelChange)="suppliers=getSuppliers(interfaceKindInfo.InterfaceKind)" [dividerKind]="-1">
        </app-admin-dropdown>
      </div>
      @if (interfaceKindInfo?.InterfaceKind!==22 && suppliers && suppliers.length>1) {
      <div class="col-auto">
        <app-admin-dropdown [(model)]="supplier" [modelValues]="suppliers" [modelValueProperty]="'id'"
          [modelDisplayProperty]="'name'" [modelEmptyDisplayValue]="'Kies een leverancier...'"
          (modelChange)="branches=getBranches(interfaceKindInfo.InterfaceKind, supplier)" [dividerKind]="-1">
        </app-admin-dropdown>
      </div>
      }
      @if (branches && branches.length>1) {
      <div class="col-auto">
        <app-admin-dropdown [(model)]="branch" [modelValues]="branches" [modelValueProperty]="'id'"
          [modelDisplayProperty]="'name'" [modelEmptyDisplayValue]="'Kies een filiaal...'" [dividerKind]="-1">
        </app-admin-dropdown>
      </div>
      }
    </div>
  </app-admin-custom-control>

  @if (interfaceKindInfo?.UsageInfo) {
  <app-admin-custom-control [label]="'Informatie koppeling'" [centerLabel]="true">
    <div class="alert alert-warning m-0" role="alert">
      {{interfaceKindInfo.UsageInfo}}
    </div>
  </app-admin-custom-control>
  }

  <app-admin-custom-control [label]="'Artikelnummer'">
    <div class="row">
      <div class="col"><app-admin-input [(model)]="itemNumber" [toUppercase]="true" [dividerKind]="-1"
          [disabled]="!interfaceKindInfo" (keyup.enter)="supplierInterfaceDoInfo(itemNumber, itemGroup)"></app-admin-input></div>
      <div class="col"><app-admin-input [label]="'Artikelgroep'" [labelCols]="3" [toUppercase]="true"
          [(model)]="itemGroup" [dividerKind]="-1" [disabled]="!interfaceKindInfo" (keyup.enter)="supplierInterfaceDoInfo(itemNumber, itemGroup)"></app-admin-input></div>
    </div>
  </app-admin-custom-control>



  <app-admin-action-button (clickAction)="supplierInterfaceDoInfo(itemNumber, itemGroup)" [buttonColor]="'info'"
    [dividerKind]="(response || busy)?0:-1">
    <span *ngIf="busy"><i class="fas fa-sync fa-spin"></i></span><span *ngIf="!busy"><i
        class="fas fa-info-circle"></i></span> Haal informatie
  </app-admin-action-button>

  <app-admin-custom-control *ngIf="response || busy" [label]="'Info'" [busy]="busy" [dividerKind]="-1"
    (contextmenu)="mainService.showDebugInfo($event, response?.Timing, response)">
    <ng-container *ngIf="response?.Timing" afterLabel>
      <br />
      <button type="button" class="btn btn-light me-2" (click)="mainService.showTimingDialog(response.Timing)"><i
          class="far fa-stopwatch"></i></button>
    </ng-container>
    <app-supplier-interface-response *ngIf="response" [response]="response.Response"
      [withoutFirstColumn]="interfaceKindInfo?.InterfaceKind!==22"></app-supplier-interface-response>
  </app-admin-custom-control>


</app-admin-form>