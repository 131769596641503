
<ul class="navbar-nav ms-auto">
  <li class="nav-item">
    <a class="nav-link pointer"
      [routerLink]="shopService.getHomeScreenLink()">{{shopService.getHomeScreenName(ctxMain)}}</a>
  </li>
  <li *ngIf="ctxPromotions && ctxPromotions.Promotions && ctxPromotions.Promotions.length>0"
    class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      {{shopService.getShopModuleName(mainService.ctxMain, shopSoort.Aanbiedingen)}}
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="ctxPromotions">
      <a *ngFor="let promotion of ctxPromotions.Promotions" class="dropdown-item pointer"
        (click)="promotionService.orderPromotion(promotion)">{{promotion.Titel}}</a>
    </div>
  </li>
  <app-webshop-menu-item *ngIf="ctxMain.ShopModuleMenu && ctxMain.ShopModuleMenu.MenuComponent==='extra'"
    [menuItem]="ctxMain.ShopModuleMenu.MenuItems[0]"></app-webshop-menu-item>

  <ng-container *ngIf="getShopModules().length > menuItemsCount; else minderShopModules">
    <li *ngFor="let shop of getFirstShopModules(menuItemsCount-1)" class="nav-item">
      <a *ngIf="shop.ID!=37;else shop37" class="nav-link pointer" [routerLink]="shopService.getShopSoortLink(shop.ID)">{{shop.Naam}}</a>
      <ng-template #shop37><a class="nav-link pointer" (click)="startShop37(shop)">{{shop.Naam}}</a></ng-template>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        Modules
      </a>
      <div class="dropdown-menu module-menu" aria-labelledby="navbarDropdown">
        <ng-container *ngFor="let shop of getNextShopModules(menuItemsCount-1)">
          <a *ngIf="shop.ID!=37;else shop37" class="dropdown-item pointer" (click)="shopService.startShopModule(mainService.ctxMain, shop)">{{shop.Naam}}</a>
          <ng-template #shop37><a class="dropdown-item pointer" (click)="startShop37(shop)">{{shop.Naam}}</a></ng-template>
        </ng-container>
      </div>
    </li>
  </ng-container>
  <ng-template #minderShopModules>
    <li *ngFor="let shop of getShopModules()" class="nav-item">
      <a *ngIf="shop.ID!=37;else shop37" class="nav-link pointer" [routerLink]="shopService.getShopSoortLink(shop.ID)">{{shop.Naam}}</a>
      <ng-template #shop37><a class="nav-link pointer" (click)="startShop37(shop)">{{shop.Naam}}</a></ng-template>
    </li>
  </ng-template>
  <ng-container *ngIf="shopService.containsShopModule(ctxMain, shopSoort.GarageInformatieSysteem)">
    <li *ngIf="customerInformationService.getModules(ctxCustomerInformation) as modules" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        {{shopService.getShopModuleName(mainService.ctxMain, shopSoort.GarageInformatieSysteem)}}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.OrderHistory)"
          class="dropdown-item pointer" [routerLink]="['/details/history']">Orderhistorie</a>
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.OpenOrders)"
          class="dropdown-item pointer" [routerLink]="['/details/open-orders']">Openstaande orders</a>
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.DeliveryNotes)"
          class="dropdown-item pointer" [routerLink]="['/details/delivery-notes']">Pakbonnen</a>
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.Invoices)"
          class="dropdown-item pointer" [routerLink]="['/details/invoices']">Facturen</a>
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.OutstandingItems)"
          class="dropdown-item pointer" [routerLink]="['/details/outstanding-items']">Openstaande posten</a>
        <a *ngIf="customerInformationService.isModuleActive(modules, gisModuleKind.ProductReturns)"
          class="dropdown-item pointer" [routerLink]="['/details/returns']">Retouren</a>
      </div>
    </li>
  </ng-container>

</ul>
