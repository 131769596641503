import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDragPreview, CdkDrag, moveItemInArray, } from '@angular/cdk/drag-drop';
import { ResponseItem } from '../../../_models/item-info/response.item';
import { AdminService } from '../../../_services/admin/admin.service';
import { TypeaheadItem } from '../../../_models/typeahead/typeahead.item';

@Component({
  selector: 'app-admin-items-input',
  templateUrl: './admin-items-input.component.html',
  styleUrls: ['./admin-items-input.component.scss']
})
export class AdminItemsInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: number[];
  @Output() modelChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() modelObjects: ResponseItem[] = [];
  @Output() modelObjectsChange: EventEmitter<ResponseItem[]> = new EventEmitter<ResponseItem[]>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() stopSearchingOnBlur: boolean;
  @Input() dropup: boolean = false;
  @Input() start: string;
  @Input() sortEnabled: boolean;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (!this.noneSelectionModelValue) { this.noneSelectionModelValue = 0; }
    if (this.model.length) this.getObjectModels(this.model);
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModels(model: number[]) {
    let changed = false;
    const added: number[] = [];
    const count = this.modelObjects.length;

    this.modelObjects = this.modelObjects.filter(i => !model.includes(i.InternalItemNumber));
    if (this.modelObjects.length !== count) changed = true;

    model.forEach(id => {
      if (!this.modelObjects.find(i => i.InternalItemNumber === id)) {
        added.push(id);
      }
    });

    if (added.length) {
      this.adminService.getItemNumbers(this.adminService.currentUid.Wholesaler, added)
        .subscribe(result => {
          if (result) {
            this.modelObjects.push(...result);
            this.modelObjectsChange.emit(this.modelObjects);
          }
        });
    } else if (changed) this.modelObjectsChange.emit(this.modelObjects);
  }

  add(item: TypeaheadItem) {
    this.model.push(item.InternalItemNumber);
    this.adminService.getItemNumber(this.adminService.currentUid.Wholesaler, item.InternalItemNumber)
      .subscribe(result => {
        this.modelObjects.push(result);
        this.doSearch('');
      });
  }

  doSearch(start: string = null) {
    setTimeout(() => {
      this.focusInput.emit(true);
      this.start = start;
    }, 0);
  }

  remove(event: MouseEvent, item: ResponseItem) {
    this.model = this.model.remove(item.InternalItemNumber);
    this.modelObjects = this.modelObjects.filter(i => this.model.includes(i.InternalItemNumber));
    this.modelChange.emit(this.model);
    this.modelObjectsChange.emit(this.modelObjects);
    event.stopPropagation();
    this.doSearch('');
  }

  id(index, item: ResponseItem) {
    return item.InternalItemNumber;
  }

  miia(from: number, to: number) {
  moveItemInArray(this.modelObjects, from, to);
  moveItemInArray(this.model, from, to);
}


}
