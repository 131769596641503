<span *ngIf="!uid;else ok" class="badge text-bg-danger">geen uid!</span>
<ng-template #ok>
  <button *ngIf="isEmpty(uid);else wholesaler" type="button" class="btn btn-xsm btn-danger"
    (click)="choose(uid)">superadmin: alles!</button>
  <ng-template #wholesaler>
    <button type="button" class="btn btn-xsm btn-outline-light" (click)="choose(uid)">
      Deze settings gelden voor
      <ng-container *ngIf="isWholesalerSpecified(uid);else branch">
        <span> iedereen <span *ngIf="adminService.superAdmin">bij
            {{adminNameService.getWholesaler(uid.Wholesaler)}}</span></span>
      </ng-container>
      <ng-template #branch>
        <span *ngIf="isBranchSpecified(uid);else customer">
          {{adminNameService.getBranch(uid.Wholesaler,uid.Branch)}}</span>
        <ng-template #customer>
          <span *ngIf="isCustomerSpecified(uid);else userid">
            {{adminNameService.getCustomer(uid.Wholesaler, uid.Customer)}} van
            {{adminNameService.getBranch(uid.Wholesaler,uid.Branch)}}</span>
          <ng-template #userid>
            <span *ngIf="isUserIDSpecified(uid);else complicated"> gebruiker
              {{adminNameService.getUser(uid.Wholesaler,uid.Customer,uid.UserID)}} van
              {{adminNameService.getCustomer(uid.Wholesaler, uid.Customer)}}</span>
            <ng-template #complicated>
              <span class="badge text-bg-danger">{{uid.Wholesaler}}-{{uid.Branch}}-{{uid.Customer}}-{{uid.UserID}}</span>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </button>
  </ng-template>
</ng-template>

<button *ngIf="!adminSettingsService.isLeastSpecificSetting(uid)" type="button" class="btn btn-xsm btn-warning ms-2" (click)="reset(uid)">
  <span><i class="fas fa-undo"></i></span>
</button>

<div class="float-end selector-warning-icon">
  <div *ngIf="adminSettingsService.hasMoreSpecificSettings(moreSpecificSettings)" class="ms-2">
    <span class="text-warning pointer" (mouseenter)="showMenu=true"><i class="fas fa-exclamation-triangle"></i></span>
    <div class="dropdown-menu selector-warning-dropdown" [ngClass]="{'show': showMenu}" (mouseleave)="showMenu=false">
      <span class="dropdown-item-text text-wrap normal-cursor">Er zijn specifieke instellingen voor:</span>
      <div class="dropdown-divider"></div>
      <div *ngFor="let u of adminSettingsService.getUidsFromMoreSpecificSettings(moreSpecificSettings)"
        class="dropdown-item pointer uid-item" (click)="select(uid, u)">
        <span class="uid-text">{{adminNameService.getUidText(u, u.Wholesaler!==uid.Wholesaler)}}</span>
        <span class="uid-delete ms-3" (click)="delete($event, u, uid)"><i class="fas fa-trash-alt"></i></span>
      </div>
    </div>
  </div>
</div>
