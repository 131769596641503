<app-centered-spinner *ngIf="!stats" [faSize]="'fa-3x'" [color]="'text-low'"></app-centered-spinner>
<div *ngIf="stats" class="card m-3">
  <div class="card-header text-bg-primary">
    Gebruikers <span class="subtitle text-white-50">afgelopen jaar</span>
  </div>
  <div class="card-body">
    <div style="display: block;">
      <canvas baseChart width="400" height="200" 
        [data]="getData(stats)" 
        [options]="barChartOptions"
        [legend]="false" 
        [type]="barChartType">
      </canvas>
    </div>
  </div>
</div>
