<div class="modal-header">
    <h4 class="modal-title">Kies een gebruiker</h4>
</div>
<div class="modal-body">
    <table class="table table-fix-striped table-fix-hover table-sm">
        <tr *ngFor="let credentials of authService.multiLogonData" style="cursor: pointer;">
            <td (click)="choose(credentials)">{{credentials.Username}}</td>
            <td><span class="float-end" (click)="remove(credentials)"><i class="far fa-trash-alt"></i></span></td>
        </tr>
    </table>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="choose(null)">Annuleren</button>
</div>