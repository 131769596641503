import { Component, OnInit } from '@angular/core';
import { AvailabilityTemplatePart } from '../../../../../_models/item-info/availability.template.part';
import { VoorraadTemplateRendered } from '../../../../../_models/item-info/voorraad.template.rendered';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ShopModule } from '../../../../../_models/common/shop.module';
import { MainService } from '../../../../../_services/main.service';

@Component({
  selector: 'app-availability-templates-overview',
  templateUrl: './availability-templates-overview.component.html',
  styleUrls: ['./availability-templates-overview.component.scss']
})
export class AvailabilityTemplatesOverviewComponent implements OnInit {
  data: {
    templateParts: { [key: number]: AvailabilityTemplatePart };
    shopModules: { [key: number]: ShopModule };
    availabilityTemplateComposition: { [key: number]: number[] };
    settingsPerTemplatePart: { [key: string]: string };
    renderedTemplateParts: { [key: number]: VoorraadTemplateRendered };
  }
  busy: number;
  onClose: Subject<number>;

  constructor(
    private bsModalRef: BsModalRef,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  clickInfo(templatePart: AvailabilityTemplatePart, event: MouseEvent) {

    this.mainService.preventAndStop(event);
  }


  public confirm(templatePartId: number) {
    this.onClose.next(templatePartId);
    this.bsModalRef.hide();
  }
}
