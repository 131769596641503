import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PortalSettingsService } from '../../_services/portal-settings.service';
import { PortalSettingGroup } from '../../_models/portal-settings/portal-setting-group';
import { RimAndTyreService } from '../../_services/rim-and-tyre.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-settings-group',
  templateUrl: './settings-group.component.html',
  styleUrls: ['./settings-group.component.scss']
})
export class SettingsGroupComponent implements OnInit {
  group: PortalSettingGroup;

  constructor(
    private portalSettingsService: PortalSettingsService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private rimAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params && params.group) {
        this.portalSettingsService.getGroup(+params.group)
          .subscribe(group => {
            this.group = group;
          });
      }
    });
  }

  saveGroup(group: PortalSettingGroup): void {
    this.portalSettingsService.saveGroup(group)
      .subscribe(ok => {
        this.resetValues(false);
        this.rimAndTyreService.clear();
        this.router.navigate(['/settings']);
      });
  }

  resetValues(reset: boolean): void {
    if (this.group) {
      this.group.Settings.forEach(setting => {
        if (reset) {
          setting.Value.ValueString = setting.Value.ValueDefault;
        } else {
          setting.Value.ValueDefault = setting.Value.ValueString;
        }
      });
    }
  }

  cancel(): void {
    this.resetValues(true);
    this.router.navigate(['/settings']);
  }

}
