<div class="container">
  <div class="row g-0">
    <div class="col-md-auto">
      <img src="/assets/img/identity/hofland/Hofland.PNG" (dblclick)="authService.gotoLogout()" />
    </div>
    <div class="col-md-auto">
      <span class="portal-header-home text-primary" [routerLink]="['/start']"><i class="fas fa-home fa-3x"></i></span>
    </div>
    <div class="col-md">
      <span class="portal-header-label">Volgende route vertrekt over:</span>
      <span class="route-timer-break">
        <br />
      </span>
      <span class="header-route-timer" *ngIf="ctx && ctx.RouteTimerEnabled">
        <img class="header-digit" src="/assets/img/identity/hofland/flapklok/dig-{{homeScreenService.getRouteTimer('HiHour')}}.png"
        />
        <img class="header-digit" src="/assets/img/identity/hofland/flapklok/dig-{{homeScreenService.getRouteTimer('LoHour')}}.png"
        />
        <img class="header-digit" src="/assets/img/identity/hofland/flapklok/dig-sep.png" />
        <img class="header-digit" src="/assets/img/identity/hofland/flapklok/dig-{{homeScreenService.getRouteTimer('HiMinute')}}.png"
        />
        <img class="header-digit" src="/assets/img/identity/hofland/flapklok/dig-{{homeScreenService.getRouteTimer('LoMinute')}}.png"
        />
      </span>
    </div>
    <div class="col-md-auto">
      <span *ngIf="cartService.isLoyaltyPointsActive()" class="portal-header-label pointer" [routerLink]="['/loyaltyshop']">{{cartService.getLoyaltyPointsName()}}
        <span class="route-timer-break">
          <br />
        </span>{{mainService.padLeft(cartService.getLoyaltyPoints().toString(),7)}}</span>
    </div>
    <div class="col-md-auto">
      <div class="cart-container pointer" [routerLink]="['/cart']">
        <img src="/assets/img/identity/hofland/cart.png" />
        <span *ngIf="!cartService.isCartEmpty()" class="badge text-bg-primary badge-cart">{{cartService.getCartItemCount()}}</span>
      </div>
    </div>
  </div>
  <div class="settings-container" *ngIf="true">
      <span [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fas fa-cog"></i></span>
      <br />
      <span (click)="cartService.doCartSlide(ctx)">
          <div class="collapse-button" [ngClass]="{'is-collapsed': ctx.ShowCartContent}"><i class="fas fa-arrow-alt-circle-left"></i></div>
      </span>
  </div>
