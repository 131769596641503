<app-admin-form [title]="'Algemene instellingen'" [object]="settings">
  <app-admin-input [label]="'Browser titel'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalBrowserTitle').Value"></app-admin-input>
  <app-admin-checkbox [label]="'Toon huidige module in titel'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalBrowserTitleIncludingCurrentModule').Value"></app-admin-checkbox>
  <app-admin-checkbox [label]="'Toon link om inloggegevens aan te vragen op loginscherm'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalShowLinkNewLogin').Value"></app-admin-checkbox>
  <app-admin-input *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalShowLinkNewLogin').Value" [label]="'Aanvraag account sturen naar e-mail'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalNewLoginEmail').Value" [disabled]="adminSettingsService.getAdminSetting(settings,'ShopPortalNewLoginOwnLinkEnabled').Value" [placeholder]="adminSettingsService.getAdminSetting(settings,'ShopPortalNewLoginEmail').Placeholder"></app-admin-input>
  <app-admin-checkbox-input *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalShowLinkNewLogin').Value" [label]="'Eigen link voor aanvragen account'" [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'ShopPortalNewLoginOwnLinkEnabled').Value" [(modelInput)]="adminSettingsService.getAdminSetting(settings,'ShopPortalNewLoginOwnLinkUrl').Value"></app-admin-checkbox-input>
  <app-admin-checkbox [label]="'Toon wachtwoord vergeten link op loginscherm'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalShowForgotPasswordLink').Value"></app-admin-checkbox>
  <app-admin-input *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalShowForgotPasswordLink').Value" [label]="'Stuur wachtwoord vergeten naar e-mail'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalForgotPasswordEmailAddress').Value" [placeholder]="adminSettingsService.getAdminSetting(settings,'ShopPortalForgotPasswordEmailAddress').Placeholder"></app-admin-input>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>
