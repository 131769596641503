import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextPublic } from '../../_models/common/context.public';
import { AuthService } from '../../_services/auth.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-login-rijpma',
  templateUrl: './login-rijpma.component.html',
  styleUrls: ['./login-rijpma.component.scss']
})
export class LoginRijpmaComponent implements OnInit {
  @Input() ctx: ContextPublic;
  @Output() wholesalerChange = new EventEmitter();
  isPwVisible = false;

  constructor(
    public mainService: MainService,
    public authService: AuthService
  ) { }

  ngOnInit() {

  }

  public keytab(event) {
    const element = event.srcElement.nextElementSibling; // get the sibling element
    if (element == null) {
      return;
    } else {
      element.focus();   // focus if not null
    }
  }

}
