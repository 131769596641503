import { Pipe, PipeTransform } from '@angular/core';
import { Part } from '../../_models/catalog/part';
import { CatalogService } from '../../_services/catalog.service';
import { ContextCatalog } from '../../_models/catalog/context.catalog';

@Pipe({
  name: 'catalogPartsFilter',
  pure: false
})
export class CatalogPartsFilterPipe implements PipeTransform {

  transform(parts: Part[], catalogService: CatalogService, ctx: ContextCatalog): any {
    if (parts) {
      return parts.filter(part => {
        return catalogService.filterParts(ctx, part);
      });
    }
    return parts;
  }


  // transform(items: any[], args?: any): any {
  //   if (args && args.itemField && args.ctx) {
  //     if (!args.ctx.Voorraad) { return items; }
  //     const self = this;
  //     return items.filter(function (item) {
  //       return self.cartService.isStockOk(item[args.itemField], args.onlyStockSupplier);
  //     });
  //   }
  //   return items;
  // }

}
