import { Component, OnInit, Input } from '@angular/core';
import { MainService } from '../../../_services/main.service';
import { ContextBase } from '../../../_models/common/context.base';

@Component({
  selector: 'app-sort-options',
  templateUrl: './sort-options.component.html',
  styleUrls: ['./sort-options.component.scss']
})
export class SortOptionsComponent implements OnInit {
@Input() ctx: ContextBase;
@Input() btn: string;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
