<ng-template #customItemTemplate let-model="item" let-index="index">
  <div class="row">
    <div class="col-md-1 small text-end">
      <span *ngIf="model.OrderNumber;else orderid">{{model.OrderNumber}}</span>
      <ng-template #orderid><span>{{model.OrderId}}</span></ng-template>
    </div>
    <div *ngIf="customer===0; else reference" class="col-md-4 overflow-auto small">{{model.Name}} ({{model.CustomerNumber}})</div>
    <ng-template #reference>
      <div class="col-md-4 overflow-auto small">{{model.Reference}} {{model.Remark}}</div>
    </ng-template>
    <div class="col-md-2 small lighter">{{model.OrderDate | date : 'dd-MM-yyyy HH:mm'}}</div>
    <div class="col-md-2 small text-end">{{model.NetOrderAmount | amount}}</div>
    <div class="col-md-1 small lighter text-end"><span *ngIf="model.CreditsReceived">+{{model.CreditsReceived}}</span></div>
    <div class="col-md-1 small text-end text-danger" *ngIf="model.CreditsOrderAmount>0">{{model.CreditsOrderAmount*-1}}</div>
    <div class="col-md-1 small text-end text-success" *ngIf="model.CreditsOrderAmount<0">+{{model.CreditsOrderAmount*-1}}</div>
    <div class="col-md-1" *ngIf="!model.CreditsOrderAmount"></div>
    <div class="col-md-1 small lighter text-end"><span *ngIf="model.Orderlines?.length">({{model.Orderlines?.length}})</span></div>
  </div>
</ng-template>
<div class="form-group row mb-0">
  <label [for]="'input'+uniqueId" [ngClass]="'col-sm-' + labelCols" class="col-form-label label-admin text-end noselect pe-0">{{label}}</label>
  <div [ngClass]="'col-sm-' + (12-labelCols)" class="my-auto">
    <div class="input-group">
      <input type="text" [appFocus]="focusInput" [typeahead]="adminService.searchShopOrder(start,customer,onlyLoyaltyShopOrders)" [typeaheadItemTemplate]="customItemTemplate"
        [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8" [id]="'input'+uniqueId" class="form-control" [disabled]="disabled"
        [(ngModel)]="start" (ngModelChange)="startChange.emit(start)" (typeaheadOnSelect)="typeaheadOnSelect($event)" (blur)="onBlur($event)"
        [typeaheadOptionField]="'OrderId'" [placeholder]="placeholder?placeholder:''" autocomplete="off">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<app-admin-divider [dividerKind]="dividerKind"></app-admin-divider>
