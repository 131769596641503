import { Component, OnInit, Input } from '@angular/core';

import { UniversalCarType } from '../../../../_models/car-types/universal.car.type';
import { PropertyKindCatalog } from '../../../../_models/catalog/property.kind.catalog';
import { CarTypeService } from '../../../../_services/car-type.service';
import { CatalogPropertyService } from '../../../../_services/catalog-property.service';

@Component({
  selector: 'app-car-type-info-property',
  templateUrl: './car-type-info-property.component.html',
  styleUrls: ['./car-type-info-property.component.scss']
})
export class CarTypeInfoPropertyComponent implements OnInit {
  @Input() carType: UniversalCarType;
  @Input() propertyKind: PropertyKindCatalog;
  @Input() label: string;
  @Input() labelCols: number;

  constructor(
    public carTypeService: CarTypeService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 7; }
  }
}
