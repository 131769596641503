import { Injectable, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';

import { ContextCatalog } from '../_models/catalog/context.catalog';
import { UniversalPartsCategory } from '../_models/catalog/universal.parts.category';
import { UniversalCarType } from '../_models/car-types/universal.car.type';
import { Part } from '../_models/catalog/part';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { ResponseItem } from '../_models/item-info/response.item';
import { MainService } from './main.service';
import { CatalogSubPartsComponent } from '../_common/catalog/catalog-sub-parts/catalog-sub-parts.component';
import { ApiService } from './api.service';
import { ContextBase } from '../_models/common/context.base';
import { CatalogPartDetailedPopupComponent } from '../_common/catalog/catalog-part-detailed-popup/catalog-part-detailed-popup.component';
import { Settings } from '../_models/common/settings';
import { CatalogPartPopupInfoKind } from '../_models/catalog/catalog-part-popup-info-kind';
import { SelectListItem } from '../_models/common/select.list.item';
import { CarTypeService } from './car-type.service';
import { ContextGraphicParts } from '../_models/catalog/context.graphic.parts';
import { PartsCategory } from '../_models/catalog/parts.category';
import { MainCategory } from '../_models/catalog/main.category';
import { GraphicPartsStrip } from '../_models/catalog/graphic.parts.strip';
import { RouteObject } from '../_models/common/route.object';
import { TimingObject } from '../_models/common/timing.object';
import { CatalogPartsSelectionPopupComponent } from '../_common/catalog/catalog-parts-selection-popup/catalog-parts-selection-popup.component';
import { MaintenancePartsSelection } from '../_models/maintenance/maintenance.parts.selection';
import { CartItemsResponse } from '../_models/cart/cart.items.response';
import { CartService } from './cart.service';
import { ShopSoort } from '../_models/common/shop.soort';
import { ResponseItemAvailabilitySupplierInfo } from '../_models/item-info/response.item.availability.supplier.info';
import { ResponseItemAvailabilitySupplierInfoDepotInfo } from '../_models/item-info/response.item.availability.supplier.info.depot.info';
import { AvailabilityCode } from '../_models/item-info/availability.code';
import { CatalogDataRequest } from '../_models/catalog/catalog.data.request';
import { ToasterService } from './toaster.service';


@Injectable()
export class CatalogService implements OnDestroy {
  _categoryCache: { [key: string]: { [key: string]: PartsCategory } } = {};
  subscriptions: Subscription[] = [];
  _lastCarType: UniversalCarType;
  _lastCategory: UniversalPartsCategory;
  _lastDescription: string;
  _lastMaintenancePartsTypes: { [key: number]: number };
  _ctxCategories: ContextCatalog;
  _ctxParts: ContextCatalog;
  _ctxGraphicParts: ContextGraphicParts;
  _ctxMaintenanceParts: ContextCatalog;
  _maintenanceSelection: MaintenancePartsSelection;


  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private carTypeService: CarTypeService,
    private cartService: CartService,
    private toasterService: ToasterService,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.subscriptions.push(this.carTypeService.carTypeChanged$
      .subscribe(carType => {
        console.info("CatalogService cartype changed -> clear()");
        this.clear();
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  clear(): void {
    this._categoryCache = {};
    this._ctxCategories = null;
    this._ctxParts = null;
    console.info("CatalogService cleared!");
  }

  // getTestMaintenanceSelection(): MaintenancePartsSelection {
  //   const sel = new MaintenancePartsSelection();
  //   sel.PartsTypes = { 402: 2, 7: 1, 8: 1, 1862: 1 };
  //   sel.PartsPerPartsType = {};
  //   sel.SelectedPartPerPartsType = {};
  //   sel.SelectedCartItemPerPartsType = {};
  //   return sel;
  // }

  areMaintenancePartsTypesEqual(partsTypes1: { [key: number]: number }, partsTypes2: { [key: number]: number }): boolean {
    if (Object.keys(partsTypes1).length === Object.keys(partsTypes2).length) {
      let ok = true;
      Object.keys(partsTypes1).forEach(partsTypeId => {
        if (!partsTypes2[partsTypeId] || partsTypes2[partsTypeId] !== partsTypes1[partsTypeId]) { ok = false; }
      });
      return ok;
    }
    return false;
  }

  areCategoriesEqual(category1: PartsCategory, category2: PartsCategory): boolean {
    return category1 === category2 && category1.CategoryId === category2.CategoryId
      && category1.Origin === category2.Origin;
  }

  getCategories(carType: UniversalCarType): Observable<ContextCatalog> {
    if (this._ctxCategories && this.carTypeService.areCarTypesEqual(carType, this._lastCarType)) {
      return of(this._ctxCategories);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'groepen ophalen', '');
      return this.apiService.getCatalogCategories(carType)
        .pipe(
          mergeMap((ctx: ContextCatalog) => {
            cb.complete();
            this._ctxCategories = ctx;
            this.fillCategoryCache(carType.Id, ctx.MainCategories);
            this._lastCarType = carType;
            return of(ctx);
          })
        );
    }
  }

  getLastCategoriesLink(): string[] {
    if (this._ctxCategories && this._lastCarType) {
      return ['/catalog/groups', this._lastCarType.Id];
    }
    return null;
  }

  getCategoryRoute(carType: UniversalCarType, category: UniversalPartsCategory, description: string): RouteObject {
    if (category) {
      const queryParams = {};
      if (description) { queryParams['description'] = description; }
      if (category.IsGraphicCategory) {
        return new RouteObject(['/catalog/graphic-parts', category.Id], { queryParams });
      } else {
        return new RouteObject(['/catalog/parts', category.Id], { queryParams });
      }
    }
    return null;
  }

  clickCategory(event: MouseEvent, carType: UniversalCarType, category: UniversalPartsCategory, description: string) {
    event.stopPropagation();
    const routeObject = this.getCategoryRoute(carType, category, description);
    this.router.navigate(routeObject.routeCommands, routeObject.routeExtras);
  }

  getMaintenancePartsData(carType: UniversalCarType, selection: MaintenancePartsSelection)
    : Observable<{ ctx: ContextCatalog, selection: MaintenancePartsSelection }> {
    if (this._ctxMaintenanceParts && this._maintenanceSelection && this.carTypeService.areCarTypesEqual(carType, this._lastCarType)
      && this.areMaintenancePartsTypesEqual(selection.PartsTypes, this._lastMaintenancePartsTypes)) {
      return of({ ctx: this._ctxMaintenanceParts, selection: this._maintenanceSelection });
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'artikelen ophalen', '');
      const partsTypeIds = Object.keys(selection.PartsTypes).map(p => +p);
      return this.apiService.getMaintenanceParts(carType, partsTypeIds)
        .pipe(
          mergeMap((ctx: ContextCatalog) => {
            cb.complete();
            if (ctx) {
              ctx.FilterDescriptions = this.getFilterDescriptions(ctx);
              this._ctxMaintenanceParts = ctx;
              this._lastCarType = carType;
              this._lastMaintenancePartsTypes = selection.PartsTypes;
              const allParts = this.getPartsFromContext(ctx);
              const updateFilters = (): void => {
                this.updateFilterDescriptionStock(ctx);
                this.fillFilterParts(ctx);
              };
              updateFilters();
              this.getCatalogPartsCartItemsForPartsDict(carType, ctx.Category.CategoryName, ctx.Parts, ctx.Timing, ctx, updateFilters)
                .subscribe(response => {
                  ctx.PartsCartItems = response.CartItems;
                  ctx.Timing = response.Timing;
                  this._maintenanceSelection = selection;
                  Object.keys(selection.PartsTypes).forEach(partsType => {
                    const partsTypeId = +partsType;
                    const partsTypeParts: Part[] = [];
                    allParts.forEach(part => {
                      if (part.PartsTypesUniversal && part.PartsTypesUniversal.includes(partsTypeId)
                        && this.hasStock(part, response.CartItems)) {
                        partsTypeParts.push(part);
                      }
                    });
                    if (partsTypeParts.length === 1) {
                      selection.SelectedPartPerPartsType[partsTypeId] = partsTypeParts[0];
                      selection.SelectedCartItemPerPartsType[partsTypeId] = response.CartItems[partsTypeParts[0].UniqueID];
                    }
                    selection.PartsPerPartsType[partsTypeId] = partsTypeParts;
                  });
                  selection.Populated = true;
                });
            }
            return of({ ctx: ctx, selection: selection });
          }));
    }
  }

  getParts(carType: UniversalCarType, category: UniversalPartsCategory, description: string): Observable<ContextCatalog> {
    if (this._ctxParts && this.carTypeService.areCarTypesEqual(carType, this._lastCarType)
      && this.areCategoriesEqual(category, this._lastCategory)
      && description === this._lastDescription) {
      return of(this._ctxParts);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'artikelen ophalen', category ? category.CategoryName : '');
      return this.apiService.getCatalogParts(carType, category, description)
        .pipe(
          mergeMap((ctx: ContextCatalog) => {
            cb.complete();
            if (ctx) {
              ctx.FilterDescriptions = this.getFilterDescriptions(ctx);
              this._ctxParts = ctx;
              this._lastCarType = carType;
              this._lastCategory = category;
              this._lastDescription = description;

              const updateFilters = (): void => {
                this.updateFilterDescriptionStock(ctx);
                this.fillFilterParts(ctx);
              };
              updateFilters();
              this.getCatalogPartsCartItemsForPartsDict(carType, ctx.Category.CategoryName, ctx.Parts, this._ctxParts.Timing, this._ctxParts, updateFilters)
                .subscribe(response => {
                  if (response) {
                    this._ctxParts.PartsCartItems = response.CartItems;
                    this._ctxParts.Timing = response.Timing;
                  }
                });
            }
            return of(ctx);
          }));
    }
  }

  getGraphicParts(carType: UniversalCarType, category: UniversalPartsCategory, description: string): Observable<ContextGraphicParts> {
    if (this._ctxParts && this.carTypeService.areCarTypesEqual(carType, this._lastCarType)
      && this.areCategoriesEqual(category, this._lastCategory)
      && description === this._lastDescription) {
      return of(this._ctxGraphicParts);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'artikelen ophalen', category ? category.CategoryName : '');
      return this.apiService.getCatalogGraphicParts(carType, category, description)
        .pipe(
          mergeMap((ctx: ContextGraphicParts) => {
            cb.complete();
            if (ctx) {
              this._ctxGraphicParts = ctx;
              this._lastCarType = carType;
              this._lastCategory = category;
              this._lastDescription = description;
              if (this._ctxGraphicParts.GraphicParts && this._ctxGraphicParts.GraphicParts.length > 0) {
                this._ctxGraphicParts.currentStrip = this._ctxGraphicParts.GraphicParts[0];
                this._ctxGraphicParts.currentStrip['collapsed'] = false;
                this._ctxGraphicParts.GraphicParts.forEach(strip => {
                  this._ctxGraphicParts.Context[strip.StripId].FilterDescriptions
                    = this.getFilterDescriptions(this._ctxGraphicParts.Context[strip.StripId]);
                });
              }
              this.getGraphicPartsCartItems(0);
            }
            return of(ctx);
          }));
    }
  }

  getGraphicPartsCartItems(strip: number): void {
    if (this._ctxGraphicParts.GraphicParts && strip < this._ctxGraphicParts.GraphicParts.length) {
      const updateFilters = (): void => {
        this.updateFilterDescriptionStock(this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId]);
        this.fillFilterParts(this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId]);
      };
      updateFilters();
      this.getCatalogPartsCartItemsForPartsDict(this.carTypeService.currentCarType
        , this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId].Category.CategoryName
        , this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId].Parts, this._ctxGraphicParts.Timing
        , this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId], updateFilters)
        .subscribe(response => {
          if (!response) { response = new CartItemsResponse(); }
          if (!response.CartItems) { response.CartItems = {}; }
          if (response.Timing) {
            this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId].Timing = response.Timing;
            this._ctxGraphicParts.Timing = response.Timing;
          }
          this._ctxGraphicParts.Context[this._ctxGraphicParts.GraphicParts[strip].StripId].PartsCartItems = response.CartItems;
          this.getGraphicPartsCartItems(strip + 1);
        });
    }
  }

  getAttributeString(part: GraphicPartsStrip): string {
    if (part && part.Attributes) {
      const attrs = [];
      for (const attr of part.Attributes) {
        attrs.push(attr.Description + ' ' + attr.Value);
      }
      return attrs.join(' | ');
    }
  }

  fillCategoryCache(carTypeId: string, mainCategories: UniversalPartsCategory[]) {
    this._categoryCache = {};
    this._categoryCache[carTypeId] = {};
    mainCategories.forEach(mainCat => {
      mainCat.SubPartsCategories.forEach(cat => {
        this._categoryCache[carTypeId][cat.Id] = cat;
      });
    });
  }

  getCategoryByCarTypeIdAndCategoryId(carTypeId: string, categoryId: string): UniversalPartsCategory {
    if (this._categoryCache[carTypeId] && this._categoryCache[carTypeId][categoryId]) {
      return this._categoryCache[carTypeId][categoryId] as UniversalPartsCategory;
    }
    return null;
  }

  getPropertyValueStringsFromPart(part: Part, property: string): string[] {
    if (part.Properties && part.Properties[property]) {
      return part.Properties[property].map(s => s.ValueString);
    }
    return [];
  }

  countSelectListItemHits(selectListItems: { [key: string]: SelectListItem }, strings: string[]) {
    strings.forEach(s => {
      if (selectListItems[s]) { selectListItems[s].count++; }
    });
  }

  orderSelectListItemsByDescription(selectListItems: SelectListItem[]): SelectListItem[] {
    return selectListItems.sort((item1, item2) => {
      if (item1.description < item2.description) { return -1; }
      if (item1.description > item2.description) { return 1; }
      return 0;
    });
  }

  cleanFullSelectListItems(selectListItems: SelectListItem[]): SelectListItem[] {
    const full = selectListItems.filter(item => !item.selected).length === 0;
    if (full) {
      selectListItems.forEach(item => item.selected = false);
    }
    return selectListItems;
  }

  updateFilterDescriptionStock(ctx: ContextCatalog) {
    if (ctx && ctx.PartsCartItems && ctx.FilterDescriptions && ctx.FilterDescriptions.Stock && ctx.FilterDescriptions.Stock.length === 1) {
      const stock = ctx.FilterDescriptions.Stock[0];
      this.getPartsFromContext(ctx).forEach(part => {
        if (this.hasStock(part, ctx.PartsCartItems)) { stock.count++; }
      });
    }
  }

  getFilterDescriptions(ctx: ContextCatalog): { [key: string]: SelectListItem[] } {
    if (ctx) {
      console.time('getFilterDescriptions');
      const stock = new SelectListItem('Voorraad bekend', ctx.Voorraad);
      if (this.isFilterFull(ctx, 'PartsDescription_', false)) { this.clearFilter(ctx, 'PartsDescription_', false); }

      // const partDescriptions: { [key: string]: SelectListItem } = this.getFilterItems(ctx, 'PartsDescription_', false)
      //   .reduce((map, item) => {
      //     map[item.description] = item;
      //     return map;
      //   }, {});
      // const brands: { [key: string]: SelectListItem } = this.getFilterItems(ctx, 'Brand_', false)
      //   .reduce((map, item) => {
      //     map[item.description] = item;
      //     return map;
      //   }, {});
      // const locations: { [key: string]: SelectListItem } = this.getFilterItems(ctx, 'Property_InstallLocation_', false)
      //   .reduce((map, item) => {
      //     map[item.description] = item;
      //     return map;
      //   }, {});
      // const systems: { [key: string]: SelectListItem } = this.getFilterItems(ctx, 'System_', false)
      //   .reduce((map, item) => {
      //     map[item.description] = item;
      //     return map;
      //   }, {});
      // const numberOfPins: { [key: string]: SelectListItem } = this.getFilterItems(ctx, 'Property_NumberOfPins_', false)
      //   .reduce((map, item) => {
      //     map[item.description] = item;
      //     return map;
      //   }, {});
      const selectListItems: { [key: string]: { [key: string]: SelectListItem } } = {};
      if (ctx.FilterDeclarations) {
        ctx.FilterDeclarations.forEach(filter => {
          selectListItems[filter.Property] = this.getFilterItems(ctx, filter.PropertyStringPart + '_', false)
            .reduce((map, item) => {
              map[item.description] = item;
              return map;
            }, {});
        });
      }

      this.getPartsFromContext(ctx).forEach(part => {

        // const partDescription = part.PartDescription;
        // const brand = part.Brand;
        // const locationStrings = this.getPropertyValueStringsFromPart(part, 'InstallLocation');
        // const systemStrings = this.getPropertyValueStringsFromPart(part, 'System');
        // const numberOfPinsStrings = this.getPropertyValueStringsFromPart(part, 'NumberOfPins');
        //
        // if (partDescriptions[partDescription]) { partDescriptions[partDescription].count++; }
        // if (brands[brand]) { brands[brand].count++; }
        // this.countSelectListItemHits(locations, locationStrings);
        // this.countSelectListItemHits(systems, systemStrings);
        // this.countSelectListItemHits(numberOfPins, numberOfPinsStrings);

        ctx.FilterDeclarations.forEach(filter => {
          if (!filter.IsPartsProperty) {
            if (selectListItems[filter.Property][part[filter.Property]]) selectListItems[filter.Property][part[filter.Property]].count++;
          } else {
            this.countSelectListItemHits(selectListItems[filter.Property], this.getPropertyValueStringsFromPart(part, filter.Property));
          }
        });
      });

      const descriptions: { [key: string]: SelectListItem[] } = {};
      descriptions.Stock = [stock];
      // descriptions.PartsDescriptions
      //   = this.orderSelectListItemsByDescription(Object.keys(partDescriptions).map(key => partDescriptions[key]));
      // descriptions.Brands
      //   = this.orderSelectListItemsByDescription(Object.keys(brands).map(key => brands[key]));
      // descriptions.Locations = Object.keys(locations).map(key => locations[key]);
      // descriptions.Systems
      //   = this.orderSelectListItemsByDescription(Object.keys(systems).map(key => systems[key]));
      // descriptions.NumberOfPins = Object.keys(numberOfPins).map(key => numberOfPins[key]);

      ctx.FilterDeclarations.forEach(filter => {
        descriptions[filter.Property] = this.orderSelectListItemsByDescription(Object.keys(selectListItems[filter.Property]).map(key => selectListItems[filter.Property][key]));
      });

      console.timeEnd('getFilterDescriptions');
      return descriptions;
    }
    return null;
  }

  getPartsFromContext(ctx: ContextCatalog): Part[] {
    const result: Part[] = [];
    let busy = 0;
    if (ctx) {
      busy = ctx['busy'];
      for (const key of Object.keys(ctx.Parts)) {
        for (const part of ctx.Parts[key]) {
          const ok = busy || !this.mainService.isProduction || part.HasSubParts || !(ctx.OnlyShowKnownParts && ctx.PartsCartItems
            && !(ctx.PartsCartItems[part.UniqueID] && ctx.PartsCartItems[part.UniqueID].ItemInfo));
          if (ok) { result.push(part); }
        }
      }
    }
    return result;
  }

  fillFilterParts(ctx: ContextCatalog, parts: Part[] = null): number {
    if (!parts) { parts = this.getPartsFromContext(ctx); }
    ctx.filteredParts = parts.filter(part => {
      return this.filterParts(ctx, part);
    });
    return parts.length;
  }

  getPartsCollapsed(parts: Part[], collapsed: { [key: string]: boolean }): boolean {
    if (parts) {
      for (const part of parts) {
        if (!collapsed[part.UniqueID]) { return false; }
      }
    }
    return true;
  }

  doCollapseParts(parts: Part[], visibleParts: Part[], collapsed: { [key: string]: boolean }) {
    const newState = !this.getPartsCollapsed(visibleParts, collapsed);
    if (parts) {
      for (const part of parts) {
        collapsed[part.UniqueID] = newState;
      }
    }
  }

  hasProperties(obj: any): boolean {
    return !$.isEmptyObject(obj);
  }

  containsObjectWithPropertyValue(obj: any, property: string, value: string) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i][property] === value) {
        return true;
      }
    }
    return false;
  }

  filterStock(parts: Part[], cartItems: { [key: string]: ShoppingCartItem }): Part[] {
    return parts.filter(part => this.hasStock(part, cartItems));
  }

  hasStock(part: Part, cartItems: { [key: string]: ShoppingCartItem }): boolean {
    if (!(cartItems && cartItems[part.UniqueID] &&
      (part.HasSubParts || (cartItems[part.UniqueID].ItemInfo &&
        (cartItems[part.UniqueID].ItemInfo.Availability.TotalBranchStock !== 0 ||
          cartItems[part.UniqueID].ItemInfo.Availability.TotalSupplierStock !== 0 ||
          this.doesAnySupplierHaveAnythingAvailable(cartItems[part.UniqueID].ItemInfo.Availability.SupplierInfo)))))) { return false; }
    return true;
  }

  getDepotInfosFromSupplierInfo(supplierInfos: { [key: number]: ResponseItemAvailabilitySupplierInfo }): ResponseItemAvailabilitySupplierInfoDepotInfo[] {
    const depotInfoArray: ResponseItemAvailabilitySupplierInfoDepotInfo[] = [];
    Object.keys(supplierInfos).forEach(supplier => {
      const depotInfos = supplierInfos[+supplier].DepotInfo;
      Object.keys(depotInfos).forEach(depot => {
        if (depotInfos[depot]) depotInfoArray.push(depotInfos[depot]);
      });
    });
    return depotInfoArray;
  }

  doesAnySupplierHaveAnythingAvailable(supplierInfos: { [key: number]: ResponseItemAvailabilitySupplierInfo }): boolean {
    const depotInfos = this.getDepotInfosFromSupplierInfo(supplierInfos);
    if (depotInfos.some(d => d.Availability === AvailabilityCode.SufficientlyInStock)) return true;
    if (depotInfos.some(d => d.Availability === AvailabilityCode.InStock)) return true;
    if (depotInfos.some(d => d.Availability === AvailabilityCode.LowOnStock)) return true;
    return false;
  }

  isPropertyFilterOk(ctx: ContextCatalog, part: Part, filterProperty: string, property: string, allMustMatch: boolean): boolean {
    let results = 0;
    const filters = this.getActiveFilterItems(ctx, filterProperty + '_', false);
    if (!filters || filters.length === 0) { return true; }
    if (part.Properties && part.Properties[property]) {
      for (const prop of part.Properties[property]) {
        for (const filter of filters) {
          if (prop.ValueString === filter.description) { results++; }
        }
      }
    }
    return (!allMustMatch && results > 0) || (allMustMatch && results === filters.length);
  }

  filterParts(ctx: ContextCatalog, part: Part): boolean {
    if (!ctx || !ctx.Filters) { return false; }
    if (ctx.partsFilter?.length && ctx.partsFilter.indexOf(part.UniqueID) === -1) { return false; }
    // if (!ctx.Filters['PartsDescription_' + part.PartDescription] && !this.isFilterEmpty(ctx, 'PartsDescription_', false)) { return false; }
    // if (!this.isFilterEmpty(ctx, 'System_', false) && !this.isPropertyFilterOk(ctx, part, 'System', 'System', false)) { return false; }
    // if (!this.isFilterEmpty(ctx, 'Property_InstallLocation_', false) && !this.isPropertyFilterOk(ctx, part, 'Property_InstallLocation', 'InstallLocation', true)) { return false; }
    // if (!this.isFilterEmpty(ctx, 'Property_NumberOfPins_', false) && !this.isPropertyFilterOk(ctx, part, 'Property_NumberOfPins', 'NumberOfPins', true)) { return false; }
    // if (!ctx.Filters['Brand_' + part.Brand] && !this.isFilterEmpty(ctx, 'Brand_', false)) { return false; }

    for (let i = 0; i < ctx.FilterDeclarations.length; i++) {
      const filter = ctx.FilterDeclarations[i];
      if (!filter.IsPartsProperty) {
        if (!ctx.Filters[filter.PropertyStringPart + '_' + part[filter.Property]] && !this.isFilterEmpty(ctx, filter.PropertyStringPart + '_', false)) { return false; }
      } else {
        if (!this.isFilterEmpty(ctx, filter.PropertyStringPart + '_', false) && !this.isPropertyFilterOk(ctx, part, filter.PropertyStringPart, filter.Property, filter.AllMustMatch)) { return false; }
      }
    }

    if (this.mainService.isProduction && !part.HasSubParts && ctx.OnlyShowKnownParts && ctx.PartsCartItems
      && !(ctx.PartsCartItems[part.UniqueID] && ctx.PartsCartItems[part.UniqueID].ItemInfo)) { return false; }
    if (ctx.Voorraad && ctx.PartsCartItems && !this.hasStock(part, ctx.PartsCartItems)) { return false; }

    let result = true;

    return result;
  }

  getFilterDescription(ctx: ContextCatalog, description: string): string[] {
    const result = [];
    if (ctx.Voorraad && ctx.PartsCartItems) { result.push('Alleen artikelen waarvan de voorraad bekend is.'); }
    if (!description) {
      const filtersPartsDescription = this.getSelectedFilterString(ctx, 'PartsDescription_', 0, null, false);
      if (filtersPartsDescription) { result.push(filtersPartsDescription); }
    }
    const filtersBrand = this.getSelectedFilterString(ctx, 'Brand_', 0, null, false);
    if (filtersBrand) { result.push(filtersBrand); }
    if (description) {
      for (const key of Object.keys(ctx.PartsPropertyValues[description])) {
        const filters = this.getActiveFilterItems(ctx, 'Property_' + description + '_' + key + '_', false).map(item => item.description);
        if (filters.length > 0) { result.push(ctx.PartsPropertyTranslations[key].NL + ' is ' + filters.join(' of ')); }
      }
    }
    return result;
  }

  getSelectedFilterString(ctx: ContextCatalog, keyPart: string, maxChars: number, completeString: string, useRegex: boolean): string {
    const activeDescriptions = this.getActiveFilterItems(ctx, keyPart, useRegex).map(item => item.description);
    if (activeDescriptions.length > 0 && !this.isFilterFull(ctx, keyPart, useRegex)) {
      return this.mainService.getMaxCharsString(activeDescriptions.join(', '), maxChars);
    }
    return completeString;
  }

  isFilterEmpty(ctx: ContextCatalog, keyPart: string, useRegex: boolean): boolean {
    const filters = this.getActiveFilterItems(ctx, keyPart, useRegex);
    return filters.length === 0;
  }

  isFilterFull(ctx: ContextCatalog, keyPart: string, useRegex: boolean): boolean {
    const filters = this.getFilterItems(ctx, keyPart, useRegex);
    return !filters.some(item => !item.selected);
  }

  setFullFilter(ctx: ContextCatalog, keyPart: string, onoff: boolean, useRegex: boolean): SelectListItem[] {
    const result: SelectListItem[] = [];
    if (ctx && ctx.Filters) {
      for (const property of Object.keys(ctx.Filters)) {
        let desc: string = null;
        if (useRegex) {
          const regex = RegExp(keyPart, 'gi');
          if (regex.test(property)) { desc = property.replace(regex, ''); }
        } else {
          if (property.startsWith(keyPart)) { desc = property.substring(keyPart.length); }
        }
        if (desc !== null) {
          ctx.Filters[property] = onoff;
          if (!result.find(item => item.description === desc)) { result.push(new SelectListItem(desc, ctx.Filters[property])); }
        }
      }
    }
    return result;
  }

  clearFilter(ctx: ContextCatalog, keyPart: string, useRegex: boolean) {
    this.setFullFilter(ctx, keyPart, false, useRegex);
  }

  fillFilter(ctx: ContextCatalog, keyPart: string, useRegex: boolean) {
    this.setFullFilter(ctx, keyPart, true, useRegex);
  }

  hasFilterItems(ctx: ContextCatalog, keyPart: string, useRegex: boolean): boolean {
    const filters = this.flipFiltersAndGetItems(ctx, keyPart, null, useRegex);
    if (filters && filters.length > 0) { return true; }
    return false;
  }

  getFilterItems(ctx: ContextCatalog, keyPart: string, useRegex: boolean): SelectListItem[] {
    return this.flipFiltersAndGetItems(ctx, keyPart, null, useRegex);
  }

  getActiveFilterItems(ctx: ContextCatalog, keyPart: string, useRegex: boolean): SelectListItem[] {
    const filters = this.flipFiltersAndGetItems(ctx, keyPart, null, useRegex);
    const result = filters.filter(item => item.selected);
    return result;
  }

  flipFiltersAndGetItems(ctx: ContextCatalog, keyPart: string, selected: string, useRegex: boolean): SelectListItem[] {
    const result: SelectListItem[] = [];
    if (ctx && ctx.Filters) {
      for (const property of Object.keys(ctx.Filters)) {
        let desc: string = null;
        if (useRegex) {
          const regex = RegExp(keyPart, 'gi');
          if (regex.test(property)) { desc = property.replace(regex, ''); }
        } else {
          if (property.startsWith(keyPart)) { desc = property.substring(keyPart.length); }
        }
        if (desc) { // ## 2019-04-04 Maurice: Was "if (desc !== null) {", maar we willen toch ook geen lege strings?
          if (selected && desc === selected) { ctx.Filters[property] = !ctx.Filters[property]; }
          if (!result.find(item => item.description === desc)) { result.push(new SelectListItem(desc, ctx.Filters[property])); }
        }
      }
    }
    return result;
  }

  setFilter(ctx: ContextCatalog, keyPart: string, selected: string, useRegex: boolean, value: boolean) {
    if (ctx && ctx.Filters) {
      for (const property of Object.keys(ctx.Filters)) {
        let desc: string = null;
        if (useRegex) {
          const regex = RegExp(keyPart, 'gi');
          if (regex.test(property)) { desc = property.replace(regex, ''); }
        } else {
          if (property.startsWith(keyPart)) { desc = property.substring(keyPart.length); }
        }
        if (desc !== null) {
          if (selected && desc === selected) { ctx.Filters[property] = value; }
        }
      }
    }
  }

  getCartItem(ctx: ContextBase, cartItems: { [key: string]: ShoppingCartItem }, part: Part): ShoppingCartItem {
    if (cartItems && cartItems[part.UniqueID]) {
      return cartItems[part.UniqueID];
    }
    const sci = new ShoppingCartItem();
    sci.Artikelnr = part.PartItemNumber;
    sci.Artikelgroep = Number(part.PartItemGroup);
    sci.Omschrijving = part.Description;
    sci.Aantal = 0;
    sci.Punten = 0;
    sci.Brutoprijs = part.GrossPrice;
    sci.Nettoprijs = 0;
    if (ctx && ctx.CarType) { sci.Autotype = ctx.CarType.TypeId; }
    if (ctx && ctx.CarType && ctx.CarType.LicensePlate) { sci.Kenteken = ctx.CarType.LicensePlate.Bare; }
    if (ctx) { sci.Herkomst = ctx.ShopKind; }
    return sci;
  }

  getCartItemForPart(cartItems: { [key: string]: ShoppingCartItem }, part: Part) {
    if (cartItems && cartItems[part.UniqueID]) {
      return cartItems[part.UniqueID];
    }
    return null;
  }

  getItemInfo(cartItems: { [key: string]: ShoppingCartItem }, part: Part): ResponseItem {
    const sci = this.getCartItemForPart(cartItems, part);
    if (sci && sci.ItemInfo) {
      return sci.ItemInfo;
    }
    return null;
  }


  getPartsByUniqueIds(parts: { [key: string]: Part[] }, uniqueIds: string[]): Part[] {
    const partList: Part[] = [];
    for (const id of uniqueIds) {
      for (const key of Object.keys(parts)) {
        for (const part of parts[key]) {
          if (part.UniqueID === id && !partList.filter(p => p.UniqueID === part.UniqueID).length) { partList.push(part); }
        }
      }
    }
    return partList;
  }


  showSubParts(carType: UniversalCarType, category: UniversalPartsCategory, part: Part): void {
    const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'artikelen ophalen', category?.CategoryName);
    this.apiService.getCatalogSubParts(carType, category, part)
      .subscribe(ctx => {
        cb.complete();
        if (ctx) {
          const initialState = {
            ctx: ctx,
            parentPart: part
          };
          this.modalService.show(CatalogSubPartsComponent, { initialState, class: 'modal-max' });
          const updateFilters = (): void => {
            this.updateFilterDescriptionStock(ctx);
            this.fillFilterParts(ctx);
          };
          updateFilters();
          this.getCatalogPartsCartItemsForPartsDict(carType, ctx.Category.CategoryName, ctx.Parts, ctx.Timing, ctx, updateFilters)
            .subscribe(response => {
              ctx.PartsCartItems = response.CartItems;
              ctx.Timing = response.Timing;
            });
        } else {
          this.toasterService.showToast('Helaas...', 'Er werden geen artikelen gevonden.');
        }
      });
  }

  showPartDetailedPopup(
    parts: Part[],
    ctx: ContextCatalog,
    infoKind: CatalogPartPopupInfoKind
  ): void {
    const initialState = {
      ctx: ctx,
      parts: parts,
      infoKind: infoKind,
      catalogService: this,
      mainService: this.mainService
    };
    this.modalService.show(CatalogPartDetailedPopupComponent, { initialState, class: 'modal-max' });
  }

  openDocument(document: string): void {
    window.open(document, '_blank');
  }

  doCollapseAll(ctx: ContextCatalog) {
    ctx.NotCollapsedByDefault = !ctx.NotCollapsedByDefault;
    for (const part of this.getPartsFromContext(ctx)) {
      part['uncollapsed'] = ctx.NotCollapsedByDefault;
    }
    this.apiService.saveSettings(new Settings('Catalog_NotCollapsedByDefault_Parts', ctx.NotCollapsedByDefault.toString()));
  }

  getCatalogPartsCartItemsForPartsDict(carType: UniversalCarType, categoryName: string, partsDict: { [key: string]: Part[] }, timing: TimingObject, busyObject: any, updateFilters: () => any)
    : Observable<CartItemsResponse> {
    busyObject['busy'] = 1;
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Catalogus;
    request.WithoutSupplierAvailability = true;
    request.CarType = carType;
    request.PartsDict = partsDict;
    request.Description = categoryName;
    request.Timing = timing;
    return this.apiService.getCatalogPartsCartItemsForPartsDict(request)
      .pipe(
        mergeMap((response: CartItemsResponse) => {
          this.cartService.updateSupplierAvailabilityCartItemsDictionary(response, busyObject, updateFilters);
          return of(response);
        })
      );
  }

  getCatalogPartsCartItemsForPartsList(carType: UniversalCarType, shopKind: ShopSoort, parts: Part[], withoutSupplierAvailability: boolean, timing: TimingObject, busyObject: any, updateFilters: () => any)
    : Observable<CartItemsResponse> {
    busyObject['busy'] = withoutSupplierAvailability ? 1 : 0;
    return this.apiService.getCatalogPartsCartItemsForPartsList(carType, shopKind, parts, withoutSupplierAvailability, timing)
      .pipe(
        mergeMap((response: CartItemsResponse) => {
          if (withoutSupplierAvailability) this.cartService.updateSupplierAvailabilityCartItemsDictionary(response, busyObject, updateFilters);
          return of(response);
        })
      );
  }

  getPartsTypeDescription(ctx: ContextCatalog, partsTypeId: number, notfound: string) {
    if (ctx && ctx.PartsTypes && ctx.PartsTypes[partsTypeId]) {
      return ctx.PartsTypes[partsTypeId];
    }
    return notfound;
  }

  getPartsByUniversalPartsTypeId(parts: Part[], partsTypeId: number): Part[] {
    return parts.filter(part => part.PartsTypesUniversal && part.PartsTypesUniversal.includes(partsTypeId));
  }

  partsSelectionPopup(selection: MaintenancePartsSelection, partsTypeId: number, ctx: ContextCatalog): void {
    const initialState = {
      ctx: ctx,
      selection: selection,
      partsTypeId: partsTypeId,
      catalogService: this,
      mainService: this.mainService
    };
    this.modalService.show(CatalogPartsSelectionPopupComponent, { initialState, class: 'modal-max' });
  }

  partGetImages(ctx: ContextCatalog, part: Part): string[] {
    if (part?.Images && part.Images.length) {
      return part.Images;
    }
    if (ctx?.PartsThumbs && ctx.PartsThumbs[part.UniqueID] && ctx.PartsThumbs[part.UniqueID].length) {
      return ctx.PartsThumbs[part.UniqueID];
    }
    return null;
  }

  partGetImage(ctx: ContextCatalog, part: Part): string {
    const images = this.partGetImages(ctx, part);
    if (images) { return images[0]; }
    return null;
  }

  partHasImage(ctx: ContextCatalog, part: Part): boolean {
    return this.partGetImage(ctx, part) !== null;
  }

}
