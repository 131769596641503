import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UniversalCarType } from '../../_models/car-types/universal.car.type';
import { MainService } from '../../_services/main.service';
import { CarTypeService } from '../../_services/car-type.service';

@Component({
  selector: 'app-car-history-list',
  templateUrl: './car-history-list.component.html',
  styleUrls: ['./car-history-list.component.scss']
})
export class CarHistoryListComponent implements OnInit {
  carTypes: UniversalCarType[];
  carTypeService: CarTypeService;

  public onClose: Subject<UniversalCarType>;

  constructor(
    private bsModalRef: BsModalRef,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.onClose = new Subject<UniversalCarType>();
  }

  choose(item: UniversalCarType) {
    if (item) {
      this.onClose.next(item);
    }
    this.bsModalRef.hide();
  }

}
