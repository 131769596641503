import { Component, OnInit, Input, EventEmitter } from '@angular/core';

import { ContextTyres } from '../../../_models/tyres/context.tyres';
import { TyreResult } from '../../../_models/tyres/tyre.result';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { MainService } from '../../../_services/main.service';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';
import { SelectListItem } from '../../../_models/common/select.list.item';
import { WheelTyreSet } from '../../../_models/rims-and-tyres/wheel.tyre.set';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { ShopService } from '../../../_services/shop.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { ViewKindInfo } from '../../../_models/common/view.kind.info';
import { ViewKind } from '../../../_models/common/view.kind';
import { SettingsService } from '../../../_services/settings.service';

@Component({
  selector: 'app-tyres-scroll-layout',
  templateUrl: './tyres-scroll-layout.component.html',
  styleUrls: ['./tyres-scroll-layout.component.scss']
})
export class TyresScrollLayoutComponent implements OnInit {
  @Input() ctx: ContextTyres;
  @Input() tyreResult: TyreResult;
  @Input() tyres: ShoppingCartItem[];
  @Input() set: WheelTyreSet;
  shopSoort = ShopSoort;
  viewKinds: ViewKindInfo[] = [{ kind: ViewKind.Detail, icon: 'fas fa-th-list', info: 'Normale weergave' }, { kind: ViewKind.Compact, icon: 'fas fa-align-justify', info: 'Compacte weergave' }];
  focusTyreInput = new EventEmitter<boolean>();


  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public carTypeService: CarTypeService,
    private rimAndTyreService: RimAndTyreService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
    if (this.settingsService.getSettingNumber('ShowTyreSizeInputInSidePanel')) {
      setTimeout(() => {
        this.focusTyreInput.emit(true);
      }, 0);
    }
  }

  filterTyres() {
    return this.rimAndTyreService.filterTyres(this.tyreResult, this.ctx.Filters, this.ctx.Voorraad, this.ctx.OnlyStockSupplier);
  }

  hasRunflat(): boolean {
    return (this.tyreResult
      && this.tyreResult.FilterDescriptions
      && this.tyreResult.FilterDescriptions.Runflat
      && this.tyreResult.FilterDescriptions.Runflat.length > 0
      && this.tyreResult.FilterDescriptions.Runflat[0].count > 0);
  }

  stockChange(item: SelectListItem) {
    this.ctx.Voorraad = item.selected;
    this.tyres = this.filterTyres();
  }

  runflatChange(item: SelectListItem) {
    this.ctx.Filters['RunFlatTyre'] = [];
    if (item.selected) { this.ctx.Filters['RunFlatTyre'].push('1'); }
    this.tyres = this.filterTyres();
  }

  filterChange(filter: string, item: SelectListItem) {
    if (this.ctx.Filters && this.ctx.Filters[filter]) {
      if (item.selected && !this.ctx.Filters[filter].includes(item.description)) {
        this.ctx.Filters[filter].push(item.description);
      } else if (!item.selected && this.ctx.Filters[filter].includes(item.description)) {
        const index = this.ctx.Filters[filter].indexOf(item.description);
        if (index > -1) { this.ctx.Filters[filter].splice(index, 1); }
      }
    }
    this.tyres = this.filterTyres();
  }

}
