import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextRimsAndTyres } from '../../../_models/rims-and-tyres/context.rims.and.tyres';
import { MainService } from '../../../_services/main.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';

@Component({
  selector: 'app-rims-overview',
  templateUrl: './rims-overview.component.html',
  styleUrls: ['./rims-overview.component.scss']
})
export class RimsOverviewComponent implements OnInit {
ctx: ContextRimsAndTyres;

  constructor(
    private route: ActivatedRoute,
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public rimsAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.rims;
      });
      if (!this.route.snapshot.paramMap.get('carTypeId')) {
        const carType = this.carTypeService.currentCarType;
        this.rimsAndTyreService.getContextRimsAndTyres(carType)
          .subscribe(ctx => this.ctx = ctx);
      }
  }

}
