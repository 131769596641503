import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-panel-and-content',
  templateUrl: './side-panel-and-content.component.html',
  styleUrls: ['./side-panel-and-content.component.scss']
})
export class SidePanelAndContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
