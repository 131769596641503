<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3 scroll-container">

    <app-header-panel [ctx]="ctx" [timing]="ctx?.Timing"
      [preText]="ctx.Context[ctx.currentStrip.StripId].Category.CategoryName + ' voor een '" (backClick)="gotoGroups()">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col-sm-auto ps-2">
          <app-voorraad-prijs-display-opties [ctx]="ctx" [viewKinds]="viewKinds"
            (stockOptionChanged)="changeMee('Voorraad')" (netPriceOptionChanged)="changeMee('Nettoprijs')">
          </app-voorraad-prijs-display-opties>
        </div>
      </div>
    </app-header-panel>

    <div class="card border-primary mt-3">
      <div class="card-header  text-bg-primary"
        (click)="ctx.currentStrip['collapsed'] = !ctx.currentStrip['collapsed']">
        <span *ngIf="ctx.GraphicParts.length != 1" class="panel-title">Maak een keuze</span>
        <span *ngIf="ctx.GraphicParts.length == 1"
          class="panel-title">{{catalogService.getAttributeString(ctx.GraphicParts[0])}}&nbsp;</span>
        <span>
          <div class="float-end collapse-button" [ngClass]="{'is-collapsed': ctx.currentStrip['collapsed']}">
              <i class="fas fa-angle-up"></i>
          </div>
      </span>
      </div>
      <div [@slideInHorizontal]="ctx.currentStrip['collapsed']" class="card-body card-body-strip">
        <ul *ngIf="ctx.GraphicParts.length != 1" class="nav nav-tabs nav-justified">
          <li *ngFor="let graphicPart of ctx.GraphicParts" class="nav-item" (click)="ctx.currentStrip=graphicPart">
            <a class="nav-link"
              [ngClass]="{'active': graphicPart.StripId == ctx.currentStrip.StripId}">{{catalogService.getAttributeString(graphicPart)}}</a>
          </li>
        </ul>
        <app-catalog-graphic-strip [ctx]="ctx"></app-catalog-graphic-strip>
      </div>
    </div>

    <app-catalog-parts-filter-row [ctx]="ctx.Context[ctx.currentStrip.StripId]"></app-catalog-parts-filter-row>

    <ng-container [ngSwitch]="ctx.ViewKind">
      <div class="my-3" *ngSwitchDefault>
        <app-catalog-parts-description-parts
          *ngFor="let description of ctx.Context[ctx.currentStrip.StripId].PartsDescriptions"
          [ctx]="ctx.Context[ctx.currentStrip.StripId]" [partsDescription]="description">
        </app-catalog-parts-description-parts>
      </div>
      <ng-container *ngSwitchCase="2">
        <div class="card border-primary mt-3">
          <app-catalog-parts-compact-list [ctx]="ctx.Context[ctx.currentStrip.StripId]" [maxItems]="settingsService.getSettingNumber('ShowCatalogPartsPerCount')">
          </app-catalog-parts-compact-list>
        </div>
      </ng-container>
    </ng-container>

  </div>
</div>
