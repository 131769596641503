import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TypeaheadItem } from '../../../_models/typeahead/typeahead.item';
import { AdminService } from '../../../_services/admin/admin.service';


@Component({
  selector: 'app-admin-typeahead-item',
  templateUrl: './admin-typeahead-item.component.html',
  styleUrls: ['./admin-typeahead-item.component.scss']
})
export class AdminTypeaheadItemComponent implements OnInit {
  @Input() label: string;
  @Input() model: TypeaheadItem;
  @Output() modelChange: EventEmitter<TypeaheadItem> = new EventEmitter<TypeaheadItem>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() searchInDescription = true;
  start: string;
  searchingItem: boolean;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (!this.model.InternalItemNumber || this.model.InternalItemNumber === 0) {
      this.enableOnTypeaheadItemSearch();
    } else {
      this.adminService.getItemNumber(this.adminService.currentUid.Wholesaler, this.model.InternalItemNumber)
        .subscribe(item => {
          if (item) {
            this.model.ItemNumber = item.ItemNumber;
            this.model.ItemNumberStripped = item.ItemNumberStripped;
            this.model.ItemGroup = item.ItemGroup;
            this.model.ItemGroupDescription = item.ItemGroupDescription;
            this.model.Description = item.Description;
            this.model.Score = item.Accuracy;
            this.modelChange.emit(this.model);
          }
        });
    }
  }

  typeaheadItemSelected(item: TypeaheadItem) {
    this.model.InternalItemNumber = item.InternalItemNumber;
    this.model.ItemNumber = item.ItemNumber;
    this.model.ItemNumberStripped = item.ItemNumberStripped;
    this.model.ItemGroup = item.ItemGroup;
    this.model.ItemGroupDescription = item.ItemGroupDescription;
    this.model.Description = item.Description;
    this.model.Score = item.Score;
    this.modelChange.emit(this.model);
    this.searchingItem = false;
  }

  enableOnTypeaheadItemSearch(): void {
    this.searchingItem = true;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }
}
