import { Component, OnInit, Input } from '@angular/core';

import { SettingsCatalogLines } from '../../../../../_models/admin/settings/settings.catalog.lines';
import { CatalogLinesTotals } from '../../../../../_models/admin/settings/catalog.lines.totals';
import { MainService } from '../../../../../_services/main.service';

@Component({
  selector: 'app-admin-settings-catalog-lines-report',
  templateUrl: './admin-settings-catalog-lines-report.component.html',
  styleUrls: ['./admin-settings-catalog-lines-report.component.scss']
})
export class AdminSettingsCatalogLinesReportComponent implements OnInit {
  objectKeys = Object.keys;
  @Input() settings: SettingsCatalogLines;
  totals: CatalogLinesTotals;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.totals = this.getTotals(this.settings);
  }

  getTotals(settings: SettingsCatalogLines): CatalogLinesTotals {
    if (settings && settings.CatalogKinds && settings.CatalogLinesFilter) {
      return this.getTotalsFromNode(settings.CatalogKinds, settings.CatalogLinesFilter);
    }
  }

  getKeyFromValue(catalogKinds: { [key: number]: string }, value: string): number {
    let resultKey = 0;
    Object.keys(catalogKinds).forEach(key => {
      if (catalogKinds[key] === value) { resultKey = +key; }
    });
    return resultKey;
  }

  isSelected(catalogNode: { [key: number]: boolean }): boolean {
    let selected = false;
    if (catalogNode) {
      Object.keys(catalogNode).forEach(key => {
        if (typeof catalogNode[key] === 'boolean' && catalogNode[key] === true) { selected = true; }
      });
    }
    return selected;
  }

  getTotalsFromNode(catalogKinds: { [key: number]: string }, node: any, depth = 0): CatalogLinesTotals {
    const totals = new CatalogLinesTotals(catalogKinds);
    if (node) {
      Object.keys(node).forEach(key => {
        if (depth < 2) {
          const keyTotal = this.getTotalsFromNode(catalogKinds, node[key], depth + 1);
          totals.Total[0] += keyTotal.Total[0];
          totals.Selected[0] += keyTotal.Selected[0];
          totals.Objects[0][key] = keyTotal;
          Object.keys(catalogKinds).forEach(catKey => {
            totals.Total[catKey] += keyTotal.Total[catKey];
            totals.Selected[catKey] += keyTotal.Selected[catKey];
            if (keyTotal.Total[catKey] !== 0) { totals.Objects[catKey][key] = keyTotal; }
          });

        } else if (depth === 2) {
          const catalogKey = this.getKeyFromValue(catalogKinds, key);
          totals.Total[0]++;
          totals.Total[catalogKey]++;
          const selected = this.isSelected(node[key]) ? 1 : 0;
          totals.Selected[0] += selected;
          totals.Selected[catalogKey] += selected;
        }
      });
    }
    return totals;
  }

  getCatalogDescription(catalogKey: number): string {
    if (this.settings.CatalogKinds && this.settings.CatalogKinds[catalogKey]) { return ` uit ${this.settings.CatalogKinds[catalogKey]}`; }
    return '';
  }

}
