<ng-container [ngSwitch]="setting.Value.ValueKind">
  <ng-container *ngSwitchDefault>
    <input type="text" class="form-control" [(ngModel)]="setting.Value.ValueString"
      [maxlength]="setting.Value.ValueMaxLength" [placeholder]="setting.Explanation">
  </ng-container>
  <ng-container *ngSwitchCase="1">
    <input type="text" class="form-control" [(ngModel)]="setting.Value.ValueString" [maxlength]="10" appDecimalOnly
      [placeholder]="setting.Explanation">
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <input type="text" class="form-control" [(ngModel)]="setting.Value.ValueString" [maxlength]="10" appDecimalOnly
      [placeholder]="setting.Explanation">
  </ng-container>
  <ng-container *ngSwitchCase="3">
    <div class="pt-2">
      <app-switch [model]="setting.Value.ValueString === 'true'"
        (modelChange)="setting.Value.ValueString === 'true' ? setting.Value.ValueString = 'false' : setting.Value.ValueString = 'true'"></app-switch>
    </div>
  </ng-container>
</ng-container>