<app-container [renderCheck]="ctx" [shopSoort]="shopKind.Aanbiedingen">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopKind.Aanbiedingen"></li>
  </app-breadcrumb-header>

  <app-header-panel *ngIf="ctx.Header && ctx?.Promotions.length" [withoutCarType]="true" [ctx]="ctx" [timing]="ctx.Timing">
    <div class="row align-items-center justify-content-between mt-0">
      <div class="ms-3">
        {{ctx.Header}}
      </div>
    </div>
  </app-header-panel>

  <ng-container *ngIf="ctx.Promotions && ctx.Promotions.length; else notification" [ngSwitch]="ctx.ViewKind">
    <div class="my-3">
      <app-promotions-card-view *ngSwitchDefault [ctx]="ctx"></app-promotions-card-view>
      <app-promotions-carousel-view *ngSwitchCase="3" [ctx]="ctx"></app-promotions-carousel-view>
    </div>
  </ng-container>
  <ng-template #notification><div [innerHTML]="ctx.NotificationPromotionsWhenNoneActive | safeHtml"></div></ng-template>
  <div *ngIf="ctx.Footer && ctx?.Promotions.length" class="row">
    <div class="col footer text-center mb-3">{{ctx.Footer}}</div>
  </div>
</app-container>
