import { CallbackInfoComponent } from '../../_common/callback-info/callback-info.component';

export class CallbackInfoProgress {
    title: string;
    description: string;
    currentActivity: string;
    progressMax: number;
    progressValue: number;
    callBackRef: CallbackInfoComponent;

    constructor(
        title: string,
        description: string,
        currentActivity: string,
        progressMax: number,
        progressValue: number,
        callBackRef: CallbackInfoComponent
    ) {
        this.title = title;
        this.description = description;
        this.currentActivity = currentActivity;
        this.progressMax = progressMax;
        this.progressValue = progressValue;
        this.callBackRef = callBackRef;
    }

    update() {
        this.callBackRef.update(new CallbackInfoProgress(
            this.title,
            this.description,
            this.currentActivity,
            this.progressMax,
            this.progressValue,
            null
        ));
    }

    complete() {
        this.update();
        this.callBackRef.complete();
    }

    isBusy(): boolean {
        return (this.callBackRef && this.callBackRef.enabled);
    }
}
