import { Component, Input, OnInit } from '@angular/core';
import { Animations } from '../../_animations/animations';

@Component({
  selector: '[app-cart-text-lines]',
  templateUrl: './cart-text-lines.component.html',
  styleUrls: ['./cart-text-lines.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CartTextLinesComponent implements OnInit {
  @Input() textLines: string[];
  @Input() colspan: number;
  more = false;
  maxDefault = 3;
  animationBusy = false;


  constructor() { }

  ngOnInit(): void {
  }

  needsMore(): boolean {
    return (this.textLines.length > this.maxDefault);
  }

  getTextLinesStart(): string[] {
    if (this.needsMore()) {
      const lines = this.textLines.slice(0, this.maxDefault - 1);
      let lastLine = this.textLines[this.maxDefault - 1];
      if (!this.more) {
        lines.push(lastLine.substring(0, lastLine.length / 2) + '...');
      } else {
        lines.push(lastLine);
      }
      return lines;
    }
    return this.textLines;
  }

  getTextLinesEnd(): string[] {
    if (this.needsMore()) {
      const lines = this.textLines.slice(this.maxDefault);
      return lines;
    }
    return null;
  }

}
