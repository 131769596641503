<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3">
    <app-header-panel [withoutCarType]="true" [ctx]="ctx" [timing]="tyreResult?.Timing"
      (backClick)="rimAndTyreService.chooseRims()" [hideBackClick]="set === null">
      <div class="d-flex flex-row align-items-center mt-0 g-0 justify-content-between">

        <div>
          <div *ngIf="!set" class="input-group">
            <input type="text" appBandenMaat [appAutofocus]="true" [appFocus]="sizeInputFocus"
              class="form-control tyre-input" [ngModel]="bandenmaat" (ngModelChange)="bandenmaat=$event" placeholder="bandenmaat..."
              aria-label="Search for..." (keyup.enter)="getTyres(bandenmaat, ctx.RequestItemCount)"
              [appSelectOnclick]>
            <button class="btn btn-light" type="button" (click)="reset()">
              <i class="fas fa-times"></i>
            </button>
            <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">{{ctx.RequestItemCount}}</button>
            <div class="dropdown-menu">
              <a *ngFor="let count of ctx.RequestItemCountsPossible" class="dropdown-item"
                (click)="ctx.RequestItemCount = count">{{count}}</a>
            </div>
            <button class="btn btn-light" type="button" (click)="getTyres(bandenmaat, ctx.RequestItemCount)">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div *ngIf="set" class="dropdown">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
              {{getSelectedItem('TyreSize', 'Alle bandenmaten')}}
            </button>
            <div *ngIf="tyreResult" class="dropdown-menu">
              <a class="dropdown-item" (click)="setSelectedItem('TyreSize', '')">Alle bandenmaten</a>
              <a *ngFor="let size of tyreResult.Sizes" class="dropdown-item"
                (click)="setSelectedItem('TyreSize',size)">{{size}}</a>
            </div>
          </div>
        </div>

        <div *ngIf="mainService.resizeInfo.isThisOrLarger('xl')">
          <div class="dropdown">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
              {{getSelectedItem('TyreSpeedIndex', 'Alle snelheden')}}
            </button>
            <div *ngIf="tyreResult" class="dropdown-menu">
              <a class="dropdown-item" (click)="setSelectedItem('TyreSpeedIndex', '')">Alle snelheden</a>
              <a *ngFor="let snelheid of tyreResult.SpeedIndications" class="dropdown-item"
                (click)="setSelectedItem('TyreSpeedIndex',snelheid)">{{snelheid}}</a>
            </div>
          </div>
        </div>

        <div *ngIf="mainService.resizeInfo.isThisOrLarger('lg') && tyreResult?.FilterDescriptions">
          <app-drop-down-filter [selectAll]="'Alle fabrikanten'" [descriptions]="tyreResult.FilterDescriptions.Brands"
            [multiSelect]="false" (filterChange)="filterChange('TyreBrand',$event)"></app-drop-down-filter>
        </div>

        <div *ngIf="mainService.resizeInfo.isThisOrLarger('md')">
          <div class="dropdown">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
              {{getSelectedItem('TyreKind', 'Alle categorieen')}}
            </button>
            <div *ngIf="tyreResult" class="dropdown-menu">
              <a class="dropdown-item" (click)="setSelectedItem('TyreKind','')">Alle categorieen</a>
              <a *ngFor="let category of tyreResult.Categories" class="dropdown-item"
                (click)="setSelectedItem('TyreKind',category)">{{mainService.getStringOrDefault(category, 'Alle categorieen')}}</a>
              <a class="dropdown-item" (click)="toggleFilterItem('RunFlatTyre')">
                <span *ngIf="getFilterOption('RunFlatTyre')"><i class="far fa-check-square"></i></span>
                <span *ngIf="!getFilterOption('RunFlatTyre')"><i class="far fa-square"></i></span>
                &nbsp;RunFlat</a>
            </div>
          </div>
        </div>

        <div>
          <app-sort-options [ctx]="ctx" [btn]="'btn-primary'"></app-sort-options>
        </div>

        <div>
          <app-voorraad-prijs-display-opties [ctx]="ctx" [viewKinds]="viewKinds" (stockOptionChanged)="doFilter()">
          </app-voorraad-prijs-display-opties>
        </div>

      </div>
      <div *ngIf="mainService.resizeInfo.isThisOrSmaller('sm')" class="d-flex flex-row align-items-center mt-0 g-0">

        <div>
          <div class="dropdown">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
              {{getSelectedItem('TyreSpeedIndex', 'Alle snelheden')}}
            </button>
            <div *ngIf="tyreResult" class="dropdown-menu">
              <a class="dropdown-item" (click)="setSelectedItem('TyreSpeedIndex', '')">Alle snelheden</a>
              <a *ngFor="let snelheid of tyreResult.SpeedIndications" class="dropdown-item"
                (click)="setSelectedItem('TyreSpeedIndex',snelheid)">{{snelheid}}</a>
            </div>
          </div>
        </div>

        <div *ngIf="tyreResult?.FilterDescriptions">
          <app-drop-down-filter [selectAll]="'Alle fabrikanten'" [descriptions]="tyreResult.FilterDescriptions.Brands"
            [multiSelect]="false" (filterChange)="filterChange('TyreBrand',$event)"></app-drop-down-filter>
        </div>

        <div>
          <div class="dropdown">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
              {{getSelectedItem('TyreKind', 'Alle categorieen')}}
            </button>
            <div *ngIf="tyreResult" class="dropdown-menu">
              <a class="dropdown-item" (click)="setSelectedItem('TyreKind','')">Alle categorieen</a>
              <a *ngFor="let category of tyreResult.Categories" class="dropdown-item"
                (click)="setSelectedItem('TyreKind',category)">{{category}}</a>
              <a class="dropdown-item" (click)="toggleFilterItem('RunFlatTyre')">
                <span *ngIf="getFilterOption('RunFlatTyre')"><i class="far fa-check-square"></i></span>
                <span *ngIf="!getFilterOption('RunFlatTyre')"><i class="far fa-square"></i></span>
                &nbsp;RunFlat</a>
            </div>
          </div>
        </div>
      </div>
    </app-header-panel>


    <div *ngIf="ctx.ViewKind == 1; then cardview else detailview"></div>
    <ng-template #detailview>
      <div *ngIf="tyres && tyres.length !== 0" class="card border-primary mt-3 text-bg-white" #itemContainer
        [ngStyle]="getContainerHeightStyle()">
        <app-tyres-list-view-boxed [ctx]="ctx" [tyres]="tyres" [set]="set" [maxItems]="settingsService.getSettingNumber('ShowTyresPerCount')"></app-tyres-list-view-boxed>
      </div>
    </ng-template>
    <ng-template #cardview>
      <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
      <div *ngIf="tyres && tyres.length !== 0" class="col mt-3" #itemContainer
        [ngStyle]="getContainerHeightStyle()">
        <app-tyres-card-view-boxed [ctx]="ctx" [tyres]="tyres" [set]="set"></app-tyres-card-view-boxed>
      </div>
    </div>
    </ng-template>

    <div *ngIf="!tyreResult && !busySearching && !set" class="container">
      <br />
      <br />
      <br />
      <br />
      <div class="alert alert-primary" role="alert">
        <i class="fas fa-info-circle"></i>&nbsp;Vul hierboven een bandenmaat in en druk op enter of de zoekknop. U kunt
        eventueel aangeven hoeveel banden
        u wilt hebben, dan wordt daar gelijk rekening mee gehouden voor voorraad en prijs informatie. Met de &nbsp;
        <i class="fas fa-th-list"></i>&nbsp; en &nbsp;
        <i class="fas fa-th"></i>&nbsp; knoppen kunt u wisselen tussen lijst weergave en tegels.
      </div>
    </div>
    <div *ngIf="tyres && tyres.length === 0" class="container">
      <br />
      <br />
      <br />
      <br />
      <div class="alert alert-warning" role="alert">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;Er werden geen banden gevonden die aan uw selectie voldoen. Pas
        uw selectie aan.
      </div>
    </div>
  </div>
</div>