import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-archive-order-kind-badge',
  templateUrl: './archive-order-kind-badge.component.html',
  styleUrls: ['./archive-order-kind-badge.component.scss']
})
export class ArchiveOrderKindBadgeComponent implements OnInit {
@Input() archiveOrderKind: number;
@Input() orderType: number;

  constructor() { }

  ngOnInit(): void {
  }

}
