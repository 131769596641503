import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ShoppingCartItem } from '../../_models/cart/shopping.cart.item';
import { CartService } from '../../_services/cart.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-item-count',
  templateUrl: './item-count.component.html',
  styleUrls: ['./item-count.component.scss']
})
export class ItemCountComponent implements OnInit {
  @Input() sci: ShoppingCartItem;
  @Input() center: boolean;
  @Input() isCart: boolean;
  
  constructor(
    public mainService: MainService,
    public cartService: CartService
  ) { }

  ngOnInit() {

  }

  getPerQuantity(): number {
    if (this.sci?.ItemInfo?.Price?.SalesUnit > 1) {
      return this.sci.ItemInfo.Price.SalesUnit;
    }
    return 1;
  }

}
