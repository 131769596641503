<div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false" (mouseleave)="closeDropDown(dropdown)">
  <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
    {{selectedText}} <span class="caret"></span>
  </button>
  <ng-container *ngIf="ctx">
    <ul *dropdownMenu class="dropdown-menu maxheight-dropdown" role="menu">
      <li role="menuitem" class="pointer">
        <a class="dropdown-item" (click)="clickFull()">
          <span *ngIf="allSelected"><i class="far fa-check-square"></i></span> 
          <span *ngIf="!allSelected"><i class="far fa-square"></i></span> 
          {{completeString}}
        </a>
      </li>
      <li *ngFor="let item of descriptions" class="pointer" role="menuitem">
        <a class="dropdown-item" (click)="clickItem(item)">
          <span *ngIf="item.selected"><i class="far fa-check-square"></i></span> 
          <span *ngIf="!item.selected"><i class="far fa-square"></i></span> 
          {{item.description}}
        </a>
      </li>
    </ul>
  </ng-container>
</div>
