<div class="row row-cols-1 row-cols-md-2 row-cols-xl-3" [@enterLeaveBounce]="parts.length">
<div *ngFor="let part of parts | catalogPartsFilter: catalogService:ctx | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.PartsCartItems" class="col">
    <div class="iteminfo-card card">
        <div class="card-header text-white text-bg-primary" (contextmenu)="mainService.showObject($event, part)">
            <app-part-item-number-link [partItemNumber]="part.PartItemNumber" [shopKind]="ctx.ShopKind">
            </app-part-item-number-link>
        </div>
        <div class="card-body p-2">
            <table class="table table-fix-striped iteminfo-table">
                <tr>
                    <td>{{part.Brand}} {{part.Description}}</td>
                </tr>
                <tr>
                    <td>
                        <img *ngIf="catalogService.partHasImage(ctx,part)" [src]="catalogService.partGetImage(ctx,part)" class="img-fluid" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <div *ngFor="let prop of ctx.PartsProperties[part.UniqueID]" class="row">
                                <div *ngIf="prop.Item1 != 'Unknown'" class="col-5">
                                    {{ctx.PartsPropertyTranslations[prop.Item1]['NL']}}
                                </div>
                                <div [ngClass]="{'col-7': prop.Item1 != 'Unknown', 'col-12': prop.Item1 == 'Unknown'}">
                                    <span>{{prop.Item2}}<br /></span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-item-info-template [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"
                            [nettoprijs]="ctx.Nettoprijs" [part]="part"></app-item-info-template>
                    </td>
                </tr>
            </table>
        </div>
        <div class="card-footer">
            <app-item-count [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"></app-item-count>
        </div>
    </div>
</div>