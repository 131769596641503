import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';
import { CartService } from '../../_services/cart.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-side-cart',
  templateUrl: './side-cart.component.html',
  styleUrls: ['./side-cart.component.scss']
})
export class SideCartComponent implements OnInit {
@Input() ctx: ContextMain;

  constructor(
    public mainService: MainService,
    public cartService: CartService
  ) { }

  ngOnInit() {
  }

}
