import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { SupplierInterfaceResponse } from '../../../../_models/admin/supplier-interface/supplier.interface.response';
import { AdminService } from '../../../../_services/admin/admin.service';
import { MainService } from '../../../../_services/main.service';
import { InterfaceData } from 'app/_models/admin/supplier-interface/interface.data';
import { InterfaceKindInfoModel } from 'app/_models/admin/supplier-interface/interface.kind.info.model';

@Component({
  selector: 'app-admin-supplier-interface-info',
  templateUrl: './admin-supplier-interface-info.component.html',
  styleUrls: ['./admin-supplier-interface-info.component.scss']
})
export class AdminSupplierInterfaceInfoComponent implements OnInit {
  public onClose: Subject<boolean>;
  itemNumber: string;
  itemGroup: string;
  interfaceData: InterfaceData;
  interfaceKindInfo: InterfaceKindInfoModel;
  withItemGroup: boolean;
  busy = false;
  response: SupplierInterfaceResponse;
  focusInput = new EventEmitter<boolean>();

  constructor(
    public mainService: MainService,
    private adminService: AdminService,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    setTimeout(() => {
      this.focusInput.emit(true);
      if (this.itemNumber && this.itemGroup) this.doInfo(null);
    }, 0);
  }

  doInfo(event: any) {
    this.busy = true;
    if (this.interfaceData) {
      this.adminService.supplierInterfaceDoInfo(this.interfaceData, this.itemNumber, this.itemGroup)
        .subscribe(response => {
          this.response = response;
          this.busy = false;
        });
    } else {
      this.adminService.supplierInterfaceKindDoInfo(this.interfaceKindInfo.InterfaceKind, this.itemNumber)
        .subscribe(response => {
          this.response = response;
          this.busy = false;
        });
    }
  }

  clear() {
    this.itemNumber = '';
    this.response = null;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  public confirm(event: MouseEvent) {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public decline(event: MouseEvent) {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
