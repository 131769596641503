import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-admin-dropdown',
  templateUrl: './admin-dropdown.component.html',
  styleUrls: ['./admin-dropdown.component.scss']
})
export class AdminDropdownComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() modelValues: any[];
  @Input() modelDisplayValues: { [key: string]: any };
  @Input() modelValueProperty: string;
  @Input() modelDisplayProperty: string;
  @Input() modelEmptyDisplayValue: string;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() disabled: boolean;
  @Input() multiSelect = false;
  @Input() emptyIsAllSelected = false;
  @Input() multiSelectSeparator = ', ';
  @Input() buttonTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() noneSelectionLabel: string;

  constructor() { }

  ngOnInit() {
    if (this.emptyIsAllSelected && this.model.length === this.getModelValues().length) {
      this.model = [];
    }
  }

  getModelValues(): any[] {
    if (this.modelValues) { return this.modelValues; }
    if (this.modelDisplayValues) { return Object.keys(this.modelDisplayValues); }
    return null;
  }

  getModelDisplayValue(value: any): any {
    if (!this.multiSelect) { return this.getModelDisplayValueInternal(value); }
    const result: string[] = [];
    if (value && value.length > 0) {
      value.forEach(item => {
        result.push(this.getModelDisplayValueInternal(item));
      });
    }
    if (!result.length) { return this.modelEmptyDisplayValue; }
    return result.join(this.multiSelectSeparator);
  }

  getModelDisplayValueInternal(value: any): any {
    if (this.modelDisplayValues && this.modelDisplayValues[value]) { return this.modelDisplayValues[value]; }
    if (this.modelValues && value && value[this.modelDisplayProperty]) { return value[this.modelDisplayProperty]; }
    if (this.modelValueProperty && this.modelValues && value!==undefined) {
      const item = this.modelValues.find(i => i[this.modelValueProperty] === value);
      if (item && this.modelDisplayProperty) {
        return item[this.modelDisplayProperty];
      } else {
        return item;
      }
    }
    if (value === undefined && this.modelEmptyDisplayValue) return this.modelEmptyDisplayValue;
    return value;
  }

  getDisplayValue(value: any): any {
    if (this.modelDisplayValues && this.modelDisplayValues[value]) { return this.modelDisplayValues[value]; }
    if (this.modelDisplayProperty && value[this.modelDisplayProperty]) { return value[this.modelDisplayProperty]; }
    if (this.modelValues && value && value[this.modelDisplayProperty]) { return value[this.modelDisplayProperty]; }
    return value;
  }

  getIndexInModel(model: any[], value: any): number {
    for (let index = 0; index < model.length; index++) {
      if (String(model[index]) === String(value)) { return index; }
    }
    return -1;
  }

  modelContainsValue(obj: any): boolean {
    let value = obj;
    if (this.modelValueProperty) { value = obj[this.modelValueProperty]; }
    if (this.multiSelect) {
      if (this.model && this.model.length > 0) {
        return this.getIndexInModel(this.model, value) >= 0;
      }
    }
    return false;
  }

  emptyIsAllSelectedAndEmpty(): boolean {
    return this.multiSelect
      && this.emptyIsAllSelected
      && (!this.model || this.model.length === 0);
  }

  setModel(obj: any) {
    let value = obj;
    if (this.modelValueProperty) { value = obj[this.modelValueProperty]; }
    if (!this.multiSelect) {
      if (this.isNumber(this.model)) value = +value;
      this.modelChange.emit(value);
    } else {
      let index = -1;
      if (!this.model) this.model = [];
      if (this.model && this.model.length > 0) { index = this.getIndexInModel(this.model, value); }
      if (index === -1 && this.emptyIsAllSelected && this.model.length === 0) {
        this.getModelValues().forEach(item => {
          if (item !== value) this.model.push(item);
        });
      } else if (index === -1) {
        this.model.push(value);
      } else {
        this.model.splice(index, 1);
      }
      if (this.emptyIsAllSelected && this.model.length >= this.getModelValues().length) {
        this.model = [];
      }
    }
  }

  isNumber = (val: any) => typeof val === "number" && val === val;

}
