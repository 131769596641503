import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { CartService } from '../../../_services/cart.service';
import { MainService } from '../../../_services/main.service';
import { RimAndTyreService } from '../../../_services/rim-and-tyre.service';

@Component({
  selector: 'app-tyre-info',
  templateUrl: './tyre-info.component.html',
  styleUrls: ['./tyre-info.component.scss']
})
export class TyreInfoComponent implements OnInit {
tyre: ShoppingCartItem;
rimAndTyreService: RimAndTyreService;

public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }


  public choose(obj) {
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

}
