import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AddressModel } from '../../../../../../_models/admin/supplier-interface/address.model';
import { InterfaceData } from '../../../../../../_models/admin/supplier-interface/interface.data';
import { InterfaceKindInfoModel } from '../../../../../../_models/admin/supplier-interface/interface.kind.info.model';
import { SupplierInterfaceData } from '../../../../../../_models/admin/supplier-interface/supplier.interface.data';
import { BranchModel } from '../../../../../../_models/common/branch.model';
import { SupplierModel } from '../../../../../../_models/common/supplier.model';
import { AdminSupplierInterfaceService } from '../../../../../../_services/admin/admin-supplier-interface.service';
import { MainService } from '../../../../../../_services/main.service';

@Component({
  selector: 'app-proxy-interface-data-edit',
  templateUrl: './proxy-interface-data-edit.component.html',
  styleUrls: ['./proxy-interface-data-edit.component.scss']
})
export class ProxyInterfaceDataEditComponent implements OnInit {
  supplierInterfaceData: SupplierInterfaceData;
  interfaceData: InterfaceData;
  addNew: boolean;
  mainService: MainService;
  adminSupplierInterfaceService: AdminSupplierInterfaceService;
  editInterfaceData: InterfaceData;
  branchModelValues: BranchModel[];
  interfaceKinds: InterfaceKindInfoModel[];
  kindInfo: InterfaceKindInfoModel;
  extraLogging: boolean;
  testUrl: boolean;

  public onClose: Subject<InterfaceData>;

  constructor(private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.editInterfaceData = JSON.parse(JSON.stringify(this.interfaceData)) as InterfaceData;
    this.kindInfo = this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, 1000);
    this.onClose = new Subject();
    this.extraLogging = this.adminSupplierInterfaceService.isProxyExtraLoggingSet(this.editInterfaceData);
    this.testUrl = this.adminSupplierInterfaceService.isProxyTestUrlSet(this.editInterfaceData);
  }

  public confirm() {
    this.onClose.next(this.editInterfaceData);
    this.bsModalRef.hide();
  }

  public cancel() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

}
