import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { SelectListItem } from '../../../../_models/common/select.list.item';
import { Settings } from '../../../../_models/common/settings';
import { ApiService } from '../../../../_services/api.service';
import { CatalogService } from '../../../../_services/catalog.service';

@Component({
  selector: 'app-side-panel-catalog',
  templateUrl: './side-panel-catalog.component.html',
  styleUrls: ['./side-panel-catalog.component.scss']
})
export class SidePanelCatalogComponent implements OnInit {
  @Input() ctx: ContextCatalog;

  constructor(
    public catalogService: CatalogService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
  }

  stockChange(item: SelectListItem) {
    this.ctx.Voorraad = item.selected;
    this.apiService.saveSettings(new Settings(this.ctx.StockOptionString, this.ctx.Voorraad.toString()));
    this.catalogService.fillFilterParts(this.ctx);
  }

  filterChange(item: SelectListItem, keyPart: string, useRegex: boolean) {
    this.catalogService.setFilter(this.ctx, keyPart, item.description, useRegex, item.selected);
    this.catalogService.fillFilterParts(this.ctx);
  }

}
