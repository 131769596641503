<ng-container *ngIf="ctx.ViewKind==viewKind.Compact;else normalView">
    <app-tyre-list-item-compact
        *ngFor="let tyre of (tyres | maxItemsFilter:_maxItemCount | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']); trackBy: mainService.trackByShoppingCartItem; let odd=odd;"
        [odd]="odd" [ctx]="ctx" [tyre]="tyre" [set]="set"></app-tyre-list-item-compact>
</ng-container>
<ng-template #normalView>
    <app-tyre-list-item
        *ngFor="let tyre of (tyres | maxItemsFilter:_maxItemCount | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']); trackBy: mainService.trackByShoppingCartItem; let odd=odd;"
        [odd]="odd" [ctx]="ctx" [tyre]="tyre" [set]="set"></app-tyre-list-item>
</ng-template>

<app-show-more-button [(currentItemCount)]="_maxItemCount" [showPerCount]="maxItems" [totalItemCount]="tyres?.length"></app-show-more-button>

<div *ngIf="tyres && tyres?.length === 0" class="row">
    <div class="col-12">
        <div class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i> Er bleven geen banden over.
        </div>
    </div>
</div>