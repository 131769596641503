import { Component, OnInit, Input } from '@angular/core';
import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { PartsCategory } from '../../../../_models/catalog/parts.category';
import { Animations } from '../../../../_animations/animations';
import { MainService } from '../../../../_services/main.service';
import { ShopService } from '../../../../_services/shop.service';
import { CatalogService } from '../../../../_services/catalog.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { SettingsService } from '../../../../_services/settings.service';
import { ShopRouteConfig } from '../../../../_models/common/shop.route.config';
import { UniversalCarType } from '../../../../_models/car-types/universal.car.type';
import { UniversalPartsCategory } from '../../../../_models/catalog/universal.parts.category';
import { ApiService } from '../../../../_services/api.service';
import { Settings } from '../../../../_models/common/settings';

@Component({
  selector: 'app-catalog-groups-scroll-layout',
  templateUrl: './catalog-groups-scroll-layout.component.html',
  styleUrls: ['./catalog-groups-scroll-layout.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CatalogGroupsScrollLayoutComponent implements OnInit {
  shopSoort = ShopSoort;
  shopRouteConfig = ShopRouteConfig;
  @Input() ctx: ContextCatalog;
  filterString: string;
  filterResult = { count: 0 };

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public catalogService: CatalogService,
    public settingsService: SettingsService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    console.info('CatalogGroupsScrollLayoutComponent ngOnInit()');
  }

  clickFavouritesCollapsed(ctx): void {
    if (ctx) {
      ctx.FavouritesCollapsed = !ctx.FavouritesCollapsed;
      this.apiService.saveSettings(new Settings('Catalog_FavouritesCollapsed', ctx.FavouritesCollapsed));
    }
  }

  containsFilter(text, filter) {
    return filter && text.toLowerCase().indexOf(filter) >= 0;
  }

  partsCatIsOpen(cat: PartsCategory) {
    return cat.PartsDescriptions.length > 0 && cat['isOpen'];
  }

  partsCatIsClosed(cat: PartsCategory) {
    return cat.PartsDescriptions.length > 0 && !cat['isOpen'];
  }

  partsCatIsEmpty(cat: PartsCategory) {
    return !cat.PartsDescriptions || cat.PartsDescriptions.length === 0;
  }

  clickCategory(event: MouseEvent, active: boolean, carType: UniversalCarType, category: UniversalPartsCategory, description: string) {
    event.stopPropagation();
    if (active) { this.catalogService.clickCategory(event, carType, category, description); }
  }

}
