<ng-container [ngSwitch]="property.Item1">
  <div class="row parts-property-list" *ngSwitchDefault>
    <div *ngIf="partsPropertyTranslations && partsPropertyTranslations[property.Item1]" class="col-5 label">{{partsPropertyTranslations[property.Item1]['NL']}}</div>
    <div class="col-7">
      <span [innerHtml]="property.Item2 | safeHtml"></span>
    </div>
  </div>
  <div class="row parts-property-list" *ngSwitchCase="'Unknown'">
    <div class="col-12">
      <span [innerHtml]="property.Item2 | safeHtml"></span>
    </div>
  </div>
  <ng-container *ngSwitchCase="'OENr'">
    <app-catalog-parts-link-property [partsPropertyTranslations]="partsPropertyTranslations" [shopKind]="shopKind"
      [part]="part" [property]="property" [maxItems]="3"></app-catalog-parts-link-property>
  </ng-container>
  <ng-container *ngSwitchCase="'SystemNumber'">
    <app-catalog-parts-link-property [partsPropertyTranslations]="partsPropertyTranslations" [shopKind]="shopKind"
      [part]="part" [property]="property" [maxItems]="3"></app-catalog-parts-link-property>
  </ng-container>
</ng-container>