import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ThirdpartyShopScrollLayoutComponent } from '../../../../portal/thirdparty-shop/thirdparty-shop-scroll-layout/thirdparty-shop-scroll-layout.component';

@Component({
  selector: 'app-side-select-list-item',
  templateUrl: './side-select-list-item.component.html',
  styleUrls: ['./side-select-list-item.component.scss']
})
export class SideSelectListItemComponent implements OnInit {
  @HostBinding('class.list-group-item') public bindStyle: boolean = true;
  @HostBinding('class.list-group-item-action') public bindStyle2: boolean = true;
  @HostBinding('class.pointer') public bindStyle3: boolean = true;
  @HostBinding('class.p-0') public bindStyle4: boolean = true;
  @HostBinding('class.list-group-item-secondary') public bindStyle5: boolean = false;
  @HostBinding('class.active') public bindStyle6: boolean = false;
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  _active: boolean;
  @Input('active')
  get active(): boolean { return this._active; }
  set active(value: boolean) {
    this._active = value;
    this.bindStyle5 = this._active;
    this.bindStyle6 = this._active;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
