import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (!value) { value = 0; }
    return '€ ' + value.toFixed(2);
  }

}
