import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-checkbox',
  templateUrl: './admin-checkbox.component.html',
  styleUrls: ['./admin-checkbox.component.scss']
})
export class AdminCheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() extraLabel: string;
  _model: boolean;
  get model(): boolean {
    if (this.invertModel) {
      return !this._model;
    } else {
      return this._model;
    }
  }
  @Input('model')
  set model(value: boolean) {
    if (this.invertModel) {
      this._model = !value;
    } else {
      this._model = value;
    }
  }
  _invertModel: boolean;
  get invertModel(): boolean { return this._invertModel; }
  @Input('invertModel')
  set invertModel(value: boolean) {
    this._invertModel = value;
    if (this._invertModel) { this._model = !this._model; }
  }
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() centerLabel: boolean;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Output() modelChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
  }

  changedModel(): void {
    this.modelChanged.emit(this.model);
  }

  changeModel(event: boolean): void {
    this.modelChange.emit(this.model);
  }

}
