<ng-template #customTemplate let-model="item" let-index="index">
  <div class="main-decription">
    {{model.Description}}&nbsp;<span class="text-low">({{model.ID}})</span>
  </div>
  <div class="parts-type-descriptions text-wrap text-low fw-lighter font-italic">{{model.DescriptionOriginal}}
  </div>
</ng-template>
<div class="input-group input-group-sm">
  <input type="text" class="form-control" [(ngModel)]="partsTypeDescription"
    [typeahead]="adminCatalogService.getTypeaheadPartsTypes(settings.LearnedPartsTypes,settings.PartsTypeCross,catalog,partsType.ID,partsTypeDescription,8,hasFocus)"
    [typeaheadMinLength]="0" [typeaheadOptionsLimit]="8" (typeaheadOnSelect)="typeaheadOnSelect($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
    [typeaheadOptionField]="'Description'" [typeaheadItemTemplate]="customTemplate" container="body" autocomplete="off">
    <span class="input-group-text binder-input-group-append">
      <span *ngIf="isOk" class="text-success"><i class="fas fa-check"></i></span>
      <span *ngIf="!isOk" class="text-danger"><i class="fas fa-times"></i></span>
      <!-- <span class="text-warning"><i class="fas fa-question"></i></span> -->
    </span>
    <button *ngIf="isOk" class="btn btn-outline-secondary" type="button" (click)="deleteCross(true)"><i class="fas fa-trash-alt"></i></button>
    <button *ngIf="isOk" class="btn btn-outline-secondary" type="button" (click)="addCross.emit(catalogPartsTypeId)"><i class="fas fa-plus"></i></button>
</div>