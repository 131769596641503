import { Component, Input, OnInit } from '@angular/core';
import { ArchiveOrder } from '../../../../_models/orders/archive.order';
import { CustomerModel } from '../../../../_models/common/customer.model';
import { Animations } from '../../../../_animations/animations';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-archive-order',
  templateUrl: './archive-order.component.html',
  styleUrls: ['./archive-order.component.scss']
})
export class ArchiveOrderComponent implements OnInit {
  @Input() order: ArchiveOrder;
  @Input() customer: CustomerModel;
  @Input() branchName: string;
  
  constructor(
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }

}
