<div class="search-container" (contextmenu)="mainService.showDebugInfo($event, ctxCarTypeSelection?.Timing, ctxCarTypeSelection)">
  <div *ngIf="mode==0" class="license-plate-search-control">
    <div class="license-plate-container">
      <div class="license-plate-left rounded-start">
        <img class="euro-sign" src="/assets/img/licenseplate/lpeuro.png">
      </div>
      <span class="license-plate rounded-end" (click)="setMode(0)">
        <input type="text" appLicensePlate [appFocus]="focusLicensePlate" class="license-plate-input"
          [ngModel]="licensePlate" (ngModelChange)="licensePlate = $event" (ngModelChange)="modelChange(licensePlate)" (click)="clear()"
          (keyup.enter)="searchLicensePlate(licensePlate)" (focus)="gotFocus.emit(true)" />
      </span>
    </div>
  </div>
  <div *ngIf="mode==1" class="bmt-search-control">
    <app-brand-model-type-search [ctxCarTypeSelection]="ctxCarTypeSelection"
      (carTypeSelected)="carTypeSelectedIntern($event)" [shopSoort]="ctx.DefaultCatalog"></app-brand-model-type-search>
  </div>
  <input *ngIf="mode==2" [appFocus]="focusVin" class="vin-search-control rounded" type="text"
    placeholder="chassisnummer..." [(ngModel)]="vin" (keyup.enter)="searchVin(vin)" />
  <div *ngIf="mode==3" class="history-search-control">
    <app-history-search [ctxCarTypeSelection]="ctxCarTypeSelection" [focusHistory]="focusHistory"
      (carTypeSelected)="carTypeSelectedIntern($event)"></app-history-search>
  </div>
  <div class="mode-buttons ms-1">
    <div class="button-container">
      <div>
        <button class="btn btn-primary mode-button me-1" [ngClass]="{'active': mode==0 }" (click)="setMode(0)"
          type="button" tooltip="Klik hier om via kenteken te zoeken" placement="top" container="body"><i
            class="far fa-rectangle-wide"></i></button>
        <button class="btn btn-primary mode-button" [ngClass]="{'active': mode==1 }" (click)="setMode(1)" type="button"
          tooltip="Klik hier om via merk, model, type te kiezen" placement="top" container="body"><i
            class="fas fa-car"></i></button>
      </div>
      <div class="mt-1">
        <button class="btn btn-primary mode-button me-1" [ngClass]="{'active': mode==2 }" (click)="setMode(2)"
          type="button" tooltip="Klik hier om via chassisnummer te zoeken" placement="bottom" container="body"><i
            class="fas fa-search"></i></button>
        <button class="btn btn-primary mode-button" [ngClass]="{'active': mode==3 }" (click)="setMode(3)" type="button"
          tooltip="Klik hier om een eerder gekozen auto te kiezen" placement="bottom" container="body"><i
            class="fas fa-history"></i></button>
      </div>
    </div>

  </div>

  <!-- <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-mmt" (click)="searchBrandModelType()">
        <i class="fas fa-car"></i>
      </div>
      <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-history" (click)="searchHistory()" tooltip="Klik hier om een eerder gekozen auto te kiezen."
        placement="right" container="body">
        <i class="fas fa-history"></i>
      </div> -->
</div>
