import { LoyaltyShopItemImage } from './loyalty.shop.item.image';

export class LoyaltyShopItem {
    ID = 0;
    Wholesaler = 0;
    Title = '';
    Description = '';
    DescriptionExtended = '';
    InternalItemNumber = 0;
    ItemNumber = '';
    ItemGroup = 0;
    ItemDescription = '';
    Credits = 0;
    Price = 0;
    Active = true;
    Images: LoyaltyShopItemImage[];
}
