<app-admin-form [title]="'Aanbiedingen Onderhoud'" [object]="promoAdmin">
  <p *ngIf="!promoAdmin || promoAdmin.Promotions.length==0">Op dit moment staan er geen eigen aanbiedingen in het
    systeem.</p>
  <p *ngIf="promoAdmin && promoAdmin.Promotions.length>0">
    Hier kunnen de aanbiedingen onderhouden worden en kan de volgorde van de aanbiedingen in de ShopPortal aangepast
    worden.
  </p>
  <table class="table table-striped small">
    <thead>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
      <tr>
        <th>Titel</th>
        <th>Vanaf</th>
        <th>Tot</th>
        <th>Status</th>
        <th class="text-center">Volgorde</th>
        <th class="text-end">Aktie</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let promo of promoAdmin.Promotions | promotionSortOrder">
        <td>{{promo.Titel}}</td>
        <td>{{promo.GeldigVanaf | date: 'dd-MM-yyyy'}}</td>
        <td>{{promo.GeldigTot | date: 'dd-MM-yyyy'}}</td>
        <td>
          <span *ngIf="promotionService.getStatus(promo) == -1" class="badge text-bg-info">Toekomstig</span>
          <span *ngIf="promotionService.getStatus(promo) == 0" class="badge text-bg-primary">Aktief</span>
          <span *ngIf="promotionService.getStatus(promo) == 1" class="badge text-bg-danger">Verlopen</span>
          <span *ngIf="promotionService.getStatus(promo) == 2" class="badge text-bg-warning">Niet aktief</span>
        </td>
        <td class="text-center">
          <div *ngIf="promotionService.getStatus(promo) < 1" class="btn-group">
            <button class="btn btn-xs btn-outline-secondary"
              [disabled]="mainService.isItemTop(promoAdmin.Promotions, promo, 'Volgorde')" (click)="clickUp(promo)"><i
                class="fas fa-chevron-up"></i></button>
            <button class="btn btn-xs btn-outline-secondary"
              [disabled]="mainService.isItemBottom(promoAdmin.Promotions, promo, 'Volgorde') || promotionService.getStatus(mainService.getNextItem(promoAdmin.Promotions, promo, 'Volgorde'))===1"
              (click)="clickDown(promo)"><i class="fas fa-chevron-down"></i></button>
          </div>
        </td>
        <td class="text-end">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary" [routerLink]="['edit',promo.ID]"><i
                class="fas fa-edit"></i>
              Wijzig</button>
            <button class="btn btn-xs btn-outline-secondary"
              (click)="adminPromotionService.deletePromotionDialog(promo)"><i class="fas fa-trash-alt"></i>
              Verwijder</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</app-admin-form>