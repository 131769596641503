<div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
<div class="col" *ngFor="let promotion of ctx.Promotions" >
    <div class="iteminfo-card card mb-3">
        <div class="card-header text-bg-primary promotion-hand"
            (click)="promotionService.orderPromotion(promotion)"
            (contextmenu)="mainService.showObject($event, promotion)">{{promotion.Titel}}</div>
        <div class="card-body card-body-promotion" (click)="promotionService.orderPromotion(promotion)">
            <div *ngIf="promotion.Images && promotion.Images.length > 0" class="row">
                <div class="col image-col">
                    <div *ngFor="let image of promotion.Images; let index = index;" class="image-container mx-auto text-center" [ngClass]="{'image-container-with-text': promotion.Omschrijving}">
                        <img class="promotion-image" [src]="image.AfbeeldingsUrl"
                            [@fadeInOut]="promotionService.getCurrentImage(promotion) === index" />
                    </div>
                </div>
            </div>
            <div *ngIf="promotion.Omschrijving" class="row description">
                <div class="col" [innerHTML]="mainService.getSafeHtml(promotion.Omschrijving)"></div>
            </div>
        </div>
        <div *ngIf="promotionService.hasArticles(promotion)" class="card-footer pb-0">
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary" (click)="promotionService.orderPromotion(promotion)">Bestel</button>
                </div>
                <div class="col text-nowrap text-end">
                    <span *ngIf="promotion.TekstVan"
                        style='text-decoration: line-through;'>{{promotion.TekstVan}}</span>
                    <span *ngIf="promotion.TekstVoor"
                        style="font-size: larger; font-weight:bold;">&nbsp;{{promotion.TekstVoor}}</span>
                    <span *ngIf="!promotion.TekstVoor" style="font-size: x-large; font-weight: bolder;">
                        <span *ngIf="ctx['busy']"><i class="fas fa-sync fa-spin"></i></span>
                        <span *ngIf="!ctx['busy']">
                            {{promotionService.getPrice(ctx.CartItems, promotion) | price}}
                        </span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col promotion-remaining text-danger text-center">{{promotionService.showRemaining(promotion.GeldigTot)}}</div>
            </div>
        </div>
    </div>
</div>
</div>