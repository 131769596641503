import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MainService } from '../../../_services/main.service';
import { Aanbieding } from '../../../_models/promotions/aanbieding';
import { AanbiedingFiliaal } from '../../../_models/promotions/aanbieding.filiaal';
import { PromotionService } from '../../../_services/promotion.service';
import { UploadEvent } from '../../../_common/upload/upload-event.model';
import { UploadFile } from '../../../_common/upload/upload-file.model';
import { HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdminPromotionService } from '../../../_services/admin/admin-promotion.service';

@Component({
  selector: 'app-admin-promotion-edit',
  templateUrl: './admin-promotion-edit.component.html',
  styleUrls: ['./admin-promotion-edit.component.scss']
})
export class AdminPromotionEditComponent implements OnInit {
  promotion: Aanbieding;
  branches: AanbiedingFiliaal[];
  uploadPercent: number;
  savedForImages = false;


  constructor(
    public mainService: MainService,
    public adminPromotionService: AdminPromotionService,
    public promotionService: PromotionService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.promotion = data.promotion;
        this.adminPromotionService.getPromotionBranches(this.promotion)
          .subscribe(branches => this.branches = branches);
      });
  }

  branchChange(branch: AanbiedingFiliaal) {
    let allBranches: AanbiedingFiliaal;
    let allSelected = true;
    this.promotion.Filialen = [];
    this.branches.forEach(b => {
      if (branch.Filiaalnr === -1) {
        b.Selected = branch.Selected;
      } else if (b.Filiaalnr !== -1 && b.Selected) {
        this.promotion.Filialen.push(b.Filiaalnr);
      } else if (b.Filiaalnr !== -1 && !b.Selected) {
        allSelected = false;
      }
      if (b.Filiaalnr === -1) { allBranches = b; }
    });
    allBranches.Selected = allSelected;
    if (allSelected) { this.promotion.Filialen = []; }
  }

  upload(event: UploadEvent) {
    const fileList: UploadFile[] = [];
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        fileList.push(droppedFile);
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.info(droppedFile.relativePath, fileEntry);
      }
    }
    this.saveIfNewPromotion(this.promotion)
      .subscribe(promo => {
        if (promo) {
          this.promotion = promo;
          this.adminPromotionService.addPromotionImages(this.promotion.AanbiedingID, fileList)
            .subscribe(progress => {
              if (progress.type === HttpEventType.UploadProgress) {
                this.uploadPercent = Math.round(100 * progress.loaded / progress.total);
              } else if (progress.type === HttpEventType.Response) {
                const updatedPromo = progress.body as Aanbieding;
                this.promotion.Images = updatedPromo.Images;
              }
            });
        }
      });
  }

  saveIfNewPromotion(promotion: Aanbieding): Observable<Aanbieding> {
    if (promotion.ID === -1) {
      return this.adminPromotionService.savePromotion(promotion)
        .pipe(mergeMap(promo => {
          if (promo) {
            this.savedForImages = true;
            return of(promo);
          }
        }));
    } else {
      this.savedForImages = false;
      return of(promotion);
    }
  }

  cancelPromotion() {
    if (this.savedForImages) {
      this.adminPromotionService.deletePromotion(this.promotion.AanbiedingID)
        .subscribe(ok => {
          if (ok) {
            this.savedForImages = false;
            this.router.navigate(['/admin/promotions']);
          }
        });
    } else {
      this.router.navigate(['/admin/promotions']);
    }
  }

}
