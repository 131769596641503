export class UserIdentificationModel {
    Wholesaler: number;
    Branch: number;
    Customer: number;
    UserID: number;
    Uid: string;
    Session: number;

    constructor() {
        this.Wholesaler = 0;
        this.Branch = 0;
        this.Customer = 0;
        this.UserID = 0;
        this.Uid = '';
        this.Session = 0;
    }

    static getClone(uid: UserIdentificationModel): UserIdentificationModel {
        const uidNew = new UserIdentificationModel();
        uidNew.Wholesaler = uid.Wholesaler || 0;
        uidNew.Branch = uid.Branch || 0;
        uidNew.Customer = uid.Customer || 0;
        uidNew.UserID = uid.UserID || 0;
        uidNew.Uid = uid.Uid || '';
        uidNew.Session = uid.Session || 0;
        return uidNew;
    }

    static copyFromTo(uid1: UserIdentificationModel, uid2: UserIdentificationModel) {
        uid2.Wholesaler = uid1.Wholesaler;
        uid2.Branch = uid1.Branch;
        uid2.Customer = uid1.Customer;
        uid2.UserID = uid1.UserID;
        uid2.Uid = uid1.Uid;
        uid2.Session = uid1.Session;
    }

    static areEqual(uid1: UserIdentificationModel, uid2: UserIdentificationModel): boolean {
        return (uid2.Wholesaler === uid1.Wholesaler
            && uid2.Branch === uid1.Branch
            && uid2.Customer === uid1.Customer
            && uid2.UserID === uid1.UserID
            && uid2.Uid === uid1.Uid
            && uid2.Session === uid1.Session);
    }
}
