<div *ngIf="ctx" class="voorraad-prijs-opties">
  <div class="form-check">
    <input icheck class="form-check-input" type="checkbox" [checked]="ctx.Voorraad" (change)="changeStockOption()">
    <label class="form-check-label header-panel-label" *ngIf="ctx.VoorraadOptie" placement="bottom" container="body"
      tooltip="Laat alleen artikelen zien waarvan de voorraad bekend en voldoende is.">Voorraad</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" icheck type="checkbox" [checked]="ctx.Nettoprijs" (change)="changeNetPriceOption()">
    <label class="header-panel-label" *ngIf="ctx.NettoprijsOptie">Nettoprijs</label>
  </div>
</div>