import { LicensePlateReason } from "./license.plate.reason";
import { LicensePlateType } from "./license.plate.type";

export class LicensePlateRequest {
  Reason: LicensePlateReason;
  LicensePlateNumber: string;
  LicensePlateType: LicensePlateType;
  DuplicateCode:number;
  Amount: number;
  RdwCompaniesCardNumber: string;
  IdentificationNumber: string;
  LastNumbersVIN: string;
  LaminateCodes: string;
  KentekencardNumber: string;
  RdwCompanyNumber: string;
  RdwCompanyCardNumber: string;
  PlateReleaseCode: string;
}
