import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadZeros'
})
export class LeadZerosPipe implements PipeTransform {

  transform(value: any, size: number): any {
    // const s = value + '';
    // return s.padStart(size, '0');

    var s = value + '';
    while (s.length < size) s = '0' + s;
    return s;

  }

}
