<app-container [renderCheck]="ctx" [shopSoort]="shopSoort.WielenEnBanden">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="7"></li>
    <span class="ms-auto">
      <span *ngIf="ctx.fetching"><i class="fas fa-sync fa-spin"></i></span>
    </span>
  </app-breadcrumb-header>

  <app-car-type-info-header [ctx]="ctx"></app-car-type-info-header>

  <app-side-panel-and-content *ngIf="ctx.Wheels">
      <ng-container side-panel>
          <app-side-panel>
              <app-side-panel-header><i class="fas fa-sort-amount-down"></i> Sorteren</app-side-panel-header>
              <app-side-panel-body>
                <app-sort-options [ctx]="ctx" [btn]="'btn-light w-100'"></app-sort-options>
              </app-side-panel-body>
            </app-side-panel>
      
            <app-side-panel>
              <app-side-panel-header>
                <i class="fas fa-filter"></i> Filteren
                <span class="float-end filter-result text-low">{{wheelsFiltered.length}} van {{ctx.Wheels.length}}</span>
              </app-side-panel-header>
              <app-side-panel-accordion-filter *ngIf="ctx.VoorraadOptie" [title]="'Voorraad'" [descriptions]="ctx.FilterDescriptions.Stock" (filterChange)="stockChange($event)"></app-side-panel-accordion-filter>
              <app-side-panel-accordion-filter [title]="'Materiaal'" [descriptions]="ctx.FilterDescriptions.WheelKinds" (filterChange)="filterChange('WheelKinds',$event)"></app-side-panel-accordion-filter>
              <app-side-panel-accordion-filter [title]="'Diameters'" [maxItems]="5" [descriptions]="ctx.FilterDescriptions.Diameters" (filterChange)="filterChange('Diameters',$event)"></app-side-panel-accordion-filter>
              <app-side-panel-accordion-filter [title]="'Merk'" [maxItems]="5" [descriptions]="ctx.FilterDescriptions.Brands" (filterChange)="filterChange('Brands',$event)"></app-side-panel-accordion-filter>
              <app-side-panel-accordion-filter [title]="'Options'" [descriptions]="ctx.FilterDescriptions.Options" [hideEmptyItems]="true" (filterChange)="filterOptionsChange($event)"></app-side-panel-accordion-filter>
              <app-side-panel-accordion-filter [title]="'Bandenmaten'" [maxItems]="5" [descriptions]="ctx.FilterDescriptions.TyreDimensions" (filterChange)="filterChange('TyreDimensions',$event)"></app-side-panel-accordion-filter>
            </app-side-panel>
        </ng-container>
      <ng-container content>
          <app-wheel-list [ctx]="ctx" [wheels]="wheelsFiltered" [maxItems]="settingsService.getSettingNumber('ShowWheelsPerCount')"></app-wheel-list>
      </ng-container>
  </app-side-panel-and-content>

</app-container>
