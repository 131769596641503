import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminSetting } from '../../../_models/admin/settings/admin.setting';
import { TestComponentRenderer } from '@angular/core/testing';

@Component({
  selector: 'app-admin-checkbox-list',
  templateUrl: './admin-checkbox-list.component.html',
  styleUrls: ['./admin-checkbox-list.component.scss']
})
export class AdminCheckboxListComponent implements OnInit {
  @Input() label: string;
  @Input() modelArray: AdminSetting[];
  @Input() invertModel: boolean;
  @Output() modelChange: EventEmitter<AdminSetting> = new EventEmitter<AdminSetting>();
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() checkboxCols: number;
  @Input() dividerKind: number;
  @Output() modelChanged: EventEmitter<AdminSetting> = new EventEmitter<AdminSetting>();
  uniqueId = Math.random().toString(36).substring(2);
  colClass: string;

  constructor() { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
    if (!this.checkboxCols) { this.checkboxCols = 3; }
    switch (this.checkboxCols) {
      case 1:
        this.colClass = 'col-12';
        break;
      case 2:
        this.colClass = 'col-6';
        break;
      case 3:
        this.colClass = 'col-4';
        break;
      case 4:
        this.colClass = 'col-3';
        break;
      case 6:
        this.colClass = 'col-2';
        break;
      default:
        this.colClass = 'col';
        break;
    }
  }

  getValue(setting: AdminSetting): boolean {
    if (this.invertModel) { return !setting.Value; }
    return setting.Value;
  }

  changeModel(setting: AdminSetting): void {
    setting.Value = !setting.Value;
    this.modelChanged.emit(setting);
  }


}
