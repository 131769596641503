import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ApiService } from '../../_services/api.service';
import { ShopService } from '../../_services/shop.service';
import { CarTypeService } from '../../_services/car-type.service';
import { ShopSoort } from '../../_models/common/shop.soort';
import { MainService } from '../../_services/main.service';
import { ContextCarTypeSelection } from '../../_models/car-types/context.car.type.selection';
import { ContextMain } from '../../_models/common/context.main';
import { CarTypeResult } from '../../_models/car-types/car.type.result';
import { LicensePlate } from '../../_models/car-types/license.plate';
import { TimingObject } from '../../_models/common/timing.object';

@Component({
  selector: 'app-car-type-search',
  templateUrl: './car-type-search.component.html',
  styleUrls: ['./car-type-search.component.scss']
})
export class CarTypeSearchComponent implements OnInit {
  @Input() ctx: ContextMain;
  @Output() carTypeSelected: EventEmitter<CarTypeResult> = new EventEmitter();
  @Input() licensePlate: string;
  @Output() licensePlateChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() gotFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  mode: number;
  vin: string; // = 'WBAAV51010FZ11465';
  focusLicensePlate = new EventEmitter<boolean>();
  focusVin = new EventEmitter<boolean>();
  focusHistory = new EventEmitter<boolean>();
  ctxCarTypeSelection: ContextCarTypeSelection;
  lastSearch = '';
  timing: TimingObject;

  constructor(
    public mainService: MainService,
    public apiService: ApiService,
    public shopService: ShopService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
    this.carTypeService.getContextCarTypeSelection(this.ctx.DefaultCatalog)
      .subscribe(ctx => {
        this.ctxCarTypeSelection = ctx;
        this.timing = ctx.Timing;
      });
    this.setMode(0);
  }

  setMode(m: number): void {
    if (this.mode === m) { m = 0; }
    this.mode = m;
    setTimeout(() => {
      switch (m) {
        case 0:
          this.focusLicensePlate.emit(true);
          break;
        case 2:
          this.focusVin.emit(true);
          break;
        case 3:
          this.focusHistory.emit(true);
          break;
      }
    }, 0);
  }

  carTypeSelectedIntern(result: CarTypeResult): void {
    this.setMode(0);
    this.carTypeSelected.emit(result);
  }

  modelChange(licensePlate: string) {
    if (licensePlate.length === 8) {
      this.searchLicensePlate(licensePlate);
    }
    this.licensePlateChange.emit(licensePlate);
  }

  clear() {
    this.licensePlate = '';
    this.lastSearch = '';
  }

  searchLicensePlate(licensePlate: string): void {
    if (this.lastSearch !== licensePlate) {
      this.lastSearch = licensePlate;
      this.carTypeService.searchLicensePlate(this.ctx.DefaultCatalog, licensePlate)
        .subscribe(result => {
          if (result && result.CarType) {
            this.timing = result.Timing;
            this.carTypeSelected.emit(result);
          }
        });
    }
  }

  searchVin(vin: string): void {
    this.carTypeService.searchVin(this.ctx.DefaultCatalog, vin)
      .subscribe(result => {
        if (result && result.CarType) {
          this.timing = result.Timing;
          this.carTypeSelected.emit(result);
        }
      });
  }


}
