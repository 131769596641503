import { Component, OnInit, Input } from '@angular/core';
import { VoorraadTemplateRendered } from '../../../_models/item-info/voorraad.template.rendered';
import { AvailabilityTemplateSubPartKind } from '../../../_models/item-info/availability.template.sub.part.kind';
import { ResponseItem } from '../../../_models/item-info/response.item';
import { Part } from '../../../_models/catalog/part';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';

@Component({
  selector: 'app-item-info-template-part',
  templateUrl: './item-info-template-part.component.html',
  styleUrls: ['./item-info-template-part.component.scss']
})
export class ItemInfoTemplatePartComponent implements OnInit {
@Input() templatePart: VoorraadTemplateRendered;
@Input() busy: number;
@Input() itemInfo: ResponseItem;
@Input() part: Part;
@Input() sci: ShoppingCartItem;

  constructor() { }

  ngOnInit(): void {
  }

  getHtmlFromTemplate(template: VoorraadTemplateRendered): string {
    if (!template) { return ''; }
    let html = template.Html;
    if (html && this.busy && template.RenderedSubParts) {
      template.RenderedSubParts.forEach(subPart => {
        if (subPart.Kind === AvailabilityTemplateSubPartKind.SupplierAvailability) {
          html = html.replace(subPart.Html, subPart.SubstitutionTemplate.replace('__SPINNER__', '<i class="fas fa-sync fa-spin"></i>'));
        }
      });
    }
    return html;
  }

}
