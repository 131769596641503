<div class="card border-primary mb-3">
    <div class="card-header text-bg-primary">
        <span>{{partsDescription}}</span>
        <span class="badge text-bg-light ms-3">{{filterResult.count}} van {{ctx.Parts[partsDescription].length}}</span>
        <span>
            <div class="float-end collapse-button"
                [ngClass]="{'is-collapsed': !catalogService.getPartsCollapsed(ctx.Parts[partsDescription], ctx.PartsExtraInfoCollapsed)}"
                (click)="catalogService.doCollapseParts(ctx.Parts[partsDescription], ctx.Parts[partsDescription], ctx.PartsExtraInfoCollapsed)">
                <i class="fas fa-angle-up"></i>
            </div>
        </span>
    </div>
    <div [@slideInHorizontal]="!catalogService.getPartsCollapsed(ctx.Parts[partsDescription], ctx.PartsExtraInfoCollapsed)"
        class="card-body body-main">
        <div class="row">
            <div *ngIf="!ctx.Catalog_HideFilters && catalogService.hasProperties(ctx.PartsPropertyValues[partsDescription])"
                class="col-md-3 pe-1">
                <div class="alert alert-info" role="alert">
                    <div class="row">
                        <div *ngFor="let key of objectKeys(ctx.PartsPropertyValues[partsDescription])"
                            class="parts-property-values col-md-6">
                            <span class="py-1">{{getTranslatedPropertyName(key, 'NL')}}</span>
                            <div *ngFor="let option of ctx.PartsPropertyValues[partsDescription][key]">
                                <label class="parts-property-values-checkbox-label">
                                    <input icheck type="checkbox"
                                        [(ngModel)]="ctx.Filters['Property_' + partsDescription + '_' + key + '_' + option]">
                                    {{option}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                [ngClass]="{'col-sm-9': !ctx.Catalog_HideFilters && catalogService.hasProperties(ctx.PartsPropertyValues[partsDescription]), 'col-sm-12': ctx.Catalog_HideFilters || !catalogService.hasProperties(ctx.PartsPropertyValues[partsDescription])}">
                <app-catalog-part-detailed
                    *ngFor="let part of ctx.Parts[partsDescription] | catalogPartsFilter: catalogService:ctx | filterCount: { object: filterResult, key: 'count' } | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.PartsCartItems; let odd=odd;"
                    [odd]="odd" [ctx]="ctx" [part]="part" [partsDescription]="partsDescription">
                </app-catalog-part-detailed>
                <div *ngIf="filterResult.count === 0" class="row">
                    <div class="col-12">
                        <div class="alert alert-warning mb-0" role="alert">
                            <i class="fas fa-exclamation-triangle"></i> Er bleven geen artikelen over aan de hand van de
                            volgende selectie keuzes:
                            <br /><br />
                            <ul class="">
                                <li *ngFor="let selectie of catalogService.getFilterDescription(ctx, '')">{{selectie}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>