<div class="container">

  <app-breadcrumb-header [debugObject]="returnItem" [autoStart]="true">
    <li appBreadcrumbHeaderItem [routerLink]="['/details/returns']">Retouren</li>
    <li appBreadcrumbHeaderItem [active]="true" *ngIf="returnItem">{{returnItem.Header.OrderNumber}}</li>
  </app-breadcrumb-header>

  <div *ngIf="returnItem">
    <div class="row">
      <div class="col-6">
        Referentie
      </div>
      <div class="col-6">
        {{returnItem.Header.Reference}}
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        Opmerking
      </div>
      <div class="col-6">
        {{returnItem.Header.Remarks}}
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        Naam aanvrager
      </div>
      <div class="col-6">
        {{returnItem.Header.ApplicantName}}
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        Retournummer
      </div>
      <div class="col-6">
        {{returnItem.Header.OrderNumber}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        Status
      </div>
      <div class="col-6">
        {{customerInformationService.getRetourStatusDescription(returnItem.Header.Status)}}
        <span *ngIf="hasDeliveryNote()">(op pakbon {{returnItem.Header.DeliveryNoteNumber}})</span>
        <span *ngIf="hasDeliveryNote()" class="text-danger pointer ms-2" (click)="showPdf()"><i class="fas fa-file-pdf"></i></span>
      </div>
    </div>

    <app-grid-webshop [items]="getSortedItems()" emptyText="Geen retourregels gevonden." class="mt-3 mb-3">
      <ng-container grid-header>
        <div class="col-1">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'LineNumber'">Regel</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemNumber'">Artikelnr</app-sort-field>
        </div>
        <div class="col-1 text-end">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemGroup'">Groep</app-sort-field>
        </div>
        <div class="col-3">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ItemDescription'">Omschrijving
          </app-sort-field>
        </div>
        <div class="col-1 text-end">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'Amount'">Aantal</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'Reason'">Reden</app-sort-field>
        </div>
        <div class="col-2">
          <app-sort-field [sortObjectId]="'returns-details'" [sortField]="'ReasonDescription'">Redenomschrijving
          </app-sort-field>
        </div>
      </ng-container>
      <ng-template let-item>
        <div class="col-1">{{item.LineNumber}}</div>
        <div class="col-2">{{item.ItemNumber}}</div>
        <div class="col-1 text-end">
          <span *ngIf="item.ItemGroup">{{item.ItemGroup}}</span>
        </div>
        <div class="col-3">{{item.ItemDescription}}</div>
        <div class="col-1 text-end">{{item.Amount}}</div>
        <div class="col-2">{{customerInformationService.getReturnReasonDescription(item.Reason)}}</div>
        <div class="col-2">{{item.ReasonDescription}}</div>
      </ng-template>
    </app-grid-webshop>





  </div>

</div>
