import { GisRequest } from './gis.request';

export class GisSearchRequest extends GisRequest {
  HistoryMonthsFilter: number;
  DateFrom: Date;
  DateTo: Date;
  ArticleNumber: string;

  constructor() {
    super();
    this.DateFrom = new Date();
    this.DateTo = new Date();
  }

}
