import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-show-more-button',
  templateUrl: './show-more-button.component.html',
  styleUrls: ['./show-more-button.component.scss']
})
export class ShowMoreButtonComponent implements OnInit {
  @Input() currentItemCount: number;
  @Output() currentItemCountChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() showPerCount: number;
  @Input() totalItemCount: number;

  constructor() { }

  ngOnInit(): void {
  }

  clickMore() {
    this.currentItemCount = this.currentItemCount + this.showPerCount;
    this.currentItemCountChange.emit(this.currentItemCount);
  }

}
