<div class="row">
  <div class="col ms-2 mb-2">
    <button type="button" class="btn w-100 btn-menu" [ngClass]="btnClass" (click)="clickMenuItem(menuItem)">
      <span>{{menuItem.Description}}</span>
      <div *ngIf="menuItem.MenuItems" class="collapse-button float-end" [ngClass]="{'is-collapsed': !menuItem['uncollapsed']}"><i class="fas fa-angle-up"></i></div>
    </button>
  </div>
</div>
<div *ngIf="menuItem.MenuItems" class="row menu-items" [@slideInHorizontal]="!menuItem['uncollapsed']">
  <div class="col ms-2">
    <app-side-menu-doetam-button *ngFor="let subItem of menuItem.MenuItems" [menuItem]="subItem"
      [parentMenu]="menuItem.MenuItems" [btnClass]="'btn-primary'"></app-side-menu-doetam-button>
  </div>
</div>