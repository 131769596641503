import { Component, OnInit } from '@angular/core';

import { ContextPortalSettings } from '../../_models/portal-settings/context-portal-settings';
import { PortalSettingsService } from '../../_services/portal-settings.service';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit {
  ctx: ContextPortalSettings;

  constructor(
    private portalSettingsService: PortalSettingsService
  ) { }

  ngOnInit() {
    this.portalSettingsService.getContext()
      .subscribe(ctx => {
        this.ctx = ctx;
      });
  }

}
