<app-admin-form [title]="'Onderhoud lijnen'" [object]="settings">
  <app-admin-radiobutton [label]="'Laat alleen zien wat'"
    [(model)]="settings.CatalogLayoutCatalogLinesFilter_FilterEverythingExcept"
    [radios]="{'NIET geselecteerd is': false,'WEL geselecteerd is': true}">
  </app-admin-radiobutton>
  <app-admin-input [label]="'Zoek'" [(model)]="filterString" (inputClick)="clear()" (modelChange)="change(filterString)" [placeholder]="'filter op merk/omschrijving/catalogus'">
    <button class="btn btn-outline-secondary" type="button" (click)="clear()"><i class="fas fa-times"></i></button>
  </app-admin-input>
  <app-admin-radiobutton [label]="'Toon lijnen'"
    [(model)]="settings.CatalogLayoutCatalogLinesFilter_DisplayKind"
    [radios]="{'in één kolom': 1,'per catalogus': 0,'rapportage': 2}">
  </app-admin-radiobutton>
  <ng-container [ngSwitch]="settings.CatalogLayoutCatalogLinesFilter_DisplayKind">
    <app-admin-custom-control *ngSwitchCase="1" [labelCols]="1">
      <ng-container *ngIf="(settings.CatalogLinesFilter | keyvalue | adminCatalogLinesFilter:filterString:0) as brands">
        <h5>Lijnen <span *ngIf="brands.length!==getTotal(0)" class="ms-2 badge text-bg-secondary">{{brands.length}} van {{getTotal(0)}}</span>
          <button class="btn btn-xs btn-outline-secondary" type="button" (click)="invert(settings.CatalogLinesFilter)" tooltip="Inverteer de selectie"><div class="animate-button" [ngClass]="{'animate-360': flip}"><i class="fad fa-sync"></i></div></button></h5>
        <div *ngIf="brands.length===0" class="alert alert-danger mt-3" role="alert"><i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!</div>
        <div *ngFor="let brand of brands">
          <app-catalog-line-checkbox [keyValuePair]="brand" [filter]="filter"></app-catalog-line-checkbox>
        </div>
      </ng-container>
    </app-admin-custom-control>
    <app-admin-custom-control *ngSwitchDefault [labelCols]="0">
      <div class="row">
        <div *ngFor="let catalogKey of objectKeys(settings.CatalogKinds)" class="col">
          <ng-container *ngIf="(perCatalog[catalogKey] | keyvalue | adminCatalogLinesFilter:filterString:1) as brands">
            <h5>{{settings.CatalogKinds[catalogKey]}} <span *ngIf="brands.length!==getTotal(+catalogKey)" class="ms-2 badge text-bg-secondary">{{brands.length}} van {{getTotal(+catalogKey)}}</span>
              <button class="btn btn-xs btn-outline-secondary" type="button" (click)="invert(settings.CatalogLinesFilter,settings.CatalogKinds[catalogKey])" tooltip="Inverteer de selectie"><i class="fad fa-sync"></i></button></h5>
            <div *ngIf="brands.length===0" class="alert alert-danger mt-3" role="alert"><i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!</div>
            <div *ngFor="let brand of brands">
              <app-catalog-line-checkbox [keyValuePair]="brand" [filter]="filter" [fixedCatalog]="settings.CatalogKinds[catalogKey]"></app-catalog-line-checkbox>
            </div>          
          </ng-container>
        </div>
      </div>
    </app-admin-custom-control>
    <app-admin-custom-control *ngSwitchCase="2" [labelCols]="1">
      <app-admin-settings-catalog-lines-report [settings]="settings"></app-admin-settings-catalog-lines-report>
    </app-admin-custom-control>
  </ng-container>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsCatalogLines(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>
