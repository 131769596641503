import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ShopSoort } from '../../../_models/common/shop.soort';
import { MainService } from '../../../_services/main.service';
import { ShopService } from '../../../_services/shop.service';

@Component({
  selector: '[appBreadcrumbHeaderItem]',
  templateUrl: './breadcrumb-header-item.component.html',
  styleUrls: ['./breadcrumb-header-item.component.scss']
})
export class BreadcrumbHeaderItemComponent implements OnInit {
  @Input() routerLink: any[] = null;
  @Input() active: boolean = false;
  @Input() addShopModule: ShopSoort;
  @HostBinding('class.breadcrumb-item') isBreadcrumb: boolean;
  @HostBinding('class.active') isActive: boolean = this.active;

  constructor(
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
    this.isBreadcrumb = true;
  }

  getRouterLink(): any[] {
    if (this.routerLink) return this.routerLink;
    if (this.addShopModule !== 0 && !this.active) return this.shopService.getShopSoortLink(this.addShopModule);
    return null;
  }

}
