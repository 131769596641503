<div class="modal-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-11">
        <h4>Timings</h4>
      </div>
      <div class="col-md-1">
        <span (click)="collapsed = !collapsed" class="float-end pointer">
          <span *ngIf="collapsed"><i class="fas fa-plus-square fa-2x"></i></span>
          <span *ngIf="!collapsed"><i class="fas fa-minus-square fa-2x"></i></span>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  <div *ngIf="timing" class="container-fluid noselect">
    <app-timing-object-display [timingObject]="timing" [classLevels]="classLevels" [collapseState]="collapsed" [flatTimings]="getFlatTimings(timing)"></app-timing-object-display>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-info" (click)="bsModalRef.hide()">Annuleren</button>
</div>
