import { ShopSoort } from '../common/shop.soort';
import { TyreSize } from './tyre.size';

export class TyreRequest {
    Sizes: TyreSize[];
    ItemCount: number;
    Category: string;
    Brand: string;
    WithPrice: boolean;
    FixedSupplier: number;
    Shop: ShopSoort;

    constructor(sizes: TyreSize[], itemCount: number, withPrice: boolean, fixedSupplier: number, shop: ShopSoort) {
        this.Sizes = sizes;
        this.ItemCount = itemCount;
        this.WithPrice = withPrice;
        this.FixedSupplier = fixedSupplier;
        this.Shop = shop;
    }

}
