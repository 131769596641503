<app-admin-form [title]="'Kentekenplaat soorten'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">
  <app-admin-custom-control *ngFor="let licensePlate of adminSettingsService.getAdminSetting(settings,'LicensePlateKinds').Value" [label]="licensePlate" [labelCols]="3">
    <div class="row">
      <div class="col"><app-admin-input [(model)]="adminSettingsService.getAdminSetting(settings,'LicensePlate_'+licensePlate+'_Description').Value" [placeholder]="'omschrijving'" [dividerKind]="-1"></app-admin-input></div>
      <div class="col"><app-admin-checkbox [label]="'wit'" [(model)]="adminSettingsService.getAdminSetting(settings,'LicensePlate_'+licensePlate+'_IsTrailerPlate').Value" [dividerKind]="-1"></app-admin-checkbox></div>
      <div class="col"><app-admin-input [(model)]="adminSettingsService.getAdminSetting(settings,'LicensePlate_'+licensePlate+'_BrightEncoding').Value" [placeholder]="'bright code'" [dividerKind]="-1"></app-admin-input></div>
    </div>
  </app-admin-custom-control>
  
  
  <app-admin-cancel-save-button [labelCols]="3" [saveAction]="adminSettingsService.saveSettingsBase(settings)"></app-admin-cancel-save-button>
</app-admin-form>