import { Pipe, PipeTransform } from '@angular/core';
import { BackendInfo } from '../_models/admin/settings/backend.info';
import { WholesalerInfo } from '../_models/admin/settings/wholesaler.info';

@Pipe({
  name: 'filterByWholesalerInfo'
})
export class FilterByWholesalerInfoPipe implements PipeTransform {

  isFilterHit(backendInfo: BackendInfo, filter: string) {
    if (backendInfo?.Environment && backendInfo.Environment.toLowerCase().startsWith(filter)) return true;
    return false;
  }

  transform(items: WholesalerInfo[], filter: string): WholesalerInfo[] {
    if (items && filter) {
      const f = filter.toLocaleLowerCase();
      return items.filter(item => {
        let ok = false;
        if (item.ID.toString().startsWith(f)) ok = true;
        if (item.Name.toLowerCase().indexOf(f) >= 0) ok = true;

        if (this.isFilterHit(item.ShopPortalBackendApi, f)) ok = true;
        item.LegacyEasyCloudProvider.forEach(bi => {
          if (this.isFilterHit(bi, f)) ok = true;
        });
        item.EasyCloudProviderApi.forEach(bi => {
          if (this.isFilterHit(bi, f)) ok = true;
        });

        item.Tags.forEach(tag => {
          if (tag.toLowerCase().startsWith(f)) ok = true;
        });
        return ok;
      });
    }
    return items;
  }

}
