import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { ShopOrder } from '../../../_models/shoporders/shop.order';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-typeahead-order-search',
  templateUrl: './admin-typeahead-order-search.component.html',
  styleUrls: ['./admin-typeahead-order-search.component.scss']
})
export class AdminTypeaheadOrderSearchComponent implements OnInit {
  @Input() label: string;
  @Input() start: string;
  @Output() startChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() wholesaler: number;
  @Input() customer: number;
  @Output() selected: EventEmitter<ShopOrder> = new EventEmitter<ShopOrder>();
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() onlyLoyaltyShopOrders: boolean = false;
  uniqueId = Math.random().toString(36).substring(2);

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.labelCols) { this.labelCols = 2; }
  }

  typeaheadOnSelect(match: TypeaheadMatch) {
    this.selected.emit(match.item);
  }

  onBlur(event) {
    this.blur.emit(this.start);
  }
}
