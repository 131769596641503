<div class="modal-header center">
  <h3 class="modal-title">Selectie Artikel Onderhoud</h3>
</div>
<div class="modal-body text-bg-light">
  <app-admin-typeahead-item [labelCols]="4" [dividerKind]="1" [label]="'Artikel'"
  [(model)]="_taItem" [focusInput]="focusInput"></app-admin-typeahead-item>
  <app-admin-dropdown [labelCols]="4" [dividerKind]="1" [label]="'Aantal'" [(model)]="item.Aantal"
    [modelValues]="[0,1,2,3,4,5,6,7,8,9]" [modelDisplayValues]="{'0':'Per wiel'}"></app-admin-dropdown>
  <app-admin-dropdown [labelCols]="4" [dividerKind]="1" [label]="'Standaard selectie'"
    [(model)]="item.Properties['SelectionKind']" [modelValues]="[0,1,2]"
    [modelDisplayValues]="{'0':'Niet Geselecteerd', '1':'Wel Geselecteerd', '2':'Vast Geselecteerd'}">
  </app-admin-dropdown>
  <app-admin-dropdown [labelCols]="4" [dividerKind]="1" [label]="'Toon keuze bij'"
    [(model)]="item.Properties['DisplayChoice']" [modelValues]="[0,1,2]"
    [modelDisplayValues]="{'0':'Altijd', '1':'Wiel+Band Combinatie', '2':'Enkel Velg'}"></app-admin-dropdown>
  <app-admin-dropdown [labelCols]="4" [dividerKind]="1" [label]="'Alleen bij TPMS'"
    [(model)]="item.Properties['OnlyTPMS']" [modelValues]="[false,true]"
    [modelDisplayValues]="{'false':'Nee', 'true':'Ja'}"></app-admin-dropdown>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i> Opslaan</button>
</div>
