<app-content-list-item [item]="tyre" [odd]="odd">
  <ng-container col-1>
    <img *ngIf="rimAndTyreService.getTyreImage(tyre)" class="img-fluid" [src]="rimAndTyreService.getTyreImage(tyre)"
      (click)="rimAndTyreService.showTyreInfo(tyre)" />
    <button type="button" class="btn btn-info btn-xs mt-1" (click)="rimAndTyreService.showTyreInfo(tyre)">info</button>
    <div><a *ngIf="rimAndTyreService.getProductInfoPdf(tyre)" class="btn btn-warning btn-xs mt-1" [href]="rimAndTyreService.getProductInfoPdf(tyre)" target="_blank"><i class="fas fa-file-pdf"></i>&nbsp;&nbsp;product info</a></div>
  </ng-container>
  <ng-container col-2>
    <div class="part-description pb-1">{{tyre.ItemInfo.Description}}</div>
    <div class="part-item-number pb-1">{{tyre.ItemInfo.Properties['TyreBrand']}} - <app-part-item-number-link [partItemNumber]="rimAndTyreService.getTyreItemNumberSupplier(tyre)"></app-part-item-number-link></div>
    <div *ngIf="tyre.ItemInfo.EANcode" class="part-item-number pb-1">EAN: {{tyre.ItemInfo.EANcode}}</div>
    <div class="mt-3">
      <app-tyre-label [tyre]="tyre"></app-tyre-label>
    </div>
  </ng-container>
  <ng-container col-3>
    <div class="row p-1">
      <div class="col part-item-info">
        <div *ngIf="ctx.TyresSurcharge.Active" class="row">
          <div class="col">{{ctx.TyresSurcharge.ColumnName}}</div>
          <div class="col text-end">{{tyre.ItemInfo.Price.RetailPrice | price}}</div>
        </div>
        <app-item-info-template [busy]="ctx['busy']" [sci]="tyre" [nettoprijs]="ctx.Nettoprijs">
        </app-item-info-template>
      </div>
    </div>
    <div class="row p-1 my-3">
      <div *ngIf="!set" class="col"><app-item-count-wide [right]="true" [sci]="tyre"></app-item-count-wide></div>
      <div *ngIf="set" class="col text-primary pointer icon-xl" (click)="rimAndTyreService.orderRimsAndTyresSet(set,tyre)"><i class="fas fa-plus-square"></i></div>
    </div>
  </ng-container>
</app-content-list-item>
