import { SettingModel } from '../../common/setting.model';
import { UserIdentificationModel } from '../../user.identification.model';
import { AdminSetting } from './admin.setting';

export class SettingsBase {
  Title: string;
  CommonSettings: { [key: string]: AdminSetting };
  MoreSpecificSettings: { [key: string]: SettingModel[] };
  UID: UserIdentificationModel;
}
