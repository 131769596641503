<div class="modal-body">
    <div class="row">
        <div class="col-7">
            <div class="image-container">
                <img *ngFor="let image of wheel.Images; let index = index;" class="wheel-image" [src]="image" [@fadeInOut]="selectedImage === index" />
            </div>
        </div>
        <div class="col-5">
            <div class="thumbnail-container">
                <img *ngFor="let thumb of wheel.Images; let index = index;" class="img-thumbnail m-1 pointer" [src]="thumb" (click)="selectedImage = index" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-7">
            <div class="row">
                <div class="col-sm-3 label">Artikelnummer</div>
                <div class="col-sm-9 data">{{wheel.PartItemNumber}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3 label">Omschrijving</div>
                <div class="col-sm-9 data">{{wheel.PartDescription}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3 label">Slotboutset</div>
                <div class="col-sm-9 data">{{wheel.PartItemNumberLockSet}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3 label">Bandenmaten</div>
                <div class="col-sm-9 data"><span *ngFor="let size of wheel.TyreDimensions">{{size.Display}}<br /></span></div>
            </div>
            <div class="row">
                <div class="col-sm-3 label">Merk</div>
                <div class="col-sm-9 data">
                    <img *ngIf="wheel.BrandLogo" [src]="wheel.BrandLogo" />
                    <span *ngIf="!wheel.BrandLogo">{{wheel.Brand}}</span>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="row mb-3">
                <div class="col label">
                    <app-item-info-template [busy]="ctx['busy']" [sci]="catalogService.getCartItemForPart(ctx.WheelCartItems, wheel)" [nettoprijs]="ctx.Nettoprijs" [part]="wheel"></app-item-info-template>
                </div>
            </div>
            <div *ngIf="wheel.Winter" class="row">
                <div class="col"><img src="/assets/img/rims/winter.png" /><span class="ms-3 label">Zeer geschikt als wintervelg.</span></div>
            </div>
            <div *ngIf="wheel.Runflat" class="row">
                <div class="col"><img src="/assets/img/rims/runflat.png" /><span class="ms-3 label">Geschikt voor runflat banden.</span></div>
            </div>
            <div *ngIf="wheel.Tpms" class="row">
                <div class="col"><img src="/assets/img/rims/tpms.png" /><span class="ms-3 label">Deze velg is geschikt voor TPMS.</span></div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="closeDialog()">Terug</button>
</div>