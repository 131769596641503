import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SettingsAvailabilitySuppliers } from '../../../../_models/admin/settings/settings.availability.suppliers';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../../_services/admin/admin.service';
import { MainService } from '../../../../_services/main.service';
import { SupplierBranchData } from '../../../../_models/admin/settings/supplier.branch.data';
import { AdminSupplierInterfaceService } from 'app/_services/admin/admin-supplier-interface.service';
import { InterfaceKindInfoModel } from 'app/_models/admin/supplier-interface/interface.kind.info.model';

@Component({
  selector: 'app-admin-settings-availability-suppliers',
  templateUrl: './admin-settings-availability-suppliers.component.html',
  styleUrls: ['./admin-settings-availability-suppliers.component.scss']
})
export class AdminSettingsAvailabilitySuppliersComponent implements OnInit {
  settings: SettingsAvailabilitySuppliers;

  constructor(
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private mainService: MainService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getBranchNumbers(): number[] {
    const branches: number[] = [];
    if (this.settings?.Branches) {
      Object.keys(this.settings?.Branches).forEach(branch => {
        branches.push(+branch);
      });
    }
    return branches;
  }

  getStockInfoInterfaceInfo(): InterfaceKindInfoModel {
    const result = new InterfaceKindInfoModel();
    result.InterfaceKind = 22;
    result.Name = "EasyCloud StockInfo";
    result.SortOrder = -1;
    return result;
  }

  getConfiguredSuppliers(): string[] {
    if (this.settings) {
      return Object.keys(this.settings.StockInfoSuppliersLink);
    }
    return [];
  }

  addLink() {
    let suppliers = this.settings.StockInfoSuppliers;
    if (this.settings.StockInfoSuppliersLink) { suppliers = this.settings.StockInfoSuppliers.filter(s => !Object.keys(this.settings.StockInfoSuppliersLink).includes(s)); }
    this.mainService.selectionBox('Kies een beschikbaarheid koppeling', suppliers)
      .subscribe(link => {
        if (link) {
          if (!this.settings.StockInfoSuppliersLink) this.settings.StockInfoSuppliersLink = {};
          this.settings.StockInfoSuppliersLink[link] = new SupplierBranchData;
        }
      });
  }

  changeLink(key: string, supplier: number) {
    this.settings.StockInfoSuppliersLink[key] = new SupplierBranchData;
    this.settings.StockInfoSuppliersLink[key].Supplier = supplier;
  }

  removeLink(key: string) {
    delete this.settings.StockInfoSuppliersLink[key];
  }

}
