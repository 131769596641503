<ng-template #popTemplate>
  <div [innerHTML]="mainService.getSafeHtml(getWarningsHtml())"></div>
</ng-template>
<div class="row header-panel align-items-center justify-content-between g-0">
  <div *ngIf="!withoutCarType" class="col">
    <span *ngIf="showBackButton()" (click)="backClick.emit($event)" class="text-primary header-panel-back-link align-middle pe-1"><i
        class="fas fa-arrow-circle-left fa-2x"></i></span>
    <span *ngIf="!withoutCarType" class="header-panel-cartype">
      <span *ngIf="preText" [innerHTML]="mainService.getSafeHtml(preText)"></span>
      <span *ngIf="ctx.CarType" class="me-1" (click)="carTypeService.resetCarType(ctx.ShopKind)">
        {{ctx.CarType.Model?.Brand.BrandName}} {{ctx.CarType.TypeName}}
        {{catalogPropertyService.getPropertyValueString(ctx.CarType.Properties,
        catalogPropertyService.propertyKindCatalog.EngineCode)}}
        <img *ngIf="ctx.CarType && ctx.CarType.LicensePlate" class="header-panel-licenseplate"
          [src]="ctx.CarType.LicensePlate?.ImageUrl" />
      </span>
      <img *ngIf="hasRDWproperties()" class="rdw-logo pointer" src="/assets/img/icons/rdw.png"
        (click)="clickCarTypeExtraDataCollapsed(ctx)" />
      <div *ngIf="ctx.CarType" class="collapse-button" [ngClass]="{'is-collapsed': ctx.CarTypeExtraDataCollapsed}"
        (click)="clickCarTypeExtraDataCollapsed(ctx)">
        <i class="fas fa-angle-up"></i>
      </div>
    </span>
    <span *ngIf="ctx && ctx.ShowWarnings && getTimingObject()?.Warnings.length > 0" class="px-2">
      <span class="badge text-bg-warning" [tooltip]="popTemplate" html="true" placement="right" container="body">
        <i class="fas fa-exclamation-triangle"></i>{{getTimingObject().Warnings.length}}</span>
    </span>
  </div>
  <div class="col">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="!withoutCarType && ctx.CarType" class="row header-panel-cartype-dataset"
  [@slideInHorizontal]="ctx.CarTypeExtraDataCollapsed">
  <app-car-type-info-block [carType]="ctx.CarType" [noLicensePlate]="true"></app-car-type-info-block>
</div>
<ng-content select="[content-bottom]"></ng-content>