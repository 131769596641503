import { Injectable } from '@angular/core';
import { ContextGraphicParts } from '../../_models/catalog/context.graphic.parts';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CatalogService } from '../../_services/catalog.service';
import { CarTypeService } from '../../_services/car-type.service';
import { Observable, of, EMPTY } from 'rxjs';
import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { take, mergeMap } from 'rxjs/operators';
import { ToasterService } from '../../_services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogGraphicPartsDataResolverService  {

  constructor(
    private catalogService: CatalogService,
    private carTypeService: CarTypeService,
    private toasterService: ToasterService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextGraphicParts> | Observable<never> {
    const categoryId = route.paramMap.get('categoryId');
    const carType = this.carTypeService.currentCarType;
    const category = this.catalogService.getCategoryByCarTypeIdAndCategoryId(carType.Id, categoryId);
    const description = route.queryParamMap.get('description');

    return this.catalogService.getGraphicParts(carType, category, description).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          this.toasterService.showToast('Helaas', 'Er zijn geen artikelen gevonden in deze groep.');
          return EMPTY;
        }
      })
    );

  }

}
