<div class="container">

  <app-breadcrumb-header [autoStart]="true" [debugObject]="request">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopSoort.PickupRequest"></li>
  </app-breadcrumb-header>

  <div class="card border mb-3">

    <div class="card-header card-header-compact  text-bg-dark">{{request.ModuleName}}</div>

    <div class="card-body p-2">

      <div class="form-group row">
        <label class="col-md-2 col-form-label">Wat op te halen</label>
        <div class="col-md-10">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="request.Description" maxlength="50" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Waar en bij wie</label>
        <div class="col-md-10">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.Location" maxlength="50" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label my-auto">Wanneer</label>
        <div class="col-md-5  my-auto">
          <div class="input-group">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="request.DateTime" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }" autocomplete="off">
            <span class="input-group-text" id="basic-addon"><i class="far fa-calendar-alt"></i></span>
          </div>
        </div>
        <div class="col-md-5">
          <timepicker [showMeridian]="false" [(ngModel)]="request.DateTime"></timepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label my-auto">Eventuele kosten</label>
        <div class="col-md-5 my-auto">
          <input type="text" appNumericOnly [decimals]="2" class="form-control form-control-sm" [ngModel]="request.CostsToBePaid" (ngModelChange)="request.CostsToBePaid=$event" autocomplete="off" maxlength="8" placeholder="Eventuele kosten die voldaan moeten worden">
        </div>
        <div class="col-md-5">
          Kosten die wij in rekening brengen <br />
          per ophaal opdracht zijn €5,- ex
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Opmerkingen</label>
        <div class="col-md-10">
            <textarea class="form-control form-control-sm" [(ngModel)]="request.Remarks" placeholder="Hier kunt u eventuele opmerkingen kwijt" style="height: 70px;"></textarea>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="col-md-10 offset-md-2">
          <button type="button" class="btn btn-primary" (click)="sendPickupRequest(request)">Verstuur</button>
        </div>
      </div>



    </div>

  </div>


</div>
