<div class="card h-100">
  <div class="card-header text-bg-danger p-1 small">
    <div class="category-title">
      <span><i class="fas fa-info-circle"></i></span>
      <span *ngIf="!category" class="mx-1"><i>Selecteer een categorie</i></span>
      <span *ngIf="category" class="mx-1">{{category.CategoryDescription}}</span>
      <span
        *ngIf="catalogLayoutService.hasFilteredLines(settings.ActiveCatalogFilter, catalogLayoutService.getPartsDescriptionsForCategory(settings.PartsDescriptions, category))"
        [tooltip]="catalogLayoutService.getLinesTooltipText(settings.ActiveCatalogFilter, settings.CatalogKindInfo, settings.PartsDescriptions, category)" placement="bottom" container="body"><i
          class="fad fa-exclamation-triangle"></i></span>
    </div>

    <div *ngIf="category" class="btn-toolbar float-end" role="toolbar">
      <div *ngIf="!catalogLayoutService.isCategoryLinked(settings.CatalogLayoutSubgroupCategoryCross, category)"
        class="btn-group btn-group-xxs me-2" role="group">
        <button type="button" class="btn btn-danger"
          [tooltip]="'Voeg '+catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).CatalogKindString+' '+category.CategoryDescription+' toe aan de indeling'"
          placement="bottom" container="body"
          (click)="catalogLayoutService.addCategory(settings.Groups,settings.CatalogLayoutSubgroupCategoryCross,category)"><i
            class="far fa-plus"></i></button>
      </div>
      <div *ngIf="catalogLayoutService.isCategoryLinked(settings.CatalogLayoutSubgroupCategoryCross, category)"
        class="btn-group btn-group-xxs me-2" role="group">
        <button type="button" class="btn btn-danger"
          [tooltip]="'Verwijder '+catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).CatalogKindString+' '+category.CategoryDescription+' uit de indeling'"
          placement="bottom" container="body"
          (click)="catalogLayoutService.deleteCategoryFromSubgroupIds(settings.CatalogLayoutSubgroupCategoryCross,category,catalogLayoutService.getCategoryLinkedSubgroups(settings.CatalogLayoutSubgroupCategoryCross, category))"><i
            class="far fa-minus"></i></button>
      </div>
      <div class="btn-group btn-group-xxs" role="group">
        <button class="btn btn-danger btn-xxs" type="button" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="far fa-sort-amount-down"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="dropdown-item" (click)="sortMode=0">
            <span class="me-1" [ngClass]="{'check-off': sortMode!==0}"><i class="far fa-check"></i></span>
            Sorteer op artikelsoort</li>
          <li class="dropdown-item" (click)="sortMode=1">
            <span class="me-1" [ngClass]="{'check-off': sortMode!==1}"><i class="far fa-check"></i></span> Sorteer op
            merknaam</li>
          <li class="dropdown-item" (click)="displayHiddenLines=!displayHiddenLines">
            <span class="me-1" [ngClass]="{'check-off': !displayHiddenLines}"><i class="far fa-check"></i></span>
            Laat inactieve lijnen zien</li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="category" class="card-body p-0 parts-descriptions">
    <div class="scroll-list-descriptions">
      <ul class="list-group list-group-flush">
        <li class="list-group-item p-1 textbg-danger">
          <div class="row">
            <div class="col-5">Catalogus</div>
            <div class="col-7">
              {{catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).CatalogKindString}}</div>
            <div class="col-5">
              {{catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).NativeMainCategoryName}}
            </div>
            <div class="col-7">{{category.MainCategoryDescription}}</div>
            <div class="col-5">
              {{catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).NativePartsCategoryName}}
            </div>
            <div class="col-7">{{category.CategoryDescription}}</div>
            <div class="col-5">
              {{catalogLayoutService.getCatalogInfo(settings.CatalogKindInfo, category.Origin).NativePartsCategoryIdName}}
            </div>
            <div class="col-7">{{category.CategoryId}}</div>
            <ng-container
              *ngFor="let subgroup of catalogLayoutService.getCategoryLinkedSubgroups(settings.CatalogLayoutSubgroupCategoryCross, category)">
              <div class="col-5">Gekoppeld in</div>
              <div class="col-7" [title]="subgroup">
                {{catalogLayoutService.getSubgroupBySubgroupId(settings.Groups, subgroup)}} </div>
            </ng-container>
            <ng-container
              *ngIf="!catalogLayoutService.isCategoryLinked(settings.CatalogLayoutSubgroupCategoryCross, category)">
              <div class="col-12">Niet gekoppeld!</div>
            </ng-container>
          </div>
        </li>
        <ng-container
          *ngFor="let description of catalogLayoutService.getPartsDescriptionsForCategory(settings.PartsDescriptions, category) | adminCatalogLayoutPartsDescriptionsSort:sortMode:getFilterStringDescription(filter):getFilterStringBrand(filter) ; odd as isOdd">
          <li
            *ngIf="displayHiddenLines || !catalogLayoutService.isFilteredLine(settings.ActiveCatalogFilter, description)"
            class="list-group-item p-1" [ngClass]="{'parts-descriptions-odd': isOdd}">
            <div class="row">
              <div class="col-7">
                <span
                  [ngClass]="{'filtered':catalogLayoutService.isFilteredLine(settings.ActiveCatalogFilter, description)}">
                  <app-filtered-text [text]="description.Description" [filter]="getFilterStringDescription(filter)">
                  </app-filtered-text>
                </span>
              </div>
              <div class="col-5">
                <span
                  [ngClass]="{'filtered':catalogLayoutService.isFilteredLine(settings.ActiveCatalogFilter, description)}">
                  <app-filtered-text [text]="description.Brand" [filter]="getFilterStringBrand(filter)">
                  </app-filtered-text>
                </span>
                <span *ngIf="catalogLayoutService.isFilteredLine(settings.ActiveCatalogFilter, description)"
                  class="float-end" tooltip="Deze lijn is niet actief. Zie onderhoud lijnen">
                  <i class="fad fa-exclamation-triangle"></i></span>
              </div>
            </div>
          </li>
        </ng-container>
        <li *ngIf="!catalogLayoutService.getPartsDescriptionsForCategory(settings.PartsDescriptions, category)"
          class="list-group-item p-1">Geen artikelsoorten bekend</li>
      </ul>
    </div>
  </div>
</div>