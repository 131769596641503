<app-admin-form [title]="'Module onderhoud'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event">
  <div *ngIf="settings; else noSettings">
    <app-admin-checkbox *ngIf="adminService.superAdmin" [label]="'Filter verborgen modules'" [labelCols]="4"
      [(model)]="showHiddenModules" [invertModel]="true"></app-admin-checkbox>
    <app-admin-custom-control>
      <div class="row">
        <div class="col-4">
          <div cdkDropList class="module-container" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let module of settings.Modules | moduleFilter:!showHiddenModules" cdkDrag
              class="module card text-bg-light my-1" [ngClass]="{'hidden__': !showHiddenModules && module.Hide}">
              <div class="module-placeholder" *cdkDragPlaceholder>
                <div class="inner-module-placeholder"></div>
              </div>
              <div class="card-body less-padding pe-0" (click)="selected=module"
                [ngClass]="{'disabled': !module.Active, 'is-hidden': module.Hide}">
                <div class="hstack gap-0">
                  <div>
                    <span class="me-1 drag-handle text-secondary"><i class="fas fa-grip-vertical"></i></span>
                  </div>
                  <div *ngIf="adminService.superAdmin && showHiddenModules" class="form-check form-switch m-0">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="module.Hide"
                      [id]="'switch_hide_' + module.ID">
                    <label class="form-check-label" [for]="'switch_hide_' + module.ID">&nbsp;</label>
                  </div>
                  <div><span class="module-title">{{module.Naam}}</span></div>
                  <div class="ms-auto">
                    <div class="form-check form-switch m-0">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="module.Active"
                        [id]="'switch_' + module.ID">
                      <label class="form-check-label" [for]="'switch_' + module.ID">&nbsp;</label>
                    </div>
                  </div>
                  <div>
                    <div class="form-check">
                      <input type="radio" [id]="'startUpRadio'+module.ID" class="form-check-input"
                        [(ngModel)]="startUpModuleId" name="startUpRadios" [value]="module.ID"
                        (change)="onStartUpChange(module)" />
                      <label class="form-check-label" [for]="'startUpRadio'+module.ID"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 pb-2">
          <div class="card mt-1 h-100">
            <div class="card-header less-padding">
              <span *ngIf="!selected">Kies hiernaast een module voor de instellingen hiervan.</span>
              <span *ngIf="selected">Instellingen voor de module {{getDefaultModuleById(selected.ID)?.Naam}}<span
                  class="ms-2 text-low">({{selected.ID}})</span></span>
            </div>
            <div *ngIf="selected" class="card-body less-padding pt-3">
              <app-admin-shop-module-settings [module]="selected"
                [moduleDefaults]="settings.DefaultModules[selected.ID]"
                [settings]="adminSettingsService.getAdminSettingsShopModule(settings, +selected.ID)"
                (startUpChange)="startUpChanged($event)">
              </app-admin-shop-module-settings>
            </div>

          </div>
        </div>
      </div>
    </app-admin-custom-control>
    <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsModules(settings)">
    </app-admin-cancel-save-button>
  </div>
  <ng-template #noSettings>No settings!!</ng-template>
</app-admin-form>