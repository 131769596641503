import { Component, Input, OnInit } from '@angular/core';
import { RouteInfo } from '../../../../_models/item-info/route.info';
import { RouteInfoObject } from 'app/_models/item-info/route.info.object';

@Component({
  selector: '[app-admin-route-info-item]',
  templateUrl: './admin-route-info-item.component.html',
  styleUrls: ['./admin-route-info-item.component.scss']
})
export class AdminRouteInfoItemComponent implements OnInit {
  @Input() route: RouteInfo;
  @Input() routeInfoObject: RouteInfoObject;

  constructor() { }

  ngOnInit(): void {
  }

  getCutoffTime(route: RouteInfo): Date {
    if (route?.StartDateTime) {
      const cutoff = new Date(route.StartDateTime.getTime() + (route.StartDateTime.getTimezoneOffset() - route.Buffer) * 60000);
      return cutoff;
    }
    return null;
  }

}
