<app-admin-form [title]="'Grossiers Overzicht'" [object]="settings" (settingsChanged)="settings=$event">
    <ng-container *ngIf="(settings.WholesalerInfos | filterByWholesalerInfo:filterString) as resultingWholesalerInfos">
      <app-admin-input [labelCols]="1" [label]="'Zoek'" [(model)]="filterString" (inputClick)="filterString = ''"
        [placeholder]="'geef hier eventueel een zoekstring'">
        <span class="input-group-text"><span class="small">{{resultingWholesalerInfos?.length}} /
            {{settings.WholesalerInfos.length}}</span></span>
      </app-admin-input>
      <div class="row p-2 border-bottom">
        <div class="col-sm-2 fw-bold">Grossier</div>
        <div class="col-sm-5 fw-bold">BackendApi</div>
        <div class="col-sm-5 fw-bold">EasyCloud Provider</div>
      </div>
      <div *ngFor="let info of resultingWholesalerInfos | sortByProperty:'Name'; let even=even" class="row border-bottom small p-2" [ngClass]="{'alt-back': even}">
        <div class="col-sm-2">
          <div class="text-low">({{info.ID}})</div>
          <div class="fw-bold">{{info.Name}}</div>
          <div><span *ngFor="let tag of info.Tags" class="badge text-bg-warning me-1">{{tag}}</span></div> 
        </div>
        <div class="col-sm-10">
          <app-admin-backend-info [backendInfo]="info.ShopPortalBackendApi"></app-admin-backend-info>
          <app-admin-backend-info *ngFor="let bi of info.CloudProviderBackend" [backendInfo]="bi" [latest]="getLatestVersion('CloudProviderBackend')"></app-admin-backend-info>
          <app-admin-backend-info *ngFor="let bi of info.EasyCloudProviderApi" [backendInfo]="bi" [latest]="getLatestVersion('EasyCloudProviderApi')"></app-admin-backend-info>
          <app-admin-backend-info *ngFor="let bi of info.LegacyEasyCloudProvider" [backendInfo]="bi" [latest]="getLatestVersion('LegacyEasyCloudProvider')"></app-admin-backend-info>
        </div>
      </div>
      <div *ngIf="resultingWholesalerInfos.length===0 && filterString" class="alert alert-danger mt-3" role="alert">
        <i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!
      </div>
    </ng-container>
  
  <!-- <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)"></app-admin-cancel-save-button> -->
</app-admin-form>
