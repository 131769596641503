import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextBase } from '../../../_models/common/context.base';
import { ApiService } from '../../../_services/api.service';
import { Settings } from '../../../_models/common/settings';
import { ViewKind } from '../../../_models/common/view.kind';

@Component({
  selector: 'app-voorraad-prijs-opties',
  templateUrl: './voorraad-prijs-opties.component.html',
  styleUrls: ['./voorraad-prijs-opties.component.scss']
})

export class VoorraadPrijsOptiesComponent implements OnInit {
  @Input() ctx: ContextBase;
  @Input() saveSettingsDirect: boolean;
  @Output() stockOptionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() netPriceOptionChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
  }

  changeStockOption() {
    if (this.ctx) {
      this.ctx.Voorraad = !this.ctx.Voorraad;
      if (this.saveSettingsDirect) {
        this.apiService.saveSettingsDirect(new Settings(this.ctx.StockOptionString, this.ctx.Voorraad.toString()));
      } else {
        this.apiService.saveSettings(new Settings(this.ctx.StockOptionString, this.ctx.Voorraad.toString()));
      }
      this.stockOptionChanged.emit(this.ctx.Voorraad);
    }
  }

  changeNetPriceOption() {
    if (this.ctx) {
      this.ctx.Nettoprijs = !this.ctx.Nettoprijs;
      if (this.saveSettingsDirect) {
        this.apiService.saveSettingsDirect(new Settings(this.ctx.NetPriceOptionString, (!this.ctx.Nettoprijs).toString()));
      } else {
        this.apiService.saveSettings(new Settings(this.ctx.NetPriceOptionString, (!this.ctx.Nettoprijs).toString()));
      }
      this.netPriceOptionChanged.emit(this.ctx.Nettoprijs);
    }
  }

}
