<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3">


    <app-header-panel [ctx]="ctx" [timing]="ctx?.Timing" [preText]="'Onderhoud voor een '">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col-sm-auto ps-2">
          <app-voorraad-prijs-display-opties [ctx]="ctx" [disableDisplayOptions]="true">
          </app-voorraad-prijs-display-opties>
        </div>
      </div>
    </app-header-panel>

    <div class="card border-primary my-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
      <div class="card-body container">
        <div *ngFor="let partsType of object.keys(selection.PartsTypes)" class="row my-3">
          <div class="col-4 m-auto">{{catalogService.getPartsTypeDescription(ctx, +partsType, 'Niet gevonden')}}</div>
          <div class="col-8">

            <div *ngIf="selection.SelectedPartPerPartsType[partsType]; else notSelected"
              class="card border-success pointer py-1 px-2"
              (click)="catalogService.partsSelectionPopup(selection, +partsType, ctx)">
              <div class="row">
                <div class="col">
                  <span class="text-success me-2"><i class="fas fa-check-circle"></i></span>
                  <span class="part-item-number pb-1">{{selection.SelectedPartPerPartsType[partsType].Brand}} -
                    <span>{{selection.SelectedPartPerPartsType[partsType].PartItemNumber}}</span></span>
                </div>
                <div class="col part-item-info">
                  <app-item-info-template [busy]="ctx['busy']"
                    [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, selection.SelectedPartPerPartsType[partsType])"
                    [nettoprijs]="ctx.Nettoprijs" [part]="selection.SelectedPartPerPartsType[partsType]">
                  </app-item-info-template>
                </div>
              </div>
            </div>

            <ng-template #notSelected>
              <button *ngIf="!selection.Populated || selection.PartsPerPartsType[partsType]?.length; else noItems"
                type="button" class="btn btn-secondary w-100 text-start py-1 px-2"
                (click)="catalogService.partsSelectionPopup(selection, +partsType, ctx)">
                <span class="text-warning me-2"><i class="fas fa-exclamation-triangle"></i></span>
                Maak een keuze uit
                <span *ngIf="selection.PartsPerPartsType[partsType]?.length; else busy;"
                  class="fixed-width text-center">{{selection.PartsPerPartsType[partsType].length}}</span>
                artikelen.
              </button>

              <ng-template #noItems>
                <div class="alert alert-danger mb-0 py-1 px-2" role="alert">
                  <span class="text-danger me-2"><i class="fas fa-exclamation-circle"></i></span>
                  Geen artikelen gevonden!
                </div>
              </ng-template>
            </ng-template>

          </div>
        </div>

        <div class="row my-3">
          <div class="col-4 m-auto"></div>
          <div class="col-8">
            <button type="button" class="btn btn-primary w-100" [disabled]="nothingSelected()"
              (click)="orderSelectedItems()">
              <span class="me-2"><i class="fal fa-cart-arrow-down"></i></span>
              Plaats de geselecteerde artikelen in de winkelwagen
            </button>
          </div>
        </div>
      </div>
      <ng-template #busy><span class="mx-2"><i class="fas fa-sync fa-spin"></i></span></ng-template>
    </div>

  </div>
</div>