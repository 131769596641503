import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UploadEvent } from '../../../../_common/upload/upload-event.model';
import { UploadFile } from '../../../../_common/upload/upload-file.model';
import { ItemImageLink } from '../../../../_models/admin/item-images/item.image.link';
import { ItemImageItemNumber } from '../../../../_models/admin/item-images/item.image.item.number';
import { TypeaheadItem } from '../../../../_models/typeahead/typeahead.item';
import { AdminItemImagesService } from '../../../../_services/admin/admin-item-images.service';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-admin-item-images-edit',
  templateUrl: './admin-item-images-edit.component.html',
  styleUrls: ['./admin-item-images-edit.component.scss']
})
export class AdminItemImagesEditComponent implements OnInit {
  item: ItemImageItemNumber;
  taItem: TypeaheadItem;
  uploadPercent: number;
  focusInput = new EventEmitter<boolean>();

  constructor(
    public mainService: MainService,
    public adminItemImagesService: AdminItemImagesService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.item = data.item;
        this.taItem = new TypeaheadItem();
        this.taItem.InternalItemNumber = data.item.InternalItemNumber;
      });
  }

  itemSelected(taItem: TypeaheadItem) {
    this.item.InternalItemNumber = taItem.InternalItemNumber;
    this.item.ItemNumber = taItem.ItemNumber;
    this.item.ItemDescription = taItem.Description;
  }

  upload(event: UploadEvent) {
    if (this.item.InternalItemNumber === 0) {
      this.mainService.msgBox('Let op!', 'Er moet nog een artikel gekozen worden!')
        .subscribe(() => this.focusInput.emit());
    } else {
      const fileList: UploadFile[] = [];
      for (const droppedFile of event.files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          fileList.push(droppedFile);
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.info(droppedFile.relativePath, fileEntry);
        }
      }
      this.adminItemImagesService.addItemImagesItemNumber(this.item.InternalItemNumber, fileList)
        .subscribe(progress => {
          if (progress.type === HttpEventType.UploadProgress) {
            this.uploadPercent = Math.round(100 * progress.loaded / progress.total);
          } else if (progress.type === HttpEventType.Response) {
            const updatedItem = progress.body as ItemImageItemNumber;
            this.item = updatedItem;
          }
        });
    }
  }

  addImageUrl(event: MouseEvent) {
    this.mainService.inputBoxExtended('Afbeelding toevoegen via url', 'Plak hier de url van de afbeelding', '', false, 'modal-xl')
      .subscribe(url => {
        if (url) {
          var image = new ItemImageLink();
          image.InternalItemNumber = this.item.InternalItemNumber;
          image.ImageUrl = url;
          this.adminItemImagesService.addItemImageItemNumbers(image)
            .subscribe(result => {
              if (result) this.item = result;
            });
        }
      });
  }

  save(item: ItemImageItemNumber): Observable<ItemImageItemNumber> {
    return this.adminItemImagesService.saveItemImageItemNumber(item)
      .pipe(mergeMap(newItem => {
        if (newItem) {
          this.router.navigate(['/admin/management/item-images']);
          return of(newItem);
        }
      }));
  }

  cancel() {
    if (this.item.InternalItemNumber && (!this.item.Images || !this.item.Images.length)) {
      this.adminItemImagesService.deleteItemImagesItemNumber(this.item.InternalItemNumber)
        .subscribe(ok => {
          if (ok) { this.router.navigate(['/admin/management/item-images']); }
        });
    } else {
      this.router.navigate(['/admin/management/item-images']);
    }
  }

}
