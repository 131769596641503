<app-admin-form [title]="'Order Informatie'" [object]="orderInfo??{}">

  <app-admin-input [label]="'Ordernummer'" [focusInput]="focusInput" [(model)]="orderId"
    (keyup.enter)="getOrderInfo(orderId)" [selectOnclick]="true">
    <button class="btn btn-outline-secondary" type="button" (click)="getOrderInfo(orderId)"
      [attr.disabled]="!orderId?true:null">
      <span><i class="far fa-search"></i></span>
    </button>
  </app-admin-input>

  <app-admin-custom-control [label]="'Informatie'" [busy]="busy">

    <div *ngIf="orderInfo?.Customer" class="mb-3">
      <app-customer-warnings [customer]="orderInfo?.Customer?.CustomerNumber"></app-customer-warnings>
    </div>
    
    <div *ngIf="orderInfo?.LogInfo?.length" class="alert alert-danger mt-3" role="alert">
      <h5><i class="fas fa-exclamation-triangle"></i><span class="ms-2">Deze order is laten vervallen!</span></h5>
      <div class="mt-3">{{orderInfo.LogInfo[0].Text}}</div>
    </div>

    <app-archive-order *ngIf="orderInfo?.ArchiveOrder" [order]="orderInfo.ArchiveOrder" [customer]="orderInfo.Customer" [branchName]="orderInfo.BranchName"></app-archive-order>

    <app-easytrade-order *ngIf="orderInfo?.Order" [order]="orderInfo.Order" [archiveOrderKind]="orderInfo?.ArchiveOrder?.OrderKind"></app-easytrade-order>

  </app-admin-custom-control>


</app-admin-form>