import { Component, Input, OnInit } from '@angular/core';
import { OrderLine } from '../../../../../_models/orders/order.line';

@Component({
  selector: '[app-easytrade-order-text-item]',
  templateUrl: './easytrade-order-text-item.component.html',
  styleUrls: ['./easytrade-order-text-item.component.scss']
})
export class EasytradeOrderTextItemComponent implements OnInit {
  @Input() orderLine: OrderLine
  
  constructor() { }

  ngOnInit(): void {
  }

}
