import { Component, OnInit, Input } from '@angular/core';

import { MainService } from '../../_services/main.service';
import { AuthService } from '../../_services/auth.service';
import { ApiService } from '../../_services/api.service';
import { ShopService } from '../../_services/shop.service';
import { CartService } from '../../_services/cart.service';
import { CarTypeService } from '../../_services/car-type.service';
import { ContextMain } from '../../_models/common/context.main';
import { CarTypeResult } from '../../_models/car-types/car.type.result';
import { CatalogService } from '../../_services/catalog.service';
import { Router } from '@angular/router';
import { UniversalCarType } from '../../_models/car-types/universal.car.type';
import { SettingsService } from '../../_services/settings.service';
import { LoyaltyShopService } from '../../_services/loyalty-shop.service';
import { ShopSoort } from '../../_models/common/shop.soort';
import { MailboxService } from '../../_services/mailbox.service';
import { RimAndTyreService } from '../../_services/rim-and-tyre.service';

@Component({
  selector: 'app-navigation-default',
  templateUrl: './navigation-default.component.html',
  styleUrls: ['./navigation-default.component.scss']
})
export class NavigationDefaultComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctx: ContextMain;
  licensePlate: string;
  item: string;
  backgroundImage: string;

  constructor(
    public mainService: MainService,
    public authService: AuthService,
    public apiService: ApiService,
    public settingsService: SettingsService,
    public shopService: ShopService,
    public cartService: CartService,
    public loyaltyShopService: LoyaltyShopService,
    public carTypeService: CarTypeService,
    public catalogService: CatalogService,
    public rimAndTyreService: RimAndTyreService,
    public mailboxService: MailboxService,
    public router: Router
  ) { }

  ngOnInit() {
    if (this.carTypeService.currentCarType) {
      this.selectCarType(this.carTypeService.currentCarType);
    }
  }

  selectCarType(carType: UniversalCarType) {
    this.shopService.resetCarTypeShops(this.mainService.ctxMain);
    this.carTypeService.setCarType(carType);
    if (carType.LicensePlate) { this.licensePlate = carType.LicensePlate.Formated; }
    this.router.navigate(['/catalog/groups', carType.Id]);
    // this.catalogService.getCategories(carType)
    //   .subscribe(
    //     (ctx: ContextCatalog) => {
    //       if (this.shopService.currentActiveShop !== this.ctx.DefaultCatalog) {
    //         this.shopService.gotoShopModule(this.ctx, this.ctx.DefaultCatalog);
    //       }
    //     });
  }

  carTypeSelected(carTypeResult: CarTypeResult): void {
    this.carTypeService.saveCarType(this.ctx.DefaultCatalog, carTypeResult.CarType)
      .subscribe(result => {
        this.selectCarType(result.CarType);
      });
  }

  getLoyaltyShopLink() {
    // if (this.shopService.containsShopModule(this.mainService.ctxMain, ShopSoort.Bonusshop)) {
    return ['/loyaltyshop'];
    // }
    // return null;
  }

  switchToCatalog() {
    const link = this.catalogService.getLastCategoriesLink();
    if (link) { this.router.navigate(link); }
  }

  switchToTyres() {
    const link = this.rimAndTyreService.getLastTyresLink();
    if (link) { this.router.navigate(link); }
  }

}
