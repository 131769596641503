<td class="text-end cell text-body-tertiary fw-light">{{orderLine.LineNumber}}</td>
<td class="text-end cell text-body-tertiary fw-light">{{orderLine.InternalItemNumber}}</td>
<td class="cell">{{orderLine.ItemNumber}} <span class="text-body-tertiary">({{orderLine.ItemGroup}})</span></td>
<td class="cell">{{orderLine.Description}}</td>
<td class="text-end cell">{{orderLine.Quantity}}</td>
<td class="text-end cell">{{orderLine.QuantityToBeDelivered}}</td>
<td class="text-end cell">{{orderLine.QuantityInBackorder}}</td>
<td class="text-end cell">{{orderLine.GrossPriceItemFile | price}}</td>
<td class="text-end cell">{{orderLine.PackingUnit}}</td>
<td class="text-end cell">{{orderLine.GrossPrice | price}}</td>
<td class="text-end cell">{{orderLine.DiscountRatePercentage}}</td>
<td class="text-end cell">{{orderLine.NetPrice | price}}</td>
<td class="text-end cell">{{orderLine.PriceUnit}}</td>
<td class="text-end cell">{{orderLine.TotalLineAmount | price}}</td>