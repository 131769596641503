export class ShopPortalMessageButton {
    Text: string;
    Command: string;
    CssClass: string;

    constructor(text: string, command: string, cssClass: string) {
        this.Text = text;
        this.Command = command;
        this.CssClass = cssClass;
    }
}
