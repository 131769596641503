import { Component, OnInit, Input } from '@angular/core';
import { ContextGraphicParts } from '../../../../_models/catalog/context.graphic.parts';
import { MainService } from '../../../../_services/main.service';
import { CarTypeService } from '../../../../_services/car-type.service';
import { CatalogService } from '../../../../_services/catalog.service';
import { Router } from '@angular/router';
import { Part } from '../../../../_models/catalog/part';
import { SelectListItem } from '../../../../_models/common/select.list.item';
import { SettingsService } from '../../../../_services/settings.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';

@Component({
  selector: 'app-catalog-graphic-parts-scroll-layout',
  templateUrl: './catalog-graphic-parts-scroll-layout.component.html',
  styleUrls: ['./catalog-graphic-parts-scroll-layout.component.scss']
})
export class CatalogGraphicPartsScrollLayoutComponent implements OnInit {
  shopSoort = ShopSoort;
  _ctx: ContextGraphicParts;
  get ctx(): ContextGraphicParts { return this._ctx; }
  @Input('ctx')
  set ctx(value: ContextGraphicParts) {
    this._ctx = value;
    this.strips = this.ctx.GraphicParts.map(
      strip => {
        return SelectListItem.getSelectListItemWithValue(this.catalogService.getAttributeString(strip), strip, false);
      });
    // this.parts = this.catalogService.getPartsFromContext(this.ctx.Context[this.ctx.currentStrip.StripId]);
    // this.filteredParts = this.parts.filter(part => {
    //   return this.catalogService.filterParts(this.ctx.Context[this.ctx.currentStrip.StripId], part);
    // });
  }
  strips: SelectListItem[];

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public catalogService: CatalogService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

}
