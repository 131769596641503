import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsBase } from '../../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../../_services/admin/admin.service';
import { MainService } from '../../../../_services/main.service';
import { AdminSetting } from '../../../../_models/admin/settings/admin.setting';

@Component({
  selector: 'app-admin-settings-extra-messages',
  templateUrl: './admin-settings-extra-messages.component.html',
  styleUrls: ['./admin-settings-extra-messages.component.scss']
})
export class AdminSettingsExtraMessagesComponent implements OnInit {
  settings: SettingsBase;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const xxx = this.settings;
    this.route.data
      .subscribe((data: any) => {
        if (data.settings) this.settings = data.settings;
      });
  }

  getIds(kind: string): number[] {
    const ids = this.adminSettingsService.getAdminSetting(this.settings, kind).Value;
    if (ids) {
      const result = ids.map(({ Key }) => Key);
      return result;
    }
    return null;
  }

  getIdKeyValue(kind: string, id: number): { "Key": number, "Value": boolean } {
    const ids = this.adminSettingsService.getAdminSetting(this.settings, kind).Value;
    if (ids) {
      const index = ids.findIndex(keyValue => keyValue.Key === id);
      if (index >= 0) return ids[index];
    }
    return null;
  }

  deleteIdKeyValue(kind: string, id: number) {
    const ids = this.adminSettingsService.getAdminSetting(this.settings, kind).Value;
    if (ids) {
      const index = ids.findIndex(keyValue => keyValue.Key === id);
      if (index >= 0) ids.splice(index, 1);
    }
  }

  getIdEnabled(kind: string, id: number): boolean {
    const keyValue = this.getIdKeyValue(kind, id);
    if (keyValue) return keyValue.Value;
    return false;
  }

  setIdEnabled(kind: string, id: number, enabled: boolean) {
    const keyValue = this.getIdKeyValue(kind, id);
    if (keyValue) keyValue.Value = enabled;
  }

  deleteId(kind: string, keyPart: string, id: number) {
    const keyValue = this.deleteIdKeyValue(kind, id);
    if (this.settings.CommonSettings[`${keyPart}${id}`]) delete this.settings.CommonSettings[`${keyPart}${id}`];
  }

  addId(kind: string, keyPart: string, id: number) {
    const keyValue = { "Key": id, "Value": true };
    this.adminSettingsService.getAdminSetting(this.settings, kind).Value.push(keyValue);
    if (!this.settings.CommonSettings[`${keyPart}${id}`]) {
      const setting = new AdminSetting();
      setting.Key = `${keyPart}${id}`;
      setting.Description = '';
      setting.Value = '';
      setting.IsDocument = false;
      setting.Placeholder = '';
      setting.Kind = 0;
      this.settings.CommonSettings[`${keyPart}${id}`] = setting;
    }
  }

  changeId(kind: string, keyPart: string, from: number, to: number) {
    if (from !== to && to > 0) {
      if (this.settings.CommonSettings[`${keyPart}${from}`]) {
        this.settings.CommonSettings[`${keyPart}${to}`] = this.settings.CommonSettings[`${keyPart}${from}`];
        this.settings.CommonSettings[`${keyPart}${to}`]['Key'] = `${keyPart}${to}`;
        delete this.settings.CommonSettings[`${keyPart}${from}`];
      }
      const keyValue = this.getIdKeyValue(kind, from);
      if (!keyValue) this.adminSettingsService.getAdminSetting(this.settings, kind).Value.push({ "Key": to, "Value": true });
      else keyValue.Key = to;
    }
  }

  beforeSave() {
    this.deleteId('ExtraMessage_MainSuppliers', 'ExtraMessage_MainSupplier_', 0);
    this.deleteId('ExtraMessage_ItemGroups', 'ExtraMessage_ItemGroup_', 0);
  }

}
