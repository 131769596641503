export class AccountRequest {
  CompanyName: string;
  KvK: string;
  Frontname: string;
  Lastname: string;
  StreetName: string;
  HouseNumber: string;
  PostalCode: string;
  City: string;
  EmailAddress: string;
  PhoneNumber: string;
  KnownCustomer: boolean;
}