import { Component, OnInit, Input, EventEmitter } from '@angular/core';

import { PartsType } from '../../../../_models/catalog/parts.type';
import { SettingsCatalogPartsTypes } from '../../../../_models/admin/settings/settings.catalog.parts.types';
import { AdminCatalogService } from '../../../../_services/admin/admin-catalog.service';
import { ItemCountComponent } from '../../../../_common/item-count/item-count.component';
import { ThemeService } from 'ng2-charts';


@Component({
  selector: 'app-admin-parts-type-binder',
  templateUrl: './admin-parts-type-binder.component.html',
  styleUrls: ['./admin-parts-type-binder.component.scss']
})
export class AdminPartsTypeBinderComponent implements OnInit {
  @Input() settings: SettingsCatalogPartsTypes;
  @Input() partsType: PartsType;
  @Input() catalog: string;
  extraCross = 0;
  addCrossFocus: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    public adminCatalogService: AdminCatalogService
  ) { }

  ngOnInit() {
  }

  addCross(catalogPartsTypeId: number) {
    this.extraCross = catalogPartsTypeId;
    // setTimeout(() => {
    //   this.addCrossFocus.emit(true);
    // }, 500);

  }

}
