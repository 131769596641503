<app-admin-form [title]="'Route Informatie'" [object]="routeInfoObject">
  <app-admin-radiobutton [label]="'Route voor'" [(model)]="routeType"
    [radios]="{'klant': 0,'filiaal': 1,'leverancier':2}" (modelChange)="routes = []"></app-admin-radiobutton>
  <app-admin-customer-input *ngIf="routeType==0" [label]="'Kies klant'" [focusInput]="focusInput" [(model)]="customer"
    [noneSelectionLabel]="'Geen klant gekozen.'" (modelObjectChange)="this.customerModel = $event"
    (contextmenu)="mainService.showObject($event, this.customerModel)"></app-admin-customer-input>
  <app-admin-custom-control *ngIf="routeType==1" [label]="'Van filiaal'">
    <div class="row">
      <div class="col-5">
        <app-admin-branch-input [labelCols]="0" [(model)]="fromBranch" [noneSelectionLabel]="'Geen filiaal gekozen.'"
          (modelObjectChange)="this.fromBranchModel = $event" [dividerKind]="-1"></app-admin-branch-input>
      </div>
      <div class="col-7">
        <app-admin-branch-input [label]="'Naar filiaal'" [(model)]="toBranch"
          [noneSelectionLabel]="'Geen filiaal gekozen.'" (modelObjectChange)="this.toBranchModel = $event"
          [dividerKind]="-1"></app-admin-branch-input>
      </div>
    </div>
  </app-admin-custom-control>
  <app-admin-custom-control *ngIf="routeType==2" [label]="'Van leverancier'">
    <div class="row g-0">
      <div [ngClass]="{'col-3': depotKeuze, 'col-5': !depotKeuze}">
        <app-admin-supplier-input [labelCols]="0" [focusInput]="focusInput" [(model)]="supplier"
          [noneSelectionLabel]="'Geen leverancier gekozen.'" (modelObjectChange)="supplierChange($event)"
          [dividerKind]="-1"></app-admin-supplier-input>
      </div>
      <div *ngIf="depotKeuze" class="col-3">
        <ng-template #dropdownDepot let-model='model'>
          <span class="me-3">{{model?.Name}}</span><span class="text-low float-end">{{model?.DepotId}}</span>
        </ng-template>
        <app-admin-dropdown [label]="'Depot'" [labelCols]="2" [(model)]="depot"
          [modelValues]="routeInfoObject.SupplierDepotsInfo[supplier]" [modelValueProperty]="'DepotId'"
          [buttonTemplate]="dropdownDepot" [itemTemplate]="dropdownDepot" [dividerKind]="-1">
        </app-admin-dropdown>
        <div *ngIf="routeInfoObject?.BalieDepotInfo[depot]" class="alert alert-warning mt-2">
          <div class="mb-2"><i class="fas fa-exclamation-triangle"></i> Let op!</div>
          Tussen {{getTime(routeInfoObject?.BalieDepotInfo[depot]['Item1'])}} uur en
          {{getTime(routeInfoObject?.BalieDepotInfo[depot]['Item2'])}} uur is dit een balie afhaal depot.
        </div>
      </div>
      <div [ngClass]="{'col-6': depotKeuze, 'col-7': !depotKeuze}">
        <app-admin-branch-input [label]="'Naar filiaal'" [labelCols]="3" [(model)]="toBranch"
          [noneSelectionLabel]="'Geen filiaal gekozen.'" (modelObjectChange)="this.toBranchModel = $event"
          [dividerKind]="-1"></app-admin-branch-input>
      </div>
    </div>
  </app-admin-custom-control>


  <app-admin-date-input [label]="'Tijdstip'" [(model)]="timestamp" [withTimepicker]="true"></app-admin-date-input>
  <app-admin-action-button [label]="' '" [buttonColor]="'primary'" (clickAction)="getRoutes()" [dividerKind]="!hasRun?-1:0"><i
      class="fas fa-truck"></i> ophalen routes</app-admin-action-button>

  <app-admin-custom-control *ngIf="hasRun" [labelCols]="2" [label]="'Routes'" [busy]="busyRouteSearch" [dividerKind]="-1">
    <table *ngIf="routes?.length; else no_routes" class="table table-striped small"
      (contextmenu)="mainService.showObject($event, routes)">
      <thead>
        <th class="text-end">start tijd</th>
        <th class="text-end">bestel stop</th>
        <th>omschrijving</th>
        <th>eind tijd</th>
        <th>route duur</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let route of routes">
          <tr app-admin-route-info-item [route]="route"></tr>
        </ng-container>
      </tbody>
    </table>
    <ng-template #no_routes>
      <div class="alert alert-warning" role="alert">
        Geen routes gevonden!
      </div>
    </ng-template>
  </app-admin-custom-control>


</app-admin-form>