<app-admin-form [title]="'Banden instellingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event">
  <app-admin-dropdown [label]="adminSettingsService.getAdminSetting(settings,'DefaultBandenAantal').Description"
    [(model)]="adminSettingsService.getAdminSetting(settings,'DefaultBandenAantal').Value"
    [modelValues]="adminSettingsService.getAdminSetting(settings,'BandenAantalKeuzes').Value"></app-admin-dropdown>
  <app-admin-checkbox
    [label]="adminSettingsService.getAdminSetting(settings,'NettoprijsOpAanvraagBijGeenBandenVoorraadHoofdleverancier').Description"
    [(model)]="adminSettingsService.getAdminSetting(settings,'NettoprijsOpAanvraagBijGeenBandenVoorraadHoofdleverancier').Value"></app-admin-checkbox>
  <app-admin-checkbox
    [label]="'Toon product-info link'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'TyresNoProductInfoLink').Value" [invertModel]="true"></app-admin-checkbox>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>