export class DatePeriod {
    StartDate: Date;
    EndDate: Date;

    static getLastYear(): DatePeriod {
        const dp = new DatePeriod();
        dp.EndDate = new Date();
        dp.EndDate.setHours(0, 0, 0, 0);
        dp.StartDate = new Date(dp.EndDate.getTime());
        dp.StartDate.setFullYear(dp.EndDate.getFullYear() - 1);
        return dp;
    }

    static getLastXYears(years: number): DatePeriod {
        const dp = new DatePeriod();
        dp.EndDate = new Date();
        dp.EndDate.setHours(0, 0, 0, 0);
        dp.StartDate = new Date(dp.EndDate.getTime());
        dp.StartDate.setFullYear(dp.EndDate.getFullYear() - years);
        return dp;
    }

    static getLastMonth(): DatePeriod {
        const dp = new DatePeriod();
        dp.EndDate = new Date();
        dp.EndDate.setHours(0, 0, 0, 0);
        dp.StartDate = new Date(dp.EndDate.getTime());
        dp.StartDate.setMonth(dp.EndDate.getMonth() - 1);
        return dp;
    }

    equals(datePeriod: DatePeriod): boolean {
        return this.StartDate.getTime() === datePeriod.StartDate.getTime() && this.EndDate.getTime() === datePeriod.EndDate.getTime();
    }
}
