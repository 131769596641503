import { AdminSettingKind } from "./admin.setting.kind";

export class AdminSetting {
  Key: string;
  Description: string;
  Value: any;
  IsDocument: boolean;
  Kind: AdminSettingKind;
  Placeholder: string;
  Sort: number;
}
