import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { AdminCatalogService } from '../../../../../_services/admin/admin-catalog.service';
import { PartsType } from '../../../../../_models/catalog/parts.type';
import { SettingsCatalogPartsTypes } from '../../../../../_models/admin/settings/settings.catalog.parts.types';

@Component({
  selector: 'app-admin-parts-type-input',
  templateUrl: './admin-parts-type-input.component.html',
  styleUrls: ['./admin-parts-type-input.component.scss']
})
export class AdminPartsTypeInputComponent implements OnInit {
  @Input() settings: SettingsCatalogPartsTypes;
  @Input() partsType: PartsType;
  @Input() catalog: string;
  @Input() catalogPartsTypeId: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Output() addCross: EventEmitter<number> = new EventEmitter<number>();
  @Output() crossed: EventEmitter<number> = new EventEmitter<number>();
  isOk: boolean;
  catalogPartsType: PartsType;
  partsTypeDescription = '';
  hasFocus: boolean;

  constructor(
    public adminCatalogService: AdminCatalogService
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.isOk = this.catalogPartsTypeId > 0;
    if (this.isOk && this.settings.PartsTypes[this.catalog]
      && this.settings.PartsTypes[this.catalog][this.catalogPartsTypeId]) {
      this.catalogPartsType = this.settings.PartsTypes[this.catalog][this.catalogPartsTypeId];
      this.partsTypeDescription = `${this.catalogPartsType.Description} (${this.catalogPartsTypeId})`;
    } else if (this.catalogPartsTypeId) {
      this.partsTypeDescription = `(${this.catalogPartsTypeId})`;
    } else {
      this.catalogPartsType = null;
      this.partsTypeDescription = '';
    }
  }

  typeaheadOnSelect(match: TypeaheadMatch) {
    const partsType = match.item as PartsType;
    if (partsType && partsType.ID) {
      if (this.catalogPartsTypeId) { this.deleteCross(false); }
      this.adminCatalogService.setCatalogPartsType(this.settings.PartsTypes, this.catalog, partsType);
      this.adminCatalogService.setCatalogPartsTypeCross(this.settings.PartsTypeCross, this.catalog, this.partsType.ID, partsType);
      this.catalogPartsType = partsType;
      this.catalogPartsTypeId = partsType.ID;
      this.crossed.emit(partsType.ID);
      this.init();
    }
  }

  onFocus(item) {
    this.hasFocus = true;
    if (this.catalogPartsType) {
      this.partsTypeDescription = this.catalogPartsType.Description;
    }
  }

  onBlur(item) {
    this.hasFocus = false;
    this.init();
  }

  deleteCross(reset: boolean) {
    this.adminCatalogService.deleteCatalogPartsType(this.settings.PartsTypes, this.catalog, this.catalogPartsType.ID);
    this.adminCatalogService.deleteCatalogPartsTypeCross(this.settings.PartsTypeCross
      , this.catalog, this.partsType.ID, this.catalogPartsType.ID);
    if (reset) {
      this.catalogPartsTypeId = 0;
      this.init();
    }
  }

}
