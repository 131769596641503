import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MenuItem } from '../../_models/admin/menu.item';
import { Animations } from '../../_animations/animations';
import { Router } from '@angular/router';
import { AdminService } from '../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-menu-item',
  templateUrl: './admin-menu-item.component.html',
  styleUrls: ['./admin-menu-item.component.scss'],
  animations: [Animations.slideInOut]
})
export class AdminMenuItemComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Input() isMainMenu: boolean;
  @Input() active: boolean;
  @Input() search: RegExp;
  @Input() parentSearchHit: boolean;
  @Output() clickMenu: EventEmitter<number> = new EventEmitter();
  activeSubMenuId: number;

  constructor(
    public adminService: AdminService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getRouterLink(): string[] {
    if (this.menuItem && this.menuItem.Route) { return [this.menuItem.Route]; }
    return null;
  }

  clickMe(event: MouseEvent): void {
    this.clickMenu.emit(this.menuItem.ID);
  }

  clickThisMenu(menuItem: MenuItem): void {
    if (this.activeSubMenuId === menuItem.ID) {
      this.activeSubMenuId = 0;
    } else if (menuItem.Route || menuItem.SubMenu) {
      this.activeSubMenuId = menuItem.ID;
    }
  }

  searchHit(menuItem: MenuItem, search: RegExp): boolean {
    if (search && (menuItem.Title.search(search) !== -1
      || (menuItem.Keywords && menuItem.Keywords.search(search) !== -1))) { return true; }
    return false;
  }

  childSearchHit(menuItem: MenuItem, search: RegExp): boolean {
    if (search && menuItem) {
      if (menuItem.SubMenu) {
        let hit = false;
        menuItem.SubMenu.forEach(item => {
          if (!hit && this.searchHit(item, search)) {
            hit = true;
          }
          if (!hit) { hit = this.childSearchHit(item, search); }
        });
        if (hit) { return true; }
      }
    }
    return false;
  }

}
