import { Pipe, PipeTransform } from '@angular/core';
import { Aanbieding } from '../../_models/promotions/aanbieding';

@Pipe({
  name: 'promotionSortOrder',
  pure: false
})
export class PromotionSortOrderPipe implements PipeTransform {

  getSortValue(promo: Aanbieding): number {
    const now = new Date();
    const promoActive = promo.Aktief && (promo.GeldigTot > now);
    let value = 10000000000 - promo.ID;
    if (promoActive) { value = promo.Volgorde; }
    return value;
  }


  transform(promotions: Aanbieding[]): Aanbieding[] {
    if (promotions) {
      return promotions.sort((a1, a2) => this.getSortValue(a1) > this.getSortValue(a2) ? 1 : -1);
    }
    return promotions;
  }

}
