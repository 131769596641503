import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-action-button',
  templateUrl: './admin-action-button.component.html',
  styleUrls: ['./admin-action-button.component.scss']
})
export class AdminActionButtonComponent implements OnInit {
  @Input() label: string;
  @Output() clickAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() buttonColor: string;

  constructor() { }

  ngOnInit() {
    if (!this.buttonColor) { this.buttonColor = 'danger'; }
    if (!this.labelCols) { this.labelCols = 2; }
  }

}
