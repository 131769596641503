import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpEvent } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';

import { PromotionsAdmin } from '../../_models/admin/promotions/promotions.admin';
import { Aanbieding } from '../../_models/promotions/aanbieding';
import { AanbiedingFiliaal } from '../../_models/promotions/aanbieding.filiaal';
import { AanbiedingArtikel } from '../../_models/promotions/aanbieding.artikel';
import { AdminPromotionEditItemComponent } from '../../admin/admin-promotions/admin-promotion-edit/admin-promotion-edit-item/admin-promotion-edit-item.component';
import { AanbiedingImage } from '../../_models/promotions/aanbieding.image';
import { UploadFile } from '../../_common/upload/upload-file.model';
import { MainService } from '../main.service';
import { ApiService } from '../api.service';
import { AdminService } from './admin.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AdminPromotionService {
  cachedData: PromotionsAdmin;
  cachedDataWholesaler: number;

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private adminService: AdminService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  clear() {
    this.cachedData = null;
  }

  getPromotionsAdmin(): Observable<PromotionsAdmin> {
    if (this.cachedData && this.cachedDataWholesaler === this.adminService.currentUid.Wholesaler) {
      return of(this.cachedData);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'Alle aanbiedingen worden opgehaald');
      return this.apiService.adminGetPromotionsAdmin(this.adminService.currentUid)
        .pipe(mergeMap(promoAdmin => {
          this.cachedData = promoAdmin;
          this.cachedDataWholesaler = this.adminService.currentUid.Wholesaler;
          this.adminService.setBadge('promotionsCount', promoAdmin.Promotions.length);
          cb.complete();
          return of(promoAdmin);
        }));
    }
  }

  savePromotion(promotion: Aanbieding): Observable<Aanbieding> {
    return this.apiService.adminSavePromotion(this.adminService.currentUid, promotion)
      .pipe(mergeMap(promo => {
        if (promo) {
          if (promotion.ID === -1) {
            return this.getPromotionsAdmin()
              .pipe(mergeMap(promoAdmin => {
                promoAdmin.Promotions.push(promo);
                this.adminService.setBadge('promotionsCount', promoAdmin.Promotions.length);
                return of(promo);
              }));
          }
          return of(promo);
        }
        return of(null);
      }));
  }

  savePromotionDialog(promotion: Aanbieding): Observable<boolean> {
    if (promotion && promotion.Titel) {
      return this.savePromotion(promotion)
        .pipe(mergeMap(promo => {
          if (promo) {
            this.router.navigate(['/admin/promotions']);
            return of(true);
          } else {
            return this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het opslaan!')
              .pipe(mergeMap(ok => of(false)));
          }
        }));
    } else {
      return new Observable<boolean>((observer) => {
        this.mainService.msgBox('Let op!', 'U heeft geen titel ingevoerd!');
        observer.next(false);
        observer.complete();
      });
    }
  }

  deletePromotion(promotionID: string): Observable<boolean> {
    return this.apiService.adminDeletePromotion(this.adminService.currentUid, promotionID)
      .pipe(mergeMap(ok => {
        if (ok) {
          return this.getPromotionsAdmin()
            .pipe(mergeMap(promoAdmin => {
              if (promoAdmin && promoAdmin.Promotions.length > 0) {
                const promo = promoAdmin.Promotions.find(p => p.AanbiedingID === promotionID);
                const index = promoAdmin.Promotions.indexOf(promo);
                if (index > -1) { promoAdmin.Promotions.splice(index, 1); }
                this.adminService.setBadge('promotionsCount', promoAdmin.Promotions.length);
                return of(ok);
              }
              return of(ok);
            }));
        }
        return of(ok);
      }));
  }

  deletePromotionDialog(promotion: Aanbieding): void {
    this.mainService.confirmBox(`Weet u zeker dat u '${promotion.Titel}' wilt verwijderen?`)
      .subscribe(ja => {
        if (ja) {
          this.deletePromotion(promotion.AanbiedingID)
            .subscribe(ok => {
              if (!ok) {
                this.mainService.msgBox('Let op!', 'Er is iets mis gegaan bij het verwijderen!');
              }
            });
        }
      });
  }

  updatePromotionsSortOrder(promotions: Aanbieding[]): Observable<number> {
    const update = this.adminService.getSortOrderUpdate(promotions, 'ID', 'Volgorde');
    return this.apiService.adminUpdatePromotionsSortOrder(this.adminService.currentUid, update);
  }

  getPromotionById(id: number): Observable<Aanbieding> {
    return this.getPromotionsAdmin()
      .pipe(mergeMap(promoAdmin => {
        if (promoAdmin && promoAdmin.Promotions) {
          let promo: Aanbieding;
          if (id === 0) {
            promo = new Aanbieding();
            promo.Volgorde = -1;
          } else {
            promo = promoAdmin.Promotions.find(p => p.ID === id);
          }
          if (promo) { return of(promo); }
        }
        return of(null);
      }));
  }

  getPromotionBranches(promotion: Aanbieding): Observable<AanbiedingFiliaal[]> {
    return this.getPromotionsAdmin()
      .pipe(mergeMap(promoAdmin => {
        if (promotion && promoAdmin && promoAdmin.Branches.length > 0) {
          const branches = JSON.parse(JSON.stringify(promoAdmin.Branches)) as AanbiedingFiliaal[];
          branches.forEach(branch => {
            if (promotion.Filialen.length === 0 || promotion.Filialen.indexOf(branch.Filiaalnr) !== -1) { branch.Selected = true; }
          });
          return of(branches);
        }
        return of(null);
      }));
  }

  editPromotionItem(promotion: Aanbieding, item: AanbiedingArtikel) {
    if (!item) {
      item = new AanbiedingArtikel();
      item.ID = -1;
    }
    const initialState = {
      item: JSON.parse(JSON.stringify(item)),
      mainService: this.mainService
    };
    const modalRef = this.modalService.show(AdminPromotionEditItemComponent, { initialState, class: 'modal-md' });
    return modalRef.content.onClose
      .subscribe((editedItem: AanbiedingArtikel) => {
        if (item.ID === -1) {
          editedItem.ID = 0;
          promotion.Artikelen.push(editedItem);
        } else {
          Object.keys(editedItem).forEach(key => item[key] = editedItem[key]);
        }
      });
  }

  deletePromotionItem(promotion: Aanbieding, item: AanbiedingArtikel) {
    const index = promotion.Artikelen.indexOf(item);
    if (index > -1) { promotion.Artikelen.splice(index, 1); }
  }

  deletePromotionImage(promotion: Aanbieding, image: AanbiedingImage) {
    const index = promotion.Images.indexOf(image);
    if (index > -1) {
      this.apiService.adminDeletePromotionImage(this.adminService.currentUid, promotion.AanbiedingID, image.VolgNr)
        .subscribe(ok => {
          if (ok) { promotion.Images.splice(index, 1); }
        });
    }
  }

  addPromotionImages(promotionID: string, fileList: UploadFile[]): Observable<HttpEvent<Aanbieding>> {
    return this.apiService.adminAddPromotionImages(this.adminService.currentUid, promotionID, fileList);
  }
}
