<div *ngIf="!descriptions || allesOfNiets(totals, catalogKey, mustInvert())">
  <span>{{getDescription(descriptions)}}{{catalogDescription}}</span>
  <span *ngIf="!descriptions && !alles(totals, catalogKey, mustInvert())">&nbsp;<i>{{mustInvert()?'maar':'behalve'}}</i>:</span>
</div>
<div *ngIf="!allesOfNiets(totals, catalogKey, mustInvert())" [ngClass]="{'ml-3': !descriptions}">
  <ng-container *ngFor="let subTotal of totals.Objects[catalogKey] | keyvalue">    
    <app-catalog-lines-totals-report *ngIf="allesOfIets(subTotal.value, catalogKey, displayInvert())"
      [descriptions]="getNewDescriptions(descriptions,subTotal.key)" [totals]="subTotal.value" [catalogKey]="catalogKey"
      [catalogDescription]="catalogDescription" [invert]="mustInvert()">
    </app-catalog-lines-totals-report>
  </ng-container>
</div>
