import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-custom-control',
  templateUrl: './admin-custom-control.component.html',
  styleUrls: ['./admin-custom-control.component.scss']
})
export class AdminCustomControlComponent implements OnInit {
  @Input() label: string;
  @Input() centerLabel: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() disabled: boolean;
  @Input() busy: boolean;
  @Input() uniqueId: string = Math.random().toString(36).substring(2);

  get labelClass(): string {
    return 'col-sm-' + this.labelCols + (this.centerLabel ? ' my-auto' : ' ms-auto');
  }

  constructor() { }

  ngOnInit() {
    if (this.labelCols !== 0 && !this.labelCols) this.labelCols = 2;
    if (!this.label) this.labelCols = 0;
  }

}
