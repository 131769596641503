import { Component, OnInit, Input } from '@angular/core';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-content-list-item',
  templateUrl: './content-list-item.component.html',
  styleUrls: ['./content-list-item.component.scss']
})
export class ContentListItemComponent implements OnInit {
  @Input() item: any;
  @Input() odd: boolean;
  @Input() columnWidths: number[] = [2, 6, 4];
  @Input() textCenterFirstColumn = true;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
