import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';

import { SettingsBase } from '../../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../../_services/admin/admin.service';
import { MainService } from '../../../../_services/main.service';
import { CustomerModel } from '../../../../_models/common/customer.model';
import { VoorraadTemplateRendered } from '../../../../_models/item-info/voorraad.template.rendered';
import { AvailabilityTemplatePart } from '../../../../_models/item-info/availability.template.part';
import { ShopModule } from '../../../../_models/common/shop.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AvailabilityTemplatesOverviewComponent } from './availability-templates-overview/availability-templates-overview.component';

@Component({
  selector: 'app-admin-settings-availability-templates',
  templateUrl: './admin-settings-availability-templates.component.html',
  styleUrls: ['./admin-settings-availability-templates.component.scss']
})
export class AdminSettingsAvailabilityTemplatesComponent implements OnInit {
  settings: SettingsBase;
  data: {
    templateParts: { [key: number]: AvailabilityTemplatePart };
    shopModules: { [key: number]: ShopModule };
    availabilityTemplateComposition: { [key: number]: number[] };
    settingsPerTemplatePart: { [key: string]: string };
    renderedTemplateParts: { [key: number]: VoorraadTemplateRendered };
  };

  currentTemplate: number = 0;
  busy: boolean;
  testBusy: boolean = false;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settingsChanged(data.settings);
        this.updateRenderedTemplateParts();
      });
  }

  settingsChanged(settings: SettingsBase) {
    this.settings = settings;
    let renderedTemplateParts = this.data?.renderedTemplateParts;
    if (!renderedTemplateParts) renderedTemplateParts = {};
    this.data = {
      templateParts: this.adminSettingsService.getAdminSetting(this.settings, 'TemplateParts').Value,
      shopModules: this.adminSettingsService.getAdminSetting(this.settings, 'ShopModules').Value,
      availabilityTemplateComposition: this.adminSettingsService.getAdminSetting(this.settings, 'AvailabilityTemplateComposition')?.Value,
      settingsPerTemplatePart: this.adminSettingsService.getAdminSetting(this.settings, 'SettingsPerTemplatePart')?.Value,
      renderedTemplateParts: renderedTemplateParts
    }
  }

  customerChanged(customer: CustomerModel) {
    this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestCustomer').Value = customer.CustomerNumber;
    this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestBranch').Value = customer.BranchNumber;
    this.updateRenderedTemplateParts();
  }

  updateRenderedTemplateParts() {
    const branch = this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestBranch').Value;
    const customer = this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestCustomer').Value;
    const internalItemNumber = this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestInternalItemNumber').Value;
    const itemCount = this.adminSettingsService.getAdminSetting(this.settings, 'SettingsAvailabilityTemplates_DefaultTestItemCount').Value;
    if (internalItemNumber != 0) {
      this.busy = true;
      const templatePartsIds = Object.keys(this.adminSettingsService.getAdminSetting(this.settings, 'TemplateParts').Value).map(i => +i);
      this.adminService.getRenderedAvailabilityTemplateParts(branch, customer, internalItemNumber, itemCount, templatePartsIds)
        .subscribe(renderedTemplateParts => {
          this.data.renderedTemplateParts = renderedTemplateParts;
          this.busy = false;
        });
    }
  }

  getShopModuleName(shopId: number): string {
    if (shopId === 0) return 'Standaard';
    if (this.data.shopModules[shopId]) return this.data.shopModules[shopId].Naam;
    return shopId.toString();
  }

  addTemplate() {
    this.currentTemplate == -99;
    const shopKeuze = Object.values(this.data.shopModules)
      .filter(s => !Object.keys(this.data.availabilityTemplateComposition).includes(s.ID.toString()))
      .reduce((map, item) => {
        map[item.Naam] = item.ID;
        return map;
      }, {});

    this.mainService.selectionBoxExtended('Kies een module t.b.v. de template', shopKeuze, null, 'modal-sm')
      .subscribe(id => {
        if (id) {
          this.data.availabilityTemplateComposition[id] = [];
          this.currentTemplate = id;
        }
      });
  }

  deletePart(partId: number) {
    if (this.data.availabilityTemplateComposition[this.currentTemplate]) {
      this.data.availabilityTemplateComposition[this.currentTemplate].delete(partId);
    }
  }

  hasConfig(partId: number) {
    return this.data.settingsPerTemplatePart[this.data.templateParts[partId].Name];
  }

  configurePart(partId: number) {
    this.adminSettingsService.openAdminModalSettingsComponent(this.data.settingsPerTemplatePart[this.data.templateParts[partId].Name]);
  }

  addPart() {
    const initialState = {
      data: this.data,
      busy: this.testBusy ? 2 : 0
    };
    const modalRef = this.modalService.show(AvailabilityTemplatesOverviewComponent, { initialState, class: 'modal-max' });
    return modalRef.content.onClose
      .subscribe((templatePart: number) => {
        if (templatePart && this.data.availabilityTemplateComposition[this.currentTemplate]) {
          this.data.availabilityTemplateComposition[this.currentTemplate].push(templatePart);
        }
      });
  }

  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}
