export class ReturnDetail {
  LineNumber: number;
  InternalItemNumber: number;
  ItemNumber: string;
  ItemDescription: string;
  ItemGroup: number;
  Amount: number;
  Reason: number;
  ReasonDescription: string;
  DeliverNoteNumber: number;
  GrossPrice: number;
  NetPrice: number;
  PriceUnit: number;
  TotalAmount: number;
  AmountOnDeliveryNote: number;
}
