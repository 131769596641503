import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';
import { ContextMpm } from '../../../_models/mpm/context.mpm';
import { MpmItem } from '../../../_models/mpm/mpm.item';

@Component({
  selector: 'app-mpm-list-item',
  templateUrl: './mpm-list-item.component.html',
  styleUrls: ['./mpm-list-item.component.scss']
})
export class MpmListItemComponent implements OnInit {
  @Input() ctx: ContextMpm;
  @Input() item: MpmItem;
  @Input() odd: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getCartItemForItem(cartItems: { [key: string]: ShoppingCartItem }, item: MpmItem) {
    if (cartItems && cartItems[item.ArticleNumber]) {
      return cartItems[item.ArticleNumber];
    }
    return null;
  }

}
