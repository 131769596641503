<app-content-list-item *ngIf="true" [item]="wheel" [odd]="odd">
  <ng-container col-1>
    <img *ngIf="wheel?.Images?.length>0" [src]="wheel.Images[0]" class="wheel-image pointer" (click)="rimAndTyreService.showWheelInfoPopup(ctx,wheel)" />
    <div class="row mt-3">
      <div class="col pe-1"><button type="button" class="btn btn-info w-100 btn-xs" (click)="rimAndTyreService.showWheelInfoPopup(ctx,wheel)"><i class="fas fa-info"></i></button></div>
      <div class="col ps-0"><button type="button" class="btn btn-info w-100 btn-xs" (click)="rimAndTyreService.showWheelCarPicture(ctx.CarType, wheel)"><i class="fas fa-camera-alt"></i></button></div>
    </div>
    <img *ngIf="wheel.BrandLogo" class="mt-3" [src]="wheel.BrandLogo" />
  </ng-container>
  <ng-container col-2>
    <app-part-list-item-info [part]="wheel" [partsProperties]="ctx.PartsProperties" [partsPropertyTranslations]="ctx.PartsPropertyTranslations" [shopKind]="ctx.ShopKind"></app-part-list-item-info>
  </ng-container>
  <ng-container col-3>
    <div class="row p-1">
      <div class="col part-item-info">
        <app-item-info-template [busy]="ctx['busy']" [sci]="catalogService.getCartItemForPart(ctx.WheelCartItems, wheel)"
          [nettoprijs]="ctx.Nettoprijs" [part]="wheel">
        </app-item-info-template>
      </div>
    </div>
    <div class="row p-1 my-3">
      <div class="col">
        <div class="row">
          <div class="col-4 text-center icon">
            <span *ngIf="wheel.Winter;else noWinter" tooltip="Zeer geschikt als wintervelg" placement="bottom" container="body"><i class="far fa-snowflake icon-active"></i></span>
            <ng-template #noWinter><i class="far fa-snowflake"></i></ng-template>
          </div>
          <div class="col-4 text-center icon">
            <span *ngIf="wheel.Runflat; else noRunFlat" tooltip="Geschikt voor runflat banden" placement="bottom" container="body"><i class="fas fa-tire-flat icon-active"></i></span>
            <ng-template #noRunFlat><i class="fas fa-tire-flat icon-active"></i></ng-template>  
          </div>
          <div class="col-4 text-center icon">
            <span *ngIf="wheel.Tpms;else noTpms" tooltip="Deze velg is geschikt voor TPMS" placement="bottom" container="body"><i class="fas fa-tire-pressure-warning icon-active"></i></span>
            <ng-template #noTpms><i class="fas fa-tire-pressure-warning"></i></ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-1">
      <div class="col-12 pb-1">
        <button class="btn btn-sm btn-warning w-100" (click)="rimAndTyreService.orderRims(ctx, wheel)"><i class="far fa-shopping-cart"></i> Alleen velgen</button>
      </div>
      <div class="col-12">
        <button class="btn btn-sm btn-warning w-100" (click)="rimAndTyreService.chooseTyres(ctx, wheel)"><i class="far fa-shopping-cart"></i> Velgen+banden</button>
      </div>
    </div>
  </ng-container>
</app-content-list-item>
