import { Pipe, PipeTransform } from '@angular/core';
import { WholesalerModel } from 'app/_models/common/wholesaler.model';

@Pipe({
  name: 'wholesalerFilter',
  standalone: false
})
export class WholesalerFilterPipe implements PipeTransform {

  transform(wholesalers: WholesalerModel[], filter: string): WholesalerModel[] {
    if (wholesalers && filter) {
      const lowercase = filter.toLowerCase();
      return wholesalers.filter(wholesaler => {
        if (wholesaler?.Name.toLowerCase().indexOf(lowercase) >= 0) return true;
        if (wholesaler?.ID.toString().startsWith(lowercase)) return true;
        return false;
      });
    }
    return wholesalers;
  }

}
