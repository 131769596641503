import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { LoyaltyShopData } from '../../_models/loyalty-shop/loyalty.shop.data';
import { AdminLoyaltyShopService } from '../../_services/admin/admin-loyalty-shop.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLoyaltyShopDataResolverService   {

  constructor(
    private adminLoyaltyShopService: AdminLoyaltyShopService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoyaltyShopData> | Observable<never> {
    return this.adminLoyaltyShopService.getLoyaltyShopData().pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
