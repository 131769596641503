import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../_models/common/context.main';
import { ShopService } from '../_services/shop.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
@Input() ctx: ContextMain;

  constructor(
    public shopService: ShopService
  ) {}

  ngOnInit() {
  }

}
