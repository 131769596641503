import { Component, Input, OnInit } from '@angular/core';
import { MainService } from '../../../../../_services/main.service';
import { Animations } from '../../../../../_animations/animations';

@Component({
  selector: '[app-archive-json-panel]',
  templateUrl: './archive-json-panel.component.html',
  styleUrls: ['./archive-json-panel.component.scss'],
  animations: [Animations.slideInOut, Animations.slideAndFadeInOut]
})
export class ArchiveJsonPanelComponent implements OnInit {
  @Input() title: string;
  @Input() json: string;
  object: any;
  panelOpen = false;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit(): void {
    this.object = JSON.parse(this.json);
  }

}
