<div class="main-content-wwp">
  <div *ngIf="ctx" class="container-fluid p-3">


    <app-header-panel [ctx]="ctx" [timing]="ctx?.Timing" [preText]="ctx.Category.CategoryName + ' voor een '"
      (backClick)="gotoGroups()">
      <div class="row align-items-center mt-0 g-0 justify-content-end">
        <div class="col-sm-auto ps-2">
          <app-voorraad-prijs-display-opties [ctx]="ctx" [viewKinds]="viewKinds" [disableDisplayOptions]="settingsService.getSettingBoolean('HideCatalogPartsBoxedLayoutDisplayOptions')"></app-voorraad-prijs-display-opties>
        </div>
      </div>
    </app-header-panel>

    <app-catalog-parts-filter-row [ctx]="ctx"></app-catalog-parts-filter-row>

    <ng-container [ngSwitch]="ctx.ViewKind">
      <div *ngSwitchDefault class="my-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
        <app-catalog-parts-description-parts *ngFor="let description of ctx.PartsDescriptions" [ctx]="ctx"
          [partsDescription]="description"></app-catalog-parts-description-parts>
      </div>
      <div *ngSwitchCase="2">
        <div class="card border-primary my-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
          <app-catalog-parts-compact-list [ctx]="ctx" [maxItems]="settingsService.getSettingNumber('ShowCatalogPartsPerCount')"></app-catalog-parts-compact-list>
        </div>
      </div>
    </ng-container>

  </div>
</div>
