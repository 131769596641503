<app-admin-form [title]="'Onderhoud Webservices Leveranciers'" [object]="supplierInterfaceData">
  <app-admin-input [labelCols]="1" [label]="'Zoek'" [dividerKind]="1" [(model)]="filterString"
    (inputClick)="clearFilter()" (modelChange)="filter(supplierInterfaceData)" [placeholder]="'geef hier eventueel een zoekstring'">
    <button class="btn btn-outline-secondary" type="button" (click)="switchFilterUnknown()">
      <span *ngIf="!filterUnknown" class="text-success"><i class="fas fa-question"></i></span>
      <span *ngIf="filterUnknown" class="text-low"><i class="fas fa-question"></i></span>
    </button>
    <button class="btn btn-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span><i class="fas fa-plus"></i></span>
    </button>
    <div class="dropdown-menu interface-kind-list">
      <button *ngFor="let interfaceKind of interfaceKinds" class="dropdown-item" type="button" (click)="addInterfaceData(interfaceKind.InterfaceKind)">{{interfaceKind.Name}}<span *ngIf="interfaceKind.InterfaceKind" class="text-low"> ({{interfaceKind.InterfaceKind}})</span></button>
    </div>
    <span class="input-group-text"><span class="small">{{countFiltered}} / {{countTotal}}</span></span>
  </app-admin-input>
  
  <div *ngFor="let groupedInterfaceData of groupedInterfaceDatas" class="row border-top py-3">
    <div class="col-2 text-center" (dblclick)="enableDebug(groupedInterfaceData.intType)">
      <span *ngIf="getKind(groupedInterfaceData.intType).NeedsProxy" class="me-1" [ngClass]="{'text-danger': !hasKind(1000)}" tooltip="Deze koppeling gaat via het datacenter en heeft de 'Interface Proxy' koppeling nodig."><i class="far fa-cloud-upload"></i></span>
      <span *ngIf="getKind(groupedInterfaceData.intType).InterfaceKind===1000" class="me-1"><i
          class="far fa-globe"></i></span>
      <b>{{getKind(groupedInterfaceData.intType).Name}}</b><span *ngIf="groupedInterfaceData.intType" class="text-low"> ({{groupedInterfaceData.intType}})</span>
    </div>
    <div class="col-10">

      <ng-container *ngIf="!debug[groupedInterfaceData.intType]; else default" [ngSwitch]="groupedInterfaceData.intType">

        <app-default-supplier-interface-settings *ngSwitchDefault
          [interfaceDataSuppliers]="groupedInterfaceData.interfaceDatas" [supplierInterfaceData]="supplierInterfaceData"
          (interfaceDataChanged)="interfaceDataChanged.emit($event)"></app-default-supplier-interface-settings>

        <app-easycloud-stockinfo-supplier-interface-settings *ngSwitchCase="22"
          [interfaceDataSuppliers]="groupedInterfaceData.interfaceDatas" [supplierInterfaceData]="supplierInterfaceData"
          (interfaceDataChanged)="interfaceDataChanged.emit($event)"></app-easycloud-stockinfo-supplier-interface-settings>

        <app-proxy-supplier-interface-settings *ngSwitchCase="1000"
          [interfaceDataSuppliers]="groupedInterfaceData.interfaceDatas" [supplierInterfaceData]="supplierInterfaceData"
          (interfaceDataChanged)="interfaceDataChanged.emit($event)"></app-proxy-supplier-interface-settings>

      </ng-container>
      <ng-template #default>
        <app-default-supplier-interface-settings 
          [interfaceDataSuppliers]="groupedInterfaceData.interfaceDatas" [supplierInterfaceData]="supplierInterfaceData"
          (interfaceDataChanged)="interfaceDataChanged.emit($event)"></app-default-supplier-interface-settings>
      </ng-template>
    </div>
  </div>

  <div *ngIf="countFiltered===0 && filterString" class="alert alert-danger mt-3" role="alert">
    <i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!
  </div>

</app-admin-form>