import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { ContextMpm } from '../../_models/mpm/context.mpm';
import { ApiService } from '../../_services/api.service';
import { CarTypeService } from '../../_services/car-type.service';
import { MainService } from 'app/_services/main.service';


@Injectable({
  providedIn: 'root'
})
export class MpmDataResolverService  {

  constructor(
    private apiService: ApiService,
    private carTypeService: CarTypeService,
    private mainService: MainService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextMpm> | Observable<never> {
    const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'De MPM data wordt geladen.', '');
    const carType = this.carTypeService.currentCarType;

    return this.apiService.getContextMpm(carType).pipe(
      take(1),
      mergeMap(ctx => {
        cb.complete();
        if (ctx) {
          return of(ctx);
        } else { // no data?
          return EMPTY;
        }
      })
    );

  }

}
