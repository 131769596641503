import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { CatalogService } from '../../_services/catalog.service';
import { CarTypeService } from '../../_services/car-type.service';
import { ShopRouteConfig } from '../../_models/common/shop.route.config';
import { ShopSoort } from '../../_models/common/shop.soort';
import { ToasterService } from '../../_services/toaster.service';


@Injectable({
  providedIn: 'root'
})
export class CatalogPartsDataResolverService  {

  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private carTypeService: CarTypeService,
    private toasterService: ToasterService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextCatalog> | Observable<never> {
    const categoryId = route.paramMap.get('categoryId');
    const carType = this.carTypeService.currentCarType;
    const category = this.catalogService.getCategoryByCarTypeIdAndCategoryId(carType.Id, categoryId);
    const description = route.queryParamMap.get('description');

    if (!(carType && category)) { this.router.navigate([ShopRouteConfig.routePaths[ShopSoort.Catalogus]]); }

    return this.catalogService.getParts(carType, category, description).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          return of(ctx);
        } else { // no data?
          this.toasterService.showToast('Helaas', 'Er zijn geen artikelen gevonden in deze groep.');
          return EMPTY;
        }
      })
    );

  }

}
