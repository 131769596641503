import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ItemInfoService } from '../../_services/item-info.service';
import { ApiService } from '../../_services/api.service';
import { TypeaheadItem } from '../../_models/typeahead/typeahead.item';
import { Observable, mergeMap, of } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';



@Component({
  selector: 'app-item-search-input',
  templateUrl: './item-search-input.component.html',
  styleUrls: ['./item-search-input.component.scss']
})
export class ItemSearchInputComponent implements OnInit {
  @Input() useSeperateInputs: boolean = false;
  @Input() useTypeahead: boolean;
  @Input() useSearchInDescriptionCheckbox: boolean;
  typeaheadItems: TypeaheadItem[];
  searchText: string;
  searchDescription: string;
  searchInDescription = false;
  hasFocus = false;

  constructor(
    public itemInfoService: ItemInfoService,
    public apiService: ApiService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  typeaheadOnSelect(match: TypeaheadMatch) {
    // this.selected.emit(match.item);
    console.info(this.searchText);
  }

  getTypeaheadItem(): Observable<TypeaheadItem[]> {
    if (this.searchText) {
      return this.apiService.getTypeaheadItemNumber(this.searchText, 10, true)
        .pipe(mergeMap(items => {
          this.typeaheadItems = items;
          return of(items);
        }));
    } else {
      return of([]);
    }
  }

  onEnterKeyTypeahead() {
    let searchInDescription = false;
    if (this.typeaheadItems && this.typeaheadItems.length > 0 && this.typeaheadItems[0].Score < 1000) { searchInDescription = true; }
    this.router.navigate(['/iteminfo', this.searchText], { queryParams: this.itemInfoService.getQueryParams(searchInDescription, 0) });
  }

  reset() {
    this.searchText = '';
  }

  onBlur() {
    setTimeout(() => {
      this.hasFocus = false;
    }, 500);
  }

}


