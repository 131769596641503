<app-admin-form [title]="'Kadoshop instellingen'" [object]="settings">
  <app-admin-checkbox [label]="'Spaarsysteem aktief'" [(model)]="settings.LoyaltyShopActive"></app-admin-checkbox>
  <app-admin-input [label]="'Naam spaarsysteem'" [(model)]="settings.NameOfLoyaltyShop" [disabled]="!settings.LoyaltyShopActive"></app-admin-input>
  <app-admin-input [label]="'Naam spaarpunten'" [(model)]="settings.NameOfPointsLoyaltyShop" [disabled]="!settings.LoyaltyShopActive"></app-admin-input>
  <app-admin-number-input [label]="'Aantal '+settings.NameOfPointsLoyaltyShop+' te ontvangen'" [(model)]="settings.Ratio_CreditsToReceive" [decimals]="2" [disabled]="!settings.LoyaltyShopActive"></app-admin-number-input>
  <app-admin-number-input [label]="'Voor iedere'" [(model)]="settings.Ratio_PerAmount" [decimals]="2" [disabled]="!settings.LoyaltyShopActive"></app-admin-number-input>
  <app-admin-radiobutton [label]="'Geef '+settings.NameOfPointsLoyaltyShop+' over'" [(model)]="settings.CreditsOnGrossOrderAmount" [radios]="{'het bruto bedrag': true,'het netto bedrag': false}" [disabled]="!settings.LoyaltyShopActive"></app-admin-radiobutton>
  <app-admin-custom-control *ngIf="settings.LoyaltyShopActive" [label]="'Voorbeeld'">
    <div class="float-start fix-vertical-position">
      <span>Over een orderbedrag van &euro;</span>
      <input type="text" class="amount mx-1" appNumericOnly [decimals]="2" [format]="true" [ngModel]="orderAmount" (ngModelChange)="orderAmount = $event" placeholder="voorbeel bedrag" autocomplete="off">
      <span>ontvangt de klant :</span>
      <div class="m-3 text-center font-italic">
        <span class="fw-light">({{orderAmount}} /
          {{settings.Ratio_PerAmount}}) x
          {{settings.Ratio_CreditsToReceive}} =<br />
          ({{floor(orderAmount/settings.Ratio_PerAmount)}}) x {{settings.Ratio_CreditsToReceive}} =</span><br />
        <div class="fw-bold m-2">
          {{getPoints()}} {{settings.NameOfPointsLoyaltyShop}}
        </div>
      </div>
    </div>
  </app-admin-custom-control>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsLoyaltyShop(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>