import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BsModalService } from 'ngx-bootstrap/modal';

import { CatalogLayoutSubgroupCategoryCrossLink } from '../_models/admin/settings/catalog.layout.subgroup.category.cross.link';
import { CatalogLayoutFilterSettings } from '../_models/admin/settings/catalog.layout.filter.settings';
import { CatalogLayoutPartsDescription } from '../_models/admin/settings/catalog.layout.parts.description';
import { CatalogLayoutSubgroup } from '../_models/admin/settings/catalog.layout.subgroup';
import { SettingsCatalogLayoutCatalogKindInfo } from '../_models/admin/settings/settings.catalog.layout.catalog.kind.info';
import { CatalogLayoutMaingroup } from '../_models/admin/settings/catalog.layout.maingroup';
import { CatalogLayoutEditStatus } from '../_models/admin/settings/catalog.layout.edit.status';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutChooseCatalogComponent } from '../admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-choose-catalog/admin-catalog-layout-choose-catalog.component';
import { CatalogKind } from '../_models/catalog/catalog.kind';

@Injectable({
  providedIn: 'root'
})
export class CatalogLayoutService {

  constructor(
    private modalService: BsModalService
  ) { }

  public chooseCatalogs(catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo })
    : Observable<{ clear: boolean, catalogInfo: { [key: number]: boolean } }> {
    const initialState = {
      catalogKindInfo: catalogKindInfo
    };
    const modalRef = this.modalService.show(AdminCatalogLayoutChooseCatalogComponent, { initialState, class: 'modal-md' });
    return modalRef.content.onClose;
  }

  doAutoFill(catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo }, groups: CatalogLayoutMaingroup[]
    , cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }) {
    this.chooseCatalogs(catalogKindInfo)
      .subscribe(info => this.autoFill(info, groups, cross));
  }

  autoFill(info: { clear: boolean, catalogInfo: { [key: number]: boolean } }, groups: CatalogLayoutMaingroup[]
    , cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }) {
    if (info.clear) { this.clearGroups(groups, cross); }
    const sorted = cross[-1].sort((cat1, cat2) => {
      if (cat1.MainCategorySortOrder > cat2.MainCategorySortOrder) { return 1; }
      if (cat1.MainCategorySortOrder < cat2.MainCategorySortOrder) { return -1; }
      if (cat1.CategoryDescription > cat2.CategoryDescription) { return 1; }
      if (cat1.CategoryDescription < cat2.CategoryDescription) { return -1; }
      return 0;
    });
    sorted.forEach(category => {
      if (info.catalogInfo[category.Origin]) {
        this.addCategory(groups, cross, category);
      }
    });
  }



  isCategoryLinked(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }
    , crosslink: CatalogLayoutSubgroupCategoryCrossLink): boolean {
    const subgroups = this.getCategoryLinkedSubgroups(cross, crosslink);
    if (subgroups.length > 0) {
      return true;
    }
    return false;
  }

  getCategoryLinkedSubgroups(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }
    , crosslink: CatalogLayoutSubgroupCategoryCrossLink): string[] {
    const subgroups: string[] = [];
    Object.keys(cross).forEach(subgroup => {
      if (subgroup !== '-1') {
        cross[subgroup]
          .forEach(c => {
            if (c.Status !== CatalogLayoutEditStatus.Deleted && c.Origin === crosslink.Origin && c.CategoryId === crosslink.CategoryId) {
              subgroups.push(subgroup);
            }
          });
      }
    });
    return subgroups;
  }

  getMaingroupBySubgroupId(groups: CatalogLayoutMaingroup[], subgroup: string) {
    let name = '';
    if (groups && subgroup) {
      groups.forEach(g => {
        g.Subgroups.forEach(s => {
          if (s.ID.toString() === subgroup) { name = g.Description; }
        });
      });
    }
    return name;
  }

  getSubgroupBySubgroupId(groups: CatalogLayoutMaingroup[], subgroup: string) {
    let name = '';
    if (groups && subgroup) {
      groups.forEach(g => {
        g.Subgroups.forEach(s => {
          if (s.ID.toString() === subgroup) { name = s.Description; }
        });
      });
    }
    return name;
  }

  countLinkedCategories(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }): number {
    let count = 0;
    if (cross && cross['-1']) {
      cross['-1'].forEach(c => {
        if (this.isCategoryLinked(cross, c)) { count++; }
      });
    }
    return count;
  }

  countFilterHitCategories(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }, filter: CatalogLayoutFilterSettings
    , partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } }): number {
    let count = 0;
    if (cross && cross['-1']) {
      cross['-1'].forEach(c => {
        if (this.filterHitCategory(c, filter, partsDescriptions)) { count++; }
      });
    }
    return count;
  }

  areCategoriesEqual(selected: CatalogLayoutSubgroupCategoryCrossLink, category: CatalogLayoutSubgroupCategoryCrossLink): boolean {
    return (selected && category && selected.CategoryId === category.CategoryId && selected.Origin === category.Origin);
  }

  getCatalogInfo(catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo; }
    , origin: CatalogKind): SettingsCatalogLayoutCatalogKindInfo {
    if (origin && catalogKindInfo && catalogKindInfo[origin]) {
      return catalogKindInfo[origin.toString()];
    }
    return new SettingsCatalogLayoutCatalogKindInfo;
  }

  getCategoriesFromCross(
    cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }, subgroup: CatalogLayoutSubgroup
  ): CatalogLayoutSubgroupCategoryCrossLink[] {
    if (subgroup && cross) {
      if (!cross[subgroup.ID]) { cross[subgroup.ID] = []; }
      return cross[subgroup.ID];
    }
    return null;
  }

  getPartsDescriptionsForCategory(descriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } }
    , category: CatalogLayoutSubgroupCategoryCrossLink): CatalogLayoutPartsDescription[] {
    if (descriptions && category) {
      if (descriptions[category.Origin] && descriptions[category.Origin][category.CategoryId]) {
        return descriptions[category.Origin][category.CategoryId];
      }
    }
    return null;
  }

  filterHitPartsDescription(partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } }
    , category: CatalogLayoutSubgroupCategoryCrossLink, filter: CatalogLayoutFilterSettings): boolean {
    if (partsDescriptions && filter) {
      let partsDescriptionHit = false;
      let brandFilterHit = false;
      const descriptions = this.getPartsDescriptionsForCategory(partsDescriptions, category);
      if (descriptions) {
        descriptions.forEach(description => {
          if (!filter.extendedFilter) {
            if (filter.withPartsDescriptions && description.Description.toLowerCase().includes(filter.filterString.toLowerCase())) {
              partsDescriptionHit = true;
            }
            if (filter.withBrands && description.Brand.toLowerCase().includes(filter.filterString.toLowerCase())) {
              brandFilterHit = true;
            }
          } else {
            if (!filter.partsDescriptionFilterString
              || description.Description.toLowerCase().includes(filter.partsDescriptionFilterString.toLowerCase())) {
              partsDescriptionHit = true;
            }
            if (!filter.brandFilterString || description.Brand.toLowerCase().includes(filter.brandFilterString.toLowerCase())) {
              brandFilterHit = true;
            }
          }
        });
        if (!filter.extendedFilter && (partsDescriptionHit || brandFilterHit)) { return true; }
        if (filter.extendedFilter && partsDescriptionHit && brandFilterHit) { return true; }
      }
    }
    return false;
  }

  filterHitCategory(category: CatalogLayoutSubgroupCategoryCrossLink, filter: CatalogLayoutFilterSettings
    , partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } }): boolean {
    if (filter && category) {
      if (!filter.extendedFilter && !filter.filterString) { return true; }
      if (filter.extendedFilter && !filter.filterString
        && !filter.partsDescriptionFilterString && !filter.brandFilterString) { return true; }
      const categoryFilterHit = !filter.filterString
        || category.CategoryDescription.toLowerCase().includes(filter.filterString.toLowerCase());
      const partsDescriptionsHit = this.filterHitPartsDescription(partsDescriptions, category, filter);
      if (!filter.extendedFilter && (categoryFilterHit || partsDescriptionsHit)) { return true; }
      if (filter.extendedFilter && categoryFilterHit && partsDescriptionsHit) { return true; }
      return false;
    }
    return true;
  }

  filterHitSubgroup(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }
    , subgroup: CatalogLayoutSubgroup, filter: CatalogLayoutFilterSettings
    , partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } }): boolean {
    const categories = this.getCategoriesFromCross(cross, subgroup);
    if (categories) {
      let hit = false;
      categories.forEach(category => {
        if (this.filterHitCategory(category, filter, partsDescriptions)) { hit = true; }
      });
      return hit;
    }
    return true;
  }

  isFilterEmpty(filter: CatalogLayoutFilterSettings) {
    if (filter && !filter.extendedFilter && filter.filterString) {
      return false;
    }
    if (filter && filter.extendedFilter
      && (filter.filterString || filter.partsDescriptionFilterString || filter.brandFilterString)) {
      return false;
    }
    return true;
  }

  clearGroups(groups: CatalogLayoutMaingroup[], cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }) {
    groups.forEach(group => {
      this.deleteMaingroup(cross, group);
    });
  }

  getMaingroupByDescription(groups: CatalogLayoutMaingroup[], description: string): CatalogLayoutMaingroup {
    let maingroup = null;
    groups.forEach(g => {
      if (g.Status !== CatalogLayoutEditStatus.Deleted && g.Description.toLowerCase() === description.toLowerCase()) { maingroup = g; }
    });
    return maingroup;
  }

  addMaingroup(groups: CatalogLayoutMaingroup[], description: string): CatalogLayoutMaingroup {
    let sortOrder = 0;
    if (groups && groups.length > 0) { sortOrder = groups[groups.length - 1].SortOrder + 1; }
    const group = new CatalogLayoutMaingroup();
    group.ID = this.getUniqueID(groups);
    group.SortOrder = sortOrder;
    group.Description = description;
    group.Subgroups = [];
    group.Status = CatalogLayoutEditStatus.New;
    groups.push(group);
    return group;
  }

  deleteMaingroup(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }, maingroup: CatalogLayoutMaingroup) {
    maingroup.Status = CatalogLayoutEditStatus.Deleted;
    maingroup.Subgroups.forEach(subgroup => this.deleteSubgroup(cross, subgroup));
  }

  getSubgroupByDescription(maingroup: CatalogLayoutMaingroup, description: string): CatalogLayoutSubgroup {
    let subgroup = null;
    maingroup.Subgroups.forEach(g => {
      if (g.Status !== CatalogLayoutEditStatus.Deleted && g.Description.toLowerCase() === description.toLowerCase()) { subgroup = g; }
    });
    return subgroup;
  }

  addSubgroup(groups: CatalogLayoutMaingroup[], maingroup: CatalogLayoutMaingroup, description: string): CatalogLayoutSubgroup {
    let sortOrder = 0;
    if (maingroup.Subgroups && maingroup.Subgroups.length > 0) {
      sortOrder = maingroup.Subgroups[maingroup.Subgroups.length - 1].SortOrder + 1;
    }
    const subgroup = new CatalogLayoutSubgroup();
    subgroup.ID = this.getUniqueID(groups);
    subgroup.SortOrder = sortOrder;
    subgroup.Description = description;
    subgroup.Maingroup = maingroup.ID;
    subgroup.Status = CatalogLayoutEditStatus.New;
    maingroup.Subgroups.push(subgroup);
    return subgroup;
  }

  deleteSubgroup(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }, subgroup: CatalogLayoutSubgroup) {
    subgroup.Status = CatalogLayoutEditStatus.Deleted;
    if (cross[subgroup.ID]) {
      cross[subgroup.ID] = [];
      // cross[subgroup.ID].forEach(c => c.Status = CatalogLayoutEditStatus.Deleted);
    }
  }

  getUniqueID(groups: CatalogLayoutMaingroup[]): number {
    let id = -10;
    if (groups) {
      groups.forEach(maingroup => {
        if (maingroup.ID <= id) { id = maingroup.ID - 1; }
        if (maingroup.Subgroups && maingroup.Subgroups.length > 0) {
          maingroup.Subgroups.forEach(subgroup => {
            if (subgroup.ID <= id) { id = subgroup.ID - 1; }
          });
        }
      });
    }
    return id;
  }

  addCategory(groups: CatalogLayoutMaingroup[]
    , cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }
    , category: CatalogLayoutSubgroupCategoryCrossLink) {
    let maingroup = this.getMaingroupByDescription(groups, category.MainCategoryDescription);
    if (!maingroup) { maingroup = this.addMaingroup(groups, category.MainCategoryDescription); }
    let subgroup = this.getSubgroupByDescription(maingroup, category.CategoryDescription);
    if (!subgroup) { subgroup = this.addSubgroup(groups, maingroup, category.CategoryDescription); }
    if (!cross[subgroup.ID]) {
      cross[subgroup.ID] = [];
    }
    if (!cross[subgroup.ID].some(c => c.Origin === category.Origin && c.CategoryId === category.CategoryId)) {
      cross[subgroup.ID].push(category);
    }
  }

  deleteCategoryFromSubgroupIds(cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }
    , category: CatalogLayoutSubgroupCategoryCrossLink, subgroupIDs: string[]) {
    if (cross && category) {
      subgroupIDs.forEach(subgroup => {
        if (cross[subgroup]) {
          cross[subgroup] = cross[subgroup].filter((c: CatalogLayoutSubgroupCategoryCrossLink) => !this.areCategoriesEqual(category, c));
        }
      });
    }
  }

  isFilteredLine(activeFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } }
    , partsDescription: CatalogLayoutPartsDescription): boolean {
    if (activeFilter
      && partsDescription
      && activeFilter[partsDescription.Origin]
      && activeFilter[partsDescription.Origin][partsDescription.Brand]
      && activeFilter[partsDescription.Origin][partsDescription.Brand][partsDescription.Description]) {
      return true;
    }
    return false;
  }

  hasFilteredLines(activeFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } }
    , partsDescriptions: CatalogLayoutPartsDescription[]): boolean {
    let hasFilter = false;
    if (activeFilter && partsDescriptions) {
      partsDescriptions.forEach(pd => {
        if (this.isFilteredLine(activeFilter, pd)) { hasFilter = true; }
      });
    }
    return hasFilter;
  }

  countInactiveLines(activeFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } }
    , partsDescriptions: CatalogLayoutPartsDescription[]): number {
    let count = 0;
    if (activeFilter && partsDescriptions) {
      partsDescriptions.forEach(pd => {
        if (this.isFilteredLine(activeFilter, pd)) { count++; }
      });
    }
    return count;
  }

  allLinesInactive(activeFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } }
    , partsDescriptions: CatalogLayoutPartsDescription[]): boolean {
    if (activeFilter && partsDescriptions) {
      return partsDescriptions.length === this.countInactiveLines(activeFilter, partsDescriptions);
    }
    return false;
  }

  getLinesTooltipText(
    activeFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } },
    catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo },
    partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } },
    category: CatalogLayoutSubgroupCategoryCrossLink) {
    const catalogName = this.getCatalogInfo(catalogKindInfo, category.Origin).CatalogKindString;
    const descriptions = this.getPartsDescriptionsForCategory(partsDescriptions, category);
    const alles = this.allLinesInactive(activeFilter, descriptions);
    const catDesc = category.CategoryDescription;
    if (alles) { return `Er is geen enkele lijn in ${catalogName} ${catDesc} actief.`; }
    return `Niet alle lijnen in ${catalogName} ${catDesc} zijn actief.`;
  }

}
