import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { MainService } from '../../../_services/main.service';
import { Order } from '../../../_models/orders/order';
import { SortService } from '../../../_services/sort.service';
import { OrderLine } from '../../../_models/orders/order.line';
import { OrderLineType } from '../../../_models/orders/order.line.type';

@Component({
  selector: 'app-open-orders-detail-webshop',
  templateUrl: './open-orders-detail-webshop.component.html',
  styleUrls: ['./open-orders-detail-webshop.component.scss']
})
export class OpenOrdersDetailWebshopComponent implements OnInit {
  lineType = OrderLineType;
  orderId: number;
  order: Order;
  isBusy = false;

  constructor(
    private route: ActivatedRoute,
    public customerInformationService: CustomerInformationService,
    public mainService: MainService,
    public sortService: SortService
  ) { }

  ngOnInit() {
    this.sortService.initSortField('open-orders-headers-details', 'LineNumber', false);
    this.route.params.subscribe(params => {
      if (params && params.id) {
        this.orderId = params.id;
        this.isBusy = true;
        this.customerInformationService.getOrder(this.orderId).subscribe(order => {
          this.isBusy = false;
          this.order = order;
          console.info(order);
        }, error => {
          this.isBusy = false;
          if (error.status === 401) { this.customerInformationService.gotoOpenOrders(); }
          console.error(error);
        });
      }
    });
  }

  getLine(item: any): OrderLine {
    return item;
  }

  getSortedItems(): OrderLine[] {
    if (this.order) { return this.sortService.sortObject('open-orders-headers-details', this.order.OrderLines); }
    return [];
  }

}
