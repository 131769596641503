<div class="lp">
  <div class="lp-start"><img src="/assets/img/identity/star/home/lp-start.png" /></div>
  <input type="text" appLicensePlate [appFocus]="focusLicensePlate" class="license-plate-input"
  [ngModel]="licensePlate" (ngModelChange)="LicensePlateChange.emit($event)" (click)="licensePlate = ''"
    (keyup.enter)="searchLicensePlate(licensePlate)" />
  <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-mmt" (click)="searchBrandModelType()"
    tooltip="Klik hier om via merk, model, type te kiezen." placement="right" container="body">
    <i class="fas fa-car"></i>
  </div>
  <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-vin" (click)="searchVin()"
    tooltip="Klik hier om via het chassisnummer te zoeken." placement="right" container="body">
    <i class="fas fa-search"></i>
  </div>
  <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-history" (click)="searchHistory()"
    tooltip="Klik hier om een eerder gekozen auto te kiezen." placement="right" container="body">
    <i class="fas fa-history"></i>
  </div>
  <div class="lp-end"><img src="/assets/img/identity/star/home/lp-end.png" /></div>
</div>