<div class="card border-primary my-3 me-3 sidecard">
  <div class="card-header  text-bg-primary">
    <span>Artikelen</span>
  </div>
  <div *ngIf="!cartService.isCartEmpty()" class="card-body m-0 p-0">
    <table class="table table-condensed table-fix-striped">
      <tbody>
        <tr *ngFor="let sci of cartService.getCartItems()">
          <td>
            <strong>{{sci.Artikelnr}}</strong><br /> {{sci.Omschrijving | lowercase}}
          </td>
          <td><span class="badge text-bg-dark">{{sci.Aantal}}</span></td>
        </tr>
      </tbody>
    </table>    
  </div>
  <div *ngIf="cartService.isCartEmpty()" id="cart-info" class="alert alert-info mb-0" role="alert">
    <span (click)="cartService.updateCart(true)"><i class="fas fa-info-circle"></i></span> Op dit moment is uw winkelwagen nog
    leeg.
  </div>
  <div class="card-footer">
    <span>Totaal</span>
  </div>
</div>
