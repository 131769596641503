<app-admin-custom-control [label]="label" [centerLabel]="centerLabel" [labelCols]="labelCols"
  [dividerKind]="dividerKind" [disabled]="disabled">
  <div class="input-group">
    <input type="text" [id]="'input'+uniqueId" [appFocus]="focusInput" class="form-control" [disabled]="disabled"
      [(ngModel)]="model" (ngModelChange)="changeModel($event)" (click)="clickModel($event)"
      [placeholder]="placeholder?placeholder:''" bsDatepicker
      [bsConfig]="{ withTimepicker: withTimepicker, dateInputFormat: 'DD-MM-YYYY'+(withTimepicker?' HH:mm':''), containerClass: 'theme-default' }"
      autocomplete="off">
    <span class="input-group-text" id="basic-addon">
      <i class="far fa-calendar-alt"></i>
    </span>
  </div>
</app-admin-custom-control>