import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AanbiedingArtikel } from '../../../../_models/promotions/aanbieding.artikel';
import { MainService } from '../../../../_services/main.service';
import { TypeaheadItem } from '../../../../_models/typeahead/typeahead.item';

@Component({
  selector: 'app-admin-promotion-edit-item',
  templateUrl: './admin-promotion-edit-item.component.html',
  styleUrls: ['./admin-promotion-edit-item.component.scss']
})
export class AdminPromotionEditItemComponent implements OnInit {
  _item: AanbiedingArtikel;
  _taItem: TypeaheadItem;
  _aantal: number;
  get item(): AanbiedingArtikel {
    this._item.InternArtikelnr = this._taItem.InternalItemNumber;
    this._item.Artikelnr = this._taItem.ItemNumber;
    this._item.Artikelgroep = this._taItem.ItemGroup;
    this._item.Omschrijving = this._taItem.Description;
    this._item.Aantal = this._aantal;
    return this._item;
  }
  set item(value: AanbiedingArtikel) {
    this._item = value;
    this._taItem = new TypeaheadItem();
    this._taItem.InternalItemNumber = value.InternArtikelnr;
    this._taItem.ItemNumber = value.Artikelnr;
    this._taItem.ItemGroup = value.Artikelgroep;
    this._taItem.Description = value.Omschrijving;
    this._aantal = value.Aantal;
  }
  mainService: MainService;
  onClose: Subject<AanbiedingArtikel> = new Subject();
  focusInput = new EventEmitter<boolean>();

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {

  }

  cancel() {
    this.bsModalRef.hide();
  }

  save() {
    if (this._taItem.InternalItemNumber === 0) {
      this.mainService.msgBox('Let op!', 'Er moet nog een artikel gekozen worden!')
        .subscribe(() => this.focusInput.emit());

    } else {
      this.onClose.next(this.item);
      this.bsModalRef.hide();
    }
  }

}
