import { Component, OnInit, ElementRef, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Aanbieding } from '../../../../_models/promotions/aanbieding';
import { PromotionService } from '../../../../_services/promotion.service';
import { MainService } from '../../../../_services/main.service';
import { Animations } from '../../../../_animations/animations';
import { ResizeInfo } from '../../../../_models/common/resize.info';
import { ContextPromotions } from '../../../../_models/promotions/context.promotions';
import { AanbiedingImage } from '../../../../_models/promotions/aanbieding.image';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-promotion-detail-boxed-layout-component',
  templateUrl: './promotion-detail-boxed-layout-component.component.html',
  styleUrls: ['./promotion-detail-boxed-layout-component.component.scss'],
  animations: [Animations.fadeInOut]
})
export class PromotionDetailBoxedLayoutComponentComponent implements OnInit, OnDestroy {
  ctx: ContextPromotions;
  promotion: Aanbieding;
  thumbnailRowHeight = 150;
  private containerHeader: ElementRef;
  private containerFooter: ElementRef;
  @ViewChild('containerHeader') set contentHeader(content: ElementRef) {
    this.containerHeader = content;
  }
  @ViewChild('containerFooter') set contentFooter(content: ElementRef) {
    this.containerFooter = content;
  }
  private containerHeight = 0;
  private contentHeight = 0;
  resizeInfosSubscription: Subscription;
  images: string[];
  showImageModal = false;


  handleResizeInfo(info: ResizeInfo) {
    if (info && this.containerHeader && this.containerFooter) {
      this.containerHeight = (info.clientHeight - info.headerHeight - 30);
      this.contentHeight = this.containerHeight - this.containerHeader.nativeElement.offsetHeight
        - this.containerFooter.nativeElement.offsetHeight;
    }
  }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'height': this.containerHeight + 'px'
      };
    }
    return null;
  }

  getHeightStyle(part: number) {
    if (this.contentHeight) {
      return {
        'height': this.contentHeight * part + 'px'
      };
    }
    return null;
  }

  getImageRowHeightStyle() {
    if (this.contentHeight) {
      return {
        'height': this.contentHeight - this.thumbnailRowHeight + 'px'
      };
    }
    return null;
  }

  getThumnailRowHeightStyle() {
    return {
      'height': this.thumbnailRowHeight + 'px'
    };
  }

  getHeightStyleByContainer(container) {
    if (container) {
      const computedStyle = getComputedStyle(container);
      const height = container.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));
      return {
        'height': height + 'px'
      };
    }
    return null;
  }

  getImagelUrlByContainerDimensions(container, promotion: Aanbieding, image: AanbiedingImage): string {
    if (container) {
      const computedStyle = getComputedStyle(container);
      const width = container.clientWidth - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
      const height = container.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));
      const dimension = `${width}x${height}`;
      return this.promotionService.getImagelUrl(promotion, image, dimension);
    }
    return null;
  }

  getCenterStyleByContainer(container) {
    if (container) {
      const computedStyle = getComputedStyle(container);
      const width = container.clientWidth - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
      const height = container.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));
      return {
        'left': (width / 2) + 'px',
        'top': (height / 2) + 'px'
      };
    }
    return null;
  }

  constructor(
    private route: ActivatedRoute,
    public promotionService: PromotionService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params && params.id) {
        this.promotionService.getContext()
          .subscribe((ctx) => {
            this.ctx = ctx;
            this.promotionService.getPromotionById(params.id)
              .subscribe((promotion) => {
                this.promotion = promotion;
                if (!this.promotion.Images || this.promotion.Images.length < 2) {
                  this.thumbnailRowHeight = 0;
                }
                this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
                this.mainService.fireReziseEvent();
              });
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  showImage(promotion: Aanbieding, image: AanbiedingImage) {
    if (promotion && image) {
      this.images = [image.AfbeeldingsUrl];
      this.showImageModal = true;
    }
  }

  closeImage() {
    this.showImageModal = false;
  }

}
