<table class="tabStripControl">
  <tr>
      <td #tabScrollButtonLeft (mousedown)="mouseDown('left')" (mouseup)="mouseUp()" *ngIf="isVisible()" class="scrollButtonLeft">
          <i class="fas fa-angle-left"></i>
      </td>
      <td style="border: none;">
          <div id="tabStripContainer" class="tabStripContainer" tab-strip-container [ngStyle]="{'width': width + 'px'}">
              <table *ngFor="let tabstrip of tabStrips" #tabStrip>
                  <tr>
                      <td *ngFor="let tab of tabstrip.Tabs" class="tab" [ngClass]="{'bg-primary text-white': tab.ID === shopService.currentActiveShop, 'inactiveTab': tab.ID !== shopService.currentActiveShop}" (click)="shopService.startShopModule(mainService.ctxMain, tab)">
                          {{tab.Naam}}
                      </td>
                  </tr>
              </table>
          </div>
      </td>
      <td id="tabScrollButtonRight" (mousedown)="mouseDown('right')" (mouseup)="mouseUp()" *ngIf="isVisible()" class="scrollButtonRight">
          <i class="fas fa-angle-right"></i>
      </td>
  </tr>
</table>