import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogLayoutSubgroupCategoryCrossLink } from '../../../../../_models/admin/settings/catalog.layout.subgroup.category.cross.link';
import { MainService } from '../../../../../_services/main.service';
import { CatalogLayoutPartsDescription } from '../../../../../_models/admin/settings/catalog.layout.parts.description';
import { CatalogLayoutFilterSettings } from '../../../../../_models/admin/settings/catalog.layout.filter.settings';
import { CatalogLayoutService } from '../../../../../_services/catalog-layout.service';
import { SettingsCatalogLayoutCatalogKindInfo } from '../../../../../_models/admin/settings/settings.catalog.layout.catalog.kind.info';

@Component({
  selector: 'app-admin-catalog-layout-category',
  templateUrl: './admin-catalog-layout-category.component.html',
  styleUrls: ['./admin-catalog-layout-category.component.scss']
})
export class AdminCatalogLayoutCategoryComponent implements OnInit {
  @Input() category: CatalogLayoutSubgroupCategoryCrossLink;
  @Input() catalogKindInfo: { [key: string]: SettingsCatalogLayoutCatalogKindInfo };
  @Input() partsDescriptions: { [key: string]: { [key: string]: CatalogLayoutPartsDescription[] } };
  @Input() activeCatalogFilter: { [key: number]: { [key: string]: { [key: string]: boolean } } };
  @Input() badgeClass: string;
  @Input() filter: CatalogLayoutFilterSettings;
  @Output() selected: EventEmitter<CatalogLayoutSubgroupCategoryCrossLink> = new EventEmitter();

  constructor(
    public catalogLayoutService: CatalogLayoutService,
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
