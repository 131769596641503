import { Component, OnInit, Input } from '@angular/core';

import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { CatalogService } from '../../../../_services/catalog.service';
import { MainService } from '../../../../_services/main.service';
import { SelectListItem } from '../../../../_models/common/select.list.item';
import { Part } from '../../../../_models/catalog/part';
import { SettingsService } from '../../../../_services/settings.service';
import { ShopSoort } from '../../../../_models/common/shop.soort';

@Component({
  selector: 'app-catalog-parts-scroll-layout',
  templateUrl: './catalog-parts-scroll-layout.component.html',
  styleUrls: ['./catalog-parts-scroll-layout.component.scss']
})
export class CatalogPartsScrollLayoutComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctx: ContextCatalog;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

}
