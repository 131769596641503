import { Injectable } from '@angular/core';
import { EMPTY, Observable, merge, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { SettingsGeneral } from '../../_models/admin/settings/settings.general';
import { SettingsModules } from '../../_models/admin/settings/settings.modules';
import { SettingsCarType } from '../../_models/admin/settings/settings.car.type';
import { SettingsCatalogLayout } from '../../_models/admin/settings/settings.catalog.layout';
import { SettingsCatalogLines } from '../../_models/admin/settings/settings.catalog.lines';
import { SettingsRimsAndTyres } from '../../_models/admin/settings/settings.rims.and.tyres';
import { SettingsShoppingCart } from '../../_models/admin/settings/settings.shopping.cart';
import { SettingsLoyaltyShop } from '../../_models/admin/settings/settings.loyalty.shop';
import { ApiService } from '../api.service';
import { AdminService } from './admin.service';
import { MainService } from '../main.service';
import { SettingsExceptionsAvailabilityAndPrice } from '../../_models/admin/settings/settings.exceptions.availability.and.price';
import { SettingsCatalogPartsTypes } from '../../_models/admin/settings/settings.catalog.parts.types';
import { SettingsBase } from '../../_models/admin/settings/settings.base';
import { AdminSetting } from '../../_models/admin/settings/admin.setting';
import { UserIdentificationModel } from '../../_models/user.identification.model';
import { SettingModel } from '../../_models/common/setting.model';
import { ShopSoort } from '../../_models/common/shop.soort';
import { SettingsMenu } from '../../_models/admin/settings/settings.menu';
import { SettingsAvailabilitySuppliers } from '../../_models/admin/settings/settings.availability.suppliers';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AdminModalSettingsWrapperComponent } from '../../_common/admin/admin-modal-settings-wrapper/admin-modal-settings-wrapper.component';


@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private adminService: AdminService,
    private modalService: BsModalService
  ) { }

  clear() {

  }

  openAdminModalSettingsComponent(componentName) {
    const initialState = {
      componentName: componentName
    };
    const modalRef = this.modalService.show(AdminModalSettingsWrapperComponent, { initialState, class: 'modal-max' });
  }

  getAdminSettings(settings: SettingsBase): AdminSetting[] {
    if (settings && settings.CommonSettings) {
      return Object.values(settings.CommonSettings)
        .orderBy('Sort');
    }
    return null;
  }

  getAdminSetting(settings: SettingsBase, key: string): AdminSetting {
    if (settings && settings.CommonSettings && settings.CommonSettings[key]) {
      return settings.CommonSettings[key];
    }
    return new AdminSetting();
  }

  getAdminSettingsShopModule(settings: SettingsBase, shop: ShopSoort): AdminSetting[] {
    if (settings && settings.CommonSettings) {
      const adminSettings: AdminSetting[] = [];
      Object.keys(settings.CommonSettings).forEach(key => {
        if (key.startsWith(ShopSoort[shop] + '_')) {
          adminSettings.push(settings.CommonSettings[key]);
        }
      });
      return adminSettings;
    }
    return null;
  }

  adminSettingArrayContainsItem(settings: SettingsBase, key: string, item: any): boolean {
    if (settings && settings.CommonSettings && settings.CommonSettings[key]) {
      let arr = settings.CommonSettings[key].Value;
      if (arr.hasOwnProperty('$values')) { arr = arr.$values; }
      let found = false;
      if (arr?.length > 0) {
        arr.forEach(el => {
          if (el === item) { found = true; }
        });
      }
      return found;
    }
    return false;
  }

  getAdminSettingValue(settings: SettingsBase, key: string): any {
    if (settings && settings.CommonSettings && settings.CommonSettings[key]) {
      if (settings.CommonSettings[key].Value.hasOwnProperty('$values')) { return settings.CommonSettings[key].Value.$values; }
      return settings.CommonSettings[key].Value;
    }
    return null;
  }

  removeAdminSetting(settings: SettingsBase, key: string) {
    if (settings && settings.CommonSettings && settings.CommonSettings[key]) {
      delete settings.CommonSettings[key];
    }
  }

  deleteSettingsForUim(uim: UserIdentificationModel, moreSpecificSettings: { [key: string]: SettingModel[] }): Observable<boolean> {
    const settings = new SettingsBase();
    settings.UID = uim;
    settings.CommonSettings = {};
    Object.keys(moreSpecificSettings).forEach(key => {
      moreSpecificSettings[key].forEach(setting => {
        if (setting.Wholesaler === uim.Wholesaler
          && setting.Branch === uim.Branch
          && setting.Customer === uim.Customer
          && setting.UserID === uim.UserID) {
          settings.CommonSettings[key] = new AdminSetting();
          settings.CommonSettings[key].Key = key;
        }
      });
    });
    return this.apiService.adminDeleteSettingsBase(settings);
  }

  isLeastSpecificSetting(uid: UserIdentificationModel) {
    return uid.Branch === 0 && uid.Customer === 0 && uid.UserID === 0 && uid.Session === 0;
  }

  hasMoreSpecificSettings(moreSpecificSettings: { [key: string]: SettingModel[] }): boolean {
    return (moreSpecificSettings && Object.keys(moreSpecificSettings).length > 0);
  }

  getUidsFromMoreSpecificSettings(moreSpecificSettings: { [key: string]: SettingModel[] }): UserIdentificationModel[] {
    if (this.hasMoreSpecificSettings(moreSpecificSettings)) {

      const uids = Object.keys(moreSpecificSettings).reduce((r, k) => {
        return r.concat(moreSpecificSettings[k]);
      }, [])
        .map((s: SettingModel) => {
          const uid = new UserIdentificationModel();
          uid.Wholesaler = s.Wholesaler;
          uid.Branch = s.Branch;
          uid.Customer = s.Customer;
          uid.UserID = s.UserID;
          uid.Session = s.Session;
          return uid;
        })
        .sort((u1, u2) => {
          if (u1.Wholesaler !== u2.Wholesaler) { return u1.Wholesaler - u2.Wholesaler; }
          if (u1.Branch !== u2.Branch) { return u1.Branch - u2.Branch; }
          if (u1.Customer !== u2.Customer) { return u1.Customer - u2.Customer; }
          if (u1.UserID !== u2.UserID) { return u1.UserID - u2.UserID; }
        });
      const distinctUids = uids.filter((uid, i, arr) => {
        return arr.indexOf(arr.find(u => UserIdentificationModel.areEqual(u, uid))) === i;
      });
      return distinctUids;
    }
    return null;
  }

  getMoreSpecificSettingsForUim(moreSpecificSettings: { [key: string]: SettingModel[] }, uim: UserIdentificationModel): string[] {
    if (this.hasMoreSpecificSettings(moreSpecificSettings)) {
      let settings: string[] = [];
      Object.keys(moreSpecificSettings).forEach(setting => {
        moreSpecificSettings[setting].forEach(s => {
          if (s.Wholesaler === uim.Wholesaler && s.Branch === uim.Branch && s.Customer === uim.Customer && s.UserID === uim.UserID) {
            settings.push(setting);
          }
        });
      });
      return settings;
    }
    return null;
  }

  getSettingsForRoute(path: string, uid: UserIdentificationModel): Observable<SettingsBase> {
    switch (path) {
      case 'superadmin/catalogpartstypes':
        return this.apiService.adminGetSettingsCatalogPartsTypes(uid);
      case 'superadmin/license-plate-kinds':
        return this.apiService.adminGetSettingsLicensePlateKinds(uid);
      case 'superadmin/wholesaler-overview':
        return this.apiService.adminGetSettingsWholesalerOverview(uid);
      case 'settings/general':
        return this.apiService.adminGetSettingsGeneral(uid);
      case 'settings/layout':
        return this.apiService.adminGetSettingsLayout(uid);
      case 'settings/layout/navigation':
        return this.apiService.adminGetSettingsNavigation(uid);
      case 'settings/modules':
        return this.getSettingsModules(uid);
      case 'settings/menu':
        return this.getSettingsMenu(uid);
      case 'settings/availability-and-price':
        return this.apiService.adminGetSettingsAvailabilityTemplates(uid);
      case 'settings/availability-and-price/availability-suppliers':
        return this.apiService.adminGetSettingsAvailabilitySuppliers(uid);
      case 'adminSettingsExtraMessagesComponent':
      case 'settings/availability-and-price/extra-messages':
        return this.apiService.adminGetSettingsExtraMessages(uid);
      case 'adminSettingsCommonSettingsGenericComponent_BranchTime':
      case 'settings/availability-and-price/template-part/branch-time':
        return this.apiService.adminGetSettingsTemplatePart(uid, 'BranchTime');
      case 'adminSettingsCommonSettingsGenericComponent_DeliveryTime':
      case 'settings/availability-and-price/template-part/delivery-time':
        return this.apiService.adminGetSettingsTemplatePart(uid, 'DeliveryTime');
      case 'adminSettingsCommonSettingsGenericComponent_ItemCounts':
      case 'settings/availability-and-price/template-part/item-counts':
        return this.apiService.adminGetSettingsTemplatePart(uid, 'ItemCounts');
      case 'adminSettingsCommonSettingsGenericComponent_VolumePricing':
      case 'settings/availability-and-price/template-part/volume-pricing':
        return this.apiService.adminGetSettingsTemplatePart(uid, 'VolumePricing');
      case 'settings/availability-and-price/exceptions':
        return this.apiService.adminGetSettingsExceptionsAvailabilityAndPrice(uid);
      case 'settings/bright':
        return this.apiService.adminGetSettingsBright(uid);
      case 'settings/vrooam':
        return this.apiService.adminGetSettingsVrooam(uid);
      case 'settings/aldoc':
        return this.apiService.adminGetSettingsAldoc(uid);
      case 'settings/car-type':
        return this.apiService.adminGetSettingsCarType(uid);
      case 'settings/catalog':
        return this.apiService.adminGetSettingsCatalog(uid);
      case 'settings/catalog/alcar':
        return this.apiService.adminGetSettingsCatalogAlcar(uid);
      case 'settings/catalog/aldoc':
        return this.apiService.adminGetSettingsCatalogAldoc(uid);
      case 'settings/catalog/hbase':
        return this.apiService.adminGetSettingsCatalogHbase(uid);
      case 'settings/catalog/intertyre':
        return this.apiService.adminGetSettingsCatalogIntertyre(uid);
      case 'settings/catalog/mpm':
        return this.apiService.adminGetSettingsCatalogMpm(uid);
      case 'settings/catalog/sinatec':
        return this.apiService.adminGetSettingsCatalogSinatec(uid);
      case 'settings/catalog/stahlie':
        return this.apiService.adminGetSettingsCatalogStahlie(uid);
      case 'settings/catalog/tecdoc':
        return this.apiService.adminGetSettingsCatalogTecdoc(uid);
      case 'settings/catalog/setup':
        return this.apiService.adminGetSettingsCatalogSetup(uid);
      case 'settings/catalog/layout':
        const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'De groepenindeling wordt opgehaald');
        return this.apiService.adminGetSettingsCatalogLayout(uid)
          .pipe(tap(settings => cb.complete()));
      case 'settings/catalog/lines':
        return this.apiService.adminGetSettingsCatalogLines(uid);
      case 'settings/tyres':
        return this.apiService.adminGetSettingsTyres(uid);
      case 'settings/rims-and-tyres':
        return this.apiService.adminGetSettingsRimsAndTyres(uid);
      case 'settings/licenseplates':
        return this.apiService.adminGetSettingsLicensePlates(uid);
      case 'settings/paint':
        return this.apiService.adminGetSettingsPaint(uid);
      case 'settings/customer-information':
        return this.apiService.adminGetSettingsCustomerInformation(uid);
      case 'settings/cart':
        return this.apiService.adminGetSettingsShoppingCart(uid);
      case 'promotions/settings':
        return this.apiService.adminGetSettingsPromotions(uid);
      case 'loyaltyshop/settings':
      case 'loyaltyshop/admin':
      case 'loyaltyshop/summary':
        return this.apiService.adminGetSettingsLoyaltyShop(uid);
    }
    console.info(`No settings for "${path}" found!`);
    return EMPTY;
  }

  saveSettingsCatalogPartsTypes(settings: SettingsCatalogPartsTypes): Observable<boolean> {
    return this.apiService.adminSaveSettingsCatalogPartsTypes(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsGeneral(settings: SettingsGeneral): Observable<boolean> {
    return this.apiService.adminSaveSettingsGeneral(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  getSettingsModules(uid: UserIdentificationModel): Observable<SettingsModules> {
    return this.apiService.adminGetSettingsModules(uid);
  }

  saveSettingsModules(settings: SettingsModules): Observable<boolean> {
    return this.apiService.adminSaveSettingsModules(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  getSettingsMenu(uid: UserIdentificationModel): Observable<SettingsMenu> {
    return this.apiService.adminGetSettingsMenu(uid);
  }

  saveSettingsMenu(settings: SettingsMenu): Observable<boolean> {
    return this.apiService.adminSaveSettingsMenu(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsExtraMessages(settings: SettingsBase): Observable<boolean> {
    return this.apiService.adminSaveSettingsExtraMessages(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsAvailabilitySuppliers(settings: SettingsAvailabilitySuppliers): Observable<boolean> {
    return this.apiService.adminSaveSettingsAvailabilitySuppliers(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsExceptionsAvailabilityAndPrice(settings: SettingsExceptionsAvailabilityAndPrice): Observable<boolean> {
    return this.apiService.adminSaveSettingsExceptionsAvailabilityAndPrice(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsCarType(settings: SettingsCarType): Observable<boolean> {
    return this.apiService.adminSaveSettingsCarType(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsCatalogLayout(settings: SettingsCatalogLayout): Observable<boolean> {
    return this.apiService.adminSaveSettingsCatalogLayout(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsCatalogLines(settings: SettingsCatalogLines): Observable<boolean> {
    return this.apiService.adminSaveSettingsCatalogLines(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsRimsAndTyres(settings: SettingsRimsAndTyres): Observable<boolean> {
    return this.apiService.adminSaveSettingsRimsAndTyres(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsCart(settings: SettingsShoppingCart): Observable<boolean> {
    return this.apiService.adminSaveSettingsShoppingCart(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsBase(settings: SettingsBase): Observable<boolean> {
    return this.apiService.adminSaveSettingsBase(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

  saveSettingsLoyaltyShop(settings: SettingsLoyaltyShop): Observable<boolean> {
    return this.apiService.adminSaveSettingsLoyaltyShop(settings)
      .pipe(mergeMap(ok => of(this.adminService.handleResult(ok, !settings['modal'] ? ['/admin'] : null))));
  }

}
