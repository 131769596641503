import { Component, OnInit, Input } from '@angular/core';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { CatalogPropertyService } from '../../../_services/catalog-property.service';
import { PropertyKindCatalog } from '../../../_models/catalog/property.kind.catalog';
import { MainService } from '../../../_services/main.service';
import { CarTypeService } from '../../../_services/car-type.service';

@Component({
  selector: 'app-car-type-info-block',
  templateUrl: './car-type-info-block.component.html',
  styleUrls: ['./car-type-info-block.component.scss']
})
export class CarTypeInfoBlockComponent implements OnInit {
@Input() carType: UniversalCarType;
@Input() noLicensePlate: boolean;
exceptPropertyKinds: PropertyKindCatalog[]
    = [PropertyKindCatalog.Color,
    PropertyKindCatalog.EngineCode,
    PropertyKindCatalog.BuildYear,
    PropertyKindCatalog.MassOfEmptyVehicle,
    PropertyKindCatalog.FirstAdmission,
    PropertyKindCatalog.RegistrationDate,
    PropertyKindCatalog.ApkExpiration,
    PropertyKindCatalog.RegistrationBrand,
    PropertyKindCatalog.RegistrationModel,
    PropertyKindCatalog.RegistrationVersion,
    PropertyKindCatalog.RegistrationFuel,
    PropertyKindCatalog.RegistrationDescription,
    PropertyKindCatalog.RegistrationEngineCode,
    PropertyKindCatalog.RegistrationEngineCapacity,
    PropertyKindCatalog.RegistrationPower
  ];

  constructor(
    public mainService: MainService,
    public catalogPropertyService: CatalogPropertyService,
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
  }

}
