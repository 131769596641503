import { Pipe, PipeTransform } from '@angular/core';
import { CatalogLayoutPartsDescription } from '../../../../../_models/admin/settings/catalog.layout.parts.description';
import { CatalogLayoutFilterSettings } from '../../../../../_models/admin/settings/catalog.layout.filter.settings';
import { CatalogLayoutService } from '../../../../../_services/catalog-layout.service';
import { SettingsCatalogLayout } from '../../../../../_models/admin/settings/settings.catalog.layout';
import { CatalogLayoutSubgroupCategoryCrossLink } from '../../../../../_models/admin/settings/catalog.layout.subgroup.category.cross.link';

@Pipe({
  name: 'adminCatalogLayoutPartsDescriptionsSort'
})
export class AdminCatalogLayoutPartsDescriptionsSortPipe implements PipeTransform {

  getScore(pd: CatalogLayoutPartsDescription, sortMode: number, filterDescription: string, filterBrand: string): number {
    let score = 0;
    if (filterDescription && pd.Description.toLowerCase().includes(filterDescription.toLowerCase())) {
      score += sortMode === 0 ? 2 : 1;
    }
    if (filterBrand && pd.Brand.toLowerCase().includes(filterBrand.toLowerCase())) {
      score += sortMode === 1 ? 2 : 1;
    }
    return score;
  }

  transform(partsDescriptions: CatalogLayoutPartsDescription[], sortMode: number, filterDescription: string, filterBrand: string): any {
    if (partsDescriptions) {
      let fields = ['Description', 'Brand'];
      if (sortMode === 1) { fields = ['Brand', 'Description']; }
      return partsDescriptions.sort((pd1, pd2) => {
        if (this.getScore(pd1, sortMode, filterDescription, filterBrand)
          < this.getScore(pd2, sortMode, filterDescription, filterBrand)) { return 1; }
        if (this.getScore(pd1, sortMode, filterDescription, filterBrand)
          > this.getScore(pd2, sortMode, filterDescription, filterBrand)) { return -1; }
        if (pd1[fields[0]] > pd2[fields[0]]) { return 1; }
        if (pd1[fields[0]] < pd2[fields[0]]) { return -1; }
        if (pd1[fields[1]] > pd2[fields[1]]) { return 1; }
        if (pd1[fields[1]] < pd2[fields[1]]) { return -1; }
        return 0;
      });
    }
    return partsDescriptions;
  }


}
