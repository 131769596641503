<span (click)="selected.emit(category)" class="badge category-badge noselect pointer {{badgeClass}}"
  [ngClass]="{'filtered': !catalogLayoutService.filterHitCategory(category,filter,partsDescriptions)}">
  <span>{{catalogLayoutService.getCatalogInfo(catalogKindInfo, category.Origin).CatalogKindString}}
    {{category.MainCategoryDescription}}</span><br />
  <span *ngIf="catalogLayoutService.isFilterEmpty(filter)">{{category.CategoryDescription}}</span>
  <span
    *ngIf="!catalogLayoutService.isFilterEmpty(filter)">{{mainService.getStringSection(0,category.CategoryDescription,filter.filterString)}}<span
      class="text-danger">{{mainService.getStringSection(1,category.CategoryDescription,filter.filterString)}}</span>{{mainService.getStringSection(2,category.CategoryDescription,filter.filterString)}}</span>
  <span
    *ngIf="catalogLayoutService.hasFilteredLines(activeCatalogFilter, catalogLayoutService.getPartsDescriptionsForCategory(partsDescriptions, category))"
    [ngClass]="{'text-danger': catalogLayoutService.allLinesInactive(activeCatalogFilter, catalogLayoutService.getPartsDescriptionsForCategory(partsDescriptions, category))}"
    class="ps-1">
    <span [tooltip]="catalogLayoutService.getLinesTooltipText(activeCatalogFilter, catalogKindInfo, partsDescriptions, category)" placement="left" container="body">
      <i class="fad fa-exclamation-triangle"></i>
    </span>
  </span>
</span>