import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { MainService } from '../_services/main.service';
import { ShopPortalLogon } from '../_models/logon/shop.portal.logon';


@Injectable()
export class AuthGuardAdmin  {

  constructor(
    private mainService: MainService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const logon = JSON.parse(localStorage.getItem('currentLogon')) as ShopPortalLogon;
    if (logon && logon.Role > 0) {
      return of(true);
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }
}
