<header class="navbar sticky-top bg-dark flex-lg-nowrap p-0 shadow header" data-bs-theme="dark">
  <div class="text-center text-white brand-name pointer">
    <span [routerLink]="['/admin']">ShopPortal<br />Admin Dashboard</span>
  </div>

  <ul class="navbar-nav flex-row d-lg-none">
    <li class="nav-item text-nowrap">
      <button class="nav-link px-3 text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSearch"
        aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle search">
        <i class="fas fa-search"></i>
      </button>
    </li>
    <li class="nav-item text-nowrap">
      <button class="nav-link px-3 text-white" type="button" title="Uitloggen" (click)="authService.gotoLogout()"
        (contextmenu)="mainService.showDebugInfo($event, mainService.ctxMain.LoginTiming, ctx)"><i
          class="fas fa-sign-out"></i></button>
    </li>
    <li class="nav-item text-nowrap">
      <button class="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
    </li>
  </ul>

  <div id="navbarSearch" class="navbar-search ms-auto collapse">
    <input class="form-control rounded-0 border-0 input-search" type="text" [(ngModel)]="search"
    (ngModelChange)="searchChanged(search)" placeholder="Zoeken..." aria-label="Search">
  </div>
  <div class="navbar-logout collapse">
    <button class="nav-link px-3 text-white" type="button" title="Uitloggen" (click)="authService.gotoLogout()"
        (contextmenu)="mainService.showDebugInfo($event, mainService.ctxMain.LoginTiming, ctx)"><i
          class="fas fa-sign-out"></i></button>
  </div>
</header>
<div *ngIf="ctx" class="main text-bg-light">
  <div class="sidebar text-bg-dark">
    <div class="offcanvas-lg offcanvas-end" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
      <div class="offcanvas-header">
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
          aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-bg-dark d-lg-flex flex-column p-0 pt-xl-3 overflow-y-auto">
        <div class="user-info noselect m-3">
          <div><b>{{ctx.CompanyName}}</b></div>
          <div>{{ctx.Name}}</div>
          <div class="text-secondary mb-3">{{ctx.Function}}</div>
          @if (adminService.superAdmin) {
          <button type="button" class="btn btn-warning" (click)="authService.chooseWholesaler($event, ctx.Wholesalers, wholesalerChange, false)">{{adminService.currentWholesalerName}}</button>
          }
          <app-helper-display-breakpoint></app-helper-display-breakpoint>
        </div>
        <ul *ngIf="ctx" class="nav d-block">
          <app-admin-menu-item *ngFor="let menuItem of ctx.Menu" [menuItem]="menuItem" [isMainMenu]="true"
            [active]="activeMenuId==menuItem.ID" [search]="searchRegex" (clickMenu)="clickMenu(menuItem)">
          </app-admin-menu-item>
        </ul>
      </div>
    </div>
  </div>

  <!-- <main role="main" class="content" [@routerTransition]="prepareRoute(outlet)"> -->
  <main role="main" class="content">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
  <div class="footer text-end text-bg-white border-top">
    <span class="copyright pe-3">Copyright <b>Autotaal Automatisering B.V. © 2015-{{year}}</b></span>
  </div>
</div>