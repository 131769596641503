import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UniversalCarType } from '../../_models/car-types/universal.car.type';
import { CarTypeService } from '../../_services/car-type.service';
import { Animations } from '../../_animations/animations';
import { MainService } from '../../_services/main.service';
import { ContextBase } from '../../_models/common/context.base';
import { ApiService } from '../../_services/api.service';
import { Settings } from '../../_models/common/settings';
import { CatalogPropertyService } from '../../_services/catalog-property.service';
import { PropertyKindCatalog } from '../../_models/catalog/property.kind.catalog';

@Component({
  selector: 'app-car-type-info-header',
  templateUrl: './car-type-info-header.component.html',
  styleUrls: ['./car-type-info-header.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CarTypeInfoHeaderComponent implements OnInit {
  _ctx: ContextBase;
  get ctx(): ContextBase { return this._ctx; }
  @Input('ctx')
  set ctx(value: any) {
    this._ctx = value;
    this.collapsed = value.CarTypeExtraDataCollapsed;
  }
  @Input() carType: UniversalCarType;
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  collapsed = true;

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService,
    private apiService: ApiService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
  }

  getCarType(): UniversalCarType {
    if (this.carType) { return this.carType; }
    if (this._ctx && this._ctx.CarType) { return this._ctx.CarType; }
    return null;
  }

  hasLogo(): boolean {
    if (this.getCarType() && this.getCarType().BrandLogo) {
      return true;
    }
    return false;
  }


  doCollapse() {
    if (this._ctx) {
      this._ctx.CarTypeExtraDataCollapsed = !this._ctx.CarTypeExtraDataCollapsed;
      this.collapsed = this._ctx.CarTypeExtraDataCollapsed;
      this.apiService.saveSettings(new Settings('CarTypeExtraDataCollapsed', this._ctx.CarTypeExtraDataCollapsed.toString()));
    } else {
      this.collapsed = !this.collapsed;
    }
    this.collapsedChange.emit(this.collapsed);
  }


}
