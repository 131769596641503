<div class="container-fluid">
  <div class="row g-0">
    <div class="col-sm-auto">
      <img *ngIf="mainService.isMenuActiveAndHidden()" class="logo" tooltip="'Klik hier om het menu aan of uit te zetten.'"
        placement="right" container="body" src="/assets/img/identity/doetam/hbmenu_50_jaar.png" (dblclick)="authService.gotoLogout()"
        (click)="mainService.toggleMenuFloating(null)" />
      <a target="_blank" href="https://www.doetam.nl"><img class="logo" [src]="ctx.Logo"
          tooltip="'Klik hier om terug te gaan naar Doetam.nl'" placement="right" container="body" (dblclick)="authService.gotoLogout()" /></a>
    </div>
    <div class="col">
      <div class="text-box" [innerHTML]="settingsService.getSetting('TekstNaastLogoInHeader') | safeHtml"></div>
      <div class="login-info float-end">
        <app-login-info [ctx]="ctx"></app-login-info>
      </div>
    </div>
  </div>
  <div class="row g-0">
    <div class="col-md-auto" [@slideInVertical]="mainService.isMenuActiveAndHidden()">
      <div [ngStyle]="getDummyStyle()"></div>
    </div>
    <div class="col-md-auto">
      <div class="license-plate m-1">
        <input type="text" [appAutofocus]="true" appLicensePlate class="license-plate-input" [ngModel]="licensePlate" (ngModelChange)="licensePlate = $event;"
          (click)="licensePlate = ''" (keyup.enter)="searchLicensePlate(licensePlate)" />
        <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon" (click)="searchBrandModelType()" tooltip="Klik hier om via merk, model, type te kiezen."
          placement="right" container="body">
          <i class="fas fa-car"></i>
        </div>
        <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon" (click)="searchVin()" tooltip="Klik hier om via het chassisnummer te zoeken."
          placement="right" container="body">
          <i class="fas fa-search"></i>
        </div>
        <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon" (click)="searchHistory()" tooltip="Klik hier om een eerder gekozen auto te kiezen."
          placement="right" container="body">
          <i class="fas fa-history"></i>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <div *ngIf="shopService.containsShopModule(ctx, shopSoort.GarageInformatieSysteem)"  class="item-info m-1">
        <div class="input-group input-group-lg">
          <input type="text" [(ngModel)]="item" class="form-control item-info-input" placeholder="Zoek op artikel..."
            (keyup.enter)="router.navigate(['/iteminfo', item])">
          <button class="btn btn-primary large-button" type="button" [routerLink]="['/iteminfo',item]">
            <i class="fas fa-search fa-2x"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="float-end">
        <div class="cart-container">
          <img *ngIf="cartService.isCartEmpty()" src="/assets/img/icons/cart-leeg.png" [routerLink]="['/cart']" />
          <img *ngIf="!cartService.isCartEmpty()" src="/assets/img/icons/cart-vol-badge.png" [routerLink]="['/cart']" />
          <span *ngIf="!cartService.isCartEmpty()" class="cart-item-count text-white">{{cartService.getCartItemCount()}}</span>
          <div class="settings-container text-primary" *ngIf="true">
            <span [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fas fa-cog"></i></span>
            <br />
            <span (click)="cartService.doCartSlide(ctx)">
              <span *ngIf="!ctx.ShowCartContent"><i class="fas fa-arrow-alt-circle-left"></i></span>
              <span *ngIf="ctx.ShowCartContent"><i class="fas fa-arrow-alt-circle-right"></i></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
