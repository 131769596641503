<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="returnItem" [autoStart]="true">
      <li appBreadcrumbHeaderItem [routerLink]="['/details/returns']">Retouren</li>
      <li appBreadcrumbHeaderItem [routerLink]="['/details/returns-request']">Nieuw</li>
      <li appBreadcrumbHeaderItem [active]="true">Artikel</li>
    </app-breadcrumb-header>

    <div class="card border mb-3">
      <div class="card-header card-header-compact  text-bg-dark">Artikel toevoegen</div>
      <div class="card-body p-2">
        <div class="form-group row">
          <label class="col-4 col-form-label">Artikel zoeken</label>
          <div class="col-8">
            <app-item-typeahead-search [labelCols]="0" [dividerKind]="1" [(start)]="currentItemValue"
              (selected)="typeaheadItemSelected($event)" #itemSearch></app-item-typeahead-search>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-4"></div>
          <div class="col-8">
            <div *ngIf="currentItem">
              {{currentItem.ItemNumber}}<br />
              {{currentItem.Description}}<br />
              {{currentItem.ItemGroup}}
            </div>
            <div *ngIf="!currentItem">
              -<br />
              -<br />
              -<br />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label">Aantal</label>
          <div class="col-8">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="currentAmount" maxlength="2" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label">Pakbon</label>
          <div class="col-8">
            <div *ngIf="!hasDeliveryNoteItems()">Geen pakbonnen gevonden.</div>
            <div *ngIf="hasDeliveryNoteItems()">

              <div class="btn-group-vertical btn-group-toggle w-100 mb-3" data-bs-toggle="buttons">
                <label *ngFor="let item of currentDeliveryNoteItems" class="btn btn-outline-dark"
                  [ngClass]="{'active':currentDeliveryNoteNumber === item.DeliveryNoteNumber}"
                  (click)="currentDeliveryNoteNumber = item.DeliveryNoteNumber; currentDeliveryNoteItem = item;">
                  <input type="radio" name="options" id="option1" autocomplete="off">Pakbon
                  {{item.DeliveryNoteNumber}}<span>
                    ({{item.Date | date : 'dd-MM-yyyy'}}, {{item.ItemCount}} artikelen)</span>
                </label>

              </div>
            </div>
          </div>

        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label">Reden</label>
          <div class="col-8">
            <select class="form-control form-control-sm" [(ngModel)]="currentReason" [compareWith]="compareReason">
              <option *ngFor="let item of customerInformationService.getReturnReasons(); let i = index" [ngValue]="item">
                {{item.Description}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row" *ngIf="isReasonAnders()">
          <label class="col-4 col-form-label">Omschrijving</label>
          <div class="col-8">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="currentDescription" maxlength="64" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <button type="button" class="btn btn-sm btn-primary" (click)="addCurrentItem()"><i class="fas fa-plus"></i>
              Toevoegen</button>
          </div>
        </div>



      </div>
    </div>

  </div>
</div>