<div *ngIf="carTypeResult && carTypeResult.History">
  <div class="row justify-content-end my-2">
    <div class="col-4" (contextmenu)="mainService.showDebugInfo($event, carTypeResult.Timing, carTypeResult)">
      <div class="input-group float-end">
        <input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="filterString"
          placeholder="filter op type">
        <button class="btn btn-light" type="button" (click)="filterString = ''">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" [ngStyle]="{'height': (mainService.resizeInfo.clientHeight - 190) + 'px', 'overflow': 'auto'}">
      <table class="table table-fix-striped table-fix-hover table-sm pointer">
        <tr *ngFor="let car of carTypeResult.History | carTypeFilter: filterString:true" (click)="carTypeSelected(car)">
          <td>{{car.Model.Brand.BrandName}}</td>
          <td>{{car.Model.ModelName}}</td>
          <td>{{car.TypeName}}</td>
          <td>{{car.TypeRemark}}</td>
          <td class="text-nowrap">{{car.AvailableFrom.Display}}</td>
          <td class="text-nowrap">{{car.AvailableUntil.Display}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<!-- <div *ngIf="carTypeResult && carTypeResult.CarType" class="row justify-content-center mt-3">
  <div class="col-5 alert alert-warning mt-3" role="alert">
    <i class="fas fa-2x fa-sync-alt fa-spin m-3"></i>
    <span class="label">Zoeken naar auto's waar dit onderdeel op past.</span>
  </div>
</div> -->