<nav class="navbar navbar-expand-md navbar-light pb-0">
  <div class="container-fluid">
    <a class="navbar-brand">
      <img class="logo" src="/assets/img/identity/star/logo.png" (dblclick)="authService.gotoLogout()"
        (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarText"
      aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item active">
          <span class="nav-link" [routerLink]="['/start']">
            <i class="fas fa-home icon text-primary"></i>
          </span>
        </li>
        <li class="nav-item">
          <span class="nav-link" [routerLink]="['/tyres']">Banden</span>
        </li>
        <li class="nav-item">
          <span class="nav-link" [routerLink]="['/details']">Bestelhistorie</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">Voertuighistorie</span>
        </li>
        <li class="nav-item">
          <span class="nav-link" [routerLink]="['/licenseplates']">Kentekenplaten</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">
            <span class="icon pointer" [routerLink]="['/settings']"
              (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fal fa-cog"></i></span>
          </span>
        </li>
        <li class="nav-item">
          <span class="nav-link" [routerLink]="['/cart']">
            <span class="cart-container">
              <i class="fal fa-shopping-cart cart-icon"></i>
              <span *ngIf="!cartService.isCartEmpty()"
                class="badge text-bg-danger cart-badge">{{cartService.getCartItemCount()}}</span>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="!shopService.getTabGroupTabs(ctx);else tab_strip" class="mx-3 seperator"></div>

<ng-template #tab_strip>
  <app-tab-strip [ctx]="ctx"></app-tab-strip>
</ng-template>