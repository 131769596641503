import { Component, Input, OnInit } from '@angular/core';
import { LayoutKind } from '../../_models/common/layout.kind';
import { MainService } from '../../_services/main.service';
import { SettingsService } from '../../_services/settings.service';
import { ShopSoort } from '../../_models/common/shop.soort';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  @Input() renderCheck: any = true;
  @Input() shopSoort: ShopSoort = ShopSoort.ONBEKEND;
  scrollLayout: boolean;

  constructor(
    public settingsService: SettingsService,
    public mainService: MainService) {
  }

  ngOnInit(): void {
    this.scrollLayout = this.mainService.getShopPortalLayoutKind(this.shopSoort) === LayoutKind.ScrollLayout;
  }

}
