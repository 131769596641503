<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="orders" [autoStart]="true">
      <li appBreadcrumbHeaderItem [routerLink]="['/details/open-orders']">Openstaande Orders</li>
    </app-breadcrumb-header>

    <div *ngIf="!customerInformationService.showPasswordPrompt">
      <app-grid-webshop [items]="sortService.sortObject('open-orders-headers', orders)"
        emptyText="Geen openstaande orders gevonden." class="mt-3 mb-3"
        (rowClick)="customerInformationService.gotoOpenOrdersDetail($event)" [isBusy]="isBusy"
        [enableRowSelection]="true">
        <ng-container grid-header>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'open-orders-headers'" [sortField]="'OrderNumber'">Ordernummer
            </app-sort-field>
          </div>
          <div class="col-3">
            <app-sort-field [sortObjectId]="'open-orders-headers'" [sortField]="'OrderDate'">Orderdatum</app-sort-field>
          </div>
          <div class="col-7">
            <app-sort-field [sortObjectId]="'open-orders-headers'" [sortField]="'CustomerReference'">Referentie
            </app-sort-field>
          </div>
        </ng-container>
        <ng-template let-item>
          <div class="col-2">{{item.OrderNumber | leadZeros:8}}</div>
          <div class="col-3">{{item.OrderDate | date : 'dd-MM-yyyy'}}</div>
          <div class="col-7">{{item.CustomerReference}}</div>
        </ng-template>
      </app-grid-webshop>
    </div>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="getOrders()"></app-extra-password-webshop>
    </div>


  </div>
</div>