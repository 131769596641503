<app-admin-checkbox *ngIf="adminService.superAdmin" [label]="'Verborgen'" [(model)]="module.Hide" [labelCols]="3"></app-admin-checkbox>
<app-admin-checkbox [label]="'Actief'" [(model)]="module.Active" [labelCols]="3"></app-admin-checkbox>
<app-admin-checkbox [label]="'Start module'" (modelChanged)="startUpChanged(module)" [(model)]="module.IsStartupShop" [labelCols]="3"></app-admin-checkbox>
<app-admin-input [label]="'Naam'" [(model)]="module.Naam" [labelCols]="3"></app-admin-input>
<app-admin-editor [label]="'Omschrijving'" [(model)]="module.Omschrijving" [labelCols]="3"></app-admin-editor>
<app-admin-checkbox *ngIf="moduleDefaults?.VoorraadOptie" [label]="'Voorraad optie'" [(model)]="module.VoorraadOptie" [labelCols]="3"></app-admin-checkbox>
<app-admin-checkbox *ngIf="moduleDefaults?.NettoprijsOptie" [label]="'Nettoprijs optie'" [(model)]="module.NettoprijsOptie" [labelCols]="3"></app-admin-checkbox>
<div *ngIf="settings">
  <ng-container *ngFor="let setting of settings" [ngSwitch]="setting.Kind">
    <app-admin-checkbox *ngSwitchCase="adminSettingKind.Checkbox" [label]="setting.Description" [(model)]="setting.Value" [labelCols]="3"></app-admin-checkbox>
    <app-admin-input *ngSwitchDefault [label]="setting.Description" [(model)]="setting.Value" [labelCols]="3"></app-admin-input>
  </ng-container>  
</div>
