<ng-template #dropdownTemplate let-model='model'>
  <span>{{model?.value}}</span>
</ng-template>

<ng-template #dropdownSuperAdminTemplate let-model='model'>
  <span class="me-3">{{model?.value}}</span><span class="text-low float-end"
    (contextmenu)="mainService.saveToClipBoard(model?.copy)">{{model?.extra ? model.extra : model?.key}}</span>
</ng-template>

<div (dblclick)="showModels=!showModels">
  <app-admin-dropdown *ngIf="adminService.superAdmin" [label]="getLabel('Grossier',modelWholesaler)"
    [labelCols]="labelCols" [(model)]="modelWholesaler" [modelValues]="wholesalers" [modelValueProperty]="'key'"
    [buttonTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    [itemTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    (modelChange)="wholesalerChange($event)" [dividerKind]="1">
  </app-admin-dropdown>
  <app-admin-dropdown *ngIf="!oneBranch" [label]="getLabel('Filiaal',modelBranch)" [labelCols]="labelCols"
    [(model)]="modelBranch" [modelValues]="branches" [modelValueProperty]="'key'"
    [buttonTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    [itemTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    (modelChange)="branchChange($event)" [disabled]="modelWholesaler===0" [dividerKind]="1">
  </app-admin-dropdown>
  <app-admin-custom-control *ngIf="!searchingCustomer" [label]="getLabel('Klant', modelCustomer)"
    [labelCols]="labelCols" [dividerKind]="1">
    <button type="button" class="btn btn-white border-low text-start" [disabled]="modelWholesaler===0"
      (click)="enableCustomerSearch()">
      <span *ngIf="!customer">Alle klanten</span>
      <span *ngIf="customer"><b>{{customer.Name}}</b> <span
          class="ms-3 text-low">({{customer.CustomerNumber}})</span><br />{{customer.City}}</span>
    </button>
  </app-admin-custom-control>
  <app-admin-typeahead-customer-search *ngIf="searchingCustomer" [label]="getLabel('Klant',modelCustomer)"
    [(start)]="inputCustomer" [labelCols]="labelCols" [wholesaler]="modelWholesaler" [disabled]="modelWholesaler===0"
    (selected)="customerChange($event)" [focusInput]="focusInputCustomer"
    [placeholder]="'zoek een klant of laat leeg voor alle klanten'" (blur)="customerBlur()" [dividerKind]="1">
    <button class="btn btn-outline-secondary" type="button" (click)="resetCustomerSearch()"><i
        class="fas fa-times"></i></button>
  </app-admin-typeahead-customer-search>
  <app-admin-dropdown [label]="getLabel('Gebruiker',modelUserID)" [labelCols]="labelCols" [(model)]="modelUserID"
    [modelValues]="users" [modelValueProperty]="'key'"
    [buttonTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    [itemTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
    (modelChange)="userChange($event)" [disabled]="modelCustomer===0" [dividerKind]="dividerKind"></app-admin-dropdown>
</div>