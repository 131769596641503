import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { Return } from '../../../_models/returns/return';
import { ReturnDetail } from '../../../_models/returns/return.detail';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-product-returns-detail',
  templateUrl: './product-returns-detail.component.html',
  styleUrls: ['./product-returns-detail.component.scss']
})
export class ProductReturnsDetailComponent implements OnInit {

  returnId: number;
  returnItem: Return;
  isBusy: boolean;

  constructor(
    private route: ActivatedRoute,
    public customerInformationService: CustomerInformationService,
    public mainService: MainService,
    public sortService: SortService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.sortService.initSortField('returns-details', 'LineNumber', false);
    this.route.params.subscribe(params => {
      if (params && params.id) {
        this.returnId = params.id;
        this.isBusy = true;
        this.customerInformationService.getReturn(this.returnId).subscribe(returnItem => {
          this.isBusy = false;
          this.returnItem = returnItem;
          console.info(returnItem);
        }, error => {
          this.isBusy = false;
          if (error.status === 401) { this.customerInformationService.gotoReturns(); }
          console.error(error);
        });
      }
    });
  }

  getSortedItems(): ReturnDetail[] {
    if (this.returnItem) { return this.sortService.sortObject('returns-details', this.returnItem.Details); }
    return [];
  }

  hasDeliveryNote(): boolean {
    return this.returnItem && this.returnItem.Header.Status === 2 && this.returnItem.Header.DeliveryNoteNumber > 0;
  }

  showPdf() {
    const url = this.mainService.getBackendApi();
    window.open(`${url}/pdf/delivery-note/${this.authService.currentLogon.Session}_${this.returnItem.Header.DeliveryNoteNumber}.pdf`);
  }

}
