import { Injectable } from '@angular/core';
import { SortObject } from '../_models/common/sort-object';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  private sortObjects: { [key: string]: SortObject } = {};

  constructor() { }

  getSortedItems(id: string, items: any[]): any[] {
    const direction = this.sortObjects[id].currentOrderIsDescending ? -1 : 1;
    const field = this.sortObjects[id].currentSortField;
    if (field) {
      return items.slice().sort(function (item1, item2) {
        if (item1[field] > item2[field]) { return 1 * direction; }
        if (item1[field] < item2[field]) { return -1 * direction; }
        if (item1[field] === item2[field]) { return 0; }
      });
    }
    return items;
  }

  sortObject(id: string, items: any[]) {
    // console.info(`sortObject id = ${id}`);
    // console.info(`sortObject item count = ${items ? items.length : 0}`);
    if (items) {
      if (!this.sortObjects[id]) { this.sortObjects[id] = new SortObject(); }
      // console.info(`sortObject currentSortField = ${this.sortObjects[id].currentSortField}`);
      return this.getSortedItems(id, items);
    }
    return items;
  }

  getCurrentSortIconClass(id: string, field: string): string {
    if (this.sortObjects[id] && this.sortObjects[id].currentSortField === field) {
      if (this.sortObjects[id].currentOrderIsDescending) {
        return 'ASC';
      } else {
        return 'DESC';
      }
    }
    return null;
  }

  setCurrentSortField(id: string, field: string): void {
    if (this.sortObjects[id]) {
      if (this.sortObjects[id].currentSortField === field) {
        this.sortObjects[id].currentOrderIsDescending = !this.sortObjects[id].currentOrderIsDescending;
      } else {
        this.sortObjects[id].currentSortField = field;
        this.sortObjects[id].currentOrderIsDescending = false;
      }
    }
  }

  initSortField(id: string, field: string, isDescending: boolean): void {
    if (!this.sortObjects[id]) { this.sortObjects[id] = new SortObject(); }
    this.sortObjects[id].currentSortField = field;
    this.sortObjects[id].currentOrderIsDescending = isDescending;
  }

  getPropertyValue(object: any, property: string): any {
    if (!object) { return null; }
    const arr = property.split('.');
    while (arr.length && (object = object[arr.shift()])) { }
    return object;
  }

  getItemValue(object: any, property: string, alternateDataKey: string, alternateData: any): any {
    let value = this.getPropertyValue(object, property);
    if (typeof(value) === 'undefined' && alternateDataKey && alternateData) { value = this.getPropertyValue(alternateData[object[alternateDataKey]], property); }
    if (value && value.valueOf) { value = value.valueOf(); }
    return value;
  }

  getEquality(item1: any, item2: any, fields: string[], alternateDataKey: string, alternateData: any): number {
    if (fields && fields.length) {
      let direction = 1;
      let field = fields[0];
      if (field.startsWith('-')) {
        direction = -1;
        field = field.substring(1);
      }
      const value1 = this.getItemValue(item1, field, alternateDataKey, alternateData);
      const value2 = this.getItemValue(item2, field, alternateDataKey, alternateData);
      // 2021-10-06 Maurice: Gebruik == ipv === zodat er ook gelijk op undefined gechecked wordt.
      if (value1 == null && value2 != null) { return 1; }
      if (value1 != null && value2 == null) { return -1; }
      if (value1 > value2) { return 1 * direction; }
      if (value1 < value2) { return -1 * direction; }
      if (value1 == value2 && fields.length > 1) {
        fields.shift();
        return this.getEquality(item1, item2, fields, alternateDataKey, alternateData);
      }
    }
    return 0;
  }
}
