<div class="container-fluid">
  <div class="row g-0">
    <div class="col-sm-4">
      <img *ngIf="ctx.Logo" [src]="'/assets/' + ctx.Logo" (dblclick)="authService.gotoLogout()" />
    </div>
    <div class="col-sm-4">
      <!-- <span *ngIf="!mainService.isProduction && mainService.resizeInfo">{{mainService.resizeInfo.usableWidth}} * {{mainService.resizeInfo.usableWidthString}}</span> -->
      <a *ngIf="ctx.ExtraBannerImage" [href]="ctx.ExtraBannerUrl" target="_blank">
        <img [src]="ctx.ExtraBannerImage" />
      </a>
    </div>
    <div class="col-sm-2">
      <div class="float-end" id="puntenContainer" *ngIf="cartService.isLoyaltyPointsActive()">
        <span id="puntenLabel">{{cartService.getLoyaltyPointsName()}}</span>
        <br />
        <span id="puntenCounter">{{mainService.padLeft(cartService.getLoyaltyPoints().toString(),7)}}</span>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="float-end">
        <div id="cartContainer" [routerLink]="['/cart']">
          <img *ngIf="cartService.isCartEmpty()" src="/assets/img/icons/cart-leeg.png" />
          <img *ngIf="!cartService.isCartEmpty()" src="/assets/img/icons/cart-vol-badge.png" />
          <div *ngIf="!cartService.isCartEmpty()">{{cartService.getCartItemCount()}}</div>
        </div>
        <div id="settingsContainer" *ngIf="true">
          <span [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)"><i class="fas fa-cog"></i></span>
          <br />
          <span (click)="cartService.doCartSlide(ctx)">
            <div class="collapse-button" [ngClass]="{'is-collapsed': ctx.ShowCartContent}"><i class="fas fa-arrow-alt-circle-left"></i></div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-legacy-tab-strip *ngIf="!mainService.isMenuActive()" [ctx]="ctx"></app-legacy-tab-strip>
