import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';

@Component({
  selector: 'app-side-menu-doetam',
  templateUrl: './side-menu-doetam.component.html',
  styleUrls: ['./side-menu-doetam.component.scss']
})
export class SideMenuDoetamComponent implements OnInit {
  @Input() ctx: ContextMain;

  constructor() { }

  ngOnInit() {
  }

}
