import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsBase } from '../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { MainService } from '../../../_services/main.service';
import { SettingsMenu } from '../../../_models/admin/settings/settings.menu';
import { ShopModuleMenuItem } from '../../../_models/common/shop.module.menu.item';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-admin-settings-menu',
  templateUrl: './admin-settings-menu.component.html',
  styleUrls: ['./admin-settings-menu.component.scss']
})
export class AdminSettingsMenuComponent implements OnInit {
  settings: SettingsMenu;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getDepth(menu: ShopModuleMenuItem[]): number {
    return 1 + Math.max(0, ...menu?.map(({ MenuItems = [] }) => this.getDepth(MenuItems)));
  }

  reIndex(menu: ShopModuleMenuItem[], parentId: number = 0) {
    const depth = this.getDepth(menu) - 1;
    const delta = Math.pow(10, ((depth - 1) * 2));
    console.info(`reIndex depth: ${depth} delta: ${delta}`);
    for (let index = 0; index < menu.length; index++) {
      const item = menu[index];
      item.Id = ((index + 1) * delta) + parentId;
      item.ParentId = parentId;
      if (item.MenuItems?.length) this.reIndex(item.MenuItems, item.Id);
    }
  }


}
