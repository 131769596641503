import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InterfaceData } from '../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../../_services/admin/admin-supplier-interface.service';
import { SupplierBranchData } from '../../../../_models/admin/settings/supplier.branch.data';
import { MainService } from '../../../../_services/main.service';
import { BranchModel } from '../../../../_models/common/branch.model';

@Component({
  selector: 'app-easycloud-stockinfo-supplier-interface-settings',
  templateUrl: './easycloud-stockinfo-supplier-interface-settings.component.html',
  styleUrls: ['./easycloud-stockinfo-supplier-interface-settings.component.scss']
})
export class EasycloudStockinfoSupplierInterfaceSettingsComponent implements OnInit {
  @Input() interfaceDataSuppliers: { [key: number]: InterfaceData[] };
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Output() interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();
  branches: BranchModel[];
  stockInfoSuppliersLink: { [key: string]: SupplierBranchData };
  
  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    private mainService: MainService
  ) { }

  ngOnInit(): void {
    this.branches = Object.values(this.supplierInterfaceData.Branches).filter(b => b.ShowStock);
    this.stockInfoSuppliersLink = this.getStockInfoSuppliersLinks();
  }

  getStockInfoSuppliersLinks(): { [key: string]: SupplierBranchData } {
    var result: { [key: string]: SupplierBranchData } = {};
    Object.keys(this.supplierInterfaceData.EasyCloudStockInfoSupplierKinds).forEach(supplierKind => {
      const supplierKindString = this.supplierInterfaceData.EasyCloudStockInfoSupplierKinds[supplierKind];
      Object.keys(this.interfaceDataSuppliers).forEach(supplierNumber => {
        this.interfaceDataSuppliers[supplierNumber].forEach(interfaceData => {
          if (supplierKind === interfaceData.SupplierCode) {
            if (!result[supplierKindString]) {
              result[supplierKindString] = new SupplierBranchData();
              result[supplierKindString].Supplier = +supplierNumber;
              result[supplierKindString].Branches = [];
            }
            result[supplierKindString].Branches.push(interfaceData.Branch)
          }
        });
      });
    });
    return result;
  }

  getConfiguredSuppliers(): string[] {
    if (this.stockInfoSuppliersLink) {
      return Object.keys(this.stockInfoSuppliersLink);
    }
    return [];
  }

  addLink() {
    let suppliers = Object.values(this.supplierInterfaceData.EasyCloudStockInfoSupplierKinds);
    if (this.stockInfoSuppliersLink) { suppliers = suppliers.filter(s => !Object.keys(this.stockInfoSuppliersLink).includes(s)); }
    this.mainService.selectionBox('Kies een beschikbaarheid koppeling', suppliers)
      .subscribe(link => {
        if (link) {
          if (!this.stockInfoSuppliersLink) this.stockInfoSuppliersLink = {};
          this.stockInfoSuppliersLink[link] = new SupplierBranchData();
        }
      });
  }

  changeLink(key: string, supplier: number) {
    this.stockInfoSuppliersLink[key] = new SupplierBranchData;
    this.stockInfoSuppliersLink[key].Supplier = supplier;
  }

  removeLink(key: string) {
    delete this.stockInfoSuppliersLink[key];
  }

}

