import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ShopModule } from '../../../../_models/common/shop.module';
import { MainService } from '../../../../_services/main.service';
import { AdminService } from '../../../../_services/admin/admin.service';
import { AdminSetting } from '../../../../_models/admin/settings/admin.setting';
import { AdminSettingKind } from '../../../../_models/admin/settings/admin.setting.kind';

@Component({
  selector: 'app-admin-shop-module-settings',
  templateUrl: './admin-shop-module-settings.component.html',
  styleUrls: ['./admin-shop-module-settings.component.scss']
})
export class AdminShopModuleSettingsComponent implements OnInit {
  adminSettingKind = AdminSettingKind;
  @Input() module: ShopModule;
  @Input() moduleDefaults: ShopModule;
  @Input() settings: AdminSetting[];
  @Output() startUpChange: EventEmitter<ShopModule> = new EventEmitter<ShopModule>();

  constructor(
    private mainService: MainService,
    public adminService: AdminService
  ) { }

  ngOnInit(): void {
  }

  startUpChanged(module: ShopModule): void {
    this.startUpChange.emit(module);
  }

}
