<div class="modal-body px-0">
  <div *ngIf="parts && parts.length > 0" class="container-fluid" [ngStyle]="{'height': (mainService.resizeInfo.clientHeight - 90) + 'px', 'overflow': 'auto'}">
    <div class="row">
      <div *ngIf="parts.length !== 1" class="col-3">
        <div class="list-group">
          <a *ngFor="let part of parts" class="list-group-item list-group-item-action p-1 pointer" [ngClass]="{'active text-white': part === currentPart}"
            (click)="choosePart(part)">
            {{part.Brand}} <app-part-item-number-link [partItemNumber]="part.PartItemNumber" [shopKind]="ctx.ShopKind"></app-part-item-number-link>
          </a>
        </div>
      </div>
      <div [ngClass]="{'col-9': parts.length !== 1, 'col-12': parts.length === 1}">
        <div class="row">
          <div class="col-11">
            <ul class="nav nav-tabs nav-justified text-center">
              <li class="nav-item pointer" (click)="infoKind = 0"><a class="nav-link" [ngClass]="{'active text-primary': infoKind === 0}">Details</a></li>
              <li *ngIf="currentPart.SupportsGetCarTypesByPart" class="nav-item pointer" (click)="infoKind = 1"><a class="nav-link" [ngClass]="{'active text-primary': infoKind === 1}">Typenvergelijk</a></li>
            </ul>
          </div>
          <div class="col-1">
            <span class="float-end" (click)="bsModalRef.hide()" (contextmenu)="mainService.showObject($event, ctx)"><i class="fas fa-times fa-2x pointer"></i></span>
          </div>
        </div>
        <div *ngIf="ctx && currentPart" class="row">
          <div *ngIf="infoKind === 0" class="col">
            <app-catalog-part-info [ctx]="ctx" [part]="currentPart"></app-catalog-part-info>
          </div>
          <div *ngIf="infoKind === 1" class="col">
            <app-catalog-part-application [ctx]="ctx" [carTypeResult]="carTypeResultApplication" [part]="currentPart" (carTypeChosen)="bsModalRef.hide()"></app-catalog-part-application>
          </div>
          <div *ngIf="infoKind === 2" class="col">
            <app-catalog-part-special-tools [ctx]="ctx" [part]="currentPart"></app-catalog-part-special-tools>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
