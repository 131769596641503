import { Component, OnInit } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';
defineLocale('nl', nlLocale);

import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }
}
