import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { Credentials } from '../../_models/logon/credentials';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-choose-cookie',
  templateUrl: './choose-cookie.component.html',
  styleUrls: ['./choose-cookie.component.scss']
})
export class ChooseCookieComponent implements OnInit {
  wholesaler: number;
  authService: AuthService;
  onClose: Subject<Credentials>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public choose(credentials: Credentials) {
    if (credentials) {
      this.onClose.next(credentials);
    }
    this.bsModalRef.hide();
  }

  public remove(credentials: Credentials) {
    if (credentials) {
      this.authService.multiLogonData = this.authService.multiLogonData.filter(
        cred => !(cred.Username === credentials.Username && cred.Password === credentials.Password
        ));
      this.authService.saveCookieList(this.wholesaler, this.authService.multiLogonData);
      if (this.authService.loginData &&
          this.authService.loginData.Username === credentials.Username &&
          this.authService.loginData.Password === credentials.Password) {
        this.authService.loginData = new Credentials('', '', false);
        this.authService.removeCookieSingle(this.wholesaler);
      }
    }
    this.bsModalRef.hide();
  }

}
