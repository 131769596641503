import { Component, OnInit } from '@angular/core';
import { SettingsCatalogPartsTypes } from '../../../_models/admin/settings/settings.catalog.parts.types';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { ActivatedRoute } from '@angular/router';
import { PartsType } from '../../../_models/catalog/parts.type';
import { AdminCatalogService } from '../../../_services/admin/admin-catalog.service';

@Component({
  selector: 'app-admin-catalog-parts-types',
  templateUrl: './admin-catalog-parts-types.component.html',
  styleUrls: ['./admin-catalog-parts-types.component.scss']
})
export class AdminCatalogPartsTypesComponent implements OnInit {
  settings: SettingsCatalogPartsTypes;
  filterString: string;
  hideCrossed = true;
  maxItems = 10;
  _maxItemCount = 10;

  constructor(
    public adminSettingsService: AdminSettingsService,
    public adminCatalogService: AdminCatalogService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getCatalogs(): string[] {
    return Object.keys(this.settings.LearnedPartsTypes);
  }

  getPartsTypesCount(): number {
    return Object.keys(this.settings.PartsTypes['Universal']).length;
  }

  getPartsTypes(): PartsType[] {
    return Object.keys(this.settings.PartsTypes['Universal'])
      .map(id => this.settings.PartsTypes['Universal'][id]);
  }


}
