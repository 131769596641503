<ng-container *ngIf="adminCatalogService.getCatalogPartsTypeIdsFromCross(settings.PartsTypeCross, catalog, partsType.ID)">
  <div *ngFor="let catalogPartsTypeId of adminCatalogService.getCatalogPartsTypeIdsFromCross(settings.PartsTypeCross, catalog, partsType.ID)" class="my-1">
    <app-admin-parts-type-input [partsType]="partsType" [catalog]="catalog" [catalogPartsTypeId]="catalogPartsTypeId" (addCross)="addCross($event)"
      [settings]="settings"></app-admin-parts-type-input>
  </div>
</ng-container>
<app-admin-parts-type-input
  *ngIf="!adminCatalogService.getCatalogPartsTypeIdsFromCross(settings.PartsTypeCross, catalog, partsType.ID) || extraCross"
  [focusInput]="addCrossFocus" [partsType]="partsType" [catalog]="catalog" [catalogPartsTypeId]="0" (crossed)="extraCross=0" [settings]="settings">
</app-admin-parts-type-input>
