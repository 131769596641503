import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SupplierModel } from '../../../_models/common/supplier.model';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-supplier-input',
  templateUrl: './admin-supplier-input.component.html',
  styleUrls: ['./admin-supplier-input.component.scss']
})
export class AdminSupplierInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelObject: SupplierModel;
  @Output() modelObjectChange: EventEmitter<SupplierModel> = new EventEmitter<SupplierModel>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() stopSearchingOnBlur: boolean;
  @Input() noneSelectedText: string;
  searching: boolean;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (this.noneSelectionLabel && !this.noneSelectionModelValue) { this.noneSelectionModelValue = 0; }
    if (this.noneSelectionLabel && this.stopSearchingOnBlur === undefined) { this.stopSearchingOnBlur = true; }
    if (this.noneSelectionLabel) { this.searching = this.model !== this.noneSelectionModelValue; }
    if (this.model !== this.noneSelectionModelValue) {
      this.getObjectModel(this.model);
    }
    if (this.model === 0 && !this.noneSelectionLabel) { this.doSearch(); }
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModel(model: number) {
    if (model) {
      this.adminService.getSupplier(this.adminService.currentUid.Wholesaler, model)
        .subscribe(result => {
          if (result) {
            if (this.model !== result.SupplierNumber) {
              this.model = result.SupplierNumber;
              this.modelChange.emit(this.model);
            }
            if (!this.modelObject || this.modelObject.SupplierNumber !== result.SupplierNumber) {
              this.modelObject = result;
              this.modelObjectChange.emit(this.modelObject);
            }
          }
          this.searching = false;
        });
    }
  }

  objectChange(value: SupplierModel) {
    if (value.SupplierNumber !== this.model) { this.getObjectModel(value.SupplierNumber); }
  }

  doSearch() {
    this.searching = true;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  clear(event: MouseEvent) {
    if (!this.disabled) {
      this.model = this.noneSelectionModelValue;
      this.modelObject = null;
      this.modelChange.emit(this.model);
      this.modelObjectChange.emit(this.modelObject);
    }
    event.stopPropagation();
  }

  typeaheadBlur(event) {
    if (this.stopSearchingOnBlur) {
      setTimeout(() => this.searching = false, 50);
    }
  }

}
