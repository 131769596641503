import { Component, OnInit } from '@angular/core';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { ActivatedRoute } from '@angular/router';
import { CatalogService } from '../../../_services/catalog.service';
import { MainService } from '../../../_services/main.service';
import { Part } from '../../../_models/catalog/part';
import { MaintenancePartsSelection } from '../../../_models/maintenance/maintenance.parts.selection';

@Component({
  selector: 'app-maintenance-parts',
  templateUrl: './maintenance-parts.component.html',
  styleUrls: ['./maintenance-parts.component.scss']
})
export class MaintenancePartsComponent implements OnInit {
  ctx: ContextCatalog;
  selection: MaintenancePartsSelection;

  constructor(
    private route: ActivatedRoute,
    public mainService: MainService,
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.resolverData.ctx;
        this.selection = data.resolverData.selection;
      });
  }

}
