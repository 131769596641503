import { SelectionItemPropertyKind } from './selection.item.property.kind';

export class SelectionItem {
    InternArtikelnr: number;
    Artikelnr: string;
    Artikelgroep: number;
    Omschrijving: string;
    Aantal: number;
    PrijsPerStuk: number;
    Properties: { [key: string]: any };

    constructor() {
        this.InternArtikelnr = 0;
        this.Artikelnr = '';
        this.Artikelgroep = 0;
        this.Omschrijving = '';
        this.Aantal = 0;
        this.PrijsPerStuk = 0;
        this.Properties = {
            'DisplayChoice': 0,
            'IsSelected': true,
            'OnlyTPMS': false,
            'SelectionKind': 0,
            'SortOrder': 0
        };
    }
}
