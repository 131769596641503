import { Component, Input, OnInit } from '@angular/core';
import { ItemSortOption } from '../../../_models/common/item.sort.option';
import { SortService } from '../../../_services/sort.service';

@Component({
  selector: 'app-debug-sort-options',
  templateUrl: './debug-sort-options.component.html',
  styleUrls: ['./debug-sort-options.component.scss']
})
export class DebugSortOptionsComponent implements OnInit {
  @Input() item: any;
  @Input() sortOption: ItemSortOption;
  @Input() alternateDataKey: string;
  @Input() alternateData: any;

  constructor(public sortService: SortService) { }

  ngOnInit(): void {
  }

  descending(field: string) : boolean {
    return field.startsWith('-');
  }

  withoutDirection(field: string) : string {
    if (this.descending(field)) return field.substr(1);
    return field;
  }
  
}
