import { SelectListItem } from '../common/select.list.item';

export class RimFilterDescriptions {
  Stock: SelectListItem[];
  WheelKinds: SelectListItem[];
  Options: SelectListItem[];
  Diameters: SelectListItem[];
  Brands: SelectListItem[];
  TyreDimensions: SelectListItem[];
}
