<app-container [renderCheck]="ctx" [shopSoort]="shopSoort.ThirdPartyShopApi">

  <app-breadcrumb-header *ngIf="scrollLayout" [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="ctx.ShopKind"></li>
    <span class="ms-auto">
      <!-- <app-voorraad-prijs-opties [ctx]="session.ctx" (netPriceOptionChanged)="netPriceOptionChanged()"
        [saveSettingsDirect]="thirdpartyShopService.changedOptionsNeedsRefresh(session.shopSoort)">
      </app-voorraad-prijs-opties> -->
    </span>
  </app-breadcrumb-header>

  <app-car-type-info-header *ngIf="ctx && scrollLayout && !thirdpartyShopService.withoutCarType(ctx.ShopKind)"
    [ctx]="ctx"></app-car-type-info-header>

  <div [ngClass]="{'h-100': !scrollLayout}" [style.minHeight.px]="ctx.FixedHeight">
    <iframe #iframe class="frame" [ngClass]="{'fullSize': !scrollLayout}" [style.minHeight.px]="ctx.FixedHeight" [scrolling]="scrollLayout&&ctx.FixedHeight==0?'no':'yes'"
      [src]="ctx.Url | safeUrl" frameBorder="0"></iframe>
  </div>
</app-container>