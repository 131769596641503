import { Component, OnInit, Input } from '@angular/core';
import { RouteInfo } from '../../_models/item-info/route.info';
import { DeliveryModeKind } from '../../_models/cart/delivery.mode.kind';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-route-info',
  templateUrl: './route-info.component.html',
  styleUrls: ['./route-info.component.scss']
})
export class RouteInfoComponent implements OnInit {
  deliveryModeKind = DeliveryModeKind;
  @Input() route: RouteInfo;
  @Input() deliveryMode: DeliveryModeKind;
  @Input() withDescription: boolean;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }

}
