import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';

import { MainService } from '../../_services/main.service';
import { ToasterService } from '../../_services/toaster.service';

@Component({
  selector: 'app-show-object',
  templateUrl: './show-object.component.html',
  styleUrls: ['./show-object.component.scss']
})
export class ShowObjectComponent implements OnInit {
  object: any;
  mainService: MainService;
  collapsed = true;

  constructor(
    public bsModalRef: BsModalRef,
    public clipboard: Clipboard,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
  }

  isString(object: any): boolean {
    return (typeof object === 'string' || object instanceof String);
  }

  copy() {
    if (this.isString(this.object)) {
      this.clipboard.copy(this.object);
      this.toasterService.showToast('Succes', `De tekst is gekopieerd naar het klembord!`);
    } else {
      this.clipboard.copy(JSON.stringify(this.object, null, 2));
      this.toasterService.showToast('Succes', `Het JSON object is gekopieerd naar het klembord!`);
    }
  }

}
