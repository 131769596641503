import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { MainService } from '../_services/main.service';
import { ShopPortalLogon } from '../_models/logon/shop.portal.logon';


@Injectable()
export class AuthGuard  {

    constructor(
        private mainService: MainService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const logon = JSON.parse(localStorage.getItem('currentLogon')) as ShopPortalLogon;
        if (logon && logon.Role === 0 && this.mainService.ctxMain) {
            // return this.mainService.getContextMain(true)
            //     .pipe(mergeMap((ctx) => {
            return of(true);
            //     }));
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return of(false);
    }
}
