import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { PromotionsAdmin } from '../../_models/admin/promotions/promotions.admin';
import { AdminPromotionService } from '../../_services/admin/admin-promotion.service';


@Injectable({
  providedIn: 'root'
})
export class AdminPromotionsResolverService   {

  constructor(
    private adminPromotionService: AdminPromotionService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PromotionsAdmin> | Observable<never> {
    return this.adminPromotionService.getPromotionsAdmin().pipe(
      take(1),
      mergeMap(promotions => {
        if (promotions) {
          return of(promotions);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
