import { Pipe, PipeTransform } from '@angular/core';
import { CarModel } from '../../_models/car-types/car.model';

@Pipe({
  name: 'carModelFilter'
})
export class CarModelFilterPipe implements PipeTransform {

  containsFilter(texts: string[], filter: string) {
    for (const text of texts) {
      if (filter && text.toLowerCase().indexOf(filter) >= 0) { return true; }
    }
    return false;
  }

  transform(models: CarModel[], filterString: string): CarModel[] {
    if (!filterString) { return models; }
    return models.filter(model => {
      let ok = false;
      if (model) {
        if (this.containsFilter([model.ModelName, model.ModelRemark], filterString)) { ok = true; }
      }
      return ok;
    });

  }

}
