<app-container [renderCheck]="ctx" [shopSoort]="shopKind.MPM">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="shopKind.MPM"></li>
  </app-breadcrumb-header>

  <app-car-type-info-header [ctx]="ctx" [carType]="ctx?.CarType"></app-car-type-info-header>

  <app-side-panel-and-content>
    <ng-container side-panel>
      <app-side-select-list>
        <ng-container *ngFor="let mainCat of ctx.Categories">
          <ng-container *ngFor="let category of mainCat.SubPartsCategories">
            <app-side-select-list-item [active]="mainCat==currentMainCat && category==currentCat"
              (click)="chooseCats(mainCat,category)">
              {{mainCat.CategoryName}} {{mainCat.CategoryId}}
              <div *ngIf="!(mainCat.SubPartsCategories.length==1 && category.CategoryName=='Normaal')" class="smaller">{{category.CategoryName}}</div>
            </app-side-select-list-item>
          </ng-container>
        </ng-container>
      </app-side-select-list>

    </ng-container>
    <ng-container content>

      <ul class="nav nav-tabs">
        <li *ngFor="let part of currentCat.Parts" class="nav-item pointer">
          <a class="nav-link" [ngClass]="{'active': part==currentPart}" (click)="currentPart=part">{{getProductkind(part)}}</a>
        </li>
      </ul>

      <div class="tab p-3">
        <app-mpm-part [mainCategory]="currentMainCat" [category]="currentCat" [part]="currentPart" [ctx]="ctx"></app-mpm-part>
      </div>
      

    </ng-container>
  </app-side-panel-and-content>



</app-container>