import { Component, OnInit, Input } from '@angular/core';
import { ShopModuleMenuItem } from '../../../../_models/common/shop.module.menu.item';
import { MainService } from '../../../../_services/main.service';
import { ShopService } from '../../../../_services/shop.service';
import { PromotionService } from '../../../../_services/promotion.service';
import { ShopRouteConfig } from '../../../../_models/common/shop.route.config';
import { ShopSoort } from '../../../../_models/common/shop.soort';
import { Router } from '@angular/router';

@Component({
  selector: 'app-webshop-menu-item',
  templateUrl: './webshop-menu-item.component.html',
  styleUrls: ['./webshop-menu-item.component.scss']
})
export class WebshopMenuItemComponent implements OnInit {
  @Input() menuItem: ShopModuleMenuItem;
  uniqueId = Math.random().toString(36).substring(2);
  
  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    public promotionService: PromotionService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  

}
