import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { ApiService } from '../../../../_services/api.service';
import { MainService } from '../../../../_services/main.service';
import { CarTypeService } from '../../../../_services/car-type.service';
import { UniversalCarType } from '../../../../_models/car-types/universal.car.type';
import { UniversalPartsCategory } from '../../../../_models/catalog/universal.parts.category';
import { PartsCategory } from '../../../../_models/catalog/parts.category';
import { Animations } from '../../../../_animations/animations';
import { Settings } from '../../../../_models/common/settings';
import { ViewKind } from '../../../../_models/common/view.kind';
import { ViewKindInfo } from '../../../../_models/common/view.kind.info';
import { Router } from '@angular/router';
import { CatalogService } from '../../../../_services/catalog.service';
import { SettingsService } from '../../../../_services/settings.service';

@Component({
  selector: 'app-catalog-groups-boxed-layout',
  templateUrl: './catalog-groups-boxed-layout.component.html',
  styleUrls: ['./catalog-groups-boxed-layout.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CatalogGroupsBoxedLayoutComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  viewKinds: ViewKindInfo[] = [
    { kind: ViewKind.Detail, icon: 'fas fa-th-large', info: 'Ruime weergave' },
    { kind: ViewKind.Compact, icon: 'fas fa-th', info: 'Compacte weergave' }
  ];
  filterString: string;
  filterResult = { count: 0 };

  constructor(
    private apiService: ApiService,
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public catalogService: CatalogService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  clickFavouritesCollapsed(ctx): void {
    if (ctx) {
      ctx.FavouritesCollapsed = !ctx.FavouritesCollapsed;
      this.apiService.saveSettings(new Settings('Catalog_FavouritesCollapsed', ctx.FavouritesCollapsed));
    }
  }

  containsFilter(text, filter) {
    return filter && text.toLowerCase().indexOf(filter) >= 0;
  }

  partsCatIsOpen(cat: PartsCategory) {
    return cat.PartsDescriptions.length > 0 && cat['isOpen'];
  }

  partsCatIsClosed(cat: PartsCategory) {
    return cat.PartsDescriptions.length > 0 && !cat['isOpen'];
  }

  partsCatIsEmpty(cat: PartsCategory) {
    return !cat.PartsDescriptions || cat.PartsDescriptions.length === 0;
  }

  clickCategory(event: MouseEvent, active: boolean, carType: UniversalCarType, category: UniversalPartsCategory, description: string) {
    event.stopPropagation();
    if (active) { this.catalogService.clickCategory(event, carType, category, description); }
  }

}
