<div class="row mt-3">

  <div *ngIf="catalogService.hasFilterItems(ctx, 'PartsDescription_', false)" class="col">
    <app-catalog-parts-filter [ctx]="ctx" [keyPart]="'PartsDescription_'" [useRegex]="false" [completeString]="'Alle productsoorten'"></app-catalog-parts-filter>
  </div>

  <div *ngIf="catalogService.hasFilterItems(ctx, 'System_', false)" class="col">
    <app-catalog-parts-filter [ctx]="ctx" [keyPart]="'System_'" [useRegex]="false" [completeString]="'Alle systemen'"></app-catalog-parts-filter>
  </div>

  <div *ngIf="catalogService.hasFilterItems(ctx, 'Brand_', false)" class="col">
    <app-catalog-parts-filter [ctx]="ctx" [keyPart]="'Brand_'" [useRegex]="false" [completeString]="'Alle merken'"></app-catalog-parts-filter>
  </div>

  <div *ngIf="catalogService.hasFilterItems(ctx, 'Property_InstallLocation_', false)" class="col">
    <app-catalog-parts-filter [ctx]="ctx" [keyPart]="'Property_InstallLocation_'" [useRegex]="false" [completeString]="'Alle inbouwplaatsen'"></app-catalog-parts-filter>
  </div>

  <div class="col">
    <div class="row">
      <div class="col-sm-9 p-0">
        <app-sort-options [ctx]="ctx" [btn]="'btn-primary'"></app-sort-options>
      </div>
      <div class="col-sm-3">
        <button *ngIf="ctx.PartsPropertyCollapsedItemCount" class="float-end btn btn-primary pointer" (click)="catalogService.doCollapseAll(ctx)">
          <div class="collapse-button" [ngClass]="{'is-collapsed': !ctx.NotCollapsedByDefault}"><i class="fas fa-angle-up"></i></div>
        </button>
      </div>
    </div>
  </div>
</div>
