export class SelectListItem {
  description: string;
  selected: boolean;
  count: number;
  hidden: boolean;
  value: any;

  constructor(description: string, selected: boolean) {
    this.description = description;
    this.selected = selected;
    this.count = 0;
    this.hidden = false;
  }

  static getSelectListItemWithValue(description: string, value: any, selected: boolean) {
    const item = new SelectListItem(description, false);
    item.value = value;
    item.selected = selected;
    return item;
  }

}
