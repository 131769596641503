import { ShopSoort } from '../common/shop.soort';

export class ItemInfoRequest {
    InternalItemNumber: number;
    ItemNumber: string;
    ItemGroup: number;
    SearchInDescriptions: boolean;
    RequestItemCount: number;
    FromShop: ShopSoort;
    WithoutCartItems: boolean;

    constructor(
        internalItemNumber: number,
        itemNumber: string,
        itemGroup: number,
        searchInDescriptions: boolean,
        requestItemCount: number,
        fromShop: ShopSoort,
        withoutCartItems: boolean
    ) {
        this.InternalItemNumber = internalItemNumber;
        this.ItemNumber = itemNumber;
        this.ItemGroup = itemGroup;
        this.SearchInDescriptions = searchInDescriptions;
        this.RequestItemCount = requestItemCount;
        this.FromShop = fromShop;
        this.WithoutCartItems = withoutCartItems;
    }

    equals(itemInfoRequest: ItemInfoRequest): boolean {
        return (
            this.InternalItemNumber === itemInfoRequest.InternalItemNumber
            && this.ItemNumber === itemInfoRequest.ItemNumber
            && this.ItemGroup === itemInfoRequest.ItemGroup
            && this.SearchInDescriptions === itemInfoRequest.SearchInDescriptions
            && this.RequestItemCount === itemInfoRequest.RequestItemCount
            && this.FromShop === itemInfoRequest.FromShop
        );
    }
}
