import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-admin-number-input',
  templateUrl: './admin-number-input.component.html',
  styleUrls: ['./admin-number-input.component.scss']
})
export class AdminNumberInputComponent implements OnInit {
  @Input() label: string;
  _model: number;
  get model(): number { return +this._displayModel; }
  @Input('model')
  set model(value: number) {
    if (value) {
      this._model = value;
      this._displayModel = this._model.toString();
    }
  }
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<any>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number = 2;
  @Input() dividerKind: number = 0;
  @Input() centerLabel: boolean;
  @Input() busy: boolean;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() decimals: number = 0;
  @Input() allowNegative = false;
  @Input() style: any;
  uniqueId = Math.random().toString(36).substring(2);
  _displayModel: string;

  constructor() { }

  ngOnInit() {
    
  }

  changeModel(event: number): void {
    this.model = event;
    this.modelChange.emit(this.model);
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

}
