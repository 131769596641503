import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SelectListItem } from '../../../../_models/common/select.list.item';
import { Animations } from '../../../../_animations/animations';

@Component({
  selector: 'app-side-panel-accordion-filter',
  templateUrl: './side-panel-accordion-filter.component.html',
  styleUrls: ['./side-panel-accordion-filter.component.scss'],
  animations: [Animations.slideInOut, Animations.slideAndFadeInOut]
})
export class SidePanelAccordionFilterComponent implements OnInit {
  @Input() title: string;
  @Input() maxItems: number = 0;
  _descriptions: SelectListItem[];
  get descriptions(): SelectListItem[] { return this._descriptions; }
  @Input('descriptions')
  set descriptions(value: SelectListItem[]) {
    this._descriptions = value;
    this.setHiddenPropertyOfItems(this.descriptions, this.maxItems, this.showAll);
  }
  @Input() hideEmptyItems: boolean;
  @Input() collapsed: boolean;
  @Input() showAlways: boolean;
  @Output() filterChange: EventEmitter<SelectListItem> = new EventEmitter<SelectListItem>();
  @Input() showAll = false;
  selected: number;
  

  constructor() { }

  ngOnInit() {
  }

  hideWithOnlyCountedItem(): boolean {
    return !this.showAlways && this.descriptions.filter(d => d.count > 0).length === 1;
  }

  change(item: SelectListItem) {
    this.filterChange.emit(item);
  }

  setHiddenPropertyOfItems(items: SelectListItem[], count: number, showAll: boolean) {
    this.selected = this.getSelectedCount(this.descriptions);
    if (!count || showAll) {
      items?.forEach(item => item.hidden = false);
    } else {
      const maxItems = count > this.selected ? count : this.selected;
      const topItems = items?.slice().sort((item1, item2) => {
        if (item1.selected && !item2.selected) { return -1; }
        if (!item1.selected && item2.selected) { return 1; }
        if (item1.count > item2.count) { return -1; }
        if (item1.count < item2.count) { return 1; }
        return 0;
      })?.slice(0, maxItems);

      items?.forEach(item => {
        item.hidden = !topItems.includes(item);
      });
    }
  }

  getSelectedCount(descriptions: SelectListItem[]): number {
    if (descriptions) {
      return descriptions.reduce((count, item) => item.selected ? count + 1 : count, 0);
    }
    return 0;
  }

  collapse(): void {
    this.selected = this.getSelectedCount(this.descriptions);
    this.collapsed = !this.collapsed;
  }

  more() {
    this.showAll = !this.showAll;
    this.setHiddenPropertyOfItems(this.descriptions, this.maxItems, this.showAll);
  }

}
