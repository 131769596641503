export class ShopPortalLogonRequest {
    Wholesaler: number;
    Username: string;
    Password: string;
    FrontendUrl: string;
    BackendUrl: string;
    KeepLogin: boolean;
    Production: boolean;
    Version: string;
    Session: number;

    constructor(
        wholesaler: number,
        username: string,
        password: string,
        frontendUrl: string,
        backendUrl: string,
        keepLogin: boolean,
        production: boolean,
        version: string,
        session: number
    ) {
        this.Wholesaler = wholesaler;
        this.Username = username;
        this.Password = password;
        this.FrontendUrl = frontendUrl;
        this.BackendUrl = backendUrl;
        this.KeepLogin = keepLogin;
        this.Production = production;
        this.Version = version;
        this.Session = session;
    }
}
