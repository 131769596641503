import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

import { MainService } from './_services/main.service';
import { ShopService } from './_services/shop.service';
import { CallbackInfoComponent } from './_common/callback-info/callback-info.component';
import { AuthService } from './_services/auth.service';
import { VersionCheckService } from './_services/version-check.service';
import { environment } from '../environments/environment';
import { SettingsService } from './_services/settings.service';
import { AdminService } from './_services/admin/admin.service';
import { HotkeyService } from './_services/hotkey.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('window:unload', ['$event'])
  doSomething($event) {
    this.authService.doLogout().subscribe();
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.hotkeyService.handleHotkeyEvent(event);    
  }
  @ViewChild(CallbackInfoComponent) set callBackInfo(callBackInfo: CallbackInfoComponent) {
    this.mainService.registerCallBackInfo(callBackInfo);
  }

  constructor(
    private versionCheckService: VersionCheckService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private mainService: MainService,
    private shopService: ShopService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private adminService: AdminService,
    private hotkeyService: HotkeyService
  ) {

  }


  ngOnInit() {
    if (environment.production) {
      this.versionCheckService.initVersionCheck(`${document.location.origin}/version.json`);
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .subscribe((route) => {
        // 20190204 Marc: null checks voor de path
        // 20191217 Maurice: null checks voor de path weg anders gaat Title zetten vanuit route.data['value']['title'] niet
        if (route && route.snapshot && route.snapshot.routeConfig) {
          if (this.adminService.currentUid.Wholesaler) {
            console.info('ShopPortal Admin Dashboard...');
            this.titleService.setTitle('SPAD');
          } else {
            let title = this.settingsService.getSetting('ShopPortalBrowserTitle') || '';
            const extraTitle = this.getExtraTitle(route.snapshot);
            const includeModule = this.settingsService.getSettingBoolean('ShopPortalBrowserTitleIncludingCurrentModule');
            const shopSoort = this.shopService.getShopSoortFromRoute(route.snapshot);
            const shop = this.shopService.getShopModuleByShopSoort(this.mainService.ctxMain, shopSoort);
            if (shop && includeModule) {
              title = `${title} ${shop.Naam} ${extraTitle}`;
            } else if (extraTitle) {
              title = `${title} ${extraTitle}`;
            }
            this.titleService.setTitle(title);
            // if (shopSoort !== ShopSoort.ONBEKEND) { this.shopService.currentActiveShop = shopSoort; }
            if (this.shopService.currentActiveShop !== shopSoort) {
              if (this.shopService.lastActiveShop !== this.shopService.currentActiveShop) {
                this.shopService.lastActiveShop = this.shopService.currentActiveShop;
              }
              this.shopService.currentActiveShop = shopSoort;
            }
          }
        } else {
          console.warn(`route.snapshot.routeConfig is null! Skipping titleService`);
        }
      });
    // }
  }

  getExtraTitle(route: ActivatedRouteSnapshot): string {
    if (route && route.data && route.data['title']) {
      return route.data['title'];
    }
    return '';
  }

}
