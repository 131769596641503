<app-admin-form *ngIf="loyaltyShop" [title]="'Bonusshop categorie onderhoud'" [object]="loyaltyShop">
  <p *ngIf="!getCategories() || getCategories().length===0">Op dit moment staan er geen categorie&euml;n in het systeem.</p>
  <p *ngIf="getCategories().length>0">
    Hier kunnen de categorieën onderhouden worden en kan de volgorde van de categorieën in de bonusshop aangepast
    worden.
  </p>

  <table class="table table-striped small">
    <thead>
      <tr>
        <th>Omschrijving</th>
        <th class="text-center">Aktief</th>
        <th class="text-center">Artikelen</th>
        <th class="text-center">Volgorde</th>
        <th class="text-end">Aktie</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of getCategories() | sortByProperty:'SortOrder'">
        <td>{{category.Description}}</td>
        <td class="text-center">
          <div class="custom-control custom-checkbox noselect">
            <input type="checkbox" class="custom-control-input" [id]="'cat'+category.ID" [(ngModel)]="category.Active"
              (change)="changedCategoryActive(category)">
            <label class="custom-control-label" [for]="'cat'+category.ID"></label>
          </div>
        </td>
        <td class="text-center"><span class="badge text-bg-warning">{{getItemCount(category)}}</span></td>
        <td class="text-center">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary"
              [disabled]="mainService.isItemTop(getCategories(), category, 'SortOrder')" (click)="clickUp(category)"><i
                class="fas fa-chevron-up"></i></button>
            <button class="btn btn-xs btn-outline-secondary"
              [disabled]="mainService.isItemBottom(getCategories(), category, 'SortOrder')"
              (click)="clickDown(category)"><i class="fas fa-chevron-down"></i></button>
          </div>
        </td>
        <td class="text-end">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary" [routerLink]="['edit',category.ID]"><i
                class="fas fa-edit"></i>
              Wijzig</button>
            <button class="btn btn-xs btn-outline-secondary"
              (click)="adminLoyaltyShopService.deleteLoyaltyShopCategoryDialog(category)"><i
                class="fas fa-trash-alt"></i>
              Verwijder</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</app-admin-form>