import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { LoyaltyShopCategory } from '../../_models/loyalty-shop/loyalty.shop.category';
import { AdminLoyaltyShopService } from '../../_services/admin/admin-loyalty-shop.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLoyaltyShopCategoryEditDataResolverService  {

  constructor(
    private adminLoyaltyShopService: AdminLoyaltyShopService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoyaltyShopCategory> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.adminLoyaltyShopService.getLoyaltyShopCategoryById(+id).pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
