<div *ngIf="modules" class="container-fluid h-100 scroll">
  <div class="container">
    <div class="card text-bg-light button-container">
      <div class="card-body">
        <div *ngFor="let module of modules" class="row">
          <div class="col py-1">
            <div class="btn-group btn-group-lg w-100">
              <button type="button" class="btn btn-primary btn-module w-100" [ngClass]="{'disabled': !module.UnLocked}"
                (click)="clickModule(module)">
                <span [innerHTML]="mainService.getSafeHtml(customerInformationService.getTitleHtml(module.Kind))"></span>
              </button>
              <button *ngIf="module.PasswordNeeded" type="button" class="btn btn-primary btn-module" (click)="clickLock(module)">
                <span class="float-end">
                  <span *ngIf="module.UnLocked"><i class="fas fa-lock-open-alt"></i></span>
                  <span *ngIf="!module.UnLocked"><i class="fas fa-lock-alt"></i></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!modules || modules.length === 0" class="alert alert-warning" role="alert">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Alle modules staan uitgeschakeld! Raadpleeg uw leverancier.
        </div>
      </div>
    </div>
  </div>
</div>
