<app-admin-form [title]="'Wielen + Banden instellingen'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">

  <app-admin-dropdown [label]="adminSettingsService.getAdminSetting(settings,'DefaultWielenAantal').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'DefaultWielenAantal').Value" [modelValues]="adminSettingsService.getAdminSetting(settings,'WielenAantalKeuzes').Value"></app-admin-dropdown>

  <app-admin-custom-control [label]="'Extra artikelen die te selecteren zijn bij een wiel + band set'">
    <table *ngIf="settings.SelectieArtikelen.length>0" class="table table-striped table-responsive small">
      <thead>
        <tr>
          <th>Artikel</th>
          <th class="text-end">Aantal</th>
          <th class="text-center">Selectie</th>
          <th class="text-center">Toon keuze</th>
          <th class="text-center">TPMS</th>
          <th class="text-center">Volgorde</th>
          <th class="text-end">Aktie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let artikel of settings.SelectieArtikelen | sortByProperty:'Properties.SortOrder'">
          <td>{{artikel.Artikelnr}}<br />{{artikel.Omschrijving}}</td>
          <td class="text-end align-middle">
            <span *ngIf="artikel.Aantal != 0">{{artikel.Aantal}}</span>
            <span *ngIf="artikel.Aantal == 0">Per wiel</span>
          </td>
          <td class="text-center align-middle">
            <span *ngIf="artikel.Properties['SelectionKind'] == 0">Niet Geselecteerd</span>
            <span *ngIf="artikel.Properties['SelectionKind'] == 1">Wel Geselecteerd</span>
            <span *ngIf="artikel.Properties['SelectionKind'] == 2">Vast Geselecteerd</span>
          </td>
          <td class="text-center align-middle">
            <span *ngIf="artikel.Properties['DisplayChoice'] == 0">Altijd</span>
            <span *ngIf="artikel.Properties['DisplayChoice'] == 1">Combinatie</span>
            <span *ngIf="artikel.Properties['DisplayChoice'] == 2">Enkel Velg</span>
          </td>
          <td class="text-center align-middle">
            <span *ngIf="!artikel.Properties['OnlyTPMS']">Nee</span>
            <span *ngIf="artikel.Properties['OnlyTPMS']">Ja</span>
          </td>
          <td class="text-center align-middle">
            <div class="btn-group">
              <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemTop(settings.SelectieArtikelen, artikel, 'Properties.SortOrder')" (click)="mainService.moveItemUp(settings.SelectieArtikelen, artikel, 'Properties.SortOrder')"><i
                  class="fas fa-chevron-up"></i></button>
              <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemBottom(settings.SelectieArtikelen, artikel, 'Properties.SortOrder')" (click)="mainService.moveItemDown(settings.SelectieArtikelen, artikel, 'Properties.SortOrder')"><i
                  class="fas fa-chevron-down"></i></button>
            </div>
          </td>
          <td class="text-end align-middle">
            <div class="btn-group">
              <button class="btn btn-xs btn-outline-secondary" (click)="adminService.editSelectionItem(settings.SelectieArtikelen, artikel)"><i class="fas fa-edit"></i>
                Wijzig</button>
              <button class="btn btn-xs btn-outline-secondary" (click)="adminService.deleteSelectionItem(settings.SelectieArtikelen, artikel)"><i class="fas fa-trash-alt"></i>
                Verwijder</button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-end align-middle" colspan="8">
            <div class="btn-group">
              <button class="btn-primary btn btn-xs" (click)="adminService.editSelectionItem(settings.SelectieArtikelen, null)"><i class="fas fa-plus"></i> Artikel Toevoegen</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button *ngIf="settings.SelectieArtikelen.length===0" class="btn-primary btn" (click)="adminService.editSelectionItem(settings.SelectieArtikelen, null)"><i class="fas fa-plus"></i> Artikel Toevoegen</button>
  </app-admin-custom-control>

  <app-admin-checkbox [label]="adminSettingsService.getAdminSetting(settings,'WielenSelectieArtikelenOokBijAlleenVelg').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'WielenSelectieArtikelenOokBijAlleenVelg').Value">
  </app-admin-checkbox>

  <app-admin-checkbox [label]="adminSettingsService.getAdminSetting(settings,'WielenEnBandenAlleenBijVoorraadLeverancier').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'WielenEnBandenAlleenBijVoorraadLeverancier').Value">
  </app-admin-checkbox>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsRimsAndTyres(settings)">
  </app-admin-cancel-save-button>

</app-admin-form>
