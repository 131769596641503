<div class="row mb-3">
  <div class="col">
        <h6><span
            *ngIf="isMainSupplier">Hoofdleverancier<br /></span>{{getSupplier(supplierInfo.SupplierNumber)?.Name}}<span
            class="ms-1 text-body-tertiary">({{supplierInfo.SupplierNumber}})</span></h6>
    <div class="row">
      <div class="col-6">Artikelnummer leverancier</div>
      <div class="col-6"><b>{{supplierInfo.ItemNumberSupplier}} <span
            class="ms-1 text-body-tertiary">({{supplierInfo.ItemGroupSupplier}})</span></b></div>
    </div>
    <div *ngIf="netPrice" class="row">
      <div class="col-6">Nettoprijs</div>
      <div class="col-6"><span class="ms-1 text-danger">{{netPrice.NetPrice | price}}</span></div>
    </div>
    <button *ngFor="let interfaceInfo of adminSupplierInterfaceService.getInterfaceInfosForSupplier(supplierInterfaceData, supplierInfo.SupplierNumber)" type="button" 
      class="btn btn-light btn-xs w-100 mt-2" (click)="clickInterface(interfaceInfo, supplierInfo.SupplierNumber, supplierInfo.ItemNumberSupplier, supplierInfo.ItemGroupSupplier)">
      <i class="fas fa-link"></i> {{interfaceInfo.Name}}</button>
  </div>
</div>
<ng-container *ngIf="supplierInfo?.DepotInfo">
  <table class="table table-warning table-striped table-sm">
    <tbody>
      <tr *ngFor="let depot of supplierInfo.DepotInfo | keyvalue">
        <td>
          <div class="row mt-1">
            <div class="col-6">
              <b>{{depot.value.DepotName}}</b><span class="ms-1 text-body-tertiary">({{depot.value.DepotID}})</span>
            </div>
            <div class="col-6">
              <span *ngIf="depot.value.Availability==availabilityCode.NotInUse">{{depot.value.Stock}}</span>
              <span *ngIf="depot.value.Availability==availabilityCode.InStock">op voorraad</span>
              <span *ngIf="depot.value.Availability==availabilityCode.LowOnStock">weinig voorraad</span>
              <span *ngIf="depot.value.Availability==availabilityCode.OutOfStock">geen voorraad</span>
              <span *ngIf="depot.value.Availability==availabilityCode.SufficientlyInStock">voldoende voorraad</span>
            </div>
            <div *ngIf="isDateOk(depot.value.LastUpdated)" class="col-6 text-end"><i>Laatste update</i></div>
            <div *ngIf="isDateOk(depot.value.LastUpdated)" class="col-6"><b>{{depot.value.LastUpdated | date :'dd-MM-yyyy HH:mm'}}</b></div>
            <div *ngIf="isDateOk(depot.value.OrderBeforeDateTime)" class="col-6 text-end"><i>Bestel voor</i></div>
            <div *ngIf="isDateOk(depot.value.OrderBeforeDateTime)" class="col-6"><b>{{depot.value.OrderBeforeDateTime |
                date
                : 'dd-MM-yyyy HH:mm'}}</b></div>
            <div *ngIf="isDateOk(depot.value.BeingDeliveredAtBranchOnDateTime)" class="col-6 text-end"><i>Geleverd
                op</i>
            </div>
            <div *ngIf="isDateOk(depot.value.BeingDeliveredAtBranchOnDateTime)" class="col-6">
              <b>{{depot.value.BeingDeliveredAtBranchOnDateTime | date : 'dd-MM-yyyy HH:mm'}}</b>
            </div>
            <div *ngIf="depot.value.PickupPossible" class="col-6 text-end"><i>Af te halen aan de balie</i></div>
            <div *ngIf="depot.value.PickupPossible" class="col-6"><i>Ja</i></div>
            <div *ngIf="depot.value.Remarks.length" class="col-12 text-body-tertiary"><i
                *ngFor="let remark of depot.value.Remarks">{{remark}}<br /></i></div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<div *ngIf="supplierInfo?.StockRemarks && supplierInfo.StockRemarks.length" class="row">
  <div class="col text-body-tertiary">
    <span *ngFor="let remark of supplierInfo.StockRemarks"><i>{{remark}}</i><br /></span>
  </div>
</div>