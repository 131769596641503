import { AppLogLevel } from './app.log.level';

export class AppLogEntry {
  EntryDate: Date;
  Level: AppLogLevel;
  Arguments: any[];

  constructor(level: AppLogLevel, args: any[]) {
    this.EntryDate = new Date();
    this.Level = level;
    this.Arguments = args;
  }
}
