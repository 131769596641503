<div class="input-group">
  <button *ngIf="!withoutExtraButtons" class="btn btn-primary" type="button"  [routerLink]="getCalculatorLink()"><i class="fas fa-calculator"></i></button>
  <input type="text" [appSelectOnclick] appBandenMaat [appFocus]="focusTyreInput" class="form-control tyre-input" [ngModel]="size" (ngModelChange)="size = $event" placeholder="bandenmaat..."
    aria-label="Search for..." (keyup.enter)="router.navigate(['/tyres', size], { queryParams: queryParams })"
    (focus)="gotFocus.emit(true)" />
    <button *ngIf="!withoutExtraButtons" class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">{{mainService.getStringOrDefault(category, 'Alle banden')}}</button>
    <div *ngIf="!withoutExtraButtons" class="dropdown-menu">
      <a *ngFor="let c of categories" class="dropdown-item"
        (click)="category=c">{{mainService.getStringOrDefault(c, 'Alle banden')}}</a>
    </div>
  
    <button *ngIf="!withoutExtraButtons" class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="dropdownClick($event)">{{mainService.getStringOrDefault(brand, 'Alle merken')}}</button>
    <div *ngIf="!withoutExtraButtons" class="dropdown-menu">
      <a class="dropdown-item" (click)="brand=''">Alle merken</a>
      <div class="input-group input-group-sm search-box">
        <input type="text" class="form-control" [appFocus]="focusFilter" [(ngModel)]="brandFilter">
        <button class="btn btn-sm btn-primary" type="button" (click)="clearFilter($event)"><i class="fas fa-times"></i></button>
      </div>
      <a *ngFor="let b of brands | filterString: brandFilter" class="dropdown-item" (click)="brand=b">{{mainService.getStringOrDefault(b, 'Alle merken')}}</a>
    </div>
    <button class="btn btn-primary" type="button" [routerLink]="['/tyres',size]" [queryParams]="queryParams">
      <i class="fas fa-search"></i>
    </button>
</div>