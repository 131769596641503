import { CatalogKind } from './catalog.kind';

export class PartsCategory {
    Id: string;
    UniqueID: string;
    Origin: CatalogKind;
    CategoryId: string;
    CategoryName: string;
    IsGraphicCategory: boolean;
    PartsDescriptions: string[];
    SortOrder: number;
    SubPartsCategories: PartsCategory[];
    ImageUrl: string;
}

