<div class="pro-header">
  <div class="pro-left-triangle"></div>
  <div class="pro-right-triangle"></div>
</div>
<div class="pro-parts-container">
  <div class="row no-gutter pro-parts-row">
    <div class="col my-auto">
      <a href="http://www.proparts.nl/"><img src="/assets/img/identity/proparts/proparts-logo.png"
          alt="logo" /></a>
    </div>
    <div class="col my-auto">
      <div class="pro-terug px-3" [routerLink]="['/start']"
        tooltip="Klik hier om terug te gaan naar het startscherm" placement="bottom" container="body"
        (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)">
        <a class="fas fa-home"></a>
      </div>
      <div class="pro-terug" (click)="cartService.changeNetPriceOption()" tooltip="Klik hier om de nettoprijs aanof uit te zetten" placement="bottom" container="body">
        <ng-container *ngIf="!cartService.getNetPriceHidden();else nettoff"><div><i class="fas fa-eye"></i></div></ng-container>
        <ng-template #nettoff><div><i class="fas fa-eye-slash"></i></div></ng-template>
      </div>
    </div>
    <div class="col my-auto">
      <div class="winkelwagen px-3"><a [routerLink]="['/cart']"><span>{{cartService.getCartItemCount()}}</span></a></div>
      <div *ngIf="false && cartService.isLoyaltyPointsActive()" class="cadeaupunten"><a [routerLink]="['/loyaltyshop']">{{cartService.getLoyaltyPointsName()}}</a>
        {{mainService.padLeft(cartService.getLoyaltyPoints().toString(),7)}}</div>
      <div *ngIf="shopService.containsShopModule(ctx, shopSoort.GarageInformatieSysteem)" class="admin px-3" [routerLink]="['/details']" tooltip="Kilk hier om naar de Administratie te gaan" placement="bottom" container="body"><i class="fas fa-user"></i></div>
      <div class="admin px-3" [routerLink]="['/settings']" (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)" tooltip="Kilk hier om naar de instellingen te gaan" placement="bottom" container="body"><i class="fas fa-cog"></i></div>
    </div>
    <div class="col my-auto ms-auto">
      <div class="pro-nrs-block">
        <div class="pro-tel">{{ctx.CompanyInfo.BranchPhone}}</div>
        <div class="pro-app">{{ctx.CompanyInfo.BranchWhatsApp}}</div>
      </div>
    </div>
  </div>
  <hr />
</div>

<div *ngIf="!shopService.getTabGroupTabs(ctx);else tab_strip" class="mx-3 seperator"></div>

<ng-template #tab_strip>
  <app-tab-strip [ctx]="ctx"></app-tab-strip>
</ng-template>
