<ng-container *ngIf="ctx" [ngSwitch]="mainService.getShopPortalLayoutKind()">

  <ng-container *ngSwitchDefault>
    <div class="main-content-portal">
      <div #header>
        <app-header [ctx]="ctx"></app-header>
      </div>
      <div class="row g-0" [ngStyle]="getRemainingHeightStyle()">

        <div *ngIf="!mainService.isIE && mainService.isMenuActiveAndHidden()" class="side-menu-static" (mouseleave)="mainService.toggleMenuFloating(false)"
          [ngStyle]="getMenuStyleStatic()" [@showStaticMenu]="mainService.isMenuFloating()">
          <app-side-menu [ctx]="ctx"></app-side-menu>
        </div>
        <div *ngIf="!mainService.isIE && mainService.isMenuActive()" class="col side-menu" [style.flexBasis]="getMenuWidth()" [ngStyle]="getMenuStyleNormal()"
          [@slideInMenu]="mainService.shouldMenuBeHidden()" #menu>
          <app-side-menu [ctx]="ctx"></app-side-menu>
        </div>

        <div *ngIf="mainService.isIE && mainService.isMenuActiveAndHidden() && mainService.isMenuFloating()" class="side-menu-static"
          (mouseleave)="mainService.toggleMenuFloating(false)" [ngStyle]="getMenuStyleStatic()">
          <app-side-menu [ctx]="ctx"></app-side-menu>
        </div>
        <div *ngIf="mainService.isIE && mainService.isMenuActive() && !mainService.shouldMenuBeHidden()" class="col side-menu"
          [ngStyle]="getIEMenuStyle()">
          <app-side-menu [ctx]="ctx"></app-side-menu>
        </div>

        <div class="col h-100">
          <router-outlet></router-outlet>
        </div>

        <div *ngIf="!mainService.isIE" class="col" [ngStyle]="getCartStyle()" [@slideInCart]="!ctx.ShowCartContent"
          #cart>
          <app-side-cart [ctx]="ctx"></app-side-cart>
        </div>
        <div *ngIf="mainService.isIE && ctx.ShowCartContent" class="col" [ngStyle]="getCartStyle()">
          <app-side-cart [ctx]="ctx"></app-side-cart>
        </div>

      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="1">
    <app-header [ctx]="ctx"></app-header>
    <app-navigation *ngIf="!settingsService.getSettingBoolean('DisableNavigationTemplate')" [ctx]="ctx"></app-navigation>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!settingsService.getSettingBoolean('DisableFooterTemplate')" [ctx]="ctx"></app-footer>
    <div *ngIf="settingsService.getSettingBoolean('DisableFooterTemplate')">&nbsp;</div>
    <app-scroll-to-top-button *ngIf="!settingsService.getSettingBoolean('DisableScrollToTopButton')"></app-scroll-to-top-button>
  </ng-container>

</ng-container>
