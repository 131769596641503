<h5 class="mb-3" (click)="mainService.showObject(null, totals)">
  In de catalogus worden de volgende lijnen getoond:
</h5>
<div *ngIf="totals">
  <ng-container *ngFor="let catalogKey of objectKeys(settings.CatalogKinds)">
    <app-catalog-lines-totals-report [totals]="totals" [catalogKey]="+catalogKey"
      [catalogDescription]="getCatalogDescription(+catalogKey)"
      [invert]="!settings.CatalogLayoutCatalogLinesFilter_FilterEverythingExcept"></app-catalog-lines-totals-report>
    <br />
  </ng-container>
</div>
