import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (value === 0) {
      return 'o.a.';
    } else if (value && value.toFixed) {
      return '€ ' + value.toFixed(2);
    }
    return null;
  }

}
