import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterfaceData } from '../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminSupplierInterfaceService } from '../../../../_services/admin/admin-supplier-interface.service';

@Component({
  selector: 'app-proxy-supplier-interface-settings',
  templateUrl: './proxy-supplier-interface-settings.component.html',
  styleUrls: ['./proxy-supplier-interface-settings.component.scss']
})
export class ProxySupplierInterfaceSettingsComponent implements OnInit {
  @Input() interfaceDataSuppliers: { [key: number]: InterfaceData[] };
  @Input() supplierInterfaceData: SupplierInterfaceData;
  @Output() interfaceDataChanged: EventEmitter<SupplierInterfaceData> = new EventEmitter<SupplierInterfaceData>();

  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService
  ) { }

  ngOnInit(): void {
  }

}
