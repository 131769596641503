import { Pipe, PipeTransform } from '@angular/core';
import { CatalogLayoutSubgroupCategoryCrossLink } from '../../../../_models/admin/settings/catalog.layout.subgroup.category.cross.link';
import { CatalogLayoutService } from '../../../../_services/catalog-layout.service';

@Pipe({
  name: 'adminCatalogLayoutCategorySort',
  pure: false
})
export class AdminCatalogLayoutCategorySortPipe implements PipeTransform {

  transform(categories: CatalogLayoutSubgroupCategoryCrossLink[], catalogLayoutService: CatalogLayoutService
    , cross: { [key: number]: CatalogLayoutSubgroupCategoryCrossLink[] }): any {
    if (categories) {
      return categories.sort((cat1, cat2) => {
        if (catalogLayoutService.isCategoryLinked(cross, cat1) && !catalogLayoutService.isCategoryLinked(cross, cat2)) { return 1; }
        if (!catalogLayoutService.isCategoryLinked(cross, cat1) && catalogLayoutService.isCategoryLinked(cross, cat2)) { return -1; }
        if (cat1.CategoryDescription > cat2.CategoryDescription) { return 1; }
        if (cat1.CategoryDescription < cat2.CategoryDescription) { return -1; }
        if (cat1.Origin > cat2.Origin) { return 1; }
        if (cat1.Origin < cat2.Origin) { return -1; }
        return 0;
      });
    }
    return categories;
  }

}
