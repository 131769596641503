<app-admin-form [title]="'Overzicht uitstaande '+settings.NameOfPointsLoyaltyShop" [object]="creditsInfos">
  <ng-container *ngIf="(creditsInfos | filterCreditsInfos:filterString) as resultingCreditsInfos">
    <app-admin-input [labelCols]="1" [label]="'Zoek'" [(model)]="filterString" (inputClick)="filterString = ''"
      [placeholder]="'geef hier eventueel een zoekstring'">
      <span class="input-group-text"><span class="small">{{resultingCreditsInfos.length}} /
          {{creditsInfos.length}}</span></span>
    </app-admin-input>
    <div class="row p-2 border-bottom">
      <div class="col-sm-6">Klant</div>
      <div class="col-sm-2 text-end">{{settings.NameOfPointsLoyaltyShop}}</div>
      <div class="col-sm-2 text-end">Gestart</div>
      <div class="col-sm-2 text-end">Laatste update</div>
    </div>
    <div *ngFor="let creditsInfo of resultingCreditsInfos; let even=even"
      class="row border-bottom small p-2" [ngClass]="{'alt-back': even}">
      <div class="col-sm-6">
        {{creditsInfo.Name}} <span class="text-low">({{creditsInfo.Customer}})</span>
      </div>
      <div class="col-sm-2 text-end">{{creditsInfo.Credits}}</div>
      <div class="col-sm-2 text-end">{{creditsInfo.StartedCounting | date : 'dd-MM-yyyy'}}</div>
      <div class="col-sm-2 text-end">{{creditsInfo.LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</div>
    </div>
    <div *ngIf="resultingCreditsInfos.length===0 && filterString" class="alert alert-danger mt-3" role="alert">
      <i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!
    </div>
  </ng-container>
</app-admin-form>