export class Settings {

    constructor(setting: string, value: string) {
        this[setting] = value;
    }

    addSetting(setting: string, value: string) {
        this[setting] = value;
    }

}
