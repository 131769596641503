<app-admin-form [title]="'Onderhoud Artikel Afbeeldingen'" [object]="itemGroupImagesData">
  <table class="table table-striped small">
    <thead>
      <tr>
        <th>Artikelgroep</th>
        <th>Omschrijving</th>
        <th>Afbeeldingen</th>
        <th class="text-end">Aktie</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of itemGroupImagesData.Items">
        <td>{{item.ItemGroupNumber}}</td>
        <td>{{item.ItemGroupDescription}}</td>
        <td>
          <div class="image-thumbs-container">
            <div class="image-container" *ngFor="let image of item.Images">
              <img [src]="image.ImageUrl" class="img-fluid image-thumb" />
            </div>
          </div>
        </td>
        <td class="text-end">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary" [routerLink]="['edit', item.ItemGroupNumber]"><i
                class="fas fa-edit"></i> Wijzig</button>
            <button class="btn btn-xs btn-outline-secondary"
              (click)="adminItemImagesService.deleteItemImagesItemGroupDialog(item)"><i class="fas fa-trash-alt"></i>
              Verwijder</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-end" colspan="7">

          <div class="btn-toolbar btn-toolbar-sm justify-content-end" role="toolbar">
            <div class="btn-group-sm me-2">
              <button class="btn btn-primary" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
            </div>
            <div class="input-group input-group-sm me-2 mb-3">
              <input type="text" [appFocus]="focusInput" [placeholder]="'filter'" [(ngModel)]="itemGroupImagesData.Filter" (ngModelChange)="doFilter()" class="form-control">
              <button class="btn btn-outline-secondary" type="button" (click)="clearFilter($event)"><i class="fas fa-times"></i></button>
              <span class="input-group-text" id="inputGroup-sizing-sm">{{itemGroupImagesData.RecordCount}}/{{itemGroupImagesData.RecordCountUnfiltered}}</span>
            </div>

            <div class="input-group input-group-sm me-2 mb-3">
              <label class="input-group-text" for="inputGroupSelect01">Toon per</label>
              <select class="form-select" id="inputGroupSelect01" [(ngModel)]="itemGroupImagesData.PageSize" (ngModelChange)="doFilter(true)">
                <option value="5" [selected]="itemGroupImagesData.PageSize == 5">5</option>
                <option value="10" [selected]="itemGroupImagesData.PageSize == 10">10</option>
                <option value="25" [selected]="itemGroupImagesData.PageSize == 25">25</option>
                <option value="100" [selected]="itemGroupImagesData.PageSize == 100">100</option>
              </select>
            </div>

            <nav *ngIf="mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize) > 1">
              <ul class="pagination pagination-sm">
                <li class="page-item" [ngClass]="{'disabled': itemGroupImagesData.PageNumber==1}">
                  <a class="page-link" [routerLink]="['/admin/management/item-group-images']" [queryParams]="{pageNumber: itemGroupImagesData.PageNumber - 1, filter: itemGroupImagesData.Filter, pageSize: itemGroupImagesData.PageSize}" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item" [ngClass]="{'active': itemGroupImagesData.PageNumber == 1}"><a class="page-link" [routerLink]="['/admin/management/item-group-images']" [queryParams]="{pageNumber: 1, filter: itemGroupImagesData.Filter, pageSize: itemGroupImagesData.PageSize}">1</a></li>
                <li *ngIf="itemGroupImagesData.PageNumber > 2 && mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize) > 3" class="page-item disabled"><a class="page-link">...</a></li>
                <li *ngIf="itemGroupImagesData.PageNumber != 1 && itemGroupImagesData.PageNumber != mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize)" class="page-item active"><a class="page-link">{{itemGroupImagesData.PageNumber}}</a></li>
                <li *ngIf="mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize) == 3 && itemGroupImagesData.PageNumber != 2" class="page-item"><a class="page-link"  [routerLink]="['/admin/management/item-group-images']" [queryParams]="{pageNumber: 2, filter: itemGroupImagesData.Filter, pageSize: itemGroupImagesData.PageSize}">2</a></li>
                <li *ngIf="itemGroupImagesData.PageNumber < mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize) - 1 && mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize) > 3" class="page-item disabled"><a class="page-link">...</a></li>
                <li class="page-item" [ngClass]="{'active': itemGroupImagesData.PageNumber == mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize)}"><a class="page-link" [routerLink]="['/admin/management/item-group-images']" [queryParams]="{pageNumber: mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize), filter: itemGroupImagesData.Filter, pageSize: itemGroupImagesData.PageSize}">{{mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize)}}</a></li>
                <li class="page-item"
                  [ngClass]="{'disabled': itemGroupImagesData.PageNumber == mainService.getMathCeil(itemGroupImagesData.RecordCount/itemGroupImagesData.PageSize)}">
                  <a class="page-link" [routerLink]="['/admin/management/item-group-images']" [queryParams]="{pageNumber: itemGroupImagesData.PageNumber + 1, filter: itemGroupImagesData.Filter, pageSize: itemGroupImagesData.PageSize}" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>

          </div>

        </td>
      </tr>
    </tbody>
  </table>
</app-admin-form>