<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="headers" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true">Orderhistorie</li>
    </app-breadcrumb-header>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="search()"></app-extra-password-webshop>
    </div>

    <div *ngIf="!customerInformationService.showPasswordPrompt">

      <div class="row">
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Selectie</label>
            <div class="col-8">
              <select class="form-control form-control-sm" [ngModel]="customerInformationService.selectionOrderHistory"
                (ngModelChange)="customerInformationService.setDates(customerInformationService.requestOrderHistory, $event); customerInformationService.selectionOrderHistory = $event;">
                <option [ngValue]="choice" *ngFor="let choice of customerInformationService.dateChoicesOrderHistory">
                  {{choice.Description}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum van</label>
            <div class="col-8">
              <input type="text" placeholder="datum van" [(ngModel)]="customerInformationService.requestOrderHistory.DateFrom"
                (ngModelChange)="customerInformationService.selectionOrderHistory = customerInformationService.getSelection(customerInformationService.requestOrderHistory, customerInformationService.dateChoicesOrderHistory)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionOrderHistory.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum tot</label>
            <div class="col-8">
              <input type="text" placeholder="datum tot" [(ngModel)]="customerInformationService.requestOrderHistory.DateTo"
                (ngModelChange)="customerInformationService.selectionOrderHistory = customerInformationService.getSelection(customerInformationService.requestOrderHistory, customerInformationService.dateChoicesOrderHistory)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionOrderHistory.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Ordernr</label>
            <div class="col-8">
              <input type="number" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestOrderHistory.Ordernumber" maxlength="9"
                max="999999999">
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Referentie</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestOrderHistory.Reference" maxlength="200">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Artikelnr</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestOrderHistory.ArticleNumber" maxlength="50">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Omschrijving</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestOrderHistory.Description" maxlength="100">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Kenteken</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestOrderHistory.PlateNumber" maxlength="6">
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group row">
            <div class="col">
              <button type="button" class="btn btn-sm w-100 btn-primary" (click)="search()"><i class="fas fa-search"></i>
                Zoeken</button>
            </div>
          </div>
        </div>
      </div>

      <app-grid-webshop [items]="sortService.sortObject('order-history', headers)" emptyText="Geen orders gevonden."
        (rowClick)="clickHeader($event)" class="mt-3" [isBusy]="isBusy">
        <ng-container grid-header>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'order-history'" [sortField]="'OrderNumber'">Ordernummer</app-sort-field>
          </div>
          <div class="col-2">
            <app-sort-field [sortObjectId]="'order-history'" [sortField]="'OrderDate'">Orderdatum</app-sort-field>
          </div>
          <div class="col-5">
            <app-sort-field [sortObjectId]="'order-history'" [sortField]="'Reference'">Referentie</app-sort-field>
          </div>
          <div class="col-1 text-end">
            <app-sort-field [sortObjectId]="'order-history'" [sortField]="'GrossOrderAmount'">Bruto</app-sort-field>
          </div>
          <div class="col-1 text-end">
            <app-sort-field [sortObjectId]="'order-history'" [sortField]="'NetOrderAmount'">Netto</app-sort-field>
          </div>
          <div class="col-1 text-center">PDF</div>
        </ng-container>
        <ng-template let-item>
          <div class="col-2 small">{{item.OrderNumber | leadZeros:8}}</div>
          <div class="col-2 small">{{item.OrderDate | date : 'dd-MM-yyyy HH:mm'}}</div>
          <div class="col-5 small">{{item.Reference}}</div>
          <div class="col-1 text-end small">{{item.GrossOrderAmount | amount}}</div>
          <div class="col-1 text-end small">{{item.NetOrderAmount | amount}}</div>
          <div class="col-1 text-center small">
            <span *ngIf="customerInformationService.containsPdf(item)" class="text-danger pointer" (click)="customerInformationService.showShopOrderPdf(item)">
              <i class="fas fa-file-pdf"></i></span>
          </div>
        </ng-template>
      </app-grid-webshop>




    </div>


  </div>
</div>