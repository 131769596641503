import { Injectable } from '@angular/core';
import { ContextMain } from '../_models/common/context.main';
import { ShopService } from './shop.service';
import { MainService } from './main.service';
import { ShopSoort } from '../_models/common/shop.soort';

@Injectable()
export class HomeScreenService {
  routeTimerString: string;
  routeTimerAlmostString: string;

  constructor(
    private mainService: MainService,
    private shopService: ShopService
  ) { }

  getHomeButtonImage(kind: string, icon: string, color: string): string {
    if (!color) { color = 'x'; }
    if (icon.endsWith('.png')) { icon = icon.substr(0, icon.length - 4); }
    return `${this.mainService.getBackendApi()}/img/home/btn_${kind}_${icon}_${color}.png`;
  }

  getSecondsUntilNextRoute(ctx: ContextMain): number {
    let seconds = 0;
    if (ctx && ctx.Routes && ctx.Routes.length > 0) {
      let index = 0;
      do {
        seconds = Math.floor((ctx.Routes[index].StartDateTime.getTime() - this.shopService.dateTimeNow.getTime()) / 1000);
        // 2019-02-27 Maurice: Fixed datetime JSON.parse to local datetime in prototypes.js line 37,
        //                     dus onderstaande regel is niet meer nodig ;-)
        // seconds += (60 * this.shopService.dateTimeNow.getTimezoneOffset());
      }
      while (seconds < 0 && ctx.Routes.length > index++);
    }
    return seconds;
  }

  getDaysHoursMinutesSecondsTillNextRoute(nextRouteCounterWithSeconds: boolean): string {
    const dateNow = new Date();
    let seconds = 0;
    if (this.mainService.ctxMain && this.mainService.ctxMain.Routes && this.mainService.ctxMain.Routes.length > 0) {
      let index = 0;
      do {
        seconds = Math.floor((this.mainService.ctxMain.Routes[index].StartDateTime.getTime()
          - dateNow.getTime()) / 1000);
      }
      while (seconds < 0 && this.mainService.ctxMain.Routes.length > index++);
    }
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    const result = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return result + (nextRouteCounterWithSeconds ? `:${seconds.toString().padStart(2, '0')}` : '');
  }

  getRouteTimer(part: string): string {
    this.setRouteTimerVars();
    if (this.routeTimerString && this.routeTimerString.length === 6) {
      let index = 0;
      if (part === 'HiHour') { index = 0; }
      if (part === 'LoHour') { index = 1; }
      if (part === 'HiMinute') { index = 2; }
      if (part === 'LoMinute') { index = 3; }
      if (part === 'HiSecond') { index = 4; }
      if (part === 'LoSecond') { index = 5; }
      let digit = this.routeTimerString.substr(index, 1);
      if (this.routeTimerAlmostString && this.routeTimerAlmostString.length === 6
        && this.routeTimerAlmostString.substr(index, 1) === '*') { digit = digit + 'flap'; }
      return digit;
    }
    return '0';
  }

  setRouteTimerVars() {
    let seconds = this.mainService.ctxMain?.RouteTimerEnabled ? this.getSecondsUntilNextRoute(this.mainService.ctxMain) : 0;
    if (seconds) {
      const hours = Math.floor(seconds / 3600);
      seconds = seconds - hours * 3600;
      const minutes = Math.floor(seconds / 60);
      seconds = seconds - minutes * 60;
      this.routeTimerString = '';
      this.routeTimerAlmostString = '';
      if (hours < 100) {
        if (hours < 10) { this.routeTimerString += '0'; }
        this.routeTimerString += hours;
        if (minutes < 10) { this.routeTimerString += '0'; }
        this.routeTimerString += minutes;
        if (seconds < 10) { this.routeTimerString += '0'; }
        this.routeTimerString += seconds;
        if (seconds < 100) {
          this.routeTimerAlmostString = '--';
          this.routeTimerAlmostString = this.getAlmostPart(minutes, seconds) + this.routeTimerAlmostString;
          this.routeTimerAlmostString = this.getAlmostPart(hours, minutes) + this.routeTimerAlmostString;
        }
      }
    }
  }

  getAlmostPart(d1, d2) {
    if (d2 === 0 && d1 === 0) { return '**'; }
    if (d2 === 0 && (d1 % 10) === 0) { return '**'; }
    if (d2 === 0) { return '-*'; }
    return '--';
  }
}
