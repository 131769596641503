<app-admin-form [title]="'Kadoshop '+settings.NameOfPointsLoyaltyShop+' mutatie' + noOrdersFound" [object]="settings">

  <app-admin-custom-control *ngIf="!searchingCustomer" [label]="'Kies een klant'" [dividerKind]="1">
    <button type="button" class="btn btn-light border border-light-subtle text-start w-100" (click)="enableCustomerSearch()">
      <span *ngIf="!customer">Geen klant gekozen.</span>

      <div *ngIf="customer" class="row">
        <div class="col-4">
          <b>{{customer.Name}}</b><span class="ms-3 text-low">({{customer.CustomerNumber}})</span><br />
          {{customer.City}}
        </div>
        <div class="col-4 fw-light large text-end">{{settings.NameOfPointsLoyaltyShop}}</div>
        <div class="col-4 fw-bold text-success large">
          <span *ngIf="creditsInfo">{{creditsInfo.Credits}}</span>
        </div>
      </div>
    </button>
  </app-admin-custom-control>
  <app-admin-typeahead-customer-search *ngIf="searchingCustomer" [label]="'Kies een klant'" [(start)]="inputCustomer"
    [wholesaler]="settings.UID.Wholesaler" (selected)="customerChange($event)" [focusInput]="focusInputCustomer"
    [placeholder]="'zoek een klant'" (blur)="customerBlur()" [dividerKind]="1">
      <button class="btn btn-outline-secondary" type="button" (click)="resetCustomerSearch()"><i
          class="fas fa-times"></i></button>
  </app-admin-typeahead-customer-search>

  <app-admin-checkbox *ngIf="!noOrdersFound"
    [label]="'Zoek alleen in de orders met een '+settings.NameOfLoyaltyShop+' bestelling'"
    [(model)]="onlyLoyaltyShopOrders"></app-admin-checkbox>

  <app-admin-custom-control *ngIf="!searchingOrder && !noOrdersFound" [label]="'Zoek een order'" [dividerKind]="1">
    <button type="button" class="btn btn-light border border-light-subtle text-start w-100" [ngClass]="{'disabled': !customer}"
      (click)="enableOrderSearch()">
      <span *ngIf="!order && !customer">Kies eerst een klant</span>
      <span *ngIf="!order && customer">Geen order gekozen.</span>
      <div *ngIf="order" class="row">
        <div class="col-2"><b *ngIf="order.OrderNumber;else orderid">{{order.OrderNumber}}</b>
          <ng-template #orderid>{{order.OrderId}}</ng-template>
        </div>
        <div class="col-2 text-low text-end small">{{order.OrderDate | date : 'dd-MM-yyyy HH:mm'}}</div>
        <div class="col-2 fw-light d-inline-block text-truncate"><span></span>{{order.Reference}}</div>
        <div class="col-6 fw-light d-inline-block text-truncate">{{order.Remark}}</div>
        <div class="col-2 fw-light">Bruto<br />Netto</div>
        <div class="col-2 fw-bold text-end">{{order.GrossOrderAmount | amount}} <br />{{order.NetOrderAmount
          | amount}}</div>
        <div class="col-4 fw-light large text-end">{{settings.NameOfPointsLoyaltyShop}}</div>
        <div class="col-4 fw-bold large">
          <span class="text-success" *ngIf="order.CreditsReceived"> +{{order.CreditsReceived}}</span>
          <span class="text-danger" *ngIf="order.CreditsOrderAmount>0"> -{{order.CreditsOrderAmount}}</span>
          <span class="text-success" *ngIf="order.CreditsOrderAmount<0"> +{{order.CreditsOrderAmount*-1}}</span>
          <span *ngIf="order.CreditsReceived && order.CreditsOrderAmount"> = <span
              class="text-warning">{{order.CreditsReceived - order.CreditsOrderAmount}}</span></span>
          <span *ngIf="!order.CreditsReceived && !order.CreditsOrderAmount" class="text-low"> 0</span>
        </div>
      </div>
    </button>
  </app-admin-custom-control>
  <app-admin-typeahead-order-search *ngIf="searchingOrder && !noOrdersFound" [label]="'Zoek een order'"
    [(start)]="inputOrder" [wholesaler]="settings.UID.Wholesaler" [customer]="modelCustomer"
    (selected)="orderChange($event)" [focusInput]="focusInputOrder"
    [placeholder]="'zoek op ordernummer, artikel, omschrijving, kenteken, enz.'" (blur)="orderBlur()" [dividerKind]="1"
    [onlyLoyaltyShopOrders]="onlyLoyaltyShopOrders">
      <button class="btn btn-outline-secondary" type="button" (click)="resetOrderSearch()"><i
          class="fas fa-times"></i></button>
  </app-admin-typeahead-order-search>

  <app-admin-custom-control *ngIf="!searchingOrder && !searchingCustomer && order && order.Orderlines?.length"
    [labelCols]="0">
    <table *ngIf="order.Orderlines; else noOrderlines" class="table table-striped small mt-3">
      <thead>
        <tr>
          <th class="text-end">Regel</th>
          <th>Artikel</th>
          <th class="text-end">Groep</th>
          <th>Omschrijving</th>
          <th class="text-end">Aantal</th>
          <th class="text-end">Bruto</th>
          <th class="text-end">Netto</th>
          <th class="text-end">{{settings.NameOfPointsLoyaltyShop}}</th>
          <th>Kenteken</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let line of order.Orderlines">
          <td class="text-end">{{line.LineNumber}}</td>
          <td>{{line.ItemNumber}}</td>
          <td class="text-end">{{line.ItemGroup}}</td>
          <td>{{line.Description}}</td>
          <td class="text-end">{{line.ItemCount}}</td>
          <td class="text-end">{{line.GrossPrice | price}}</td>
          <td class="text-end">{{line.NetPrice | price}}</td>
          <td class="text-end"><span *ngIf="line.Credits" class="text-danger">{{line.Credits}}</span></td>
          <td><img *ngIf="line.LicensePlate" style="width: 100px;" [src]="licensePlateService.getLicensePlateUrl(line.LicensePlate) | async" /></td>
        </tr>
      </tbody>
    </table>
    <ng-template #noOrderlines>
      Deze order bevat geen regels.
    </ng-template>
  </app-admin-custom-control>

  <app-admin-textarea *ngIf="!noOrdersFound" [label]="'Reden'" [rows]="4" [(model)]="request.Remarks"
    [disabled]="noOrdersFound"
    [placeholder]="'Vul hier de reden in van de aanpassing van de '+settings.NameOfPointsLoyaltyShop+'...'">
  </app-admin-textarea>

  <app-admin-custom-control *ngIf="noOrdersFound">
    <div class="alert alert-danger" role="alert">
      Deze klant heeft geen ShopPortal orders!
    </div>
  </app-admin-custom-control>


  <app-admin-custom-control [labelCols]="0">
    <div class="btn-toolbar" role="toolbar">
      <div class="btn-group me-1" role="group">
        <button type="button" class="btn btn-warning ms-3 me-2" [routerLink]="['/admin']"><i class="fas fa-undo"></i>
          Annuleren</button>
      </div>
      <div class="btn-group" role="group">
        <div class="input-group">
            <button class="btn btn-danger" [ngClass]="{'disabled': getDisabled()}" type="button"
              (click)="editCredits($event)">
              <span *ngIf="!busySaving"><i class="fas fa-edit"></i></span>
              <span *ngIf="busySaving"><i class="fas fa-sync fa-spin"></i></span>
              Muteer saldo met
            </button>
          <input type="text" appNumericOnly [allowNegative]="true" class="form-control input-width"
            [ngModel]="request.Credits" (ngModelChange)="request.Credits=$event">
            <button class="btn btn-danger" [ngClass]="{'disabled': getDisabled()}" type="button"
              (click)="editCredits($event)">
              {{settings.NameOfPointsLoyaltyShop}}
            </button>
        </div>
      </div>
    </div>



  </app-admin-custom-control>

</app-admin-form>