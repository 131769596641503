import { Component, OnInit, Input } from '@angular/core';

import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { ItemInfoService } from '../../../_services/item-info.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { PartsProperty } from '../../../_models/catalog/parts.property';
import { PropertyKindCatalog } from '../../../_models/catalog/property.kind.catalog';

@Component({
  selector: 'app-catalog-parts-link-property',
  templateUrl: './catalog-parts-link-property.component.html',
  styleUrls: ['./catalog-parts-link-property.component.scss']
})
export class CatalogPartsLinkPropertyComponent implements OnInit {
  @Input() part: Part;
  @Input() property: { Item1: string, Item2: string };
  @Input() partsPropertyTranslations: { [key: string]: { [key: string]: string } };
  @Input() shopKind: ShopSoort;
  @Input() maxItems: number;
  more = false;

  constructor(
    public itemInfoService: ItemInfoService
  ) { }

  ngOnInit() {
  }

  getBrand(prop: PartsProperty): string {
    if (prop && prop.Kind === PropertyKindCatalog.OENr && prop.Description) return prop.Description;
    return null;
  }

}
