interface Array<T> {
  remove(elem: T): Array<T>;
  delete(elem: T): Array<T>;
  replaceElements(newElements: T[]);
  orderBy(property: string): Array<T>;
}


if (!Array.prototype.remove) {
  Array.prototype.remove = function <T>(elem: T): T[] {
    return this.filter(e => e !== elem);
  }
}

if (!Array.prototype.delete) {
  Array.prototype.delete = function <T>(elem: T): T[] {
    const index: number = this.indexOf(elem);
    if (index !== -1) {
      this.splice(index, 1);
    }
    return this;
  }
}

if (!Array.prototype.replaceElements) {
  Array.prototype.replaceElements = function <T>(newKeyValues: T[]) {
    this.splice(0, this.length);
    for (let element of newKeyValues) {
      this.push(element);
    }
  }
}

if (!Array.prototype.orderBy) {
  Array.prototype.orderBy = function <T>(property: string): T[] {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return this.sort((a, b) => {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    });
  }
}
