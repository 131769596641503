<app-admin-form [title]="'Gebruikers online'" [object]="onlineSessions">
  <ng-container *ngIf="(sessions | filterSessions:filterString) as resultingSessions">
    <app-admin-input [labelCols]="1" [label]="'Zoek'" [(model)]="filterString" (inputClick)="filterString = ''"
      [placeholder]="'geef hier eventueel een zoekstring'">
      <span class="input-group-text"><span class="small">{{resultingSessions.length}} /
          {{sessions.length}}</span></span>
    </app-admin-input>
    <div class="row p-2 border-bottom">
      <div class="col-sm-4">Klant</div>
      <div class="col-sm-2">Sessie</div>
      <div class="col-sm-2">Module</div>
      <div class="col-sm-2">Platform</div>
      <div class="col-sm-2">Browser</div>
    </div>
    <div *ngFor="let session of resultingSessions | sortSessions:true; let even=even"
      class="row border-bottom small p-2" [ngClass]="{'alt-back': even}"
      (contextmenu)="mainService.showObjectDialog($event, session)">
      <ng-template #popTemplate>
        <div class="mb-2"><i class="fas fa-exclamation-triangle pe-2"></i><b>Let op!</b></div>
        <div class="mb-2">Deze klant is ingelogd vanaf verschillende lokaties:</div>
        <ul>
          <li *ngFor="let host of getHostnames(session)">{{host}}</li>
        </ul>
      </ng-template>
      <div class="col-sm-4">
        <span>{{session.CustomerName}} <span *ngIf="session.LoginKind===4"><i class="fas fa-user-crown"></i></span>
          <span *ngIf="getTotalsCustomerCount(session)>1" class="badge text-bg-success pointer ms-1"
            tooltip="Dit is het aantal gebruikers ingelogd bij deze klant" placement="top" container="body"
            (click)="filterString=session.CustomerName">{{getTotalsCustomerCount(session)}}</span></span><span *ngIf="session.CustomerNumber" class="text-low">&nbsp;({{session.CustomerNumber}})</span><br />
        <span *ngIf="session.Username" class="text-low">gebruiker: {{session.Username}} <span
            *ngIf="getTotalsUserIDCount(session)>1" class="badge text-bg-warning pointer"
            tooltip="Dit is het aantal gebruikers tegelijk ingelogd met deze login. Het is beter om een aparte login voor iedere gebruiker aan te maken."
            placement="top" container="body" (click)="filterString=session.Username">{{getTotalsUserIDCount(session)}}</span><span *ngIf="ctx && ctx.AdminAccesslevel > 15" class="text-low">&nbsp;({{session.UserID}})</span><br /></span>
        <span class="text-low">{{session.Hostname}}</span> <span *ngIf="getTotalsIPs(session)>1" [popover]="popTemplate"
          placement="top" triggers="mouseenter:mouseleave" containerClass="pop-warning" container="body"
          class="p-2 text-danger"><i class="fas fa-exclamation-triangle"></i></span><br />
        <span class="text-low">{{session.BranchName}} <span class="badge text-bg-secondary pointer"
            tooltip="Dit is het aantal gebruikers ingelogd bij dit filiaal" placement="top"
            container="body" (click)="filterString=session.BranchName">{{getTotalsBranchCount(session)}}</span></span><br />
        <span *ngIf="ctx && ctx.AdminAccesslevel > 15" class="text-low" [popover]="popWholesalerNameInfoTemplate" popoverTitle="Grossiers" triggers="mouseenter:mouseleave" placement="top">{{session.WholesalerName}} <span class="badge text-bg-info pointer"
          tooltip="Dit is het aantal gebruikers ingelogd bij deze grossier" placement="top"
          container="body" (click)="filterString=session.WholesalerName">{{getTotalsWholesalerCount(session)}}</span></span>
      </div>
      <div class="col-sm-2">
        <span>{{ session.SessionStart | date:'HH:mm:ss' }}</span>
        <!-- <span *ngIf="ctx && ctx.AdminAccesslevel > 15" class="pointer ms-1" (click)="showBackend(session)"><i
            class="fad fa-user-secret"></i></span> --><br />
        <ng-container *ngIf="isDayOffset(session.SessionStart, 0); then today; else otherday"></ng-container>
        <ng-template #today><span class="text-low">Vandaag</span><br /></ng-template>
        <ng-template #otherday>
          <ng-container *ngIf="isDayOffset(session.SessionStart, -1); then yesterday; else later"></ng-container>
          <ng-template #yesterday><span class="text-low">Gisteren</span><br /></ng-template>
          <ng-template #later><span class="text-low">{{ session.SessionStart | date:'EEEE d MMMM' }}<br /></span>
          </ng-template>
        </ng-template>
        <div *ngIf="ctx && ctx.AdminAccesslevel > 15" class="text-low">{{ session.LoginID }} - {{
          session.HeartbeatCount}}</div>
        <!-- <div><img *ngIf="session.Ticket?.Kenteken" style="width: 70px;"
            [src]="licensePlateService.getLicensePlateUrl(session.Ticket?.Kenteken) | async" /></div>
        <div><img *ngIf="session.Ticket?.LicensePlate" style="width: 70px;"
            [src]="licensePlateService.getLicensePlateUrl(session.Ticket?.LicensePlate) | async" /></div> -->
        <span *ngIf="ctx && ctx.AdminAccesslevel > 15" class="text-low" [popover]="popFrontendSessionInfoTemplate" popoverTitle="Versies" triggers="mouseenter:mouseleave" placement="left">{{session.FrontendVersion}} <span class="badge text-bg-info pointer" (click)="filterString=session.FrontendVersion">{{frontendVersionTotals[session.FrontendVersion]}}</span></span><br />
        <span *ngIf="ctx && ctx.AdminAccesslevel > 15" class="text-low" [popover]="popEnvironmentInfoTemplate" popoverTitle="Environment" triggers="mouseenter:mouseleave" placement="left">{{session.Environment}} <span class="badge text-bg-success pointer" (click)="filterString=session.Environment">{{environmentTotals[session.Environment]}}</span></span><br />
      </div>
      <div class="col-sm-2">
        <div>{{session.ModuleName}}</div>
      </div>
      <div class="col-sm-2">
        <span *ngIf="session.ThirdPartyCustomerName" (click)="filterString=session.ThirdPartyCustomerName" [popover]="popThirdPartyCustomerNameInfoTemplate" popoverTitle="Koppelingen" triggers="mouseenter:mouseleave" placement="left">{{session.ThirdPartyCustomerName}}<br /></span>
        <span (click)="filterString=session.Platform" [popover]="popPlatformInfoTemplate" popoverTitle="Platform" triggers="mouseenter:mouseleave" placement="left">{{session.Platform}}</span>&nbsp;<span (click)="filterString=session.PlatformVersion">{{session.PlatformVersion}}</span><span *ngIf="session.Device" class="text-low" (click)="filterString=session.Device"> ({{session.Device}})</span><br />
        <span class="text-low" (click)="filterString=session.Brand">{{session.Brand}} {{session.Model}}</span>
      </div>
      <div class="col-sm-2"><span (click)="filterString=session.Browser" [popover]="popBrowserInfoTemplate" popoverTitle="Browsers" triggers="mouseenter:mouseleave" placement="left">{{session.Browser}}</span>&nbsp;<span (click)="filterString=session.BrowserVersion">{{session.BrowserVersion}}</span></div>
    </div>
    <div *ngIf="resultingSessions.length===0 && filterString" class="alert alert-danger mt-3" role="alert">
      <i class="fas fa-exclamation-triangle"></i> Er bleef niets over na het filteren!
    </div>
  </ng-container>
</app-admin-form>
<ng-template #popWholesalerNameInfoTemplate>
  <div *ngFor="let pair of wholesalerNameTotals | keyvalue" class="row">
    <div class="col">{{pair.key}}</div><div class="col text-end fw-bold">{{pair.value}}</div>
  </div>
</ng-template>
<ng-template #popFrontendSessionInfoTemplate>
  <div *ngFor="let pair of frontendVersionTotals | keyvalue" class="row">
    <div class="col">{{pair.key}}</div><div class="col text-end fw-bold">{{pair.value}}</div>
  </div>
</ng-template>
<ng-template #popEnvironmentInfoTemplate>
  <div *ngFor="let pair of environmentTotals | keyvalue" class="row">
    <div class="col text-nowrap">{{pair.key}}</div><div class="col text-end fw-bold text-nowrap">{{pair.value}}</div>
  </div>
</ng-template>
<ng-template #popThirdPartyCustomerNameInfoTemplate>
  <div *ngFor="let pair of thirdPartyCustomerNameTotals | keyvalue" class="row">
    <div class="col text-nowrap">{{pair.key}}</div><div class="col text-end fw-bold text-nowrap">{{pair.value}}</div>
  </div>
</ng-template>
<ng-template #popPlatformInfoTemplate>
  <div *ngFor="let pair of platformTotals | keyvalue" class="row">
    <div class="col text-nowrap">{{pair.key}}</div><div class="col text-end fw-bold text-nowrap">{{pair.value}}</div>
  </div>
</ng-template>
<ng-template #popBrowserInfoTemplate>
  <div *ngFor="let pair of browserTotals | keyvalue" class="row">
    <div class="col text-nowrap">{{pair.key}}</div><div class="col text-end fw-bold text-nowrap">{{pair.value}}</div>
  </div>
</ng-template>