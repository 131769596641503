<app-admin-form *ngIf="loyaltyShop" [title]="'Bonusshop categorie onderhoud'" [object]="loyaltyShop">
  <p *ngIf="!getItems() || getItems().length===0">Op dit moment staan er geen artikelen in het systeem.</p>
  <p *ngIf="getItems().length>0">
    Hier kunnen de artikelen onderhouden worden en kan de volgorde van de artikelen in de bonusshop aangepast worden.
  </p>

  <table class="table table-striped small">
    <thead>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
      <tr>
        <th>Titel</th>
        <th>
          <div class="dropdown">
            <a class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{getCategoryDescription()}}
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item noselect pointer" [ngClass]="{'active': !currentCategoryID}"
                (click)="currentCategoryID=0">Alle categorieën</a>
              <a *ngFor="let category of getCategories() | sortByProperty:'SortOrder'"
                class="dropdown-item noselect pointer" [ngClass]="{'active': currentCategoryID===category.ID}"
                (click)="currentCategoryID=category.ID">{{category.Description}}</a>
            </div>
          </div>
        </th>
        <th class="text-center">Aktief</th>
        <th *ngIf="currentCategoryID" class="text-center">Volgorde</th>
        <th class="text-end">Aktie</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of getItems()">
        <td>{{item.Title}}</td>
        <td>
          <span *ngFor="let category of adminLoyaltyShopService.getCategoriesForItem(item) | async"
            class="badge text-bg-warning me-1">{{category.Description}}</span>
        </td>
        <td class="text-center">
          <div class="custom-control custom-checkbox noselect">
            <input type="checkbox" class="custom-control-input" [id]="'item'+item.ID" [(ngModel)]="item.Active"
              (change)="changedItemActive(item)">
            <label class="custom-control-label" [for]="'item'+item.ID"></label>
          </div>
        </td>
        <td *ngIf="currentCategoryID" class="text-center">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemTop(getItems(), item)"
              (click)="clickUp(item)"><i class="fas fa-chevron-up"></i></button>
            <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemBottom(getItems(), item)"
              (click)="clickDown(item)"><i class="fas fa-chevron-down"></i></button>
          </div>
        </td>
        <td class="text-end">
          <div class="btn-group">
            <button class="btn btn-xs btn-outline-secondary" [routerLink]="['edit',item.ID]"><i class="fas fa-edit"></i>
              Wijzig</button>
            <button class="btn btn-xs btn-outline-secondary"
              (click)="adminLoyaltyShopService.deleteLoyaltyShopItemDialog(item)"><i class="fas fa-trash-alt"></i>
              Verwijder</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" [routerLink]="['new']"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</app-admin-form>