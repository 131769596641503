import { Component, OnInit, Input } from '@angular/core';

import { ContextRimsAndTyres } from '../../../_models/rims-and-tyres/context.rims.and.tyres';
import { Wheel } from '../../../_models/rims-and-tyres/wheel';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-wheel-list',
  templateUrl: './wheel-list.component.html',
  styleUrls: ['./wheel-list.component.scss']
})
export class WheelListComponent implements OnInit {
  _maxItemCount: number;
  @Input() ctx: ContextRimsAndTyres;
  @Input() maxItems: number;
  _wheels: Wheel[];
  get wheels(): Wheel[] { return this._wheels; }
  @Input('wheels')
  set wheels(value: Wheel[]) {
    this._wheels = value;
    this._maxItemCount = this.maxItems;
  }

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
    this._maxItemCount = this.maxItems;
  }

}
