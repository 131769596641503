import { Component, Input, OnInit } from '@angular/core';
import { Part } from '../../../_models/catalog/part';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-catalog-part-document-buttons',
  templateUrl: './catalog-part-document-buttons.component.html',
  styleUrls: ['./catalog-part-document-buttons.component.scss']
})
export class CatalogPartDocumentButtonsComponent implements OnInit {
  @Input() part: Part;
  @Input() button: boolean;
  show = false;

  constructor(
    public catalogService: CatalogService
  ) { }

  ngOnInit(): void {
  }

}
