import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { InterfaceData } from '../../../../../../_models/admin/supplier-interface/interface.data';
import { SupplierInterfaceData } from '../../../../../../_models/admin/supplier-interface/supplier.interface.data';
import { BranchModel } from '../../../../../../_models/common/branch.model';
import { AdminSupplierInterfaceService } from '../../../../../../_services/admin/admin-supplier-interface.service';
import { MainService } from '../../../../../../_services/main.service';
import { InterfaceKindInfoModel } from '../../../../../../_models/admin/supplier-interface/interface.kind.info.model';
import { AddressModel } from '../../../../../../_models/admin/supplier-interface/address.model';
import { SupplierModel } from '../../../../../../_models/common/supplier.model';
import { add } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-default-interface-data-edit',
  templateUrl: './default-interface-data-edit.component.html',
  styleUrls: ['./default-interface-data-edit.component.scss']
})
export class DefaultInterfaceDataEditComponent implements OnInit {
  supplierInterfaceData: SupplierInterfaceData;
  interfaceData: InterfaceData;
  addNew: boolean;
  mainService: MainService;
  adminSupplierInterfaceService: AdminSupplierInterfaceService;
  editInterfaceData: InterfaceData;
  branchModelValues: BranchModel[];
  interfaceKinds: InterfaceKindInfoModel[];
  kindInfo: InterfaceKindInfoModel;

  public onClose: Subject<InterfaceData>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.editInterfaceData = JSON.parse(JSON.stringify(this.interfaceData)) as InterfaceData;
    this.branchModelValues = Object.values(this.supplierInterfaceData.Branches);
    this.interfaceKinds = this.adminSupplierInterfaceService
      .getSortedInterfaceKindInfos(this.supplierInterfaceData.InterfaceKindInfos)
      .filter(interfaceKind => !interfaceKind.Depricated);
    this.kindInfo = this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, this.interfaceData.IntType);
    this.onClose = new Subject();
  }

  getInterfaceKindName(intType: number): string {
    return this.adminSupplierInterfaceService.getInterfaceTypeInfo(this.supplierInterfaceData.InterfaceKindInfos, Math.abs(intType)).Name
  }

  kindChange(interfaceKind: InterfaceKindInfoModel) {
    this.editInterfaceData.IntType = interfaceKind.InterfaceKind;
    this.kindInfo = interfaceKind;
  }

  supplierChange(supplier: SupplierModel) {
    if (!this.supplierInterfaceData.SupplierData[supplier.SupplierNumber]) {
      const address = new AddressModel();
      address.Name = supplier.Name;
      address.StreetAddress = supplier.StreetName;
      address.Number = supplier.HouseNumber;
      address.PostalCode = supplier.PostalCode;
      address.City = supplier.City;
      address.Country = supplier.Country;
      this.supplierInterfaceData.SupplierData[supplier.SupplierNumber] = address;
    }

  }


  public confirm() {
    if (this.adminSupplierInterfaceService.isUnique(this.supplierInterfaceData.InterfaceData, this.interfaceData, this.editInterfaceData)) {
      this.onClose.next(this.editInterfaceData);
      this.bsModalRef.hide();
    }
  }

  public cancel() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

}
