import { Pipe, PipeTransform } from '@angular/core';
import { PartsType } from '../../../_models/catalog/parts.type';
import { SettingsCatalogPartsTypes } from '../../../_models/admin/settings/settings.catalog.parts.types';

@Pipe({
  name: 'filterPartsTypes'
})
export class FilterPartsTypesPipe implements PipeTransform {

  transform(partsTypes: PartsType[], filterString: string): any {
    if (!filterString) { return partsTypes; }
    return partsTypes.filter(partsType => {
      const search = filterString.toLowerCase();
      if (partsType) {
        if (partsType.ID.toString().startsWith(search)
          || partsType.Description.toLowerCase().indexOf(search) >= 0) { return true; }
      }
      return false;
    });
  }

}
