import { Component, OnInit } from '@angular/core';

import { MainService } from '../../../_services/main.service';
import { WheelTyreSet } from '../../../_models/rims-and-tyres/wheel.tyre.set';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContextRimsAndTyres } from '../../../_models/rims-and-tyres/context.rims.and.tyres';
import { DatePipe } from '@angular/common';
import { SelectionItem } from '../../../_models/rims-and-tyres/selection.item';
import { CarTypeService } from '../../../_services/car-type.service';
import { CatalogPropertyService } from '../../../_services/catalog-property.service';

@Component({
  selector: 'app-rims-and-tyres-order-popup',
  templateUrl: './rims-and-tyres-order-popup.component.html',
  styleUrls: ['./rims-and-tyres-order-popup.component.scss']
})
export class RimsAndTyresOrderPopupComponent implements OnInit {
  ctx: ContextRimsAndTyres;
  set: WheelTyreSet;
  mainService: MainService;
  months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
  years: number[] = [];

  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
    private datePipe: DatePipe,
    public carTypeService: CarTypeService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    const year = new Date().getFullYear();
    for (let i = year; i > year - 20; i--) { this.years.push(i); }
  }

  splitProperty(property: string): string[] {
    if (property) {
      const regex = /(.*): ?(.*)/g;
      const match = regex.exec(property);
      if (match && match.length > 1) { return [match[1], match[2]]; }
    }
    return ['', property];
  }

  displayItem(item: SelectionItem): boolean {
    if (!this.set.Tpms && item.Properties['OnlyTPMS']) { return false; }
    if (!this.set.CartItemTyre && item.Properties['DisplayChoice'] === 1) { return false; }
    if (this.set.CartItemTyre && item.Properties['DisplayChoice'] === 2) { return false; }
    return true;
  }

  selectionItemCount(item: SelectionItem): number {
    if (item.Aantal) { return item.Aantal; }
    return this.set.RequestItemCount;
  }

  priceSingleSelectionItem(item: SelectionItem, priceField: string): number {
    if (item.Properties['ItemInfo']) {
      return item.Properties['ItemInfo'].Price[priceField];
    }
    return 0;
  }

  priceSelectionItem(item: SelectionItem, priceField: string): number {
    if (item.Properties['IsSelected'] && item.Properties['ItemInfo'] && this.displayItem(item)) {
      return item.Properties['ItemInfo'].Price[priceField] * this.selectionItemCount(item);
    }
    return 0;
  }

  totalSelectionItems(priceField: string): number {
    let price = 0;
    if (this.set.SelectionItems) {
      this.set.SelectionItems.forEach((item) => {
        price += this.priceSelectionItem(item, priceField);
      });
    }
    return price;
  }

  totalPrice(priceField: string): number {
    if (this.set && this.set.CartItemWheel) {
      let price = this.totalSelectionItems(priceField);
      if (priceField === 'GrossPrice') {
        price += this.set.RequestItemCount * (this.set.CartItemWheel.Brutoprijs);
        if (this.set.CartItemTyre) { price += this.set.RequestItemCount * (this.set.CartItemTyre.Brutoprijs); }
      } else {
        price += this.set.RequestItemCount * (this.set.CartItemWheel.Nettoprijs);
        if (this.set.CartItemTyre) { price += this.set.RequestItemCount * (this.set.CartItemTyre.Nettoprijs); }
      }
      return price;
    }
    return 0;
  }


  confirm() {
    if (this.set.Tpms && (!this.set.BuildMonth || !this.set.BuildYear)) {
      this.mainService.msgBox(
        'Let op!', 'Er moet voor de programmering van de TPMS ventielen een bouwmaand + bouwjaar geselecteerd worden.'
      );
    } else {
      this.set.Ok = true;
      this.onClose.next(true);
      this.bsModalRef.hide();
    }
  }

  decline() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
