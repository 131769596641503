import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { ShopSoort } from '../../_models/common/shop.soort';
import { ContextThirdPartyFrame } from '../../_models/thirdparty-shop/context.third.party.frame';
import { ApiService } from '../../_services/api.service';
import { CarTypeService } from '../../_services/car-type.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyFrameContextResolverService  {

  constructor(
    private apiService: ApiService,
    private carTypeService: CarTypeService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContextThirdPartyFrame> | Observable<never> {
    const shopKind = route.data['shop'] as ShopSoort;
    const licensePlate = this.carTypeService.currentCarType?.LicensePlate.Bare;
    const kType = this.carTypeService.currentCarType?.Ktypes[0];

    return this.apiService.getContextThirdPartyFrame(shopKind, licensePlate, kType).pipe(
      take(1),
      mergeMap(ctx => {
        if (ctx) {
          if (ctx.NewWindow) {
            window.open(ctx.Url, '_blank');
            return EMPTY;
          } else {
            return of(ctx);
          }
        } else { // no data?
          return EMPTY;
        }
      })
    );

  }

}
