import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PartsType } from '../../_models/catalog/parts.type';


@Injectable({
  providedIn: 'root'
})
export class AdminCatalogService {

  constructor() { }

  getCatalogPartsTypeIdsFromCross(cross: { [key: number]: { [key: string]: number[] } }, catalog: string, partsTypeId: number): number[] {
    if (cross[partsTypeId] && cross[partsTypeId][catalog] && cross[partsTypeId][catalog].length > 0) {
      return cross[partsTypeId][catalog];
    }
    return null;
  }

  setCatalogPartsType(partsTypes: { [key: string]: { [key: number]: PartsType } }, catalog: string, catalogPartsType: PartsType) {
    if (!partsTypes[catalog]) { partsTypes[catalog] = {}; }
    partsTypes[catalog][catalogPartsType.ID] = catalogPartsType;
  }

  deleteCatalogPartsType(partsTypes: { [key: string]: { [key: number]: PartsType } }, catalog: string, catalogPartsTypeId: number) {
    if (partsTypes[catalog] && partsTypes[catalog][catalogPartsTypeId]) {
      delete partsTypes[catalog][catalogPartsTypeId];
    }
  }

  setCatalogPartsTypeCross(cross: { [key: number]: { [key: string]: number[] } }, catalog: string
    , partsTypeId: number, catalogPartsType: PartsType) {
    if (!cross[partsTypeId]) { cross[partsTypeId] = {}; }
    if (!cross[partsTypeId][catalog]) { cross[partsTypeId][catalog] = []; }
    cross[partsTypeId][catalog].push(catalogPartsType.ID);
  }

  deleteCatalogPartsTypeCross(cross: { [key: number]: { [key: string]: number[] } }
    , catalog: string, partsTypeId: number, catalogPartsTypeId: number) {
    if (cross[partsTypeId] && cross[partsTypeId][catalog] && cross[partsTypeId][catalog]
      && cross[partsTypeId][catalog].includes(catalogPartsTypeId)) {
      const index = cross[partsTypeId][catalog].indexOf(catalogPartsTypeId, 0);
      if (index > -1) {
        cross[partsTypeId][catalog].splice(index, 1);
      }
    }
  }

  getTypeaheadPartsTypes(learnedPartsTypes: { [key: string]: { [key: number]: PartsType[] } }
    , cross: { [key: number]: { [key: string]: number[] } }, catalog: string, partsTypeId: number
    , search: string, maxItems = 10, hasFocus: boolean): Observable<PartsType[]> {
    if (hasFocus && search && learnedPartsTypes && learnedPartsTypes[catalog]) {
      const alreadyCrossed = this.getCatalogPartsTypeIdsFromCross(cross, catalog, partsTypeId);
      const lsearch = search.toLowerCase();
      const result = Object.keys(learnedPartsTypes[catalog])
        .map(id => {
          const partsType = new PartsType();
          partsType.ID = +id;

          const hit = learnedPartsTypes[catalog][partsType.ID]
            .find(p => p.Description.toLowerCase().indexOf(lsearch) !== -1 && (!alreadyCrossed || !alreadyCrossed.includes(p.ID)));

          if (hit) {
            partsType.Origin = hit.Origin;
            partsType.ID = hit.ID;
            const ptlist: PartsType[] = [];
            learnedPartsTypes[catalog][partsType.ID].forEach(p => {
              const pt = new PartsType();
              pt.Description = p.Description;
              pt.Score = p.Score;
              if (p.Description === search) {
                pt.Score += 40000000;
              } else if (p.Description.toLowerCase() === lsearch) {
                pt.Score += 30000000;
              } else if (p.Description.toLowerCase().startsWith(lsearch)) {
                pt.Score += 20000000;
              } else if (p.Description.toLowerCase().indexOf(lsearch) !== -1) {
                pt.Score += 10000000;
              }
              ptlist.push(pt);
            });
            ptlist.sort((p1, p2) => p2.Score - p1.Score);
            const first = ptlist.shift();
            partsType.Description = first.Description;
            partsType.Score = first.Score;
            partsType.DescriptionOriginal = ptlist.map(p => p.Description).join(', ');
          }

          return partsType;
        })
        .filter(i => i.Score > 0)
        .sort((p1, p2) => p2.Score - p1.Score)
        .slice(0, maxItems);


      return of(result);
    } else {
      return of([]);
    }
  }


}
