import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { ShopOrder } from '../../../_models/shoporders/shop.order';
import { CarTypeService } from '../../../_services/car-type.service';
import { LicensePlateService } from '../../../_services/license-plate.service';

@Component({
  selector: 'app-order-history-details',
  templateUrl: './order-history-details.component.html',
  styleUrls: ['./order-history-details.component.scss']
})
export class OrderHistoryDetailsComponent implements OnInit {

  orderId: number;
  order: ShopOrder;
  isBusy: boolean;

  constructor(
    private route: ActivatedRoute,
    public customerInformationService: CustomerInformationService,
    public mainService: MainService,
    public sortService: SortService,
    public carTypeService: CarTypeService,
    public licensePlateService: LicensePlateService
  ) { }

  ngOnInit() {
    this.sortService.initSortField('order-history-regels', 'LineNumber', false);
    this.route.params.subscribe(params => {
      if (params && params.id) {
        this.orderId = params.id;
        this.isBusy = true;

        this.customerInformationService.getShopOrder(this.orderId).subscribe(order => {
          this.isBusy = false;
          this.order = order;
          console.info(order);
        }, error => {
          this.isBusy = false;
          if (error.status === 401) { this.customerInformationService.gotoOrderHistory(); }
          console.error(error);
        });
      }
    });
  }

  getSortedItems(): ShopOrder[] {
    if (this.order) { return this.sortService.sortObject('order-history-regels', this.order.Orderlines); }
    return [];
  }
}
