<div *ngIf="ctx" class="card settings-list">
  <div class="card-header text-bg-primary">
    Instellingen
  </div>
  <div class="card-body">
    <div class="list-group">
      <button *ngFor="let group of ctx.SettingGroups" type="button" [routerLink]="['group', group.Id]" class="list-group-item list-group-item-action">
        {{group.Description}}
      </button>
    </div>
  </div>
</div>
