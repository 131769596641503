import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartItemsResponse } from '../../_models/cart/cart.items.response';
import { ShopSoort } from '../../_models/common/shop.soort';
import { TimingObject } from '../../_models/common/timing.object';
import { ContextMpm } from '../../_models/mpm/context.mpm';
import { MpmCapacity } from '../../_models/mpm/mpm.capacity';
import { MpmPart } from '../../_models/mpm/mpm.part';
import { MpmPartsCategory } from '../../_models/mpm/mpm.parts.category';
import { CartService } from '../../_services/cart.service';

@Component({
  selector: 'app-mpm',
  templateUrl: './mpm.component.html',
  styleUrls: ['./mpm.component.scss']
})
export class MpmComponent implements OnInit {
  shopKind = ShopSoort;
  ctx: ContextMpm;
  currentMainCat: MpmPartsCategory;
  currentCat: MpmPartsCategory;
  currentPart: MpmPart;

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
        this.currentMainCat = this.ctx.Categories[0];
        this.currentCat = this.currentMainCat.SubPartsCategories[0];
        this.currentPart = this.currentCat.Parts[0];
        const response = new CartItemsResponse();
        response.CartItems = this.ctx.CartItems;
        response.Timing = this.ctx.Timing;
        this.cartService.updateSupplierAvailabilityCartItemsDictionary(response, this.ctx);
      });
  }

  chooseCats(mainCategory: MpmPartsCategory, category: MpmPartsCategory) {
    this.currentMainCat = mainCategory;
    this.currentCat = category;
    this.currentPart = category?.Parts[0];
  }

  getProductkind(part: MpmPart): string {
    if (part.Kind === 0) {
      return 'Productaanbeveling';
    } else if (part.Kind === 1) {
      return 'Product alternatief';
    } else if (part.Kind === 2) {
      return 'Petronas alternatief';
    }
  }


}
