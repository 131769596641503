<app-admin-form [title]="'Winkelwagen instellingen'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">
  <app-admin-checkbox [label]="adminSettingsService.getAdminSetting(settings,'GeenRoutetijdenKeuzeInShoppingCart').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'GeenRoutetijdenKeuzeInShoppingCart').Value"></app-admin-checkbox>

  <app-admin-custom-control *ngIf="adminSettingsService.getAdminSetting(settings,'GeenRoutetijdenKeuzeInShoppingCart').Value" [label]="'Eigen bezorg/afhaal keuzes'">
    <table *ngIf="settings.AfleverKeuzes.length>0" class="table table-striped table-responsive small">
      <thead>
        <tr>
          <th>Keuze omschrijving</th>
          <th class="text-center">Laat zien van/tot</th>
          <th class="text-center">Laat zien bij</th>
          <th class="text-center">Volgorde</th>
          <th class="text-end">Aktie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let choice of settings.AfleverKeuzes | sortByProperty:'SortOrder'">
          <td>{{choice.ChoiceDescription}}</td>
          <td class="text-center">{{getShowTime(choice)}}</td>
          <td class="text-center">{{getShowKind(choice)}}</td>
          <td class="text-center">
            <div class="btn-group">
              <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemTop(settings.AfleverKeuzes, choice, 'SortOrder')"
                (click)="mainService.moveItemUp(settings.AfleverKeuzes, choice, 'SortOrder')"><i class="fas fa-chevron-up"></i></button>
              <button class="btn btn-xs btn-outline-secondary" [disabled]="mainService.isItemBottom(settings.AfleverKeuzes, choice, 'SortOrder')"
                (click)="mainService.moveItemDown(settings.AfleverKeuzes, choice, 'SortOrder')"><i class="fas fa-chevron-down"></i></button>
            </div>
          </td>
          <td class="text-end">
            <div class="btn-group">
              <button class="btn btn-xs btn-outline-secondary" (click)="adminService.editDeliveryChoice(settings.AfleverKeuzes, choice)"><i class="fas fa-edit"></i>
                Wijzig</button>
              <button class="btn btn-xs btn-outline-secondary" (click)="adminService.deleteDeliveryChoice(settings.AfleverKeuzes, choice)"><i class="fas fa-trash-alt"></i>
                Verwijder</button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-end" colspan="7">
            <div class="btn-group">
              <button class="btn-primary btn btn-xs" (click)="adminService.editDeliveryChoice(settings.AfleverKeuzes, null)"><i class="fa fa-plus"></i> Afleverkeuze Toevoegen</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button *ngIf="settings.AfleverKeuzes.length==0" class="btn-primary btn" (click)="adminService.editDeliveryChoice(settings.AfleverKeuzes, null)"><i class="fa fa-plus"></i> Afleverkeuze Toevoegen</button>
  </app-admin-custom-control>
  <app-admin-checkbox *ngIf="!adminSettingsService.getAdminSetting(settings,'GeenRoutetijdenKeuzeInShoppingCart').Value" [label]="adminSettingsService.getAdminSetting(settings,'RouteDescriptionsInShoppingCart').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'RouteDescriptionsInShoppingCart').Value"></app-admin-checkbox>
  <app-admin-dropdown *ngIf="!adminSettingsService.getAdminSetting(settings,'GeenRoutetijdenKeuzeInShoppingCart').Value" [label]="adminSettingsService.getAdminSetting(settings,'MaxAantalRoutesCart').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'MaxAantalRoutesCart').Value" [modelValues]="[1, 2, 3, 4, 5, 6, 7, 8, 9]"></app-admin-dropdown>
  <app-admin-checkbox-input [label]="adminSettingsService.getAdminSetting(settings,'TekstOpOrderWanneerOnbekendeLevertijdEnabled').Description"
    [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'TekstOpOrderWanneerOnbekendeLevertijdEnabled').Value" [(modelInput)]="adminSettingsService.getAdminSetting(settings,'TekstOpOrderWanneerOnbekendeLevertijd').Value"
    [placeholder]="'Tekst voor op de order...'"></app-admin-checkbox-input>
  <app-admin-checkbox [label]="adminSettingsService.getAdminSetting(settings,'TimeStampAlsTekstregelInOrder').Description" [(model)]="adminSettingsService.getAdminSetting(settings,'TimeStampAlsTekstregelInOrder').Value"></app-admin-checkbox>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsCart(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>
