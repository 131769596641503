<div *ngIf="sci" class="input-group item-count pe-3" [ngClass]="{'mx-auto': center}">
  <input type="text" [ngModel]="cartService.getShoppingCartItemCountFromCart(sci)" class="form-control" maxlength="4"
    (ngModelChange)="cartService.setShoppingCartItemCount(sci, $event, getPerQuantity())" appNumericOnly
    (contextmenu)="mainService.showObject($event, sci)">
  <div class="input-group-btn-vertical-spinner">
    <button class="btn btn-outline-secondary" type="button"
      (click)="cartService.changeShoppingCartItemCount(sci, getPerQuantity())">
      <i class="fas fa-caret-up"></i>
    </button>
    <button class="btn btn-outline-secondary" type="button"
      (click)="cartService.changeShoppingCartItemCount(sci, getPerQuantity() * -1)">
      <i class="fas fa-caret-down"></i>
    </button>
  </div>
</div>