import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ContextCarTypeSelection } from '../../../_models/car-types/context.car.type.selection';
import { UniversalCarBrand } from '../../../_models/car-types/universal.car.brand';
import { CarTypeService } from '../../../_services/car-type.service';
import { ApiService } from '../../../_services/api.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { CarModel } from '../../../_models/car-types/car.model';
import { CarType } from '../../../_models/car-types/car.type';
import { MainService } from '../../../_services/main.service';
import { CarTypeResult } from '../../../_models/car-types/car.type.result';
import { CatalogPropertyService } from '../../../_services/catalog-property.service';

@Component({
  selector: 'app-brand-model-type-search',
  templateUrl: './brand-model-type-search.component.html',
  styleUrls: ['./brand-model-type-search.component.scss']
})
export class BrandModelTypeSearchComponent implements OnInit {
  @Input() ctxCarTypeSelection: ContextCarTypeSelection;
  @Input() shopSoort: ShopSoort;
  @Output() carTypeSelected: EventEmitter<CarTypeResult> = new EventEmitter();
  selectedBrand: UniversalCarBrand;
  selectedModel: CarModel;
  selectedCarType: CarType;
  models: CarModel[];
  carTypes: CarType[];
  carTypeResult: CarTypeResult;
  filterBrand: string;
  filterModel: string;
  filterType: string;


  constructor(
    public mainService: MainService,
    public apiService: ApiService,
    public carTypeService: CarTypeService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
  }

  resetFilters() {
    this.filterBrand = '';
    this.filterModel = '';
    this.filterType = '';
  }

  chooseBrand(brand: UniversalCarBrand): void {
    if (this.selectedBrand !== brand) {
      this.selectedBrand = brand;
      this.selectedModel = null;
      this.selectedCarType = null;
      this.models = null;
      this.carTypes = null;
      this.resetFilters();
      this.apiService.getCatalogCarModels(this.shopSoort, brand)
        .subscribe(model => {
          this.models = model.CatalogCarModels[Object.keys(model.CatalogCarModels)[0]];
        });
    }
  }

  getSelectedModel(defaultText: string): string {
    if (this.selectedModel) {
      return this.selectedModel.ModelName;
    }
    return defaultText;
  }

  getSelectedModelDuration(): string {
    if (this.selectedModel) {
      return this.selectedModel.AvailableFrom.Display + '-' + this.selectedModel.AvailableUntil.Display;
    }
    return null;
  }

  chooseModel(model: CarModel): void {
    if (this.selectedModel !== model) {
      this.selectedModel = model;
      this.selectedCarType = null;
      this.carTypes = null;
      this.resetFilters();
      this.apiService.getCatalogCarTypes(this.shopSoort, this.carTypeService.getUniversalCarModelFromCarModel(this.selectedBrand, model))
        .subscribe(result => {
          this.carTypeResult = result;
          this.carTypes = result.CarType.CatalogCarTypes[Object.keys(result.CarType.CatalogCarTypes)[0]];
        });
    }
  }

  getSelectedCarType(defaultText: string): string {
    if (this.selectedCarType) {
      return this.selectedCarType.TypeName + ' ' + this.selectedCarType.TypeRemark;
    }
    return defaultText;
  }

  getSelectedCarTypeDuration(): string {
    if (this.selectedCarType) {
      return this.selectedCarType.AvailableFrom.Display + '-' + this.selectedCarType.AvailableUntil.Display;
    }
    return null;
  }

  chooseCarType(carType: CarType): void {
    if (this.selectedCarType !== carType) {
      this.resetFilters();
      this.selectedCarType = carType;
      this.carTypeResult.CarType.CatalogCarTypes[Object.keys(this.carTypeResult.CarType.CatalogCarTypes)[0]] = [carType];
      this.carTypeSelected.emit(this.carTypeResult);
    }
  }

}
