import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SettingsLoyaltyShop } from '../../../_models/admin/settings/settings.loyalty.shop';
import { CustomerModel } from '../../../_models/common/customer.model';
import { AdminService } from '../../../_services/admin/admin.service';
import { ShopOrder } from '../../../_models/shoporders/shop.order';
import { CarTypeService } from '../../../_services/car-type.service';
import { CreditsInfo } from '../../../_models/loyalty-shop/credits.info';
import { MainService } from '../../../_services/main.service';
import { AdjustLoyaltyShopCreditsRequest } from '../../../_models/loyalty-shop/adjust.loyalty.shop.credits.request';
import { ToasterService } from '../../../_services/toaster.service';
import { LicensePlateService } from '../../../_services/license-plate.service';

@Component({
  selector: 'app-admin-loyalty-shop-admin',
  templateUrl: './admin-loyalty-shop-admin.component.html',
  styleUrls: ['./admin-loyalty-shop-admin.component.scss']
})
export class AdminLoyaltyShopAdminComponent implements OnInit {
  request = new AdjustLoyaltyShopCreditsRequest();
  settings: SettingsLoyaltyShop;
  modelCustomer: number;
  searchingCustomer: boolean;
  inputCustomer: string;
  focusInputCustomer = new EventEmitter<boolean>();
  customer: CustomerModel;
  modelCustomerLast: number;
  modelOrder: number;
  searchingOrder: boolean;
  inputOrder: string;
  focusInputOrder = new EventEmitter<boolean>();
  order: ShopOrder;
  modelOrderLast: number;
  busySaving = false;
  onlyLoyaltyShopOrders: boolean = false;
  creditsInfo: CreditsInfo;
  noOrdersFound: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public carTypeService: CarTypeService,
    public licensePlateService: LicensePlateService,
    private mainService: MainService,
    private toasterService: ToasterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.enableCustomerSearch();
      });
  }

  enableCustomerSearch(): void {
    this.inputCustomer = '';
    this.customer = null;
    this.creditsInfo = null;
    this.inputOrder = '';
    this.order = null;
    this.request.Customer = 0;
    this.request.Credits = 0;
    this.request.OrderId = 0;
    this.searchingOrder = false;
    this.searchingCustomer = true;
    this.noOrdersFound = false;
    setTimeout(() => {
      this.focusInputCustomer.emit(true);
    }, 0);
  }

  enableOrderSearch(): void {
    if (this.customer) {
      this.searchingOrder = true;
      setTimeout(() => {
        this.focusInputOrder.emit(true);
      }, 0);
    }
  }

  getCustomer(wholesaler: number, customerNumber: number): Observable<boolean> {
    return this.adminService.getCustomer(wholesaler, customerNumber)
      .pipe(mergeMap(customer => {
        if (customer) {
          this.customer = customer;
          this.request.Customer = customer.CustomerNumber;
        } else {
          console.warn(`Geen klant ontvangen voor ${customerNumber}`);
        }
        this.adminService.getLoyaltyShopCreditsInfoCustomer(customerNumber)
          .subscribe(info => this.creditsInfo = info);
        this.adminService.getLastShopOrders(customerNumber, 1)
          .subscribe(order => {
            if (order.length) { this.noOrdersFound = false; }
            else { this.noOrdersFound = true; }
          });
        this.searchingCustomer = false;
        this.enableOrderSearch();
        return of(true);
      }));
  }

  getOrder(wholesaler: number, orderId: number): Observable<boolean> {
    return this.adminService.getShopOrder(wholesaler, orderId)
      .pipe(mergeMap(order => {
        if (order) {
          this.order = order;
          this.request.Credits = (this.order.CreditsReceived - this.order.CreditsOrderAmount) * -1;
          this.request.OrderId = this.order.OrderId;
        } else {
          console.warn(`Geen order ontvangen voor ${orderId}`);
        }
        this.searchingOrder = false;
        // this.modelChange.emit(true);
        return of(true);
      }));
  }

  customerChange(value: CustomerModel) {
    if (value.CustomerNumber !== this.modelCustomerLast) {
      this.resetSelectorCustomer(value.CustomerNumber);
      this.getCustomer(this.settings.UID.Wholesaler, value.CustomerNumber).subscribe();
    }
    this.modelCustomerLast = this.modelCustomer;
  }

  orderChange(value: ShopOrder) {
    if (value.OrderId !== this.modelOrderLast) {
      this.resetSelectorOrder(value.OrderId);
      this.getOrder(this.settings.UID.Wholesaler, value.OrderId).subscribe();
    }
    this.modelCustomerLast = this.modelCustomer;
  }

  customerBlur() {
    if (!this.inputCustomer) {
      this.searchingCustomer = false;
    }
  }

  orderBlur() {
    if (!this.inputOrder) {
      this.searchingOrder = false;
    }
  }

  resetCustomerSearch() {
    if (this.modelCustomer !== 0) {
      this.customer = null;
      this.resetSelectorCustomer(0);
      // this.modelChange.emit(true);
    }
    this.searchingCustomer = false;
  }

  resetOrderSearch() {
    if (this.modelOrder !== 0) {
      this.order = null;
      this.resetSelectorOrder(0);
      // this.modelChange.emit(true);
    }
    this.searchingCustomer = false;
  }

  resetSelectorCustomer(value: number) {
    this.modelCustomer = value;
    // this.modelCustomerChange.emit(value);
  }

  resetSelectorOrder(value: number) {
    this.modelOrder = value;
    // this.modelCustomerChange.emit(value);
  }

  getDisabled(): boolean {
    return !this.request.Remarks || !this.request.Credits;
  }

  editCredits(event: MouseEvent) {
    if (!this.request.Remarks || !this.request.Credits) return;
    const meerMinder = this.request.Credits < 0 ? 'minder' : 'extra';
    const absoluut = Math.abs(this.request.Credits);
    this.mainService.confirmBox(`Weet u zeker dat u de het saldo van ${this.creditsInfo.Credits} ${this.settings.NameOfPointsLoyaltyShop} van ${this.customer.Name} wilt aanpassen met ${absoluut} ${this.settings.NameOfPointsLoyaltyShop} ${meerMinder} naar ${this.creditsInfo.Credits - (this.request.Credits * -1)} ${this.settings.NameOfPointsLoyaltyShop}?`)
      .subscribe(ja => {
        if (ja) {
          this.busySaving = true;
          this.adminService.adjustLoyaltyShopCredits(this.request)
            .subscribe(response => {
              this.busySaving = false;
              if (response && response.NewSaldo > 0) {
                this.toasterService.showToast('Succes!', response.Remarks + ` Het nieuwe saldo is ${response.NewSaldo} ${this.settings.NameOfPointsLoyaltyShop}.`);
                this.router.navigate(['/admin']);
              } else {
                this.mainService.msgBox('Let op!', 'Er ging iets fout bij het muteren. ' + response?.Remarks);
              }
            });
        }
      });
  }

}
