import { Wheel } from './wheel';
import { ShoppingCartItem } from '../cart/shopping.cart.item';
import { SelectionItem } from './selection.item';
import { UniversalCarType } from '../car-types/universal.car.type';

export class WheelTyreSet {
    Ok: boolean;
    Tpms: boolean;
    BuildMonth: number;
    BuildYear: number;
    RequestItemCountsPossible: number[];
    RequestItemCount: number;
    Wheel: Wheel;
    CartItemWheel: ShoppingCartItem;
    CartItemTyre: ShoppingCartItem;
    SelectionItems: SelectionItem[];
    CarType: UniversalCarType;

    static getCloneWithoutItemInfo(wts: WheelTyreSet) {
        const c = new WheelTyreSet();
        c.Ok = wts.Ok;
        c.Tpms = wts.Tpms;
        c.BuildMonth = wts.BuildMonth;
        c.BuildYear = wts.BuildYear;
        c.RequestItemCountsPossible = wts.RequestItemCountsPossible;
        c.RequestItemCount = wts.RequestItemCount;
        c.Wheel = wts.Wheel;
        c.CartItemWheel = ShoppingCartItem.getCloneWithoutItemInfo(wts.CartItemWheel);
        if (wts.CartItemTyre) c.CartItemTyre = ShoppingCartItem.getCloneWithoutItemInfo(wts.CartItemTyre);
        c.SelectionItems = wts.SelectionItems;
        c.CarType = wts.CarType;
        return c;
    }
}
