import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input } from '@angular/core';
import { ContextPromotions } from '../../../../_models/promotions/context.promotions';

import { ViewKindInfo } from '../../../../_models/common/view.kind.info';
import { ViewKind } from '../../../../_models/common/view.kind';
import { MainService } from '../../../../_services/main.service';
import { ResizeInfo } from '../../../../_models/common/resize.info';


@Component({
  selector: 'app-promotions-overview-boxed-layout-component',
  templateUrl: './promotions-overview-boxed-layout-component.component.html',
  styleUrls: ['./promotions-overview-boxed-layout-component.component.scss']
})
export class PromotionsOverviewBoxedLayoutComponentComponent implements OnInit {
  @Input() ctx: ContextPromotions;
  viewKinds: ViewKindInfo[] = [
    { kind: ViewKind.Card, icon: 'fas fa-th' },
    // { kind: ViewKind.Detail, icon: 'fas fa-th-list' },
    { kind: ViewKind.Carousel, icon: 'fas fa-arrows-alt-h' }
  ];

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
  }


}
