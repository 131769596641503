import { Component, OnInit, Input } from '@angular/core';

import { ContextMain } from '../../_models/common/context.main';
import { MainService } from '../../_services/main.service';
import { CartService } from '../../_services/cart.service';
import { ShopService } from '../../_services/shop.service';
import { AuthService } from '../../_services/auth.service';
import { ShopSoort } from '../../_models/common/shop.soort';

@Component({
  selector: 'app-header-pro-parts',
  templateUrl: './header-pro-parts.component.html',
  styleUrls: ['./header-pro-parts.component.scss']
})
export class HeaderProPartsComponent implements OnInit {
  @Input() ctx: ContextMain;
  shopSoort = ShopSoort;

  constructor(
    public mainService: MainService,
    public cartService: CartService,
    public shopService: ShopService,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

}
