<div class="row">
    <div class="col-md-5 col-lg-4 col-xl-3">
      <div class="row my-3">
        <div class="col-8 part-description">{{part.Brand}} {{part.Description}}</div>
        <div class="col-4 text-center part-item-number" (contextmenu)="mainService.showObject($event, part)">
          <app-part-item-number-link [partItemNumber]="part.PartItemNumber" [shopKind]="ctx.ShopKind"></app-part-item-number-link>
        </div>
      </div>
      <div class="row">
        <div class="col-8 part-item-info">
          <app-item-info-template [busy]="ctx['busy']" [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)" [nettoprijs]="ctx.Nettoprijs" [part]="part"></app-item-info-template>
        </div>
        <div class="col-4 text-center">
          <app-item-count *ngIf="!part.HasSubParts" [center]="true" [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"></app-item-count>
        </div>
      </div>
      <div class="row">
        <div class="col my-3">
          <span *ngIf="part.DescriptionLong" class="ps-1 pointer text-primary" (click)="mainService.msgBox('Extra informatie',part.DescriptionLong)" ><i class="fad fa-file-alt"></i></span>
          <app-catalog-parts-properties [partsProperties]="ctx.PartsProperties" [partsPropertyTranslations]="ctx.PartsPropertyTranslations" [shopKind]="ctx.ShopKind" [part]="part" [collapsedItemCount]="20"></app-catalog-parts-properties>
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 col-xl-9">
      <div class="row">
        <div class="col">
          <div class="part-image-box" [ngStyle]="getImageBoxStyle(part)"></div>
        </div>
      </div>
      <div *ngIf="catalogService.partHasImage(ctx, part)" class="row">
        <div class="col py-2">
          <div class="thumbnail-container">
            <img *ngFor="let thumb of catalogService.partGetImages(ctx, part); let index = index;" (click)="currentImage = index" class="img-thumbnail m-1"
              [ngClass]="{'border-primary': currentImage === index}" [src]="thumb" />
          </div>
        </div>
      </div>
    </div>
  </div>