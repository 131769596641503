import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsLoyaltyShop } from '../../../_models/admin/settings/settings.loyalty.shop';
import { CreditsInfo } from '../../../_models/loyalty-shop/credits.info';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-loyalty-shop-summary',
  templateUrl: './admin-loyalty-shop-summary.component.html',
  styleUrls: ['./admin-loyalty-shop-summary.component.scss']
})
export class AdminLoyaltyShopSummaryComponent implements OnInit {
  settings: SettingsLoyaltyShop;
  creditsInfos: CreditsInfo[];
  filterString = '';


  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.adminService.getLoyaltyShopCreditsInfo()
          .subscribe(info => this.creditsInfos = info);
      });
  }

}
