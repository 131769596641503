import { Component, OnInit, Input } from '@angular/core';
import { ShopModuleMenuItem } from '../../../_models/common/shop.module.menu.item';
import { Animations } from '../../../_animations/animations';
import { ShopService } from '../../../_services/shop.service';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-side-menu-default-button',
  templateUrl: './side-menu-default-button.component.html',
  styleUrls: ['./side-menu-default-button.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class SideMenuDefaultButtonComponent implements OnInit {
  @Input() menuItem: ShopModuleMenuItem;

  constructor(
    private shopService: ShopService,
    private mainService: MainService
  ) { }

  ngOnInit() {
  }

  clickMenuItem(menuItem: ShopModuleMenuItem): void {
    if (menuItem.MenuItems) {
      menuItem['uncollapsed'] = !menuItem['uncollapsed'];
    } else {
      this.mainService.toggleMenuFloating(false);
      this.shopService.gotoShopModule(this.mainService.ctxMain, menuItem.ShopKind);
    }
  }

}
