import { Component, Input, OnInit } from '@angular/core';
import { AdminSetting } from '../../../_models/admin/settings/admin.setting';
import { AdminSettingKind } from '../../../_models/admin/settings/admin.setting.kind';

@Component({
  selector: 'app-admin-admin-setting',
  templateUrl: './admin-admin-setting.component.html',
  styleUrls: ['./admin-admin-setting.component.scss']
})
export class AdminAdminSettingComponent implements OnInit {
  @Input() adminSetting: AdminSetting;
  @Input() adminSetting2: AdminSetting;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  adminSettingKind = AdminSettingKind;

  constructor() { }

  ngOnInit(): void {
    const ff = this.adminSetting;
  }

}
