<app-admin-form [title]="'Beschikbaarheidstemplate extra meldingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event" [saveAction]="adminSettingsService.saveSettingsExtraMessages(settings)"
  (beforeSaveAction)="beforeSave()">

  <app-admin-custom-control [label]="'Meldingen hoofdleveranciers'">
    <ng-container afterLabel><br /><button type="button" class="btn btn-primary mt-2"
        (click)="addId('ExtraMessage_MainSuppliers', 'ExtraMessage_MainSupplier_', 0)"><i class="fas fa-plus"></i>
      </button></ng-container>
    <div *ngFor="let supplier of getIds('ExtraMessage_MainSuppliers')">
      <app-extra-message-main-supplier [enabled]="getIdEnabled('ExtraMessage_MainSuppliers', supplier)"
        (enabledChange)="setIdEnabled('ExtraMessage_MainSuppliers', supplier, $event)" [supplier]="supplier"
        (supplierChange)="changeId('ExtraMessage_MainSuppliers', 'ExtraMessage_MainSupplier_', supplier,$event)"
        [(message)]="adminSettingsService.getAdminSetting(settings,'ExtraMessage_MainSupplier_'+supplier).Value"
        (deleted)="deleteId('ExtraMessage_MainSuppliers', 'ExtraMessage_MainSupplier_', $event)"></app-extra-message-main-supplier>
    </div>
  </app-admin-custom-control>

  <app-admin-custom-control [label]="'Meldingen artikelgroepen'">
    <ng-container afterLabel><br /><button type="button" class="btn btn-primary mt-2"
        (click)="addId('ExtraMessage_ItemGroups', 'ExtraMessage_ItemGroup_', 0)"><i class="fas fa-plus"></i>
      </button></ng-container>
    <div *ngFor="let group of getIds('ExtraMessage_ItemGroups')">
      <app-extra-message-item-group [enabled]="getIdEnabled('ExtraMessage_ItemGroups', group)"
        (enabledChange)="setIdEnabled('ExtraMessage_ItemGroups', group, $event)" [itemGroup]="group"
        (itemGroupChange)="changeId('ExtraMessage_ItemGroups', 'ExtraMessage_ItemGroup_', group, $event)"
        [(message)]="adminSettingsService.getAdminSetting(settings,'ExtraMessage_ItemGroup_'+group).Value"
        (deleted)="deleteId('ExtraMessage_ItemGroups', 'ExtraMessage_ItemGroup_', $event)"></app-extra-message-item-group>
    </div>
  </app-admin-custom-control>

</app-admin-form>