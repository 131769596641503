import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../_services/main.service';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-ticket-login',
  templateUrl: './ticket-login.component.html',
  styleUrls: ['./ticket-login.component.scss']
})
export class TicketLoginComponent implements OnInit {
  showTicket = false;
  ticket: string;

  constructor(
    public mainService: MainService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const ticket = params['ticket'];
      if (ticket && ticket.length === 36) {
        this.authService.doLoginByTicket(ticket);
      }
      this.ticket = ticket;
    });
  }

}
