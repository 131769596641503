import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { KeyValue } from '@angular/common';

import { AdminService } from '../../../_services/admin/admin.service';
import { ContextAdmin } from '../../../_models/admin/context.admin';
import { CustomerModel } from '../../../_models/common/customer.model';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-admin-settings-selector',
  templateUrl: './admin-settings-selector.component.html',
  styleUrls: ['./admin-settings-selector.component.scss']
})
export class AdminSettingsSelectorComponent implements OnInit {
  @Input() modelWholesaler: number;
  @Output() modelWholesalerChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelBranch: number;
  @Output() modelBranchChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelCustomer: number;
  @Output() modelCustomerChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelUserID: number;
  @Output() modelUserIDChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() labelCols: number;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() dividerKind: number;
  showModels: boolean;
  modelWholesalerLast: number;
  modelBranchLast: number;
  modelCustomerLast: number;
  modelUserIDLast: number;
  wholesalers: KeyValue<number, string>[] = [{ key: 0, value: 'Alle grossiers' }];
  branches = [{ key: 0, value: 'Alle filialen' }];
  oneBranch = false;
  searchingCustomer: boolean;
  inputCustomer: string;
  focusInputCustomer = new EventEmitter<boolean>();
  customer: CustomerModel;
  users = [{ key: 0, value: 'Alle gebruikers' }];

  constructor(
    public adminService: AdminService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.modelWholesalerLast = this.modelWholesaler;
    this.modelBranchLast = this.modelBranch;
    this.modelCustomerLast = this.modelCustomer;
    this.modelUserIDLast = this.modelUserID;
    this.init();
  }

  init() {
    this.fillWholesalers()
      .subscribe(ready1 => {
        if (this.modelWholesaler !== 0) {
          this.fillBranches(this.modelWholesaler)
            .subscribe(ready2 => {
              if (this.modelCustomer !== 0) {
                this.getCustomer(this.modelWholesaler, this.modelCustomer)
                  .subscribe(ready3 => {
                    if (this.modelUserID !== 0) {
                      this.resetSelectorUser(this.modelUserID);
                    }
                  });
              }
            });
        }
      });
  }

  fillWholesalers(): Observable<boolean> {
    if (this.adminService.superAdmin) {
      return this.adminService.getContext()
        .pipe(mergeMap((ctx: ContextAdmin) => {
          this.wholesalers = [{ key: 0, value: 'Alle grossiers' }]
            .concat(ctx.Wholesalers.map(w => ({
              key: w.ID,
              value: w.ShortName,
            })));
          return of(true);
        }));
    } else {
      return of(true);
    }
  }

  fillBranches(wholesaler: number): Observable<boolean> {
    return this.adminService.getBranches(wholesaler)
      .pipe(mergeMap(branches => {
        if (branches) {
          const filialen = branches.map(b => ({
            key: b.BranchNumber,
            value: b.BranchNumber === 0 ? `${b.BranchName} (of alle filialen)` : b.BranchName
          }));
          this.oneBranch = (branches.length === 1);
          const filiaal0 = filialen.find(b => b.key === 0);
          if (filiaal0) {
            this.branches = filialen;
          } else {
            this.branches = this.branches.concat(filialen);
          }
        } else {
          console.info(`Geen filialen ontvangen voor ${wholesaler}`);
        }
        this.modelChange.emit(true);
        return of(true);
      }));
  }

  getCustomer(wholesaler: number, customerNumber: number): Observable<boolean> {
    return this.adminService.getCustomer(wholesaler, customerNumber, true)
      .pipe(mergeMap(customer => {
        if (customer) {
          this.customer = customer;
          if (this.modelBranch !== customer.BranchNumber) {
            this.modelBranch = customer.BranchNumber;
            this.modelBranchChange.emit(customer.BranchNumber);
          }
          if (customer.Users) {
            const users = customer.Users.map(u =>
              ({ key: u.UserID, value: u.Username, extra: `uid=${u.UserID} - pwd=${u.Password}`, copy: u.Password }));
            this.users = this.users.concat(users);
          }
        } else {
          console.warn(`Geen klant ontvangen voor ${customerNumber}`);
        }
        this.searchingCustomer = false;
        this.modelChange.emit(true);
        return of(true);
      }));
  }

  getLabel(label: string, value: number): string {
    if (this.showModels) {
      return `${label}: ${value}`;
    }
    return label;
  }

  resetSelectorWholesaler(value: number) {
    this.modelWholesaler = value;
    this.modelWholesalerChange.emit(value);
    this.branches = [{ key: 0, value: 'Alle filialen' }];
    this.oneBranch = false;
    this.modelBranchLast = 0;
    this.resetSelectorBranch(0);
  }

  resetSelectorBranch(value: number) {
    this.modelBranch = value;
    this.modelBranchChange.emit(value);
    this.customer = null;
    this.searchingCustomer = false;
    this.modelCustomerLast = 0;
    this.resetSelectorCustomer(0);
  }

  resetSelectorCustomer(value: number) {
    this.modelCustomer = value;
    this.modelCustomerChange.emit(value);
    this.users = [{ key: 0, value: 'Alle gebruikers' }];
    this.modelUserIDLast = 0;
    this.resetSelectorUser(0);
  }

  resetSelectorUser(value: number) {
    this.modelUserID = value;
    this.modelUserIDChange.emit(value);
  }

  enableCustomerSearch(): void {
    this.searchingCustomer = true;
    setTimeout(() => {
      this.focusInputCustomer.emit(true);
    }, 0);
  }

  wholesalerChange(value: number) {
    if (value !== this.modelWholesalerLast) {
      this.resetSelectorWholesaler(value);
      if (value !== 0) {
        this.fillBranches(value).subscribe();
      } else {
        this.modelChange.emit(true);
      }
    }
    this.modelWholesalerLast = this.modelWholesaler;
  }

  branchChange(value: number) {
    if (value !== this.modelBranchLast) {
      this.resetSelectorBranch(value);
      this.modelChange.emit(true);
    }
    this.modelBranchLast = this.modelBranch;
  }

  customerChange(value: CustomerModel) {
    if (value.CustomerNumber !== this.modelCustomerLast) {
      this.resetSelectorCustomer(value.CustomerNumber);
      this.getCustomer(this.modelWholesaler, value.CustomerNumber).subscribe();
    }
    this.modelCustomerLast = this.modelCustomer;
  }

  customerBlur() {
    if (!this.inputCustomer) {
      this.searchingCustomer = false;
    }
  }

  resetCustomerSearch() {
    if (this.modelCustomer !== 0) {
      this.customer = null;
      this.resetSelectorCustomer(0);
      this.modelChange.emit(true);
    }
    this.searchingCustomer = false;
  }

  userChange(value: number) {
    this.resetSelectorUser(value);
    if (value !== this.modelUserIDLast) { this.modelChange.emit(true); }
    this.modelUserIDLast = this.modelUserID;
  }

}
