export class SortOrderUpdate {
    Sort: { [key: number]: number };

    constructor(sortOrder: { [key: number]: number } = null) {
        if (sortOrder) {
            this.Sort = sortOrder;
        } else {
            this.Sort = {};
        }
    }
}
