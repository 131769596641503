import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsBase } from '../../../../_models/admin/settings/settings.base';

import { LayoutKind } from '../../../../_models/common/layout.kind';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-catalog-general',
  templateUrl: './admin-settings-catalog-general.component.html',
  styleUrls: ['./admin-settings-catalog-general.component.scss']
})
export class AdminSettingsCatalogGeneralComponent implements OnInit {
  layoutKind = LayoutKind;
  settings: SettingsBase;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }
}
