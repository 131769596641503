import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ItemImagesItemNumberData } from '../../_models/admin/item-images/item.images.item.number.data';
import { AdminItemImagesService } from '../../_services/admin/admin-item-images.service';

@Injectable({
  providedIn: 'root'
})
export class AdminItemImagesItemNumberResolverService  {

  constructor(
    private router: Router,
    private adminItemImagesService: AdminItemImagesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ItemImagesItemNumberData> | Observable<never> {
    const pageNumber = route.queryParams['pageNumber'] ?? 1;
    const pageSize = route.queryParams['pageSize'] ?? 5;
    const filter = route.queryParams['filter'] ?? '';

    return this.adminItemImagesService.getItemImagesItemNumberData(filter, +pageNumber, +pageSize).pipe(
      take(1),
      mergeMap(itemImagesData => {
        if (itemImagesData) {
          return of(itemImagesData);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
