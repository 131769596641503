import { Component, OnInit } from '@angular/core';
// import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-wheel-car-picture',
  templateUrl: './wheel-car-picture.component.html',
  styleUrls: ['./wheel-car-picture.component.scss']
})
export class WheelCarPictureComponent implements OnInit {
  html: string;
  mainService: MainService;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    // this.html = `<iframe srcdoc="${this.html}"></iframe>`;

  }

}
