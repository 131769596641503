import { Component, OnInit } from '@angular/core';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { PromotionService } from '../../../_services/promotion.service';

@Component({
  selector: 'app-promotion-carousel',
  templateUrl: './promotion-carousel.component.html',
  styleUrls: ['./promotion-carousel.component.scss']
})
export class PromotionCarouselComponent implements OnInit {

  ctxPromotion: ContextPromotions;

  constructor(
    public promotionService: PromotionService
  ) { }

  ngOnInit() {
    this.getPromotionContext();
  }

  getPromotionContext(): void {
    this.promotionService.getContext(true)
      .subscribe(ctx => {
        this.ctxPromotion = ctx;
      });
  }

}
