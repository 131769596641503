import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserIdentificationModel } from '../../../../_models/user.identification.model';

@Component({
  selector: 'app-admin-settings-selector-popup',
  templateUrl: './admin-settings-selector-popup.component.html',
  styleUrls: ['./admin-settings-selector-popup.component.scss']
})
export class AdminSettingsSelectorPopupComponent implements OnInit {
  public uid: UserIdentificationModel;
  public onClose: Subject<UserIdentificationModel>;
  uidNew: UserIdentificationModel;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    if (this.uid) {
      this.uidNew = UserIdentificationModel.getClone(this.uid);
    } else {
      this.uidNew = new UserIdentificationModel();
      this.uidNew.Wholesaler = 0;
      this.uidNew.Branch = 0;
      this.uidNew.Customer = 0;
      this.uidNew.UserID = 0;
      this.uidNew.Session = 0;
    }
  }

  selectorChanged(uid: UserIdentificationModel) {

  }

  public confirm(uid: UserIdentificationModel) {
    this.onClose.next(uid);
    this.bsModalRef.hide();
  }

  public decline() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

}
