import { Pipe, PipeTransform } from '@angular/core';
import { ShopModule } from '../../../_models/common/shop.module';

@Pipe({
  name: 'moduleFilter',
  pure: false
})
export class ModuleFilterPipe implements PipeTransform {

  transform(modules: ShopModule[], filterHiddenModules: boolean): ShopModule[] {
    if (!modules) { return null; }
    return modules.filter(module => {
      return !filterHiddenModules || !module.Hide;
    });
  }

}
