import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { ApiService } from '../../../_services/api.service';
import { MainService } from '../../../_services/main.service';
import { CarTypeResult } from '../../../_models/car-types/car.type.result';
import { CarTypeService } from '../../../_services/car-type.service';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { ShopService } from '../../../_services/shop.service';
import { Router } from '@angular/router';
import { CarType } from '../../../_models/car-types/car.type';

@Component({
  selector: 'app-catalog-part-application',
  templateUrl: './catalog-part-application.component.html',
  styleUrls: ['./catalog-part-application.component.scss']
})
export class CatalogPartApplicationComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  @Input() part: Part;
  @Input() carTypeResult: CarTypeResult;
  @Output() carTypeChosen: EventEmitter<UniversalCarType> = new EventEmitter<UniversalCarType>();
  filterString: string;

  constructor(
    public mainService: MainService,
    private apiService: ApiService,
    public carTypeService: CarTypeService,
    private shopService: ShopService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.part && this.carTypeResult && !this.carTypeResult.History) {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'zoeken naar auto`s waar dit onderdeel op past', '');
      this.apiService.getCarTypesByPart(this.part)
        .subscribe((result) => {
          if (result) {
            this.carTypeResult.Timing = result.Timing;
            this.carTypeResult.History = result.History;
          }
          cb.complete();
        });
    }
  }

  carTypeSelected(carType: CarType): void {
    this.carTypeService.saveCarType(ShopSoort.Catalogus, carType as UniversalCarType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        // if (carType.LicensePlate) { this.licensePlate = carType.LicensePlate.Formated; }
        this.router.navigate(['/catalog/groups', carType.Id]);
        this.carTypeChosen.emit(carType as UniversalCarType);
      });
  }




}
