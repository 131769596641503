import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ContextLoyaltyShop } from '../_models/loyalty-shop/context.loyalty.shop';
import { MainService } from './main.service';
import { ApiService } from './api.service';
import { LoyaltyShopItem } from '../_models/loyalty-shop/loyalty.shop.item';
import { LoyaltyShopItemImage } from '../_models/loyalty-shop/loyalty.shop.item.image';
import { ContextMain } from '../_models/common/context.main';
import { LoyaltyShopCategory } from '../_models/loyalty-shop/loyalty.shop.category';
import { CartService } from './cart.service';

@Injectable()
export class LoyaltyShopService {
  ctx: ContextLoyaltyShop;

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private cartService: CartService
  ) { }

  clear(): void {
    this.ctx = null;
  }

  getContext(hideProgress?: boolean): Observable<ContextLoyaltyShop> {
    if (this.ctx) {
      return of(this.ctx);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'data wordt ingeladen', '', hideProgress);
      return this.apiService.getContextLoyaltyShop()
        .pipe(mergeMap(ctx => {
          this.ctx = ctx;
          cb.complete();
          return of(ctx);
        }));
    }
  }

  getCategories(activeCategories: boolean): LoyaltyShopCategory[] {
    const result: LoyaltyShopCategory[] = [];
    if (this.ctx && this.ctx.LoyaltyShop) {
      for (const catID of Object.keys(this.ctx.LoyaltyShop.Categories)) {
        const cat = this.ctx.LoyaltyShop.Categories[catID];
        if (!activeCategories || this.ctx.LoyaltyShop.Categories[catID].Active) { result.push(cat); }
      }
    }
    return result;
  }

  getItems(categoryId: number, activeItems: boolean): LoyaltyShopItem[] {
    const result: LoyaltyShopItem[] = [];
    if (this.ctx && this.ctx.LoyaltyShop) {
      if (categoryId > 0) {
        if (this.ctx.LoyaltyShop.Cross && this.ctx.LoyaltyShop.Cross[categoryId]) {
          for (const itemID of Object.keys(this.ctx.LoyaltyShop.Cross[categoryId])) {
            const item = this.ctx.LoyaltyShop.Items[+itemID];
            if (!activeItems || (item && item.Active)) {
              result.push(item);
            }
          }
        }
      } else {
        for (const itemID of Object.keys(this.ctx.LoyaltyShop.Items)) {
          const item = this.ctx.LoyaltyShop.Items[+itemID];
          if (!activeItems || (item && item.Active)) {
            result.push(item);
          }
        }
      }
    }
    return result;
  }

  getImagelUrl(item: LoyaltyShopItem, image: LoyaltyShopItemImage, dimension: string = null): string {
    // if (!dimension) { dimension = '155x190'; }
    // return `${this.mainService.getBackendUrl()}/img/loyalty-shop/${item.ID}_${image.ID}_${dimension}.png`;
    return image.Url;
  }

  orderItem(item: LoyaltyShopItem) {
    this.apiService.orderLoyaltyShopItem(this.cartService, item);
    this.mainService.msgBox('Bedankt!', 'Het artikel is toegevoegd aan de winkelwagen.');
  }

}
