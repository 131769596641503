<div class="main-content-wwp">
    <div *ngIf="ctx" class="container-fluid p-3">
      <app-header-panel [withoutCarType]="true" [ctx]="ctx" [timing]="ctx?.Timing">
        <div class="row align-items-center justify-content-between mt-0">
          <div class="col">
            <div class="input-group">
              <button *ngIf="itemInfoService._itemInfoRequest && itemInfoService._itemInfoRequest.FromShop !== 0 && itemInfoService._itemInfoRequest.FromShop !== 21" class="btn btn-light" type="button" (click)="shopService.gotoShopModule(mainService.ctxMain, itemInfoService._itemInfoRequest.FromShop)">
                <i class="fas fa-arrow-left"></i> terug</button>
              <input type="text" [appFocus]="focusInput" [appSelectOnclick] class="form-control" [(ngModel)]="item" placeholder="artikel..."
                (keyup.enter)="router.navigate(['/iteminfo', item], { queryParams: itemInfoService.getQueryParams(searchInDescriptions,0) })">
              <button class="btn btn-light" type="button" [routerLink]="['/iteminfo',item]" [queryParams]="itemInfoService.getQueryParams(searchInDescriptions,0)">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div class="col">
            <label class="header-input">
              <input icheck type="checkbox" [(ngModel)]="searchInDescriptions"> Zoek ook in omschrijving</label>
          </div>
          <div class="col">
            <app-sort-options [ctx]="ctx" [btn]="'btn-primary'"></app-sort-options>
          </div>
          <div class="col-auto float-end">
            <app-voorraad-prijs-display-opties [ctx]="ctx" [viewKinds]="viewKinds" (viewKindChanged)="onViewKindChanged()">
            </app-voorraad-prijs-display-opties>
          </div>
        </div>
      </app-header-panel>
  
      <ng-container *ngIf="ctx && ctx.Parts" [ngSwitch]="ctx.ViewKind">
        <div *ngSwitchDefault class="mt-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
          <app-catalog-parts-card-view [ctx]="ctx"></app-catalog-parts-card-view>
        </div>
        <div *ngSwitchCase="0" class="card border-primary mt-3" #itemContainer [ngStyle]="getContainerHeightStyle()">
          <app-catalog-parts-compact-list [ctx]="ctx" [maxItems]="settingsService.getSettingNumber('ShowCrossPartsPerCount')">
          </app-catalog-parts-compact-list>
        </div>
      </ng-container>
  
    </div>
  </div>
  