<div [ngClass]="{'scroll-container': mainService.getShopPortalLayoutKind()===mainService.layoutKind.BoxedLayout}">
  <div class="container">

    <app-breadcrumb-header [debugObject]="customerInformationService.requestInvoice" [autoStart]="true">
      <li appBreadcrumbHeaderItem [active]="true">Facturen</li>
    </app-breadcrumb-header>

    <div *ngIf="!customerInformationService.showPasswordPrompt">

      <div class="row">
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Selectie</label>
            <div class="col-8">
              <select class="form-control form-control-sm" [ngModel]="customerInformationService.selectionInvoice"
                (ngModelChange)="customerInformationService.setDates(customerInformationService.requestInvoice, $event);customerInformationService.selectionInvoice = $event;">
                <option [ngValue]="choice" *ngFor="let choice of customerInformationService.dateChoicesInvoice">{{choice.Description}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum van</label>
            <div class="col-8">
              <input type="text" placeholder="datum van" [(ngModel)]="customerInformationService.requestInvoice.DateFrom"
                (ngModelChange)="customerInformationService.selectionInvoice = customerInformationService.getSelection(customerInformationService.requestInvoice, customerInformationService.dateChoicesInvoice)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionInvoice.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Datum tot</label>
            <div class="col-8">
              <input type="text" placeholder="datum tot" [(ngModel)]="customerInformationService.requestInvoice.DateTo"
                (ngModelChange)="customerInformationService.selectionInvoice = customerInformationService.getSelection(customerInformationService.requestInvoice, customerInformationService.dateChoicesInvoice)"
                class="form-control form-control-sm" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default' }"
                [disabled]="customerInformationService.selectionInvoice.DisableControls">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Factuurnr</label>
            <div class="col-8">
              <input type="number" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestInvoice.InvoiceNumber" maxlength="9"
                max="999999999">
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Pakbonnr</label>
            <div class="col-8">
              <input type="number" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestInvoice.DeliveryNoteNumber"
                maxlength="9" max="999999999">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Artikelnr</label>
            <div class="col-8">
              <input type="text" class="form-control form-control-sm"
                [(ngModel)]="customerInformationService.requestInvoice.ArticleNumber" maxlength="50">
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group row">
            <div class="col">
              <button type="button" class="btn btn-sm w-100 btn-primary" (click)="search()"><i class="fas fa-search"></i>
                Zoeken</button>
            </div>
          </div>
        </div>
      </div>

      <app-grid-webshop contentHeight="300px" [items]="sortService.sortObject('invoices', headers)"
        emptyText="Geen facturen gevonden." class="flexbox-grow flexbox-dumpster mt-3" [isBusy]="isBusy">
        <ng-container grid-header>
          <div class="col-3">
            <app-sort-field [sortObjectId]="'invoices'" [sortField]="'InvoiceNumber'">Factuurnr</app-sort-field>
          </div>
          <div class="col-4">
            <app-sort-field [sortObjectId]="'invoices'" [sortField]="'InvoiceDate'">Factuurdatum</app-sort-field>
          </div>
          <div class="col-4 text-end">
            <app-sort-field [sortObjectId]="'invoices'" [sortField]="'TotalAmount'">Totaalbedrag</app-sort-field>
          </div>
          <div class="col-1 text-center">PDF</div>
        </ng-container>
        <ng-template let-item>
          <div class="col-3">{{item.InvoiceNumber | leadZeros:8}}</div>
          <div class="col-4">{{item.InvoiceDate | date : 'dd-MM-yyyy'}}</div>
          <div class="col-4 text-end">{{item.TotalAmount | price}}</div>
          <div class="col-1 text-center">
            <span *ngIf="customerInformationService.containsPdf(item)" class="text-danger pointer" (click)="customerInformationService.showInvoicePdf(item.InvoiceNumber)"><i
                class="fas fa-file-pdf"></i></span>
          </div>
        </ng-template>
      </app-grid-webshop>
    </div>

    <div *ngIf="customerInformationService.showPasswordPrompt">
      <app-extra-password-webshop (tryPassword)="search()"></app-extra-password-webshop>
    </div>

  </div>
</div>