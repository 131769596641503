import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ResponseItem } from '../../_models/item-info/response.item';
import { MainService } from '../../_services/main.service';



@Component({
  selector: 'app-choose-response-item',
  templateUrl: './choose-response-item.component.html',
  styleUrls: ['./choose-response-item.component.scss']
})
export class ChooseResponseItemComponent implements OnInit {
  nettoprijs: boolean;
  title: string;
  items: ResponseItem[];
  mainService: MainService;
  onClose: Subject<ResponseItem>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public choose(item: ResponseItem) {
    this.onClose.next(item);
    this.bsModalRef.hide();
  }
}
