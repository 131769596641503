import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subject, EMPTY } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { SelectionItem } from '../../../../_models/rims-and-tyres/selection.item';
import { TypeaheadItem } from '../../../../_models/typeahead/typeahead.item';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-edit-selection-item',
  templateUrl: './edit-selection-item.component.html',
  styleUrls: ['./edit-selection-item.component.scss']
})
export class EditSelectionItemComponent implements OnInit {
  _item: SelectionItem;
  _taItem: TypeaheadItem;
  get item(): SelectionItem {
    this._item.InternArtikelnr = this._taItem.InternalItemNumber;
    this._item.Artikelnr = this._taItem.ItemNumber;
    this._item.Artikelgroep = this._taItem.ItemGroup;
    this._item.Omschrijving = this._taItem.Description;
    return this._item;
  }
  set item(value: SelectionItem) {
    this._item = value;
    this._taItem = new TypeaheadItem();
    this._taItem.InternalItemNumber = value.InternArtikelnr;
    this._taItem.ItemNumber = value.Artikelnr;
    this._taItem.ItemGroup = value.Artikelgroep;
    this._taItem.Description = value.Omschrijving;
  }
  mainService: MainService;
  onClose: Subject<SelectionItem> = new Subject();
  focusInput = new EventEmitter<boolean>();

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.bsModalRef.hide();
  }

  save() {
    if (this.item.InternArtikelnr === 0) {
      this.mainService.msgBox('Let op!', 'Er moet nog een artikel gekozen worden!')
        .subscribe(() => this.focusInput.emit());
    } else {
      this.onClose.next(this.item);
      this.bsModalRef.hide();
    }
  }

}
