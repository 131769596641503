import { ShopSoort } from './shop.soort';

export class ShopRouteConfig {

  static routePaths = {
    '-1': 'cart',
    '-2': 'settings',
    '999': 'testshop',
    '1': 'hbase',
    '2': 'tools',
    '3': 'universalparts',
    '4': 'technical-data',
    '5': 'aldoc',
    '6': 'tyres',
    '7': 'rims',
    '8': 'amt',
    '9': 'greenline',
    '10': 'modifications',
    '11': 'recalls',
    '12': 'tyrecalc',
    '13': 'details',
    '14': 'home',
    '15': 'promotions',
    '16': 'thule',
    '17': 'tyrestorage',
    '18': 'licenseplates',
    '19': 'workshop',
    '20': 'store',
    '21': 'iteminfo',
    '22': 'start',
    '23': 'hartsant',
    '24': 'mpm',
    '25': 'nhscross',
    '26': 'modus',
    '27': 'dvse',
    '28': 'rentaltool',
    '29': 'loyaltyshop',
    '30': 'catalog',
    '31': 'sinatec',
    '32': 'links',
    '33': 'paint',
    '34': 'favorites',
    '35': 'maintenance/parts',
    '36': 'pickup',
    '37': 'haynespro',
    '101': 'external1',
    '102': 'external2',
    '103': 'external3',
    '104': 'external4',
    '105': 'external5',
    '106': 'external6',
    '107': 'external7',
    '108': 'external8',
    '109': 'external9'
  };

  static routeParameterPaths = {
    '30': '/catalog/groups'
  };

  static getRoutePathForShopSoort(shopSoort: ShopSoort): string {
    if (shopSoort !== ShopSoort.ONBEKEND && this.routePaths.hasOwnProperty(shopSoort) && this.routePaths[shopSoort]) {
      return this.routePaths[shopSoort];
    }
    return null;
  }

  static getRoutePathForCarTypeShopSoort(shopSoort: ShopSoort): string {
    if (shopSoort !== ShopSoort.ONBEKEND && this.routeParameterPaths.hasOwnProperty(shopSoort) && this.routeParameterPaths[shopSoort]) {
      return this.routeParameterPaths[shopSoort];
    }
    return null;
  }

}
