<div class="modal-header center text-bg-light">
  <h5 class="modal-title">Menu item aanpassen</h5>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <app-admin-dropdown [label]="'Shop Module'" [labelCols]="3" [(model)]="menuItem.ShopKind" [modelDisplayValues]="shopModules" [dividerKind]="1">
    </app-admin-dropdown>
    <app-admin-input [label]="'Omschrijving'" [labelCols]="3" [(model)]="menuItem.Description" [focusInput]="focusDescription" [placeholder]="getPlaceholder(menuItem)" [dividerKind]="1"></app-admin-input>
    <app-admin-input [label]="'Link'" [disabled]="menuItem.ShopKind!==0" [labelCols]="3" [(model)]="menuItem.Link" [dividerKind]="1"></app-admin-input>
    <app-admin-dropdown [label]="'Extra stijl'" [labelCols]="3" [(model)]="menuItem.CssClass" [modelDisplayValues]="ExtraStyles" [dividerKind]="1">
    </app-admin-dropdown>
  </div>
</div>
<div class="modal-footer text-bg-light">
  <app-admin-custom-control [labelCols]="3" [dividerKind]="-1">
    <button type="button" class="btn btn-warning me-2" (click)="confirm(null)"><i class="fas fa-undo"></i>
      Annuleren</button>
    <button type="button" class="btn btn-primary" (click)="confirm(menuItem)"><i class="fas fa-check"></i>
      Opslaan</button>
  </app-admin-custom-control>
</div>