import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-selection-popup',
  templateUrl: './selection-popup.component.html',
  styleUrls: ['./selection-popup.component.scss']
})
export class SelectionPopupComponent implements OnInit {
  title: string;
  selectionValues: { [key: string]: any };
  defaultKey: string;
  mainService: MainService;
  
  public onClose: Subject<string>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public confirm(value: any) {
    this.onClose.next(value);
    this.bsModalRef.hide();
  }

  public decline() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }
}
