import { Injectable } from '@angular/core';
import { Observable, of, Subject, merge } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ShopSoort } from '../_models/common/shop.soort';
import { ApiService } from './api.service';
import { UniversalCarType } from '../_models/car-types/universal.car.type';
import { CarTypeListComponent } from '../car-type-selection/car-type-list/car-type-list.component';
import { CarBrandListComponent } from '../car-type-selection/car-brand-list/car-brand-list.component';
import { CarModelListComponent } from '../car-type-selection/car-model-list/car-model-list.component';
import { UniversalCarBrand } from '../_models/car-types/universal.car.brand';
import { UniversalCarModel } from '../_models/car-types/universal.car.model';
import { CarHistoryListComponent } from '../car-type-selection/car-history-list/car-history-list.component';
import { CarTypeResult } from '../_models/car-types/car.type.result';
import { MainService } from './main.service';
import { ShopPortalMessage } from '../_models/common/shop.portal.message';
import { CarType } from '../_models/car-types/car.type';
import { CarBrand } from '../_models/car-types/car.brand';
import { CarModel } from '../_models/car-types/car.model';
import { ContextCarTypeSelection } from '../_models/car-types/context.car.type.selection';
import { CarTypeSelectionInfo } from '../_models/car-types/car.type-selection.info';
import { CartComponent } from '../cart/cart.component';


@Injectable()
export class CarTypeService {
  ctxs: { [key: number]: ContextCarTypeSelection } = {};
  currentCarType: UniversalCarType;
  carTypeChangedSubject = new Subject<UniversalCarType>();
  public carTypeChanged$ = this.carTypeChangedSubject.asObservable();

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private mainService: MainService,
    private router: Router
  ) { }

  clear(): void {
    this.currentCarType = null;
    this.ctxs = {};
  }

  getContextCarTypeSelection(shopSoort: ShopSoort): Observable<ContextCarTypeSelection> {
    if (this.ctxs[shopSoort]) {
      return of(this.ctxs[shopSoort]);
    } else {
      return this.apiService.getContextCarTypeSelection(shopSoort)
        .pipe(
          mergeMap((ctx: ContextCarTypeSelection) => {
            this.ctxs[shopSoort] = ctx;
            return of(ctx);
          })
        );
    }
  }

  saveCarType(shopKind: ShopSoort, carType: UniversalCarType): Observable<CarTypeResult> {
    return this.apiService.saveCarType(shopKind, carType)
      .pipe(
        mergeMap(result => {
          if (this.ctxs[shopKind] && this.ctxs[shopKind].Historie) {
            Object.assign(this.ctxs[shopKind].Historie, result.History, { length: result.History.length });
          }
          return of(result);
        })
      );
  }

  public setKenteken(licensePlate: string): void {
    alert(`setKenteken: ${licensePlate}!`);
  }

  public resetCarType(shopSoort: ShopSoort) {
    console.info(`resetCarType -> apiService.clearCarType() ${this.currentCarType.TypeId}`);
    // this.apiService.clearCarType()
    //   .subscribe(ok => {
    this.currentCarType = null;
    console.info(`resetCarType -> choose "/cartype/${shopSoort}" and return to "${this.router.url}"`);
    this.router.navigate(['/cartype/' + shopSoort], { queryParams: { returnUrl: this.router.url } });
    //   });
  }

  public backToCarType(shopSoort: ShopSoort) {
    this.router.navigate(['/cartype/' + shopSoort], { queryParams: { returnUrl: this.router.url, back: false } });
  }

  areCarTypesEqual(carType1: CarType, carType2: CarType): boolean {
    return carType1 && carType2 && carType1.TypeId === carType2.TypeId && carType1.Origin === carType2.Origin;
  }

  public setCarType(carType: UniversalCarType) {
    if (!this.areCarTypesEqual(this.currentCarType, carType)) {
      this.currentCarType = carType;
      console.info(`setCarType -> currentCarType = '${carType.TypeId}'`);
      this.carTypeChangedSubject.next(carType);
    } else {
      console.info(`setCarType -> carType is allready current! currentCarType = '${carType.TypeId}'`);
    }
  }

  public getCarTypeSelectionInfo(shopSoort: ShopSoort): Observable<CarTypeSelectionInfo> {
    return this.apiService.getCarTypeSelectionInfo(shopSoort, this.currentCarType)
      .pipe(mergeMap(info => {
        if (info?.CurrentCarType && !info.CompleteSelectionRequired) {
          this.currentCarType = info.CurrentCarType;
        }
        return of(info);
      }));
  }

  private chooseCarBrand(brands: UniversalCarBrand[]): Observable<UniversalCarBrand> {
    const initialState = {
      brands: brands,
      carTypeService: this
    };
    const modalRef = this.modalService.show(CarBrandListComponent, { initialState, class: 'modal-lg' });
    return modalRef.content.onClose;
  }

  private chooseCarModel(model: UniversalCarModel, catalog: string): Observable<UniversalCarModel> {
    const initialState = {
      carModel: model,
      catalog: catalog
    };
    const modalRef = this.modalService.show(CarModelListComponent, { initialState, class: 'modal-lg' });
    return modalRef.content.onClose;
  }

  private chooseCarType(carTypeResult: CarTypeResult, catalog: string): Observable<CarTypeResult> {
    const initialState = {
      carTypeResult: carTypeResult,
      catalog: catalog
    };
    const modalRef = this.modalService.show(CarTypeListComponent, { initialState, class: 'modal-max' });
    return modalRef.content.onClose;
  }

  private narrowUniversalCarModel(carModel: UniversalCarModel): Observable<UniversalCarModel> {
    for (const catalog in carModel.CatalogCarModels) {
      if (carModel.CatalogCarModels.hasOwnProperty(catalog) && carModel.CatalogCarModels[catalog].length > 1) {
        return this.chooseCarModel(carModel, catalog)
          .pipe(mergeMap(model => {
            if (!model) { return of(null); }
            return this.narrowUniversalCarModel(model);
          }));
      }
    }
    return of(carModel);
  }

  narrowCarTypeResult(carTypeResult: CarTypeResult): Observable<CarTypeResult> {
    for (const catalog in carTypeResult.CarType.CatalogCarTypes) {
      if (carTypeResult.CarType.CatalogCarTypes.hasOwnProperty(catalog)
        && carTypeResult.CarType.CatalogCarTypes[catalog].length > 1 && catalog !== 'Stahlie') {
        return this.chooseCarType(carTypeResult, catalog)
          .pipe(mergeMap(result => {
            if (!result) { return of(null); }
            return this.narrowCarTypeResult(result);
          }));
      }
    }
    return of(carTypeResult);
  }

  public searchLicensePlate(shopSoort: ShopSoort, licensePlate: string): Observable<CarTypeResult> {
    return this.apiService.searchByLicensePlate(shopSoort, licensePlate)
      .pipe(mergeMap(result => {
        if (result.CarType) {
          console.info(`searchLicensePlate: ${licensePlate}`);
          return this.narrowCarTypeResult(result);
        } else {
          console.info(`searchLicensePlate: not found! ${licensePlate}`);
          this.mainService.msgBoxExtended(new ShopPortalMessage('Helaas...', 'Het kenteken werd niet gevonden!', null, result.Timing));
          return of(result);
        }
      }));
  }

  public searchVin(shopSoort: ShopSoort, vin: string): Observable<CarTypeResult> {
    return this.apiService.searchByVIN(shopSoort, vin)
      .pipe(mergeMap(result => {
        if (result.CarType) {
          console.info(`searchVin: ${vin}`);
          return this.narrowCarTypeResult(result);
        } else {
          console.info(`searchVin: not found! ${vin}`);
          this.mainService.msgBoxExtended(new ShopPortalMessage('Helaas...', 'Het chassisnummer werd niet gevonden!', null, result.Timing));
          return of(result);
        }
      }));
  }

  public searchHistory(carTypes: UniversalCarType[]): Observable<CarTypeResult> {
    const initialState = {
      carTypes: carTypes,
      carTypeService: this
    };
    const modalRef = this.modalService.show(CarHistoryListComponent, { initialState, class: 'modal-lg' });
    return modalRef.content.onClose
      .pipe(mergeMap((carType: UniversalCarType) => {
        const result = new CarTypeResult();
        result.CarType = carType;
        return this.narrowCarTypeResult(result);
      }));
  }

  getCarBrandLogoUrl(brandLogo: string, size: string) {
    if (brandLogo && brandLogo.startsWith('http')) {
      return brandLogo;
    } else if (brandLogo) {
      return `${this.mainService.getBackendApi()}/img/carbrand/${brandLogo}_${size}.png`;
    }
    return null;
  }

  getDuration(carType: UniversalCarType, separator: string, emptyEndString: string) {
    let duration = '';
    if (carType) {
      if (carType.AvailableFrom) { duration = carType.AvailableFrom.Display; }
      duration += separator;
      if (carType.AvailableUntil && carType.AvailableUntil.Display) {
        duration += carType.AvailableUntil.Display;
      } else {
        duration += emptyEndString;
      }
    }
    return duration;
  }

  getCarTypesFromCarTypeResult(carTypeResult: CarTypeResult) {
    let result: CarType[] = [];
    for (const catKind of Object.keys(carTypeResult.CarType.CatalogCarTypes)) {
      result = result.concat(carTypeResult.CarType.CatalogCarTypes[catKind]);
    }
    return result;
  }

  // public searchBrandModelType(shopSoort: ShopSoort, brands: UniversalCarBrand[], carType: UniversalCarType): Observable<CarTypeResult> {
  //   if (!carType) {
  //     return this.searchType(shopSoort, brands, null,
  //       this.searchModel(shopSoort, brands, null), null
  //     );
  //   } else {
  //     return this.searchType(shopSoort, brands, carType.Model.Brand as UniversalCarBrand, null, carType.Model as UniversalCarModel);
  //   }
  // }

  public getUniversalCarBrandFromCarBrand(brands: UniversalCarBrand[], brand: CarBrand): UniversalCarBrand {
    const data = brands.filter(b => b.BrandName === brand.BrandName);
    if (data.length > 0) { return data[0]; }
    return null;
  }

  public getUniversalCarModelFromCarModel(brand: UniversalCarBrand, model: CarModel): UniversalCarModel {
    const m = new UniversalCarModel();
    m.Brand = brand;
    m.ModelId = model.ModelId;
    m.ModelName = model.ModelName;
    m.ModelRemark = model.ModelRemark;
    m.AvailableFrom = model.AvailableFrom;
    m.AvailableUntil = model.AvailableUntil;
    m.CatalogCarModels = {};
    m.CatalogCarModels[model.Brand.Origin] = [model];
    return m;
  }

  private getCarBrand(brands: UniversalCarBrand[], brand: UniversalCarBrand): Observable<UniversalCarBrand> {
    if (brand) {
      return of(brand);
    } else {
      return this.chooseCarBrand(brands);
    }
  }

  private getCarModel(shopSoort: ShopSoort, brand: UniversalCarBrand, model: UniversalCarModel): Observable<UniversalCarModel> {
    if (model) {
      return of(model);
    } else {
      return this.apiService.getCatalogCarModels(shopSoort, brand)
        .pipe(mergeMap(models => {
          return this.narrowUniversalCarModel(models);
        }));
    }
  }

  private getCarType(shopSoort: ShopSoort, model: UniversalCarModel): Observable<CarTypeResult> {
    return this.apiService.getCatalogCarTypes(shopSoort, model)
      .pipe(mergeMap(result => {
        return this.narrowCarTypeResult(result);
      }));
  }

  public searchBrandModelType(
    shopSoort: ShopSoort, brands: UniversalCarBrand[], brand: UniversalCarBrand, model: UniversalCarModel
  ): Observable<CarTypeResult> {
    return this.getCarBrand(brands, brand)
      .pipe(mergeMap(b => {
        if (!b) { return of(null); }
        return this.getCarModel(shopSoort, b, model)
          .pipe(mergeMap(m => {
            if (!m) { return this.searchBrandModelType(shopSoort, brands, null, null); }
            return this.getCarType(shopSoort, m)
              .pipe(mergeMap(result => {
                if (!result) { return this.searchBrandModelType(shopSoort, brands, brand, null); }
                return of(result);
              }));

          }));
      }));
  }

  getCarTypeDescription(carType: UniversalCarType): string {
    var description = carType?.Model?.Brand?.BrandName ?? '';
    if (carType?.Model) {
      var parts = carType.Model.ModelName.split(' ');
      parts.forEach(part => {
        if (description.indexOf(part) < 0) { description += ' ' + part; }
      });
      parts = carType.TypeName.split(' ');
      parts.forEach(part => {
        if (description.indexOf(part) < 0) { description += ' ' + part; }
      });
    }
    return description;
  }

  getCarTypeImage(carType: CarType): string {
    const images = carType?.Images;
    if (carType && images) {
      if (images.length && images[0].startsWith('http')) return images[0];
    }
    return null;
  }

  sameBrandName(carTypes: CarType[]): boolean {
    if (carTypes?.length) {
      const value = carTypes[0]?.Model?.Brand?.BrandName;
      for (let index = 0; index < carTypes.length; index++) {
        if (carTypes[index]?.Model?.Brand?.BrandName !== value)
          return false;
      }
      return true;
    }
    return false;
  }

  sameModelName(carTypes: CarType[]): boolean {
    if (carTypes?.length) {
      const value = carTypes[0]?.Model?.ModelName;
      for (let index = 0; index < carTypes.length; index++) {
        if (carTypes[index]?.Model?.ModelName !== value)
          return false;
      }
      return true;
    }
    return false;
  }

  sameModelRemark(carTypes: CarType[]): boolean {
    if (carTypes?.length) {
      const value = carTypes[0]?.Model?.ModelRemark;
      for (let index = 0; index < carTypes.length; index++) {
        if (carTypes[index]?.Model?.ModelRemark !== value)
          return false;
      }
      return true;
    }
    return false;
  }

}
