import { Component, OnInit, Input } from '@angular/core';

import { ContextHomeScreen } from '../../../_models/common/context.home.screen';
import { ShopService } from '../../../_services/shop.service';
import { UniversalCarType } from '../../../_models/car-types/universal.car.type';
import { CarTypeService } from '../../../_services/car-type.service';
import { ApiService } from '../../../_services/api.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { MainService } from '../../../_services/main.service';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { ContextLoyaltyShop } from '../../../_models/loyalty-shop/context.loyalty.shop';
import { ContextCarTypeSelection } from '../../../_models/car-types/context.car.type.selection';
import { PromotionService } from '../../../_services/promotion.service';
import { LoyaltyShopService } from '../../../_services/loyalty-shop.service';
import { ItemInfoRequest } from '../../../_models/item-info/item.info.request';

@Component({
  selector: 'app-home-pro-parts',
  templateUrl: './home-pro-parts.component.html',
  styleUrls: ['./home-pro-parts.component.scss']
})
export class HomeProPartsComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctx: ContextHomeScreen;
  @Input() ctxPromotions: ContextPromotions;
  @Input() ctxLoyaltyShop: ContextLoyaltyShop;
  ctxCarTypeSelection: ContextCarTypeSelection;
  licensePlate: string;
  year: number = new Date().getFullYear();

  constructor(
    public mainService: MainService,
    public shopService: ShopService,
    private carTypeService: CarTypeService,
    public promotionService: PromotionService,
    public loyaltyShopService: LoyaltyShopService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.setContextCarType(ShopSoort.StartScherm);
    this.setCarTypeInfo(this.ctx.CarType);
    const ff = this.ctxPromotions;
  }

  setContextCarType(shopKind: ShopSoort) {
    this.carTypeService.getContextCarTypeSelection(shopKind)
      .subscribe(ctx => {
        this.ctxCarTypeSelection = ctx;
      });
  }

  carTypeSelected(carType: UniversalCarType): void {
    this.carTypeService.saveCarType(ShopSoort.StartScherm, carType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        this.ctx.CarType = result.CarType;
        this.setCarTypeInfo(result.CarType);
        let defaultCatalog = ShopSoort.Catalogus;
        if (this.mainService.ctxMain.DefaultCatalog) { defaultCatalog = this.mainService.ctxMain.DefaultCatalog; }
        if (this.mainService.ctxMain && this.shopService.currentActiveShop !== defaultCatalog) {
          this.shopService.gotoShopModule(this.mainService.ctxMain, defaultCatalog);
        }
      });
  }

  searchLicensePlate(licensePlate: string): void {
    this.carTypeService.searchLicensePlate(ShopSoort.StartScherm, licensePlate)
      .subscribe(carTypeResult => {
        if (carTypeResult && carTypeResult.CarType) {
          this.carTypeSelected(carTypeResult.CarType);
        }
      });
  }

  setCarTypeInfo(carType: UniversalCarType): void {
    if (carType) {
      if (carType.LicensePlate) {
        this.licensePlate = carType.LicensePlate.Formated;
      } else {
        this.licensePlate = null;
      }
    }
  }

  searchBrandModelType(): void {
    this.carTypeService.searchBrandModelType(ShopSoort.StartScherm, this.ctxCarTypeSelection.Merken, null, null)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

  searchHistory(): void {
    this.carTypeService.searchHistory(this.ctxCarTypeSelection.Historie)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

}
