import { Component, OnInit, EventEmitter } from '@angular/core';
import { DeliveryChoice } from '../../../../_models/cart/delivery.choice';
import { MainService } from '../../../../_services/main.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-delivery-item',
  templateUrl: './edit-delivery-item.component.html',
  styleUrls: ['./edit-delivery-item.component.scss']
})
export class EditDeliveryItemComponent implements OnInit {
  choice: DeliveryChoice;
  mainService: MainService;
  onClose: Subject<DeliveryChoice> = new Subject();
  focusInput = new EventEmitter<boolean>();
  always: boolean;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.always = (this.choice && this.choice.ValidFrom && this.choice.ValidThru
      && this.choice.ValidFrom.getTime() === this.choice.ValidThru.getTime());
    this.setInputFocus();
  }

  setInputFocus(): void {
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  save() {
    if (!this.choice.ChoiceDescription) {
      this.mainService.msgBox('Let op!', 'Er moet een omschrijving ingevuld worden.')
        .subscribe(() => this.setInputFocus());
    } else {
      if (this.always) {
        this.choice.ValidFrom.setHours(0, 0, 0, 0);
        this.choice.ValidThru.setHours(0, 0, 0, 0);
      }
      this.onClose.next(this.choice);
      this.bsModalRef.hide();
    }
  }

}
