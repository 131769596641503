import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription, Observable, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ContextPromotions } from '../_models/promotions/context.promotions';
import { Aanbieding } from '../_models/promotions/aanbieding';
import { AanbiedingImage } from '../_models/promotions/aanbieding.image';
import { MainService } from './main.service';
import { ApiService } from './api.service';
import { ShopService } from './shop.service';
import { ShopSoort } from '../_models/common/shop.soort';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';

@Injectable()
export class PromotionService {
  ctx: ContextPromotions;
  imageChangingSubscription: Subscription;
  fromShop = ShopSoort.Aanbiedingen;

  constructor(
    private mainService: MainService,
    private shopService: ShopService,
    private apiService: ApiService,
    private router: Router
  ) { }

  init(): void {
    this.clear();
  }

  clear(): void {
    this.ctx = null;
    if (this.imageChangingSubscription) { this.imageChangingSubscription.unsubscribe(); }
  }

  getContext(hideProgress?: boolean): Observable<ContextPromotions> {
    if (this.ctx) {
      return of(this.ctx);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'aanbiedingen worden opgehaald', '', hideProgress);
      return this.apiService.getContextPromotions()
        .pipe(mergeMap(ctx => {
          this.ctx = ctx;
          cb.complete();
          ctx['busy'] = 1;
          this.apiService.getPromotionsCartItems()
            .subscribe(cartItems => {
              ctx.CartItems = cartItems;
              ctx['busy'] = 0;
            });
          return of(ctx);
        }));
    }
  }

  getPromotionSets(promotions: Aanbieding[], perSet: number): Aanbieding[][] {
    if (promotions && promotions.length) {
      const sets: Aanbieding[][] = [];
      let row: Aanbieding[] = [];
      for (let i = 0; i < promotions.length; i++) {
        if (row.length >= perSet) {
          sets.push(row);
          row = [];
        }
        if (promotions[i]) { row.push(promotions[i]); }
      }
      if (row.length > 0) { sets.push(row); }
      return sets;
    }
    return null;
  }

  getCartItemByItemId(cartItems: { [key: number]: ShoppingCartItem }, itemId: number): ShoppingCartItem {
    if (cartItems && cartItems[itemId]) { return cartItems[itemId]; }
    return null;
  }

  getPromotionById(id: string): Observable<Aanbieding> {
    return this.getContext()
      .pipe(mergeMap((ctx) => {
        if (ctx) {
          for (const promotion of ctx.Promotions) {
            if (promotion.AanbiedingID === id) { return of(promotion); }
          }
        }
        return of(null);
      }));
  }

  startImageChanging(ctx: ContextPromotions) {
    if (this.imageChangingSubscription) { this.imageChangingSubscription.unsubscribe(); }
    if (ctx && ctx.Promotions && ctx.Promotions.length) {
      const promotions = ctx.Promotions;
      console.info(`startImageChanging... for ${promotions.length} promotions`);
      this.imageChangingSubscription = timer(5000, 5000)
        .subscribe(val => {
          // console.info(`Change promotion images... ${val}`);
          for (const promotion of promotions) {
            if (promotion.Images.length > 0) {
              promotion['currentImage'] = (Number(this.getCurrentImage(promotion) + 1) % promotion.Images.length);
            }
          }
        });
    }
  }

  getCurrentImage(promotion: Aanbieding): number {
    if (!promotion['currentImage']) { return 0; }
    return Number(promotion['currentImage']);
  }

  setCurrentImage(promotion: Aanbieding, index: number): void {
    promotion['currentImage'] = index;
  }


  getImagelUrl(promotion: Aanbieding, image: AanbiedingImage, dimension: string = null): string {
    if (!(promotion && image)) { return null; }
    // if (!dimension) { dimension = '290x200'; }
    // return `${this.mainService.getBackendApi()}/img/promotion/${promotion.AanbiedingID}_${image.VolgNr}_${dimension}.png`;
    return image.AfbeeldingsUrl;
  }

  hasArticles(promotion: Aanbieding): boolean {
    if (promotion.Artikelen && promotion.Artikelen.length > 0) {
      return true;
    }
    return false;
  }

  gotoPromotionsOverview(): void {
    if (this.fromShop !== ShopSoort.Aanbiedingen) {
      this.shopService.gotoShopModule(this.mainService.ctxMain, this.fromShop);
      this.fromShop = ShopSoort.Aanbiedingen;
    } else {
      this.router.navigate(['/promotions/overview']);
    }
  }

  orderPromotion(promotion: Aanbieding, fromShop: ShopSoort = ShopSoort.ONBEKEND): void {
    if (fromShop) { this.fromShop = fromShop; }
    this.router.navigate(['/promotions/detail/' + promotion.AanbiedingID]);
  }

  getPrice(cartItems: { [key: number]: ShoppingCartItem }, promotion: Aanbieding): number {
    let prijs = 0.0;
    if (promotion) {
      promotion.Artikelen.forEach((art) => {
        const sci = this.getCartItemByItemId(cartItems, art.ID);
        if (sci && sci.ItemInfo && sci.ItemInfo.Price) {
          if (prijs === 0 || sci.ItemInfo.Price.NetPrice < prijs) {
            prijs = sci.ItemInfo.Price.NetPrice;
          }
        }
      });
    }
    return prijs;
  }

  showRemaining(endDate): string {
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    const distance = endDate - this.shopService.dateTimeNow.getTime();
    if (distance < 0) {
      return 'Verlopen!';
    }
    const days = Math.floor(distance / _day);
    const hours = Math.floor((distance % _day) / _hour);
    const minutes = Math.floor((distance % _hour) / _minute);
    const seconds = Math.floor((distance % _minute) / _second);

    let countdown = 'nog ' + days + ' dagen en ';
    countdown += hours + ':';
    if (minutes < 10) { countdown += '0'; }
    countdown += minutes;
    // if (seconds < 10) { countdown += '0'; }
    // countdown += + ':' + seconds;
    countdown += ' uur geldig!';

    return countdown;
  }

  getStatus(promo: Aanbieding) {
    if (promo) {
      const now = new Date();
      if (promo.GeldigVanaf < now && promo.GeldigTot > now && promo.Aktief) {
        return 0;
      } else if (promo.GeldigVanaf > now && promo.Aktief) {
        return -1;
      } else if (promo.GeldigTot < now) {
        return 1;
      } else if (!promo.Aktief) {
        return 2;
      }
    }
    return null;
  }

}
