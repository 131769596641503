<ng-template #popTemplate>
    <div [innerHTML]="mainService.getSafeHtml(warningsHtml)"></div>
</ng-template>
<div class="modal-header" *ngIf="message.Title">
    <h3 class="modal-title" [innerHTML]="mainService.getSafeHtml(message.Title)" (contextmenu)="mainService.showDebugInfo($event, message.Timing, message)"></h3>
    <span *ngIf="warningsHtml" class="float-end">
    <span class="badge text-bg-warning" [tooltip]="popTemplate" html="true" placement="bottom" container="body">
      <i class="fas fa-exclamation-triangle"></i>{{message.Timing.Warnings.length}}</span>
    </span>
</div>
<div class="modal-body" [innerHTML]="mainService.getSafeHtml(message.Text)"></div>
<div class="modal-footer">
    <button *ngFor="let btn of message.Buttons" type="button" class="btn" [ngClass]="btn.CssClass" (click)="choose(btn.Command)">
        {{btn.Text}}
    </button>
</div>