export class CatalogLinesTotals {
  Total: { [key: number]: number };
  Selected: { [key: number]: number };
  Objects: { [key: number]: { [key: string]: CatalogLinesTotals }};

  constructor(catalogKinds: { [key: number]: string }) {
    this.Total = {};
    this.Selected = {};
    this.Objects = {};
    this.Total[0] = 0;
    this.Selected[0] = 0;
    this.Objects[0] = {};
    Object.keys(catalogKinds).forEach(catKey => {
      this.Total[catKey] = 0;
      this.Selected[catKey] = 0;
      this.Objects[catKey] = {};
    });
  }
}
