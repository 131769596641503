<div class="card border mb-3">

  <div class="card-header card-header-compact  text-bg-dark">Acties</div>

  <ul class="list-group list-group-flush">
    <li class="list-group-item p-0">
      <div id="promotionCarousel" class="carousel slide" data-ride="carousel" *ngIf="ctxPromotion">
        <div class="carousel-inner carousel-height">

          <div class="carousel-item carousel-promotion-item" [ngClass]="{'active':first}" *ngFor="let promotion of ctxPromotion.Promotions;let first=first;">
            <div class="d-block w-100">
              <div class="carousel-promotion" (click)="promotionService.orderPromotion(promotion)">
                <div class="carousel-promotion-window">
                  <div class="carousel-promotion-content">
                      <b>{{promotion.Titel}}</b>
                      <p [innerHTML]="promotion.Omschrijving"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#promotionCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#promotionCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div *ngIf="!ctxPromotion" class="carousel-item-wait">
          <i class="fas fa-sync-alt fa-spin fa-3x"></i>
      </div>
    </li>
  </ul>
</div>
