<div class="modal-body">
  <div [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 180) + 'px', 'overflow': 'auto'}">
    <div *ngIf="isString(object); else json"><pre>{{object}}</pre></div>
    <ng-template #json><ngx-json-viewer [json]="object" [expanded]="!collapsed"></ngx-json-viewer></ng-template>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="!isString(object)" type="button" class="btn btn-default" (click)="collapsed = !collapsed">
    <span *ngIf="collapsed"><i class="fas fa-plus"></i></span>
    <span *ngIf="!collapsed"><i class="fas fa-minus"></i></span>
  </button>
  <button type="button" class="btn btn-warning" (click)="bsModalRef.hide()">Annuleren</button>
  <button *ngIf="isString(object)" type="button" class="btn btn-info" (click)="copy()"><i class="far fa-copy"></i> Kopieer tekst</button>
  <button *ngIf="!isString(object)" type="button" class="btn btn-info" (click)="copy()"><i class="far fa-brackets-curly"></i> Kopieer JSON</button>
</div>
