<div class="upload-container" [ngClass]="{'container-drag-over': isDraggingOverDropZone}" (drop)="dropFiles($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <input #fileSelector class="hide-input" type="file" multiple (change)="uploadFiles($event)" (click)="preventAndStop($event)" [accept]="accept" />  
  <div class="icon">
    <div><i class="far fa-2x fa-download me-2"></i></div>
  </div>
  <div class="content-container" [ngClass]="{'content-drag-over': isDraggingOverDropZone}">
    <ng-content></ng-content>
  </div>
</div>
<button class="btn-primary btn btn-sm mt-2" (click)="clickUpload($event, fileSelector)">{{description}}</button>

