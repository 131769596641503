import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-cancel-save-button',
  templateUrl: './admin-cancel-save-button.component.html',
  styleUrls: ['./admin-cancel-save-button.component.scss']
})
export class AdminCancelSaveButtonComponent implements OnInit {
  @Input() saveAction: Observable<any>;
  @Output() beforeSaveAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() cancelAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() labelCols: number = 2;
  @Input() saveDisabled: boolean = false;
  @Input() modal: boolean;


  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

  cancel(event: MouseEvent) {
    if (this.modal || this.cancelAction.observers.length > 0) {
      this.cancelAction.emit(event);
    } else {
      this.router.navigate(['/admin']);
    }
  }



}
