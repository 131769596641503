<div *ngIf="group" class="container py-3">
  <div class="form-group row mt-3">
    <div class="offset-sm-4 col-sm-8">
      <h2 (contextmenu)="mainService.showObject($event, group)">{{group.Description}}</h2>
    </div>
  </div>
  <div *ngFor="let setting of group.Settings" class="form-group row">
    <label for="colFormLabel" class="col-sm-4 col-form-label label-right">{{setting.Description}}</label>
    <div class="col-sm-8">
      <app-portal-setting [setting]="setting"></app-portal-setting>
    </div>
  </div>
  <div class="form-group row">
    <div class="offset-sm-4 col-sm-8">
      <button class="btn btn-primary me-2" (click)="saveGroup(group)">Opslaan</button>
      <button class="btn btn-warning" (click)="cancel()">Annuleren</button>
    </div>
  </div>
</div>
