<div *ngIf="promotion" class="card pointer" (click)="promotionService.orderPromotion(promotion, shopKind.StartScherm)">
  <div class="card-body p-0 promo-body">
    <div class="row mx-3 h-100">
      <div class="col-4 text-white" [ngClass]="css">
        <div *ngIf="promotion.TekstVan" class="van-voor mt-3">
          Van:<br />{{promotion.TekstVan}}
        </div>
        <div *ngIf="promotion.TekstVoor || itemsPrice" class="van-voor fw-bold mt-3">
          Voor:
        </div>
        <div *ngIf="promotion.TekstVoor; else price" class="voor-prijs">
          {{promotion.TekstVoor}}
        </div>
        <ng-template #price>
          <div *ngIf="itemsPrice" class="voor-prijs">{{itemsPrice | price}}</div>
        </ng-template>
      </div>
      <div class="col-8 pe-0">
        <div class="promo-title" [innerHTML]="promotion.Titel | safeHtml"></div>
        <div *ngIf="promotion.Images && promotion.Images.length" class="promo-image-container d-flex align-items-end">
          <img class="mx-auto img-fluid img-fluid-height" [src]="promotion.Images[0].AfbeeldingsUrl" />
        </div>
      </div>
    </div>
  </div>
</div>
