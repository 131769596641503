import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';

@Component({
  selector: 'app-footer-roskamp',
  templateUrl: './footer-roskamp.component.html',
  styleUrls: ['./footer-roskamp.component.scss']
})
export class FooterRoskampComponent implements OnInit {
  @Input() ctx: ContextMain;
  year = 2019;
  constructor() { }

  ngOnInit() {
    const now = new Date();
    this.year = now.getFullYear();
  }

}
