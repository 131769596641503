<div class="strips-container">
  <div *ngFor="let strip of ctx.GraphicParts">
    <img *ngIf="ctx.currentStrip === strip" class="image-height" [src]="strip.Image" [style.width.px]="currentStripWidth">
  </div>
  <div *ngFor="let strip of ctx.GraphicParts" [@fadeInOut]="ctx.currentStrip === strip" class="strip-map-container">
    <div class="strip-map-center-container">
      <img [src]="strip.Image" [style.width.px]="currentStripWidth" [attr.usemap]="'#sp' + strip.StripId"
        (click)="click($event, ctx.Context[strip.StripId])">
      <ng-container *ngFor="let map of strip.Maps">
        <ng-template #popTemplate>
          <table class="popover-table">
            <tbody>
              <tr *ngFor="let part of getPartsForMap(ctx.Context[strip.StripId], map)">
                <td>{{part.Brand}}</td>
                <th scope="row">{{part.PartItemNumber}}</th>
              </tr>
            </tbody>
          </table>
        </ng-template>
        <div *ngIf="!map.IsText && !isFiltered(ctx.Context[strip.StripId], map)"
          (click)="clickMap(ctx.Context[strip.StripId], map)" [id]="getID(map)"
          [ngStyle]="getGraphicPartDivStyle(ctx.Context[strip.StripId], map)" (mouseenter)="mouseEnter(map)"
          (mouseleave)="mouseLeave(map)" [popover]="popTemplate" [popoverTitle]="map.ArtikelCodeOmschrijving"
          triggers="mouseenter:mouseleave" container="body" placement="top" containerClass="trans-back-090"></div>
        <div *ngIf="!map.IsText && isFiltered(ctx.Context[strip.StripId], map)"
          (click)="clickMap(ctx.Context[strip.StripId], map)" [id]="getID(map)+'_filter'" class="filtered-part"
          [ngStyle]="getGraphicPartDivStyle(ctx.Context[strip.StripId], map)" container="body" placement="top"
          containerClass="trans-back-090"></div>
      </ng-container>
    </div>
  </div>
</div>