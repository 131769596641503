<div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
<!-- <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3" [@enterLeaveBounce]="filterResult['count']"> -->
<div *ngFor="let wheel of ctx.Wheels | rimFilter: +ctx.Filters['Diameter']:ctx.Voorraad:ctx.WheelCartItems:catalogService | filterCount: { object: filterResult, key: 'count' } | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.WheelCartItems; trackBy: mainService.trackByCatalogPart" class="col">
    <div class="card mb-3">
        <div class="card-body" (contextmenu)="mainService.showObject($event, wheel)">
            <div class="row row-logo-and-availability align-items-center">
                <div class="col-5 text-center">
                    <img *ngIf="wheel.BrandLogo" [src]="wheel.BrandLogo" />
                    <span *ngIf="!wheel.BrandLogo" class="wheel-brand">{{wheel.Brand}}</span>
                </div>
                <div class="col-7 item-info">
                    <app-item-info-template [busy]="ctx['busy']"
                        [itemInfo]="catalogService.getItemInfo(ctx.WheelCartItems,wheel)" [nettoprijs]="ctx.Nettoprijs" [part]="wheel">
                    </app-item-info-template>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-5 text-center">
                    <img *ngIf="wheel?.Images?.length>0" [src]="wheel.Images[0]" class="pointer wheel-image"
                        (click)="rimsAndTyreService.showWheelInfoPopup(ctx,wheel)" />
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col pb-1">
                            <button class="btn btn-sm btn-primary w-100"
                                (click)="rimsAndTyreService.showWheelCarPicture(ctx.CarType, wheel)">Foto met
                                Auto</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col pb-1">
                            <button class="btn btn-sm btn-primary w-100"
                                (click)="rimsAndTyreService.orderRims(ctx, wheel)">Velgen bestellen</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col pb-1">
                            <button class="btn btn-sm btn-primary w-100"
                                (click)="rimsAndTyreService.chooseTyres(ctx, wheel)">Velgen+banden bestellen</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <img src="/assets/img/rims/info.png" class="pointer"
                                (click)="rimsAndTyreService.showWheelInfoPopup(ctx,wheel)" />
                        </div>
                        <div class="col">
                            <img src="/assets/img/rims/winter.png" [ngClass]="{'wheel-icon-off': !wheel['Winter']}" />
                        </div>
                        <div class="col">
                            <img src="/assets/img/rims/runflat.png" [ngClass]="{'wheel-icon-off': !wheel['Runflat']}" />
                        </div>
                        <div class="col">
                            <img src="/assets/img/rims/tpms.png" [ngClass]="{'wheel-icon-off': !wheel['Tpms']}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div *ngIf="filterResult.count === 0" class="row">
    <div class="col-12">
        <div *ngIf="!fetching" class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i>
            <span class="ps-3">Er bleven geen velgen over die op voorraad zijn!</span>
        </div>
        <div *ngIf="fetching" class="alert alert-info" role="alert">
            <i class="fas fa-sync-alt fa-spin"></i>
            <span class="ps-3">De velgen worden op dit moment nog opgehaald.</span>
        </div>
    </div>
</div>