<div class="form-group row mb-0">
  <label for="dropdownMenuButton" [ngClass]="'col-sm-' + labelCols"
    class="col-form-label label-admin text-end noselect pe-0">{{label}}</label>
  <div [ngClass]="'col-sm-' + (12-labelCols)" class="my-auto">
    <button [class]="'btn btn-'+buttonColor+' btn-sm'" type="button" (click)="clickAction.emit($event)">
      <ng-content></ng-content>
    </button>
  </div>
</div>

<app-admin-divider [dividerKind]="dividerKind"></app-admin-divider>