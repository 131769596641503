import { Component, OnInit, Input } from '@angular/core';

import { MainService } from '../../_services/main.service';
import { ShopService } from '../../_services/shop.service';
import { ShopSoort } from '../../_models/common/shop.soort';

@Component({
  selector: 'app-breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss']
})
export class BreadcrumbHeaderComponent implements OnInit {
  @Input() debugObject: any;
  @Input() autoStart: boolean;

  constructor(
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
  }

  showDebug(event: MouseEvent, obj: any) {
    if (obj && obj['Timing']) {
      this.mainService.showDebugInfo(event, obj['Timing'], obj);
    } else {
      this.mainService.showObject(event, obj);
    }
  }

}
