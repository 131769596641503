import { Component, OnInit, Input } from '@angular/core';

import { Part } from '../../../_models/catalog/part';
import { ShopSoort } from '../../../_models/common/shop.soort';

@Component({
  selector: 'app-catalog-parts-property',
  templateUrl: './catalog-parts-property.component.html',
  styleUrls: ['./catalog-parts-property.component.scss']
})
export class CatalogPartsPropertyComponent implements OnInit {
  @Input() part: Part;
  @Input() property: { Item1: string, Item2: string };
  @Input() partsPropertyTranslations: { [key: string]: { [key: string]: string } };
  @Input() shopKind: ShopSoort;

  constructor(
  ) { }

  ngOnInit() {
  }

}
