import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../../_services/toaster.service';

import { Animations } from '../../_animations/animations';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  animations: [Animations.toastAppearLeave]
})
export class ToastsComponent implements OnInit {

  constructor(
    public toasterService: ToasterService
  ) { }

  ngOnInit() {
  }

}
