import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { DateChoice } from '../_models/customer-information/date.choice';
import { GisSearchRequest } from '../_models/customer-information/gis.search.request';
import { ShopService } from './shop.service';
import { ShopSoort } from '../_models/common/shop.soort';
import { ApiService } from './api.service';
import { GisOrderHistorySearchRequest } from '../_models/customer-information/gis.order.history.search.request';
import { GisOrderInfo } from '../_models/customer-information/gis.order.info';
import { GisRequest } from '../_models/customer-information/gis.request';
import { GisDeliveryNoteSearchRequest } from '../_models/customer-information/gis.delivery.note.search.request';
import { GisDeliveryNote } from '../_models/customer-information/gis.delivery.note';
import { GisInvoiceSearchRequest } from '../_models/customer-information/gis.invoice.search.request';
import { ContextCustomerInformation } from '../_models/customer-information/context.customer.information';
import { GisModuleKind } from '../_models/customer-information/gis.module.kind';
import { Order } from '../_models/orders/order';
import { DeliveryNoteHeader } from '../_models/delivery-notes/delivery.note.header';
import { InvoiceHeader } from '../_models/invoices/invoice.header';
import { OutstandingBill } from '../_models/bills/outstanding.bill';
import { ShopOrder } from '../_models/shoporders/shop.order';
import { ReturnHeader } from '../_models/returns/return.header';
import { Return } from '../_models/returns/return';
import { ReturnReason } from '../_models/returns/return.reason';
import { ReturnDetail } from '../_models/returns/return.detail';
import { ReturnOrder } from '../_models/returns/return.order';
import { DeliveryNoteItem } from '../_models/delivery-notes/delivery.note.item';
import { GisModuleSettings } from '../_models/customer-information/gis.module.settings';


@Injectable()
export class CustomerInformationService {
  ctx: ContextCustomerInformation;
  password: string;
  dateChoicesOrderHistory: DateChoice[];
  selectionOrderHistory: DateChoice;
  requestOrderHistory: GisOrderHistorySearchRequest;
  dateChoicesDeliveryNote: DateChoice[];
  selectionDeliveryNote: DateChoice;
  requestDeliveryNote: GisDeliveryNoteSearchRequest;
  requestInvoice: GisInvoiceSearchRequest;
  dateChoicesInvoice: DateChoice[];
  selectionInvoice: DateChoice;

  showPasswordPrompt = false;

  constructor(
    private shopService: ShopService,
    private apiService: ApiService,
    private router: Router
  ) { }

  clear() {
    this.requestOrderHistory = null;
    this.requestDeliveryNote = null;
    this.requestInvoice = null;
    this.ctx = null;
    this.password = '';
  }

  setPassword(password: string) {
    this.password = password;
    this.ctx = null;
  }

  getContextCustomerInformation(): Observable<ContextCustomerInformation> {
    if (this.ctx) {
      return of(this.ctx);
    } else {
      const r = new GisRequest();
      r.ExtraPassword = this.password;
      r.PlaceHolder = 'PlaceHolder';
      return this.apiService.getContextCustomerInformation(r)
        .pipe(mergeMap(ctx => {
          delete ctx.Modules['ProductWarranty'];
          this.ctx = ctx;
          return of(ctx);
        }));
    }
  }

  getModules(ctx: ContextCustomerInformation): GisModuleSettings[] {
    if (ctx?.Modules) {
      const modules = Object.keys(ctx.Modules).map(e => {
        return ctx.Modules[e];
      });
      return modules;
    }
    return [];
  }

  getModuleByKind(ctx: ContextCustomerInformation, kind: GisModuleKind): GisModuleSettings {
    const modules = this.getModules(ctx);
    const module = modules.find(m => m.Kind === kind);
    return module;
  }

  isModuleActive(modules: GisModuleSettings[], kind: GisModuleKind): boolean {
    var ok = false;
    const module = modules.find(m => m.Kind === kind);
    if (module) { ok = true; }
    return ok;
  }

  getModuleName(moduleKind: GisModuleKind) {
    switch (moduleKind) {
      case GisModuleKind.OrderHistory:
        return 'Orderhistorie';
      case GisModuleKind.OpenOrders:
        return 'Openstaande orders';
      case GisModuleKind.DeliveryNotes:
        return 'Pakbonnen';
      case GisModuleKind.Invoices:
        return 'Facturen';
      case GisModuleKind.OutstandingItems:
        return 'Openstaande posten';
      case GisModuleKind.ProductReturns:
        return 'Retouren';
      case GisModuleKind.ProductWarranty:
        return 'Garantie';
    }
  }

  getTitleHtml(moduleKind: GisModuleKind) {
    switch (moduleKind) {
      case GisModuleKind.OrderHistory:
        return `<i class="fal fa-history"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.OpenOrders:
        return `<i class="fal fa-folder-open"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.DeliveryNotes:
        return `<i class="fal fa-receipt"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.Invoices:
        return `<i class="fal fa-file-invoice"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.OutstandingItems:
        return `<i class="fal fa-file-check"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.ProductReturns:
        return `<i class="fal fa-undo-alt"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.ProductWarranty:
        return `<i class="fal fa-award"></i>  ${this.getModuleName(moduleKind)}`;
    }
  }

  getTitleHtml2x(moduleKind: GisModuleKind) {
    switch (moduleKind) {
      case GisModuleKind.OrderHistory:
        return `<i class="fal fa-history"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.OpenOrders:
        return `<i class="fal fa-folder-open"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.DeliveryNotes:
        return `<i class="fal fa-2x fa-receipt text-primary header-panel-text"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.Invoices:
        return `<i class="fal fa-file-invoice"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.OutstandingItems:
        return `<i class="fal fa-file-check"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.ProductReturns:
        return `<i class="fal fa-undo-alt"></i> ${this.getModuleName(moduleKind)}`;
      case GisModuleKind.ProductWarranty:
        return `<i class="fal fa-award"></i> ${this.getModuleName(moduleKind)}`;
    }
  }

  navigateTo(path: string) {
    this.shopService.lastShopRoutes[ShopSoort.GarageInformatieSysteem] = path;
    this.router.navigate([path]);
  }

  gotoMenu() {
    this.navigateTo('/details');
  }

  gotoOrderHistory() {
    this.navigateTo('/details/history');
  }

  gotoOpenOrders() {
    this.navigateTo('/details/open-orders');
  }

  gotoOpenOrdersDetail(order: GisOrderInfo) {
    console.info(order);
    this.router.navigate(['/details/open-orders/' + order.OrderNumber]);
  }

  gotoShopOrderDetail(order: ShopOrder) {
    this.router.navigate(['/details/history/' + order.OrderId]);
  }

  gotoDeliveryNotes() {
    this.navigateTo('/details/delivery-notes');
  }

  gotoInvoices() {
    this.navigateTo('/details/invoices');
  }

  gotoOutstandingItems() {
    this.navigateTo('/details/outstanding-items');
  }


  gotoReturns() {
    this.navigateTo('/details/returns');
  }

  gotoReturnDetail(returnHeader: ReturnHeader) {
    this.router.navigate(['/details/returns/' + returnHeader.ReturnId]);
  }

  gotoReturnRequest() {
    this.navigateTo('/details/returns-request');
  }

  gotoReturnRequestItem() {
    this.navigateTo('/details/returns-request-item');
  }

  gotoWarranty() {
    this.navigateTo('/details/warranty');
  }

  containsPdf(item: any): boolean {
    return item.HasPDF || item.hasPdf || item.HasPdf;
  }

  showShopOrderPdf(header: ShopOrder) {
    this.apiService.getShopOrderPdf(header.OrderId)
      .subscribe(pdf => window.open(pdf));
  }

  showDeliveryNotePdf(header: DeliveryNoteHeader) {
    this.apiService.getDeliveryNotePdf(header.DeliveryNoteNumber)
      .subscribe(pdf => window.open(pdf));
  }

  showInvoicePdf(invoiceNumber: number) {
    this.apiService.getInvoicePdf(invoiceNumber)
      .subscribe(pdf => window.open(pdf));
  }

  getDateChoices(): DateChoice[] {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date((today).valueOf() - 1000 * 60 * 60 * 24);
    const lastweek = new Date((today).valueOf() - 1000 * 60 * 60 * 24 * 7);
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const startDate = new Date(1970, 0, 1);

    const dateChoices = [];
    dateChoices.push(new DateChoice('Vandaag', today, today));
    dateChoices.push(new DateChoice('Vandaag en gisteren', yesterday, today));
    dateChoices.push(new DateChoice('Afgelopen week', lastweek, today));
    dateChoices.push(new DateChoice('Afgelopen maand', lastMonth, today));
    dateChoices.push(new DateChoice('Alles', startDate, today));

    const specific = new DateChoice('Specifieke periode', today, today);
    specific.DisableControls = false;

    dateChoices.push(specific);

    // console.info(dateChoices);

    return dateChoices;
  }

  setDates(request: GisSearchRequest, choice: DateChoice): void {
    if (request) {
      request.DateFrom = choice.DateFrom;
      request.DateTo = choice.DateTo;
    }
  }

  getDateTo(dateTo: Date): Date {
    //2022-03-09 Maurice neem de volgende dag, zodat alles van de dateTo dag zelf ook zit in de datum selectie.
    const nextDay = new Date(dateTo);
    nextDay.setUTCDate(nextDay.getUTCDate() + 1);
    return nextDay;
  }

  getSelection(request: GisSearchRequest, dateChoices: DateChoice[]): DateChoice {
    for (const choice of dateChoices) {
      if (choice.DateFrom.getTime() === request.DateFrom.getTime() && choice.DateTo.getTime() === request.DateTo.getTime()) {
        return choice;
      }
    }
    dateChoices[dateChoices.length - 1].DateFrom = request.DateFrom;
    dateChoices[dateChoices.length - 1].DateTo = request.DateTo;
    return dateChoices[dateChoices.length - 1];
  }

  getOpenOrdersV2(): Observable<Order[]> {
    return this.apiService.getOpenOrdersV2();
  }

  getOrder(orderNumber: number): Observable<Order> {
    return this.apiService.getOrder(orderNumber);
  }

  getDeliveryNotes(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    deliveryNoteNumber: number,
    invoiceNumber: number,
    reference: string): Observable<DeliveryNoteHeader[]> {
    return this.apiService.getDeliveryNotes(dateFrom, this.getDateTo(dateTo), itemNumber, deliveryNoteNumber, invoiceNumber, reference);
  }

  getInvoices(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    deliveryNoteNumber: number,
    invoiceNumber: number): Observable<InvoiceHeader[]> {
    return this.apiService.getInvoices(
      dateFrom,
      this.getDateTo(dateTo),
      itemNumber,
      deliveryNoteNumber,
      invoiceNumber
    );
  }

  getShopOrders(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    orderNumber: number,
    reference: string,
    description: string,
    licensePlateNumber: string
  ): Observable<ShopOrder[]> {
    return this.apiService.getShopOrders(
      dateFrom,
      this.getDateTo(dateTo),
      itemNumber,
      orderNumber,
      reference,
      description,
      licensePlateNumber
    );
  }

  getShopOrder(orderId: number): Observable<ShopOrder> {
    return this.apiService.getShopOrder(orderId);
  }

  getOutstandingBillsV2(billType: number): Observable<OutstandingBill[]> {
    return this.apiService.getOutstandingBills(billType);
  }

  getReturns(): Observable<ReturnHeader[]> {
    return this.apiService.getReturns();
  }

  getReturn(returnId: number): Observable<Return> {
    return this.apiService.getReturn(returnId);
  }

  getCurrentReturn(): Observable<Return> {
    return this.apiService.getCurrentReturn();
  }

  getRetourStatusDescription(status: number): string {
    switch (status) {
      case 0:
        return "Nieuw";
      case 1:
        return "Verstuurd";
      case 2:
        return "Afgehandeld";
      default:
        return "Onbekend";
    }
  }

  getReturnReasonDescription(reason: number): string {
    switch (reason) {
      case 0:
        return "Nieuw retour";
      case 1:
        return "Verkeerd besteld";
      case 2:
        return "Verkeerd geleverd";
      case 3:
        return "Anders";
      default:
        return "Onbekend";
    }
  }

  getReturnReasons(): ReturnReason[] {
    const result: ReturnReason[] = [];
    result.push({ ReasonId: 0, Description: "Nieuw retour" });
    result.push({ ReasonId: 1, Description: "Verkeerd besteld" });
    result.push({ ReasonId: 2, Description: "Verkeerd geleverd" });
    result.push({ ReasonId: 3, Description: "Anders" });
    return result;
  }

  postReturnDetail(returnId: number, detail: ReturnDetail): Observable<Object> {
    return this.apiService.postReturnDetail(returnId, detail);
  }

  deleteReturnDetail(returnId: number, detail: ReturnDetail): Observable<Object> {
    return this.apiService.deleteReturnDetail(returnId, detail);
  }

  sendReturn(returnId: number): Observable<ReturnOrder> {
    return this.apiService.sendReturn(returnId);
  }

  getDeliveryNoteItems(internalItemNumber: number, itemCount: number): Observable<DeliveryNoteItem[]> {
    return this.apiService.getDeliveryNoteItems(internalItemNumber, itemCount);
  }

  saveReturn(returnItem: ReturnHeader): Observable<Object> {
    return this.apiService.saveReturn(returnItem);
  }


}
