import { Component, OnInit, Input } from '@angular/core';

import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { ShopService } from '../../../_services/shop.service';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { ItemInfoService } from '../../../_services/item-info.service';
import { ShopRouteConfig } from '../../../_models/common/shop.route.config';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { PreviousRouteService } from '../../../_services/previous-route.service';
import { RouteObject } from '../../../_models/common/route.object';
import { SettingsService } from '../../../_services/settings.service';

@Component({
  selector: 'app-item-info-scroll-layout',
  templateUrl: './item-info-scroll-layout.component.html',
  styleUrls: ['./item-info-scroll-layout.component.scss']
})
export class ItemInfoScrollLayoutComponent implements OnInit {
  shopKind = ShopSoort;
  shopRouteConfig = ShopRouteConfig;
  @Input() ctx: ContextCatalog;

  constructor(
    public previousRouteService: PreviousRouteService,
    public shopService: ShopService,
    public catalogService: CatalogService,
    public mainService: MainService,
    public itemInfoService: ItemInfoService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  getBackUrl(): RouteObject {
    if (this.itemInfoService._itemInfoRequest.FromShop === ShopSoort.Catalogus) {
      const route = this.catalogService.getCategoryRoute(
        this.catalogService._lastCarType, this.catalogService._lastCategory, this.catalogService._lastDescription
      );
      if (route) { return route; }
    }
    return new RouteObject(['/' + ShopRouteConfig.routePaths[this.itemInfoService._itemInfoRequest.FromShop]]);
  }

}
