import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { Order } from '../../../_models/orders/order';


@Component({
  selector: 'app-open-orders-webshop',
  templateUrl: './open-orders-webshop.component.html',
  styleUrls: ['./open-orders-webshop.component.scss']
})
export class OpenOrdersWebshopComponent implements OnInit {

  orders: Order[];
  isBusy = false;

  constructor(
    public mainService: MainService,
    public sortService: SortService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
    this.sortService.initSortField('open-orders-headers', 'OrderDate', true);
    this.getOrders();
  }

  getOrders() {
    this.isBusy = true;
    this.customerInformationService.getOpenOrdersV2().subscribe(orders => {
      this.isBusy = false;
      this.customerInformationService.showPasswordPrompt = false;
      this.orders = orders;
    }, error => {
      this.isBusy = false;
      if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
      console.info(error);
    });

  }

}
