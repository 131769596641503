<div *ngIf="ctx && ctx.CompanyInfo">
  <div class="row">
    <div class="col">
      <span class="welcome" title="Klantnr:{{ctx.CompanyInfo.CustomerNumber}} UserID:{{ctx.CompanyInfo.UserID}} Session: {{authService.currentLogon.Session}}">Welkom {{ctx.CompanyInfo.CustomerName}}
        <span *ngIf="authService.currentLogon">({{authService.currentLogon.Login}})</span>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col loggedin">Ingelogd bij:</div>
    <div class="col">
      <div class="btn-group float-end" role="group">
        <button type="button" class="btn btn-warning" *ngIf="!ctx.CompanyInfo.LogoutDisabled" (click)="authService.gotoLogout()">
          uitloggen
          <i class="fas fa-sign-out-alt"></i>
        </button>
        <button type="button" class="btn btn-warning" *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1"
          (click)="authService.switchUser()" tooltip="Account wisselen" placement="bottom" container="body">
          <i class="fas fa-users"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col branch">
      {{ctx.CompanyInfo.BranchName}}
    </div>
  </div>
</div>
