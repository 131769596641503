import { Component, OnInit, Input } from '@angular/core';
import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { CatalogService } from '../../../_services/catalog.service';

@Component({
  selector: 'app-catalog-part-special-tools',
  templateUrl: './catalog-part-special-tools.component.html',
  styleUrls: ['./catalog-part-special-tools.component.scss']
})
export class CatalogPartSpecialToolsComponent implements OnInit {
  @Input() ctx: ContextCatalog;
  @Input() part: Part;

  constructor(
    public catalogService: CatalogService
  ) { }

  ngOnInit() {
  }

}
