import { trigger, state, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

export class Animations {

    public static slideInOut = trigger('slideInOut', [
        transition(':enter', [
            style({ height: '0px' }),
            animate('300ms ease-in', style({ height: '*' }))
        ]),
        transition(':leave', [
            animate('300ms ease-in', style({ height: '0px' }))
        ])
    ]);

    public static slideAndFadeInOut = trigger('slideAndFadeInOut', [
        transition(':enter', [
            style({ height: '0px', minHeight: '0px', opacity: '0' }),
            animate('300ms ease-in', style({ height: '*', opacity: '*' }))
        ]),
        transition(':leave', [
            animate('300ms ease-in', style({ height: '0px', minHeight: '0px', opacity: '0' }))
        ])
    ]);

    public static slideInHorizontal = trigger('slideInHorizontal', [
        state('true', style({ height: '0px' })),
        state('false', style({ height: '*' })),
        transition('1 => 0', animate('500ms ease-in')),
        transition('0 => 1', animate('500ms ease-out'))
    ]);

    public static slideInVertical = trigger('slideInVertical', [
        state('true', style({ width: '0px' })),
        state('false', style({ width: '*' })),
        transition('1 => 0', animate('500ms ease-in')),
        transition('0 => 1', animate('500ms ease-out'))
    ]);

    public static slideInCart = trigger('slideInCart', [
        state('true', style({ flex: '0 0 0', opacity: '0' })),
        state('false', style({ flex: '0 0 240px', opacity: '*' })),
        transition('1 => 0', animate('500ms ease-in')),
        transition('0 => 1', animate('500ms ease-out'))
    ]);

    public static slideInMenu = trigger('slideInMenu', [
        state('true', style({ flexBasis: '0' })),
        state('false', style({ flexBasis: '200px' })),
        transition('1 => 0', animate('500ms ease-in')),
        transition('0 => 1', animate('500ms ease-out'))
    ]);

    public static showStaticMenu = trigger('showStaticMenu', [
        state('true', style({ opacity: '1', zIndex: 1008 })),
        state('false', style({ opacity: '0', zIndex: -1 })),
        transition('1 => 0', animate('250ms ease-in')),
        transition('0 => 1', animate('250ms ease-out'))
    ]);

    public static fadeInOut = trigger('fadeInOut', [
        state('true', style({ opacity: '1', zIndex: 1008  })),
        state('false', style({ opacity: '0', zIndex: -1 })),
        transition('1 => 0', animate('500ms linear')),
        transition('0 => 1', animate('500ms linear'))
    ]);

    public static fadeScrollButton = trigger('fadeScrollButton', [
        state('true', style({ opacity: '.7' })),
        state('false', style({ opacity: '0' })),
        transition('1 => 0', animate('500ms linear')),
        transition('0 => 1', animate('1500ms linear'))
    ]);

    public static enterLeaveBounce = trigger('enterLeaveBounce', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), { optional: true }),

            query(':enter', stagger('100ms', [
                animate('.6s ease-in', keyframes([
                    style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                    style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
                    style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
                ]))]), { optional: true })
            ,
            query(':leave', stagger('100ms', [
                animate('.6s ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                    style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
                    style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
                ]))]), { optional: true })
        ])
    ]);

    public static enterLeaveAppear = trigger('enterLeaveAppear', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), { optional: true }),

            query(':enter', stagger('100ms', [
                animate('.5s ease-in', keyframes([
                    style({ opacity: 0 }),
                    style({ opacity: 1 })
                ]))]), { optional: true })
            ,
            query(':leave', stagger('100ms', [
                animate('.5s ease-out', keyframes([
                    style({ opacity: 1 }),
                    style({ opacity: 0 })
                ]))]), { optional: true })
        ])
    ]);

    public static toastAppearLeave = trigger('toastAppearLeave', [
        transition('* => *', [

            query(':enter', style({ height: '0px', minHeight: '0px', opacity: 0 }), { optional: true }),

            query(':enter', stagger('100ms', [
                animate('.6s ease-in', keyframes([
                    style({ opacity: 0, transform: 'translateX(100%)', offset: 0 }),
                    style({ height: '*', opacity: .5, transform: 'translateX(-15px)', offset: 0.3 }),
                    style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
                ]))]), { optional: true })
            ,
            query(':leave', stagger('100ms', [
                animate('300ms ease-in', style({ height: '0px', minHeight: '0px', opacity: '0' }))
                ]), { optional: true })
        ])
      ]);

    // public static toastAppearLeave = trigger('toastAppearLeave', [
    //     state('flyIn', style({ transform: 'translateX(0)' })),
    //     transition(':enter', [
    //       style({ transform: 'translateX(100%)' }),
    //       animate('0.5s 300ms ease-in')
    //     ]),
    //     transition(':leave', [
    //       animate('0.3s ease-out', style({ transform: 'translateX(-100%)' }))
    //     ])
    //   ]);

}
