import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ContextPortalSettings } from '../_models/portal-settings/context-portal-settings';
import { ApiService } from './api.service';
import { PortalSettingGroup } from '../_models/portal-settings/portal-setting-group';


@Injectable({
  providedIn: 'root'
})
export class PortalSettingsService {
  ctx: ContextPortalSettings;

  constructor(
    private apiService: ApiService
  ) { }

  clear(): void {
    this.ctx = null;
  }

  getContext(): Observable<ContextPortalSettings> {
    if (this.ctx) {
      return of(this.ctx);
    } else {
      return this.apiService.getContextPortalSettings()
        .pipe(mergeMap((ctx => {
          this.ctx = ctx;
          return of(this.ctx);
        })));
    }
  }

  getGroupFromContext(ctx: ContextPortalSettings, groupId: number): PortalSettingGroup {
    const group = ctx.SettingGroups.find(g => g.Id === groupId);
    if (group) { return group; }
    return null;
  }

  getGroup(groupId: number): Observable<PortalSettingGroup> {
    return this.getContext()
      .pipe(mergeMap((ctx => {
        return of(this.getGroupFromContext(ctx, groupId));
      })));
  }

  saveGroup(group: PortalSettingGroup): Observable<boolean> {
    return this.apiService.savePortalSettingGroup(group)
      .pipe(mergeMap((ok => {
        return of(ok);
      })));
  }

}
