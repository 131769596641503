import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextBase } from '../../../_models/common/context.base';
import { TimingObject } from '../../../_models/common/timing.object';
import { MainService } from '../../../_services/main.service';
import { ApiService } from '../../../_services/api.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { Settings } from '../../../_models/common/settings';
import { Animations } from '../../../_animations/animations';
import { CatalogPropertyService } from '../../../_services/catalog-property.service';
import { PropertyKindCatalog } from '../../../_models/catalog/property.kind.catalog';

@Component({
  selector: 'app-header-panel-inner',
  templateUrl: './header-panel-inner.component.html',
  styleUrls: ['./header-panel-inner.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class HeaderPanelInnerComponent implements OnInit {
  @Input() ctx: ContextBase;
  @Input() timing: TimingObject;
  @Input() preText: string;
  @Output() backClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() hideBackClick: boolean;
  @Input() withoutCarType: boolean;

  constructor(
    public mainService: MainService,
    private apiService: ApiService,
    public carTypeService: CarTypeService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
  }

  showBackButton(): boolean {
    return !this.hideBackClick && this.backClick.observers.length > 0;
  }

  clickCarTypeExtraDataCollapsed(ctx) {
    if (ctx) {
      ctx.CarTypeExtraDataCollapsed = !ctx.CarTypeExtraDataCollapsed;
      this.apiService.saveSettings(new Settings('CarTypeExtraDataCollapsed', ctx.CarTypeExtraDataCollapsed));
      setTimeout(() => {
        this.mainService.fireReziseEvent();
      }, 300);
      setTimeout(() => {
        this.mainService.fireReziseEvent();
      }, 1000);
      return false;
    }
  }

  getTimingObject(): TimingObject {
    if (this.timing) { return this.timing; }
    if (this.ctx && this.ctx.Timing) { return this.ctx.Timing; }
    if (this.ctx && this.ctx['LoginTiming']) { return this.ctx['LoginTiming']; }
  }

  getWarningsHtml() {
    const t = this.getTimingObject();
    if (t && t.Warnings) {
      return t.Warnings.join('<br />');
    }
    return null;
  }

  hasRDWproperties(): boolean {
    if (!this.withoutCarType && this.ctx && this.ctx.CarType) {
      const cartype = this.ctx.CarType;
      if (this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationBrand)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationModel)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationDescription)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationVersion)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationDate)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.Color)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.ApkExpiration)
        || this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.FirstAdmission)
      ) {
        return true;
      }
    }
    return false;
  }

}
