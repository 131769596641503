import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectListItem } from '../../../../_models/common/select.list.item';

@Component({
  selector: 'app-side-panel-select',
  templateUrl: './side-panel-select.component.html',
  styleUrls: ['./side-panel-select.component.scss']
})
export class SidePanelSelectComponent implements OnInit {
  @Input() descriptions: SelectListItem[];
  @Input() selected: any;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


}
