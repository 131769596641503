import { Pipe, PipeTransform } from '@angular/core';
import { OnlineSession } from '../../../_models/admin/statistics/online.session';

@Pipe({
  name: 'filterSessions'
})
export class FilterSessionsPipe implements PipeTransform {

  transform(sessions: OnlineSession[], filterString: string): any {
    if (!filterString) { return sessions; }
    return sessions.filter(session => {
      const search = filterString.toLowerCase();
      if (session) {
        if (session.CustomerName.toLowerCase().indexOf(search) >= 0) {return true; }
        if (session.Username.toLowerCase().indexOf(search) >= 0) {return true; }
        if (session.LoginID.toString()===search) {return true; }
        if (session.WholesalerName.toLowerCase().indexOf(search) >= 0) {return true; }
        if (session.BranchName.toLowerCase().indexOf(search) >= 0) {return true; }
        if (session.ModuleName.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.IP.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.Hostname.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.Platform.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.PlatformVersion.toLowerCase()===search)  {return true; }
        if (session.Browser.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.BrowserVersion.toLowerCase()===search)  {return true; }
        if (session.FrontendVersion.toLowerCase()===search)  {return true; }
        if (session.BackendVersion.toLowerCase()===search)  {return true; }
        if (session.Environment.toLowerCase().startsWith(search))  {return true; }
        if (session.ThirdPartyCustomerName.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.Device.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.Brand.toLowerCase().indexOf(search) >= 0)  {return true; }
        if (session.Model.toLowerCase().indexOf(search) >= 0)  {return true; }
      }
      return false;
    });
  }

}
