<div *ngIf="part.Properties && part.Properties[property.Item1]" class="row parts-property-list">
  <div class="col-5 label">{{partsPropertyTranslations[property.Item1].NL}}</div>
  <div class="col-7">
    <span *ngFor="let prop of part.Properties[property.Item1]; let last=last; let idx=index;">
      <ng-container *ngIf="more || idx<=maxItems">
        <span class="link"><app-part-item-number-link [partItemNumber]="prop.Value" [description]="getBrand(prop)" [shopKind]="shopKind"></app-part-item-number-link></span>
        <span *ngIf="!last">, </span>
      </ng-container>
    </span>
    <ng-container *ngIf="!more && part.Properties[property.Item1].length > maxItems">
      <span class="link" (click)="more=!more"><i class="fas fa-caret-square-right more ms-1" tooltip="klik hier voor meer."
          placement="right" container="body"></i></span>
    </ng-container>
    <ng-container *ngIf="more && part.Properties[property.Item1].length > maxItems">
      <span class="link" (click)="more=!more"><i class="fas fa-caret-square-left more ms-1" tooltip="klik hier voor minder."
          placement="left" container="body"></i></span>
    </ng-container>
  </div>
</div>
