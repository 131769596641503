import { Component, OnInit, Input } from '@angular/core';

import { TimingObject } from '../../../_models/common/timing.object';
import { TimingClassItem } from '../../../_models/common/timing.class.level';
import { TimingItem } from '../../../_models/common/timing.item';


@Component({
  selector: 'app-timing-object-display',
  templateUrl: './timing-object-display.component.html',
  styleUrls: ['./timing-object-display.component.scss']
})
export class TimingObjectDisplayComponent implements OnInit {
  @Input() timingObject: TimingObject;
  @Input() startTimeStamp: number;
  @Input() flatTimings: TimingItem[];
  @Input() depth: number;
  @Input() startAlt: boolean;
  @Input() collapseState: boolean;
  @Input() classLevels: TimingClassItem[];

  constructor() { }

  ngOnInit() {
    if (!this.depth) { this.depth = 0; }
    if (!this.startAlt) { this.startAlt = false; }
  }

  useEven(even: boolean) {
    if (this.startAlt) { return !even; }
    return even;
  }

  getStartTimeStamp(): number {
    if (this.startTimeStamp) { return this.startTimeStamp; }
    if (this.timingObject && this.timingObject.Timings.length > 0) { return this.timingObject.Timings[0].TimeStamp; }
    return 0;
  }


}
