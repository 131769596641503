import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';

@Pipe({
  name: 'adminCatalogLinesFilter'
})
export class AdminCatalogLinesFilterPipe implements PipeTransform {

  hasFilterHit(regexFilter: RegExp, kvp: { key: string, value: any }, depth: number): boolean {
    let hit = false;
    if (kvp.key && kvp.key.search(regexFilter) !== -1) { hit = true; }
    if (!hit) {
      const keys = Object.keys(kvp.value);
      if (keys && keys.length > 0) {
        keys.forEach(key => {
          if (key.search(regexFilter) !== -1) { hit = true; }
          if (!hit && depth < 2) {
            if (this.hasFilterHit(regexFilter, { key: null, value: kvp.value[key] }, depth + 1)) { hit = true; }
          }
        });
      }
    }
    return hit;
  }

  transform(keyValuePairs: { key: string, value: any }[], filter: string, depthAdjust: number): any {
    if (keyValuePairs && filter) {
      const regexFilter = new RegExp(filter, 'i');
      const filtered = keyValuePairs.filter(kvp => {
        return this.hasFilterHit(regexFilter, kvp, depthAdjust);
      });
      return filtered;
    }
    return keyValuePairs;
  }

}
