import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

import { Animations } from '../_animations/animations';
import { ContextCart } from '../_models/cart/context.cart';
import { MainService } from '../_services/main.service';
import { CartService } from '../_services/cart.service';
import { AuthService } from '../_services/auth.service';
import { OrderRequestParams } from '../_models/cart/order.request.params';
import { ContextMain } from '../_models/common/context.main';
import { DeliveryChoice } from '../_models/cart/delivery.choice';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { ShopService } from '../_services/shop.service';
import { RouteInfo } from '../_models/item-info/route.info';
import { ShopPortalMessage } from '../_models/common/shop.portal.message';
import { ShopPortalMessageButton } from '../_models/common/shop.portal.message.button';
import { DeliveryMomentKind } from '../_models/cart/delivery.moment.kind';
import { CarTypeService } from '../_services/car-type.service';
import { ShoppingCartItemComposite } from '../_models/cart/shopping.cart.item.composite';
import { SettingsService } from '../_services/settings.service';
import { LayoutKind } from '../_models/common/layout.kind';
import { Router } from '@angular/router';
import { LicensePlateService } from '../_services/license-plate.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [Animations.enterLeaveAppear]
})
export class CartComponent implements OnInit {
  layoutKind = LayoutKind;
  ctx: ContextCart;
  ctxMain: ContextMain;
  Order: OrderRequestParams;
  test = false;

  constructor(
    public mainService: MainService,
    public cartService: CartService,
    public authService: AuthService,
    public licensePlateService: LicensePlateService,
    public shopService: ShopService,
    public carTypeService: CarTypeService,
    public settingsService: SettingsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.mainService.getContextMain('', true)
      .subscribe((ctxMain) => {
        this.ctxMain = ctxMain;
        if (this.ctxMain) {
          this.ctx = this.cartService.getCartContext();
          this.Order = Object.assign({}, this.ctx.DefaultOrderRequest);
        }
      });
  }

  showNettoPrices(): boolean {
    return this.ctx?.ToonNettoPrijzen != 2 && !this.cartService.getNetPriceHidden();
  }

  enableDisplayAfleverKeuze(choice: DeliveryChoice): boolean {
    if (choice) {
      return (choice.DeliveryMethod === -1 ||
        (choice.DeliveryMethod === 0 && this.Order.Levermode !== 1) ||
        (choice.DeliveryMethod === 1 && this.Order.Levermode === 1));
    }
    return false;
  }

  isValidAfleverKeuze(choice: DeliveryChoice): boolean {
    if (choice) {
      const currentDate = new Date();
      return (choice.ValidFrom && choice.ValidThru && ((choice.ValidFrom === choice.ValidThru)
        || (choice.ValidFrom.getTime && choice.ValidThru.getTime &&
          ((choice.ValidFrom.getTime() === choice.ValidThru.getTime()) ||
            (choice.ValidFrom < currentDate && choice.ValidThru > currentDate)))));
    }
    return false;
  }

  isChosenAfleverKeuze(choice: DeliveryChoice): boolean {
    return (choice && this.Order.AfleverKeuze && choice.ID === this.Order.AfleverKeuze.ID);
  }

  anyValidAfleverKeuzes(): boolean {
    let ok = false;
    if (this.ctx.AfleverKeuzes && this.ctx.AfleverKeuzes.length > 0) {
      for (const choice of this.ctx.AfleverKeuzes) {
        if (this.enableDisplayAfleverKeuze(choice) && this.isValidAfleverKeuze(choice)) {
          ok = true;
        }
      }
    }
    if (!ok) { this.Order.AfleverKeuze = null; }
    return ok;
  }

  clickChoice(choice: DeliveryChoice) {
    console.info(`clickChoice: ${choice.ChoiceDescription}`);
    if (this.isValidAfleverKeuze(choice)) {
      this.Order.AfleverKeuze = choice;
    }
  }

  isChosenRoute(route: RouteInfo): boolean {
    return (route && this.Order.Route && route.HumanStartDagTijd === this.Order.Route.HumanStartDagTijd);
  }

  clickRoute(route: RouteInfo) {
    this.Order.Route = route;
  }

  clearShoppingcart() {
    this.Order = Object.assign({}, this.ctx.DefaultOrderRequest);
    this.ctx.Routes = [];
  }

  emptyShoppingcart() {
    this.mainService.confirmBox('Weet u zeker dat u de winkelwagen wilt leeg maken?')
      .subscribe(result => {
        if (result) {
          this.cartService.emptyShoppingcart()
            .subscribe(emptied => {
              if (emptied) {
                this.clearShoppingcart();
                // cartService.broadcastCartChange(CS.ShopSoort.ShoppingCart, null);
                if (this.ctx.DeleteOrderCompleteNavigateUrl.length !== 0) {
                  location.href = this.ctx.DeleteOrderCompleteNavigateUrl;
                }
              }
            });
        }
      });
  }

  orderShoppingCart() {
    if (this.ctx.GeenRoutetijdenKeuzeInShoppingCart) {
      if ((!this.isValidAfleverKeuze(this.Order.AfleverKeuze) || !this.enableDisplayAfleverKeuze(this.Order.AfleverKeuze))
        && this.anyValidAfleverKeuzes() && !this.cartService.isCartEmpty()) {
        this.mainService.msgBox('Let op!', 'Er moet nog een levering gekozen worden.');
      } else {
        this.doOrder();
      }
      // } else if (this.ctx.RouteKeuze && this.ctx.RouteKeuzeBijRoute
      //             && this.ctx.RouteKeuzeBijRoute.HumanStartDagTijd === this.Order.Route.HumanStartDagTijd) {
      //   this.cartService.RouteKeuzeDialog(this.ctx.RouteKeuzeMessage, this.ctx.RouteKeuze,
      // this.Order.Levermode === 1).then(function (result) {
      //     if (result) {
      //       $scope.Context.Routes.forEach(function (r) {
      //         if (r.HumanStartDagTijd === result.Route.HumanStartDagTijd) {
      //           $scope.Order.Route = r;
      //         }
      //       });
      //       $scope.doOrder();
      //     }
      //   });
    } else {
      this.doOrder();
    }
  }

  doOrder() {
    if (!this.Order.Opmerking && this.cartService.isCartEmpty()) {
      this.mainService.msgBox('Let op!', 'Er moet minstens een opmerking gevuld zijn of er moeten artikelen in de winkelwagen staan.');
    } else if (this.ctx.BestelKnopNavigateUrl.length === 0 && this.ctx.BestelKnopPopupTekst.length === 0) {
      if (this.cartService.deliveryMomentChoiceNeeded(this.cartService.getCartItems())
        && this.Order.DeliveryMoment === DeliveryMomentKind.Unknown) {
        this.mainService.msgBox(
          'Let op!',
          'Er moet gekozen worden of de items die op voorraad zijn vast uitgeleverd moeten worden, of pas wanneer de order compleet is.'
        );
      } else {
        if (!this.cartService.deliveryMomentChoiceNeeded(this.cartService.getCartItems())) {
          this.Order.DeliveryMoment = DeliveryMomentKind.Unknown;
        }
        this.Order.TimeStamp = new Date();
        const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'Uw bestelling wordt verzonden.', '');
        this.cartService.orderShoppingCart(this.Order)
          .subscribe(response => {
            if (response && response.OrderNumberWholesaler) {
              cb.complete();
              // this.clearShoppingcart();
              this.cartService.updateCart(true)
              // cartService.broadcastCartChange(CS.ShopSoort.ShoppingCart, null);
              const message = new ShopPortalMessage(
                'Bedankt voor uw bestelling!',
                `Het ordernummer van deze bestelling is ${response.OrderNumberWholesaler}.`,
                [new ShopPortalMessageButton('Ok', 'OK', 'btn-primary')],
                response.Timing
              );
              if (response.PdfUrl) {
                message.Buttons.push(new ShopPortalMessageButton('Print', 'PRINT', 'btn-warning'));
              }
              if (response.OrderNumberWholesaler === -1) {
                message.Text = 'Deze bestelling is ontvangen bij uw leverancier';
              }
              this.mainService.msgBoxExtended(message)
                .subscribe(command => {
                  if (command === 'PRINT') {
                    const url = this.mainService.getBackendApi();
                    const grossier = this.ctxMain.CompanyInfo.Wholesaler;
                    const custId = this.ctxMain.CompanyInfo.CustomerNumber;
                    const userId = this.ctxMain.CompanyInfo.UserID;
                    window.open(response.PdfUrl);
                  }
                  if (this.ctx.OrderCompleteNavigateUrl.length !== 0) {
                    location.href = this.ctx.OrderCompleteNavigateUrl;
                  }
                });
            } else if (response && response.ErrorMessage) {
              cb.complete();
              const message = new ShopPortalMessage(
                'Let op!',
                response.ErrorMessage,
                [new ShopPortalMessageButton('Ok', 'OK', 'btn-primary')],
                response.Timing
              );
              this.mainService.msgBoxExtended(message).subscribe();
            } else {
              //Geen ontwoord ontvangen... probeer CheckOrder
              this.cartService.checkOrder(this.Order.TimeStamp)
                .subscribe(response => {
                  if (response && response.OrderNumberWholesaler) {
                    cb.complete();
                    // this.clearShoppingcart();
                    this.cartService.updateCart(true)
                    // cartService.broadcastCartChange(CS.ShopSoort.ShoppingCart, null);
                    const message = new ShopPortalMessage(
                      'Bedankt voor uw bestelling!',
                      `Het ordernummer van deze bestelling is ${response.OrderNumberWholesaler}.`,
                      [new ShopPortalMessageButton('Ok', 'OK', 'btn-primary')],
                      response.Timing
                    );
                    if (response.PdfUrl) {
                      message.Buttons.push(new ShopPortalMessageButton('Print', 'PRINT', 'btn-warning'));
                    }
                    if (response.OrderNumberWholesaler === -1) {
                      message.Text = 'Deze bestelling is ontvangen bij uw leverancier';
                    }
                    this.mainService.msgBoxExtended(message)
                      .subscribe(command => {
                        if (command === 'PRINT') {
                          const url = this.mainService.getBackendApi();
                          const grossier = this.ctxMain.CompanyInfo.Wholesaler;
                          const custId = this.ctxMain.CompanyInfo.CustomerNumber;
                          const userId = this.ctxMain.CompanyInfo.UserID;
                          window.open(response.PdfUrl);
                        }
                        if (this.ctx.OrderCompleteNavigateUrl.length !== 0) {
                          location.href = this.ctx.OrderCompleteNavigateUrl;
                        }
                      });
                  } else {
                    cb.complete();
                    const message = new ShopPortalMessage(
                      'Let op!',
                      'We hebben geen antwoord ontvangen. Raadpleeg uw orderhistorie of de order in het systeem staat.',
                      [new ShopPortalMessageButton('Ok', 'OK', 'btn-primary'), new ShopPortalMessageButton('Orderhistorie', 'HISTORY', 'btn-warning')],
                      response.Timing
                    );
                    this.mainService.msgBoxExtended(message)
                      .subscribe(command => {
                        if (command === 'HISTORY') {
                          this.router.navigateByUrl('/details/history')
                        }
                        if (this.ctx.OrderCompleteNavigateUrl.length !== 0) {
                          location.href = this.ctx.OrderCompleteNavigateUrl;
                        }
                      });
                  }
                });
            }
          });
      }
    } else if (this.ctx.BestelKnopNavigateUrl && this.ctx.BestelKnopNavigateUrl.length !== 0) {
      location.href = this.ctx.BestelKnopNavigateUrl;
    } else if (this.ctx.BestelKnopPopupTekst.length !== 0) {
      this.mainService.msgBox('Let op!', this.ctx.BestelKnopPopupTekst);
    }
  }


}
