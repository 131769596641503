import { Component, OnInit, Input } from '@angular/core';

import { MainService } from '../../../../../_services/main.service';
import { CatalogLayoutService } from '../../../../../_services/catalog-layout.service';
import { CatalogLayoutSubgroupCategoryCrossLink } from '../../../../../_models/admin/settings/catalog.layout.subgroup.category.cross.link';
import { CatalogLayoutFilterSettings } from '../../../../../_models/admin/settings/catalog.layout.filter.settings';
import { SettingsCatalogLayout } from '../../../../../_models/admin/settings/settings.catalog.layout';

@Component({
  selector: 'app-admin-catalog-layout-category-info',
  templateUrl: './admin-catalog-layout-category-info.component.html',
  styleUrls: ['./admin-catalog-layout-category-info.component.scss']
})
export class AdminCatalogLayoutCategoryInfoComponent implements OnInit {
  @Input() category: CatalogLayoutSubgroupCategoryCrossLink;
  @Input() settings: SettingsCatalogLayout;
  @Input() filter: CatalogLayoutFilterSettings;
  sortMode = 0;
  displayHiddenLines = true;

  constructor(
    public mainService: MainService,
    public catalogLayoutService: CatalogLayoutService
  ) { }

  ngOnInit() {
  }

  getFilterStringDescription(filter: CatalogLayoutFilterSettings) {
    if (filter && filter.extendedFilter) { return filter.partsDescriptionFilterString; }
    if (filter && !filter.extendedFilter && filter.withPartsDescriptions) { return filter.filterString; }
    return null;
  }

  getFilterStringBrand(filter: CatalogLayoutFilterSettings) {
    if (filter && filter.extendedFilter) { return filter.brandFilterString; }
    if (filter && !filter.extendedFilter && filter.withBrands) { return filter.filterString; }
    return null;
  }

}
