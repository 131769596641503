import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { SettingsModules } from '../../../_models/admin/settings/settings.modules';
import { ShopModule } from '../../../_models/common/shop.module';
import { ContextAdmin } from '../../../_models/admin/context.admin';

import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { ShopSoort } from '../../../_models/common/shop.soort';


@Component({
  selector: 'app-admin-settings-modules',
  templateUrl: './admin-settings-modules.component.html',
  styleUrls: ['./admin-settings-modules.component.scss']
})
export class AdminSettingsModulesComponent implements OnInit {
  settings: SettingsModules;
  ctx: ContextAdmin;
  startUpModuleId: number;
  selected: ShopModule;
  showHiddenModules: boolean;
  lastUpdated: number;
  shopSoort = ShopSoort;

  settingsModules = new UntypedFormGroup({
    ShopPortalSettingsBackendAreLeading: new UntypedFormControl(null),
    Modules: new UntypedFormControl(null)
  });


  constructor(
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.initStartUpModule(this.settings);
      });
  }

  getSettings(wholesaler: number, branch: number, customer: number, userid: number) {
    this.adminSettingsService.getSettingsModules(this.settings.UID)
      .subscribe(settings => {
        this.settings = settings;
        this.initStartUpModule(this.settings);
      });
  }

  selectorChanged(settings: SettingsModules) {
    this.getSettings(settings.UID.Wholesaler, settings.UID.Branch, settings.UID.Customer, settings.UID.UserID);
    this.lastUpdated = Date.now();
  }

  setStartUpModule(settings: SettingsModules, id: number) {
    this.startUpModuleId = id;
    settings.Modules.forEach(module => {
      module.IsStartupShop = (module.ID === id);
    });
  }

  setFirstPossibleStartUpModule(settings: SettingsModules, butNot: number) {
    if (settings && settings.Modules && settings.Modules.length > 0) {
      const startUpShop = settings.Modules.find(m => m.Active && !m.Hide && m.ID !== butNot);
      if (startUpShop) { this.setStartUpModule(settings, startUpShop.ID); }
    }
  }

  initStartUpModule(settings: SettingsModules) {
    if (settings && settings.Modules && settings.Modules.length > 0) {
      const startUpShop = settings.Modules.find(m => m.IsStartupShop && m.Active && !m.Hide);
      if (startUpShop) {
        this.startUpModuleId = startUpShop.ID;
      } else {
        this.setFirstPossibleStartUpModule(settings, -1);
      }
    }
  }

  startUpChanged(module: ShopModule) {
    if (module && this.settings && this.settings.Modules && this.settings.Modules.length > 0) {
      if (!module.IsStartupShop) {
        this.setFirstPossibleStartUpModule(this.settings, module.ID);
      } else {
        this.setStartUpModule(this.settings, module.ID);
      }
    }
  }

  getCorrectedIndex(index: number): number {
    if (this.showHiddenModules) { return index; }
    let shift = 0;
    if (this.settings && this.settings.Modules && this.settings.Modules.length > 0 && this.settings.Modules.length) {
      let i = 0;
      while (i <= index + shift) {
        if (this.settings.Modules[i++].Hide) { shift++; }
      }
    }
    return index + shift;
  }

  getModuleByIndex(index: number): ShopModule {
    if (this.settings && this.settings.Modules && this.settings.Modules[index]) {
      return this.settings.Modules[index];
    }
    return null;
  }

  getDefaultModuleById(id: number): ShopModule {
    if (this.settings && this.settings.DefaultModules && this.settings.DefaultModules[id]) {
      return this.settings.DefaultModules[id];
    }
    return null;
  }

  drop(event: CdkDragDrop<ShopModule[]>) {
    moveItemInArray(this.settings.Modules, this.getCorrectedIndex(event.previousIndex), this.getCorrectedIndex(event.currentIndex));
    const module = this.getModuleByIndex(this.getCorrectedIndex(event.currentIndex));
    // ## 2019-07-06 Maurice: Hacky solution omdat anders de radio checked verdwijnt
    //                        ivm dubbel (dus niet meer uniek) in DOM door drag/drop
    if (module && module.IsStartupShop) {
      this.setStartUpModule(this.settings, -1);
      setTimeout(() => { this.setStartUpModule(this.settings, module.ID); }, 0);
    }
    // ## Einde hacky solution
  }

  onStartUpChange(module: ShopModule) {
    this.setStartUpModule(this.settings, module.ID);
  }

}
