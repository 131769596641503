<div class="container my-3">
  <div class="row">
    <div class="col">
      <div class="card mb-3 login-height">
        <h4 class="card-header">Welkom bij Rijpma Automaterialen</h4>
        <div class="card-body">
          <h5><em>Voor al uw automaterialen en accessoires</em></h5><br />
          <h6>U kunt hier inloggen op ons bestelportaal.</h6>
          <p class="card-text">
            Heeft u nog geen login? Deze kunt u aanvragen via onderstaande contact informatie.
          </p>
        </div>
      </div>
    </div>

    <div class="col-lg-4">

      <div class="card border-primary login-panel login-height">
        <div class="card-header  text-bg-primary"
          (contextmenu)="authService.chooseWholesaler($event, ctx.Grossiers, wholesalerChange)">
          <span *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1"
            (click)="authService.chooseCookieList(ctx.Wholesaler, false)" class="float-end pointer"><i
              class="fas fa-users"></i></span>
          <span *ngIf="!ctx.Layout.LoginPanelLogo" class="card-title">Login</span>
          <img *ngIf="ctx.Layout.LoginPanelLogo" [src]="'/assets' + ctx.Layout.LoginPanelLogo" />
        </div>
        <div class="card-body">
          <form accept-charset="UTF-8" role="form">
            <div class="form-group">
              <input class="form-control login-input" [appAutofocus]="true" placeholder="Loginnaam" name="Username"
                [(ngModel)]="authService.loginData.Username" type="text" (keyup.enter)="keytab($event)">
              <input class="form-control login-input" placeholder="Wachtwoord" name="Password"
                [(ngModel)]="authService.loginData.Password" [type]="isPwVisible ? 'text' : 'password'"
                (dblclick)="isPwVisible = mainService.swicthIfWithAltShift($event, isPwVisible)"
                (keyup.enter)="authService.doLogin(ctx.Wholesaler)">
            </div>
            <div class="checkbox login-input">
              <label>
                <input name="remember" type="checkbox" [(ngModel)]="authService.loginData.KeepLogin"> Logingegevens
                onthouden
              </label>
            </div>
            <button class="btn btn-primary w-100" (click)="authService.doLogin(ctx.Wholesaler)">Login</button>
            <app-manage-account></app-manage-account>
          </form>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="card bottom-height">
        <div class="row">
          <div class="col"><img src="/assets/img/identity/rijpma/rijpma.jpg" class="img-fluid"></div>
          <div class="col">
            <div class="card-body">
              <h6 class="card-title">Rijpma Automaterialen</h6>
              <p class="card-text">
                Kanaalstraat 72 <br />
                9301 LT Roden <br />
                Tel: 050-5019197 <br />
                Fax: 050-5016315 <br />
                Email: <a href="mailto:info@rijpmaroden.nl">info&#64;rijpmaroden.nl</a> <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card bottom-height">
        <h6 class="card-header">Openingstijden</h6>
        <div class="card-body">
          <div class="row">
            <div class="col-4">MA</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">DI</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">WO</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">DO</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">VR</div>
            <div class="col-8 text-end">8:00-17:30</div>
            <div class="col-4">ZA</div>
            <div class="col-8 text-end">8:00-16:00</div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>