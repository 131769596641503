<div class="modal-header center">
  <h3 class="modal-title" (contextmenu)="mainService.showObject($event, editInterfaceData)"><span *ngIf="!addNew">Wijzigen</span><span *ngIf="addNew">Toevoegen</span> proxy koppeling</h3>
</div>
<div class="modal-body text-bg-light">
  <app-admin-input [labelCols]="4" [label]="'Username'" [dividerKind]="1" [(model)]="editInterfaceData.Username" [placeholder]="kindInfo.HintUsername"></app-admin-input>
  <app-admin-input [labelCols]="4" [label]="'Password'" [dividerKind]="1" [(model)]="editInterfaceData.Password" [placeholder]="kindInfo.HintPassword"></app-admin-input>
  <app-admin-checkbox [labelCols]="4" [label]="'Extra logging'" [dividerKind]="1" [(model)]="extraLogging" (modelChanged)="adminSupplierInterfaceService.setProxyExtraLogging(editInterfaceData, extraLogging)"></app-admin-checkbox>
  <app-admin-checkbox [labelCols]="4" [label]="'Test URL'" [dividerKind]="1" [(model)]="testUrl" (modelChanged)="adminSupplierInterfaceService.setProxyTestUrl(editInterfaceData, testUrl)"></app-admin-checkbox>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-2" (click)="cancel()"><i class="fas fa-undo"></i> Annuleren</button>
  <button type="button" class="btn btn-primary" (click)="confirm()"><i class="fas fa-check"></i> Opslaan</button>
</div>