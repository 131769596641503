import { forEach } from '@angular-devkit/schematics';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByProperty',
  pure: false
})
export class SortByPropertyPipe implements PipeTransform {

  getPropertyValue(object: any, property: string): any {
    if (!object) { return null; }
    const arr = property.split('.');
    while (arr.length && (object = object[arr.shift()])) { }
    return object;
  }

  getItemValue(object: any, property: string): any {
    let value = this.getPropertyValue(object, property);
    if (value && value.valueOf) { value = value.valueOf(); }
    return value;
  }

  getEquality(item1: any, item2: any, field: string): number {
    let direction = 1;
    if (field.startsWith('-')) {
      direction = -1;
      field = field.substring(1);
    }
    const value1 = this.getItemValue(item1, field);
    const value2 = this.getItemValue(item2, field);
    if (value1 === null && value2 !== null) { return 1; }
    if (value1 !== null && value2 === null) { return -1; }
    if (value1 > value2) { return 1 * direction; }
    if (value1 < value2) { return -1 * direction; }
    return 0;
  }


  transform(values: any[], propertyNames: string): any[] {
    if (values) {
      const propertyName = propertyNames.split(',');
      let result = values;
      for (let index = propertyName.length-1; index >= 0; index--) {
        result = result.sort((v1, v2) => this.getEquality(v1, v2, propertyName[index]));
      };
      return result;
    }
    return values;
  }

}
