import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';

import { ShopSoort } from '../../_models/common/shop.soort';
import { PaintRequest } from '../../_models/paint/paint.request';
import { ApiService } from '../../_services/api.service';
import { CartService } from '../../_services/cart.service';
import { ActivatedRoute } from '@angular/router';
import { ContextPaint } from '../../_models/paint/context.paint';
import { MainService } from '../../_services/main.service';
import { ItemInfoService } from '../../_services/item-info.service';
import { ToasterService } from '../../_services/toaster.service';
import { ShopService } from '../../_services/shop.service';
import { CarTypeService } from '../../_services/car-type.service';


@Component({
  selector: 'app-paint',
  templateUrl: './paint.component.html',
  styleUrls: ['./paint.component.scss']
})
export class PaintComponent implements OnInit {
  ctx: ContextPaint;
  shopSoort = ShopSoort;
  request: PaintRequest = new PaintRequest();

  constructor(
    public mainService: MainService,
    public itemInfoService: ItemInfoService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private cartService: CartService,
    private toasterService: ToasterService,
    private shopService: ShopService,
    private carTypeService: CarTypeService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
        if (this.ctx.PaintCartItems?.length === 1) {
          this.request.InternalItemNumbers = [this.ctx.PaintCartItems[0].InternArtikelnr];
        }
      });
    if (this.carTypeService.currentCarType) {
      this.request.CarBrand = `${this.carTypeService.currentCarType.Model.Brand.BrandName} ${this.carTypeService.currentCarType.Model.ModelName}`;
      if (this.carTypeService.currentCarType.LicensePlate.Formated) {
        this.request.CarBrand += ` (${this.carTypeService.currentCarType.LicensePlate.Formated})`;
      }
    }
  }

  addremove(item: number) {
    if (this.ctx.Multiselect && this.request?.InternalItemNumbers) {
      const index = this.request.InternalItemNumbers.indexOf(item);
      if (index >= 0) this.request.InternalItemNumbers.splice(index, 1);
      else this.request.InternalItemNumbers.push(item);
    } else this.request.InternalItemNumbers = [item];

  }

  contains(item: number) {
    return this.request.InternalItemNumbers?.indexOf(item) >= 0;
  }

  orderPaint(request: PaintRequest) {
    if (this.ctx.PaintCartItems?.length > 1 && !request.InternalItemNumbers?.length) {
      this.mainService.msgBox('Let op!', 'Er dient eerst een artikel gekozen te worden.')
    } else {
      if (!request.CarBrand || !request.CarPaintColor) {
        this.mainService.msgBox('Let op!', 'Automerk en/of kleur zijn niet ingevoerd.')
      } else {
        this.apiService.orderPaint(request)
          .subscribe(sci => {
            if (sci) {
              this.cartService.addToFrontendCart(sci);
              this.toasterService.showToast('Succes', `Het item is toegevoegd aan de winkelwagen!`);
              this.shopService.gotoStartupModule(this.mainService.ctxMain);
            }
          });
      }
    }
  }

}
