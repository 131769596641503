<div *ngFor="let tyre of tyres | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']; trackBy: mainService.trackByShoppingCartItem" class="card card-primary band-card">
  <div class="card-header  text-bg-primary">{{tyre.ItemInfo.Description}}</div>
  <div class="card-body band-card-body">
      <div class="row">
          <div class="col-sm-4 control-label">Artikel</div>
          <div class="col-sm-8 form-data">{{rimAndTyreService.getTyreItemNumberSupplier(tyre)}}</div>
      </div>
      <div class="row">
          <div class="col-sm-4 control-label">Fabrikant</div>
          <div class="col-sm-8 form-data">{{tyre.ItemInfo.Properties['TyreBrand']}}</div>
      </div>
      <div class="row">
          <div class="col-sm-4 control-label">Categorie</div>
          <div class="col-sm-8 form-data">
              <span *ngIf="tyre.ItemInfo.Properties['RunFlatTyre'] === 1">RunFlat </span>{{tyre.ItemInfo.Properties['TyreKind']}}</div>
      </div>
      <div class="row">
          <div class="col-sm-4 control-label">Label</div>
          <div class="col-sm-8 form-data">{{rimAndTyreService.getTyreLabel(tyre)}}</div>
      </div>
      <div class="row" *ngIf="ctx.TyresSurcharge.Active">
          <div class="col-sm-4 control-label">{{ctx.TyresSurcharge.ColumnName}}</div>
          <div class="col-sm-8 form-data" style="text-align: right;">{{tyre.ItemInfo.Price.RetailPrice | price }}</div>
      </div>
      <div class="row">
          <div class="col-sm-4 control-label">{{tyre.ItemInfo.Templates.Combined.Description}}</div>
          <div class="col-sm-8 form-data">
              <app-item-info-template [itemInfo]="tyre.ItemInfo" [nettoprijs]="ctx.Nettoprijs"></app-item-info-template>
          </div>
      </div>
      <img class="panel-image" [src]="tyre.ItemInfo.Properties['Image']">
  </div>
  <div class="card-footer">
      <div class="row justify-content-between">
          <div *ngIf="!set" class="col">
              <app-item-count [sci]="tyre"></app-item-count>
          </div>
          <div *ngIf="set" class="col">
              <span class="text-primary pointer" (click)="rimAndTyreService.orderRimsAndTyresSet(set,tyre)"><i class="fas fa-plus-square fa-2x"></i></span>
          </div>
          <div class="col-sm-auto float-end">
              <span class="text-primary pointer" (click)="rimAndTyreService.showTyreInfo(tyre)"><i class="fas fa-info-circle fa-2x"></i></span>
          </div>
      </div>

  </div>
</div>