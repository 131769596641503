<div *ngIf="toast" (mouseenter)="pauseCloseTimer()" (mouseleave)="resumeCloseTimer()">
  <div class="toast-header">
    <img *ngIf="toast.Icon" [src]="toast.Icon" class="toast-icon me-2">
    <strong class="me-auto">{{toast.Title}}</strong>
    <!-- <small class="text-body-tertiary">just now</small> -->
    <button type="button" class="ms-2 mb-1 btn-close" data-dismiss="toast" aria-label="Close" (click)="toasterService.closeToast(toast)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="toast-progress-container">
    <div class="bg-primary h-100 progress-animate"
      [ngStyle]="{'width':  percentage + '%', 'transition-duration': duration + 's'}"></div>
  </div>
  <div class="toast-body" [innerHTML]="toast.MessageHtml | safeHtml"></div>
</div>