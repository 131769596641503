import { Component, OnInit } from '@angular/core';

import { SettingsRimsAndTyres } from '../../../_models/admin/settings/settings.rims.and.tyres';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../../_services/main.service';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-settings-rims-and-tyres',
  templateUrl: './admin-settings-rims-and-tyres.component.html',
  styleUrls: ['./admin-settings-rims-and-tyres.component.scss']
})
export class AdminSettingsRimsAndTyresComponent implements OnInit {
  settings: SettingsRimsAndTyres;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        if (!this.settings.SelectieArtikelen) { this.settings.SelectieArtikelen = []; }
      });
  }

}
