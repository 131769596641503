<div class="row m-2">
  <div [ngClass]="{'col-lg-3': carType.LicensePlate, 'col-lg-4': !carType.LicensePlate}" class="p-0 text-center"><img class="car-image" [src]="carTypeService.getCarTypeImage(carType)" /></div>
  <div [ngClass]="{'col-lg-3': carType.LicensePlate, 'col-lg-4': !carType.LicensePlate}" class="background-color p-2">
    <div class="row g-0">
      <div *ngIf="carType.LicensePlate && !noLicensePlate" class="col-5 fw-light">Kenteken</div>
      <div *ngIf="carType.LicensePlate && !noLicensePlate" class="col-7 text-end fw-bold"><img *ngIf="carType.LicensePlate" class="header-panel-licenseplate" [src]="carType.LicensePlate?.ImageUrl" /></div>
      <div class="col-2 fw-light">Merk</div>
      <div class="col-10 text-end fw-bold">{{carType.Model.Brand.BrandName}}</div>
      <div class="col-2 fw-light">Model</div>
      <div class="col-10 text-end fw-bold">{{carType.Model.ModelName}}</div>
      <div class="col-2 fw-light">Type</div>
      <div class="col-10 text-end fw-bold">{{carType.TypeName}}</div>          
    </div>
    <app-car-type-info-property [carType]="carType" [labelCols]="2" [propertyKind]="catalogPropertyService.propertyKindCatalog.Color"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [labelCols]="4" [propertyKind]="catalogPropertyService.propertyKindCatalog.EngineCode"></app-car-type-info-property>
  </div>
  <div *ngIf="carType.LicensePlate" class="col-lg-3 background-color p-2">
    <app-car-type-info-property [carType]="carType" [label]="'RDW'" [labelCols]="2" [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationVersion"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [propertyKind]="catalogPropertyService.propertyKindCatalog.BuildYear"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [propertyKind]="catalogPropertyService.propertyKindCatalog.MassOfEmptyVehicle"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [propertyKind]="catalogPropertyService.propertyKindCatalog.FirstAdmission"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationDate"></app-car-type-info-property>
    <app-car-type-info-property [carType]="carType" [propertyKind]="catalogPropertyService.propertyKindCatalog.ApkExpiration"></app-car-type-info-property>
  </div>
  <div [ngClass]="{'col-lg-3': carType.LicensePlate, 'col-lg-4': !carType.LicensePlate}" class="background-color p-2">
    <app-car-type-info-property [carType]="carType" *ngFor="let propKind of catalogPropertyService.getPropertyKinds(carType.Properties, exceptPropertyKinds)" [propertyKind]="propKind"></app-car-type-info-property>
  </div>
</div>

