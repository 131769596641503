<div class="modal-header center text-bg-light">
  <h5 class="modal-title">Beschikbaarheidstemplate overzicht</h5>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 my-2" *ngFor="let templatePart of data.templateParts | keyvalue">
        <div class="card template w-100 grow pointer" (click)="confirm(+templatePart.key)">
          <div class="card-header">
            <b>{{templatePart.value.Naam}}</b>
          </div>
          <div class="card-body overflow-auto"><app-item-info-template-part *ngIf="data.renderedTemplateParts"
              [templatePart]="data.renderedTemplateParts[templatePart.key]" [busy]="busy"></app-item-info-template-part>
          </div>
          <div class="card-body small info pt-0">{{templatePart.value.Opmerking}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-bg-light">
  <button type="button" class="btn btn-warning" (click)="confirm(null)"><i class="fas fa-undo"></i> Annuleren</button>
</div>