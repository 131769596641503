<app-admin-form [title]="'Autolak instellingen'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">

  <app-admin-items-input [label]="'Autolak artikelen'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_ItemNumbers').Value" [noneSelectionLabel]="'Geen artikelen geselecteerd.'" [dropup]="true" [sortEnabled]="true" [disabled]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_ItemNumbers_Disabled').Value"></app-admin-items-input>

  <app-admin-checkbox [label]="'Er mogen meerdere artikelen gekozen worden'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_Multiselect_ItemNumbers').Value"></app-admin-checkbox>

  <app-admin-checkbox-input [label]="'Één artikel i.p.v. keuze'" [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_ItemNumbers_Disabled').Value" [(modelInput)]="adminSettingsService.getAdminSetting(settings,'PaintItemNumber').Value"></app-admin-checkbox-input>

  <app-admin-input [label]="'Omschrijving autolak bestelling'" [(model)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_Order_Description').Value"></app-admin-input>

  <app-admin-checkbox-editor [label]="'Extra Informatie'" [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_Order_ExtraInformationEnabled').Value" [(modelEditor)]="adminSettingsService.getAdminSetting(settings,'ShopModule_Paint_Order_ExtraInformation').Value"></app-admin-checkbox-editor>
  
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)"></app-admin-cancel-save-button>
</app-admin-form>