import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { CatalogService } from '../../../_services/catalog.service';
import { MainService } from '../../../_services/main.service';
import { MaintenancePartsSelection } from '../../../_models/maintenance/maintenance.parts.selection';


@Component({
  selector: 'app-catalog-parts-selection-popup',
  templateUrl: './catalog-parts-selection-popup.component.html',
  styleUrls: ['./catalog-parts-selection-popup.component.scss']
})
export class CatalogPartsSelectionPopupComponent implements OnInit {
  ctx: ContextCatalog;
  selection: MaintenancePartsSelection;
  partsTypeId: number;
  catalogService: CatalogService;
  mainService: MainService;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  choosePart(part: Part) {
    this.selection.SelectedPartPerPartsType[this.partsTypeId] = part;
    this.selection.SelectedCartItemPerPartsType[this.partsTypeId] = this.ctx.PartsCartItems[part.UniqueID];
    this.bsModalRef.hide();
  }

  closeDialog() {
    this.bsModalRef.hide();
  }

}
