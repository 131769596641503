<div class="fixed-top top">
  <app-container>
    <div class="row top-row align-items-center">
      <div class="col-md-3 pointer text-low" (click)="cartService.changeNetPriceOption()">
        <span class="me-2">nettoprijs</span>
        <span *ngIf="!cartService.getNetPriceHidden()"><i class="fas fa-eye"></i></span>
        <span *ngIf="cartService.getNetPriceHidden()"><i class="fas fa-eye-slash"></i></span>
      </div>
      <div class="col-md-3">
        <app-helper-display-breakpoint></app-helper-display-breakpoint>
        <span *ngIf="!settingsService.getSettingBoolean('GeenVolgendeRouteInHeader')">
          <i class="fas fa-truck"></i> Volgende route <span #counter></span>
        </span>
      </div>
      <div class="col-md-6 d-none d-lg-block text-end">
        <span *ngIf="settingsService.getSetting('TekstRechtsInHeader')" class="me-3 text-top-right"
          [innerHTML]="settingsService.getSetting('TekstRechtsInHeader') | safeHtml"></span>
        <a *ngIf="ctx.CompanyInfo.BranchFacebook" target="_new" class="top-link mx-1"
          [href]="ctx.CompanyInfo.BranchFacebook"><i class="fab fa-facebook-square"></i></a>
        <a *ngIf="ctx.CompanyInfo.BranchWhatsApp" target="_new" class="top-link mx-1" [href]="getWhatsAppLink()"><i
            class="fab fa-whatsapp-square"></i></a>
        <a *ngIf="ctx.CompanyInfo.BranchMail" target="_new" class="top-link mx-1"
          [href]="'mailto:' + ctx.CompanyInfo.BranchMail"><i class="fas fa-envelope-square"></i></a>
      </div>
    </div>
  </app-container>
  <div [ngClass]="'bg-' + settingsService.getSetting('HeaderWebshopMenuCssClass')">
    <app-container>
      <nav class="navbar navbar-expand-lg"
        [ngClass]="{'navbar-dark': !settingsService.getSettingBoolean('HeaderWebshopMenuLight'),'navbar-light': settingsService.getSettingBoolean('HeaderWebshopMenuLight')}">
        <div class="container-fluid">
          <a class="navbar-brand brand-logo" [routerLink]="shopService.getHomeScreenLink()"
            (dblclick)="authService.gotoLogout()"
            (contextmenu)="mainService.showDebugInfo($event, ctx.LoginTiming, ctx)">
            <span *ngIf="settingsService.getSetting('TextInsteadOfLogo') as text; else logo">{{text}}</span>
            <ng-template #logo><img class="brand-logo-image" *ngIf="ctx.Logo" [src]="'/assets/' + ctx.Logo" />
            </ng-template>
          </a>
          <div class="collapse navbar-collapse" id="navbarText">
            <app-webshop-menu-dynamic
              *ngIf="ctx.ShopModuleMenu && ctx.ShopModuleMenu.MenuComponent==='webshop5'; else staticMenu"
              [ctxMain]="ctx" [ctxPromotions]="ctxPromotions"></app-webshop-menu-dynamic>
            <ng-template #staticMenu>
              <app-webshop-menu-static [ctxMain]="ctx" [ctxPromotions]="ctxPromotions"></app-webshop-menu-static>
            </ng-template>
          </div>
          <button *ngIf="settingsService.getSettingBoolean('CartInHeaderInsteadOfNavigationTemplate')" class="btn"
            [ngClass]="'btn-' + settingsService.getSetting('HeaderWebshopMenuCssClass')" tooltip="Winkelwagen"
            placement="top" container="body" [routerLink]="['/cart']"><span class="text-nowrap">
              <span *ngIf="cartService.isCartEmpty()"><i class="far fa-shopping-cart"></i></span>
              <span *ngIf="!cartService.isCartEmpty()"><i class="fas fa-shopping-cart"></i></span>
              <span *ngIf="!cartService.isCartEmpty()"
                class="ms-1 badge text-bg-danger">{{cartService.getCartItemCount()}}</span></span></button>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </app-container>
  </div>
</div>