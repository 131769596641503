<div *ngIf="ctx.filteredParts">
    <app-catalog-part-list-item
        *ngFor="let part of (ctx.filteredParts | sortOptions: mainService.getActiveSort(ctx?.SortOptions):ctx['busy']:'UniqueID':ctx.PartsCartItems) | maxItemsFilter:_maxItemCount; let odd=odd;"
        [odd]="odd" [ctx]="ctx" [part]="part"></app-catalog-part-list-item>

    <app-show-more-button [(currentItemCount)]="_maxItemCount" [showPerCount]="maxItems"
        [totalItemCount]="ctx.filteredParts.length">
    </app-show-more-button>

    <div *ngIf="ctx.filteredParts.length === 0" class="row">
        <div class="col-12">
            <div class="alert alert-warning" role="alert">
                <i class="fas fa-exclamation-triangle"></i> Er bleven geen artikelen over aan de hand van de volgende
                selectie keuzes:
                <br />
                <br />
                <ul class="">
                    <li *ngFor="let selectie of catalogService.getFilterDescription(ctx, '')">{{selectie}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>