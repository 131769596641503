import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SettingsBase } from '../../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../../_services/admin/admin.service';


@Component({
  selector: 'app-admin-settings-catalog-setup',
  templateUrl: './admin-settings-catalog-setup.component.html',
  styleUrls: ['./admin-settings-catalog-setup.component.scss']
})
export class AdminSettingsCatalogSetupComponent implements OnInit {
  settings: SettingsBase;

  constructor(
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getGroupLayoutPossibilities(): Record<number, string> {
    const possibilities = { 0: 'Gebruik eigen layout' };
    if (this.adminSettingsService.getAdminSetting(this.settings, 'CatalogKinds_Providers').Value) {
      Object.keys(this.adminSettingsService.getAdminSetting(this.settings, 'CatalogKinds_Providers').Value).forEach(key => {
        possibilities[+key] = this.adminSettingsService.getAdminSetting(this.settings, 'CatalogKinds_Providers').Value[key];
      });
    }
    return possibilities;
  }

}
