import { Component, OnInit, Input } from '@angular/core';

import { ContextPromotions } from '../../../../_models/promotions/context.promotions';
import { MainService } from '../../../../_services/main.service';
import { Animations } from '../../../../_animations/animations';
import { PromotionService } from '../../../../_services/promotion.service';


@Component({
  selector: 'app-promotions-card-view',
  templateUrl: './promotions-card-view.component.html',
  styleUrls: ['./promotions-card-view.component.scss'],
  animations: [Animations.fadeInOut]
})
export class PromotionsCardViewComponent implements OnInit {
  @Input() ctx: ContextPromotions;

  constructor(
    public mainService: MainService,
    public promotionService: PromotionService
  ) { }

  ngOnInit() {
  }

}
