import { Component, OnInit } from '@angular/core';
import { Return } from '../../../_models/returns/return';
import { ActivatedRoute } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { CustomerInformationService } from '../../../_services/customer-information.service';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { AuthService } from '../../../_services/auth.service';
import { ReturnDetail } from '../../../_models/returns/return.detail';
import { TypeaheadItem } from '../../../_models/typeahead/typeahead.item';
import { ReturnReason } from '../../../_models/returns/return.reason';
import { ItemTypeaheadSearchComponent } from '../../../_common/item-typeahead-search/item-typeahead-search.component';

@Component({
  selector: 'app-product-returns-request',
  templateUrl: './product-returns-request.component.html',
  styleUrls: ['./product-returns-request.component.scss']
})
export class ProductReturnsRequestComponent implements OnInit {

  returnItem: Return;
  isBusy: boolean;

  currentItemValue: string;
  currentItem: TypeaheadItem;
  currentAmount: number;
  currentReason: ReturnReason;
  currentDescription: string;

  constructor(
    private route: ActivatedRoute,
    public customerInformationService: CustomerInformationService,
    public mainService: MainService,
    public sortService: SortService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // this.currentAmount = 1;
    // this.currentReason = this.customerInformationService.getReturnReasons()[0];
    this.resetFields();
    this.getReturnItem();
  }

  resetFields() {
    this.currentItemValue = null;
    this.currentItem = null;
    this.currentAmount = 1;
    this.currentReason = this.customerInformationService.getReturnReasons()[0];
    this.currentDescription = "";
  }

  getReturnItem() {
    this.isBusy = true;
    this.customerInformationService.getCurrentReturn().subscribe(returnItem => {
      this.isBusy = false;
      this.returnItem = returnItem;
      console.info(returnItem);
    }, error => {
      this.isBusy = false;
      if (error.status === 401) { this.customerInformationService.gotoReturns(); }
      console.error(error);
    });
  }

  getSortedItems(): ReturnDetail[] {
    if (this.returnItem) { return this.sortService.sortObject('returns-details', this.returnItem.Details); }
    return [];
  }

  searchItem() {

  }

  typeaheadItemSelected(result: TypeaheadMatch) {
    this.currentItem = result.item;
    console.info(result);
    this.getDeliveryNoteItems();
  }

  isReasonAnders() {
    return (this.currentReason && this.currentReason.ReasonId === 3);
  }

  addCurrentItem() {

    const detail = new ReturnDetail();
    detail.InternalItemNumber = this.currentItem.InternalItemNumber;
    detail.ItemNumber = this.currentItem.ItemNumber;
    detail.ItemDescription = this.currentItem.Description;
    detail.ItemGroup = this.currentItem.ItemGroup;
    detail.Amount = this.currentAmount;
    detail.Reason = this.currentReason.ReasonId;
    detail.ReasonDescription = this.currentDescription;

    // DeliverNoteNumber: number;
    // GrossPrice: number;
    // NetPrice: number;
    // PriceUnit: number;
    // TotalAmount: number;
    // AmountOnDeliveryNote: number;

    this.customerInformationService.postReturnDetail(this.returnItem.Header.ReturnId, detail).subscribe(result => {
      this.resetFields();
      this.getReturnItem();
    }, error => {
      console.error(error);
    });
  }

  compareReason(c1: ReturnReason, c2: ReturnReason): boolean {
    return c1 && c2 ? c1.ReasonId === c2.ReasonId : c1 === c2;
  }

  deleteItem(item: ReturnDetail) {
    this.mainService.confirmBox("Wilt u dit artikel verwijderen?").subscribe(result => {
      if (result) {
        this.customerInformationService.deleteReturnDetail(this.returnItem.Header.ReturnId, item).subscribe(result => {
          this.resetFields();
          this.getReturnItem();
        }, error => {
          console.error(error);
        });
      }
    });
  }

  send() {

    this.customerInformationService.saveReturn(this.returnItem.Header).subscribe(resultSave => {

      this.customerInformationService.sendReturn(this.returnItem.Header.ReturnId).subscribe(resultSend => {
        this.mainService.msgBox("Verstuurd", `Retour is verstuurd. Het ordernummer is ${resultSend.OrderNumber}`).subscribe(resultBox => {
          this.customerInformationService.gotoReturns();
        });
      }, error => {
        console.error(error);
      });

    }, error => {
      console.error(error);
    });
  }

  save() {
    this.customerInformationService.saveReturn(this.returnItem.Header).subscribe(result => {
      this.mainService.msgBox("Opgeslagen", `De retourgegevens zijn opgeslagen!`).subscribe(resultBox => {
        this.customerInformationService.gotoReturns();
      });
    }, error => {
      console.error(error);
    });

  }

  getDeliveryNoteItems() {

    this.customerInformationService.getDeliveryNoteItems(this.currentItem.InternalItemNumber, this.currentAmount).subscribe(result => {
      console.info(result);
    }, error => {
      console.error(error);
    });


  }

  addItem() {
    this.customerInformationService.gotoReturnRequestItem();
  }
}
