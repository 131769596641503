import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextGraphicParts } from '../../../_models/catalog/context.graphic.parts';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-catalog-graphic-parts',
  templateUrl: './catalog-graphic-parts.component.html',
  styleUrls: ['./catalog-graphic-parts.component.scss']
})
export class CatalogGraphicPartsComponent implements OnInit {
  shopSoort = ShopSoort;
  ctx: ContextGraphicParts;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.graphicParts;
      });
  }

}
