import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartItem } from '../../../_models/cart/shopping.cart.item';

@Component({
  selector: 'app-tyre-label',
  templateUrl: './tyre-label.component.html',
  styleUrls: ['./tyre-label.component.scss']
})
export class TyreLabelComponent implements OnInit {
  @Input() tyre: ShoppingCartItem;

  constructor() { }

  ngOnInit(): void {
  }

}
