import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { SettingsShoppingCart } from '../../../_models/admin/settings/settings.shopping.cart';
import { DeliveryChoice } from '../../../_models/cart/delivery.choice';
import { MainService } from '../../../_services/main.service';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-settings-cart',
  templateUrl: './admin-settings-cart.component.html',
  styleUrls: ['./admin-settings-cart.component.scss']
})
export class AdminSettingsCartComponent implements OnInit {
  settings: SettingsShoppingCart;

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  getShowTime(choice: DeliveryChoice) {
    if (choice && choice.ValidFrom && choice.ValidThru && choice.ValidFrom.getTime() === choice.ValidThru.getTime()) {
      return "Altijd";
    }
    return "van "
      + this.datePipe.transform(choice.ValidFrom, 'HH:mm')
      + " tot " + this.datePipe.transform(choice.ValidThru, 'HH:mm');
  }

  getShowKind(choice: DeliveryChoice) {
    if (choice.DeliveryMethod === 0) {
      return "Afhalen";
    } else if (choice.DeliveryMethod === 1) {
      return "Bezorgen";
    }
    return "Afhalen + Bezorgen";
  }

}
