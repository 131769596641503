<footer class="footer text-bg-dark smaller py-3">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="footer-main">
          <div class="row">
            <div class="col-lg-3 col-xs-12">
              <aside id="custom_html-8" class="widget_text widget group widget_custom_html">
                <h3 class="widget-title">Hoe u ons kunt bereiken</h3>
                <div class="textwidget custom-html-widget">
                  <p><a
                      href="https://api.whatsapp.com/send?phone=31646096052&amp;text=Hallo,%20ik%20neem%20even%20contact%20op%20via%20WhatsApp"><img
                        class="alignnone wp-image-857 size-medium size-auto"
                        src="https://roskampautomaterialen.nl/cmccs/wp-content/uploads/WhatsApp-Roskamp-Automaterialen-met-06-300x52.png"
                        alt="WhatsApp-Roskamp-Automaterialen met 06" width="300" height="52"
                        srcset="https://roskampautomaterialen.nl/cmccs/wp-content/uploads/WhatsApp-Roskamp-Automaterialen-met-06-300x52.png 300w, https://roskampautomaterialen.nl/cmccs/wp-content/uploads/WhatsApp-Roskamp-Automaterialen-met-06-768x132.png 768w, https://roskampautomaterialen.nl/cmccs/wp-content/uploads/WhatsApp-Roskamp-Automaterialen-met-06-1024x177.png 1024w, https://roskampautomaterialen.nl/cmccs/wp-content/uploads/WhatsApp-Roskamp-Automaterialen-met-06-464x80.png 464w"
                        sizes="(max-width: 300px) 100vw, 300px"></a></p>
                </div>
              </aside>
              <div class="my-3"><span class="phone"><i class="fas fa-phone-rotary"></i></span><span class="ms-2"><a class="phone" [href]="'tel:' + ctx.CompanyInfo.BranchPhone">{{ctx.CompanyInfo.BranchPhone}}</a></span></div>
              <div class="my-2"><span class="phone"><i class="fas fa-at"></i></span><span class="ms-2"><a class="mail" [href]="'mailto:' + ctx.CompanyInfo.BranchMail">{{ctx.CompanyInfo.BranchMail}}</a></span></div>
            </div>
            <div class="col-lg-3 col-xs-12">
              <aside id="custom_html-4" class="widget_text widget group widget_custom_html">
                <h3 class="widget-title">Automaterialen</h3>
                <div class="textwidget custom-html-widget">
                  <ul>
                    <li>Remblokken</li>
                    <li> Remschijven</li>
                    <li> Filter Bosch &amp; Mann</li>
                    <li> Distributieset</li>
                    <li> Waterpomp</li>
                    <li> Stuurdelen</li>
                    <li> Koppeling</li>
                    <li> Uitlaat</li>
                    <li> Schokbreker</li>
                    <li> Accu</li>
                    <li> Ruitenwisser </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div class="col-lg-3 col-xs-12">
              <aside id="custom_html-6" class="widget_text widget group widget_custom_html">
                <h3 class="widget-title">Gereedschap</h3>
                <div class="textwidget custom-html-widget">
                  <ul>
                    <li> Facom</li>
                    <li> Kraftwerk</li>
                    <li> DeWalt</li>
                    <li> KS Tools</li>
                    <li> Midlock</li>
                    <li> Gereedschapkar</li>
                    <li> Gereedschapkoffer</li>
                    <li> Afstelgereedschap</li>
                    <li> Gereedschap verhuur</li>
                    <li> Automotive tools</li>
                    <li> Actie Tool Special
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div class="col-lg-3 col-xs-12">
              <aside id="custom_html-7" class="widget_text widget group widget_custom_html">
                <h3 class="widget-title">Overige</h3>
                <div class="textwidget custom-html-widget">
                  <ul>
                    <li> Motorolie</li>
                    <li> Versnellingsbakolie</li>
                    <li> Stuurbekrachtigingsolie</li>
                    <li> Bosch Injection Systems (BIS)</li>
                    <li> AD Autobedrijf</li>
                    <li> Carprof</li>
                    <li> Auto Parts Nederland</li>
                    <li> Kentekenplaat</li>
                    <li> Fietsendrager</li>
                    <li> Navigatie Tom Tom
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div class="footer-copy">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              Roskamp Automaterialen </div>
            <div class="col-md-6 col-xs-12 text-end">
              </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</footer>
