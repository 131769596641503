import { Pipe, PipeTransform } from '@angular/core';
import { CreditsInfo } from '../../../_models/loyalty-shop/credits.info';

@Pipe({
  name: 'filterCreditsInfos'
})
export class FilterCreditsInfosPipe implements PipeTransform {

  transform(creditInfos: CreditsInfo[], filterString: string): any {
    if (!filterString) { return creditInfos; }
    return creditInfos.filter(creditInfo => {
      const search = filterString.toLowerCase();
      if (creditInfo) {
        if (creditInfo.Customer.toString().indexOf(search) >= 0) {return true; }
        if (creditInfo.Name.toLowerCase().indexOf(search) >= 0) {return true; }
        if (creditInfo.Credits.toString().indexOf(search) >= 0) {return true; }
      }
      return false;
    });
  }

}
