import { Component, OnInit, Input } from '@angular/core';

import { ContextGraphicParts } from '../../../../_models/catalog/context.graphic.parts';
import { ViewKindInfo } from '../../../../_models/common/view.kind.info';
import { ViewKind } from '../../../../_models/common/view.kind';
import { MainService } from '../../../../_services/main.service';
import { CatalogService } from '../../../../_services/catalog.service';
import { Router } from '@angular/router';
import { CarTypeService } from '../../../../_services/car-type.service';
import { GraphicPartsStrip } from '../../../../_models/catalog/graphic.parts.strip';
import { Animations } from '../../../../_animations/animations';
import { SettingsService } from '../../../../_services/settings.service';

@Component({
  selector: 'app-catalog-graphic-parts-boxed-layout',
  templateUrl: './catalog-graphic-parts-boxed-layout.component.html',
  styleUrls: ['./catalog-graphic-parts-boxed-layout.component.scss'],
  animations: [Animations.slideInHorizontal]
})
export class CatalogGraphicPartsBoxedLayoutComponent implements OnInit {
  @Input() ctx: ContextGraphicParts;
  viewKinds: ViewKindInfo[] = [
    { kind: ViewKind.Detail, icon: 'fas fa-th-large', info: 'Ruime weergave' },
    { kind: ViewKind.Compact, icon: 'fas fa-th', info: 'Compacte weergave' }
  ];

  constructor(
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public catalogService: CatalogService,
    private router: Router,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  gotoGroups(): void {
    this.router.navigate(['/catalog/groups', this.ctx.CarType.Id]);
  }

  changeMee(optie: string) {
    for (let i = 0; i < this.ctx.GraphicParts.length; i++) {
      this.ctx.Context[this.ctx.GraphicParts[i].StripId][optie] = this.ctx[optie];
    }
  }
}
