<div *ngIf="timingItem" class="row g-0" [ngClass]="{'alt-back': alt, 'pointer': timingItem.SubTiming}"
  (click)="clickCollapse($event)">
  <div class="col-8 ps-3">
    <div [ngStyle]="{'padding-left.px': depth*25}">
      {{timingItem.Description}}
    </div>
  </div>
  <div class="col-4 pe-3 text-end">
    <span class="seconds" [ngClass]="mainService.getTimingClassLevel(classLevels, timingItem.Duration/10000000, false)">
      <div [ngStyle]="{'padding-right.px': depth*25}">
        {{mainService.getMax(timingItem.Duration/10000000,0) | number:'.3-3'}}
      </div>
    </span>
    <span class="ms-3 seconds text-low">{{((timingItem.TimeStamp - startTimeStamp) / 10000000) | number:'.3-3'}}</span>
    <span class="icons">
      <span *ngIf="timingItem.DebugObject; else objectInTree" (click)="mainService.showObjectDialog($event, timingItem.DebugObject)" class="icon"><i class="far fa-cube"></i></span>
      <ng-template #objectInTree><span *ngIf="hasDebugObjectInTree(timingItem)" class="text-low icon"><i class="far fa-cube"></i></span></ng-template>
      <span *ngIf="timingItem.Warning; else warningInTree" (click)="mainService.showObjectDialog($event, timingItem.DebugObject)" class="icon"><i class="fas fa-exclamation-triangle"></i></span>
      <ng-template #warningInTree><span *ngIf="hasWarningInTree(timingItem)" class="text-low icon"><i class="fas fa-exclamation-triangle"></i></span></ng-template>
    </span>
    <span class="collapse-subtiming">
      <div *ngIf="timingItem.SubTiming" class="collapse-button" [ngClass]="{'is-collapsed': collapsed}">
        <i class="fas fa-chevron-up"></i>
      </div>
    </span>
  </div>
</div>
<div *ngIf="timingItem?.SubTiming && !collapsed" [@slideInOut] class="collapsable borders">
  <app-timing-object-display [timingObject]="timingItem.SubTiming" [startTimeStamp]="startTimeStamp" [depth]="depth+1"
    [startAlt]="alt" [classLevels]="classLevels" [collapseState]="collapseState" [flatTimings]="flatTimings"></app-timing-object-display>
</div>