import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-admin-textarea',
  templateUrl: './admin-textarea.component.html',
  styleUrls: ['./admin-textarea.component.scss']
})
export class AdminTextareaComponent implements OnInit {
  @Input() label: string = '';
  @Input() centerLabel: boolean = false;
  @Input() model: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<any>();
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() labelCols: number = 2;
  @Input() rows = 5;
  @Input() dividerKind: number = 0;
  @Input() focusInput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() labelLeftAlign: boolean = false;
  uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
  }

  getClasses(): string[] {
    const classes = ['col-sm-' + this.labelCols];
    if (!this.labelLeftAlign) { classes.push('text-end'); }
    return classes;
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }
}
