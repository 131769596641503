import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleSourceRecord } from '../../../../_models/common/article-source/article.source.record';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-article-source-response',
  templateUrl: './article-source-response.component.html',
  styleUrls: ['./article-source-response.component.scss']
})
export class ArticleSourceResponseComponent implements OnInit {
  @Input() response: ArticleSourceRecord[];
  @Output() searchAction: EventEmitter<string> = new EventEmitter();

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit(): void {
  }

}
