import { UniversalPartsCategory } from './universal.parts.category';
import { UniversalCarType } from '../car-types/universal.car.type';
import { Part } from './part';
import { ShopSoort } from '../common/shop.soort';
import { TimingObject } from '../common/timing.object';

export class CatalogDataRequest {
    ShopKind: ShopSoort;
    Description: string;
    WithoutSupplierAvailability: boolean;
    CarType: UniversalCarType;
    Category: UniversalPartsCategory;
    Part: Part;
    PartsDict: { [key: string]: Part[] };
    PartsList: Part[];
    PartsTypeIds: number[];
    Timing: TimingObject;
}
