import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { SettingsCatalogLines } from '../../../../_models/admin/settings/settings.catalog.lines';
import { ActivatedRoute } from '@angular/router';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-catalog-lines',
  templateUrl: './admin-settings-catalog-lines.component.html',
  styleUrls: ['./admin-settings-catalog-lines.component.scss']
})
export class AdminSettingsCatalogLinesComponent implements OnInit {
  objectKeys = Object.keys;
  settings: SettingsCatalogLines;
  uncollapsed: { [key: string]: { [key: string]: boolean } };
  filterString: string;
  filter: RegExp;
  perCatalog: { [key: number]: { [key: string]: { [key: string]: { [key: string]: { [key: number]: boolean } } } } };
  flip = false;
  filterTimeoutHandle: number;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute,
    public cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.perCatalog = this.getDividedPerCatalog(data.settings.CatalogKinds, data.settings.CatalogLinesFilter);
      });
  }

  getDividedPerCatalog(catalogKinds: { [key: string]: string }
    , filter: { [key: string]: { [key: string]: { [key: string]: { [key: number]: boolean } } } })
    : { [key: string]: { [key: string]: { [key: string]: { [key: string]: { [key: number]: boolean } } } } } {
    const perCatalog: { [key: number]: { [key: string]: { [key: string]: { [key: string]: { [key: number]: boolean } } } } } = {};
    Object.keys(catalogKinds).forEach(catalogKey => {
      const catalog = catalogKinds[catalogKey];
      perCatalog[catalogKey] = {};
      Object.keys(filter).forEach(brandName => {
        let okBrand = false;
        const brand: { [key: string]: { [key: string]: { [key: number]: boolean } } } = {};
        Object.keys(filter[brandName]).forEach(partDescription => {
          if (filter[brandName][partDescription][catalog]) {
            brand[partDescription] = filter[brandName][partDescription];
            okBrand = true;
          }
        });
        if (okBrand) { perCatalog[catalogKey][brandName] = brand; }
      });
    });
    return perCatalog;
  }

  change(filterString: string) {
    if (this.filterTimeoutHandle) { window.clearTimeout(this.filterTimeoutHandle); }
    if (this.filterString.length > 1) {
      this.filterTimeoutHandle = window.setTimeout(
        (): void => {
          this.filterTimeoutHandle = null;
          this.filter = new RegExp(filterString, 'i');
        }
        , 2000);
    } else {
      this.filter = null;
    }
  }

  clear() {
    this.filterString = '';
    this.filter = null;
  }

  getTotal(catalogKey: number): number {
    if (catalogKey === 0 && this.settings && this.settings.CatalogLinesFilter) {
      return Object.keys(this.settings.CatalogLinesFilter).length;
    }
    if (catalogKey !== 0 && this.perCatalog && this.perCatalog[catalogKey]) {
      return Object.keys(this.perCatalog[catalogKey]).length;
    }
    return 0;
  }

  invert(nodes: any, catalog?: string, depth = 0) {
    if (depth === 0) { this.flip = !this.flip; }
    if (nodes) {
      Object.keys(nodes).forEach(key => {
        if (typeof nodes[key] !== 'boolean' && !(catalog && depth === 2 && key !== catalog)) {
          this.invert(nodes[key], catalog, depth + 1);
        }
        if (typeof nodes[key] === 'boolean') { nodes[key] = !nodes[key]; }
      });
    }
  }

}
