import { Component, OnInit, Input } from '@angular/core';
import { CatalogLinesTotals } from '../../../../../../_models/admin/settings/catalog.lines.totals';
import { stringify } from 'querystring';

@Component({
  selector: 'app-catalog-lines-totals-report',
  templateUrl: './catalog-lines-totals-report.component.html',
  styleUrls: ['./catalog-lines-totals-report.component.scss']
})
export class CatalogLinesTotalsReportComponent implements OnInit {
  @Input() descriptions: string[];
  @Input() totals: CatalogLinesTotals;
  @Input() catalogKey: number;
  @Input() catalogDescription: string;
  @Input() invert: boolean;

  constructor() { }

  ngOnInit() {
  }

  displayInvert(): boolean {
    return this.totals.Selected[this.catalogKey] > 0
      && this.totals.Selected[this.catalogKey] !== this.totals.Total[this.catalogKey]
      && (this.totals.Selected[this.catalogKey] > (this.totals.Total[this.catalogKey] / 2));
  }

  mustInvert(): boolean {
    return this.displayInvert() ? !this.invert : this.invert;
  }

  getNewDescriptions(description: string[], key: string): string[] {
    if (description) { return [key].concat(description); }
    return [key];
  }

  getDescription(descriptions: string[]): string {
    if (descriptions && descriptions.length === 1) { return `${!this.mustInvert() ? 'Wel alles van ' : 'Niets van '}"${descriptions[0]}"`; }
    if (descriptions && descriptions.length > 1) { return `${this.mustInvert() ? 'Wel ' : 'Geen '}"${descriptions.join('" van "')}"`; }
    return this.mustInvert() ? 'Alles' : 'Niets';
  }

  alles(totals: CatalogLinesTotals, catalogKey: number, invert: boolean): boolean {
    if (invert) { return totals.Selected[catalogKey] === 0; }
    return totals.Selected[catalogKey] !== 0 && totals.Selected[catalogKey] === totals.Total[catalogKey];
  }

  niets(totals: CatalogLinesTotals, catalogKey: number, invert: boolean): boolean {
    if (invert) { return totals.Selected[catalogKey] !== 0 && totals.Selected[catalogKey] === totals.Total[catalogKey]; }
    return totals.Selected[catalogKey] === 0;
  }

  allesOfNiets(totals: CatalogLinesTotals, catalogKey: number, invert: boolean): boolean {
    return this.alles(totals, catalogKey, invert) || this.niets(totals, catalogKey, invert);
  }

  iets(totals: CatalogLinesTotals, catalogKey: number, invert: boolean): boolean {
    return totals.Selected[catalogKey] !== 0 && totals.Selected[catalogKey] !== totals.Total[catalogKey];
  }

  allesOfIets(totals: CatalogLinesTotals, catalogKey: number, invert: boolean): boolean {
    return this.alles(totals, catalogKey, invert) || this.iets(totals, catalogKey, invert);
  }
}
