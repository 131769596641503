Object.defineProperty(Object.prototype, "replaceKeys", {
  enumerable: false,
  value: replaceKeys
});

interface Object {
  replaceKeys: typeof replaceKeys;
}

function replaceKeys(newKeyValues: any) {
  Object.keys(newKeyValues).forEach(key => this[key] = newKeyValues[key]);
}
