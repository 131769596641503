import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextBase } from '../../../_models/common/context.base';
import { ViewKind } from '../../../_models/common/view.kind';
import { ApiService } from '../../../_services/api.service';
import { Settings } from '../../../_models/common/settings';
import { ViewKindInfo } from '../../../_models/common/view.kind.info';

@Component({
  selector: 'app-display-opties',
  templateUrl: './display-opties.component.html',
  styleUrls: ['./display-opties.component.scss']
})
export class DisplayOptiesComponent implements OnInit {
  @Input() ctx: ContextBase;
  @Input() viewKinds: ViewKindInfo[];
  @Output() viewKindChanged: EventEmitter<ViewKind> = new EventEmitter();

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
  }

  changeViewKind(viewKind: number) {
    if (this.ctx) {
      this.ctx.ViewKind = viewKind;
      this.apiService.saveSettings(new Settings(this.ctx.ViewKindOptionString, viewKind.toString()));
      this.viewKindChanged.emit(this.ctx.ViewKind);
    }
  }

}
