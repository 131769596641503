import { Injectable } from '@angular/core';

import { NamesCacheObject } from '../../_models/admin/names.cache.object';
import { UserIdentificationModel } from '../../_models/user.identification.model';

@Injectable({
  providedIn: 'root'
})
export class AdminNameService {
  cache: NamesCacheObject;

  constructor() {
    this.cache = new NamesCacheObject();
    this.cache.wholesalers = {};
  }

  setWholesaler(wholesaler: number, name: string) {
    if (!this.cache.wholesalers[wholesaler]) { this.cache.wholesalers[wholesaler] = { wholesalerName: name, branches: {}, customers: {} }; }
    this.cache.wholesalers[wholesaler].wholesalerName = name;
  }

  setBranch(wholesaler: number, branch: number, name: string) {
    if (!this.cache.wholesalers[wholesaler]) { this.cache.wholesalers[wholesaler] = { wholesalerName: name, branches: {}, customers: {} }; }
    if (!this.cache.wholesalers[wholesaler].branches[branch]) {
      this.cache.wholesalers[wholesaler].branches[branch] = { branchName: name };
    }
    this.cache.wholesalers[wholesaler].branches[branch].branchName = name;
  }

  setCustomer(wholesaler: number, branch: number, customer: number, name: string) {
    if (!this.cache.wholesalers[wholesaler]) { this.cache.wholesalers[wholesaler] = { wholesalerName: name, branches: {}, customers: {} }; }
    if (!this.cache.wholesalers[wholesaler].customers[customer]) {
      this.cache.wholesalers[wholesaler].customers[customer] = { customerName: name, branch: branch, users: {} };
    }
    this.cache.wholesalers[wholesaler].customers[customer].customerName = name;
    this.cache.wholesalers[wholesaler].customers[customer].branch = branch;
  }

  setUser(wholesaler: number, branch: number, customer: number, user: number, name: string) {
    if (!this.cache.wholesalers[wholesaler]) { this.cache.wholesalers[wholesaler] = { wholesalerName: name, branches: {}, customers: {} }; }
    if (!this.cache.wholesalers[wholesaler].customers[customer]) {
      this.cache.wholesalers[wholesaler].customers[customer] = { customerName: name, branch: branch, users: {} };
    }
    if (!this.cache.wholesalers[wholesaler].customers[customer].users[user]) {
      this.cache.wholesalers[wholesaler].customers[customer].users[user] = { userName: name };
    } else {
      this.cache.wholesalers[wholesaler].customers[customer].users[user].userName = name;
    }
  }

  getWholesaler(wholesaler: number): string {
    if (this.cache.wholesalers[wholesaler]) {
      return this.cache.wholesalers[wholesaler].wholesalerName;
    }
    return `grossier ${wholesaler}`;
  }

  getBranch(wholesaler: number, branch: number): string {
    if (this.cache.wholesalers[wholesaler]?.branches[branch]) {
      return this.cache.wholesalers[wholesaler].branches[branch].branchName;
    }
    return `filiaal ${branch}`;
  }

  getCustomer(wholesaler: number, customer: number): string {
    if (this.cache.wholesalers[wholesaler]?.customers[customer]) {
      return this.cache.wholesalers[wholesaler].customers[customer].customerName;
    }
    return `klant ${customer}`;
  }

  getUser(wholesaler: number, customer: number, user: number): string {
    if (this.cache.wholesalers[wholesaler]?.customers[customer]?.users[user]) {
      return this.cache.wholesalers[wholesaler].customers[customer].users[user].userName;
    }
    return `${user}`;
  }

  getUidText(uid: UserIdentificationModel, withWholesaler: boolean): string {
    const user = `gebruiker ${this.getUser(uid.Wholesaler, uid.Customer, uid.UserID)}`;
    const customer = this.getCustomer(uid.Wholesaler, uid.Customer);
    const branch = this.getBranch(uid.Wholesaler, uid.Branch);
    const wholesaler = this.getWholesaler(uid.Wholesaler);
    let text = '';
    if (uid.UserID) { text = user; }
    if (uid.Customer && uid.UserID) { text += ' van '; }
    if (uid.Customer) { text += customer; }
    if (uid.Branch) { text += ` bij ${branch}`; }
    if (withWholesaler && uid.Wholesaler) { text += ` bij ${wholesaler}`; }
    return text;
  }

}
