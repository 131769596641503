export class TypeaheadItem {
  InternalItemNumber: number;
  ItemNumber: string;
  ItemNumberStripped: string;
  Description: string;
  ItemGroup: number;
  ItemGroupDescription: string;
  Score: number;

  // constructor(data: string[]) {
  //   if (data.length > 0) { this.InternalItemNumber = +data[0]; }
  //   if (data.length > 1) { this.ItemNumber = data[1]; }
  //   if (data.length > 2) { this.ItemNumberStripped = data[2]; }
  //   if (data.length > 3) { this.Description = data[3]; }
  //   if (data.length > 4) { this.ItemGroup = +data[4]; }
  //   if (data.length > 5) { this.Accuracy = +data[5]; }
  // }
}
