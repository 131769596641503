import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxItemsFilter',
  pure: false
})
export class MaxItemsFilterPipe implements PipeTransform {

  transform(items: any[], count: number): any {
    if (!count || !items) {return items; }
    const result = items.filter((item, index) => index < count);
    return result;
  }

}
