<div class="header p-3">
  <div class="row">
    <div class="col-9" (contextmenu)="mainService.showDebugInfo($event, carTypeResult.Timing, carTypeResult)">
      <div class="row">
        <div class="col-12"><b>{{getTitle()}}</b></div>
        <div class="col-10">
          <small>
            <app-car-type-info-property [label]="'RDW omschrijving'" [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationVersion">
            </app-car-type-info-property>
            <app-car-type-info-property [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.BuildYear"></app-car-type-info-property>
            <app-car-type-info-property [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.FirstAdmission"></app-car-type-info-property>
            <app-car-type-info-property [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationEngineCapacity">
            </app-car-type-info-property>
            <app-car-type-info-property [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationPower">
            </app-car-type-info-property>
            <app-car-type-info-property [carType]="this.carTypeResult.CarType"
              [propertyKind]="catalogPropertyService.propertyKindCatalog.RegistrationEngineCode">
            </app-car-type-info-property>
          </small>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="input-group float-end">
        <input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="filterString"
          placeholder="filter op type">
      </div>
    </div>
  </div>
</div>
<div class="modal-body"
  [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  <div *ngIf="hasAnyCarTypeAlternatives(carTypeResult.CarType.CatalogCarTypes[catalog])" class="alert alert-danger">
    Ondanks onze uitgebreide methoden om een kenteken te koppelen aan het juiste voertuig, is dat ons met onderstaande
    auto niet gelukt. U kunt eventueel een alternatief kiezen</div>
  <table class="table table-striped table-hover table-sm">
    <thead>
      <tr>
        <th [attr.colspan]="getColumnCount()"></th>
        <th class="text-center">van</th>
        <th class="text-center">tot</th>
        <th *ngFor="let property of propertyDiffs" class="text-nowrap text-center">
          {{catalogPropertyService.getPropertyTranslation(property)}}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="let autotype of carTypeResult.CarType.CatalogCarTypes[catalog] | carTypeFilter: filterString">
        <tr [ngClass]="{'pointer': !autotype['Alternatives'], 'text-low': autotype['Alternatives']}"
          (click)="choose(autotype)">
          <td *ngIf="!sameBrandName">{{autotype.Model?.Brand?.BrandName}}</td>
          <td *ngIf="!sameModelName">{{autotype.Model.ModelName}}</td>
          <td *ngIf="!sameModelRemark">{{autotype.Model.ModelRemark}}</td>
          <td>{{autotype.TypeName}}</td>
          <td>{{autotype.TypeRemark}}</td>
          <td class="text-nowrap text-center">{{autotype?.AvailableFrom?.Display}}</td>
          <td class="text-nowrap text-center">{{autotype?.AvailableUntil?.Display}}</td>
          <td *ngFor="let property of propertyDiffs" class="text-nowrap text-center">
            {{catalogPropertyService.getPropertyValueString(autotype?.Properties, property)}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="footer p-3">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-warning footer-button" (click)="choose(null)">Terug</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-warning footer-button float-end" (click)="cancel()">Annuleren</button>
    </div>
  </div>
</div>