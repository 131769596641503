import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SettingsLoyaltyShop } from '../../../_models/admin/settings/settings.loyalty.shop';
import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-loyalty-shop-settings',
  templateUrl: './admin-loyalty-shop-settings.component.html',
  styleUrls: ['./admin-loyalty-shop-settings.component.scss']
})
export class AdminLoyaltyShopSettingsComponent implements OnInit {
  settings: SettingsLoyaltyShop;
  orderAmount = 100;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

  floor(value: number): number {
    return Math.floor(value);
  }

  getPoints(): number {
    if (this.settings.Ratio_PerAmount > 0) {
      return Math.floor(Math.floor(this.orderAmount / this.settings.Ratio_PerAmount) * this.settings.Ratio_CreditsToReceive);
    }
    return 0;
  }

}
