export enum AppLogLevel {
  Nothing = 0,
  Errors = 1,
  Warnings = 2,
  ErrorsAndWarnings = 3,
  Debugging = 4,
  ErrorsAndWarningsAndDebugging = 7,
  Info = 8,
  ErrorsAndWarningsAndDebuggingAndInfo = 15
}
