import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';

import { ApiService } from './api.service';
import { UniversalCarType } from '../_models/car-types/universal.car.type';
import { Wheel } from '../_models/rims-and-tyres/wheel';
import { MainService } from './main.service';
import { WheelCarPictureComponent } from '../_common/catalog/wheel-car-picture/wheel-car-picture.component';
import { ContextRimsAndTyres } from '../_models/rims-and-tyres/context.rims.and.tyres';
import { WheelInfoPopupComponent } from '../_common/catalog/wheel-info-popup/wheel-info-popup.component';
import { WheelTyreSet } from '../_models/rims-and-tyres/wheel.tyre.set';
import { CatalogService } from './catalog.service';
import { RimsAndTyresOrderPopupComponent } from '../portal/rims-and-tyres/rims-and-tyres-order-popup/rims-and-tyres-order-popup.component';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { TyreInfoComponent } from '../portal/tyres/tyre-info/tyre-info.component';
import { TyresSurchargeData } from '../_models/tyres/tyres.surcharge.data';
import { CartService } from './cart.service';
import { ContextTyres } from '../_models/tyres/context.tyres';
import { TyreRequest } from '../_models/tyres/tyre.request';
import { TyreSize } from '../_models/tyres/tyre.size';
import { TyreResult } from '../_models/tyres/tyre.result';
import { TyreDimension } from '../_models/tyres/tyre.dimension';
import { ShopSoort } from '../_models/common/shop.soort';
import { TyreFilterDescriptions } from '../_models/tyres/tyre.filter.descriptions';
import { SelectListItem } from '../_models/common/select.list.item';
import { CarTypeService } from './car-type.service';
import { RimFilterDescriptions } from '../_models/rims-and-tyres/rim.filter.descriptions';
import { TyreSearchInfo } from '../_models/tyres/tyre.search.info';
import { TyreSeason } from '../_models/tyres/tyre.season';
import { RimRequest } from '../_models/rims-and-tyres/rim.request';
import { SettingsService } from './settings.service';


@Injectable()
export class RimAndTyreService {
  _ctxTyres: ContextTyres;
  _lastCarType: UniversalCarType;
  _ctxRimsAndTyres: ContextRimsAndTyres;
  _tyreRequest: TyreRequest;
  _tyreResult: TyreResult;
  busySearchingTyres = false;
  wheel: Wheel;

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private carTypeService: CarTypeService,
    private catalogService: CatalogService,
    private cartService: CartService,
    private settingsService: SettingsService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  public clear(): void {
    this._ctxTyres = null;
    this._ctxRimsAndTyres = null;
    this._tyreRequest = null;
    this._tyreResult = null;
    this.wheel = null;
  }

  getContextTyres(): Observable<ContextTyres> {
    if (this._ctxTyres) {
      return of(this._ctxTyres);
    } else {
      return this.apiService.getContextTyres()
        .pipe(
          mergeMap((ctx: ContextTyres) => {
            this._ctxTyres = ctx;
            return of(ctx);
          }));
    }
  }

  getEmptyContextRimsAndTyresFilters(): { [key: string]: string[] } {
    const filter: { [key: string]: string[] } = {};
    filter['WheelKinds'] = [];
    filter['Winter'] = [];
    filter['Runflat'] = [];
    filter['Tpms'] = [];
    filter['Diameters'] = [];
    filter['Brands'] = [];
    filter['TyreDimensions'] = [];
    return filter;
  }

  getContextRimsAndTyres(carType: UniversalCarType): Observable<ContextRimsAndTyres> {
    if (this._ctxRimsAndTyres && this.carTypeService.areCarTypesEqual(carType, this._lastCarType)) {
      return of(this._ctxRimsAndTyres);
    } else {
      const cb = this.mainService.callbackInfoBoxExtended('Eén moment geduld...', 'ophalen velgen', 100, 80, 'dit kan even duren');
      // getRims [0] om alleen de eerste diameter op te halen.
      const request = new RimRequest();
      request.Diameters = [0];
      request.CarType = carType;
      return this.apiService.getWheels(request)
        .pipe(mergeMap(ctx => {
          ctx.fetching = true;
          ctx.Filters = this.getEmptyContextRimsAndTyresFilters();
          ctx.FilterDescriptions = this.getFilterDescriptionsWheels(ctx);
          cb.progressValue = 100;
          // cb.description = 'ophalen prijzen en beschikbaarheid';
          // cb.update();
          cb.complete();
          this._ctxRimsAndTyres = ctx;
          this._lastCarType = carType;
          const updateFilters = (): void => {
            this.updateFilterDescriptionStock(ctx);
          };
          updateFilters();
          this.catalogService.getCatalogPartsCartItemsForPartsList(ctx.CarType, ShopSoort.WielenEnBanden, ctx.Wheels, false, ctx.Timing, ctx, updateFilters)
            .subscribe(response => {
              ctx.WheelCartItems = response.CartItems;
              ctx.Timing = response.Timing;
              updateFilters();
              // getRims [-1] om alles behalve de eerste diameter op te halen.
              request.Diameters = [-1];
              request.Timing = ctx.Timing;
              this.apiService.getWheels(request)
                .subscribe(ctxRest => {
                  ctx.Timing = ctxRest.Timing;
                  ctx.Wheels = ctx.Wheels.concat(ctxRest.Wheels);
                  // ctx.WheelThumbs = Object.assign(ctxRest.WheelThumbs, ctx.WheelThumbs);
                  // ctx.WheelImages = Object.assign(ctxRest.WheelImages, ctx.WheelImages);
                  ctx.FilterDescriptions = this.getFilterDescriptionsWheels(ctx);
                  ctx.fetching = false;
                  this.catalogService.getCatalogPartsCartItemsForPartsList(ctx.CarType, ShopSoort.WielenEnBanden, ctxRest.Wheels, false, ctx.Timing, ctx, updateFilters)
                    .subscribe(responseRest => {
                      ctx.Timing = responseRest.Timing;
                      ctx.WheelCartItems = Object.assign(responseRest.CartItems, ctx.WheelCartItems);
                      updateFilters();
                    });
                });
            });
          return of(ctx);
        }));
    }
  }

  tyreRequestsEqualsExceptBrandAndCategory(request1: TyreRequest, request2: TyreRequest): boolean {
    if (request1 && request2 && request1.FixedSupplier === request2.FixedSupplier && request1.ItemCount === request2.ItemCount
      && request1.Shop === request2.Shop && request1.WithPrice === request2.WithPrice && request1.Sizes.length === request2.Sizes.length) {
      let ok = true;
      request1.Sizes.forEach(s1 => {
        let sizeOk = false;
        request2.Sizes.forEach(s2 => {
          if (s1.toString() === s2.toString()) { sizeOk = true; }
        });
        if (!sizeOk) { ok = false; }
      });
      return ok;
    }
    return false;
  }

  getTyreResult(request: TyreRequest): Observable<TyreResult> {
    if (this._tyreRequest && this.tyreRequestsEqualsExceptBrandAndCategory(request, this._tyreRequest)) {
      this._tyreRequest = request;
      const result = Object.assign({}, this._tyreResult);
      this._tyreResult = result;
      return of(result);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'ophalen banden', 'inclusief beschikbaarheid');
      return this.apiService.getTyreSizes(request)
        .pipe(
          mergeMap(tyreResult => {
            this._tyreRequest = request;
            this._tyreResult = tyreResult;
            cb.complete();
            return of(tyreResult);
          }));
    }
  }

  getLastTyresLink(): string[] {
    if (this._tyreRequest && this._tyreRequest) {
      return ['/tyres', this._tyreRequest.Sizes[0].toBareString()];
    }
    return null;
  }

  getTyreSizes(request: TyreRequest): Observable<TyreResult> {
    this.busySearchingTyres = true;
    return this.getTyreResult(request)
      .pipe(
        mergeMap(tyreResult => {
          return this.getContextTyres()
            .pipe(mergeMap((ctx: ContextTyres) => {
              if (request.Brand) {
                ctx.Filters['TyreBrand'] = [request.Brand];
              } else {
                ctx.Filters['TyreBrand'] = [];
              }
              if (request.Category) {
                ctx.Filters['TyreKind'] = [request.Category];
              } else {
                ctx.Filters['TyreKind'] = [];
              }
              tyreResult.FilterDescriptions = this.getFilterDescriptionsTyres(ctx, tyreResult);
              if (ctx.TyresSurcharge.Active) { this.fillSurchargeData(tyreResult.Tyres, ctx.TyresSurcharge); }
              ctx.Timing = tyreResult.Timing;
              this.busySearchingTyres = false;
              return of(tyreResult);
            }));
        }));
  }

  updateFilterDescriptionStock(ctx: ContextRimsAndTyres) {
    if (ctx && ctx.FilterDescriptions && ctx.FilterDescriptions.Stock && ctx.FilterDescriptions.Stock.length === 1) {
      const stock = ctx.FilterDescriptions.Stock[0];
      ctx.Wheels.forEach(wheel => {
        if (this.catalogService.hasStock(wheel, ctx.WheelCartItems)) { stock.count++; }
      });
    }
  }

  countSelectListItemHits(selectListItems: { [key: string]: SelectListItem }, strings: string[]) {
    strings.forEach(s => {
      if (selectListItems[s]) { selectListItems[s].count++; }
    });
  }

  getFilterDescriptionsWheels(ctx: ContextRimsAndTyres): RimFilterDescriptions {
    if (ctx) {
      console.time('getFilterDescriptions');
      const stock = new SelectListItem('Voorraad bekend', ctx.Voorraad);
      const wheelkinds: { [key: string]: SelectListItem } = {};
      wheelkinds['2'] = SelectListItem.getSelectListItemWithValue('Staal', '2', ctx.Filters['WheelKinds'].includes('2'));
      wheelkinds['1'] = SelectListItem.getSelectListItemWithValue('Lichtmetaal', '1', ctx.Filters['WheelKinds'].includes('1'));
      const options: { [key: string]: SelectListItem } = {};
      options['Winter'] = SelectListItem.getSelectListItemWithValue(
        'Geschikt als wintervelg', 'Winter', ctx.Filters['Winter'].length > 0);
      options['Runflat'] = SelectListItem.getSelectListItemWithValue(
        'Geschikt voor runflat banden', 'Runflat', ctx.Filters['Runflat'].length > 0);
      options['Tpms'] = SelectListItem.getSelectListItemWithValue(
        'Geschikt voor TPMS', 'Tpms', ctx.Filters['Tpms'].length > 0);
      const diameters: { [key: string]: SelectListItem } = ctx.Wheels
        .reduce((map, item) => {
          map[item.Diameter.toString()] = SelectListItem.getSelectListItemWithValue(
            `${item.Diameter} inch`, item.Diameter, ctx.Filters['Diameters'].includes(item.Diameter.toString())
          );
          return map;
        }, {});
      const brands: { [key: string]: SelectListItem } = ctx.Wheels
        .reduce((map, item) => {
          map[item.Brand] = SelectListItem.getSelectListItemWithValue(item.Brand, item.Brand, ctx.Filters['Brands'].includes(item.Brand));
          return map;
        }, {});

      const tyredimensions: { [key: string]: SelectListItem }
        = ctx.Wheels.map(w => w.TyreDimensions.map(d => d ? `${d.Width}/${d.AspectRatio}R${d.Diameter}` : ''))
          .reduce((flat, toFlatten) => flat.concat(toFlatten), [])
          .sort()
          .reduce((map, item) => {
            map[item] = SelectListItem.getSelectListItemWithValue(item, item, ctx.Filters['TyreDimensions'].includes(item));
            return map;
          }, {});

      ctx.Wheels.forEach(wheel => {
        const wheelkind = wheel.Materiaal.toString();
        const winter = wheel['Winter'];
        const runflat = wheel['Runflat'];
        const tpms = wheel['Tpms'];
        const diameter = wheel.Diameter.toString();
        const brand = wheel.Brand;

        if (wheelkinds[wheelkind.toString()]) { wheelkinds[wheelkind.toString()].count++; }
        if (winter) { options['Winter'].count++; }
        if (runflat) { options['Runflat'].count++; }
        if (tpms) { options['Tpms'].count++; }
        if (diameters[diameter]) { diameters[diameter].count++; }
        if (brands[brand]) { brands[brand].count++; }
        this.countSelectListItemHits(tyredimensions, wheel.TyreDimensions.map(d => d ? `${d.Width}/${d.AspectRatio}R${d.Diameter}` : ''));

      });

      const descriptions = new RimFilterDescriptions();
      descriptions.Stock = [stock];
      descriptions.WheelKinds = Object.keys(wheelkinds).map(key => wheelkinds[key]);
      descriptions.Options = [options['Winter'], options['Runflat'], options['Tpms']];
      descriptions.Diameters = Object.keys(diameters).map(key => diameters[key]);
      descriptions.Brands = Object.keys(brands).map(key => brands[key]);
      descriptions.TyreDimensions = Object.keys(tyredimensions).map(key => tyredimensions[key]);

      console.timeEnd('getFilterDescriptions');
      return descriptions;
    }
    return null;
  }

  getTyreSearchInfoFromResult(tyreResult: TyreResult): TyreSearchInfo {
    if (tyreResult) {
      const info = new TyreSearchInfo();
      info.Brands = tyreResult.Brands.slice();
      let index = 0;
      info.Seasons = tyreResult.Categories.map(item => {
        const s = new TyreSeason();
        s.TyreSeasonId = index++;
        s.Description = item;
        return s;
      });
      return info;
    }
    return null;
  }

  getFilterDescriptionsTyres(ctx: ContextTyres, tyreResult: TyreResult): TyreFilterDescriptions {
    let tyreSearchInfo: TyreSearchInfo = null;
    if (ctx && ctx.TyreSearchInfo) {
      tyreSearchInfo = ctx.TyreSearchInfo;
    } else if (ctx && !ctx.TyreSearchInfo && tyreResult) {
      tyreSearchInfo = this.getTyreSearchInfoFromResult(tyreResult);
    }
    if (ctx && tyreSearchInfo) {
      console.time('getFilterDescriptions');
      const stock = new SelectListItem('Voorraad bekend', ctx.Voorraad);
      const categories: { [key: string]: SelectListItem } = tyreSearchInfo.Seasons
        .reduce((map, item) => {
          map[item.Description] = new SelectListItem(item.Description, ctx.Filters['TyreKind'].includes(item.Description));
          return map;
        }, {});
      const brands: { [key: string]: SelectListItem } = tyreSearchInfo.Brands
        .reduce((map, item) => {
          map[item] = new SelectListItem(item, ctx.Filters['TyreBrand'].includes(item));
          return map;
        }, {});
      const runflats = new SelectListItem('Alleen runflat banden', ctx.Filters['RunFlatTyre'].includes('1'));
      tyreResult.Tyres.forEach(tyre => {
        const category = tyre.ItemInfo.Properties['TyreKind'].toString();
        const brand = tyre.ItemInfo.Properties['TyreBrand'].toString();
        const runflat = tyre.ItemInfo.Properties['RunFlatTyre'].toString();
        if (this.cartService.isStockOk(tyre.ItemInfo, ctx.OnlyStockSupplier)) { stock.count++; }
        if (categories[category]) { categories[category].count++; }
        if (brands[brand]) { brands[brand].count++; }
        if (runflat === '1') { runflats.count++; }
      });

      const descriptions = new TyreFilterDescriptions();
      descriptions.Stock = [stock];
      descriptions.Categories = Object.keys(categories).map(key => categories[key]);
      descriptions.Brands = Object.keys(brands).map(key => brands[key]).filter(item => item.count > 0 || item.selected);
      descriptions.Runflat = [runflats];

      console.timeEnd('getFilterDescriptions');
      return descriptions;
    }
    return null;
  }

  getTyreImage(tyre: ShoppingCartItem): string {
    if (tyre.ItemInfo.Properties['Image']?.length && tyre.ItemInfo.Properties['Image'][0].length > 1) {
      return tyre.ItemInfo.Properties['Image'][0];
    } else if (tyre.ItemInfo.Properties['Image']?.length) {
      return tyre.ItemInfo.Properties['Image'];
    }
    return null;
  }

  getTyreProfileImage(tyre: ShoppingCartItem, size: string): string {
    if (tyre.ItemInfo.Properties['ProfileImage']) {
      return `${this.mainService.getBackendApi()}/img/tyre/${tyre.ItemInfo.Properties['ProfileImage']}_${size}.png`;
    }
    return null;
  }

  getTyreLabel(tyre: ShoppingCartItem): string {
    if (tyre.ItemInfo && tyre.ItemInfo.Properties &&
      tyre.ItemInfo.Properties['FuelEfficiencyClass'] &&
      tyre.ItemInfo.Properties['WetGripClass'] &&
      tyre.ItemInfo.Properties['RollNoiseValue'] &&
      tyre.ItemInfo.Properties['RollNoiseClass']) {
      let rollNoiseClass = tyre.ItemInfo.Properties['RollNoiseClass'];
      if (rollNoiseClass === 'A') { rollNoiseClass = 0; }
      if (rollNoiseClass === 'B') { rollNoiseClass = 1; }
      if (rollNoiseClass === 'C') { rollNoiseClass = 2; }
      return tyre.ItemInfo.Properties['FuelEfficiencyClass'] + '-' +
        tyre.ItemInfo.Properties['WetGripClass'] + '-' +
        tyre.ItemInfo.Properties['RollNoiseValue'] + '-' +
        rollNoiseClass;
    }
    return '';
  }

  getEprelId(tyre: ShoppingCartItem): number {
    if (tyre?.ItemInfo?.Properties['EprelId']) {
      return tyre.ItemInfo.Properties['EprelId'];
    }
    return 0;
  }

  public getLabelUrl(tyre: ShoppingCartItem, pdf: boolean = false) {
    const eprelId = this.getEprelId(tyre);
    if (eprelId) {
      if (pdf) {
        return `https://eprel.ec.europa.eu/label/Label_${eprelId}.pdf`;
      } else {
        return `https://eprel.ec.europa.eu/label/Label_${eprelId}.png`;
      }
    }
    return null;
  }

  public getProductInfoPdf(tyre: ShoppingCartItem, pdf: boolean = false) {
    const eprelId = this.getEprelId(tyre);
    if (eprelId && !this.settingsService.getSettingBoolean('TyresNoProductInfoLink')) {
      return `https://eprel.ec.europa.eu/informationsheet/Fiche_${eprelId}_NL.pdf`;
    }
    return null;
  }

  public getRunFlat(tyre: ShoppingCartItem): string {
    if (tyre.ItemInfo && tyre.ItemInfo.Properties && tyre.ItemInfo.Properties['RunFlatTyre'] === 0) {
      return 'nee';
    } else if (tyre.ItemInfo && tyre.ItemInfo.Properties && tyre.ItemInfo.Properties['RunFlatTyre'] === 1) {
      return 'ja';
    }
    return '';
  }

  public showTyreInfo(sci: ShoppingCartItem): void {
    const initialState = {
      tyre: sci,
      rimAndTyreService: this
    };
    const modalRef = this.modalService.show(TyreInfoComponent, { initialState, class: 'modal-xxl' });
    // return modalRef.content.onClose;
  }

  public showWheelCarPicture(carType: UniversalCarType, wheel: Wheel): void {
    this.apiService.getWheelCarPictureHtml(carType, wheel)
      .subscribe(html => {
        const initialState = {
          html: html,
          mainService: this.mainService
        };
        const modalRef = this.modalService.show(WheelCarPictureComponent, { initialState, class: 'modal-max' });
      });
  }

  public showWheelInfoPopup(ctx: ContextRimsAndTyres, wheel: Wheel): void {
    const initialState = {
      ctx: ctx,
      wheel: wheel,
      mainService: this.mainService,
      catalogService: this.catalogService
    };
    const modalRef = this.modalService.show(WheelInfoPopupComponent, { initialState, class: 'modal-lg' });
  }

  public showRimsAndTyresOrderPopup(ctx: ContextRimsAndTyres, set: WheelTyreSet): Observable<boolean> {
    const initialState = {
      ctx: ctx,
      set: set,
      mainService: this.mainService
    };
    const modalRef = this.modalService.show(RimsAndTyresOrderPopupComponent, { initialState, class: 'modal-xxl' });
    return modalRef.content.onClose;
  }

  private createWheelTyreSet(ctx: ContextRimsAndTyres, wheel: Wheel): WheelTyreSet {
    const set = new WheelTyreSet();
    set.RequestItemCount = ctx.RequestItemCount;
    set.RequestItemCountsPossible = ctx.RequestItemCountsPossible;
    set.Wheel = wheel;
    if (ctx.WheelCartItems) { set.CartItemWheel = ctx.WheelCartItems[wheel.UniqueID]; }
    set.CarType = ctx.CarType;
    set.BuildMonth = 0;
    set.BuildYear = 0;
    set.Tpms = (set.CarType.Properties['Tpms'] !== null);
    if (ctx.WielenSelectieArtikelenOokBijAlleenVelg) { set.SelectionItems = ctx.SelectionItems; }
    return set;
  }

  public orderRims(ctx: ContextRimsAndTyres, wheel: Wheel): void {
    const set = this.createWheelTyreSet(ctx, wheel);
    this.showRimsAndTyresOrderPopup(ctx, set)
      .subscribe((ok) => {
        if (ok) {
          this.apiService.orderWheelTyreSet(this.cartService, set)
            .subscribe((orderOk) => {
              if (orderOk) {
                this.mainService.msgBox('Bedankt!', 'Uw velgen zijn toegevoegd aan de winkelwagen.');
              } else {
                this.mainService.msgBox('Helaas!', 'Er is iets mis gegaan met het toevoegen aan de winkelwagen.');
              }
            });
        }
      });
  }

  orderRimsAndTyresSet(set: WheelTyreSet, tyre: ShoppingCartItem): void {
    if (this._ctxRimsAndTyres) {
      set.CartItemTyre = tyre;
      this.showRimsAndTyresOrderPopup(this._ctxRimsAndTyres, set)
        .subscribe((ok) => {
          if (ok) {
            this.apiService.orderWheelTyreSet(this.cartService, set)
              .subscribe((orderOk) => {
                if (orderOk) {
                  this.mainService.msgBox('Bedankt!', 'Uw velgen + banden set is toegevoegd aan de winkelwagen.');
                  this.wheel = null;
                  this.chooseRims();
                } else {
                  this.mainService.msgBox('Helaas!', 'Er is iets mis gegaan met het toevoegen aan de winkelwagen.');
                }
              });
          }
        });
    }
  }
 
  chooseTyres(ctx: ContextRimsAndTyres, wheel: Wheel): void {
    this._ctxRimsAndTyres = ctx;
    this.wheel = wheel;
    this.router.navigate(['/rims', this.carTypeService.currentCarType.Id, 'tyres']);
  }

  chooseRims(): void {
    this.router.navigate(['/rims', this.carTypeService.currentCarType.Id]);
  }

  getWheelTyreSet(): WheelTyreSet {
    if (this._ctxRimsAndTyres && this.wheel) {
      return this.createWheelTyreSet(this._ctxRimsAndTyres, this.wheel);
    }
    return null;
  }

  private getTyreSizeFromTyreDimension(tyreDimension: TyreDimension): TyreSize {
    const size = new TyreSize();
    size.Width = tyreDimension.Width;
    size.AspectRatio = tyreDimension.AspectRatio;
    size.Diameter = tyreDimension.Diameter;
    return size;
  }

  private getTyreSizesFromTyreDimensions(tyreDimensions: TyreDimension[]): TyreSize[] {
    return tyreDimensions.map(dimension => this.getTyreSizeFromTyreDimension(dimension));
  }

  public getTyreRequest(set: WheelTyreSet): TyreRequest {
    if (this._ctxRimsAndTyres && this.wheel) {
      return new TyreRequest(
        this.getTyreSizesFromTyreDimensions(this.wheel.TyreDimensions), set.RequestItemCount, true, 0, ShopSoort.WielenEnBanden
      );
    }
    return null;
  }

  public getTyreItemNumberSupplier(tyre: ShoppingCartItem): string {
    if (tyre && tyre.ItemInfo && tyre.ItemInfo.MainSupplier && tyre.ItemInfo.Availability
      && tyre.ItemInfo.Availability.SupplierInfo[tyre.ItemInfo.MainSupplier]
      && tyre.ItemInfo.Availability.SupplierInfo[tyre.ItemInfo.MainSupplier].ItemNumberSupplier) {
      return tyre.ItemInfo.Availability.SupplierInfo[tyre.ItemInfo.MainSupplier].ItemNumberSupplier;
    }
    return tyre.ItemInfo.ItemNumber;
  }

  public fillSurchargeData(tyres: ShoppingCartItem[], surchargeData: TyresSurchargeData): void {
    if (tyres && tyres.length > 0) {
      for (const sci of tyres) {
        if (sci.ItemInfo && sci.ItemInfo.Price && sci.ItemInfo.Price.NetPrice > 0) {
          sci.ItemInfo.Price.RetailPrice = (sci.ItemInfo.Price.NetPrice +
            (sci.ItemInfo.Price.NetPrice * surchargeData.SurchargePercentage / 100) +
            surchargeData.SurchargeAmount) * surchargeData.VatPercentage;
        }
      }
    }
  }

  filterTyreItemHit(sci: ShoppingCartItem, filter: { [key: string]: string[] }, filterItem: string): boolean {
    return (filter[filterItem].length === 0 || filter[filterItem].includes(sci.ItemInfo.Properties[filterItem].toString()));
  }

  filterTyreHit(
    sci: ShoppingCartItem, filter: { [key: string]: string[] }, voorraad: boolean, onlyStockSupplier: boolean
  ): boolean {
    if (!this.filterTyreItemHit(sci, filter, 'TyreSize')) { return false; }
    if (!this.filterTyreItemHit(sci, filter, 'TyreSpeedIndex')) { return false; }
    if (!this.filterTyreItemHit(sci, filter, 'TyreBrand')) { return false; }
    if (!this.filterTyreItemHit(sci, filter, 'TyreKind')) { return false; }
    if (!this.filterTyreItemHit(sci, filter, 'RunFlatTyre')) { return false; }
    if (voorraad && !this.cartService.isStockOk(sci.ItemInfo, onlyStockSupplier)) { return false; }
    return true;
  }

  filterTyres(
    tyreResult: TyreResult, filter: { [key: string]: string[] }, voorraad: boolean, onlyStockSupplier: boolean
  ): ShoppingCartItem[] {
    if (tyreResult && filter) {
      console.time('Filter tyres');
      const tyres = tyreResult.Tyres.filter(tyre => {
        return this.filterTyreHit(tyre, filter, voorraad, onlyStockSupplier);
      }, this);
      console.timeEnd('Filter tyres');
      return tyres;
    }
    return null;
  }

  filterWheelItemHit(fields: any[], filter: { [key: string]: string[] }, filterItem: string): boolean {
    if (filter[filterItem].length === 0) { return true; }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i] && filter[filterItem].includes(fields[i].toString())) { return true; }
    }
    return false;
  }

  filterWheelHit(
    wheel: Wheel, cartItems: { [key: string]: ShoppingCartItem }, filter: { [key: string]: string[] }, voorraad: boolean
  ): boolean {
    if (!this.filterWheelItemHit([wheel.Materiaal], filter, 'WheelKinds')) { return false; }
    if (!this.filterWheelItemHit([wheel.Winter], filter, 'Winter')) { return false; }
    if (!this.filterWheelItemHit([wheel.Runflat], filter, 'Runflat')) { return false; }
    if (!this.filterWheelItemHit([wheel.Tpms], filter, 'Tpms')) { return false; }
    if (!this.filterWheelItemHit([wheel.Diameter], filter, 'Diameters')) { return false; }
    if (!this.filterWheelItemHit([wheel.Brand], filter, 'Brands')) { return false; }
    if (!this.filterWheelItemHit(
      wheel.TyreDimensions.map(d => d ? `${d.Width}/${d.AspectRatio}R${d.Diameter}` : ''), filter, 'TyreDimensions')
    ) { return false; }
    if (voorraad && !this.catalogService.hasStock(wheel, cartItems)) { return false; }
    return true;
  }

  filterWheels(
    ctx: ContextRimsAndTyres, filter: { [key: string]: string[] }, voorraad: boolean
  ): Wheel[] {
    if (ctx && filter) {
      console.time('Filter wheels');
      const wheels = ctx.Wheels.filter(wheel => {
        return this.filterWheelHit(wheel, ctx.WheelCartItems, filter, voorraad);
      }, this);
      console.timeEnd('Filter wheels');
      return wheels;
    }
    return null;
  }

}
