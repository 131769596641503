<div class="dropdown">
  <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
    {{getSelectedItem()}}
  </button>
  <div class="dropdown-menu">
    <a *ngIf="selectAll" class="dropdown-item" (click)="resetSelectedItems()">{{selectAll}}</a>
    <ng-container *ngFor="let item of descriptions">
      <a *ngIf="!item.hidden && !(hideEmptyItems && item.count===0)" class="dropdown-item pointer"
        (click)="setSelectedItem(item)">
        <span *ngIf="multiSelect" class="me-3" [ngClass]="{'check-off': !item.selected}"><i class="far fa-check"></i></span>
        {{item.description}}
        <!-- <span *ngIf="item.count" class="float-end text-low">{{item.count}}</span> -->
      </a>
    </ng-container>
    <a *ngIf="maxItems && descriptions.length > maxItems" class="dropdown-item" (click)="more()"><span
        *ngIf="!showAll">meer
      </span><div class="collapse-button" [ngClass]="{'is-collapsed': showAll}"><i class="fas fa-angle-double-right"></i></div><span *ngIf="showAll"> minder</span></a>
  </div>
</div>
