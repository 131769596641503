import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutKind } from '../../_models/common/layout.kind';
import { ShopSoort } from '../../_models/common/shop.soort';
import { ContextThirdPartyFrame } from '../../_models/thirdparty-shop/context.third.party.frame';
import { SinatecMessage } from '../../_models/thirdparty-shop/Sinatec/sinatec.message';
import { CartService } from '../../_services/cart.service';
import { MainService } from '../../_services/main.service';
import { MessageService } from '../../_services/message.service';
import { ThirdpartyShopService } from '../../_services/thirdparty-shop.service';

@Component({
  selector: 'app-third-party-frame',
  templateUrl: './third-party-frame.component.html',
  styleUrls: ['./third-party-frame.component.scss']
})
export class ThirdPartyFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  shopSoort = ShopSoort;
  @ViewChild('iframe') iframe: ElementRef;
  ctx: ContextThirdPartyFrame;
  scrollLayout: boolean;
  messageListener: Subscription;


  constructor(
    public mainService: MainService,
    public thirdpartyShopService: ThirdpartyShopService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.scrollLayout = this.mainService.getShopPortalLayoutKind() === LayoutKind.ScrollLayout;
    this.route.data
      .subscribe((data: any) => {
        this.receiveMessages(data.ctx);
        this.ctx = data.ctx;
      });
  }

  ngAfterViewInit() {
    // let content = '<button id="button" class="button" >My button </button>';
    // let doc =  this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    // doc.open();
    // doc.write(content);
    // doc.close();
  }

  ngOnDestroy(): void {
    if (this.ctx) {
      this.messageService.unsubscribeMessageListener(ShopSoort[this.ctx.ShopKind], this.ctx.Host, this.messageListener);
    } else if (this.messageListener) { this.messageListener.unsubscribe(); }
  }

  receiveMessages(ctx: ContextThirdPartyFrame): void {
    if (!this.messageService.isRegistered(ShopSoort[ctx.ShopKind], ctx.Host)) {
      this.messageListener = this.messageService.messageListener(ShopSoort[ctx.ShopKind], ctx.Host).subscribe(message => {
        if (message) {
          let handled = false;

          if (ctx.ShopKind === ShopSoort.Sinatec) {
            const msg = message as SinatecMessage;

            if (msg.windowHeight) {
              if (this.scrollLayout) {
                console.info(`${ShopSoort[ctx.ShopKind]} -> set iframe height to ${msg.windowHeight}`);
                this.iframe.nativeElement['style'].height = msg.windowHeight + 'px';
              } else { console.info(`${ShopSoort[ctx.ShopKind]} -> ignore set iframe height to ${msg.windowHeight}`); }
              handled = true;
            }

            if (msg.art_code && msg.number) {
              console.info(`${ShopSoort[ctx.ShopKind]} -> addItemToCart ${msg.number}x ${msg.art_code}`);
              this.cartService.addItemToCart(msg.art_code, 0, msg.description, msg.number, +msg.price.replace(',', '.'), '', '', ctx.ShopKind);
              handled = true;
            }
          }

          if (!handled) { console.info(`${ShopSoort[ctx.ShopKind]} ->`, message); }
        }
      });
    }
  }

}
