import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsBase } from 'app/_models/admin/settings/settings.base';
import { AdminSettingsService } from 'app/_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-vrooam',
  templateUrl: './admin-settings-vrooam.component.html',
  styleUrl: './admin-settings-vrooam.component.scss'
})
export class AdminSettingsVrooamComponent {
  settings: SettingsBase;
  locatie: number = 1;
  property: string = 'Characteristic2';
  description: string = '';

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
        this.fromSettingToProperties();
      });
  }

  fromSettingToProperties() {
    if (this.settings?.CommonSettings['VrooamPromoLabels']) {
      if (+Object.keys(this.settings.CommonSettings['VrooamPromoLabels'].Value)[0]) this.locatie = +Object.keys(this.settings.CommonSettings['VrooamPromoLabels'].Value)[0];
      this.property = Object.keys(this.settings.CommonSettings['VrooamPromoLabels'].Value[this.locatie])[0];
      this.description = this.settings.CommonSettings['VrooamPromoLabels'].Value[this.locatie][this.property];
    } else this.fromPropertiesToSettings();
  }

  fromPropertiesToSettings() {
    if (this.settings?.CommonSettings['VrooamPromoLabels']) {
      this.settings.CommonSettings['VrooamPromoLabels'].Value = {};
      this.settings.CommonSettings['VrooamPromoLabels'].Value[this.locatie.toString()] = {};
      this.settings.CommonSettings['VrooamPromoLabels'].Value[this.locatie.toString()][this.property] = this.description;
    }
  }



}
