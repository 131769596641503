import { Component, OnInit, Input } from '@angular/core';
import { ContextHomeScreen } from '../../../_models/common/context.home.screen';
import { PromotionService } from '../../../_services/promotion.service';
import { ContextPromotions } from '../../../_models/promotions/context.promotions';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-home-webshop',
  templateUrl: './home-webshop.component.html',
  styleUrls: ['./home-webshop.component.scss']
})
export class HomeWebshopComponent implements OnInit {
  @Input() ctx: ContextHomeScreen;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
