import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextTyres } from '../../_models/tyres/context.tyres';
import { TyreResult } from '../../_models/tyres/tyre.result';
import { ShoppingCartItem } from '../../_models/cart/shopping.cart.item';
import { MainService } from '../../_services/main.service';
import { RimAndTyreService } from '../../_services/rim-and-tyre.service';
import { WheelTyreSet } from '../../_models/rims-and-tyres/wheel.tyre.set';
import { ShopSoort } from '../../_models/common/shop.soort';


@Component({
  selector: 'app-tyres',
  templateUrl: './tyres.component.html',
  styleUrls: ['./tyres.component.scss']
})
export class TyresComponent implements OnInit {
  shopSoort = ShopSoort;
  ctx: ContextTyres;
  tyreResult: TyreResult;
  tyres: ShoppingCartItem[];
  set: WheelTyreSet = null;

  constructor(
    private route: ActivatedRoute,
    public mainService: MainService,
    public rimAndTyreService: RimAndTyreService
  ) { }

  ngOnInit() {
    this.rimAndTyreService.getContextTyres()
      .subscribe(ctx => {
        this.ctx = ctx;
        this.route.data
          .subscribe((data: any) => {
            this.tyreResult = data.tyreResult;
            if (this.tyreResult && this.tyreResult.ForRims) { this.set = this.rimAndTyreService.getWheelTyreSet(); }
            this.tyres = this.rimAndTyreService
              .filterTyres(this.tyreResult, this.ctx.Filters, this.ctx.Voorraad, this.ctx.OnlyStockSupplier);
            this.mainService.fireReziseEvent();
          });
      });
  }

}
