<app-admin-form [title]="'Beschikbaarheidstemplate instellingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settingsChanged($event)">

  <app-admin-custom-control [label]="'Test artikel, aantal en eventueel klant'" [centerLabel]="true">
    <div class="row">
      <div class="col-md-4"><app-admin-item-input [labelCols]="0" [dividerKind]="-1"
          [(model)]="adminSettingsService.getAdminSetting(settings,'SettingsAvailabilityTemplates_DefaultTestInternalItemNumber').Value"
          (modelChange)="updateRenderedTemplateParts()"
          [noneSelectionLabel]="'Kies eerst een artikel!'"></app-admin-item-input>
      </div>
      <div class="col-md-4"><app-admin-number-input [labelCols]="2" [label]="'aantal'" [centerLabel]="true"
          [style]="{'height':'62px'}" [dividerKind]="-1"
          [(model)]="adminSettingsService.getAdminSetting(settings,'SettingsAvailabilityTemplates_DefaultTestItemCount').Value"
          (modelChange)="updateRenderedTemplateParts()"></app-admin-number-input>
      </div>
      <div class="col-md-4"><app-admin-customer-input [labelCols]="0" [dividerKind]="-1"
          [model]="adminSettingsService.getAdminSetting(settings,'SettingsAvailabilityTemplates_DefaultTestCustomer').Value"
          (modelObjectChange)="customerChanged($event)"
          [noneSelectionLabel]="'Geen klant gekozen.'"></app-admin-customer-input></div>

    </div>
  </app-admin-custom-control>

  <app-admin-custom-control [label]="'Template indeling'" [busy]="busy"
    (contextmenu)="mainService.showObject($event, data.renderedTemplateParts)">
    <div
      *ngIf="adminSettingsService.getAdminSetting(settings,'SettingsAvailabilityTemplates_DefaultTestInternalItemNumber').Value; else warning"
      class="mt-1">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li *ngFor="let template of data.availabilityTemplateComposition | keyvalue" class="nav-item"
              (click)="currentTemplate=+template.key">
              <button class="nav-link"
                [ngClass]="{'active': currentTemplate===+template.key}">{{getShopModuleName(+template.key)}}</button>
            </li>
            <li class="nav-item" (click)="addTemplate()">
              <button class="nav-link" [ngClass]="{'active': currentTemplate===-99}"><i
                  class="fas fa-plus"></i></button>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">
              <ul cdkDropList [cdkDropListData]="data.availabilityTemplateComposition[currentTemplate]"
                class="list-group list-group-flush composition-parts" (cdkDropListDropped)="drop($event)">
                <li *ngFor="let compositionPart of data.availabilityTemplateComposition[currentTemplate]"
                  class="list-group-item  composition-part" cdkDrag>
                  <div class="placeholder" *cdkDragPlaceholder></div>
                  <div class="row">
                    <div class="col-6 text-truncate"><span cdkDragHandle><i
                          class="fas fa-grip-vertical move"></i></span>
                      {{data.templateParts[compositionPart].Naam}}</div>
                    <div class="col-5"><app-item-info-template-part *ngIf="data.renderedTemplateParts"
                        [templatePart]="data.renderedTemplateParts[compositionPart]"
                        [busy]="(testBusy || busy)?2:0"></app-item-info-template-part></div>
                    <div class="col-1">
                      <div class="btn-group btn-group-xxs me-2" role="group">
                        <button type="button" class="btn btn-outline-secondary" (click)="deletePart(compositionPart)"><i
                            class="fas fa-trash-alt"></i></button>
                        <button *ngIf="hasConfig(compositionPart)" type="button" class="btn btn-outline-secondary"
                          (click)="configurePart(compositionPart)"><i class="fas fa-cog"></i></button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-4">
              <button type="button" class="btn btn-primary w-100 mb-3" (click)="addPart()"><i
                  class="fas fa-arrow-alt-left"></i> Template gedeelte toevoegen</button>
              <div *ngIf="data.renderedTemplateParts" class="small border rounded mb-3 p-2">
                <app-item-info-template-part
                  *ngFor="let compositionPart of data.availabilityTemplateComposition[currentTemplate]"
                  [templatePart]="data.renderedTemplateParts[compositionPart]"
                  [busy]="(testBusy || busy)?2:0"></app-item-info-template-part>
              </div>


              <app-switch [label]="'Test bezig met ophalen'" [model]="testBusy" (modelChange)="testBusy=$event"></app-switch>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ng-template #warning>
      <div class="alert alert-danger" role="alert">
        <i class="fas fa-exclamation-triangle"></i> <span class="m-1"><strong>Let op!</strong></span><br />
        <div class="mt-1">
          Kies hierboven eerst een artikel, zodat er een voorbeeld van het template gemaakt kan worden.
          Er kan eventueel ook een aantal en een klant gekozen worden. Dit kan gebruikt worden om bepaalde delen van de
          template te kunnen
          testen die anders niet zichtbaar zouden worden, zoals bijvoorbeeld staffelprijzen of route tijden.
        </div>
      </div>
    </ng-template>

  </app-admin-custom-control>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>