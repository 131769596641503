import { Component, OnInit, Input } from '@angular/core';

import { UniversalCarBrand } from '../../../../_models/car-types/universal.car.brand';
import { CarTypeService } from '../../../../_services/car-type.service';

@Component({
  selector: 'app-car-brand-display',
  templateUrl: './car-brand-display.component.html',
  styleUrls: ['./car-brand-display.component.scss']
})
export class CarBrandDisplayComponent implements OnInit {
  @Input() carBrand: UniversalCarBrand;
  @Input() defaultText: string;

  constructor(
    public carTypeService: CarTypeService
  ) { }

  ngOnInit() {
  }

  getName(): string {
    if (this.carBrand) {
      return this.carBrand.BrandName;
    }
    return this.defaultText;
  }

  hasBrand(): boolean {
    if (this.carBrand) {
      return true;
    }
    return false;
  }

  hasLogo(): boolean {
    return (this.carBrand && this.carBrand.BrandLogo !== null && this.carBrand.BrandLogo !== '');
  }

}
