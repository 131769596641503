<app-content-list-item *ngIf="getCartItemForItem(ctx.CartItems, item)" [item]="item" [odd]="odd" [columnWidths]="[2,5,5]">
  <ng-container col-1>
    <img *ngFor="let img of item.Images" [src]="img.Size_original" class="img-fluid grow max-size m-2" />
    <!-- <div class="row mt-3 px-2">
      <div class="col">
        <button *ngIf="part.SupportsGetCarTypesByPart" type="button" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Application)">
          <i class="fas fa-car pointer"></i></button>
        <button type="button" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Details)">
          <i class="fas fa-info"></i></button>
        <button *ngIf="part.HasSubParts" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showSubParts(ctx.CarType, ctx.Category, part)" tooltip="Toebehoren">
          <i class="fas fa-plus"></i>
        </button>
        <app-catalog-part-document-buttons [part]="part" [button]="true"></app-catalog-part-document-buttons>
      </div>
    </div> -->
  </ng-container>
  <ng-container col-2>
    <div class="part-item-number">{{item.ArticleNumber}}</div>
    <div class="row part-item-info">
      <div class="col-6">Capaciteit:</div><div class="col-6">{{item.Capacity}}{{item.CapacityType}}</div>
      <div class="col-6">Per doos:</div><div class="col-6">{{item.ItemsPerBox}}</div>
      <div *ngIf="item.ItemsPerPallet" class="col-6">Per pallet:</div><div *ngIf="item.ItemsPerPallet" class="col-6">{{item.ItemsPerPallet}}</div>
    </div>
  </ng-container>
  <ng-container col-3>
    <div class="part-item-info">
      <app-item-info-template [busy]="ctx['busy']" [sci]="getCartItemForItem(ctx.CartItems, item)"
        [nettoprijs]="ctx.Nettoprijs"></app-item-info-template>
    </div>
    <div class="my-3"><app-item-count-wide [right]="true" [sci]="getCartItemForItem(ctx.CartItems, item)"></app-item-count-wide></div>
  </ng-container>
</app-content-list-item>