<app-admin-form [title]="'Layout instellingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event">
  <app-admin-dropdown [label]="'Layout soort'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value" [modelValues]="[0,1]"
    [modelDisplayValues]="{'0':'Vaste hoogte','1':'Nieuwe Scrolllayout'}"></app-admin-dropdown>
  <app-admin-dropdown [label]="'Breedte scherm'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'ShopPortalFullWidth').Value" [modelValues]="[false,true]"
    [modelDisplayValues]="{false:'Gecentreerd',true:'Volledige breedte'}"></app-admin-dropdown>
  <app-admin-dropdown [label]="'Headertemplate'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'HeaderTemplate').Value"
    [modelValues]="adminSettingsService.getAdminSettingValue(settings,'HeaderTemplateKinds')"></app-admin-dropdown>

  <app-admin-checkbox
    *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value===layoutKind.ScrollLayout"
    [label]="'Volgende route in header'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'GeenVolgendeRouteInHeader').Value" [invertModel]="true">
  </app-admin-checkbox>
  <app-admin-checkbox
    *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value===layoutKind.ScrollLayout && !adminSettingsService.getAdminSetting(settings,'GeenVolgendeRouteInHeader').Value"
    [label]="'Toon seconden bij volgende routetijd'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'NextRouteCounterWithSeconds').Value">
  </app-admin-checkbox>
  <app-admin-editor
    *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value===layoutKind.ScrollLayout"
    [label]="'Tekst rechts in header'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'TekstRechtsInHeader').Value"></app-admin-editor>
  <app-admin-checkbox
    *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value===layoutKind.ScrollLayout"
    [label]="'Verberg navigatie template'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'DisableNavigationTemplate').Value"></app-admin-checkbox>
  <app-admin-checkbox
    *ngIf="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value===layoutKind.ScrollLayout"
    [label]="'Footer actief'" [(model)]="adminSettingsService.getAdminSetting(settings,'DisableFooterTemplate').Value"
    [invertModel]="true"></app-admin-checkbox>
  <app-admin-editor
    *ngIf="adminSettingsService.adminSettingArrayContainsItem(settings,'TekstNaastLogoInHeaderHeaderTemplateKinds',adminSettingsService.getAdminSetting(settings,'HeaderTemplate').Value)"
    [label]="'Tekst naast logo in header'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'TekstNaastLogoInHeader').Value"></app-admin-editor>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>

<div class="example">

</div>