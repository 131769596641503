import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';

@Component({
  selector: 'app-side-menu-default',
  templateUrl: './side-menu-default.component.html',
  styleUrls: ['./side-menu-default.component.scss']
})
export class SideMenuDefaultComponent implements OnInit {
@Input() ctx: ContextMain;

  constructor() { }

  ngOnInit() {
  }

}
