<div class="card border flexbox-grow mb-3">
  <div class="card-header table-header-container text-bg-dark" #rowHeaderContainer>
    <div style="display:flex;flex-direction: row;">
      <div style="flex-grow: 1;" class="row table-header">
        <ng-content select="[grid-header]"></ng-content>
      </div>
      <div style="background-color:transparent;"></div>
    </div>
  </div>
  <div class="card-body table-rows-container flexbox-ignore flexbox-column-dir" #rowsContainer>
    <div *ngIf="!isBusy">
      <div *ngFor="let item of items; let odd = odd; let index = index;" class="row table-row" [ngClass]="{'table-row-odd': odd, 'table-row-selected': index === selectedIndex, 'pointer': withRowClick, 'table-row-hover': enableRowSelection}"
        (click)="rowClickInternal(item, index)">
        <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
      </div>
      <div *ngIf="!items || items.length == 0" class="row col-12 empty-text">
        {{emptyText}}
      </div>
    </div>
    <div *ngIf="isBusy" class="is-busy">
      <i class="fas fa-sync-alt fa-spin fa-3x"></i>
    </div>
  </div>
</div>
