import { Component, Input, OnInit } from '@angular/core';
import { ResponseItem } from '../../../../_models/item-info/response.item';
import { FavoritesService } from '../../../../_services/favorites.service';
import { MainService } from '../../../../_services/main.service';
import { ShopService } from '../../../../_services/shop.service';
import { Part } from '../../../../_models/catalog/part';
import { ShoppingCartItem } from '../../../../_models/cart/shopping.cart.item';

@Component({
  selector: 'app-favorites-control',
  templateUrl: './favorites-control.component.html',
  styleUrls: ['./favorites-control.component.scss']
})
export class FavoritesControlComponent implements OnInit {
  @Input() itemInfo: ResponseItem;
  @Input() part: Part;
  @Input() sci: ShoppingCartItem;
  enabled = false;
  isFavorite = false;
  startStatus = false;

  constructor(
    private mainService: MainService,
    private shopService: ShopService,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.favoritesService.areFavoritesEnabled()
      .subscribe(enabled => this.enabled = enabled);
    this.favoritesService.isFavorite(this.itemInfo?.InternalItemNumber)
      .subscribe(isFavorite => {
        this.isFavorite = isFavorite;
        this.startStatus = isFavorite;
      });
  }


  debug(event: MouseEvent): void {
    this.favoritesService.getFavorites()
      .subscribe(favorites => this.mainService.showObject(event, favorites));
  }

  toggleFavorite(): void {
    this.isFavorite = !this.isFavorite;
    this.favoritesService.toggleFavorite(this.itemInfo?.InternalItemNumber, this.part, this.sci);
  }

}
