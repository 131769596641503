<app-admin-form [title]="'Klanten Informatie'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">
  <app-admin-checkbox-input [label]="'Gebruik een wachtwoord voor alle of sommige modules'"
    [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'GisPasswordDisabled').Value" [invertModel]="true"
    [(modelInput)]="adminSettingsService.getAdminSetting(settings,'ShopModule_GarageInformatieSysteem_ExtraPassword').Value"
    [placeholder]="'Type een wachtwoord...'"></app-admin-checkbox-input>
  <app-admin-checkbox-list [label]="'Activeer de volgende modules'" [modelArray]="getActiveModels()" [checkboxCols]="3"
    [invertModel]="true"></app-admin-checkbox-list>
  <app-admin-checkbox-list *ngIf="!adminSettingsService.getAdminSetting(settings,'GisPasswordDisabled').Value"
    [label]="'Verplicht het wachtwoord voor de volgende modules'" [modelArray]="getPasswordModels()" [checkboxCols]="3"
    [invertModel]="true"></app-admin-checkbox-list>
  <app-admin-cancel-save-button [saveAction]="save()"></app-admin-cancel-save-button>
</app-admin-form>