import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ShopService } from '../_services/shop.service';
import { CarTypeService } from '../_services/car-type.service';
import { MainService } from '../_services/main.service';
import { CarTypeResult } from '../_models/car-types/car.type.result';
import { ApiService } from '../_services/api.service';
import { ShopSoort } from '../_models/common/shop.soort';

@Injectable()
export class CarTypeGuard  {

    constructor(
        private mainService: MainService,
        private apiService: ApiService,
        private shopService: ShopService,
        private carTypeService: CarTypeService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.mainService.getContextMain('', true)
            .pipe(mergeMap((ctx) => {
                const shopSoort = this.shopService.getShopSoortFromRoute(route);
                const shop = this.shopService.getShopModuleByShopSoort(ctx, shopSoort);
                if (!shop) {
                    // no shop???
                    console.warn('No shop!?!?');
                    return of(false);
                } else if (!shop.GebruiktAutotypes) {
                    // it doesn't use cartypes... so ok!
                    return of(true);
                } else {
                    return this.carTypeService.getCarTypeSelectionInfo(shopSoort)
                        .pipe(mergeMap(info => {
                            if (!info || info.CompleteSelectionRequired) {
                                // no cartype found for shop so redirect to cartype selection page with the return url
                                console.info(`CarTypeGuard no cartype -> choose "/cartype/${shopSoort}" and return to "${state.url}"`);
                                this.router.navigate(['/cartype/' + shopSoort], { queryParams: { returnUrl: state.url } });
                                return of(false);
                            } else if (info.NarrowDownSelectionRequired) {
                                console.info(`CarTypeGuard more than one cartype for shopSoort: ${ShopSoort[shopSoort]} -> show selection`);
                                const result = new CarTypeResult();
                                result.CarType = info.CurrentCarType;
                                result.Timing = info.Timing;
                                return this.carTypeService.narrowCarTypeResult(result)
                                    .pipe(mergeMap(carResult => {
                                        if (carResult && carResult.CarType) {
                                            console.info(`CarTypeGuard one cartype selected for shopSoort: ${ShopSoort[shopSoort]}`);
                                            return this.carTypeService.saveCarType(shopSoort, carResult.CarType)
                                                .pipe(mergeMap(res => {
                                                    console.info(`CarTypeGuard cartype: '${res.CarType.TypeId}' saved for shopSoort: ${ShopSoort[shopSoort]}`);
                                                    this.carTypeService.setCarType(res.CarType);
                                                    return of(true);
                                                }));
                                        } else {
                                            return of(false);
                                        }
                                    }));
                            } else {
                                // cartype found... so ok!
                                console.info(`CarTypeGuard cartype: '${info.CurrentCarType.TypeId}' ok for shopSoort: ${ShopSoort[shopSoort]}`);
                                return of(true);
                            }
                        }));
                }
            }));
    }
}
