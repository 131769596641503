<div class="container">

  <app-breadcrumb-header [debugObject]="ctx" [autoStart]="true"></app-breadcrumb-header>

  <div class="row">
    <div class="col-lg-6">

      <div class="card border mb-3">

        <div class="card-header card-header-compact  text-bg-dark">Spaaracties</div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action pointer home-item alt-back">
            <div class="row">
              <div class="col-5">
                Opleidingspunten
              </div>
              <div class="col-7">
                9999 punten
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action pointer home-item">
            <div class="row">
              <div class="col-5">
                Inschrijving geopend
              </div>
              <div class="col-7">
                0%
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div class="col-lg-6">

      <app-promotion-carousel></app-promotion-carousel>




    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">

      <div class="card border mb-3">

        <div class="card-header card-header-compact  text-bg-dark">Nieuwsberichten</div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action pointer home-item alt-back">
            <div class="row">
              <div class="col-8">
                Profiteer van hoge korting!
              </div>
              <div class="col-4 text-end">
                24-01-2019
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action pointer home-item">
            <div class="row">
              <div class="col-8">
                Speciale acties voor onze trouwe klanten
              </div>
              <div class="col-4 text-end">
                25-01-2019
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action pointer home-item alt-back">
            <div class="row">
              <div class="col-8">
                Nieuw nieuw nieuw!
              </div>
              <div class="col-4 text-end">
                26-01-2019
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div class="col-lg-6">

      <div class="card border mb-3">

        <div class="card-header card-header-compact  text-bg-dark">Activiteiten</div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action pointer home-item alt-back">
            <div class="row">
              <div class="col-8">
                Informatieavond Remmen (meerdere data/locaties)
              </div>
              <div class="col-4 text-end">
                24-01-2019
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action pointer home-item">
            <div class="row">
              <div class="col-8">
                Informatieavond Airco (meerdere data/locaties)
              </div>
              <div class="col-4 text-end">
                25-01-2019
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-action pointer home-item alt-back">
            <div class="row">
              <div class="col-8">
                Informatieavond Turbo (meerdere data/locaties)
              </div>
              <div class="col-4 text-end">
                26-01-2019
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </div>


</div>
