import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ItemImageItemNumber } from '../../_models/admin/item-images/item.image.item.number';
import { AdminItemImagesService } from '../../_services/admin/admin-item-images.service';

@Injectable({
  providedIn: 'root'
})
export class AdminItemImagesItemNumberEditResolverService  {

  constructor(
    private adminItemImagesService: AdminItemImagesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ItemImageItemNumber> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.adminItemImagesService.getItemImageItemNumber(+id).pipe(
      take(1),
      mergeMap(item => {
        if (item) {
          return of(item);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
