import { AvailabilityTemplateSubPart } from "./availability.template.sub.part";

export class VoorraadTemplateRendered {
    Id: number;
    Description: string;
    Html: string;
    Width: number;
    Height: number;
    HorizontalAlignment: number;
    Value: any;
    RenderedSubParts: AvailabilityTemplateSubPart[];
}
