<ng-template #dropdownTemplate let-model='model'>
  <span *ngIf="model; else noneSelected">{{model?.BranchName}}</span>
  <ng-template #noneSelected>{{noneSelectionLabel}}</ng-template>
</ng-template>

<ng-template #dropdownSuperAdminTemplate let-model='model'>
  <span *ngIf="model; else noneSelected"><span class="me-3">{{model?.BranchName}}</span><span class="text-low float-end">{{model?.BranchNumber}}</span></span>
    <ng-template #noneSelected>{{noneSelectionLabel}}</ng-template>
</ng-template>
<app-admin-dropdown [label]="label" [labelCols]="labelCols" [(model)]="model"
  [modelDisplayValues]="branches" [buttonTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate"
  [itemTemplate]="adminService.superAdmin?dropdownSuperAdminTemplate:dropdownTemplate" [dividerKind]="dividerKind" (modelChange)="modelChange.emit($event)">
</app-admin-dropdown>