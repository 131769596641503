import { Component, OnInit, Input } from '@angular/core';

import { ContextCatalog } from '../../../_models/catalog/context.catalog';
import { Part } from '../../../_models/catalog/part';
import { MainService } from '../../../_services/main.service';
import { CatalogService } from '../../../_services/catalog.service';
import { CatalogPartPopupInfoKind } from '../../../_models/catalog/catalog-part-popup-info-kind';
import { HotkeyService } from '../../../_services/hotkey.service';

@Component({
  selector: 'app-catalog-part-list-item',
  templateUrl: './catalog-part-list-item.component.html',
  styleUrls: ['./catalog-part-list-item.component.scss']
})
export class CatalogPartListItemComponent implements OnInit {
  catalogPartPopupInfoKind = CatalogPartPopupInfoKind;
  @Input() ctx: ContextCatalog;
  @Input() part: Part;
  @Input() partsDescription: string;
  @Input() odd: boolean;

  constructor(
    public mainService: MainService,
    public catalogService: CatalogService,
    public hotkeyService: HotkeyService
  ) { }

  ngOnInit() {
  }

}
