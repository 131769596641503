import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../_models/common/context.main';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
@Input() ctx: ContextMain;
  constructor() { }

  ngOnInit() {
  }

}
