<div [ngClass]="{'cart-container': mainService.getShopPortalLayoutKind() === layoutKind.BoxedLayout}">
  <div class="container">
    <div class="row mt-3">
      <div class="col-8 form-group">
        <div class="row">
          <div class="col">
            <h4 (contextmenu)="mainService.showDebugInfo($event, cartService.getCartContextTiming(), ctx)">
              Uw bestelling...</h4>
            <br />
            <input type="text" [(ngModel)]="Order.Referentie" class="form-control" maxlength="50"
              placeholder="Hier kunt u een referentie in geven.">
          </div>
        </div>
        <div *ngIf="ctx.CartItems.length!=0" class="row">
          <div class="col-12 pt-3 pointer">
            <div (click)="Order.Levermode = 0">
              <span *ngIf="Order.Levermode !== 1" class="radio-button"><i class="far fa-dot-circle"></i></span>
              <span *ngIf="Order.Levermode === 1" class="radio-button"><i class="far fa-circle"></i></span>
              Deze bestelling wordt afgehaald.
            </div>
          </div>
          <div class="col-12 pb-3 pointer">
            <div (click)="Order.Levermode = 1">
              <span *ngIf="Order.Levermode === 1" class="radio-button"><i class="far fa-dot-circle"></i></span>
              <span *ngIf="Order.Levermode !== 1" class="radio-button"><i class="far fa-circle"></i></span>
              Deze bestelling moet bezorgd worden.
            </div>
          </div>
          <ng-container *ngIf="!ctx.NoDeliveryMomentChoiceInCart && cartService.deliveryMomentChoiceNeeded(cartService.getCartItems())">
            <div class="col-12 pointer">
              <div (click)="Order.DeliveryMoment = 1">
                <span *ngIf="Order.DeliveryMoment === 1" class="radio-button"><i class="far fa-dot-circle"></i></span>
                <span *ngIf="Order.DeliveryMoment !== 1" class="radio-button"><i class="far fa-circle"></i></span>
                Mag uitgeleverd worden wanneer order compleet is.
              </div>
            </div>
            <div class="col-12 pb-3 pointer">
              <div (click)="Order.DeliveryMoment = 2">
                <span *ngIf="Order.DeliveryMoment === 2" class="radio-button"><i class="far fa-dot-circle"></i></span>
                <span *ngIf="Order.DeliveryMoment !== 2" class="radio-button"><i class="far fa-circle"></i></span>
                Items die op voorraad zijn vast uitleveren.
              </div>
            </div>
          </ng-container>
          <div *ngIf="!ctx.GeenRoutetijdenKeuzeInShoppingCart">
            <div *ngFor="let route of ctx.Routes" class="col-12">
              <div (click)="clickRoute(route)" style="cursor: pointer;">
                <span *ngIf="isChosenRoute(route)" class="radio-button pe-1"><i class="far fa-dot-circle"></i></span>
                <span *ngIf="!isChosenRoute(route)" class="radio-button pe-1"><i class="far fa-circle"></i></span>
                <app-route-info [route]="route" [deliveryMode]="Order.Levermode"
                  [withDescription]="settingsService.getSettingBoolean('RouteDescriptionsInShoppingCart')">
                </app-route-info>
              </div>
            </div>
          </div>
          <div *ngIf="ctx.GeenRoutetijdenKeuzeInShoppingCart">
            <div *ngFor="let choice of ctx.AfleverKeuzes" class="col-12">
              <div *ngIf="enableDisplayAfleverKeuze(choice)" (click)="clickChoice(choice)"
                [ngClass]="{'select-option': isValidAfleverKeuze(choice), 'select-option-disabled': !isValidAfleverKeuze(choice)}">
                <span *ngIf="isChosenAfleverKeuze(choice)" class="radio-button"><i class="far fa-dot-circle"></i></span>
                <span *ngIf="!isChosenAfleverKeuze(choice)" class="radio-button"><i class="far fa-circle"></i></span>
                {{choice.ChoiceDescription}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!ctxMain.CompanyInfo.CompanyInfoInHeader" class="col-4">
        <app-login-info [ctx]="ctxMain"></app-login-info>
      </div>
    </div>

    <div *ngIf="!cartService.isCartEmpty()" class="card my-3">
      <div class="card-header text-bg-primary">
        <div class="row">
          <div class="col-lg-3 col-8">Artikel</div>
          <div class="col-xl-1 col-lg-2 col-4 text-center">Aantal</div>
          <div class="d-none d-lg-block" [ngClass]="{'col-xl-3': showNettoPrices(), 'col-xl-4': !showNettoPrices(), 'col-lg-4': showNettoPrices(), 'col-lg-5': !showNettoPrices()}"><app-item-info-template [cartItems]="cartService.getCartItems()"></app-item-info-template></div>
          <div class="col-xl-1 d-none d-xl-block text-end">Bruto</div>
          <div *ngIf="showNettoPrices()" class="col-xl-1 d-none d-xl-block d-xxl-block text-end">Netto</div>
          <div class="d-none d-lg-block col-lg-2 text-center">Kenteken</div>
          <div class="d-none d-lg-block col-lg-1 text-end"><span *ngIf="cartService.busyUpdating"><i class="fas fa-sync-alt fa-spin"></i></span></div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngFor="let sci of cartService.getCartItems();let odd=odd; let index=index">
          <div class="row py-1" [ngClass]="{'bg-body-tertiary':odd}"></div>
          <div class="row" *ngIf="cartService.getCartItemTextLines(sci,0)" app-cart-text-lines
              [textLines]="cartService.getCartItemTextLines(sci,0)" [ngClass]="{'bg-body-tertiary':odd}">
          </div>
          <div class="row" [ngClass]="{'bg-body-tertiary':odd}">
            <div *ngIf="sci.BelongsToCompositeItem != sci.Artikelnr && !cartService.isLoyaltyShopItem(sci)" class="col-lg-3 col-8">
              <b>{{sci.Artikelnr}}</b><br />
              <span *ngIf="sci.ItemInfo && sci.ItemInfo.Brand">{{sci.ItemInfo.Brand}}<br /></span>
              <span>{{sci.Omschrijving}}</span>
            </div>
            <div *ngIf="sci.BelongsToCompositeItem == sci.Artikelnr" class="col-lg-3 col-8">
              <ng-template #popTemplate>
                <div class="composite-content-popover">
                  <div *ngFor="let item of cartService.getCompositeItem(sci).CompositeItemContent" class="row">
                    <span class="col-2">{{item.Aantal}}x</span>
                    <span class="col-10">{{item.Omschrijving}}</span>
                  </div>
                </div>
              </ng-template>
              <div [popover]="popTemplate" popoverTitle="Samengesteld artikel" triggers="mouseenter:mouseleave"
                placement="top">
                <i class="fas fa-archive"></i>&nbsp;
                <span>{{sci.Omschrijving}}</span>
              </div>
            </div>
            <div *ngIf="cartService.isLoyaltyShopItem(sci)" class="col-lg-3 col-8">
              <div>
                <i class="fas fa-gift"></i>&nbsp;
                <span>{{sci.Omschrijving}}</span>
              </div>
            </div>
            <div class="col-xl-1 col-lg-2 col-4 text-center">
              <app-item-count *ngIf="sci.BelongsToCompositeItem != sci.Artikelnr && !cartService.isLoyaltyShopItem(sci);else fixed" [sci]="sci" [center]="true"></app-item-count>
              <ng-template #fixed><span class="total-item-count-fix">{{sci.Aantal}}</span></ng-template>
              <div class="d-block d-lg-none">
                <img *ngIf="sci.KentekenCoded" class="my-2" style="width: 100px;" [src]="licensePlateService.getLicensePlateUrl(sci.Kenteken) | async" /><br />
                <button type="button" class="btn btn-danger" aria-label="Wissen"
                (click)="cartService.deleteShoppingCartItem(sci)">
                <i class="fas fa-trash-alt"></i>
              </button>
              </div>
            </div>
            <div class="col-8" [ngClass]="{'col-xl-3': showNettoPrices(), 'col-xl-4': !showNettoPrices(), 'col-lg-4': showNettoPrices(), 'col-lg-5': !showNettoPrices()}">
              <app-item-info-template *ngIf="sci.BelongsToCompositeItem != sci.Artikelnr && !cartService.isLoyaltyShopItem(sci); else altItem" [itemInfo]="sci.ItemInfo" [sci]="sci"
                [nettoprijs]="ctx.NettoprijsOpties[shopService.getShopKindName(mainService.ctxMain, sci.Herkomst)]">
              </app-item-info-template>
              <ng-template #altItem>
                <div *ngIf="cartService.isLoyaltyShopItem(sci)" colspan="3">
                  Kadoshop artikel:
                  <span *ngIf="sci.Punten">{{sci.Punten}} punten</span>
                  <span *ngIf="sci.VastePrijs">&euro; {{sci.VastePrijs}}</span>
                </div>
              </ng-template>
            </div>
            <div class="col-xl-1 d-none d-xl-block text-end">
              <span *ngIf="!cartService.isLoyaltyShopItem(sci)">{{cartService.getTotalValueByTemplatePartDescription(sci,'Bruto') | price}}</span>
            </div>
            <div *ngIf="showNettoPrices()" class="col-xl-1 d-none d-xl-block text-end">
              <ng-container *ngIf="!cartService.isLoyaltyShopItem(sci) && ctx.ToonNettoPrijzen != 2 && !cartService.getNetPriceHidden()">
                <span *ngIf="ctx.NettoprijsOpties[shopService.getShopKindName(mainService.ctxMain, sci.Herkomst)]">
                  {{cartService.getTotalValueByTemplatePartDescription(sci,'Netto') | price}}
                </span>
                <span *ngIf="!ctx.NettoprijsOpties[shopService.getShopKindName(mainService.ctxMain, sci.Herkomst)]">
                  <i class="fas fa-lock" tooltip="De nettoprijzen voor {{shopService.getShopModuleName(ctxMain, sci.Herkomst)}} staan uit. U kunt uw grossier vragen om dit aan te zetten."
                    data-placement="left"></i>
                </span>
              </ng-container>
            </div>
            <div class="d-none d-lg-block col-lg-2 col-3 text-center">
              <img *ngIf="sci.KentekenCoded" style="width: 100px;" [src]="licensePlateService.getLicensePlateUrl(sci.Kenteken) | async" />
            </div>
            <div class="d-none d-lg-block col-lg-1 col-1 text-end">
              <div class="btn-group" role="group">
                <button *ngIf="cartService.hasAlternatives(sci)" type="button" class="btn btn-warning"
                  (click)="cartService.chooseAlternative(sci, ctx.NettoprijsOpties[shopService.getShopKindName(mainService.ctxMain, sci.Herkomst)])">
                  <i class="fas fa-exclamation-triangle" tooltip="Klik hier voor alternatieven voor dit artikel."
                    data-placement="top"></i>
                </button>
                <button type="button" class="btn btn-danger" aria-label="Wissen"
                  (click)="cartService.deleteShoppingCartItem(sci)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="cartService.getCartItemTextLines(sci,1)" app-cart-text-lines
              [textLines]="cartService.getCartItemTextLines(sci,1)" [ngClass]="{'bg-body-tertiary':odd}">
          </div>
          <div class="row py-1" [ngClass]="{'bg-body-tertiary':odd}"></div>
        </ng-container>
      </div>
      <div class="card-footer text-bg-secondary">
        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-xl-1 col-lg-2 col-3 text-center"><span class="total-item-count-fix">{{cartService.getCartItemCount()}}</span></div>
          <div class="d-none d-xl-block" [ngClass]="{'col-xl-3': showNettoPrices(), 'col-xl-4': !showNettoPrices()}"></div>
          <div class="col-xl-1 col-lg-2 col-3 text-end">{{cartService.getCartTotalGross() | price}}</div>
          <div *ngIf="showNettoPrices()" class="col-xl-1 col-lg-2 col-3 text-end">
            <span *ngIf="cartService.isCartTotalNetLocked(shopService)"
              tooltip="De nettoprijzen voor één of meerdere items staan uit. U kunt uw grossier vragen om dit aan te zetten."
              data-placement="left">
              <i class="fas fa-lock"></i>
            </span>
            <span *ngIf="!cartService.isCartTotalNetLocked(shopService)">{{cartService.getCartTotalNet() | price}}</span>
          </div>
          <div class="d-none d-xl-block col-xl-2 text-center"></div>
          <div class="d-none d-xl-block col-xl-1 text-end"></div>
        </div>
      </div>
    </div>

    <div *ngIf="cartService.isCartEmpty()" id="cart-info" class="alert alert-info mt-3" role="alert">
      <span *ngIf="!cartService.busyUpdating" (click)="cartService.updateCart(true)"><i
          class="fas fa-info-circle"></i></span>
      <span *ngIf="cartService.busyUpdating"><i class="fas fa-sync-alt fa-spin"></i></span>
      Op dit moment is uw winkelwagen nog leeg.
    </div>

    <div class="row">
      <div class="col-8">
        <textarea class="form-control" [(ngModel)]="Order.Opmerking" placeholder="Type hier eventuele opmerkingen."
          style="height: 70px;"></textarea>
      </div>
      <div class="col-4">&nbsp;</div>
    </div>
    <div class="row my-3">
      <div class="col-4 pe-2">
        <button type="button" class="btn btn-primary w-100"
          (click)="orderShoppingCart()">{{cartService.getCartContext().BestelKnopTekst || 'Bestel'}}</button>
      </div>
      <div class="col-4 ps-2">
        <button type="button" class="btn btn-primary w-100" (click)="emptyShoppingcart()"
          [disabled]="cartService.getCartItemCount() === 0">Wis bestelling</button>
      </div>
      <div class="col-4">&nbsp;</div>
    </div>
  </div>
</div>