<div class="footer text-bg-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg">
        <div class="footer-header pb-2">Over Autotaal</div>
        <ul class="footer-list mt-2">
          <li><a><span>Even voorstellen</span></a></li>
          <li><a><span>Geschiedenis</span></a></li>
          <li><a><span>Klant worden...</span></a></li>
          <li><a><span>Werken bij</span></a></li>
        </ul>
      </div>
      <div class="col-lg">
        <div class="footer-header pb-2">Blah blah</div>
        <ul class="footer-list mt-2">
          <li><a><span>blah</span></a></li>
          <li><a><span>blah blah</span></a></li>
        </ul>
      </div>
      <div class="col-lg">
        <div class="footer-header pb-2">Nog meer blah</div>
        <ul class="footer-list mt-2">
          <li><a><span>blaaaah</span></a></li>
          <li><a><span>blaaah!</span></a></li>
        </ul>
      </div>
    </div>
    <footer>Algemene voorwaarden  &nbsp;|&nbsp; Privacy &nbsp;|&nbsp; &copy; {{year}} Autotaal Automatisering B.V.</footer>
  </div>
</div>
