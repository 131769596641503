import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ItemInfoWarnings } from 'app/_models/item-info/item.info.warnings';
import { AdminService } from 'app/_services/admin/admin.service';
import { ApiService } from 'app/_services/api.service';

export const itemInfoWarningsResolver: ResolveFn<ItemInfoWarnings> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  adminService: AdminService = inject(AdminService),
  apiService: ApiService = inject(ApiService)
) => {
  return apiService.adminGetItemInfoWarnings(adminService.currentUid);
};
