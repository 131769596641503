<app-content-list-item [item]="part" [odd]="odd">
  <ng-container col-1>
    <img *ngIf="catalogService.partHasImage(ctx, part)" class="part-image grow" [src]="catalogService.partGetImage(ctx, part)"
      (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Details)" />
    <div class="row mt-3 px-2">
      <div class="col">
        <button *ngIf="part.SupportsGetCarTypesByPart" type="button" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Application)">
          <i class="fas fa-car pointer"></i></button>
        <button type="button" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showPartDetailedPopup([part], ctx, catalogPartPopupInfoKind.Details)">
          <i class="fas fa-info"></i></button>
        <button *ngIf="part.HasSubParts" class="btn btn-info btn-xs icon-button"
          (click)="catalogService.showSubParts(ctx.CarType, ctx.Category, part)" tooltip="Toebehoren">
          <i class="fas fa-plus"></i>
        </button>
        <app-catalog-part-document-buttons [part]="part" [button]="true"></app-catalog-part-document-buttons>
      </div>
    </div>
  </ng-container>
  <ng-container col-2>
    <app-part-list-item-info [part]="part" [partsProperties]="ctx.PartsProperties"
      [partsPropertyTranslations]="ctx.PartsPropertyTranslations" [shopKind]="ctx.ShopKind"
      [partsPropertyCollapsedItemCount]="ctx.PartsPropertyCollapsedItemCount"></app-part-list-item-info>
    <app-debug-sort-options *ngIf="hotkeyService.debugMode" [item]="part"
      [sortOption]="mainService.getActiveSort(ctx?.SortOptions)" [alternateDataKey]="'UniqueID'"
      [alternateData]="ctx.PartsCartItems"></app-debug-sort-options>
  </ng-container>
  <ng-container col-3>
    <div class="row p-1">
      <div class="col part-item-info">
        <!-- *{{part['getEquality_value']}}* -->
        <app-item-info-template [busy]="ctx['busy']" [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)"
          [nettoprijs]="ctx.Nettoprijs" [part]="part"></app-item-info-template>
      </div>
    </div>
    <div class="row p-1 my-3">
      <div class="col pb-1">
        <div class="row mt-2">
          <div class="col">
            <app-item-count-wide [right]="true" [sci]="catalogService.getCartItemForPart(ctx.PartsCartItems, part)">
            </app-item-count-wide>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-list-item>