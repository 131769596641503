import { Component, OnInit } from '@angular/core';
import { ShopSoort } from '../../_models/common/shop.soort';
import { ContextCatalog } from '../../_models/catalog/context.catalog';
import { ShopService } from '../../_services/shop.service';
import { SettingsService } from '../../_services/settings.service';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  shopKind = ShopSoort;
  ctx: ContextCatalog;

  constructor(
    public shopService: ShopService,
    public mainService: MainService,
    public settingsService: SettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.ctx = data.ctx;
      });
  }


}
