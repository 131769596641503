import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { ViewKindInfo } from '../../../../_models/common/view.kind.info';
import { ViewKind } from '../../../../_models/common/view.kind';
import { MainService } from '../../../../_services/main.service';
import { CatalogService } from '../../../../_services/catalog.service';
import { Router } from '@angular/router';
import { ResizeInfo } from '../../../../_models/common/resize.info';
import { SettingsService } from '../../../../_services/settings.service';


@Component({
  selector: 'app-catalog-parts-boxed-layout',
  templateUrl: './catalog-parts-boxed-layout.component.html',
  styleUrls: ['./catalog-parts-boxed-layout.component.scss']
})
export class CatalogPartsBoxedLayoutComponent implements OnInit, OnDestroy {
  @Input() ctx: ContextCatalog;
  viewKinds: ViewKindInfo[] = [
    { kind: ViewKind.Detail, icon: 'fas fa-th-large', info: 'Ruime weergave' },
    { kind: ViewKind.Compact, icon: 'fas fa-th', info: 'Compacte weergave' }
  ];
  resizeInfosSubscription: Subscription;
  private containerHeight = '0';
  private itemContainer: ElementRef;
  @ViewChild('itemContainer') set content(content: ElementRef) {
    this.itemContainer = content;
    this.resizeInfosSubscription = this.mainService.resizeInfos$.subscribe(info => this.handleResizeInfo(info));
  }

  constructor(
    public settingsService: SettingsService,
    public mainService: MainService,
    public catalogService: CatalogService,
    private router: Router
  ) { }

  ngOnInit() {
    this.mainService.fireReziseEvent();
  }

  ngOnDestroy() {
    if (this.resizeInfosSubscription) { this.resizeInfosSubscription.unsubscribe(); }
  }

  getContainerHeightStyle() {
    if (this.containerHeight) {
      return {
        'max-height': this.containerHeight,
        'overflow': 'hidden',
        'overflow-y': 'auto'
      };
    }
    return null;
  }

  handleResizeInfo(info: ResizeInfo) {
    if (this.itemContainer) {
      this.containerHeight = (info.clientHeight - info.headerHeight - this.itemContainer.nativeElement.offsetTop + 105) + 'px';
    }
  }

  gotoGroups() {
    this.router.navigate(['/catalog/groups', this.ctx.CarType.Id]);
  }

}
