import { Component, OnInit, HostListener, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { RouterOutlet } from '@angular/router';

import { AdminService } from '../_services/admin/admin.service';
import { ContextAdmin } from '../_models/admin/context.admin';
import { MenuItem } from '../_models/admin/menu.item';
import { AuthService } from '../_services/auth.service';
import { MainService } from '../_services/main.service';
import { ResizeInfo } from '../_models/common/resize.info';
import { RouteAnimations } from '../_animations/route.animations';
import { WholesalerModel } from '../_models/common/wholesaler.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: RouteAnimations
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
  ctx: ContextAdmin;
  activeMenuId: number;
  search: string;
  searchRegex: RegExp;
  year: number = new Date().getFullYear();
  intervalSubscription: Subscription;
  wholesalerChange: EventEmitter<WholesalerModel> = new EventEmitter<WholesalerModel>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mainService.onResize(new ResizeInfo(document.body.clientWidth, document.body.clientHeight, 0, 0, 0));
  }

  constructor(
    public mainService: MainService,
    public adminService: AdminService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.adminService.getContext(true)
      .subscribe((ctx: ContextAdmin) => {
        this.ctx = ctx;
      });

    this.intervalSubscription = interval(60 * 1000)
      .subscribe(() => this.adminService.updateOnlineSessionCount());

    this.wholesalerChange
      .subscribe(wholesaler=>this.adminService.setCurrentWholesaler(wholesaler));
  }

  ngAfterViewInit() {
    // wait a tick to avoid one-time devMode
    // unidirectional-data-flow-violation error
    const self = this;
    setTimeout(_ => {
      self.mainService.onResize(new ResizeInfo(document.body.clientWidth, document.body.clientHeight, 0, 0, 0));
    }, 500);
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  prepareRoute(outlet: RouterOutlet) {
    // return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  clickMenu(menuItem: MenuItem): void {
    if (this.activeMenuId === menuItem.ID) {
      this.activeMenuId = 0;
    } else {
      this.activeMenuId = menuItem.ID;
    }
  }

  searchChanged(search: string) {
    if (search) {
      this.searchRegex = new RegExp(search, 'i');
    } else {
      this.searchRegex = null;
    }
  }

}
