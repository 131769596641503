import { Component, OnInit } from '@angular/core';

import { MainService } from '../../../_services/main.service';


@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss']
})
export class OpenOrdersComponent implements OnInit {

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
