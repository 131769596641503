import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextBase } from '../../../_models/common/context.base';
import { ApiService } from '../../../_services/api.service';
import { Settings } from '../../../_models/common/settings';
import { ViewKind } from '../../../_models/common/view.kind';
import { ViewKindInfo } from '../../../_models/common/view.kind.info';

@Component({
  selector: 'app-voorraad-prijs-display-opties',
  templateUrl: './voorraad-prijs-display-opties.component.html',
  styleUrls: ['./voorraad-prijs-display-opties.component.scss']
})
export class VoorraadPrijsDisplayOptiesComponent implements OnInit {
  @Input() ctx: ContextBase;
  @Input() viewKinds: ViewKindInfo[];
  @Input() disableDisplayOptions: boolean;
  @Output() viewKindChanged: EventEmitter<ViewKind> = new EventEmitter();
  @Output() stockOptionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() netPriceOptionChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
  }

  activeViewKindCount(): number {
    if (!this.viewKinds) { return 0; }
    return this.viewKinds.length;
  }

  onViewKindChanged(event: ViewKind) {
    this.viewKindChanged.emit(event);
  }

  onStockOptionChanged(event: boolean) {
    this.stockOptionChanged.emit(event);
  }

  onNetPriceOptionChanged(event: boolean) {
    this.netPriceOptionChanged.emit(event);
  }

}
