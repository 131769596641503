import { Component, OnInit, Input } from '@angular/core';
import { ItemInfoService } from '../../../_services/item-info.service';
import { ShopSoort } from '../../../_models/common/shop.soort';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-part-item-number-link',
  templateUrl: './part-item-number-link.component.html',
  styleUrls: ['./part-item-number-link.component.scss']
})
export class PartItemNumberLinkComponent implements OnInit {
  @Input() partItemNumber: string;
  @Input() shopKind: ShopSoort = ShopSoort.ONBEKEND;
  @Input() description: string = null;
  shopSoort = ShopSoort;

  constructor(
    public itemInfoService: ItemInfoService,
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
