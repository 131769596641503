import { Component, OnInit, Input } from '@angular/core';

import { ContextCatalog } from '../../../../_models/catalog/context.catalog';
import { MaintenancePartsSelection } from '../../../../_models/maintenance/maintenance.parts.selection';

@Component({
  selector: 'app-maintenance-parts-scroll-layout',
  templateUrl: './maintenance-parts-scroll-layout.component.html',
  styleUrls: ['./maintenance-parts-scroll-layout.component.scss']
})
export class MaintenancePartsScrollLayoutComponent implements OnInit {
@Input() ctx: ContextCatalog;
@Input() selection: MaintenancePartsSelection;

  constructor() { }

  ngOnInit() {
  }

}
