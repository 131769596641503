<div class="container">

  <app-breadcrumb-header [debugObject]="request" [autoStart]="true">
    <li appBreadcrumbHeaderItem [active]="true" [addShopModule]="18"></li>
  </app-breadcrumb-header>

  <div class="card border mb-3">

    <div class="card-header card-header-compact  text-bg-dark">Kentekenplaten bestellen</div>

    <div class="card-body p-2">

      <div class="form-group row">
        <label class="col-md-6 col-form-label">Soort</label>
        <div class="col-md-6">
          <select class="form-control form-control-sm" [compareWith]="compareType"
            [(ngModel)]="request.LicensePlateType">
            <option [ngValue]="undefined" disabled=selected>Selecteer een soort</option>
            <option *ngFor="let item of licensePlateService.licensePlateTypes; let i = index" [ngValue]="item">
              {{item.Description}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label">Kenteken of tekst</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.LicensePlateNumber"
            maxlength="10" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Reden aanschaf</label>
        <div class="col-md-6">
          <select class="form-control form-control-sm" [compareWith]="compareReason" [(ngModel)]="request.Reason"
            (change)="reasonChanged()">
            <option [ngValue]="undefined" disabled=selected>Selecteer een reden</option>
            <option *ngFor="let item of licensePlateService.reasons; let i = index" [ngValue]="item">
              {{item.Description}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Duplicaatcode</label>
        <div class="col-md-6">
          <select class="form-control form-control-sm" [(ngModel)]="request.DuplicateCode">
            <option *ngFor="let item of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" [ngValue]="item">{{item}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label">Aantal</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.Amount" maxlength="2" />
        </div>
      </div>
      <!-- <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">RDW bedrijven pasnr.</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.RdwCompaniesCardNumber"
            maxlength="20" />
        </div>
      </div> -->
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Rijbewijs/identiteitsnr.</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.IdentificationNumber"
            maxlength="50" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Meldcode</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.LastNumbersVIN" maxlength="4" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Lamineercode(s)</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.LaminateCodes"
            maxlength="45" /><br>
          Bij meerdere lamineercodes deze scheiden door een komma te plaatsen.
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Kentekencard documentnr</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.KentekencardNumber"
            maxlength="10" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">RDW Bedrijfsvoorraadpas bedrijfsnr</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.RdwCompanyNumber"
            maxlength="25" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">RDW Bedrijfsvoorraadpas pasnr</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.RdwCompanyCardNumber"
            maxlength="25" />
        </div>
      </div>
      <div class="form-group row" [hidden]="isTrailerPlateSelected()">
        <label class="col-md-6 col-form-label">Plaatafgiftecode</label>
        <div class="col-md-6">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="request.PlateReleaseCode"
            maxlength="6" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" (click)="orderLicensePlate()">Bestellen</button>
        </div>
      </div>



    </div>

  </div>


</div>
