<div class="modal-body">
  <div *ngIf="log" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 180) + 'px', 'overflow': 'auto'}" class="log">
    <div *ngFor="let item of log.LogItems" [ngStyle]="{'padding-left.px': item.Indent*30}">
      <span [innerHtml]="item.Text | safeHtml"></span><br />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-info" (click)="bsModalRef.hide()">Annuleren</button>
</div>
