import { Component, OnInit } from '@angular/core';
import { GisOutstandingItemRequest } from '../../../_models/customer-information/gis.outstanding.item.request';
import { GisOutstandingItem } from '../../../_models/customer-information/gis.outstanding.item';
import { MainService } from '../../../_services/main.service';
import { SortService } from '../../../_services/sort.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerInformationService } from '../../../_services/customer-information.service';
import { GisOutstandingItemRequestType } from '../../../_models/customer-information/gis.outstanding.item.request.type';
import { OutstandingBill } from '../../../_models/bills/outstanding.bill';

@Component({
  selector: 'app-outstanding-items-webshop',
  templateUrl: './outstanding-items-webshop.component.html',
  styleUrls: ['./outstanding-items-webshop.component.scss']
})
export class OutstandingItemsWebshopComponent implements OnInit {

  request: GisOutstandingItemRequest;
  headers: OutstandingBill[];

  totaalFactuurbedrag: number;
  totaalBetaald: number;
  totaalNogTeBetalen: number;
  totaalNietVervallen: number;
  totaalVervallen: number;

  isBusy = false;

  constructor(
    public mainService: MainService,
    public sortService: SortService,
    public authService: AuthService,
    public customerInformationService: CustomerInformationService
  ) { }

  ngOnInit() {
    this.request = new GisOutstandingItemRequest();
    this.request.RequestType = GisOutstandingItemRequestType.AllOutstandingBills;
    this.sortService.initSortField('outstandingitems', 'InvoiceDate', true);
    this.getBills();
  }

  getBills() {
    this.isBusy = true;
    this.customerInformationService.showPasswordPrompt = false;
    this.customerInformationService.getOutstandingBillsV2(this.request.RequestType)
      .subscribe(headers => {
        this.isBusy = false;
        this.headers = headers;
        this.berekenTotalen();
        // this.mainService.fireReziseEvent();
      }, error => {
        this.isBusy = false;
        if (error.status === 401) { this.customerInformationService.showPasswordPrompt = true; }
        console.error(error);
      });
  }

  berekenTotalen() {
    const isNull = (item) => item ? item : 0;
    const doSum = (sum, current) => sum + current;

    this.totaalFactuurbedrag = this.headers.map(h => isNull(h.AmountTotal)).reduce(doSum, 0);
    this.totaalBetaald = this.headers.map(h => isNull(h.AmountPaid)).reduce(doSum, 0);
    this.totaalNogTeBetalen = this.headers.map(h => isNull(h.AmountOutstanding)).reduce(doSum, 0);
    this.totaalNietVervallen = this.headers.filter(h => !h.IsDue).map(h => isNull(h.AmountOutstanding)).reduce(doSum, 0);
    this.totaalVervallen = this.headers.filter(h => h.IsDue).map(h => isNull(h.AmountOutstanding)).reduce(doSum, 0);
  }

  setRequestType(requestType: GisOutstandingItemRequestType): void {
    this.request.RequestType = requestType;
    this.getBills();
  }
}
