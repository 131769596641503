import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { AdminService } from '../../../_services/admin/admin.service';
import { SupplierModel } from '../../../_models/common/supplier.model';

@Component({
  selector: 'app-admin-typeahead-supplier-search',
  templateUrl: './admin-typeahead-supplier-search.component.html',
  styleUrls: ['./admin-typeahead-supplier-search.component.scss']
})
export class AdminTypeaheadSupplierSearchComponent implements OnInit {
  @Input() label: string;
  @Input() centerLabel: boolean = false;
  @Input() start: string;
  @Output() startChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() wholesaler: number;
  @Output() selected: EventEmitter<SupplierModel> = new EventEmitter<SupplierModel>();
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() emptyAfterSelected: boolean;
  uniqueId = Math.random().toString(36).substring(2);

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (this.labelCols!==0 && !this.labelCols) { this.labelCols = 2; }
  }

  typeaheadOnSelect(match: TypeaheadMatch) {
    this.selected.emit(match.item);
    if (this.emptyAfterSelected) {
      setTimeout(() => this.start = '', 10);
    }
  }

  onBlur(event: FocusEvent) {
    setTimeout(() => this.blur.emit(this.start), 500);
  }

}
