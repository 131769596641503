<div class="header p-3">
  <div class="row">
    <div class="col" (contextmenu)="mainService.showObject($event, carModel)"><b>{{getTitle()}}</b></div>
    <div class="col">
      <div class="input-group float-end">
        <input type="text" [appAutofocus]="true" class="form-control" [(ngModel)]="filterString" placeholder="filter op model">
      </div>
    </div>
  </div>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  <table class="table table-striped table-hover table-sm">
    <tr *ngFor="let model of carModel.CatalogCarModels[catalog] | carModelFilter: filterString" (click)="choose(model)">
      <td>{{model.ModelName}}</td>
      <td>{{model.ModelRemark}}</td>
      <td class="text-nowrap">{{model.AvailableFrom.Display}}</td>
      <td class="text-nowrap">{{model.AvailableUntil.Display}}</td>
    </tr>
  </table>
</div>
<div class="footer p-3">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-warning footer-button" (click)="choose(null)">Terug</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-warning footer-button float-end" (click)="cancel()">Annuleren</button>
    </div>
  </div>
</div>
