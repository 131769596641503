import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-extra-message-item-group',
  templateUrl: './extra-message-item-group.component.html',
  styleUrls: ['./extra-message-item-group.component.scss']
})
export class ExtraMessageItemGroupComponent implements OnInit {
  @Input() enabled: boolean;
  @Output() enabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() itemGroup: number;
  @Output() itemGroupChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() message: string;
  @Output() messageChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changeEnabled() {
    this.enabled=!this.enabled;
    this.enabledChange.emit(this.enabled);
  }

}
