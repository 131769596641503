import { UniversalCarType } from '../car-types/universal.car.type';
import { ShopSoort } from '../common/shop.soort';

export class RequestContextNhsShop {
  ShopKind: ShopSoort;
  CarType: UniversalCarType;
  Url: string;
  Domain: string;
  Ticket: string;
  FrontendUrl: string;

  constructor(shopKind: ShopSoort, carType: UniversalCarType, url: string, domain: string, ticket: string, frontendUrl: string) {
    this.ShopKind = shopKind;
    this.CarType = carType;
    this.Url = url;
    this.Domain = domain;
    this.Ticket = ticket;
    this.FrontendUrl = frontendUrl;
  }
}
