import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UploadEvent } from '../../../../_common/upload/upload-event.model';
import { UploadFile } from '../../../../_common/upload/upload-file.model';
import { ItemImageItemGroup } from '../../../../_models/admin/item-images/item.image.item.group';
import { ItemImageLink } from '../../../../_models/admin/item-images/item.image.link';
import { ItemGroup } from '../../../../_models/common/item.group';
import { TypeaheadItem } from '../../../../_models/typeahead/typeahead.item';
import { AdminItemImagesService } from '../../../../_services/admin/admin-item-images.service';
import { MainService } from '../../../../_services/main.service';

@Component({
  selector: 'app-admin-item-group-images-edit',
  templateUrl: './admin-item-group-images-edit.component.html',
  styleUrls: ['./admin-item-group-images-edit.component.scss']
})
export class AdminItemGroupImagesEditComponent implements OnInit {
  item: ItemImageItemGroup;
  itemGroup: ItemGroup;
  uploadPercent: number;
  focusInput = new EventEmitter<boolean>();

  constructor(
    public mainService: MainService,
    public adminItemImagesService: AdminItemImagesService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.item = data.item;
        this.itemGroup = new ItemGroup();
        this.itemGroup.ItemGroupId = data.item.ItemGroupNumber;
        this.itemGroup.Description = data.item.ItemGroupDescription;
      });
  }

  itemSelected(itemGroup: ItemGroup) {
    this.item.ItemGroupNumber = itemGroup.ItemGroupId;
    this.item.ItemGroupDescription = itemGroup.Description;
  }

  upload(event: UploadEvent) {
    if (this.item.ItemGroupNumber === 0) {
      this.mainService.msgBox('Let op!', 'Er moet nog een artikelgroep gekozen worden!')
        .subscribe(() => this.focusInput.emit());
    } else {
      const fileList: UploadFile[] = [];
      for (const droppedFile of event.files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          fileList.push(droppedFile);
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.info(droppedFile.relativePath, fileEntry);
        }
      }
      this.adminItemImagesService.addItemImagesItemGroup(this.item.ItemGroupNumber, fileList)
        .subscribe(progress => {
          if (progress.type === HttpEventType.UploadProgress) {
            this.uploadPercent = Math.round(100 * progress.loaded / progress.total);
          } else if (progress.type === HttpEventType.Response) {
            const updatedItem = progress.body as ItemImageItemGroup;
            this.item = updatedItem;
          }
        });
    }
  }

  addImageUrl(event: MouseEvent) {
    this.mainService.inputBoxExtended('Afbeelding toevoegen via url', 'Plak hier de url van de afbeelding', '', false, 'modal-xl')
      .subscribe(url => {
        if (url) {
          var image = new ItemImageLink();
          image.InternalItemNumber = this.item.ItemGroupNumber;
          image.ImageUrl = url;
          this.adminItemImagesService.addItemImageItemGroup(image)
            .subscribe(result => {
              if (result) this.item = result;
            });
        }
      });
  }

  save(item: ItemImageItemGroup): Observable<ItemImageItemGroup> {
    return this.adminItemImagesService.saveItemImageItemGroup(item)
      .pipe(mergeMap(newItem => {
        if (newItem) {
          this.router.navigate(['/admin/management/item-group-images']);
          return of(newItem);
        }
      }));
  }

  cancel() {
    if (this.item.ItemGroupNumber && (!this.item.Images || !this.item.Images.length)) {
      this.adminItemImagesService.deleteItemImagesItemNumber(this.item.ItemGroupNumber)
        .subscribe(ok => {
          if (ok) { this.router.navigate(['/admin/management/item-group-images']); }
        });
    } else {
      this.router.navigate(['/admin/management/item-group-images']);
    }
  }

}
