<div class="row">
  <div *ngIf="!isLinked(); else linked" class="col-10 small">
    <div *ngIf="interfaceDatas.length>1 || interfaceData.Branch>0" class="row">
      <div class="col-3"><strong>Filiaal</strong></div>
      <div class="col-9">
        {{adminSupplierInterfaceService.getBranch(supplierInterfaceData.Branches, interfaceData.Branch).BranchName}}
      </div>
    </div>
    <div *ngIf="interfaceData.Url" class="row">
      <div class="col-3"><strong>Url</strong></div>
      <div class="col-9">{{interfaceData.Url}}</div>
    </div>
    <div *ngIf="interfaceData.Username" class="row">
      <div class="col-3"><strong>Username</strong></div>
      <div class="col-9">{{interfaceData.Username}}</div>
    </div>
    <div *ngIf="interfaceData.Password" class="row">
      <div class="col-3"><strong>Password</strong></div>
      <div class="col-9">{{interfaceData.Password}}</div>
    </div>
    <div *ngIf="interfaceData.CustomerCode" class="row">
      <div class="col-3"><strong>Klantcode</strong></div>
      <div class="col-9">{{interfaceData.CustomerCode}}</div>
    </div>
    <div *ngIf="interfaceData.SupplierCode" class="row">
      <div class="col-3"><strong>Leverancierscode</strong></div>
      <div class="col-9">{{interfaceData.SupplierCode}}</div>
    </div>

  </div>
  <ng-template #linked>
    <div class="col-10 small">
      <b>Linked:</b><span *ngFor="let linkedInfo of getLinked()" class="badge text-bg-info ms-1">{{linkedInfo.Name}}</span>
    </div>
  </ng-template>

  <div class="col-2 actions text-end">
    <app-switch [model]="interfaceData.IntType > 0" (modelChange)="switch(interfaceData)"
      [disabled]="!adminSupplierInterfaceService.isDisabledUnique(supplierInterfaceData.InterfaceData,interfaceData)"></app-switch>
    <button class="btn btn-xs btn-outline-secondary w-100"
      (click)="adminSupplierInterfaceService.editInterfaceData(supplierInterfaceData, interfaceData,interfaceDataChanged)"><i
        class="fas fa-edit"></i> Wijzig</button>
    <button class="btn btn-xs btn-outline-secondary w-100"
      (click)="adminSupplierInterfaceService.deleteInterfaceData(supplierInterfaceData, interfaceData, interfaceDataChanged)"><i
        class="fas fa-trash-alt"></i> Verwijder</button>
  </div>
</div>