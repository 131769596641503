import { animate, animateChild, animation, group, keyframes, query, style, transition, trigger, useAnimation } from '@angular/animations';

export const RouteAnimations = [
  trigger('routeAnimations', [
    transition('HomePage <=> AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('900ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('900ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> FilterPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('600ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('900ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]),


  trigger('myAnimation', [
    transition('* => *', [
      query(
        ':enter',
        [style({ opacity: 0 })],
        { optional: true }
      ),
      query(
        ':enter', [
          style({ visibility: 'hidden' }),
          group([
            useAnimation(animation([
              animate('900ms 0ms', keyframes([
                style({ visibility: 'visible', opacity: 0, transform: 'translate3d({200px, 0, 0)', easing: 'ease', offset: 0 }),
                style({ opacity: 1, transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 1 })
              ]))])
            )])
        ])
    ])
  ]),

  trigger('routerTransition', [
    transition('* <=> *', [
      /* order */
      /* 1 */ query(':enter, :leave', style({ position: 'fixed' }),
          { optional: true }),
      /* 3 */ group([ // block executes in parellel
          query(':enter', [
              style({ opacity: 0 }),
              animate('300ms ease-in-out', style({ opacity: 1 }))
          ], { optional: true }),
          query(':leave', [
              style({ opacity: 1 }),
              animate('300ms ease-in-out', style({ opacity: 0 }))
          ], { optional: true })
      ])
    ])
  ])
];
