import { Component, OnInit, Input } from '@angular/core';
import { ShopModule } from '../../_models/common/shop.module';
import { MainService } from '../../_services/main.service';
import { ShopService } from '../../_services/shop.service';
import { ContextMain } from '../../_models/common/context.main';
import { ShopSoort } from '../../_models/common/shop.soort';

@Component({
  selector: 'app-tab-strip',
  templateUrl: './tab-strip.component.html',
  styleUrls: ['./tab-strip.component.scss']
})
export class TabStripComponent implements OnInit {
  @Input() ctx: ContextMain;

  constructor(
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
  }

}
