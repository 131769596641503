import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsBase } from '../../../../_models/admin/settings/settings.base';
import { AdminSettingsService } from '../../../../_services/admin/admin-settings.service';

@Component({
  selector: 'app-admin-settings-navigation',
  templateUrl: './admin-settings-navigation.component.html',
  styleUrls: ['./admin-settings-navigation.component.scss']
})
export class AdminSettingsNavigationComponent implements OnInit {
  settings: SettingsBase;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

}
