import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../_services/settings.service';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {

  constructor(
    public mainService: MainService,
    public settingsService:SettingsService
    ) { }

  ngOnInit(): void {
  }

}
