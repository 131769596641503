import { Component, OnInit } from '@angular/core';
import { ArchiveOrdersResponse } from '../../../_models/orders/archive.orders.response';
import { ArchiveOrdersRequest } from '../../../_models/orders/archive.orders.request';
import { AdminService } from '../../../_services/admin/admin.service';
import { ApiService } from '../../../_services/api.service';
import { ArchiveOrder } from '../../../_models/orders/archive.order';
import { CustomerModel } from '../../../_models/common/customer.model';
import { ArchiveOrderKindEnum } from '../../../_models/orders/archive.order.kind.enum';
import { OrderTypeEnum } from '../../../_models/orders/order.type.enum';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-admin-order-search',
  templateUrl: './admin-order-search.component.html',
  styleUrls: ['./admin-order-search.component.scss']
})
export class AdminOrderSearchComponent implements OnInit {
  request = new ArchiveOrdersRequest();
  response: ArchiveOrdersResponse;
  busy = false;
  archiveOrderKindTypes: string[];
  archiveOrderKindFilter: string = null;
  orderStatus: string[];
  orderStatusFilter: string = null;
  filteredOrders: ArchiveOrder[];

  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
    this.request.Wholesaler = this.adminService.currentUid.Wholesaler;
    this.request.Customer = 0;
    this.request.InternalItemNumber = 0;
    this.request.DateTimeFrom = new Date(new Date().setHours(0, 0, 0, 0));
    this.request.DateTimeTo = new Date(this.request.DateTimeFrom);
    this.request.DateTimeTo.setDate(this.request.DateTimeFrom.getDate() + 1);
  }

  search() {
    this.busy = true;
    this.apiService.adminSearchOrders(this.adminService.currentUid, this.request)
      .subscribe(result => {
        this.busy = false;
        this.response = result;
        this.archiveOrderKindTypes = [...Array.from(new Set(this.response.Orders.map(order => `${order.OrderKind},${(order.OrderKind < 0 ? order.OrderType : 9)}`)))];
        this.orderStatus = [...Array.from(new Set(Object.values(this.response.OrderStatus)))];
        this.filterOrders();
      });
  }

  filterOrders() {
    this.filteredOrders = this.response.Orders.filter(o => ((this.archiveOrderKindFilter == null || this.isOrderKindTypeEqual(o, this.archiveOrderKindFilter))
      && (this.orderStatusFilter == null || this.response.OrderStatus[o.OrderNumber] == this.orderStatusFilter)));
  }

  isOrderKindTypeEqual(order: ArchiveOrder, kind: string): boolean {
    const archiveKind = this.getArchiveOrderKind(kind);
    if (archiveKind !== ArchiveOrderKindEnum.Unknown) return order.OrderKind === archiveKind;
    return `${order.OrderKind},${order.OrderType}` == kind;
  }

  getArchiveOrderKind(kind: string): ArchiveOrderKindEnum {
    const data = kind.split(',');
    if (data.length === 2) return +data[0];
    return ArchiveOrderKindEnum.Unknown;
  }

  getOrderType(kind: string): OrderTypeEnum {
    const data = kind.split(',');
    if (data.length === 2) return +data[1];
    return OrderTypeEnum.Extern;
  }

  setArchiveOrderKindFilter(kind: string) {
    this.archiveOrderKindFilter = kind;
    this.filterOrders();
  }

  setOrderStatusFilter(status: string) {
    this.orderStatusFilter = status;
    this.filterOrders();
  }

  getCustomer(order: ArchiveOrder): CustomerModel {
    if (this.response?.Customers[order.Customer]) {
      return this.response.Customers[order.Customer];
    }
    return new CustomerModel();
  }

}
