import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MainService } from '../../_services/main.service';
import { CarTypeResult } from '../../_models/car-types/car.type.result';
import { CatalogPropertyService } from '../../_services/catalog-property.service';
import { PropertyKindCatalog } from '../../_models/catalog/property.kind.catalog';
import { PartsProperty } from '../../_models/catalog/parts.property';
import { CarType } from '../../_models/car-types/car.type';
import { CarTypeService } from 'app/_services/car-type.service';


@Component({
  selector: 'app-car-type-list',
  templateUrl: './car-type-list.component.html',
  styleUrls: ['./car-type-list.component.scss']
})
export class CarTypeListComponent implements OnInit {
  carTypeResult: CarTypeResult;
  catalog: string;
  filterString: string;
  propertyDiffs: PropertyKindCatalog[];
  onClose: Subject<CarTypeResult>;
  sameBrandName: boolean;
  sameModelName: boolean;
  sameModelRemark: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    public mainService: MainService,
    public carTypeService: CarTypeService,
    public catalogPropertyService: CatalogPropertyService
  ) { }

  ngOnInit() {
    this.onClose = new Subject<CarTypeResult>();
    if (this.carTypeResult && this.carTypeResult.CarType) {
      const cartype = this.carTypeResult.CarType;
      // if (this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationBrand)) {
      const propertyArray = cartype.CatalogCarTypes[this.catalog].map(p => p.Properties);
      this.propertyDiffs = this.catalogPropertyService.getPropertyDiffs(propertyArray);
      // }
      this.sameBrandName = this.carTypeService.sameBrandName(this.carTypeResult.CarType.CatalogCarTypes[this.catalog]);
      this.sameModelName = this.carTypeService.sameModelName(this.carTypeResult.CarType.CatalogCarTypes[this.catalog]);
      this.sameModelRemark = this.carTypeService.sameModelRemark(this.carTypeResult.CarType.CatalogCarTypes[this.catalog]);
    }
  }

  getTitle() {
    if (this.carTypeResult && this.carTypeResult.CarType) {
      const cartype = this.carTypeResult.CarType;
      if (this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationBrand)) {
        let text = `Kies een type ${this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationBrand)}`;
        if (this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationModel)
          && this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationModel)
          !== this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationBrand)) {
          text += ' ' + this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationModel);
        }
        if (this.catalogPropertyService.hasProperty(cartype.Properties, PropertyKindCatalog.RegistrationDescription)
          && this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationDescription)
          !== this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationModel)) {
          text += ' ' + this.catalogPropertyService.getPropertyValueString(cartype.Properties, PropertyKindCatalog.RegistrationDescription);
        }
        return text;
      } else if (cartype.Model?.Brand?.BrandName) {
        return `Kies een type ${this.sameBrandName ? cartype.Model.Brand.BrandName : ''} ${this.sameModelName ? cartype.Model.ModelName : ''} ${this.sameModelRemark ? cartype.Model.ModelRemark : ''}.`;
      }
    }
    return 'Kies een type.';
  }

  getColumnCount(): number {
    let count = 5;
    if (this.sameBrandName) count--;
    if (this.sameModelName) count--;
    if (this.sameModelRemark) count--;
    return count;
  }

  hasAnyCarTypeAlternatives(carTypes: CarType[]) {
    let alternative = false;
    carTypes.forEach(carType => {
      if (carType["Alternatives"]) alternative = true;
    });
    return alternative;
  }

  choose(item) {
    if (!item?.Alternatives) {
      if (item) {
        this.carTypeResult.CarType.CatalogCarTypes[this.catalog] = [item];
        this.onClose.next(this.carTypeResult);
      } else {
        this.onClose.next(null);
      }
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }


}
