import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-helper-display-breakpoint',
  templateUrl: './helper-display-breakpoint.component.html',
  styleUrl: './helper-display-breakpoint.component.scss'
})
export class HelperDisplayBreakpointComponent implements OnInit {

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
