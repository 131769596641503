<app-admin-typeahead-item-search *ngIf="searchingItem" [labelCols]="labelCols" [dividerKind]="dividerKind" [label]="label"
[wholesaler]="adminService.currentUid.Wholesaler" [start]="model.ItemNumber" [focusInput]="focusInput" [searchInDescription]="searchInDescription" (selected)="typeaheadItemSelected($event)">
    <span class="input-group-text" id="basic-addon">
      <i class="fas fa-search"></i>
    </span>
</app-admin-typeahead-item-search>
<app-admin-custom-control *ngIf="!searchingItem" [labelCols]="labelCols" [dividerKind]="dividerKind" [label]="label">
  <label class="col-form-label pointer" (click)="enableOnTypeaheadItemSearch();"
    tooltip="Klik op het artikel om het te wijzigen" placement="top" container="body">
    <b>{{model.ItemNumber}}</b> <span class="ms-3 text-low">({{model.ItemGroup}})</span><br />
    {{model.Description}}
  </label>
</app-admin-custom-control>