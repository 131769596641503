import { Pipe, PipeTransform } from '@angular/core';
import { PartsCategory } from '../../_models/catalog/parts.category';

@Pipe({
  name: 'partsCategoryFilter'
})
export class PartsCategoryFilterPipe implements PipeTransform {

  containsFilter(text, filter) {
    return filter && text && text.toLowerCase().indexOf(filter) >= 0;
  }

  transform(categories: PartsCategory[], filterString: string): PartsCategory[] {
    if (!filterString) { return categories; }
    return categories.filter(cat => {
      let ok = false;
      if (cat) {
        if (this.containsFilter(cat.CategoryName, filterString)) { ok = true; }
        if (cat.PartsDescriptions) {
          for (const c of cat.PartsDescriptions) {
            if (this.containsFilter(c, filterString)) { ok = true; }
          }
        }
      }
      return ok;
    });


  }

}
