<app-admin-form [title]="'Aanbiedingen instellingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event">
  <app-admin-checkbox-input [label]="'Koptekst boven de aanbiedingen'"
    [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'KopTekstAanbiedingenEnabled').Value"
    [(modelInput)]="adminSettingsService.getAdminSetting(settings,'KopTekstAanbiedingen').Value"
    [placeholder]="'Tekst voor boven de aanbiedingen...'"></app-admin-checkbox-input>
  <app-admin-checkbox-input [label]="'Voettekst onder de aanbiedingen'"
    [(modelEnabled)]="adminSettingsService.getAdminSetting(settings,'VoetTekstAanbiedingenEnabled').Value"
    [(modelInput)]="adminSettingsService.getAdminSetting(settings,'VoetTekstAanbiedingen').Value"
    [placeholder]="'Tekst voor onder de aanbiedingen...'"></app-admin-checkbox-input>
  <app-admin-dropdown [label]="'Soort weergave'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'ViewKind_Aanbiedingen').Value" [modelValues]="[0,3]"
    [modelDisplayValues]="{'0':'Naast elkaar','3':'Carousel'}">
  </app-admin-dropdown>
  <app-admin-checkbox [label]="'Verberg module aanbiedingen wanneer er geen enkele aanbieding actief is'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'HidePromotionsWhenNoneActive').Value">
  </app-admin-checkbox>
  <app-admin-editor *ngIf="!adminSettingsService.getAdminSetting(settings,'HidePromotionsWhenNoneActive').Value"
    [label]="'Melding wanneer er geen aanbiedingen actief zijn'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'NotificationPromotionsWhenNoneActive').Value">
  </app-admin-editor>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>