import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-excluparts',
  templateUrl: './footer-excluparts.component.html',
  styleUrls: ['./footer-excluparts.component.scss']
})
export class FooterExclupartsComponent implements OnInit {
  year = 2020;
  constructor() { }

  ngOnInit() {
    const now = new Date();
    this.year = now.getFullYear();
  }

}
