import { AvailabilityCode } from "./availability.code";

export class SupplierResponseItemDepot {
  Supplier: string;
  DepotID: string;
  DepotName: string;
  Quantity: number;
  Availability: AvailabilityCode;
  LastUpdated: Date | null;
  Remark: string;
  ShippingMethod: string;
  OrderBeforeDateTime: Date | null;
  DeliveryDateTime: Date | null;
  ShippingCosts: number;
}