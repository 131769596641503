import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemGroup } from '../../../_models/common/item.group';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-item-group-input',
  templateUrl: './admin-item-group-input.component.html',
  styleUrls: ['./admin-item-group-input.component.scss']
})
export class AdminItemGroupInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelObject: ItemGroup;
  @Output() modelObjectChange: EventEmitter<ItemGroup> = new EventEmitter<ItemGroup>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() stopSearchingOnBlur: boolean;
  @Input() dropup: boolean = false;
  searching: boolean;

  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit() {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (!this.noneSelectionModelValue) { this.noneSelectionModelValue = 0; }
    if (!this.noneSelectionLabel) { this.searching = this.model === this.noneSelectionModelValue; }
    if (this.model !== this.noneSelectionModelValue) {
      this.getObjectModel(this.model);
    }
    if (this.model === 0) { this.doSearch(); }
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModel(model: number) {
    this.adminService.getItemGroup(this.adminService.currentUid.Wholesaler, model)
      .subscribe(result => {
        if (result) {
          if (this.model !== result.ItemGroupId) {
            this.model = result.ItemGroupId;
            this.modelChange.emit(this.model);
          }
          if (!this.modelObject || this.modelObject.ItemGroupId !== result.ItemGroupId) {
            this.modelObject = result;
            this.modelObjectChange.emit(this.modelObject);
          }
        }
        this.searching = false;
      });
  }

  objectChange(value: ItemGroup) {
    if (value.ItemGroupId !== this.model) { this.getObjectModel(value.ItemGroupId); }
  }

  doSearch() {
    this.searching = true;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  clear(event: MouseEvent) {
    this.model = this.noneSelectionModelValue;
    this.modelObject = null;
    this.modelChange.emit(this.model);
    this.modelObjectChange.emit(this.modelObject);
    event.stopPropagation();
  }

  typeaheadBlur(event: string) {
    if (this.stopSearchingOnBlur) {
      setTimeout(() => this.searching = false, 500);
    }
  }

}
