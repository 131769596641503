import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';

import { MainService } from '../../../_services/main.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { PortalStatisticsToday } from '../../../_models/admin/statistics/portal.statistics.today';
import { ChartData, ChartType } from 'chart.js/dist/types/index';
import { PieChart } from '../../../_models/admin/statistics/pie.chart';


@Component({
  selector: 'app-admin-usage-today',
  templateUrl: './admin-usage-today.component.html',
  styleUrls: ['./admin-usage-today.component.scss']
})
export class AdminUsageTodayComponent implements OnInit {
  stats: PortalStatisticsToday;
  intervalSubscription: Subscription;

  constructor(
    public mainService: MainService,
    public adminService: AdminService
  ) { }

  ngOnInit() {
    this.intervalSubscription = interval(30 * 60 * 1000)
      .pipe(
        startWith(0),
        mergeMap(() => this.adminService.getStatisticsToday(25))
      )
      .subscribe((stats: PortalStatisticsToday) => this.stats = stats);
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  public lineChartType: ChartType = 'line';
  public doughnutChartType: ChartType = 'doughnut';

  getDoughnutChartData(chartData: PieChart): ChartData<'doughnut'> {
    return {
      labels: chartData.labels,
      datasets: [
        { data: chartData.data }
      ],
    };
  }



}
