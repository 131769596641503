export class DeliveryChoice {
    ID: number;
    SortOrder: number;
    ChoiceDescription: string;
    ValidFrom: Date;
    ValidThru: Date;
    DeliveryMethod: number;

    constructor() {
        this.ValidFrom = new Date();
        this.ValidThru = new Date();
        this.ValidFrom.setHours(0, 0, 0, 0);
        this.ValidThru.setHours(0, 0, 0, 0);
        this.DeliveryMethod = -1;
    }
}
