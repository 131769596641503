import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AdminSetting } from '../../../_models/admin/settings/admin.setting';

@Component({
  selector: 'app-admin-input',
  templateUrl: './admin-input.component.html',
  styleUrls: ['./admin-input.component.scss']
})
export class AdminInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() centerLabel: boolean = false;
  @Input() model: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<any>();
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() labelCols: number = 2;
  @Input() dividerKind: number = 0;
  @Input() focusInput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() labelLeftAlign: boolean = true;
  @Input() toUppercase: boolean = false;
  @Input() selectOnclick: boolean = false;
  @Input() adminSetting: AdminSetting;
  @Input() uniqueId = Math.random().toString(36).substring(2);

  constructor() { }

  ngOnInit() {
    if (this.adminSetting) {
      this.label = this.adminSetting.Description;
      this.model = this.adminSetting.Value;
      this.placeholder = this.adminSetting.Placeholder;
      this.modelChange.subscribe(value => this.adminSetting.Value = value);
    }
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

}
