import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { ThirdpartyShopSession } from '../../../_models/thirdparty-shop/thirdparty-shop-session';
import { ThirdpartyShopService } from '../../../_services/thirdparty-shop.service';
import { CarTypeService } from '../../../_services/car-type.service';
import { ShopService } from '../../../_services/shop.service';
import { MainService } from '../../../_services/main.service';
import { ShopSoort } from '../../../_models/common/shop.soort';

@Component({
  selector: 'app-thirdparty-shop-scroll-layout',
  templateUrl: './thirdparty-shop-scroll-layout.component.html',
  styleUrls: ['./thirdparty-shop-scroll-layout.component.scss']
})
export class ThirdpartyShopScrollLayoutComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() session: ThirdpartyShopSession;
  isZoomed = false;
  headerEnabled = true;
  @ViewChild('thirdpartyContainer') set content(element: ElementRef) {
    const container = element;
    if (container && this.session.container !== container.nativeElement) {
      this.session.container = container.nativeElement;
      this.thirdpartyShopService.initFrame(this.session);
    }
  }

  constructor(
    public mainService: MainService,
    public thirdpartyShopService: ThirdpartyShopService,
    public carTypeService: CarTypeService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
  }

  netPriceOptionChanged() {
    if (this.thirdpartyShopService.changedOptionsNeedsRefresh(this.session.shopSoort)) {
      this.thirdpartyShopService.refreshFrame(this.session);
    }
  }

}
