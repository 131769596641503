import { TimingItem } from './timing.item';

export class TimingObject {
    Timings: TimingItem[];
    Warnings: string[];
    Depth: number;

    static updateFromTo(timingFrom: TimingObject, timingTo: TimingObject) {
        timingTo.Timings.replaceElements(timingFrom.Timings);
        timingTo.Warnings.replaceElements(timingFrom.Warnings);
        timingTo.Depth = timingFrom.Depth;
    }
}
