<app-admin-form [title]="'Catalogus instellingen'" [object]="settings" [withSettingsSelector]="true"
  (settingsChanged)="settings=$event">
  <app-admin-checkbox [labelCols]="8" [label]="'Verberg displayopties in het groepenscherm'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'HideCatalogGroupsBoxedLayoutDisplayOptions').Value"
    [disabled]="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value!==layoutKind.BoxedLayout">
  </app-admin-checkbox>
  <app-admin-checkbox [labelCols]="8" [label]="'Verberg inklaplijsten met artikelsoorten in het groepenscherm'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'HideCatalogGroupsPartsTypesCollapsibleList').Value">
  </app-admin-checkbox>
  <app-admin-checkbox [labelCols]="8" [label]="'Verberg displayopties in het artikelscherm'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'HideCatalogPartsBoxedLayoutDisplayOptions').Value"
    [disabled]="adminSettingsService.getAdminSetting(settings,'ShopPortalLayoutKind').Value!==layoutKind.BoxedLayout">
  </app-admin-checkbox>
  <app-admin-checkbox [labelCols]="8" [label]="'Selecteer standaard alle artikel soorten'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_AllPartsDescriptionsSelectedByDefault').Value">
  </app-admin-checkbox>
  <app-admin-checkbox [labelCols]="8" [label]="'Laat alleen artikelen zien die in Easy-trade staan'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_OnlyShowKnownParts').Value"></app-admin-checkbox>
  <app-admin-checkbox [labelCols]="8" [label]="'Match op artikelnummer leverancier i.p.v. Easy-trade'"
    [(model)]="adminSettingsService.getAdminSetting(settings,'Catalog_MatchOnItemNumberSupplier').Value"></app-admin-checkbox>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)">
  </app-admin-cancel-save-button>
</app-admin-form>