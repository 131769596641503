import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appSelectOnclick]'
})
export class SelectOnclickDirective {
  @Input() appSelectOnclick = true;

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('click') onClick() {
    if (this.appSelectOnclick) {
      const inputElement = (this.el.nativeElement as HTMLInputElement);
      if (inputElement.select) {
        const isInputSelected = inputElement.selectionEnd != inputElement.selectionStart;
        if (!isInputSelected) { inputElement.select() }
      }
    }
  }

}
