import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminSettingsService } from '../../../_services/admin/admin-settings.service';
import { SettingsCarType } from '../../../_models/admin/settings/settings.car.type';

@Component({
  selector: 'app-admin-settings-car-type',
  templateUrl: './admin-settings-car-type.component.html',
  styleUrls: ['./admin-settings-car-type.component.scss']
})
export class AdminSettingsCarTypeComponent implements OnInit {
  settings: SettingsCarType;

  constructor(
    public adminSettingsService: AdminSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.settings = data.settings;
      });
  }

}
