import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TabStrip } from '../../_models/common/tab.strip';
import { ContextMain } from '../../_models/common/context.main';
import { MainService } from '../../_services/main.service';
import { ShopService } from '../../_services/shop.service';
import { ShopModule } from '../../_models/common/shop.module';

@Component({
  selector: 'app-legacy-tab-strip',
  templateUrl: './legacy-tab-strip.component.html',
  styleUrls: ['./legacy-tab-strip.component.scss']
})
export class LegacyTabStripComponent implements OnInit, AfterViewInit {
  tabStrips: TabStrip[];
  width = -1;
  scrollButtons = false;
  timer;
  versnelling = 1;
  scrollButtonWidth = -1; // = element[0].offsetWidth;
  @ViewChild('myIdentifier') myIdentifier: ElementRef;
  @Input() ctx: ContextMain;
  @ViewChild('tabScrollButtonLeft') tabScrollButtonLeft: ElementRef;
  @ViewChild('tabStrip') tabStrip: ElementRef;
  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    if (this.tabScrollButtonLeft) {
      this.setWidths(this);
    }
  }

  constructor(
    @Inject(DOCUMENT) private document,
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
    this.tabStrips = this.getTabStrips(this.ctx.ShopModules, this.ctx.MaximaalAantalTabsInBreedte);
  }

  ngAfterViewInit() {
    // wait a tick to avoid one-time devMode
    // unidirectional-data-flow-violation error
    const self = this;
    setTimeout(_ => {
      self.setWidths(self);
      this.mainService.fireReziseEvent();
    });
  }

  setWidths(self): void {
    self.scrollButtonWidth = self.tabScrollButtonLeft.nativeElement.offsetWidth;
    self.width = $(window).width() - ((self.scrollButtonWidth + 1) * 2);
    for (const strip of self.tabStrips) {
      strip.Width = self.tabStrip.nativeElement.offsetWidth;
    }
  }


  getTabStrips(shopModules: ShopModule[], maxTabsInBreedte: number): TabStrip[] {
    const strips: TabStrip[] = [];
    if (maxTabsInBreedte < 1) {
      strips[0] = { Tabs: shopModules, Width: -1 };
    } else {
      let index = 0;
      let tabStripIndex = 0;
      strips[tabStripIndex] = { Tabs: [], Width: -1 };
      while (index < shopModules.length) {
        const tab = shopModules[index++];
        strips[tabStripIndex].Tabs.push(tab);
        if (strips[tabStripIndex].Tabs.length === maxTabsInBreedte) {
          strips[++tabStripIndex] = { Tabs: [], Width: -1 };
        }
      }
    }
    return strips;
  }

  scrollFunc(dir): void {
    this.versnelling++;
    if (dir === 'left') {
      $('#tabStripContainer').animate({ scrollLeft: '-=' + 200 * this.versnelling }, 250, 'linear');
    } else {
      $('#tabStripContainer').animate({ scrollLeft: '+=' + 200 * this.versnelling }, 250, 'linear');
    }
  }

  mouseDown(dir): void {
    this.scrollFunc(dir);
    this.timer = setInterval(() => {
      this.scrollFunc(dir);
    }, 250);
  }

  mouseUp(): void {
    clearInterval(this.timer);
    this.versnelling = 0;
  }

  isVisible(): boolean {
    let scroll = false;
    for (const strip of this.tabStrips) {
      if (strip.Width < 0 || this.width < strip.Width) {
        scroll = true;
      }
    }
    this.scrollButtons = scroll;
    return scroll;
  }

}
