import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { AccountRequest } from '../../_models/logon/account.request';

@Component({
  selector: 'app-request-account-popup',
  templateUrl: './request-account-popup.component.html',
  styleUrls: ['./request-account-popup.component.scss']
})
export class RequestAccountPopupComponent implements OnInit {
  request: AccountRequest;

  public onClose: Subject<AccountRequest>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public confirm() {
    this.onClose.next(this.request);
    this.bsModalRef.hide();
  }

  public decline() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

}
