import { Component, OnInit, Input } from '@angular/core';
import { ContextMain } from '../../_models/common/context.main';
import { CartService } from '../../_services/cart.service';
import { MainService } from '../../_services/main.service';
import { ShopModule } from '../../_models/common/shop.module';
import { Router } from '@angular/router';
import { ShopService } from '../../_services/shop.service';
import { AuthService } from '../../_services/auth.service';
import { CarTypeService } from '../../_services/car-type.service';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss']
})
export class HeaderDefaultComponent implements OnInit {

  @Input() ctx: ContextMain;

  constructor(
    public mainService: MainService,
    public cartService: CartService,
    public shopService: ShopService,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

}
