import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoyaltyShopData } from '../../../_models/loyalty-shop/loyalty.shop.data';
import { MainService } from '../../../_services/main.service';
import { LoyaltyShopCategory } from '../../../_models/loyalty-shop/loyalty.shop.category';
import { AdminLoyaltyShopService } from '../../../_services/admin/admin-loyalty-shop.service';


@Component({
  selector: 'app-admin-loyalty-shop-categories',
  templateUrl: './admin-loyalty-shop-categories.component.html',
  styleUrls: ['./admin-loyalty-shop-categories.component.scss']
})
export class AdminLoyaltyShopCategoriesComponent implements OnInit {
  loyaltyShop: LoyaltyShopData;
  sortUpdateTimeout: number;

  constructor(
    public mainService: MainService,
    public adminLoyaltyShopService: AdminLoyaltyShopService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: any) => {
        this.loyaltyShop = data.loyaltyShopData;
      });
  }

  getCategories(): LoyaltyShopCategory[] {
    return Object.keys(this.loyaltyShop.Categories).map(key => this.loyaltyShop.Categories[key]);
  }

  getItemCount(category: LoyaltyShopCategory) {
    if (this.loyaltyShop && this.loyaltyShop.Cross && this.loyaltyShop.Cross[category.ID]
      && Object.keys(this.loyaltyShop.Cross[category.ID]).length > 0) {
      return Object.keys(this.loyaltyShop.Cross[category.ID]).length;
    }
    return 0;
  }

  changedCategoryActive(category: LoyaltyShopCategory) {
    this.adminLoyaltyShopService.saveLoyaltyShopCategory(category)
      .subscribe(updated => console.info(`CatID ${category.ID} changed to ${updated.Active}`));
  }

  click(isUp: boolean, category: LoyaltyShopCategory) {
    if (isUp) {
      this.mainService.moveItemUp(this.getCategories(), category, 'SortOrder');
    } else {
      this.mainService.moveItemDown(this.getCategories(), category, 'SortOrder');
    }
    if (this.sortUpdateTimeout) { window.clearTimeout(this.sortUpdateTimeout); }
    this.sortUpdateTimeout = window.setTimeout(() => {
      this.adminLoyaltyShopService.updateLoyaltyShopCategoriesSortOrder(this.getCategories()).subscribe(updated => console.info(`SortOrders updated: ${updated}`));
    }, 1000);
  }

  clickUp(category: LoyaltyShopCategory) {
    this.click(true, category);
  }

  clickDown(category: LoyaltyShopCategory) {
    this.click(false, category);
  }

}
