import { Component, Input, OnInit } from '@angular/core';
import { BackendInfo } from '../../../../_models/admin/settings/backend.info';

@Component({
  selector: 'app-admin-backend-info',
  templateUrl: './admin-backend-info.component.html',
  styleUrls: ['./admin-backend-info.component.scss']
})
export class AdminBackendInfoComponent implements OnInit {
  @Input() backendInfo: BackendInfo;
  @Input() latest: string;

  constructor() { }

  ngOnInit(): void {
  }

  isDateMinValue(date: Date):boolean {
    return (date.getTime()===-62135597972000);
  }

}
