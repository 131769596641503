<div class="row px-1 my-1">
  <div class="col-lg-8 pe-0">
    <div class="input-group input-group-sm">
      <input type="text" class="form-control" [appFocus]="focusFilter" [(ngModel)]="filter.filterString"
        [placeholder]="filter.extendedFilter?'filter op categorie':'filter op omschrijving'">
      <input *ngIf="filter.extendedFilter" type="text" class="form-control"
        [(ngModel)]="filter.partsDescriptionFilterString" placeholder="filter op artikelsoort">
      <input *ngIf="filter.extendedFilter" type="text" class="form-control" [(ngModel)]="filter.brandFilterString"
        placeholder="filter op merknaam">
      <button class="btn btn-primary" type="button" (click)="clearFilter()"><i class="far fa-times"></i></button>
        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false"><i class="far fa-filter"></i> opties</button>
        <div class="dropdown-menu">
          <span class="dropdown-item pointer" (click)="filter.extendedFilter=!filter.extendedFilter">
            <span class="me-2" [ngClass]="{'check-off': !filter.extendedFilter}"><i class="far fa-check"></i></span>Uitgebreid filter</span>
          <span *ngIf="!filter.extendedFilter" class="dropdown-item pointer"
            (click)="filter.withPartsDescriptions=!filter.withPartsDescriptions">
            <span class="me-2" [ngClass]="{'check-off': !filter.withPartsDescriptions}"><i class="far fa-check"></i></span>Filter ook op
            artikelsoorten</span>
          <span *ngIf="!filter.extendedFilter" class="dropdown-item pointer"
            (click)="filter.withBrands=!filter.withBrands">
            <span class="me-2" [ngClass]="{'check-off': !filter.withBrands}"><i class="far fa-check"></i></span>Filter ook op
            merknamen</span>
          <span class="dropdown-item pointer" (click)="filterClick()">
            <span class="me-2" [ngClass]="{'check-off': !filter.autoFilter}"><i class="far fa-check"></i></span>Automatisch filteren op beginletters geselecteerde
            category</span>
          <span class="dropdown-item pointer" (click)="filter.hideFilteredCategories=!filter.hideFilteredCategories">
            <span class="me-2" [ngClass]="{'check-off': !filter.hideFilteredCategories}"><i class="far fa-check"></i></span>Verberg
            uitgefilterde
            categorie&euml;n</span>
          <span class="dropdown-item pointer" (click)="filter.hideFilteredGroups=!filter.hideFilteredGroups">
            <span class="me-2" [ngClass]="{'check-off': !filter.hideFilteredGroups}"><i class="far fa-check"></i></span>Verberg uitgefilterde
            groepen</span>
            <span class="dropdown-item pointer" (click)="filter.hideInactiveCategories=!filter.hideInactiveCategories">
              <span class="me-2" [ngClass]="{'check-off': !filter.hideInactiveCategories}"><i class="far fa-check"></i></span>Verberg compleet inactieve categoriën</span>
      </div>
    </div>
  </div>
  <div class="col-lg-2 ps-1 pe-1">
    <button class="btn btn-warning btn-sm w-100" type="button" [routerLink]="['/admin']"><i class="fas fa-undo"></i>
      Annuleren</button>
  </div>
  <div class="col-lg-2 ps-0">
    <app-admin-save-button [saveAction]="adminSettingsService.saveSettingsCatalogLayout(settings)" [block]="true">
    </app-admin-save-button>
  </div>
</div>
<div id="main-container" class="main-container">
  <div class="top-div">
    <div class="row px-1 h-100">
      <div class="col-md-8 pe-0 h-100">
        <div class="card h-100">
          <div class="card-header text-bg-dark header-text p-1" (contextmenu)="mainService.showObject($event, settings)">
            <i class="fas fa-th-list me-1"></i>
            <span>Alle categoriën</span>
            <span
              class="badge text-bg-warning ms-3">{{settings.CatalogLayoutSubgroupCategoryCross[-1].length - catalogLayoutService.countLinkedCategories(settings.CatalogLayoutSubgroupCategoryCross)}}
              niet gekoppeld</span>
            <span
              class="badge text-bg-secondary ms-3">{{catalogLayoutService.countLinkedCategories(settings.CatalogLayoutSubgroupCategoryCross)}}
              wel gekoppeld</span>
            <span *ngIf="!catalogLayoutService.isFilterEmpty(filter)"
              class="badge text-bg-light ms-3">{{catalogLayoutService.countFilterHitCategories(settings.CatalogLayoutSubgroupCategoryCross,filter,settings.PartsDescriptions)}}
              van {{settings.CatalogLayoutSubgroupCategoryCross[-1].length}}</span>
            <span *ngIf="catalogLayoutService.isFilterEmpty(filter)"
              class="badge text-bg-light ms-3">{{settings.CatalogLayoutSubgroupCategoryCross[-1].length}}</span>
            <button class="btn btn-dark btn-xxs float-end" type="button" (click)="catalogLayoutService.doAutoFill(settings.CatalogKindInfo, settings.Groups, settings.CatalogLayoutSubgroupCategoryCross)"><i class="fas fa-magic"></i> Automatisch
              indelen</button>
          </div>
          <div id="scrollContainerCategories" class="card-body p-0">
            <div *ngIf="explainDrag" class="explain-drag text-center text-danger">
              <div>
                <br /> <br />
                Sleep de categorie&euml;n die u wilt  <br />ontkoppelen/verwijderen hierheen... <br />
                ...en laat ze los.<br /><br />
                Of sleep ze naar een andere subgroep om ze daar aan te koppelen.
              </div>
            </div>
            <div class="scroll-list-categories">
              <div [id]="'subgroupAll'" cdkDropList [cdkDropListConnectedTo]="otherSubgroupIDs('subgroupAll')"
                [cdkDropListData]="settings.CatalogLayoutSubgroupCategoryCross[-1]"
                (cdkDropListDropped)="dropCategorie($event)" (cdkDropListEntered)="explainDrag=true"
                (cdkDropListExited)="explainDrag=false" class="category-list p-1">
                <ng-container
                  *ngFor="let category of settings.CatalogLayoutSubgroupCategoryCross[-1] | adminCatalogLayoutCategorySort:catalogLayoutService:settings.CatalogLayoutSubgroupCategoryCross">
                  <span cdkDrag [cdkDragData]="category" [id]="'cat_'+category.Origin+'_'+category.CategoryId+'_all'">
                    <app-admin-catalog-layout-category
                      *ngIf="(!filter.hideFilteredCategories || catalogLayoutService.filterHitCategory(category,filter,settings.PartsDescriptions)) && (!filter.hideInactiveCategories || !catalogLayoutService.allLinesInactive(settings.ActiveCatalogFilter,catalogLayoutService.getPartsDescriptionsForCategory(settings.PartsDescriptions, category)))"
                      [category]="category" [catalogKindInfo]="settings.CatalogKindInfo"
                      [partsDescriptions]="settings.PartsDescriptions"
                      [activeCatalogFilter]="settings.ActiveCatalogFilter"
                      [badgeClass]="catalogLayoutService.areCategoriesEqual(selected, category) ? 'bg-danger' : catalogLayoutService.isCategoryLinked(settings.CatalogLayoutSubgroupCategoryCross, category) ? 'bg-secondary' : 'bg-warning'"
                      [filter]="filter" (selected)="setSelected(category)">
                    </app-admin-catalog-layout-category>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 ps-1 h-100">
        <app-admin-catalog-layout-category-info [category]="selected" [settings]="settings" [filter]="filter">
        </app-admin-catalog-layout-category-info>
      </div>
    </div>
  </div>
  <div id="scrollContainerGroups" class="bottom-div">
    <div class="scroll-list-groups">
      <div [id]="'maingroupList'" cdkDropList cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropMaingroup($event)" [cdkDropListData]="settings.Groups" class="maingroup-list">
        <div cdkDrag [id]="'maingroup'+group.ID" *ngFor="let group of settings.Groups | adminCatalogLayoutStatusFilter"
          [cdkDragData]="group" class="maingroup">
          <div class="card mb-2">
            <div class="card-header text-bg-dark p-1 fw-bold header-text noselect">
              <span cdkDragHandle><i class="fas fa-grip-vertical move"></i></span>
              <span class="group-title">
                {{group.Description}}
              </span>
              <div class="btn-toolbar float-end" role="toolbar">
                <div class="btn-group btn-group-xxs me-2" role="group">
                  <button type="button" class="btn btn-dark" (click)="addSubgroup(group)"><i
                      class="far fa-plus"></i></button>
                </div>
                <div class="btn-group btn-group-xxs" role="group">
                  <button type="button" class="btn btn-dark" (click)="editMaingroup(group)"><i
                      class="far fa-edit"></i></button>
                  <button type="button" class="btn btn-dark" (click)="deleteMaingroup(group)"><i
                      class="fas fa-trash-alt"></i></button>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <ul cdkDropList [id]="'subgroupList'+group.ID"
                [cdkDropListConnectedTo]="otherMaingroupIDs(settings.Groups,group)" [cdkDropListData]="group.Subgroups"
                (cdkDropListDropped)="dropSubgroup($event)" class="list-group list-group-flush subgroup-list">
                <ng-container *ngFor="let subgroup of group.Subgroups | adminCatalogLayoutStatusFilter">
                  <ng-container
                    *ngIf="!filter.hideFilteredGroups || catalogLayoutService.filterHitSubgroup(settings.CatalogLayoutSubgroupCategoryCross, subgroup, filter, settings.PartsDescriptions)">
                    <li cdkDrag [cdkDragData]="subgroup" class="list-group-item subgroup">
                      <div class="subgroup-description">
                        <span cdkDragHandle><i cdkDragHandle class="fas fa-grip-vertical move"></i></span>
                        <span class="subgroup-title align-middle ps-1">{{subgroup.Description}}</span>
                        <div class="btn-group btn-group-xxs subgroup-btn-group float-end" role="group">
                          <button type="button" class="btn btn-outline-secondary" (click)="editSubgroup(subgroup)"><i
                              class="far fa-edit"></i></button>
                          <button type="button" class="btn btn-outline-secondary" (click)="deleteSubgroup(subgroup)"><i
                              class="fas fa-trash-alt"></i></button>
                        </div>
                      </div>
                      <div cdkDropList [id]="'subgroup'+subgroup.ID"
                        [cdkDropListConnectedTo]="otherSubgroupIDs('subgroup'+subgroup.ID)"
                        [cdkDropListData]="catalogLayoutService.getCategoriesFromCross(settings.CatalogLayoutSubgroupCategoryCross, subgroup)"
                        [cdkDropListEnterPredicate]="noDoublesPredicate" (cdkDropListDropped)="dropCategorie($event)"
                        class="subgroup-categories category-list">
                        <span cdkDrag
                          *ngFor="let category of catalogLayoutService.getCategoriesFromCross(settings.CatalogLayoutSubgroupCategoryCross, subgroup)"
                          [id]="'cat_'+category.Origin+'_'+category.CategoryId+'_'+subgroup.ID"
                          [cdkDragData]="category">
                          <app-admin-catalog-layout-category 
                            *ngIf="(!filter.hideFilteredCategories || catalogLayoutService.filterHitCategory(category,filter,settings.PartsDescriptions)) && (!filter.hideInactiveCategories || !catalogLayoutService.allLinesInactive(settings.ActiveCatalogFilter,catalogLayoutService.getPartsDescriptionsForCategory(settings.PartsDescriptions, category)))"
                            [category]="category"
                            [catalogKindInfo]="settings.CatalogKindInfo"
                            [partsDescriptions]="settings.PartsDescriptions"
                            [activeCatalogFilter]="settings.ActiveCatalogFilter"
                            [badgeClass]="catalogLayoutService.areCategoriesEqual(selected, category) ? 'bg-danger' : 'bg-warning'"
                            [filter]="filter" (selected)="setSelected(category)">
                          </app-admin-catalog-layout-category>
                        </span>
                        <span
                          *ngIf="catalogLayoutService.getCategoriesFromCross(settings.CatalogLayoutSubgroupCategoryCross, subgroup).length==0">
                          <i class="text-danger">Deze subgroep is leeg! U kunt hier categorie&euml;n heen slepen...</i>
                        </span>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="maingroup" [style.width.%]="(4-(settings.Groups.length%4))*25">
          <button class="btn btn-primary btn-sm" [style.width.%]="100/(4-(settings.Groups.length%4))" type="button"
            (click)="addMaingroup()"><i class="far fa-plus"></i> Hoofdgroep
            toevoegen</button>
        </div>
      </div>
    </div>
  </div>
</div>
