import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { MainService } from '../_services/main.service';
import { CustomerInformationService } from '../_services/customer-information.service';
import { GisModuleKind } from '../_models/customer-information/gis.module.kind';


@Injectable({
  providedIn: 'root'
})
export class CustomerInformationGuard  {

  constructor(
    private mainService: MainService,
    private customerInformationService: CustomerInformationService
  ) { }

  tryPassword(kind: GisModuleKind): Observable<boolean> {
    return this.mainService.inputBoxExtended(
      `${this.customerInformationService.getModuleName(kind)} vereist een wachtwoord`, 'Wachtwoord',
      this.customerInformationService.password, true, 'modal-sm')
      .pipe(mergeMap(password => {
        if (password) {
          this.customerInformationService.setPassword(password);
          this.customerInformationService.ctx = null;
          return this.customerInformationService.getContextCustomerInformation()
            .pipe(mergeMap(ctx => {
              const module = this.customerInformationService.getModuleByKind(ctx, kind);
              if (module && module.UnLocked) {
                return of(true);
              } else if (module && !module.UnLocked) {
                return this.mainService.msgBox(
                  'Let op!', `Het wachtwoord voor '${this.customerInformationService.getModuleName(module.Kind)}' is onjuist.`)
                  .pipe(mergeMap(() => this.tryPassword(kind)));
              }
              return of(false);
            }));
        }
        return of(false);
      }));
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const kind = next.data.moduleKind;

    return this.customerInformationService.getContextCustomerInformation()
      .pipe(mergeMap(ctx => {
        const module = this.customerInformationService.getModuleByKind(ctx, kind);
        if (module && module.UnLocked) {
          return of(true);
        } else if (module && !module.UnLocked) {
          return this.tryPassword(kind);
        } else {
          return of(false);
        }
      }));
  }

}
