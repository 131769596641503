<ng-container *ngIf="descriptions && descriptions.length > 0">
  <div class="card-body text-bg-light less-padding filter-group pointer noselect" (click)="collapse()">
    <span>{{title}}</span>
    <span *ngIf="collapsed && selected" class="ms-3 text-low">{{selected}} van {{descriptions.length}}</span>
    <div class="float-end collapse-button" [ngClass]="{'is-collapsed': collapsed}"><i class="fas fa-chevron-up"></i></div>
  </div>
  <div *ngIf="!collapsed" [@slideInOut] class="collapsable">
    <div class="card-body filter-options">
      <div *ngFor="let item of descriptions" class="noselect">
        <div *ngIf="!item.hidden && !(hideEmptyItems && item.count===0)" [@slideAndFadeInOut] class="form-checkbox select-list-item">
          <input type="checkbox" class="form-check-input me-2" [id]="'customCheck_' + title + '_' + item.description"
            [(ngModel)]="item.selected" [disabled]="item.count===0 && !item.selected" (ngModelChange)="change(item)">
          <label class="form-check-label pointer"
            [for]="'customCheck_' + title + '_' + item.description">{{item.description}}</label>
          <span *ngIf="item.count" class="float-end text-low">{{item.count}}</span>
        </div>
      </div>
      <span *ngIf="maxItems && descriptions.length > maxItems" class="pointer" (click)="more()"><span *ngIf="!showAll">meer </span>
      <div class="collapse-button" [ngClass]="{'is-collapsed': showAll}"><i class="fas fa-angle-double-right"></i></div><span *ngIf="showAll"> minder</span></span>
    </div>
  </div>
</ng-container>
