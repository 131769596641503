import { Component, OnInit, Input } from '@angular/core';

import { Animations } from '../../_animations/animations';
import { ContextMain } from '../../_models/common/context.main';
import { MainService } from '../../_services/main.service';
import { ShopService } from '../../_services/shop.service';
import { CartService } from '../../_services/cart.service';
import { AuthService } from '../../_services/auth.service';
import { CarTypeService } from '../../_services/car-type.service';
import { UniversalCarType } from '../../_models/car-types/universal.car.type';
import { ApiService } from '../../_services/api.service';
import { ItemInfoRequest } from '../../_models/item-info/item.info.request';
import { ContextCarTypeSelection } from '../../_models/car-types/context.car.type.selection';
import { Router } from '@angular/router';
import { SettingsService } from '../../_services/settings.service';
import { ShopSoort } from '../../_models/common/shop.soort';


@Component({
  selector: 'app-header-doetam',
  templateUrl: './header-doetam.component.html',
  styleUrls: ['./header-doetam.component.scss'],
  animations: [Animations.slideInVertical]
})
export class HeaderDoetamComponent implements OnInit {
  shopSoort = ShopSoort;
  @Input() ctx: ContextMain;
  ctxCarTypeSelection: ContextCarTypeSelection;
  licensePlate: string;
  item: string;

  constructor(
    public mainService: MainService,
    public authService: AuthService,
    public shopService: ShopService,
    public cartService: CartService,
    public settingsService: SettingsService,
    private carTypeService: CarTypeService,
    public router: Router
  ) { }


  ngOnInit() {
    this.carTypeService.getContextCarTypeSelection(this.ctx.DefaultCatalog)
      .subscribe(ctx => {
        this.ctxCarTypeSelection = ctx;
      });
  }

  getDummyStyle() {
    let menuWidth = 0;
    if (this.ctx && this.ctx.ShopModuleMenu) { menuWidth = this.ctx.ShopModuleMenu.MenuWidth; }
    return {
      width: `${menuWidth}px`
    };
  }

  carTypeSelected(carType: UniversalCarType): void {
    this.carTypeService.saveCarType(this.ctx.DefaultCatalog, carType)
      .subscribe(result => {
        this.shopService.resetCarTypeShops(this.mainService.ctxMain);
        this.carTypeService.setCarType(result.CarType);
        this.ctx.CurrentAuto = result.CarType;
        this.shopService.gotoCarTypeSupportedShopModule(this.ctx, this.shopService.currentActiveShop, this.ctx.DefaultCatalog);
        this.licensePlate = null;
        // this.setCarTypeInfo(result.CarType);
      });
  }

  searchLicensePlate(licensePlate: string): void {
    this.carTypeService.searchLicensePlate(this.ctx.DefaultCatalog, licensePlate)
      .subscribe(carTypeResult => {
        if (carTypeResult && carTypeResult.CarType) {
          this.carTypeSelected(carTypeResult.CarType);
        }
      });
  }

  setCarTypeInfo(carType: UniversalCarType): void {
    if (carType) {
      if (carType.LicensePlate) {
        this.licensePlate = carType.LicensePlate.Formated;
      } else {
        this.licensePlate = null;
      }
    }
  }

  searchBrandModelType(): void {
    this.carTypeService.searchBrandModelType(this.ctx.DefaultCatalog, this.ctxCarTypeSelection.Merken, null, null)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

  searchVin(): void {
    this.mainService.inputBoxExtended('Zoeken op chassisnummer', 'Chassisnr', '', false, 'modal-md')
      .subscribe(vin => {
        if (vin) {
          this.carTypeService.searchVin(this.ctx.DefaultCatalog, vin)
            .subscribe(result => {
              if (result && result.CarType) { this.carTypeSelected(result.CarType); }
            });
        }
      });
  }

  searchHistory(): void {
    this.carTypeService.searchHistory(this.ctxCarTypeSelection.Historie)
      .subscribe(result => {
        if (result && result.CarType) { this.carTypeSelected(result.CarType); }
      });
  }

}
