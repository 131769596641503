import { Component, OnInit } from '@angular/core';
import { ShopSoort } from '../../_models/common/shop.soort';
import { PickupRequest } from '../../_models/pickup-request/pickup.request';
import { ApiService } from '../../_services/api.service';
import { CartService } from '../../_services/cart.service';
import { MainService } from '../../_services/main.service';
import { ShopService } from '../../_services/shop.service';

@Component({
  selector: 'app-pickup-request',
  templateUrl: './pickup-request.component.html',
  styleUrls: ['./pickup-request.component.scss']
})
export class PickupRequestComponent implements OnInit {
  shopSoort = ShopSoort;
  request: PickupRequest = new PickupRequest();

  constructor(
    private apiService: ApiService,
    private cartService: CartService,
    public mainService: MainService,
    public shopService: ShopService
  ) { }

  ngOnInit() {
    this.request.ModuleName = this.shopService.getShopModuleName(this.mainService.ctxMain, ShopSoort.PickupRequest);
    this.request.DateTime = new Date();
  }

  sendPickupRequest(request: PickupRequest) {
    this.apiService.sendPickupRequest(request)
      .subscribe(result => {
        if (result?.OrderNumberWholesaler) {
          this.mainService.msgBox('Bedankt!', `Uw verzoek is ontvangen op ordernummer ${result.OrderNumberWholesaler}.`)
            .subscribe(ok => {
              this.shopService.gotoStartupModule(this.mainService.ctxMain);
            });
        } else {
          this.mainService.msgBox('Helaas!', 'Er is iets mis gegaan met het verzenden.');
        }
      });
  }

}
