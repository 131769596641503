import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { ItemImageItemGroup } from '../../_models/admin/item-images/item.image.item.group';
import { AdminItemImagesService } from '../../_services/admin/admin-item-images.service';

@Injectable({
  providedIn: 'root'
})
export class AdminItemImagesItemGroupEditResolverService   {

  constructor(
    private adminItemImagesService: AdminItemImagesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ItemImageItemGroup> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.adminItemImagesService.getItemImageItemGroup(+id).pipe(
      take(1),
      mergeMap(item => {
        if (item) {
          return of(item);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
