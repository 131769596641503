import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { RimAndTyreService } from '../../_services/rim-and-tyre.service';
import { TyreResult } from '../../_models/tyres/tyre.result';
import { TyreSize } from '../../_models/tyres/tyre.size';
import { TyreRequest } from '../../_models/tyres/tyre.request';
import { ShopSoort } from '../../_models/common/shop.soort';


@Injectable({
  providedIn: 'root'
})
export class TyresDataResolverService  {

  constructor(
    private rimAndTyreService: RimAndTyreService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TyreResult> | Observable<never> {
    let request: TyreRequest;
    if (state.url.startsWith('/rims')) {
      const set = this.rimAndTyreService.getWheelTyreSet();
      if (set) { request = this.rimAndTyreService.getTyreRequest(set); }
    } else {
      const size = route.paramMap.get('search');
      request = new TyreRequest([TyreSize.getFromString(size)], 2, true, 0, ShopSoort.Banden);
      if (route.queryParams['brand']) { request.Brand = route.queryParams['brand']; }
      if (route.queryParams['category']) { request.Category = route.queryParams['category']; }
    }


    return this.rimAndTyreService.getTyreSizes(request).pipe(
      take(1),
      mergeMap(tyreResult => {
        if (tyreResult) {
          tyreResult.ForRims = state.url.startsWith('/rims');
          return of(tyreResult);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }


}
