import { Component, OnInit } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';
defineLocale('nl', nlLocale);

import { MainService } from '../../../_services/main.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit() {
  }

}
