import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-extra-message-main-supplier',
  templateUrl: './extra-message-main-supplier.component.html',
  styleUrls: ['./extra-message-main-supplier.component.scss']
})
export class ExtraMessageMainSupplierComponent implements OnInit {
  @Input() enabled: boolean;
  @Output() enabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() supplier: number;
  @Output() supplierChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() message: string;
  @Output() messageChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changeEnabled() {
    this.enabled=!this.enabled;
    this.enabledChange.emit(this.enabled);
  }

}
