<div class="form-group row mb-0">
  <label [ngClass]="'col-sm-' + labelCols" class="form-check-label label-admin text-end noselect pe-0" [for]="label">
    {{label}}
  </label>
  <div [ngClass]="'col-sm-' + (12-labelCols)" class="my-auto">
    <div class="row">
      <div *ngFor="let setting of modelArray" [ngClass]="colClass">
        <div class="form-check noselect">
          <input type="checkbox" class="form-check-input" [id]="'chk'+uniqueId+setting.Key" [checked]="getValue(setting)" [disabled]="disabled" (ngModelChange)="changeModel(setting)">
          <label class="form-check-label" [for]="'chk'+uniqueId+setting.Key">{{setting.Description}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<app-admin-divider [dividerKind]="dividerKind"></app-admin-divider>
