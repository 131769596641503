import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ShopSoort } from '../../_models/common/shop.soort';
import { ThirdpartyShopService } from '../../_services/thirdparty-shop.service';
import { CarTypeService } from '../../_services/car-type.service';
import { ApiService } from '../../_services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ContextBase } from '../../_models/common/context.base';
import { UniversalCarType } from '../../_models/car-types/universal.car.type';
import { ContextAldocSys } from '../../_models/aldoc-sys/context.aldoc.sys';
import { MainService } from '../../_services/main.service';
import { ThirdpartyShopSession } from '../../_models/thirdparty-shop/thirdparty-shop-session';

@Component({
  selector: 'app-thirdparty-shop',
  templateUrl: './thirdparty-shop.component.html',
  styleUrls: ['./thirdparty-shop.component.scss']
})
export class ThirdpartyShopComponent implements OnInit, OnDestroy {
  session: ThirdpartyShopSession;
  subscriptions: Subscription[] = [];
  shopSoort: ShopSoort;

  constructor(
    public mainService: MainService,
    public thirdpartyShopService: ThirdpartyShopService,
    public carTypeService: CarTypeService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.shopSoort = this.route.snapshot.data['shop'];
    if (this.shopSoort !== ShopSoort.ONBEKEND) {
      this.thirdpartyShopService.getShopSession(this.shopSoort)
        .subscribe(session => {
          if (session) {
            console.info(`thirdpartyShopService.getShopSession: got session for ${ShopSoort[session.shopSoort]}`);
            this.session = session;
            if (!session.ctx) {
              console.warn(`ThirdpartyShopComponent: No Context!`);
            }
            if (this.carTypeService.currentCarType) {
              console.info(`thirdpartyShopService.getShopSession: set carType to ${this.carTypeService.currentCarType.TypeId}`);
              const carType = this.carTypeService.currentCarType;
              setTimeout(() => {
                this.thirdpartyShopService.updateCarType(session, carType);
              }, 1000);
            } else {
              console.warn(`ThirdpartyShopComponent: No CarType`);
            }
            this.subscriptions.push(this.carTypeService.carTypeChanged$
              .subscribe(carType => {
                this.thirdpartyShopService.updateCarType(this.session, carType);
              }));
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.shopSoort === ShopSoort.AldocSys) {
      this.apiService.clearExternalCarts().subscribe();
    }
  }

}
