<div class="alert alert-secondary px-2 mt-2" *ngFor="let interfaceData of interfaceDatas;let even=even">
  <div class="row">
    <div class="col-10 small">
      <div *ngIf="interfaceData.Username" class="row">
        <div class="col-3"><strong>Username</strong></div>
        <div class="col-9">{{interfaceData.Username}}</div>
      </div>
      <div *ngIf="interfaceData.Password" class="row">
        <div class="col-3"><strong>Password</strong></div>
        <div class="col-9">{{interfaceData.Password}}</div>
      </div>
      <div *ngIf="adminSupplierInterfaceService.isProxyExtraLoggingSet(interfaceData)" class="row">
        <div class="col"><i>Extra logging staat aan.</i></div>
      </div>
      <div *ngIf="adminSupplierInterfaceService.isProxyTestUrlSet(interfaceData)" class="row">
        <div class="col"><i>Maakt gebruik van de test url.</i></div>
      </div>
    </div>
    <div class="col-2 actions text-end">
      <button class="btn btn-xs btn-outline-secondary w-100"
        (click)="adminSupplierInterfaceService.editProxyInterfaceData(supplierInterfaceData, interfaceData, interfaceDataChanged)"><i
          class="fas fa-edit"></i> Wijzig</button>
    </div>
  </div>
</div>