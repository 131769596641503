import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterString'
})
export class FilterStringPipe implements PipeTransform {

  transform(items: string[], filter: string): string[] {
    if (items && filter) {
      return items.filter(item => {
        if (item) return item.toLowerCase().indexOf(filter) >= 0;
        return false;
      });
    }
    return items;
  }

}
